import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { AutoSizer, List } from "react-virtualized";

import { actions } from "~/action-panel/components/field-module/";
import { messages as apMessages } from "~/action-panel/i18n-messages";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import { adminData } from "~/admin/data";
import { messages as setupMessages } from "~/admin/setup/i18n-messages";
import {
    AutoSearch,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    SelectInput,
} from "~/core";
import { messages as globalMessages } from "~/i18n-messages";
import { getTheUserGuid } from "~/login";
import { getTheUserPersonalityId, getUser } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { IFieldsToMove } from "~/action-panel/components/field-module/models";
import { SearchAPI } from "@ai360/core";

import { messages } from "../../i18n-messages";
import * as selectors from "../../../../selectors";
import { messages as contextMessages } from "../../../../../context-menus/i18n-messages";

import _ from "lodash";

import "./move-fields-modal.css";
import { fetchOccupiedFarmNames } from "~/utils/api/field";

interface IProps {
    agvanceOrgList: any[];
    apiCallError: () => void;
    clearFieldsToMove: () => void;
    fetchAgvanceOrgLevelList: (customerGuid: string) => void;
    intl: any;
    isOpen: boolean;
    isLoading: boolean;
    fieldsToMove: IFieldsToMove;
    moveCustomerFields: (payload: any) => void;
    orgLevelList: any[];
    personalityId: number;
    userGuid: string;
    userRoles: any;
}

interface IState {
    agvanceCustomerGuid: string;
    agvanceFieldOrgLevelGuid: string;
    isAgvanceCustomer: boolean;
    customerGuid: string;
    customerName: string;
    farmName: string;
    farmNameOptions: any[];
    includeInactive: boolean;
}

class MoveFieldsModal_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            agvanceCustomerGuid: null,
            agvanceFieldOrgLevelGuid: null,
            customerGuid: null,
            customerName: "",
            farmName: "",
            farmNameOptions: [],
            isAgvanceCustomer: AgvanceUtils.isAgvanceConnected(props.personalityId),
            includeInactive: props.fieldsToMove.fields.some((f) => !f.activeYn),
        };
    }

    async componentDidMount() {
        const { fieldsToMove } = this.props;

        if (fieldsToMove.customerGuid) {
            if (!this.hasAcrossCustomerRole()) {
                const farmNameOptions = await this.getFarmNameOptions(fieldsToMove.customerGuid);
                this.setState({
                    farmNameOptions,
                    customerGuid: fieldsToMove.customerGuid,
                });
            } else {
                this.setState({
                    farmNameOptions: [],
                });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        const { isOpen, fieldsToMove } = this.props;
        if (nextProps.isOpen && isOpen !== nextProps.isOpen) {
            const { customerGuid } = fieldsToMove;
            const { farmName } = fieldsToMove.fields.find(
                (x) => x.id === nextProps.fieldsToMove.fieldGuids[0]
            );
            this.setState({
                customerGuid,
                farmName: nextProps.fieldsToMove.fieldGuids.length === 1 ? farmName : "",
                customerName: nextProps.fieldsToMove.customer.name,
                includeInactive: nextProps.fieldsToMove.fields.some((f) => !f.activeYn),
            });
        }
        if (
            this.state.isAgvanceCustomer &&
            this.state.customerGuid &&
            nextProps.agvanceOrgList !== this.props.agvanceOrgList
        ) {
            const primaryAgvanceCustomer = nextProps.agvanceOrgList.find((item) => {
                return item.isPrimary;
            });
            this.setState({
                agvanceCustomerGuid: primaryAgvanceCustomer
                    ? primaryAgvanceCustomer.agvanceCustomerGuid
                    : "",
                agvanceFieldOrgLevelGuid: primaryAgvanceCustomer
                    ? primaryAgvanceCustomer.orgLevelGuid
                    : "",
            });
        }
    }

    private async getFarmNameOptions(customerGuid: string) {
        const { userGuid } = this.props;
        const { includeInactive } = this.state;

        const farmNames = await fetchOccupiedFarmNames(
            customerGuid,
            !includeInactive ? true : null,
            userGuid
        );

        return farmNames.map((f) => ({
            label: f,
            value: f,
        }));
    }

    private hasAcrossCustomerRole() {
        return this.props.userRoles.acrossCustomers;
    }

    private onAction() {
        const { fieldsToMove, userGuid } = this.props;
        const {
            customerGuid,
            farmName,
            agvanceCustomerGuid,
            agvanceFieldOrgLevelGuid,
            isAgvanceCustomer,
        } = this.state;
        const agvanceData = isAgvanceCustomer
            ? { agvanceCustomerGuid, agvanceFieldOrgLevelGuid }
            : {};

        this.props.moveCustomerFields({
            userGuid,
            customerGuid,
            farmName,
            fieldGuidList: fieldsToMove.fieldGuids,
            ...agvanceData,
        });
    }

    private onAgvanceCustomerChange({ agvanceCustomerGuid, orgLevelGuid }) {
        this.setState({
            agvanceCustomerGuid,
            agvanceFieldOrgLevelGuid: orgLevelGuid,
        });
    }

    private onClose() {
        this.setState({ farmName: "" });
        this.props.clearFieldsToMove();
    }

    private rowRenderer({ index, key, style }) {
        const { fieldsToMove } = this.props;

        const field = fieldsToMove.fields.find((x) => x.id === fieldsToMove.fieldGuids[index]);
        const display = [field.farmName, field.name].filter((s) => s).join(", ");
        return (
            <div className="mf-field-list-item" key={key} style={style} title={display}>
                {display}
            </div>
        );
    }

    private showAdditionalAgvanceCustomer() {
        return (
            this.state.customerGuid &&
            this.state.isAgvanceCustomer &&
            this.props.agvanceOrgList.length > 1
        );
    }

    private async updateCustomerFarmList(customer: any) {
        if (customer) {
            const { customerGuid } = customer;
            const { fetchAgvanceOrgLevelList } = this.props;

            const farmNameOptions = await this.getFarmNameOptions(customerGuid);

            this.setState(
                {
                    customerGuid,
                    farmNameOptions,
                    agvanceCustomerGuid: "",
                    agvanceFieldOrgLevelGuid: "",
                },
                () => {
                    if (this.state.isAgvanceCustomer) {
                        fetchAgvanceOrgLevelList(customerGuid);
                    }
                }
            );
        } else {
            this.setState({
                customerGuid: "",
                farmNameOptions: [],
                agvanceCustomerGuid: "",
                agvanceFieldOrgLevelGuid: "",
            });
        }
    }

    private getOptions(userGuid: string, searchValue: string) {
        const { orgLevelList } = this.props;

        const search = searchValue === "_" ? null : searchValue;
        const orgLevelGuids = orgLevelList?.map(({ orgLevelGuid }) => orgLevelGuid);
        const active = this.state.includeInactive ? null : true;

        return SearchAPI.getCustomers({
            userGuid,
            search,
            orgLevelGuids,
            active,
        });
    }

    render() {
        const { fieldsToMove, isOpen, userGuid, isLoading } = this.props;
        const { formatMessage } = this.props.intl;
        const { customerGuid, farmName, farmNameOptions } = this.state;

        if (!isOpen) {
            return null;
        }

        const searchForCustomer = formatMessage(setupMessages.searchFor, {
            searchedFor: formatMessage(setupMessages.customer),
        });
        const { agvanceCustomerGuid, agvanceFieldOrgLevelGuid } = this.state;
        return (
            <DialogBox
                draggable
                action={formatMessage(messages.move)}
                actionDisabled={this.hasAcrossCustomerRole() ? !customerGuid : !farmName}
                className="move-fields-modal"
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                onAction={() => this.onAction()}
                onClose={() => this.onClose()}
                title={formatMessage(contextMessages.moveFieldsText, {
                    count: fieldsToMove.fieldGuids.length,
                })}
                unrestricted
            >
                <div className="mf-row">
                    <div className="mf-col">
                        <div className="mf-header">
                            {formatMessage(messages.selectedField, {
                                count: fieldsToMove.fieldGuids.length,
                            })}
                        </div>
                        <div className="mf-content">
                            <AutoSizer>
                                {({ width, height }) => (
                                    <List
                                        height={height}
                                        rowCount={fieldsToMove.fieldGuids.length}
                                        rowHeight={20}
                                        rowRenderer={(e) => this.rowRenderer(e)}
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                    <div className="mf-col">
                        <div className="mf-header">{formatMessage(messages.moveTo)}</div>
                        {!this.hasAcrossCustomerRole() ? null : (
                            <AutoSearch
                                getAutoSearchList={this.getOptions.bind(this)}
                                keyProp={SearchAPI.Props.CUSTOMER_GUID}
                                nameProp={SearchAPI.Props.CUSTOMER_NAME}
                                itemList={[]}
                                onSelection={(item) => this.updateCustomerFarmList(item)}
                                placeholderText={searchForCustomer}
                                secondaryPropList={[
                                    SearchAPI.Props.CUSTOMER_CITY,
                                    SearchAPI.Props.STATE_ABBR,
                                ]}
                                userGuid={userGuid}
                            />
                        )}
                        {!this.showAdditionalAgvanceCustomer() ? null : (
                            <SelectInput
                                clearable={false}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                clearFilterInputOnBlur={false}
                                placeholderText={formatMessage(globalMessages.agvanceCustomer)}
                                value={{
                                    agvanceCustomerGuid,
                                    orgLevelGuid: agvanceFieldOrgLevelGuid,
                                }}
                                onChange={(v) => this.onAgvanceCustomerChange(v)}
                                options={this.props.agvanceOrgList.map(
                                    ({
                                        agvanceGrowerName,
                                        orgLevelGuid,
                                        customerName,
                                        agvanceCustomerGuid,
                                        orgLevelName,
                                        isPrimary,
                                    }) => ({
                                        label: `${
                                            isPrimary ? customerName : agvanceGrowerName
                                        } > ${orgLevelName}`,
                                        value: {
                                            agvanceCustomerGuid,
                                            orgLevelGuid,
                                        },
                                    })
                                )}
                                required
                            />
                        )}
                        <SelectInput
                            disabled={this.hasAcrossCustomerRole() ? !customerGuid : false}
                            initialFilterStr={farmName}
                            onInputChange={(farmName) => this.setState({ farmName })}
                            onChange={(farmName) => this.setState({ farmName })}
                            options={farmNameOptions}
                            placeholderText={formatMessage(apMessages.farmName)}
                            value={farmName}
                            clearFilterInputOnBlur={false}
                            allowEmptyOptions
                        />
                    </div>
                </div>
                {!isLoading &&
                !(
                    this.state.isAgvanceCustomer &&
                    this.state.customerGuid &&
                    !this.state.agvanceCustomerGuid
                ) ? null : (
                    <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />
                )}
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    agvanceOrgList: selectors.getAgvanceOrgLevelList(state),
    personalityId: getTheUserPersonalityId(state),
    userGuid: getTheUserGuid(state),
    userRoles: getUser(state).role,
    isLoading: selectors.getMoveDialogLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    apiCallError: (err) => dispatch(notificationActions.apiCallError(err)),
    clearFieldsToMove: () => dispatch(actions.clearFieldsToMove()),
    fetchAgvanceOrgLevelList: (payload) => dispatch(actions.fetchAgvanceOrgLevelList(payload)),
    moveCustomerFields: (payload) => dispatch(actions.moveCustomerFields(payload)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});
export const MoveFieldsModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(MoveFieldsModal_));
