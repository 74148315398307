import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { ReportTable, ReportOptionsContainer, ReportTypes } from "../components";
import { withReport } from "../hocs";

import { eventsSelectors } from "~/recs-events";
import { recsSelectors } from "~/recs-events";
import { FieldAPI, ReportAPI, ReportBooksAPI } from "@ai360/core";
import "../reports.css";
import { ICrops, ISeason } from "@ai360/core/dist/4x/es/api/field/field";
import { IReportType, IReportTypePage } from "../data/interfaces";

const service = {
    REPORT_TYPE_LIST_URL: ReportAPI.GET_REPORT_TYPE_LIST_URL,
    REPORT_TYPE_PAGE_LIST_URL: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
};
export interface AGRIntelligence_Props {
    agEventSummaryMap?: Map<any, any>;
    createReport?: () => void;
    createReportHubProgress?: boolean;
    crops?: ICrops[];
    initialRequestOptions?: Record<string, any>;
    initializeReportData?: () => void;
    intl: intlShape;
    needs?: () => void;
    onAllReportCreated?: () => void;
    onInputChange?: () => void;
    recGeneralGuidToRecMap?: Map<any, any>;
    reportPreferences?: Record<string, any>;
    reportTypes?: IReportType[];
    reportTypePages?: IReportTypePage[];
    seasons?: ISeason[];
    selectReportType?: () => void;
    selectedFields?: FieldAPI.IFieldInfo[] | ReportBooksAPI.IField[];
    selectedReportTypes?: string[];
    selectedReportTypeNames?: string[];
    selectedEventGuidSet?: Set<string>;
    selectedRecGuidSet?: Set<string>;
}
export class AGRIntelligence_ extends Component<AGRIntelligence_Props> {
    static defaultProps = {
        crops: [],
        seasons: [],
        reportTypes: [],
        reportTypePages: [],
    };

    /// Event Handlers ///
    ///--------------------------///
    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        /// Property set up, etc. ///
        return (
            <div className="reports-container">
                <div className="reports-options-container">
                    <ReportTypes
                        selectReportType={this.props.selectReportType}
                        reportTypes={this.props.reportTypes}
                        selectedReportTypes={this.props.selectedReportTypes}
                        formatMessage={formatMessage}
                    />
                    <ReportOptionsContainer
                        agEventSummaryMap={this.props.agEventSummaryMap}
                        createReport={this.props.createReport}
                        crops={this.props.crops}
                        seasons={this.props.seasons}
                        formatMessage={formatMessage}
                        onInputChange={this.props.onInputChange}
                        recGeneralGuidToRecMap={this.props.recGeneralGuidToRecMap}
                        reportPreferences={this.props.reportPreferences}
                        reportTypePages={this.props.reportTypePages}
                        selectedEventGuidSet={this.props.selectedEventGuidSet}
                        selectedFields={this.props.selectedFields}
                        selectedRecGuidSet={this.props.selectedRecGuidSet}
                        selectedReportTypes={this.props.selectedReportTypeNames}
                    />
                </div>
                <ReportTable
                    createReportHubProgress={this.props.createReportHubProgress}
                    initialRequestOptions={this.props.initialRequestOptions}
                    needs={this.props.needs}
                    onAllReportCreated={this.props.onAllReportCreated}
                    url={ReportAPI.GET_REPORT_LIST_URL}
                    autoSearchUrl={ReportAPI.GET_REPORT_AUTO_SEARCH_URL}
                    selectAllUrl={ReportAPI.GET_REPORT_SELECT_ALL_URL}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    agEventSummaryMap: eventsSelectors.getEventGeneralGuidToEventSummaryMap(state),
    recGeneralGuidToRecMap: recsSelectors.getRecGeneralGuidToRecSummaryMap(state),
});

export const AGRIntelligence = withReport(
    injectIntl(connect<Partial<AGRIntelligence_Props>>(mapStateToProps)(AGRIntelligence_)),
    service
);
