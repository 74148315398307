import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { DialogBox, DialogBoxFooterType } from "~/core";

import { injectIntl, intlShape } from "react-intl";
import { messages } from "./i18n-messages";
import "./attribute-edit-modal.css";

class AttributeEditModal_ extends Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        isOpen: PropTypes.bool.isRequired,
        canSave: PropTypes.bool.isRequired,
        onSaveEdit: PropTypes.func.isRequired,
        onCloseEdit: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        children: PropTypes.node,
        unrestricted: PropTypes.bool,
    };
    static defaultProps = {
        title: messages.editAttributeText.defaultMessage,
        unrestricted: false,
    };

    render() {
        return (
            <DialogBox
                className={classnames("attribute-edit-modal", this.props.className)}
                forceOverflow={false}
                isOpen={this.props.isOpen}
                isModal={true}
                title={this.props.title}
                draggable={true}
                hideCloseX={true}
                actionDisabled={!this.props.canSave}
                onAction={() => this.props.onSaveEdit()}
                onClose={() => this.props.onCloseEdit()}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                unrestricted={this.props.unrestricted}
            >
                {this.props.children}
            </DialogBox>
        );
    }
}

export const AttributeEditModal = injectIntl(AttributeEditModal_);
