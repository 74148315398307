import { messages } from "~/i18n-messages";
import { codeToErrorMessage } from "~/i18n-error-messages";

export const errorsToKeyedDetails = (errors, intl) => {
    return Array.from(
        errors
            .map((error) => {
                const id = (() => {
                    const keys = error.keys !== undefined ? Object.entries(error.keys) : [];

                    let id = null;
                    let integerId = null;
                    let name = null;
                    for (const [key, value] of keys) {
                        const sterilizedKey = () => {
                            if (typeof value === "string") {
                                return value;
                            } else if (typeof value === "number") {
                                return value.toString();
                            } else {
                                return null;
                            }
                        };

                        if (key === "Id") {
                            id = sterilizedKey();
                        } else if (key === "IntegerId") {
                            integerId = sterilizedKey();
                        } else if (key === "Name") {
                            name = sterilizedKey();
                        }
                    }

                    const sterilizedPart = (part, unknownMessage) => {
                        return part !== null && typeof part === "string" && part !== ""
                            ? part
                            : intl.formatMessage(unknownMessage);
                    };

                    id = sterilizedPart(id, messages.unknownId);
                    integerId = sterilizedPart(integerId, messages.unknownIntegerId);
                    name = sterilizedPart(name, messages.unknownName);

                    return `(${integerId}, ${name}, ${id})`;
                })();

                return {
                    id: id,
                    code: error.code,
                };
            })
            .reduce((acc, obj) => {
                const errorMessage = codeToErrorMessage(intl.formatMessage, obj.code);

                const oldValues = acc.get(errorMessage);
                const newValues = !oldValues ? [obj.id] : oldValues.concat([obj.id]);
                acc.set(errorMessage, newValues);

                return acc;
            }, new Map())
    );
};

export const errorsToMessageDetails = (errors, intl) => {
    return errors.map((error) => codeToErrorMessage(intl.formatMessage, error.code));
};

export const newApiResultToErrorDetails = (newApiResults, intl) => {
    const errors = newApiResults.errors || [];
    const deserializationFailureCode = 124;
    const deserializationFailure = errors.find(
        (error) => error.code === deserializationFailureCode
    );
    const details = errorsToMessageDetails(errors, intl);
    let detailHeader;
    if (deserializationFailure) {
        detailHeader = codeToErrorMessage(intl.formatMessage, deserializationFailureCode);
        details.splice(0, 1);
    }
    return {
        details,
        detailHeader,
    };
};

export class CommandStatus {
    static Accepted = 0;
    static Rejected = 1;
    static SystemError = 2;
}

export class MergeOperation {
    static Add = "Add";
    static Update = "Update";
    static Failure = "Failure";
}
