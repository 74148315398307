import * as immutableUtils from "~/utils/immutable";

import { accordionReducer, model as accordionModel } from "~/accordion";

import { getFieldGuidFromDimIdx } from "../../../common/accordion/model";
import { getRecGuidFromDimIdx } from "../../../common/accordion/rec-event-accordion-item";
import * as commonReducer from "../../../common/rec-event-reducer";

import { RecEventListTabs } from "~/recs-events/actions";
import * as actions from "./actions";
import * as selectors from "./selectors";

export const recListInitialState = {
    convertRecsToEventsGuidMap: new Map(),
    exportRecGeneralGuidSet: new Set(),
    isPanelLoading: false,
    searchValue: "",
    showFilters: false,
    isRecLoading: false,

    filter: {
        cropGuid: "",
        onlySelected: false,
        recTypeGuid: "",
        seasonGuid: "",
        activeTab: RecEventListTabs.ACTIVE,
    },
    filterInfo: {
        croppingSeasons: {},
        crops: {},
        filters: {},
        recTypes: {},
    },
    filterOptions: {
        croppingSeasonOptions: [],
        cropOptions: [],
        recTypeOptions: [],
    },
    filterValueCount: "",
    allAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },
    selectedAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },
    inactiveAccordionInit: {
        initScrollTop: 0,
        lastClickedDimIdx: [0],
    },

    errorDetailsToShow: null,
    expandedFieldGuidSet: new Set(),
    fieldGuidToSelectedRecGuidSetMap: new Map(),
    selectedRecGuidSet: new Set(),

    [selectors.ACCORDION_KEY]: accordionReducer(undefined, { type: "INIT" }),
};

export const recListReducer = (state = recListInitialState, action) => {
    switch (action.type) {
        case actions.CLEAR_REC_FILTER: {
            const recFilter = {
                cropGuid: null,
                eventType: null,
                seasonGuid: null,
                recTypeGuid: null,
            };
            const filter = {
                ...state.filter,
                ...recFilter,
            };
            return Object.freeze({
                ...state,
                filter,
                filterValueCount: null,
            });
        }
        case actions.DESELECT_RECS_FOR_FIELD_GUIDS: {
            const { fieldGuidsToRemove } = action.payload;
            const fieldGuidToSelectedRecGuidSetMap = immutableUtils.noMutateMapDeleteKeys(
                state.fieldGuidToSelectedRecGuidSetMap,
                fieldGuidsToRemove
            );
            const deselectRecsGuidList = fieldGuidsToRemove.reduce(
                (deselectRecsGuidList, fieldGuid) => {
                    const selectedRecsGuidSet =
                        state.fieldGuidToSelectedRecGuidSetMap.get(fieldGuid);
                    deselectRecsGuidList.push(...selectedRecsGuidSet);
                    return deselectRecsGuidList;
                },
                []
            );
            const selectedRecGuidSet = immutableUtils.noMutateSetDeleteAll(
                state.selectedRecGuidSet,
                deselectRecsGuidList
            );
            return Object.freeze({
                ...state,
                fieldGuidToSelectedRecGuidSetMap,
                selectedRecGuidSet,
            });
        }
        case actions.SET_CONVERT_RECS_TO_EVENTS_GUID_MAP: {
            const { convertRecsToEventsGuidMap } = action.payload;
            return Object.freeze({
                ...state,
                convertRecsToEventsGuidMap,
            });
        }
        case actions.CLEAR_CONVERT_RECS_TO_EVENTS_GUID_MAP: {
            return Object.freeze({
                ...state,
                convertRecsToEventsGuidMap: new Map(),
            });
        }
        case actions.SET_EXPORT_REC_GENERAL_GUID_SET: {
            return Object.freeze({
                ...state,
                exportRecGeneralGuidSet: new Set(action.payload.recGeneralGuids),
            });
        }
        case actions.CLEAR_EXPORT_REC_GENERAL_GUID_SET: {
            return Object.freeze({
                ...state,
                exportRecGeneralGuidSet: new Set(),
            });
        }
        case actions.SET_REC_FILTER: {
            const { recFilter } = action.payload;
            const filter = {
                ...state.filter,
                ...recFilter,
            };
            let filterValueCount = 0;
            if (filter.seasonGuid) {
                filterValueCount++;
            }
            if (filter.recTypeGuid) {
                filterValueCount++;
            }
            if (filter.cropGuid) {
                filterValueCount++;
            }
            return Object.freeze({
                ...state,
                filter,
                filterValueCount: filterValueCount > 0 ? filterValueCount : null,
            });
        }
        case actions.SET_REC_FILTER_INFO: {
            const { filterInfo } = action.payload;
            return Object.freeze({
                ...state,
                filterInfo,
            });
        }
        case actions.SET_REC_FILTER_OPTIONS: {
            const { filterOptions } = action.payload;
            return Object.freeze({
                ...state,
                filterOptions,
            });
        }
        case actions.SET_SHOW_FILTERS: {
            const { showFilters } = action.payload;
            return Object.freeze({
                ...state,
                showFilters,
            });
        }
        case actions.SET_REC_PANEL_LOADING: {
            const { isPanelLoading } = action.payload;
            return Object.freeze({
                ...state,
                isPanelLoading,
            });
        }
        case actions.SET_IS_REC_LOADING: {
            const { isRecLoading } = action.payload;
            return Object.freeze({
                ...state,
                isRecLoading,
            });
        }
        case actions.SET_LAST_CLICKED_DIMIDX: {
            const { itemDimIdx } = action.payload;
            const newState = { ...state };
            switch (newState.filter.activeTab) {
                case RecEventListTabs.ACTIVE:
                    newState.allAccordionInit = {
                        ...newState.allAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                case RecEventListTabs.INACTIVE:
                    newState.inactiveAccordionInit = {
                        ...newState.inactiveAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                case RecEventListTabs.SELECTED:
                    newState.selectedAccordionInit = {
                        ...newState.selectedAccordionInit,
                        lastClickedDimIdx: itemDimIdx,
                    };
                    break;
                default:
                    throw new Error("unreachable: unknown selected tab");
            }
            return Object.freeze(newState);
        }
        case actions.SET_INIT_SCROLL_TOP: {
            const { scrollTop, activeTab } = action.payload;
            const newState = { ...state };
            switch (activeTab) {
                case RecEventListTabs.ACTIVE:
                    newState.allAccordionInit = {
                        ...newState.allAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                case RecEventListTabs.INACTIVE:
                    newState.inactiveAccordionInit = {
                        ...newState.inactiveAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                case RecEventListTabs.SELECTED:
                    newState.selectedAccordionInit = {
                        ...newState.selectedAccordionInit,
                        initScrollTop: scrollTop,
                    };
                    break;
                default:
                    throw new Error("unreachable: unknown selected tab");
            }
            return Object.freeze(newState);
        }
        case actions.SHOW_ERROR_DETAILS: {
            const { recGeneralGuid } = action.payload.recSummary;
            return Object.freeze({
                ...state,
                errorDetailsToShow: recGeneralGuid,
            });
        }
        case actions.HIDE_ERROR_DETAILS: {
            return Object.freeze({
                ...state,
                errorDetailsToShow: null,
            });
        }
        case actions.UPDATE_EXPANDED_FIELD_GUID_SET: {
            const { fieldGuid, isExpanded } = action.payload;
            const expandedFieldGuidSet = immutableUtils.noMutateSetChange(
                isExpanded,
                state.expandedFieldGuidSet,
                fieldGuid
            );
            return Object.freeze({
                ...state,
                expandedFieldGuidSet,
            });
        }
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        case actions.SELECT_RECS_FROM_ACCORDION:
        case actions.DESELECT_RECS_FROM_ACCORDION:
        case actions.DESELECT_RECS_FROM_DIMIDX_LIST: {
            let dimIdxIter;
            if (action.type === actions.DESELECT_RECS_FROM_DIMIDX_LIST) {
                dimIdxIter = action.payload.toDeselectDimIdxList;
            } else {
                const { startDimIdx, endDimIdx } = action.payload;
                dimIdxIter = accordionModel.getDimIdxSliceIter(
                    state[selectors.ACCORDION_KEY].items,
                    startDimIdx,
                    endDimIdx,
                    Boolean(endDimIdx),
                    [2]
                );
            }
            const [selectedRecGuidSet, fieldGuidToSelectedRecGuidSetMap] =
                commonReducer.updateSelectedItemGuidSet(
                    dimIdxIter,
                    (dimIdx) =>
                        getFieldGuidFromDimIdx(state[selectors.ACCORDION_KEY].items, dimIdx),
                    (dimIdx) => getRecGuidFromDimIdx(state[selectors.ACCORDION_KEY].items, dimIdx),
                    state.selectedRecGuidSet,
                    state.fieldGuidToSelectedRecGuidSetMap,
                    action.type === actions.SELECT_RECS_FROM_ACCORDION
                        ? immutableUtils.noMutateSetAddAll
                        : immutableUtils.noMutateSetDeleteAll
                );

            return Object.freeze({
                ...state,
                fieldGuidToSelectedRecGuidSetMap,
                selectedRecGuidSet,
            });
        }
        default: {
            const accordionState = accordionReducer(state[selectors.ACCORDION_KEY], action);
            if (accordionState === state[selectors.ACCORDION_KEY]) {
                return state;
            }
            return Object.freeze({
                ...state,
                [selectors.ACCORDION_KEY]: accordionState,
            });
        }
    }
};
