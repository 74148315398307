import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";

// constants
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import { adminData, GUID, ID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
// Components
import {
    Checkbox,
    NumericInput,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import PhotoList from "./../../components/photo-list";
import AliasList from "./../../components/alias-list";
//Style
import "./add-edit-panel.css";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.equipment,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.equipment = {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupEquipmentRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([this.props.getNextId(), this.props.fetchPicklistData(), this.props.fetchUnitData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.equipment);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.equipment = nextProps.record;
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.equipment[model.PROPS_EQUIPMENT_ID] = nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupEquipmentRecord = () => {
        const { addEditPanel } = this.props;
        this.equipment = {};
        if (addEditPanel.mode === "ADD") {
            this.equipment = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeEquipmentParentType(nextProps);
        this.initializeEquipmentType(nextProps);
        this.initializeManufacturer(nextProps);
        this.initializeMaxSpeedUnits(nextProps);
        this.initializeFuelCapacity(nextProps);
        this.initializeContainerSize(nextProps);
        this.initializeDriveTrain(nextProps);
        this.initializeTransmissionType(nextProps);
        this.initializeFuelEfficiency(nextProps);
        this.initializeHorsePower(nextProps);
        this.initializeFuelType(nextProps);
        this.initializePhysicalState(nextProps);
        this.initializeWeightUnit(nextProps);
        this.initializeWeightTowingCapacityUnit(nextProps);
        this.initializeLength(nextProps);
        this.initializeClassSize(nextProps);
    };

    initializeEquipmentParentType = (nextProps) => {
        if (
            this.equipment[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID]
                ),
            });
        }
    };

    initializeEquipmentType = (nextProps) => {
        if (
            this.equipment[model.PROPS_EQUIPMENT_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]
        ) {
            this.setState({
                [picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_EQUIPMENT_TYPE_GUID]
                ),
            });
        }
    };

    initializeManufacturer = (nextProps) => {
        if (
            this.equipment[model.PROPS_MANUFACTURER_GUID] != null &&
            nextProps[picklistService.PICKLIST_MANUFACTURER]
        ) {
            this.setState({
                [picklistService.PICKLIST_MANUFACTURER]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_MANUFACTURER],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_MANUFACTURER_GUID]
                ),
            });
        }
    };

    initializeMaxSpeedUnits = (nextProps) => {
        if (
            this.equipment[model.PROPS_MAX_SPEED_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_SPEED]
        ) {
            this.setState({
                [unitService.UNIT_SPEED]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_SPEED],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_MAX_SPEED_UNIT_GUID]
                ),
            });
        }
    };

    initializeFuelCapacity = (nextProps) => {
        if (
            this.equipment[model.PROPS_FUEL_CAPACITY_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_VOLUME]
        ) {
            this.setState({
                [unitService.UNIT_VOLUME]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_FUEL_CAPACITY_UNIT_GUID]
                ),
            });
        }
    };

    initializeContainerSize = (nextProps) => {
        if (
            this.equipment[model.PROPS_CONTAINER_SIZE_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_VOLUME]
        ) {
            this.setState({
                [model.PROPS_CONTAINER_SIZE_UNIT]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_VOLUME],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_CONTAINER_SIZE_UNIT_GUID]
                ),
            });
        }
    };

    initializeDriveTrain = (nextProps) => {
        if (
            this.equipment[model.PROPS_DRIVE_TRAIN_GUID] != null &&
            nextProps[picklistService.PICKLIST_DRIVE_TRAIN]
        ) {
            this.setState({
                [picklistService.PICKLIST_DRIVE_TRAIN]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_DRIVE_TRAIN],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_DRIVE_TRAIN_GUID]
                ),
            });
        }
    };

    initializeTransmissionType = (nextProps) => {
        if (
            this.equipment[model.PROPS_TRANSMISSION_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_TRANSMISSION_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_TRANSMISSION_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_TRANSMISSION_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_TRANSMISSION_TYPE_GUID]
                ),
            });
        }
    };

    initializeFuelEfficiency = (nextProps) => {
        if (
            this.equipment[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_FUEL_EFFICIENCY]
        ) {
            this.setState({
                [unitService.UNIT_FUEL_EFFICIENCY]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_FUEL_EFFICIENCY],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID]
                ),
            });
        }
    };

    initializeHorsePower = (nextProps) => {
        if (
            this.equipment[model.PROPS_HORSE_POWER_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_POWER]
        ) {
            this.setState({
                [unitService.UNIT_POWER]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_POWER],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_HORSE_POWER_UNIT_GUID]
                ),
            });
        }
    };

    initializeFuelType = (nextProps) => {
        if (
            this.equipment[model.PROPS_FUEL_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_FUEL_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_FUEL_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_FUEL_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_FUEL_TYPE_GUID]
                ),
            });
        }
    };

    initializePhysicalState = (nextProps) => {
        if (
            this.equipment[model.PROPS_PHYSICAL_STATE_GUID] != null &&
            nextProps[picklistService.PICKLIST_PHYSICAL_STATE]
        ) {
            this.setState({
                [picklistService.PICKLIST_PHYSICAL_STATE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_PHYSICAL_STATE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_PHYSICAL_STATE_GUID]
                ),
            });
        }
    };

    initializeWeightUnit = (nextProps) => {
        if (
            this.equipment[model.PROPS_WEIGHT_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_WEIGHT]
        ) {
            this.setState({
                [unitService.UNIT_WEIGHT]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_WEIGHT],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_WEIGHT_UNIT_GUID]
                ),
            });
        }
    };

    initializeWeightTowingCapacityUnit = (nextProps) => {
        if (
            this.equipment[model.PROPS_TOWING_CAPACITY_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_WEIGHT]
        ) {
            this.setState({
                [model.PROPS_TOWING_CAPACITY_UNIT]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_WEIGHT],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_TOWING_CAPACITY_UNIT_GUID]
                ),
            });
        }
    };

    initializeLength = (nextProps) => {
        if (
            this.equipment[model.PROPS_DIMENSION_UNIT_GUID] != null &&
            nextProps[unitService.UNIT_LENGTH]
        ) {
            this.setState({
                [unitService.UNIT_LENGTH]: prepareSelectableOptions(
                    nextProps[unitService.UNIT_LENGTH],
                    { guid: GUID, label: NAME, id: ID },
                    this.equipment[model.PROPS_DIMENSION_UNIT_GUID]
                ),
            });
        }
    };

    initializeClassSize = (nextProps) => {
        if (
            this.equipment[model.PROPS_CLASS_SIZE_GUID] != null &&
            nextProps[picklistService.PICKLIST_CLASS_SIZE]
        ) {
            this.setState({
                [picklistService.PICKLIST_CLASS_SIZE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_CLASS_SIZE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.equipment[model.PROPS_CLASS_SIZE_GUID]
                ),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.equipment = handlePicklistChange(this.equipment, { type, guid, value }, callback);
    };

    onPhysicalStateChange = ({ guid }, value) => {
        if (guid && value) {
            this.equipment[guid] = value.guid;
        } else {
            console.error(`${guid} / ${value} value is null`);
        }
    };

    onTextChange = (formKey, value, callback) => {
        this.equipment = onTextChange(this.equipment, { formKey: [formKey], value }, callback);
    };

    onNumberChange = (formKey, value, callback) => {
        this.equipment = onNumberChange(this.equipment, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.equipment[model.PROPS_EQUIPMENT_NAME]]);
    };

    onEquipmentParentChange = () => {
        const picklistKey = picklistService.getPickListCode(
            picklistService.PICKLIST_EQUIPMENT_TYPE_NAME
        );
        this.props.needs([
            this.props.fetchPicklistData({
                url: picklistService.URL_GET_PICKLIST_CHILD_VALUE_LIST,
                picklistKey,
                model: {
                    parentPicklistName: "Equipment Parent Type",
                    picklistName: "Equipment Type Name",
                    picklistParentGuid: this.equipment[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                },
                forcedRequest: true,
            }),
        ]);
    };

    renderEquipmentInfo = () => {
        const { apiErrors, userRole } = this.props;
        const { formatMessage } = this.props.intl;
        const { equipment } = this;
        const { nextId } = this.state;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={["form-input-id"]}
                            placeholderText={formatMessage(messages.equipmentId)}
                            labelText={formatMessage(messages.equipmentId)}
                            value={nextId || equipment[model.PROPS_EQUIPMENT_ID]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_EQUIPMENT_ID, value)
                            }
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={equipment[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(211, apiErrors)}
                            autoFocus
                            openOnFocus={false}
                            required
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE]}
                            placeholderText={formatMessage(messages.equipmentParentType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_EQUIPMENT_PARENT_TYPE],
                                selectedGuid: equipment[model.PROPS_EQUIPMENT_PARENT_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_EQUIPMENT_PARENT_TYPE,
                                        guid: model.PROPS_EQUIPMENT_PARENT_TYPE_GUID,
                                    },
                                    value
                                );
                                this.onEquipmentParentChange();
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            required
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            containerClassNames={getAgBytesErrorClassNames(213, apiErrors)}
                            disabled={!equipment[model.PROPS_EQUIPMENT_PARENT_TYPE]}
                            options={this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME]}
                            placeholderText={formatMessage(messages.equipmentType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_EQUIPMENT_TYPE_NAME],
                                selectedGuid: equipment[model.PROPS_EQUIPMENT_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_EQUIPMENT_TYPE,
                                        guid: model.PROPS_EQUIPMENT_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section className="equipment-section">
                    <SubSection className="form-section-row-2">
                        <SelectInput
                            tabIndex={0}
                            required
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            containerClassNames={getAgBytesErrorClassNames(343, apiErrors)}
                            options={this.state[picklistService.PICKLIST_MANUFACTURER]}
                            placeholderText={formatMessage(messages.manufacturerName)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_MANUFACTURER],
                                selectedGuid: equipment[model.PROPS_MANUFACTURER_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_MANUFACTURER,
                                        guid: model.PROPS_MANUFACTURER_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={[
                                this.equipment[model.PROPS_EQUIPMENT_NAME],
                                ...getAgBytesErrorClassNames(382, apiErrors),
                            ]}
                            placeholderText={formatMessage(messages.modelName)}
                            labelText={formatMessage(messages.modelName)}
                            value={equipment[model.PROPS_EQUIPMENT_NAME]}
                            required
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_EQUIPMENT_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderDetailInfo1 = () => {
        const { apiErrors } = this.props;
        const { formatMessage } = this.props.intl;
        const { equipment } = this;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.fuelPerformanceInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(401, apiErrors)}
                            placeholderText={formatMessage(messages.maxSpeed)}
                            labelText={formatMessage(messages.maxSpeed)}
                            value={equipment[model.PROPS_MAX_SPEED] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) => this.onNumberChange(model.PROPS_MAX_SPEED, value)}
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_SPEED]}
                            placeholderText={formatMessage(messages.maxSpeedUnit)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_SPEED],
                                selectedGuid: equipment[model.PROPS_MAX_SPEED_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_MAX_SPEED_UNIT,
                                        guid: model.PROPS_MAX_SPEED_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.fuelCapacity)}
                            labelText={formatMessage(messages.fuelCapacity)}
                            value={equipment[model.PROPS_FUEL_CAPACITY] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_FUEL_CAPACITY, value)
                            }
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_VOLUME]}
                            placeholderText={formatMessage(messages.fuelCapacityUnit)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_VOLUME],
                                selectedGuid: equipment[model.PROPS_FUEL_CAPACITY_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_FUEL_CAPACITY_UNIT,
                                        guid: model.PROPS_FUEL_CAPACITY_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_DRIVE_TRAIN]}
                            placeholderText={formatMessage(messages.drivetrain)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_DRIVE_TRAIN],
                                selectedGuid: equipment[model.PROPS_DRIVE_TRAIN_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_DRIVE_TRAIN,
                                        guid: model.PROPS_DRIVE_TRAIN_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_TRANSMISSION_TYPE]}
                            placeholderText={formatMessage(messages.transmissionType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_TRANSMISSION_TYPE],
                                selectedGuid: equipment[model.PROPS_TRANSMISSION_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_TRANSMISSION_TYPE,
                                        guid: model.PROPS_TRANSMISSION_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.fuelEfficiency)}
                            labelText={formatMessage(messages.fuelEfficiency)}
                            value={equipment[model.PROPS_FUEL_EFFICIENCY] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_FUEL_EFFICIENCY, value)
                            }
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            containerClassNames={getAgBytesErrorClassNames(403, apiErrors)}
                            options={this.state[unitService.UNIT_FUEL_EFFICIENCY]}
                            placeholderText={formatMessage(messages.fuelEfficiencyUnit)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_FUEL_EFFICIENCY],
                                selectedGuid: equipment[model.PROPS_FUEL_EFFICIENCY_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_FUEL_EFFICIENCY_UNIT,
                                        guid: model.PROPS_FUEL_EFFICIENCY_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.horsePower)}
                            labelText={formatMessage(messages.horsePower)}
                            value={equipment[model.PROPS_HORSE_POWER] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_HORSE_POWER, value)
                            }
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_POWER]}
                            containerClassNames={getAgBytesErrorClassNames(404, apiErrors)}
                            placeholderText={formatMessage(messages.horsePowerUnit)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_POWER],
                                selectedGuid: equipment[model.PROPS_HORSE_POWER_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_HORSE_POWER_UNIT,
                                        guid: model.PROPS_HORSE_POWER_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_FUEL_TYPE]}
                            placeholderText={formatMessage(messages.fuelType)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_FUEL_TYPE],
                                selectedGuid: equipment[model.PROPS_FUEL_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_FUEL_TYPE,
                                        guid: model.PROPS_FUEL_TYPE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.cylinders)}
                            labelText={formatMessage(messages.cylinders)}
                            value={equipment[model.PROPS_CYLINDERS] || ""}
                            precision={4}
                            scale={0}
                            onChange={(value) => this.onNumberChange(model.PROPS_CYLINDERS, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.engineDisplacement)}
                            labelText={formatMessage(messages.engineDisplacement)}
                            value={equipment[model.PROPS_ENGINE_DISPLACEMENT] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_ENGINE_DISPLACEMENT, value)
                            }
                        />
                    </SubSection>
                </Section>
                <Section headerText={formatMessage(messages.containerInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.containerSize)}
                            labelText={formatMessage(messages.containerSize)}
                            value={equipment[model.PROPS_CONTAINER_SIZE] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_CONTAINER_SIZE, value)
                            }
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_CONTAINER_SIZE_UNIT]}
                            containerClassNames={getAgBytesErrorClassNames(406, apiErrors)}
                            placeholderText={formatMessage(messages.containerSizeUnit)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_CONTAINER_SIZE_UNIT],
                                selectedGuid: equipment[model.PROPS_CONTAINER_SIZE_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_CONTAINER_SIZE_UNIT,
                                        guid: model.PROPS_CONTAINER_SIZE_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_PHYSICAL_STATE]}
                            placeholderText={formatMessage(messages.physicalState)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_PHYSICAL_STATE],
                                selectedGuid: equipment[model.PROPS_PHYSICAL_STATE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        guid: model.PROPS_PHYSICAL_STATE_GUID,
                                    },
                                    value,
                                    this.onPhysicalStateChange(
                                        {
                                            guid: model.PROPS_PHYSICAL_STATE_GUID,
                                        },
                                        value
                                    )
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    renderDetailInfo2 = () => {
        const { apiErrors } = this.props;
        const { formatMessage } = this.props.intl;
        const { equipment } = this;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.weightInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.maxGrossWeight)}
                            labelText={formatMessage(messages.maxGrossWeight)}
                            value={equipment[model.PROPS_MAX_GROSS_WEIGHT] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_MAX_GROSS_WEIGHT, value)
                            }
                        />
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.tareWeight)}
                            labelText={formatMessage(messages.tareWeight)}
                            value={equipment[model.PROPS_TARE_WEIGHT] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_TARE_WEIGHT, value)
                            }
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_WEIGHT]}
                            placeholderText={formatMessage(messages.weightUnit)}
                            containerClassNames={getAgBytesErrorClassNames(407, apiErrors)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_WEIGHT],
                                selectedGuid: equipment[model.PROPS_WEIGHT_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_WEIGHT_UNIT,
                                        guid: model.PROPS_WEIGHT_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
                <Section headerText={formatMessage(messages.sizeInfo)}>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.width)}
                            labelText={formatMessage(messages.width)}
                            value={equipment[model.PROPS_WIDTH] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) => this.onNumberChange(model.PROPS_WIDTH, value)}
                        />
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.length)}
                            labelText={formatMessage(messages.length)}
                            value={equipment[model.PROPS_LENGTH] || ""}
                            precision={9}
                            scale={2}
                            onChange={(value) => this.onNumberChange(model.PROPS_LENGTH, value)}
                        />
                    </SubSection>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            placeholderText={formatMessage(messages.height)}
                            labelText={formatMessage(messages.height)}
                            value={equipment[model.PROPS_HEIGHT] || ""}
                            precision={8}
                            scale={2}
                            onChange={(value) => this.onNumberChange(model.PROPS_HEIGHT, value)}
                        />
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[unitService.UNIT_LENGTH]}
                            placeholderText={formatMessage(messages.dimensionUnit)}
                            containerClassNames={getAgBytesErrorClassNames(407, apiErrors)}
                            value={mapToPicklistValue({
                                options: this.state[unitService.UNIT_LENGTH],
                                selectedGuid: equipment[model.PROPS_DIMENSION_UNIT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_DIMENSION_UNIT,
                                        guid: model.PROPS_DIMENSION_UNIT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_CLASS_SIZE]}
                            placeholderText={formatMessage(messages.classSize)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_CLASS_SIZE],
                                selectedGuid: equipment[model.PROPS_CLASS_SIZE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_CLASS_SIZE,
                                        guid: model.PROPS_CLASS_SIZE_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <Section headerText={formatMessage(messages.towingInfo)}>
                        <SubSection>
                            <NumericInput
                                tabIndex={0}
                                placeholderText={formatMessage(messages.towingCapacity)}
                                labelText={formatMessage(messages.towingCapacity)}
                                value={equipment[model.PROPS_TOWING_CAPACITY] || ""}
                                precision={9}
                                scale={2}
                                onChange={(value) =>
                                    this.onNumberChange(model.PROPS_TOWING_CAPACITY, value)
                                }
                            />
                            <SelectInput
                                tabIndex={0}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[model.PROPS_TOWING_CAPACITY_UNIT]}
                                placeholderText={formatMessage(messages.towingCapacityUnit)}
                                containerClassNames={getAgBytesErrorClassNames(409, apiErrors)}
                                value={mapToPicklistValue({
                                    options: this.state[model.PROPS_TOWING_CAPACITY_UNIT],
                                    selectedGuid: equipment[model.PROPS_TOWING_CAPACITY_UNIT_GUID],
                                })}
                                onChange={(value) => {
                                    this.onPicklistChange(
                                        {
                                            type: model.PROPS_TOWING_CAPACITY_UNIT,
                                            guid: model.PROPS_TOWING_CAPACITY_UNIT_GUID,
                                        },
                                        value
                                    );
                                }}
                            />
                        </SubSection>
                    </Section>
                </Section>
            </div>
        );
    };

    renderDetailInfo3 = () => {
        const { formatMessage } = this.props.intl;
        const { equipment } = this;
        return (
            <div className="section-container section-column">
                <Section headerText={formatMessage(messages.miscellaneousInfo)}>
                    <SubSection>
                        <TextInput
                            maxLength={50}
                            tabIndex={0}
                            placeholderText={formatMessage(messages.agGatewayID)}
                            labelText={formatMessage(messages.agGatewayID)}
                            value={equipment[model.PROPS_AGGATEWAY_ID]}
                            onChange={(value) => this.onTextChange(model.PROPS_AGGATEWAY_ID, value)}
                        />
                    </SubSection>
                    <SubSection className="form-section-child-stretch">
                        <TextArea
                            tabIndex={0}
                            placeholderText={formatMessage(messages.description)}
                            value={equipment[model.PROPS_DESCRIPTION]}
                            onChange={(value) => this.onTextChange(model.PROPS_DESCRIPTION, value)}
                        />
                    </SubSection>
                </Section>
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.equipmentPhotos)}
                >
                    <SubSection>
                        <PhotoList
                            formatMessage={formatMessage}
                            record={equipment[model.PROPS_EQUIPMENT_PHOTOLIST]}
                            itemListAlias={model.PROPS_EQUIPMENT_PHOTOLIST}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.equipmentTypeAlias)}
                >
                    <SubSection>
                        <AliasList
                            formatMessage={formatMessage}
                            aliasTextLabel="equipmentTypeAlias"
                            record={equipment[model.PROPS_EQUIPMENT_TYPE_ALIASLIST]}
                            itemListAlias={model.PROPS_EQUIPMENT_TYPE_ALIASLIST}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        return (
            <div className="add-edit-panel">
                {this.renderEquipmentInfo()}
                <div className="section-container">
                    {this.renderDetailInfo1()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo2()}
                    <span className="bar section-fence"></span>
                    {this.renderDetailInfo3()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
