import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";

import { connect } from "react-redux";
import classNames from "classnames";

import { Loader, SelectInput } from "~/core";
import { selectors as cdSelectors } from "~/customer-data";

import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { eventsSelectors } from "~/recs-events";

import { ModuleFiltersToggle, ModuleFilters } from "../../../../module-filters";
import { ModuleSearch } from "../../../../module-search";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { previewMergeEvents, mergeEvents } from "../../event-info/actions";

import { EventFilterTabs } from "./event-filter-tabs/event-filter-tabs";
import { EventAccordion } from "./event-accordion/event-accordion";
import "./event-list.css";

import { YieldCalibrationModal } from "./dialog-boxes/yield-calibration-modal";
import { MergeEventsModal } from "./dialog-boxes/merge-events-modal";
import { ErrorsModal } from "./dialog-boxes/errors-modal";
import { ErrorDetailsDialog } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";

import { MergeableEventType } from "~/data/mergeable-events";

import { getErrorMessages } from "~/i18n-error-messages";
import { LockIcon } from "~/core/icons";
import { getTheUserLockCustomer } from "~/login/selectors";

const messages = defineMessages({
    cropPlaceholder: {
        id: "eventList.filterTabs.cropPlaceholder",
        defaultMessage: "Crop",
    },
    seasonPlaceholder: {
        id: "eventList.filterTabs.seasonPlaceholder",
        defaultMessage: "Season",
    },
    eventTypePlaceholder: {
        id: "eventList.filterTabs.eventTypePlaceholder",
        defaultMessage: "Event Type",
    },
    allFieldsLocked: {
        id: "eventList.allFieldsLocked",
        defaultMessage: "All selected Fields are not Enrolled",
    },
});

const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;

interface IProps {
    errorCodes: any;
    errorDetailsToShow: any;
    hideErrorDetails: () => void;
    hideErrors: () => void;
    eventGeneralGuidToEventMap: any;
    mergeableEvents: any;
    eventFilter: any;
    eventFilterOptions: any;
    filterValueCount: string;
    intl: any;
    isPanelLoading: boolean;
    isLockIconShowing: boolean;
    onFetchPicklists: () => void;
    onMergeEventsModalConfirm: (mergeableEvents: any) => void;
    onMergeEventsModalCancel: () => void;
    onToggleFilters: () => void;
    picklistOptionsCroppingSeason: any[];
    searchValue: string;
    setEventFilter: (filter: any) => void;
    showFilters: boolean;
    showYieldCalibrationModal: boolean;
    showMergeEventsModal: boolean;
    yieldCalibrationModalClose: () => void;
    isEventLoading: boolean;
    updateSearchValue: (value: any) => void;
}

interface IState {
    lastSearchValue: string | null;
}

class EventList_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            lastSearchValue: null,
        };
    }

    private onSearchChange(newValue: any) {
        if (this.state.lastSearchValue !== newValue) {
            this.setState(
                {
                    lastSearchValue: newValue,
                },
                () => this.props.updateSearchValue(newValue)
            );
        }
    }

    private mergeEventsConfirmed(mergeableEvents: any) {
        this.props.onMergeEventsModalConfirm(mergeableEvents);
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            intl,
            eventFilter,
            isPanelLoading,
            isLockIconShowing,
            errorCodes,
            errorDetailsToShow,
            eventFilterOptions,
            filterValueCount,
            mergeableEvents,
            onMergeEventsModalCancel,
            onToggleFilters,
            setEventFilter,
            searchValue,
            showFilters,
            showYieldCalibrationModal,
            showMergeEventsModal,
            yieldCalibrationModalClose,
            isEventLoading,
        } = this.props;

        const errors = errorCodes
            ? getErrorMessages(intl.formatMessage, { errorCodeList: errorCodes }, "<br/>")
            : null;

        const accordion = (
            <div
                className={classNames("pane-content", {
                    "pane-content-loader": isPanelLoading,
                })}
            >
                {isPanelLoading ? (
                    <Loader />
                ) : isLockIconShowing ? (
                    <LockIcon className="lock-icon" />
                ) : (
                    <EventAccordion />
                )}
                {!isPanelLoading && isLockIconShowing ? (
                    <div className="lock-icon-text">{formatMessage(messages.allFieldsLocked)}</div>
                ) : null}
            </div>
        );

        const filterInputEls = [
            <SelectInput
                key={0}
                options={eventFilterOptions.croppingSeasonOptions}
                placeholderText={formatMessage(messages.seasonPlaceholder)}
                onChange={(seasonGuid) => setEventFilter({ seasonGuid })}
                value={eventFilter.seasonGuid}
            />,
            <SelectInput
                key={1}
                options={eventFilterOptions.eventTypeOptions}
                placeholderText={formatMessage(messages.eventTypePlaceholder)}
                onChange={(eventType) => setEventFilter({ eventType })}
                value={eventFilter.eventType}
            />,
            <SelectInput
                key={2}
                options={eventFilterOptions.cropOptions}
                placeholderText={formatMessage(messages.cropPlaceholder)}
                onChange={(cropGuid) => setEventFilter({ cropGuid })}
                value={eventFilter.cropGuid}
            />,
        ];

        const yieldCalibrationModalProps = {
            isOpen: true,
            onClose: yieldCalibrationModalClose,
        };

        const errorDetailsDialogProps = {
            onClose: () => this.props.hideErrorDetails(),
            correlationId: errorDetailsToShow,
            logType: "createevent",
        };

        return (
            <div className={classNames("event-list")}>
                {!isEventLoading ? null : <Loader className="module-loader" />}
                <div className="module-filter-row">
                    <ModuleSearch
                        searchValue={searchValue}
                        onSearchChange={this.onSearchChange.bind(this)}
                    />
                    <ModuleFiltersToggle
                        filterValueCount={filterValueCount}
                        filterVisible={showFilters}
                        onToggleFilters={onToggleFilters}
                        showFilterValueCount={true}
                    />
                </div>
                {!showFilters ? null : (
                    <div className="module-filter-row module-filters">
                        <ModuleFilters>{filterInputEls}</ModuleFilters>
                    </div>
                )}

                <EventFilterTabs>{accordion}</EventFilterTabs>
                {!showYieldCalibrationModal ? null : (
                    <YieldCalibrationModal {...yieldCalibrationModalProps} />
                )}
                {!showMergeEventsModal ? null : (
                    <MergeEventsModal
                        className="test class"
                        onAction={this.mergeEventsConfirmed.bind(this)}
                        onClose={onMergeEventsModalCancel}
                        formatMessage={formatMessage}
                        mergeableEvents={mergeableEvents}
                    />
                )}
                {!errorDetailsToShow ? null : <ErrorDetailsDialog {...errorDetailsDialogProps} />}
                {!errors ? null : (
                    <ErrorsModal
                        intl={intl}
                        errors={errors}
                        onExit={() => this.props.hideErrors()}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const eventModuleState = selectors.getModuleState(state);
    const eventGeneralGuidToEventMap = eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);

    const selectedFieldGuids = cdSelectors.getSelectedFieldGuids(state);
    const lockCustomersNotEnrolled = getTheUserLockCustomer(state);
    const eventSummaryForLastSelectedField = [...eventGeneralGuidToEventMap.values()].find(
        (x) => x.fieldGuid === selectedFieldGuids.last()
    );
    const showLockIcon =
        lockCustomersNotEnrolled &&
        selectedFieldGuids.count() > 0 &&
        eventSummaryForLastSelectedField != null &&
        !eventSummaryForLastSelectedField.customerEnrolled;

    return {
        eventFilter: eventModuleState.filter,
        eventFilterOptions: eventModuleState.filterOptions,
        eventGeneralGuidToEventMap,
        filterValueCount: eventModuleState.filterValueCount,
        mergeableEvents: eventModuleState.mergeableEvents,
        isPanelLoading: eventModuleState.isPanelLoading,
        isEventLoading: eventModuleState.isEventLoading,
        isLockIconShowing: showLockIcon,
        picklistOptionsCroppingSeason: picklistSelectors.getPicklistOptionsFromCode(
            state,
            getPickListCode(PICKLIST_CROPPING_SEASON)
        ),
        showFilters: selectors.getShowFilters(state),
        searchValue: selectors.getSearchValue(state),
        showYieldCalibrationModal: eventModuleState.showYieldCalibrationModal,
        showMergeEventsModal: eventModuleState.showMergeEventsModal,
        errorCodes: selectors.getErrorCodes(state),
        errorDetailsToShow: selectors.getErrorToShow(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventFilter: (filter) => dispatch(actions.setEventFilter(filter)),
        onFetchPicklists: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
        yieldCalibrationModalClose: () => dispatch(actions.showHideYieldCalibrationModal(false)),
        previewMergeEvents: (primaryEventSummary) =>
            dispatch(previewMergeEvents(primaryEventSummary)),
        mergeEvents: (mergeableEvents) => dispatch(mergeEvents(mergeableEvents)),
        onMergeEventsModalCancel: () => {
            dispatch(actions.setMergeableEvents(null));
            dispatch(actions.hideMergeModal());
        },
        onToggleFilters: (showFilters) => dispatch(actions.setShowFilters(showFilters)),
        hideMergeModal: () => dispatch(actions.hideMergeModal()),
        hideErrors: () => dispatch(actions.hideErrors()),
        hideErrorDetails: () => dispatch(actions.hideErrorDetails()),
        updateSearchValue: (newSearchValue) => dispatch(actions.updateSearchValue(newSearchValue)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onMergeEventsModalConfirm: (mergeableEvents) => {
            switch (mergeableEvents.type) {
                case MergeableEventType.Application:
                    dispatchProps.mergeEvents(mergeableEvents);
                    break;
                case MergeableEventType.Soil: {
                    const mergeIntoGeneralGuid = mergeableEvents.mergeInto.agEventGeneralGuid;
                    const primaryEventSummary =
                        stateProps.eventGeneralGuidToEventMap.get(mergeIntoGeneralGuid);
                    dispatchProps.previewMergeEvents(primaryEventSummary);
                    break;
                }
                default:
                    throw new Error("Unknown mergeable event type.");
            }

            dispatchProps.hideMergeModal();
        },
    };
};

export const EventList = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventList_));
