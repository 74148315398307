import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
    getIsLoading,
    getCurrentFieldData,
} from "~/action-panel/components/import-module/components/import-wizard/selectors";
import { mapToolsActions, mapToolsSelectors } from "~/map";

import * as actions from "../../../actions";
import { AddEditFieldModal_ } from "../components/add-edit-field-modal";
import { getSystemAreaLimit } from "~/login";
import { getTheUserGuid, getTheUserLockCustomer } from "~/login/selectors";

const mapStateToProps = (state) => ({
    currentFieldData: getCurrentFieldData(state),
    drawToolFlags: mapToolsSelectors.getDrawToolFlags(state),
    fieldEditGeometries: mapToolsSelectors.getFieldEditGeometries(state),
    fieldEditsChanged: mapToolsSelectors.getFieldEditsChanged(state),
    isLoading: getIsLoading(state),
    isLockCustomer: getTheUserLockCustomer(state),
    maximumArea: getSystemAreaLimit(state),
    userGuid: getTheUserGuid(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentFieldData: (fieldGuid) => dispatch(actions.fetchCurrentFieldData(fieldGuid)),
    setIsLoading: (isLoading) => dispatch(actions.setIsLoading(isLoading)),
    setActiveToolset: (toolset, payload) =>
        dispatch(mapToolsActions.setActiveToolset(toolset, payload)),
    setActiveToolsetPayloadOnly: (payload) =>
        dispatch(mapToolsActions.setActiveToolsetPayloadOnly(payload)),
    setToolsetDisabled: (isDisabled) => dispatch(mapToolsActions.setToolsetDisabled(isDisabled)),
});
export const AddEditFieldModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AddEditFieldModal_));
