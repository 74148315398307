import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Checkbox } from "~/core";
import { StandardLayerContextMenu } from "../../context-menus/standard-layer-context-menu";

class DynamicLayer_ extends Component {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        intl: intlShape.isRequired,
        label: PropTypes.string.isRequired,
        legend: PropTypes.array.isRequired,
        mapScale: PropTypes.number.isRequired,
        maxScale: PropTypes.number.isRequired,
        minScale: PropTypes.number.isRequired,
        onChecked: PropTypes.func.isRequired,
        onLayerPreferences: PropTypes.func.isRequired,
    };

    _isLayerVisible() {
        const { mapScale, maxScale, minScale } = this.props;
        return (!maxScale || mapScale >= maxScale) && (!minScale || mapScale <= minScale);
    }

    _onClick(evt) {
        if (evt && evt.isDefaultPrevented()) {
            return;
        }

        if (this._isLayerVisible()) {
            this.props.onChecked({
                ...evt,
                checked: !this.props.checked,
            });
        }
        evt.preventDefault();
    }

    render() {
        const { checked, label, legend, onLayerPreferences } = this.props;

        let src = "";
        if (legend.length) {
            const { contentType, imageData } = legend[0];
            src = `data:${contentType};base64,${imageData}`;
        }

        const disabled = !this._isLayerVisible();

        return (
            <div className="dynamic-layer" onClick={(evt) => this._onClick(evt)}>
                <Checkbox
                    disabled={disabled}
                    onChange={(evt) => this._onClick(evt)}
                    value={checked}
                />
                <img alt="" className="legend-icon" src={src} />
                <div className={classnames("legend-label", { disabled })}>{label}</div>
                <StandardLayerContextMenu onLayerPreferences={() => onLayerPreferences()} />
            </div>
        );
    }
}

export const DynamicLayer = injectIntl(DynamicLayer_);
