import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { actions as accordionActions } from "~/accordion";
import { config as intlConfig } from "~/intl-provider/config";
import { Loader, LoaderTypes, Menu } from "~/core";
import { selectors as cdSelectors } from "~/customer-data";
import * as recsSelectors from "~/recs-events/recs/selectors";
import * as eventsSelectors from "~/recs-events/events/selectors";
import { RecEventListTabs } from "~/recs-events/actions";

import "./field-accordion-item.css";

const messages = defineMessages({
    custFarmSize: {
        id: "actionPanel.recEventCommon.custFarmSize",
        defaultMessage: "{custFarmSize} Acres",
    },
    eventCountLabel: {
        id: "actionPanel.recEventCommon.eventCountLabel",
        defaultMessage: "{eventCount, plural, one {Event} other {Events}}",
    },
    recCountLabel: {
        id: "actionPanel.recEventCommon.recCountLabel",
        defaultMessage: "{recCount, plural, one {Rec} other {Recs}}",
    },
});

interface IProps {
    accordionId: number;
    collapseItem: (accordionId: number, itemDimIdx: number[]) => void;
    expandItem: (accordionId: number, itemDimIdx: number[]) => void;
    field: any;
    getMenuItems: (props: IProps) => void;
    getSelectedItemCount: (field: any) => number;
    hasSelected: boolean;
    hideEventCount: boolean;
    hideRecCount: boolean;
    intl: any;
    isExpanded: boolean;
    isLoading: boolean;
    itemDimIdx: number[];
    showHasSelected: boolean;
    activeTab: string;
}

export class FieldItem_ extends Component<IProps> {
    static defaultProps = {
        hasSelected: false,
        hideEventCount: false,
        hideRecCount: false,
        getSelectedItemCount: () => 0,
        showHasSelected: false,
        activeTab: RecEventListTabs.SELECTED,
    };

    _onClick(event) {
        if (this.props.isLoading || event.isDefaultPrevented()) {
            return;
        }

        const { isExpanded, expandItem, collapseItem, accordionId, itemDimIdx } = this.props;

        if (isExpanded) {
            collapseItem(accordionId, itemDimIdx);
        } else {
            expandItem(accordionId, itemDimIdx);
        }
    }

    render() {
        const {
            field,
            getMenuItems,
            getSelectedItemCount,
            hasSelected,
            hideEventCount,
            hideRecCount,
            isLoading,
            showHasSelected,
        } = this.props;

        const { formatMessage, formatNumber } = this.props.intl;

        if (!field) {
            return null;
        }

        const custFarmSize = [
            field.customerName,
            field.farmName,
            formatNumber(field.acres, intlConfig.numberFormatOptions),
        ]
            .filter((v) => v && v !== "")
            .join(", ");

        let loader;
        if (isLoading) {
            loader = (
                <Loader className="layer-loader" type={LoaderTypes.LINE_SCALE_PULSE_OUT_RAPID} />
            );
        }

        const counts = [];
        if (!hideRecCount) {
            counts.push(
                <div key="recsCount" className="rec-event-count">
                    {formatNumber(field.recCount)}
                    <span className="count">{formatMessage(messages.recCountLabel, field)}</span>
                </div>
            );
        }
        if (!hideEventCount) {
            counts.push(
                <div key="eventsCount" className="rec-event-count">
                    {formatNumber(field.eventCount)}
                    <span className="count">{formatMessage(messages.eventCountLabel, field)}</span>
                </div>
            );
        }

        const selectedItemCount = getSelectedItemCount(field);
        const farmNameSizeStr = formatMessage(messages.custFarmSize, {
            custFarmSize,
        });
        return (
            <div className="field-accordion-item" onClick={(event) => this._onClick(event)}>
                {!showHasSelected ? null : (
                    <div className={hasSelected ? "has-selected" : "has-no-selected"} />
                )}
                <div className="left-column">
                    <div className="field-name-and-item-count">
                        <span className="field-name" title={field.name}>
                            {field.name}
                        </span>
                        {selectedItemCount === 0 ? null : (
                            <span className="selected-item-count">{selectedItemCount}</span>
                        )}
                    </div>
                    <div className="cust-farm-size" title={farmNameSizeStr}>
                        {farmNameSizeStr}
                    </div>
                </div>
                {counts.length === 0 ? null : <div className="right-columns">{counts}</div>}
                <div className="context-menu-container" onClick={(evt) => evt.preventDefault()}>
                    <Menu
                        className="context-menu"
                        isDotMenu={true}
                        getMenuItems={() => getMenuItems(this.props)}
                    />
                </div>
                {loader}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        expandItem: (accordionId, dimIdx) => {
            dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx));
        },
        collapseItem: (accordionId, dimIdx) => {
            dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx));
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    const isInactiveTab = ownProps.activeTab === RecEventListTabs.INACTIVE;
    const fieldGuidToRecListMap = isInactiveTab
        ? recsSelectors.getFieldGuidToInactiveRecListMap(state)
        : recsSelectors.getFieldGuidToRecListMap(state);
    const fieldGuidToEventListMap = isInactiveTab
        ? eventsSelectors.getFieldGuidToInactiveEventListMap(state)
        : eventsSelectors.getFieldGuidToEventListMap(state);
    const field = ownProps.field;
    const fieldRecList = fieldGuidToRecListMap.get(field.fieldGuid);
    const fieldEventList = fieldGuidToEventListMap.get(field.fieldGuid);
    const eventCount =
        ownProps.activeTab === RecEventListTabs.SELECTED
            ? ownProps.getSelectedItemCount(field)
            : fieldEventList?.length || 0;
    const recCount =
        ownProps.activeTab === RecEventListTabs.SELECTED
            ? ownProps.getSelectedItemCount(field)
            : fieldRecList
            ? fieldRecList.length
            : 0;
    return {
        field: {
            ...field,
            eventCount,
            recCount,
        },
    };
};

export const FieldItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FieldItem_));
