import { keywords } from "~/action-panel/components/import-module/keywords";

export const getClassNameFromStatusCode = (code: number): string => {
    return errorStatusCodes.includes(code)
        ? "import-status-error"
        : processingStatusCodes.includes(code)
        ? "import-status-processing"
        : readyStatusCodes.includes(code)
        ? "import-status-ready"
        : "status-value-text";
};

export const getClassNameForStatusFromEvent = (
    eventSummary: Record<string, any> = {},
    eventTypeInfo: Record<string, any> = {}
): string => {
    if (
        eventSummary.eventId &&
        eventSummary.importedStatus === 6 &&
        eventSummary.importedPoints === 0
    ) {
        return "";
    }
    return getClassNameFromStatusCode(eventSummary.importedStatus);
};

export const errorStatusCodes = [
    10, 11, 12, 14, 15, 16, 17, 18, 19, 25, 26, 27, 31, 32, 36, 40, 43, 45, 49, 111, 391,
];
export const processingStatusCodes = [
    1, 2, 3, 4, 8, 9, 20, 21, 22, 23, 24, 28, 29, 30, 33, 34, 37, 371, 38, 39, 41, 42, 44, 48,
];
export const loadsheetPreventionStatusCodes = [30];
export const readyStatusCodes = [5, 6, 7, 35];
