import { connect } from "react-redux";
import CustomerContainer from "./../../containers/add-edit-panel";
import { withMasked, withApiResult } from "~/hocs";
import Customer from "./customer";
import { dropdowns, service } from "./data";
import * as actions from "./data/actions";
import * as selectors from "./data/selectors";
import {
    getTheUserCompanyGuid,
    getTheUserPersonalityId,
    getTheUserGuid,
    getTheUserEnrollmentRequired,
} from "~/login/selectors";
import { getApiErrorCodeList } from "~/core/api/selectors";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";

const mapStateToProps = (state) => ({
    apiErrors: getApiErrorCodeList(state),
    agvanceCustomerList: selectors.getAgvanceCustomers(state),
    customerNameExists: selectors.getCustomerNameExists(state),
    communityDataDefault: selectors.getCommunityDataDefault(state),
    enrollmentRequired: getTheUserEnrollmentRequired(state),
    newPrimaryCustomer: selectors.getNewPrimaryCustomer(state),
    companyGuid: getTheUserCompanyGuid(state),
    customerRecord: selectors.getCustomerRecord(state),
    equipmentList: selectors.getCustomerEquipmentList(state),
    customerFieldSet: cdSelectors.getCustomerGuidToFieldGuidSetMap(state),
    personalityId: getTheUserPersonalityId(state),
    userGuid: getTheUserGuid(state),
    salespersonList: selectors.getSalespersonList(state),
    oracleCustomer: selectors.getOracleCustomer(state),
    isAgvanceCustomerListOutOfBound: selectors.getAgvanceCustomerListOutOfBound(state),
    additionalAgvanceCustomerList: selectors.getAdditionalAgvanceCustomerList(state),
    customerSearchIsLoading: selectors.getCustomerSearchIsLoading(state),
    isAdditionalAgvanceCustomerSearch: selectors.getAdditionalAgvanceCustomerSearchFlag(state),
});

const mapDispatchToProps = (dispatch) => ({
    addEnrolledFields: (fieldGuids) => dispatch(cdActions.addEnrolledFields(fieldGuids)),
    clearEnrolledFields: (fieldGuids) => dispatch(cdActions.clearEnrolledFields(fieldGuids)),
    createCustomer: (payload) => actions.createCustomer(payload),
    createCustomerOracleId: (payload) => actions.createCustomerOracleId(payload),
    updateCustomer: (payload) => actions.updateCustomer(payload),
    updateEnrollmentForm: (payload) => dispatch(actions.updateEnrollmentForm(payload)),
    removeCustomerRecord: () => dispatch(actions.removeCustomerRecord()),
    createEquipment: (payload) => actions.createEquipment(payload),
    getAgvanceCustomer: (payload) => actions.getAgvanceCustomer(payload),
    fetchCustomerEquipmentList: (payload) => actions.fetchCustomerEquipmentList(payload),
    fetchCustomerExists: (payload) => actions.fetchCustomerExists(payload),
    fetchOwnerCommunityData: (payload) => actions.fetchOwnerCommunityData(payload),
    fetchAgvanceCustomer: (payload) => actions.fetchAgvanceCustomer(payload),
    fetchEnrollmentForm: (payload) => dispatch(actions.fetchEnrollmentForm(payload)),
    clearAgvanceCustomerList: () => dispatch(actions.clearAgvanceCustomerList()),
    clearOracleCustomer: () => dispatch(actions.setOracleCustomer()),
    sendAuthentication: (payload) => actions.sendAuthentication(payload),
    fetchAgvanceCustomerWithNameSearch: (payload) =>
        dispatch(actions.fetchAgvanceCustomerWithNameSearch(payload)),
    setAgvanceCustomerListOutOfBound: (payload) =>
        dispatch(actions.setAgvanceCustomerListOutOfBound(payload)),
});

const advancedCustomerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerContainer(Customer, { dropdowns, service }));

export default withMasked(withApiResult(advancedCustomerContainer));
