import { createReducer } from "redux-act";
import * as actions from "./actions";
import { keywords } from "../keywords";
import {
    Template,
    ImportTemplateList,
    SamplingTemplate,
    ECDataTemplate,
    ImportAttribute,
    ImportTemplateFactory,
} from "./model";

export const TEMPLATE_MANAGER_DATA_KEY = "TEMPLATE_MANAGER_DATA";

const initialState = {
    gridData: [],
    store: new Template(),
    currentTab: 0,
};

export const templateManagerReducer = createReducer(
    {
        [actions.getTemplateGridSuccess]: (state, payload) => ({
            ...state,
            ...payload,
        }),
        [actions.setTemplateGridData]: (state, payload) => ({
            ...state,
            gridData: {
                ...payload,
            },
        }),
        [actions.setIsMonitorFileFlag]: (state, payload) => ({
            ...state,
            isMonitorType: payload,
        }),
        [actions.setCurrentTab]: (state, payload) => ({
            ...state,
            currentTab: payload,
        }),
        [actions.resetImportTemplateListData]: (state, payload) => {
            const existingTemplate = new Template({ ...state.store });
            const newImportTemplateList = state.store.importTemplateList.map((importTemplate) => {
                return new ImportTemplateList({
                    ...payload,
                    templateGuid: importTemplate.templateGuid,
                });
            });
            Template.updateTemplate(existingTemplate, {
                importTemplateList: newImportTemplateList,
            });
            return {
                ...state,
                gridData: [],
                store: existingTemplate,
            };
        },
        [actions.resetTemplate]: () => {
            return initialState;
        },
        [actions.updateTemplate]: (state, payload) => {
            const { key, value } = payload;
            const existingTemplate = new Template({ ...state.store });
            const newTemplate = Template.updateTemplate(existingTemplate, {
                [key]: value,
            });
            return {
                ...state,
                store: newTemplate,
            };
        },
        [actions.updateImportTemplateList]: (state, payload) => {
            const { key, value } = payload;
            const existingTemplate = new Template({ ...state.store });
            // Updating the existing template list
            // For now it will update properties all templates using this action
            const newImportTemplateList = state.store.importTemplateList.map((importTemplate) => {
                let newImportTemplate = ImportTemplateFactory.createImportTemplateList(
                    importTemplate,
                    { ...importTemplate }
                );
                return ImportTemplateList.updateImportTemplateList(newImportTemplate, {
                    [key]: value,
                });
            });
            const newTemplate = Template.updateTemplate(existingTemplate, {
                importTemplateList: newImportTemplateList,
            });
            return {
                ...state,
                store: newTemplate,
            };
        },
        [actions.updateImportAttributes]: (state, payload) => {
            const { value } = payload;
            const importTemplateList = state.store.importTemplateList;
            const importTemplateListIndex = state.currentTab;
            // Get currently used templateList from the store
            const importTemplateListToBeUpdated = importTemplateList[importTemplateListIndex];
            const importAttributes = [...importTemplateListToBeUpdated.importAttributes];
            const importAttributeToBeUpdatedIndex =
                importTemplateListToBeUpdated.importAttributes.findIndex((importAttribute) => {
                    return value.isShapeFile
                        ? value.columnNameInFile === importAttribute.columnNameInFile
                        : value.columnOrderInFile === importAttribute.columnOrderInFile;
                });
            // Add new import attribute
            if (importAttributeToBeUpdatedIndex === -1) {
                importAttributes.push(
                    new ImportAttribute(
                        value.importAttributeGuid,
                        value.columnNameInFile,
                        value.columnOrderInFile
                    )
                );
            } else {
                // Edit import attribute
                if (value.importAttributeGuid) {
                    const existingData =
                        importTemplateListToBeUpdated.importAttributes[
                            importAttributeToBeUpdatedIndex
                        ];
                    importAttributes.splice(
                        importAttributeToBeUpdatedIndex,
                        1,
                        new ImportAttribute(
                            value.importAttributeGuid,
                            value.columnNameInFile,
                            value.columnOrderInFile,
                            existingData.attributeUnitGuid,
                            existingData.dbfColumnName,
                            existingData.haasColumnName,
                            existingData.haasTableName,
                            existingData.haasUnitColumnName
                        )
                    );
                } else {
                    // Remove import attribute
                    importAttributes.splice(importAttributeToBeUpdatedIndex, 1);
                }
            }
            const newImportTemplate = ImportTemplateFactory.createImportTemplateList(
                state.store.importTemplateList[importTemplateListIndex],
                { ...importTemplateListToBeUpdated }
            );
            const retImportTemplate = ImportTemplateList.updateImportTemplateList(
                newImportTemplate,
                {
                    importAttributes,
                }
            );
            importTemplateList[importTemplateListIndex] = retImportTemplate;
            return {
                ...state,
                store: {
                    ...state.store,
                    importTemplateList,
                },
            };
        },
        [actions.updateManualImportAttributes]: (state, payload) => {
            const { value } = payload;
            const importTemplateList = state.store.importTemplateList;
            const importTemplateListIndex = state.currentTab;
            // Get currently used templateList from the store
            const importTemplateListToBeUpdated = importTemplateList[importTemplateListIndex];
            const importAttributes = [...importTemplateListToBeUpdated.importAttributes];
            const importAttributeToBeUpdatedIndex =
                importTemplateListToBeUpdated.importAttributes.findIndex((importAttribute) => {
                    return value.columnOrderInFile === importAttribute.columnOrderInFile;
                });
            const importAttribute = new ImportAttribute(value.importAttributeGuid, null);
            importAttribute.columnOrderInFile = value.columnOrderInFile;
            // Add new import attribute
            if (importAttributeToBeUpdatedIndex === -1) {
                importAttributes.push(importAttribute);
            } else {
                // Edit import attribute
                if (value.importAttributeGuid) {
                    importAttributes.splice(importAttributeToBeUpdatedIndex, 1, importAttribute);
                } else {
                    // Remove import attribute
                    importAttributes.splice(importAttributeToBeUpdatedIndex, 1);
                    // Update order of other attributes
                    for (let i = 0; i < importAttributes.length; i++) {
                        if (importAttributes[i].columnOrderInFile > value.columnOrderInFile) {
                            importAttributes[i].columnOrderInFile--;
                        }
                    }
                }
            }
            const newImportTemplate = ImportTemplateFactory.createImportTemplateList(
                state.store.importTemplateList[importTemplateListIndex],
                { ...importTemplateListToBeUpdated }
            );
            const retImportTemplate = ImportTemplateList.updateImportTemplateList(
                newImportTemplate,
                {
                    importAttributes,
                }
            );
            importTemplateList[importTemplateListIndex] = retImportTemplate;
            return {
                ...state,
                store: {
                    ...state.store,
                    importTemplateList,
                },
            };
        },
        [actions.updateFileType]: (state, payload) => {
            const existingTemplate = new Template({ ...state.store });
            Template.updateTemplate(existingTemplate, {
                importTypeGuid: payload.fileType.guid,
            });
            let newImportTemplateList = new Array(new ImportTemplateList());
            // Create an appropriate template model based on the selected file type
            switch (payload.fileType.label) {
                case keywords.EC_DATA: {
                    newImportTemplateList = new Array(
                        new ECDataTemplate({
                            importTypeGuid: payload.fileType.guid,
                            statisticalOutliersYn: true,
                            ignoreNegativeValues: true,
                        })
                    );
                    break;
                }
                case keywords.SAMPLING_SOIL:
                case keywords.SAMPLING_NEMATODE:
                case keywords.SAMPLING_TISSUE: {
                    newImportTemplateList = new Array(new SamplingTemplate());
                    break;
                }
                default:
                    break;
            }
            return {
                ...state,
                store: {
                    ...existingTemplate,
                    importTemplateList: newImportTemplateList,
                },
            };
        },
    },
    initialState
);
