import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { injectIntl, intlShape } from "react-intl";

import { TextInput, Bucket, BucketHeader, SelectInput, Checkbox } from "~/core";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { messages } from "../i18n-messages";

import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./analysis-info.css";
import { eventListSelectors } from "../../../event-module";
import { eventsSelectors } from "~/recs-events";

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
    [2800, messages.analysisMethodNoYieldLayers], // ErrorCode.AnalysisNoYieldDataExists
    [2806, messages.analysisDuplicateName],
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisNormalizedYieldForm_ extends PureComponent {
    static propTypes = {
        analysisSummary: PropTypes.object,
        intl: intlShape.isRequired,
        normalizedEventYieldOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
        pickListNumClasses: PropTypes.arrayOf(PropTypes.object).isRequired,
        analysisDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        onUpdateCurrentAnalysisSummary: PropTypes.func.isRequired,
        isBatchNormalizedYield: PropTypes.bool,
        selectedEventGuidSet: PropTypes.instanceOf(Set),
        selectedEventSummary: PropTypes.instanceOf(Map),
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getAnalysisErrors(errorMessagePlaceholderSet, formatMessage) {
        if (errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0) {
            return null;
        }
        const errorMessages = [];
        errorMessagePlaceholderSet.forEach((errorEntry) => {
            errorMessages.push(
                <div className="analysis-error-message">{formatMessage(errorEntry)}</div>
            );
        });
        return errorMessages;
    }

    _getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateAnalysisToggleEvent(guid, val) {
        const { agEventGeneralGuidList } = this.props.analysisSummary;
        const list = agEventGeneralGuidList.filter(function (item) {
            return item !== guid;
        });
        if (val) {
            list.push(guid);
        }
        this._updateAnalysisSummary({ agEventGeneralGuidList: list });
    }

    _updateAnalysisSummary(newProps) {
        this.props.onUpdateCurrentAnalysisSummary(newProps);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    _getYieldOptionLabel = (event) => {
        const { formatMessage } = this.props.intl;
        if (event.calculatedArea) {
            return `${event.displayName} - ${formatMessage(messages.analysisLayerCalculatedArea, {
                calculatedArea: event.calculatedArea,
            })}`;
        }
        return `${event.displayName}`;
    };

    _removeCottonDuplicates = (events) => {
        return events.reduce((acc, evt) => {
            const duplicateEvtIdx = acc.findIndex(
                (a) => a.agEventGeneralGuid === evt.agEventGeneralGuid
            );
            if (events[duplicateEvtIdx]) {
                if (evt.displayName.indexOf("Cotton") > -1 && evt.attributeName === "Lint Yield") {
                    acc.splice(duplicateEvtIdx, 1, evt);
                }
            } else {
                acc.push(evt);
            }
            return acc;
        }, []);
    };

    _renderPostLabel = (event) => {
        return (
            <div className="analysis-yield-label-cont">
                <div className="checkbox-label-text-span">{this._getYieldOptionLabel(event)}</div>
                <div className="analysis-yield-label">
                    {event.attributeName} - {event.avgYield} {event.yieldUnit}
                </div>
            </div>
        );
    };

    _checkHarvestEvent(event) {
        return event.agEventTypeName === "Harvest" && event.isImportedYn;
    }

    _getSelectedSeasons() {
        const seasons = [];
        const { selectedEventSummary, selectedEventGuidSet } = this.props;

        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.get(eventGeneralGuid) || {};
            if (this._checkHarvestEvent(event)) {
                seasons.push(event.croppingSeasonNum);
            }
        }
        return seasons;
    }

    _renderBatchNormalizedYield() {
        const { formatMessage } = this.props.intl;
        const selectedSeasons = this._getSelectedSeasons();
        return (
            <div>
                <div className="event-count">
                    {formatMessage(messages.harvestEvents)} {selectedSeasons.length}
                </div>
                <div className="seasons">
                    {formatMessage(messages.seasons)}{" "}
                    {Array.from([...new Set(selectedSeasons)]).join(",")}
                </div>
            </div>
        );
    }

    render() {
        const {
            analysisSummary,
            /** @type  */ pickListNumClasses,
            normalizedEventYieldOptions,
            isBatchNormalizedYield,
            selectedEventGuidSet,
        } = this.props;
        const { agEventGeneralGuidList } = isBatchNormalizedYield
            ? Array.from(selectedEventGuidSet)
            : analysisSummary;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;
        let eventCheckboxes;
        if (!isBatchNormalizedYield) {
            eventCheckboxes = this._removeCottonDuplicates(normalizedEventYieldOptions).map(
                (event, key) => {
                    const checked = agEventGeneralGuidList.some(function (guid) {
                        return guid === event.agEventGeneralGuid;
                    });
                    return (
                        <Checkbox
                            className="analysis-input"
                            key={key}
                            value={checked}
                            onChange={(evt, val) =>
                                this._updateAnalysisToggleEvent(event.agEventGeneralGuid, val)
                            }
                            renderLabel={() => this._renderPostLabel(event)}
                        />
                    );
                }
            );
        }

        const analysisErrors = this._getAnalysisErrors(errorMessagePlaceholderSet, formatMessage);

        return (
            <div className="rec-event-info-form">
                {analysisErrors}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                    <TextInput
                        containerClassNames={[
                            "analysis-form-input",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.analysisMethodPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateAnalysisSummary({ name: v })}
                        placeholderText={formatMessage(messages.analysisNamePlaceholderText)}
                        required={true}
                        value={analysisSummary.name}
                        maxLength={50}
                    />
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>
                        {formatMessage(messages.analysisSymbologyInfoPlaceholderText)}
                    </BucketHeader>
                    <SelectInput
                        containerClassNames={["analysis-form-input"]}
                        clearable={false}
                        onChange={(v) => this._updateAnalysisSummary({ numClasses: v })}
                        options={pickListNumClasses}
                        placeholderText={formatMessage(messages.analysisNumClassesPlaceholderText)}
                        required={true}
                        value={analysisSummary.numClasses}
                    />
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.analysisEventsPlaceholderText)}
                    </BucketHeader>
                    {!isBatchNormalizedYield ? (
                        eventCheckboxes
                    ) : (
                        <div>{this._renderBatchNormalizedYield()}</div>
                    )}
                </Bucket>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateNormalizedYieldAnalysisSummary(newProps)),
});

const mapStateToProps = (state) => {
    const normalizedEventYieldOptions = selectors.getNormalizedYieldOptions(state);
    const isBatchNormalizedYield = selectors.getBatchNormalizedYield(state);
    const selectedEventGuidSet = eventListSelectors.getSelectedEventGuidSet(state);
    const selectedEventSummary = eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);
    return {
        normalizedEventYieldOptions,
        isBatchNormalizedYield,
        selectedEventSummary,
        selectedEventGuidSet,
    };
};

export const AnalysisNormalizedYieldForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisNormalizedYieldForm_));
