import { UserAPI, LayerAPI } from "@ai360/core";

export const LOGIN_STATE_KEY = "LOGIN_STATE";

const _getTheUserAttribute = (state, attribute, secondAttribute = undefined) => {
    const theUser = getUser(state);
    if (theUser == null) {
        return null;
    }
    const userAttribVal = theUser[attribute];
    if (userAttribVal == null || secondAttribute == null) {
        return userAttribVal;
    }
    return userAttribVal[secondAttribute];
};

export const getFirstName = (state: unknown): any => {
    if (state[LOGIN_STATE_KEY].theUser != null) {
        return state[LOGIN_STATE_KEY].theUser.personProperties.firstName;
    } else {
        return "";
    }
};

export const getError = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].error;
};
export const getEulaInfo = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].eula;
};
export const getFilteredCustomers = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].filteredCustomers;
};
export const getImageryTileBucketName = (state: unknown): any =>
    state[LOGIN_STATE_KEY].imageryTileBucketName;
export const getLoginState = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].loginState;
};

export const getLoginBackgroundUrl = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].loginBackgroundUrl;
};

export const getMobileAppDetails = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].mobileAppDetails;
};

export const getSecurityInfo = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].securityInfo;
};

export const getSecurityMessageCode = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].securityMessageCode;
};

export const getSessionTimeout = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].sessionTimeout;
};

export const getSystemFaviconUrl = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].systemFaviconUrl;
};

export const getTheUserCompanyGuid = (state: unknown): any => {
    return _getTheUserAttribute(state, "companyGuid");
};

export const getTheUserGuid = (state: unknown): any => {
    return _getTheUserAttribute(state, "userGuid") || "";
};

export const getTheUserEnrollmentRequired = (state: unknown): any => {
    return _getTheUserAttribute(state, "enrollmentRequiredYN");
};

export const getTheUserLockCustomer = (state: unknown): any => {
    return _getTheUserAttribute(state, "lockCustomersNotEnrolledYn");
};

export const getUser = (
    state: unknown
): UserAPI.IUser & { layerTypesAccess: LayerAPI.IAnalysisLayerType[] } => {
    return state[LOGIN_STATE_KEY].theUser;
};

export const getUserTypeId = (state: unknown): any => {
    return _getTheUserAttribute(state, "userTypeId");
};

export const getUserHasRealTimeFieldUpdates = (state: unknown): any => {
    return _getTheUserAttribute(state, "realTimeFieldUpdates");
};

export const currentUserHasRole = (state: unknown, permission: string): any => {
    const role = getUser(state).role;
    return role && Object.hasOwn(role, permission) && role[permission];
};

export const getTheUserPersonalityId = (state: unknown): number => {
    return _getTheUserAttribute(state, "ownerPersonalityId");
};

export const getTheOwnerBrandName = (state: unknown): any => {
    return _getTheUserAttribute(state, "systemProperties", "brandingName");
};

export const getTheSystemName = (state: unknown): any => {
    return _getTheUserAttribute(state, "systemProperties", "systemName");
};

export const getSystemAreaLimit = (state: unknown): any => {
    return _getTheUserAttribute(state, "systemProperties", "areaLimit");
};

export const getUserSystemProperties = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].theUser.systemProperties;
};

export const getLastUsedCompanyGuid = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].theUser.lastUsedCompanyGuid;
};

export const getLastUsedLocationGuid = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].theUser.lastUsedLocationGuid;
};

export const getOrgLevelGuids = (state: unknown): any => {
    const lastUsedLocationGuid = state[LOGIN_STATE_KEY].theUser.lastUsedLocationGuid;
    if (lastUsedLocationGuid && lastUsedLocationGuid !== "") return [lastUsedLocationGuid];

    const topOrgLevelGuids = state[LOGIN_STATE_KEY].theUser.topOrgLevelGuids;
    if (topOrgLevelGuids && topOrgLevelGuids.length > 0) {
        return topOrgLevelGuids;
    }

    return [state[LOGIN_STATE_KEY].theUser.lastUsedCompanyGuid];
};

export const getUsers = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].theUsers;
};

export const isProcessing = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].isProcessing;
};

export const getReleaseNotes = (state: unknown): any => {
    return state[LOGIN_STATE_KEY].releaseNotes;
};
