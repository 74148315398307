import React, { Component } from "react";
import PropTypes from "prop-types";
import * as filters from "./import-filter/filters";
import { ImportFilterTypes } from "../../../actions";
import {
    PICKLIST_CROPPING_SEASON,
    PICKLIST_CROPPING_SEASON_CYCLE,
    PICKLIST_CROP_PURPOSE,
} from "~/core/picklist/picklist-names";
const HARVEST = "Harvest";
const APPLICATION = "Application";
const PLANTING = "Planting";

export class FilesPanel_ extends Component {
    static propTypes = {
        addEquipmentImportFilter: PropTypes.func,
        canContinue: PropTypes.bool.isRequired,
        equipmentFilter: PropTypes.object,
        importFileGuidList: PropTypes.array.isRequired,
        importFilterListData: PropTypes.object.isRequired,
        importType: PropTypes.object,
        loadDetailPage: PropTypes.func,
        needs: PropTypes.any,
        removeImportFiles: PropTypes.func,
        assignMatchedToUnmatched: PropTypes.func,
        selectedFieldGuid: PropTypes.string,
        updateCanContinue: PropTypes.func.isRequired,
        updateImageryAttribute: PropTypes.func.isRequired,
        updateImportFilterListData: PropTypes.func.isRequired,
    };

    static defaultProps = {
        selectedFieldGuid: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            equipmentFilterComplete: false,
            seasonFilterComplete: false,
            cropFilterComplete: false,
            productFilterComplete: false,
        };
    }

    selectItem = (listProp, item) => {
        /* This will go through each of the possible filter lists below, toggling all
            rows, in all lists, to an unselected state, exc. the selected row which will be toggled on/off. */

        const listProps = Object.values(ImportFilterTypes);
        const newFilterData = { ...this.props.importFilterListData };

        listProps.forEach((filterListProp) => {
            const filterList = this.props.importFilterListData[filterListProp];
            newFilterData[filterListProp] = !filterList
                ? null
                : filterList.map((listItem) => {
                      const ignoredMatchAttributes = [
                          "eventDate",
                          "importFileGuidList",
                          "importFiles",
                          "pointCount",
                          "hasLookupMatch",
                          "isSelected",
                          "monitorIdAliases",
                      ];
                      const keys = Object.keys(listItem).filter(
                          (k) => ignoredMatchAttributes.indexOf(k) === -1
                      );
                      const matched = keys.filter((k) => {
                          if (listItem[k] || item[k]) {
                              return listItem[k] === item[k];
                          }
                          return true;
                      });
                      return {
                          ...listItem,
                          isSelected: Boolean(
                              listProp === filterListProp &&
                                  matched.length === keys.length &&
                                  !listItem.isSelected
                          ),
                      };
                  });
        });
        this.props.updateImportFilterListData(newFilterData);
    };

    allFiltersComplete = () => {
        const {
            equipmentFilterComplete,
            seasonFilterComplete,
            cropFilterComplete,
            productFilterComplete,
        } = this.state;
        const { equipmentProfiles, croppingSeasons, crops, products } =
            this.props.importFilterListData;

        return (
            (!equipmentProfiles || equipmentFilterComplete) &&
            (!croppingSeasons || seasonFilterComplete) &&
            (!crops || cropFilterComplete) &&
            (!products || productFilterComplete)
        );
    };

    updateImportFilterListData = (listProp, items) => {
        const newFilterData = { ...this.props.importFilterListData };
        let listPropData = newFilterData[listProp];

        // list of attributes to ignore when comparing values for merge
        const ignoredMergeAttributes = [
            "importFileGuidList",
            "importFiles",
            "pointCount",
            "hasLookupMatch",
            "isSelected",
        ];
        let newListPropData = listPropData.filter((o) => !o.isSelected);
        const selectedIndex = listPropData.findIndex((o) => o.isSelected === true);

        items.forEach((item) => {
            const keys = Object.keys(item).filter((k) => ignoredMergeAttributes.indexOf(k) === -1);
            let merged = false;

            newListPropData.forEach((o, idx) => {
                const matched = keys.filter((k) => {
                    if (o[k] || item[k]) {
                        return o[k] === item[k];
                    }
                    return true;
                });
                // If all property values matched, merge the import files/counts
                if (matched.length === keys.length) {
                    const importFiles = Object.keys(item.importFiles);
                    importFiles.forEach((i) => {
                        if (o.importFiles[i]) {
                            o.importFiles[i] += item.importFiles[i];
                        } else {
                            o.importFiles[i] = item.importFiles[i];
                        }
                    });
                    newListPropData = [
                        ...newListPropData.slice(0, idx),
                        o,
                        ...newListPropData.slice(idx + 1),
                    ];
                    merged = true;
                }
            });

            if (!merged) {
                newListPropData.splice(selectedIndex, 0, item);
            }
        });

        newFilterData[listProp] = newListPropData;
        this.props.updateImportFilterListData(newFilterData);
    };

    updateFilterStatus = (filterProp, isComplete) => {
        if (this.state[filterProp] !== isComplete) {
            this.setState(
                {
                    [filterProp]: isComplete,
                },
                () => {
                    this.props.updateCanContinue(this.allFiltersComplete());
                }
            );
        }
    };

    render() {
        const getImportFileGuidList = (importFiles) => {
            return Object.keys(importFiles);
        };

        const getTotalPointCount = (importFiles) => {
            return Object.keys(importFiles).reduce((sum, key) => sum + importFiles[key], 0);
        };

        const { equipmentProfiles, croppingSeasons, crops, products, layerList } =
            this.props.importFilterListData;
        const {
            ImportEquipmentFilter,
            ImportSeasonFilter,
            ImportCropFilter,
            ImportProductFilter,
            ImportLayerFilter,
        } = filters;

        const seasonPicklistData = {
            [PICKLIST_CROPPING_SEASON]: this.props[PICKLIST_CROPPING_SEASON],
            [PICKLIST_CROPPING_SEASON_CYCLE]: this.props[PICKLIST_CROPPING_SEASON_CYCLE],
        };

        const cropPicklistData = {
            [PICKLIST_CROP_PURPOSE]: this.props[PICKLIST_CROP_PURPOSE],
        };

        const equipmentFilter = !(equipmentProfiles && equipmentProfiles.length > 0) ? null : (
            <ImportEquipmentFilter
                importType={this.props.importType}
                importFileGuidList={this.props.importFileGuidList}
                filterItems={equipmentProfiles.map((ep) => {
                    return {
                        ...ep,
                        pointCount: getTotalPointCount(ep.importFiles),
                        importFileGuidList: getImportFileGuidList(ep.importFiles),
                    };
                })}
                selectItem={(item) => {
                    this.selectItem(ImportFilterTypes.EQUIPMENT_PROFILES, item);
                }}
                updateFilterStatus={(isComplete) =>
                    this.updateFilterStatus("equipmentFilterComplete", isComplete)
                }
                updateAttribute={(item) =>
                    this.updateImportFilterListData(ImportFilterTypes.EQUIPMENT_PROFILES, [item])
                }
                selectedFieldGuid={this.props.selectedFieldGuid}
                loadDetailPage={this.props.loadDetailPage}
                addEquipmentImportFilter={this.props.addEquipmentImportFilter}
                equipmentFilter={this.props.equipmentFilter}
                needs={this.props.needs}
                removeImportFiles={(importFileGuidList) =>
                    this.props.removeImportFiles(importFileGuidList)
                }
                assignMatchedToUnmatched={(index, assignment) =>
                    this.props.assignMatchedToUnmatched(index, assignment)
                }
            />
        );

        const seasonFilter = !(croppingSeasons && croppingSeasons.length > 0) ? null : (
            <ImportSeasonFilter
                importFileGuidList={this.props.importFileGuidList}
                picklistData={seasonPicklistData}
                filterItems={croppingSeasons.reduce((seasons, cs) => {
                    const season = seasons.find((c) => c.croppingSeason === cs.croppingSeason);
                    if (season) {
                        season.pointCount += getTotalPointCount(cs.importFiles);
                        const files = getImportFileGuidList(cs.importFiles);
                        files.forEach((f) => {
                            if (season.importFileGuidList.indexOf(f) === -1) {
                                season.importFileGuidList.push(f);
                            }
                        });
                        season.items.push(cs);
                    } else {
                        seasons.push({
                            ...cs,
                            pointCount: getTotalPointCount(cs.importFiles),
                            importFileGuidList: getImportFileGuidList(cs.importFiles),
                            items: [cs],
                        });
                    }
                    return seasons;
                }, [])}
                selectItem={(item) => {
                    this.selectItem(ImportFilterTypes.CROPPING_SEASONS, item);
                }}
                updateFilterStatus={(isComplete) =>
                    this.updateFilterStatus("seasonFilterComplete", isComplete)
                }
                updateAttribute={(items) =>
                    this.updateImportFilterListData(ImportFilterTypes.CROPPING_SEASONS, items)
                }
                selectedFieldGuid={this.props.selectedFieldGuid}
                removeImportFiles={(importFileGuidList) =>
                    this.props.removeImportFiles(importFileGuidList)
                }
                assignMatchedToUnmatched={(index, assignment) =>
                    this.props.assignMatchedToUnmatched(index, assignment)
                }
            />
        );

        const cropFilter = !(crops && crops.length > 0) ? null : (
            <ImportCropFilter
                isHarvest={this.props.importType && this.props.importType.name === HARVEST}
                isPlanting={this.props.importType && this.props.importType.name === PLANTING}
                importFileGuidList={this.props.importFileGuidList}
                picklistData={cropPicklistData}
                filterItems={crops.map((c) => {
                    return {
                        ...c,
                        pointCount: getTotalPointCount(c.importFiles),
                        importFileGuidList: getImportFileGuidList(c.importFiles),
                    };
                })}
                selectItem={(item) => {
                    this.selectItem(ImportFilterTypes.CROPS, item);
                }}
                updateFilterStatus={(isComplete) =>
                    this.updateFilterStatus("cropFilterComplete", isComplete)
                }
                updateAttribute={(item) =>
                    this.updateImportFilterListData(ImportFilterTypes.CROPS, [item])
                }
                selectedFieldGuid={this.props.selectedFieldGuid}
                removeImportFiles={(importFileGuidList) =>
                    this.props.removeImportFiles(importFileGuidList)
                }
                assignMatchedToUnmatched={(index, assignment) =>
                    this.props.assignMatchedToUnmatched(index, assignment)
                }
            />
        );

        const productFilter = !(products && products.length > 0) ? null : (
            <ImportProductFilter
                isApplication={this.props.importType && this.props.importType.name === APPLICATION}
                importFileGuidList={this.props.importFileGuidList}
                filterItems={products.map((p) => {
                    return {
                        ...p,
                        pointCount: getTotalPointCount(p.importFiles),
                        importFileGuidList: getImportFileGuidList(p.importFiles),
                    };
                })}
                selectItem={(item) => {
                    this.selectItem(ImportFilterTypes.PRODUCTS, item);
                }}
                updateFilterStatus={(isComplete) =>
                    this.updateFilterStatus("productFilterComplete", isComplete)
                }
                updateAttribute={(item) =>
                    this.updateImportFilterListData(ImportFilterTypes.PRODUCTS, [item])
                }
                selectedFieldGuid={this.props.selectedFieldGuid}
                removeImportFiles={(importFileGuidList) =>
                    this.props.removeImportFiles(importFileGuidList)
                }
                assignMatchedToUnmatched={(index, assignment) =>
                    this.props.assignMatchedToUnmatched(index, assignment)
                }
            />
        );

        const layerFilter = !(layerList && layerList.length > 0) ? null : (
            <ImportLayerFilter
                importFileGuidList={this.props.importFileGuidList}
                picklistData={seasonPicklistData}
                filterItems={layerList}
                selectItem={(item) => {
                    this.selectItem("layerList", item);
                }}
                updateFilterStatus={(isComplete) =>
                    this.updateFilterStatus("layerFilterComplete", isComplete)
                }
                updateAttribute={(req, cb) => this.props.updateImageryAttribute(req, cb)}
                selectedFieldGuid={this.props.selectedFieldGuid}
                removeImportFiles={(importFileGuidList) =>
                    this.props.removeImportFiles(importFileGuidList)
                }
                assignMatchedToUnmatched={(index, assignment) =>
                    this.props.assignMatchedToUnmatched(index, assignment)
                }
            />
        );

        return (
            <div className="files-panel">
                {equipmentFilter}
                {seasonFilter}
                {cropFilter}
                {productFilter}
                {layerFilter}
            </div>
        );
    }
}
