import { createSelector } from "reselect";

import { selectors as accSelectors } from "~/accordion";

import * as moduleSelectors from "../../selectors";

import * as cdSelectors from "~/customer-data/selectors";

import * as models from "./models";
import Immutable from "immutable";

export const FIELD_LIST_KEY = "FIELD_LIST";

export const FIELDS_ACCORDION_KEY = "FIELDS_ACCORDION";
export const FIELD_LIST_ATTRS_KEY = "FIELD_LIST_ATTRS";

const getFieldListState = (state: Record<string, unknown>): models.IFieldListState =>
    moduleSelectors.getModuleState(state)[FIELD_LIST_KEY];

const getFieldListAttrsState = (state: Record<string, unknown>): models.IFieldListAttrsState =>
    getFieldListState(state)[FIELD_LIST_ATTRS_KEY];

const getAccordionState = (state: Record<string, unknown>): any =>
    getFieldListState(state)[FIELDS_ACCORDION_KEY];

const getAccordionInit = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).accordionInit;

export const getAccordionId = (state: Record<string, unknown>) =>
    getAccordionState(state).accordionId;
export const getAccordionItems = (state: Record<string, unknown>) =>
    accSelectors.itemsSelector(getAccordionState(state));
export const getAccordionItemsCount = (state: Record<string, unknown>) =>
    accSelectors.itemsSelector(getAccordionState(state)).length;
export const getAccordionRecHeight = (state: Record<string, unknown>) =>
    getAccordionState(state).recHeight;
export const getAccordionRecItemCount = (state: Record<string, unknown>) =>
    getAccordionState(state).recItemCount;
export const getDimIdxMap = (state: Record<string, unknown>) =>
    accSelectors.dimIdxMapSelector(getAccordionState(state));
export const getFlatIdxMap = (state: Record<string, unknown>) =>
    accSelectors.flatIdxMapSelector(getAccordionState(state));
export const getBatchFieldDetails = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).batchFieldDetails;
export const getManualExpandedCustomers = (state: Record<string, unknown>): Immutable.Set<string> =>
    getFieldListAttrsState(state).manualExpandedCustomers;
export const getFilterOptions = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).filterOptions;
export const getFilterSelections = (
    state: Record<string, unknown>
): models.IFieldFilterSelections => getFieldListAttrsState(state).filterSelections;
export const getFilterCount = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).filterCount;
export const getShowBatchEdit = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).showBatchEdit;
export const getShowFilters = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).showFilters;
export const getSearchValue = (state: Record<string, unknown>): any =>
    getFieldListAttrsState(state).searchValue;

export const getAllExpandedCustomers = createSelector(
    getManualExpandedCustomers,
    cdSelectors.getAutoExpandedCustomers,
    (manualExpandedCustomers, autoExpandedCustomers) =>
        manualExpandedCustomers.concat(autoExpandedCustomers)
);

export const getAccordionScrollTop = createSelector(
    getAccordionInit,
    (accordionInit) => accordionInit.initScrollTop
);

export const getAccordionScrollToFlatIdx = createSelector(
    getAccordionInit,
    (accordionInit) => accordionInit.scrollToFlatIdx
);

export const getLastClickedDimIdx = createSelector(
    getAccordionInit,
    (accordionInit) => accordionInit.lastClickedDimIdx
);
