import React, { Component } from "react";
import { connect } from "react-redux";

import { injectIntl } from "react-intl";

import { Menu } from "~/core";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getUser } from "~/login";
import { mapActions } from "~/map";
import { actions as recsEventsActions, eventsSelectors, recsSelectors } from "~/recs-events";

import * as analysisModels from "~/recs-events/analysis/model";
import * as fieldActions from "../../../components/field-module/actions";
import * as fieldSelectors from "../../../components/field-module/selectors";

import { DeleteConfDialog } from "../item-delete-confirmation-dialog";

import { messages as globalMessages } from "~/i18n-messages";
import { messages } from "../i18n-messages";
import { CustomerInfo, FieldInfo } from "~/customer-data/models";

import "./field-context-menu.css";

interface IFieldContextMenuProps {
    activeTab: "ACTIVE" | "INACTIVE" | "SELECTED";
    field: FieldInfo;
    intl: any;
    enrolledFieldGuids: any;
    eventTypeOptions: any;
    recTypeOptions: any;
    onActivateField: any;
    onAddEditField: (customer: CustomerInfo, fieldGuid: string) => void;
    onCreateNewEvent: any;
    onCreateNewRec: any;
    onDeleteField: any;
    onExportBoundary: any;
    onAddAnalysisLayer: any;
    onSetZoomToFarm: any;
    onSetZoomToField: any;
    userInfo: any;
    customer: CustomerInfo;
}

interface IFieldContextMenuState {
    isDeleteConfirmationOpen: boolean;
}

class FieldContextMenu_ extends Component<IFieldContextMenuProps, IFieldContextMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteConfirmationOpen: false,
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { field, onDeleteField } = this.props;

        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  className: "dialog-expanded",
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: messages.deleteFieldConfirmation,
                  onConfirmationClick: (confirmation) => {
                      if (confirmation) {
                          onDeleteField(field.fieldGuid);
                      }
                      this.setState({ isDeleteConfirmationOpen: false });
                  },
              });

        return (
            <div className="field-context-menu-container">
                <Menu
                    className="context-menu field-context-menu"
                    isDotMenu={true}
                    getMenuItems={this.getMenuItems}
                />
                {deleteConfDialog}
            </div>
        );
    }

    private getMenuItems = (): Array<{
        key: number;
        label: string;
        action: () => any;
    }> => {
        const { formatMessage } = this.props.intl;
        const {
            activeTab,
            enrolledFieldGuids,
            eventTypeOptions,
            customer,
            field,
            onActivateField,
            onAddAnalysisLayer,
            onAddEditField,
            onCreateNewEvent,
            onCreateNewRec,
            onExportBoundary,
            onSetZoomToFarm,
            onSetZoomToField,
            recTypeOptions,
            userInfo,
        } = this.props;

        const blockedByEnrollment =
            userInfo.lockCustomersNotEnrolledYn === true &&
            !enrolledFieldGuids.has(field.fieldGuid);
        const fieldContextMenuItems = [];
        const recMenuItems = recTypeOptions.map((recTypeOption, key) => {
            const recTypeInfo = recTypeOption.value;
            return {
                key,
                label: recTypeOption.label,
                action: () => onCreateNewRec(field.fieldGuid, field.fieldBoundaryGuid, recTypeInfo),
                disabled: blockedByEnrollment,
            };
        });
        const eventMenuItems = eventTypeOptions.map((eventTypeOption, key) => {
            const eventTypeInfo = eventTypeOption.value;
            return {
                key,
                label: eventTypeOption.label,
                action: () =>
                    onCreateNewEvent(
                        field.fieldGuid,
                        field.fieldBoundaryGuid,
                        field.customerGuid,
                        eventTypeInfo.agEventTransactionTypeName
                    ),
                disabled: blockedByEnrollment,
            };
        });

        const userRole = userInfo.role || {};
        if (
            activeTab === fieldActions.FieldListTabs.ACTIVE ||
            activeTab === fieldActions.FieldListTabs.SELECTED
        ) {
            if (field.farmName) {
                fieldContextMenuItems.push({
                    key: messages.zoomToText.id,
                    label: formatMessage(messages.zoomToText),
                    subMenuItems: [
                        {
                            key: 0,
                            label: formatMessage(messages.zoomToFieldText),
                            action: () => onSetZoomToField(field.fieldGuid),
                        },
                        {
                            key: 1,
                            label: formatMessage(messages.zoomToFarmText),
                            action: () => onSetZoomToFarm(field.customerGuid, field.farmName),
                        },
                    ],
                });
            } else {
                fieldContextMenuItems.push({
                    key: messages.zoomToText.id,
                    label: formatMessage(messages.zoomToText),
                    action: () => onSetZoomToField(field.fieldGuid),
                });
            }
            if (userRole.recommendations && recMenuItems.length > 0) {
                fieldContextMenuItems.push({
                    key: messages.newRecText.id,
                    label: formatMessage(messages.newRecText),
                    subMenuItems: recMenuItems,
                    disabled: blockedByEnrollment,
                });
            }
            if (userRole.agEvents && eventMenuItems.length > 0) {
                fieldContextMenuItems.push({
                    key: messages.newEventText.id,
                    label: formatMessage(messages.newEventText),
                    subMenuItems: eventMenuItems,
                    disabled: blockedByEnrollment,
                });
            }
            if (userRole.analysisLayers && userInfo.layerTypesAccess.length) {
                const subMenuItems = [
                    {
                        key: 0,
                        label: formatMessage(messages.fourMationText),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION
                            ), // Use SeedStrong DH for 4Mation
                    },
                    {
                        key: 1,
                        label: formatMessage(messages.managementArea),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA
                            ),
                    },
                    {
                        key: 2,
                        label: formatMessage(messages.normalizedYieldText),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD
                            ),
                    },
                    {
                        key: 3,
                        label: formatMessage(messages.profitLossText),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS
                            ),
                    },
                    {
                        key: 4,
                        label: formatMessage(messages.seedStrongText),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG
                            ),
                    },
                    {
                        key: 5,
                        label: formatMessage(messages.seedStrongDHText),
                        action: () =>
                            onAddAnalysisLayer(
                                [field.fieldGuid],
                                analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH
                            ),
                    },
                ];
                const layerType = userInfo.layerTypesAccess.map(({ name }) => name);
                const modifiedSubmenuItems = subMenuItems.filter(({ label }) =>
                    layerType.includes(label)
                );
                fieldContextMenuItems.push({
                    key: messages.newAnalysisLayerText.id,
                    label: formatMessage(messages.newAnalysisLayerText),
                    subMenuItems: modifiedSubmenuItems,
                    disabled: blockedByEnrollment,
                });
            }
            if (userRole.manageFields) {
                fieldContextMenuItems.push({
                    key: messages.viewEditFieldText.id,
                    label: formatMessage(messages.viewEditFieldText),
                    action: () => onAddEditField(customer, field.fieldGuid),
                });
            }
            if (userRole.exportFieldBoundary) {
                fieldContextMenuItems.push({
                    key: messages.exportBoundaryText.id,
                    label: formatMessage(messages.exportBoundaryText),
                    action: () => onExportBoundary(field.fieldGuid),
                    disabled: blockedByEnrollment,
                });
            }
            if (userRole.manageFields) {
                fieldContextMenuItems.push({
                    key: messages.deleteFieldText.id,
                    label: formatMessage(messages.deleteFieldText),
                    action: () => this.setState({ isDeleteConfirmationOpen: true }),
                });
            }
        } else {
            if (userRole.manageFields) {
                fieldContextMenuItems.push({
                    key: messages.activateFieldText.id,
                    label: formatMessage(messages.activateFieldText),
                    action: () => onActivateField(field.fieldGuid),
                });
            }
        }

        return fieldContextMenuItems;
    };
}

const mapStateToProps = (state, ownProps) => ({
    activeTab: fieldSelectors.getActiveTab(state),
    enrolledFieldGuids: cdSelectors.getEnrolledFieldGuids(state),
    eventTypeOptions: eventsSelectors.getNewableEventTypeOptions(state),
    field: ownProps.field,
    recTypeOptions: recsSelectors.getNewableRecTypeOptions(state),
    userInfo: getUser(state),
});
const mapDispatchToProps = (dispatch) => ({
    onActivateField: (fieldGuid) => dispatch(fieldActions.activateField(fieldGuid)),
    onAddAnalysisLayer: (fieldGuids, analysisTypeGuid) =>
        dispatch(fieldActions.addAnalysisLayer(fieldGuids, analysisTypeGuid)),
    onDeleteField: (fieldGuid) => dispatch(fieldActions.deleteField(fieldGuid)),
    onSetZoomToFarm: (customerGuid, farmName) =>
        dispatch(mapActions.setZoomToFarm(customerGuid, farmName)),
    onSetZoomToField: (fieldGuid) => dispatch(mapActions.setZoomToField(fieldGuid)),
    onCreateNewEvent: (fieldGuid, fieldBoundaryGuid, customerGuid, agEventTransactionTypeName) => {
        dispatch(cdActions.addSelectedField(fieldGuid));
        dispatch(
            recsEventsActions.createNewEventDetails(
                [fieldGuid],
                new Map([[fieldGuid, fieldBoundaryGuid]]),
                new Map([[fieldGuid, customerGuid]]),
                agEventTransactionTypeName
            )
        );
    },
    onCreateNewRec: (fieldGuid, fieldBoundaryGuid, recType) => {
        dispatch(cdActions.addSelectedField(fieldGuid));
        dispatch(
            recsEventsActions.createNewRecDetails(
                [fieldGuid],
                new Map([[fieldGuid, fieldBoundaryGuid]]),
                recType,
                null,
                null
            )
        );
    },
    onExportBoundary: (fieldGuid) => dispatch(fieldActions.exportBoundary(fieldGuid)),
});

export const FieldContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldContextMenu_));
