export const ADD_ANALYSIS_LAYER = "analysis-info/ADD_ANALYSIS_LAYER";
export const ADD_SEEDSTRONG_ANALYSIS_LAYER = "analysis-info/ADD_SEEDSTRONG_ANALYSIS_LAYER";
export const CLOSE_ANALYSIS_INFO = "analysis-info/CLOSE_ANALYSIS_INFO";
export const DELETE_ANALYSIS_LAYER = "analysis-info/DELETE_ANALYSIS_LAYER";
export const DELETE_ANALYSIS_LAYER_BATCH = "analysis-info/DELETE_ANALYSIS_LAYER_BATCH";
export const FETCH_ANALYSIS_DETAILS = "analysis-info/FETCH_ANALYSIS_DETAILS";
export const FETCH_ANALYSIS_INFO = "analysis-info/FETCH_ANALYSIS_INFO";
export const FETCH_ANALYSIS_INFO_SUCCEEDED = "analysis-info/FETCH_ANALYSIS_INFO_SUCCEEDED";
export const FETCH_ANALYSIS_LAYER_TYPES_SUCCEEDED =
    "analysis-info/FETCH_ANALYSIS_LAYER_TYPES_SUCCEEDED";
export const FETCH_MANAGEMENT_AREA_ATTRIBUTES = "analysis-info/FETCH_MANAGEMENT_AREA_ATTRIBUTES";
export const FETCHED_DROPDOWN_DATA = "analysis-info/FETCHED_DROPDOWN_DATA";
export const REMOVE_PROCESSING_ANALYSIS_INFO = "analysis-info/REMOVE_PROCESSING_ANALYSIS_INFO";
export const RESET_MANAGEMENT_AREA_ATTRIBUTE_DATA =
    "analaysis-info/RESET_MANAGEMENT_AREA_ATTRIBUTE_DATA";
export const SAVE_ANALYSIS_INFO = "analysis-info/SAVE_ANALYSIS_INFO";
export const SET_ANALYSIS_SUMMARY = "analysis-info/SET_ANALYSIS_SUMMARY";
export const SET_ANALYSIS_DETAILS_LOADING = "analysis-info/SET_ANALYSIS_DETAILS_LOADING";
export const SET_ANALYSIS_INFO_PICKLISTS = "analysis-info/SET_ANALYSIS_INFO_PICKLISTS";
export const SET_EC_DATA_ATTRIBUTES = "analysis-info/SET_EC_DATA_ATTRIBUTES";
export const SET_FIELDS = "analysis-info/SET_FIELDS";
export const SET_FIELD_GUIDS = "analysis-info/SET_FIELD_GUIDS";
export const SET_MANAGEMENT_AREA_ATTRIBUTE_DATA =
    "analaysis-info/SET_MANAGEMENT_AREA_ATTRIBUTE_DATA";
export const SET_MANAGEMENT_AREA_ATTRIBUTES = "analaysis-info/SET_MANAGEMENT_AREA_ATTRIBUTES";
export const SET_MANAGEMENT_AREA_TYPES = "analaysis-info/SET_MANAGEMENT_AREA_TYPES";
export const SET_NORMALIZED_YIELD_OPTIONS = "analysis-info/SET_NORMALIZED_YIELD_OPTIONS";
export const SET_SEED_STRONG_OPTIONS = "analysis-info/SET_SEED_STRONG_OPTIONS";
export const SET_PROCESSING_ANALYSIS_INFO = "analysis-info/SET_PROCESSING_ANALYSIS_INFO";
export const SHOW_ANALYSIS_INFO = "analysis-info/SHOW_ANALYSIS_INFO";
export const UPDATE_ANALYSIS_LAYER = "analysis-info/UPDATE_ANALYSIS_LAYER";
export const UPDATE_ANALYSIS_ZONES = "analysis-info/UPDATE_ANALYSIS_ZONES";
export const UPDATE_EC_DATA_ANALYSIS_SUMMARY = "analysis-info/UPDATE_EC_DATA_ANALYSIS_SUMMARY";
export const UPDATE_MANAGEMENT_AREA_ANALYSIS_SUMMARY =
    "analysis-info/UPDATE_MANAGEMENT_AREA_ANALYSIS_SUMMARY";
export const UPDATE_MANAGEMENT_AREA_ATTRIBUTE_DATA =
    "analysis-info/UPDATE_MANAGEMENT_AREA_ATTRIBUTE_DATA";
export const UPDATE_MANAGEMENT_AREA_APPLY_TO_AREA =
    "analysis-info/UPDATE_MANAGEMENT_AREA_APPLY_TO_AREA";
export const UPDATE_NORMALIZED_YIELD_ANALYSIS_SUMMARY =
    "analysis-info/UPDATE_NORMALIZED_YIELD_ANALYSIS_SUMMARY";
export const UPDATE_PROFITLOSS_ANALYSIS_SUMMARY =
    "analysis-info/UPDATE_PROFITLOSS_ANALYSIS_SUMMARY";
export const UPDATE_SEEDSTRONG_ANALYSIS_SUMMARY =
    "analysis-info/UPDATE_SEEDSTRONG_ANALYSIS_SUMMARY";
export const FETCH_ACTUAL_DETAIL_COST = "analysis-info/FETCH_ACTUAL_DETAIL_COST";
export const FETCH_ACTUAL_DETAIL_COST_COMPLETED =
    "analysis-info/FETCH_ACTUAL_DETAIL_COST_COMPLETED";
export const CLOSE_PROFIT_LOSS_DETAILS = "analysis-info/CLOSE_PROFIT_LOSS_DETAILS";
export const SET_IS_LOADING = "analysis-info/SET_IS_LOADING";
export const SET_IS_BATCH_NORMALIZED_YIELD = "analysis-info/SET_IS_BATCH_NORMALIZED_YIELD";

export const addAnalysisLayer = (fieldGuids, analysisLayerType) => ({
    type: ADD_ANALYSIS_LAYER,
    payload: { fieldGuids, analysisLayerType },
});

export const addSeedStrongAnalysisLayer = (fieldGuid) => ({
    type: ADD_SEEDSTRONG_ANALYSIS_LAYER,
    payload: { fieldGuid },
});

export const closeAnalysisInfo = () => ({
    type: CLOSE_ANALYSIS_INFO,
});

export const setBatchNormalizedYield = (isBatchNormalizedYield) => ({
    type: SET_IS_BATCH_NORMALIZED_YIELD,
    payload: { isBatchNormalizedYield },
});

export const deleteAnalysisLayer = (analysisLayerGuid, fieldGuid) => ({
    type: DELETE_ANALYSIS_LAYER,
    payload: { analysisLayerGuid, fieldGuid },
});

export const deleteAnalysisLayerBatch = (batchGuid, analysisInBatch) => ({
    type: DELETE_ANALYSIS_LAYER_BATCH,
    payload: { batchGuid, analysisInBatch },
});

export const fetchAnalysisDetails = (analysisLayerTypes) => ({
    type: FETCH_ANALYSIS_DETAILS,
    payload: { analysisLayerTypes },
});

export const fetchAnalysisInfo = (analysisLayerGuid) => ({
    type: FETCH_ANALYSIS_INFO,
    payload: { analysisLayerGuid },
});

export const fetchAnalysisInfoSucceeded = (analysisSummary) => ({
    type: FETCH_ANALYSIS_INFO_SUCCEEDED,
    payload: { analysisSummary },
});

export const fetchManagementAreaAttributes = (importTemplateGuid, analysisLayerGuid = null) => ({
    type: FETCH_MANAGEMENT_AREA_ATTRIBUTES,
    payload: { importTemplateGuid, analysisLayerGuid },
});

export const setEcDataAttributes = (attributes) => ({
    type: SET_EC_DATA_ATTRIBUTES,
    payload: { attributes },
});

export const fetchedDropdownData = (payload) => ({
    type: FETCHED_DROPDOWN_DATA,
    payload,
});

export const removeProcessingAnalysisInfo = (fieldGuid) => ({
    type: REMOVE_PROCESSING_ANALYSIS_INFO,
    payload: { fieldGuid },
});

export const resetManagementAreaAttributeData = () => ({
    type: RESET_MANAGEMENT_AREA_ATTRIBUTE_DATA,
});

export const setAnalysisSummary = (analysisSummary) => ({
    type: SET_ANALYSIS_SUMMARY,
    payload: { analysisSummary },
});

export const saveAnalysisInfo = () => ({
    type: SAVE_ANALYSIS_INFO,
});

export const setAnalysisDetailsLoading = (isLoading) => ({
    type: SET_ANALYSIS_DETAILS_LOADING,
    payload: { isLoading },
});

export const setAnalysisInfoPicklists = (recInfoPicklists) => ({
    type: SET_ANALYSIS_INFO_PICKLISTS,
    payload: { recInfoPicklists },
});

export const setFields = (fields) => ({
    type: SET_FIELDS,
    payload: { fields },
});

export const setFieldGuids = (fieldGuids) => ({
    type: SET_FIELD_GUIDS,
    payload: { fieldGuids },
});

export const setManagementAreaAttributeData = (
    areaId,
    name,
    textValue,
    yesNoValue,
    decimalIntValue
) => ({
    type: SET_MANAGEMENT_AREA_ATTRIBUTE_DATA,
    payload: { areaId, name, textValue, yesNoValue, decimalIntValue },
});

export const setManagementAreaAttributes = (payload) => ({
    type: SET_MANAGEMENT_AREA_ATTRIBUTES,
    payload,
});

export const setManagementAreaTypes = (payload) => ({
    type: SET_MANAGEMENT_AREA_TYPES,
    payload,
});

export const setNormalizedYieldOptions = (normalizedYieldOptions) => ({
    type: SET_NORMALIZED_YIELD_OPTIONS,
    payload: { normalizedYieldOptions },
});

export const setSeedStrongOptions = (seedStrongOptions) => ({
    type: SET_SEED_STRONG_OPTIONS,
    payload: { seedStrongOptions },
});

export const setProcessingAnalysisInfo = (fieldGuid, processingAnalysisInfo) => ({
    type: SET_PROCESSING_ANALYSIS_INFO,
    payload: { fieldGuid, processingAnalysisInfo },
});

export const showAnalysisInfo = (analysisLayerGuid) => ({
    type: SHOW_ANALYSIS_INFO,
    payload: { analysisLayerGuid },
});

export const updateAnalysisLayer = () => ({
    type: UPDATE_ANALYSIS_LAYER,
    payload: {},
});

export const updateEcDataAnalysisSummary = (newProps) => ({
    type: UPDATE_EC_DATA_ANALYSIS_SUMMARY,
    payload: { newProps },
});

export const updateManagementAreaAnalysisSummary = (newProps) => ({
    type: UPDATE_MANAGEMENT_AREA_ANALYSIS_SUMMARY,
    payload: { newProps },
});

export const updateManagementAreaApplyToArea = () => ({
    type: UPDATE_MANAGEMENT_AREA_APPLY_TO_AREA,
    payload: {},
});

export const updateManagementAreaAttributeData = (
    areaId,
    name,
    textValue,
    yesNoValue,
    decimalIntValue
) => ({
    type: UPDATE_MANAGEMENT_AREA_ATTRIBUTE_DATA,
    payload: { areaId, name, textValue, yesNoValue, decimalIntValue },
});

export const updateNormalizedYieldAnalysisSummary = (newProps) => ({
    type: UPDATE_NORMALIZED_YIELD_ANALYSIS_SUMMARY,
    payload: { newProps },
});

export const updateProfitLossAnalysisSummary = (newProps) => ({
    type: UPDATE_PROFITLOSS_ANALYSIS_SUMMARY,
    payload: { newProps },
});

export const updateSeedStrongAnalysisSummary = (newProps) => ({
    type: UPDATE_SEEDSTRONG_ANALYSIS_SUMMARY,
    payload: { newProps },
});

export const updateZones = (zones, fieldBoundaryGuid, areaIdToNewAreaIdPolygonMap) => ({
    type: UPDATE_ANALYSIS_ZONES,
    payload: { zones, fieldBoundaryGuid, areaIdToNewAreaIdPolygonMap },
});

export const fetchActualDetailCost = (model) => ({
    type: FETCH_ACTUAL_DETAIL_COST,
    payload: { model },
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
});

export const fetchActualDetailCostCompleted = (results) => ({
    type: FETCH_ACTUAL_DETAIL_COST_COMPLETED,
    payload: { results },
});

export const closeProfitLossDetails = () => ({
    type: CLOSE_PROFIT_LOSS_DETAILS,
});
