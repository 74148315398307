import { defineMessages } from "react-intl";

export const messages = defineMessages({
    addText: {
        id: "importWizard.addText",
        defaultMessage: "Add",
    },
    addProductMixText: {
        id: "importWizard.addProductMixText",
        defaultMessage: "+ Add Product Mix",
    },
    editProductMixText: {
        id: "importWizard.editProductMixText",
        defaultMessage: "Edit Product Mix",
    },
    removeProductMixText: {
        id: "importWizard.removeProductMixText",
        defaultMessage: "Remove Product Mix",
    },
    mixText: {
        id: "importWizard.mixText",
        defaultMessage: "Mix",
    },
    assignToAllText: {
        id: "importWizard.assignToAllText",
        defaultMessage: "Assign To All",
    },
    averageTestWeightText: {
        id: "importWizard.averageTestWeightText",
        defaultMessage: "Average Test Weight",
    },
    averageUnitWeightText: {
        id: "importWizard.averageUnitWeightText",
        defaultMessage: "Average Unit Weight",
    },
    brandVarietyText: {
        id: "importWizard.brandVarietyText",
        defaultMessage: "Brand/Variety",
    },
    cropFilterTitle: {
        id: "importWizard.cropFilterTitle",
        defaultMessage: "Crop Info",
    },
    cropPlaceholderText: {
        id: "importWizard.cropPlaceholderText",
        defaultMessage: "Crop",
    },
    cropPurposePlaceholderText: {
        id: "importWizard.cropPurposePlaceholderText",
        defaultMessage: "Crop Purpose",
    },
    brandPlaceholderText: {
        id: "importWizard.brandPlaceholderText",
        defaultMessage: "Brand/Organization",
    },
    equipment: {
        id: "importWizard.Equipment",
        defaultMessage: "Equipment",
    },
    varietyPlaceholderText: {
        id: "importWizard.varietyPlaceholderText",
        defaultMessage: "Variety/Hybrid",
    },
    yieldUnitPlaceholderText: {
        id: "importWizard.yieldUnitPlaceholderText",
        defaultMessage: "Yield Unit",
    },
    lintPercentPlaceholderText: {
        id: "importWizard.lintPercentPlaceholderText",
        defaultMessage: "Lint %",
    },
    cropText: {
        id: "importWizard.cropText",
        defaultMessage: "Crop",
    },
    croppingSeasonText: {
        id: "importWizard.croppingSeasonText",
        defaultMessage: "Season",
    },
    croppingSeasonCycleText: {
        id: "importWizard.croppingSeasonCycleText",
        defaultMessage: "Cycle",
    },
    dateText: {
        id: "importWizard.dateText",
        defaultMessage: "Date",
    },
    editAttributeText: {
        id: "importWizard.editAttributeText",
        defaultMessage: "Edit Attribute",
    },
    editCroppingSeasonText: {
        id: "importWizard.editCroppingSeasonText",
        defaultMessage: "Edit Cropping Season",
    },
    editCropText: {
        id: "importWizard.editCropText",
        defaultMessage: "Edit Crop",
    },
    editEquipmentText: {
        id: "importWizard.editEquipmentText",
        defaultMessage: "Edit Equipment",
    },
    editLayerText: {
        id: "importWizard.editLayerText",
        defaultMessage: "Edit Image Detail",
    },
    editProductText: {
        id: "importWizard.editProductText",
        defaultMessage: "Edit Product",
    },
    editText: {
        id: "importWizard.editText",
        defaultMessage: "Edit",
    },
    equipmentFilterTitle: {
        id: "importWizard.equipmentFilterTitle",
        defaultMessage: "Equipment Info",
    },
    equipmentNameText: {
        id: "importWizard.equipmentNameText",
        defaultMessage: "Equipment Name",
    },
    featuresText: {
        id: "importWizard.featuresText",
        defaultMessage: "Features",
    },
    layerFilterTitle: {
        id: "importWizard.layerFilterTitle",
        defaultMessage: "Imagery Detail",
    },
    layerNameText: {
        id: "importWizard.layerNameText",
        defaultMessage: "Layer Name",
    },
    machineIdText: {
        id: "importWizard.machineIdText",
        defaultMessage: "Monitor ID",
    },
    noMatchText: {
        id: "importWizard.noMatchText",
        defaultMessage: "No Matches",
    },
    productFilterTitle: {
        id: "importWizard.productFilterTitle",
        defaultMessage: "Product Info",
    },
    productNameText: {
        id: "importWizard.productNameText",
        defaultMessage: "Product Name",
    },
    profileNameText: {
        id: "importWizard.profileNameText",
        defaultMessage: "Profile Name",
    },
    removeText: {
        id: "importWizard.importFilter.removeText",
        defaultMessage: "Remove",
    },
    seasonFilterTitle: {
        id: "importWizard.seasonFilterTitle",
        defaultMessage: "Season Info",
    },
    rateUnitText: {
        id: "importWizard.rateUnitText",
        defaultMessage: "Unit",
    },
    yieldUnitText: {
        id: "importWizard.yieldUnitText",
        defaultMessage: "Unit",
    },
});
