import PropTypes from "prop-types";
import _ from "lodash";
import Point from "@arcgis/core/geometry/Point";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";

import { LayerAPI, FieldAPI, AppHelpers } from "@ai360/core";

import { GeometryMath, GeometryUtils, ListenerManager } from "@ai360/core";
import Polygon from "@arcgis/core/geometry/Polygon";

export const LinkedMapGroupManagerType = PropTypes.shape({
    addMap: PropTypes.func.isRequired,
    deleteMap: PropTypes.func.isRequired,
    getLayer: PropTypes.func.isRequired,
    getLayerInfo: PropTypes.func.isRequired,
    setFieldInfo: PropTypes.func.isRequired,
    setLayerInfo: PropTypes.func.isRequired,
});

export class LinkedMapGroupManager {
    constructor() {
        this._listeners = new Map();
        this.maps = new Map();
        this.mgrsCells = new Map();
        this.dggData = new Map();
        this.clearSurfaceDggInfo = this.clearSurfaceDggInfo.bind(this);
        this.userDefined = null;
        this.mapDisplayCount = 0;
        this.mgrss = [];
        this.userDefinedPolygons = [];
    }

    _cleanupListeners(mapId) {
        let listeners = this._listeners.get(mapId);
        if (listeners != null) {
            listeners.removeAll();
        } else {
            listeners = new ListenerManager();
            this._listeners.set(mapId, listeners);
        }
        return listeners;
    }

    _cloneFeatures() {
        const features = [];
        for (var feat of this.features) {
            features.push(feat.clone());
        }
        return features;
    }

    _onSyncExtentUpdate(mapId, extent) {
        if (this.isLoading || extent == null) {
            return;
        }
        for (var [key, lm] of this.maps.entries()) {
            if (key !== mapId) {
                lm.mapView.extent = extent;
            }
        }
    }

    _setExtentForAll(extent) {
        this.isLoading = true;
        for (var lm of this.maps.values()) {
            lm.mapView.extent = extent;
        }
        setTimeout(() => {
            this.isLoading = false;
        }, 250);
    }

    _setMapFieldFeatures() {
        if (
            (this.mapsLoadedCount !== this.mapDisplayCount && this.mapsLoadedCount !== 4) ||
            this.maps.size !== this.mapCount ||
            !this.isNewField ||
            this.features == null ||
            this.fieldGuid == null
        ) {
            return;
        }

        const fieldExtent = geometryEngine
            .union(
                this.features.map((feat) => {
                    return feat.geometry;
                })
            )
            .extent.expand(1.5);
        for (let i = 0; i < this.maps.size; i++) {
            const lm = Array.from(this.maps.values())[i];
            if (i >= this.mapDisplayCount) {
                this._cleanupListeners(lm.mapView.id);
                continue;
            }
            lm.setFieldFeatures(this.fieldGuid, this._cloneFeatures());
            lm.mapView.extent = fieldExtent;
            this._setupListeners(lm.mapView);
            this._setupResizeListener(lm.mapView, fieldExtent);
        }

        this.isNewField = false;
        this.isLoading = false;
    }

    _setupListeners(mapView) {
        const listeners = this._cleanupListeners(mapView.id);

        const move = () => {
            this._onSyncExtentUpdate(mapView.id, mapView.extent);
        };

        const scale = AppHelpers.debounce(() => {
            this._onSyncExtentUpdate(mapView.id, mapView.extent);
        }, 1);

        listeners.add(mapView.watch("scale", scale));
        listeners.add(mapView.on("drag", move));
    }

    _setupResizeListener(mapView, fieldExtent) {
        const listeners = this._listeners.get(mapView.id);
        if (listeners) {
            listeners.add(
                mapView.on("resize", () => {
                    clearTimeout(this.resizeTimer);
                    this.resizeTimer = setTimeout(() => {
                        this._setExtentForAll(fieldExtent);
                    }, 250);
                })
            );
        }
    }

    addMap(linkedMap) {
        this.maps.set(linkedMap.mapView.id, linkedMap);
    }

    addSurfaceDgg(surface) {
        if (this.dggData.has(surface.rendererGuid)) {
            return Promise.resolve();
        }
        this.dggData.set(surface.rendererGuid, []);

        return LayerAPI.getSurfaceDGGInfo([surface.rendererGuid])
            .then((results) => {
                for (const mgrs in results.mgrsCells) {
                    if (!this.mgrsCells.has(mgrs)) {
                        const cellGeometry = GeometryUtils.wktToGeometry(results.mgrsCells[mgrs]);
                        const projectedCell = webMercatorUtils.project(
                            cellGeometry,
                            this.getSpatialReference()
                        );
                        this.mgrsCells.set(mgrs, projectedCell);
                    }
                }
                this.dggData.set(surface.rendererGuid, results.data[surface.rendererGuid]);
            })
            .catch((e) => {
                console.error("Error fetching Dgg Stat Info for Surface", e);
                this.dggData.set(surface.rendererGuid, []);
            });
    }

    clearSurfaceDggInfo() {
        this.mgrsCells.clear();
        this.dggData.clear();
    }

    clearSurfaceDggSummary() {
        this.updateMGRSGroup(null, null);
    }

    deleteMap(linkedMap) {
        this.maps.delete(linkedMap.map.id);
    }

    getExtent() {
        return this.maps.entries().next().value[1].mapView.extent.toJSON();
    }

    getLayer(surfaceInfo) {
        if (this.layerInfo) {
            for (const layer of this.layerInfo) {
                if (
                    layer.subLayers &&
                    layer.subLayers.find((l) => {
                        return l.surfaceGuid === surfaceInfo.surfaceGuid;
                    })
                ) {
                    return layer;
                }
            }
        }
    }

    getLayerInfo() {
        return this.layerInfo;
    }

    getMGRSCell(mapPoint) {
        if (mapPoint) {
            const { longitude, latitude } = mapPoint;
            const point = new Point({
                x: longitude,
                y: latitude,
                spatialReference: { wkid: 4326 },
            });
            const mgrs = GeometryMath.getGridMgrs(point);
            if (this.mgrsCells.has(mgrs)) {
                return [mgrs, this.mgrsCells.get(mgrs)];
            }
        }
        return [null, null];
    }

    getMGRSCells(geometry) {
        const mgrss = [];
        const cells = [];
        const simplified = geometryEngine.simplify(geometry);
        for (const [mgrs, cell] of this.mgrsCells.entries()) {
            if (geometryEngine.intersects(simplified, cell)) {
                mgrss.push(mgrs);
                cells.push(cell);
            }
        }

        if (cells.length > 0) {
            return [new Set(mgrss), geometryEngine.union(cells)];
        }

        return [null, null];
    }

    getSpatialReference() {
        return this.maps.entries().next().value[1].mapView.spatialReference;
    }

    getUserDefinedPolyJson() {
        const _userDefinedPolygon = [];
        let polyUnion;
        if (this.userDefinedPolygons && this.userDefinedPolygons.length > 0) {
            this.userDefinedPolygons.every((x) => _userDefinedPolygon.push(new Polygon(x.poly)));
            polyUnion = geometryEngine.union(_userDefinedPolygon);
            const wgs84Poly = webMercatorUtils.project(
                polyUnion,
                new SpatialReference({ wkid: 4326 })
            );
            return wgs84Poly.toJSON();
        }
        return null;
    }

    getUserDefinedPolyJsonForPrint() {
        const polyJsonForPrint = [];
        if (this.userDefinedPolygons && this.userDefinedPolygons.length > 0) {
            for (let index = 0; index < this.userDefinedPolygons.length; index++) {
                const element = this.userDefinedPolygons[index];
                const { poly } = element;
                const wgs84Poly = webMercatorUtils.project(
                    poly,
                    new SpatialReference({ wkid: 4326 })
                );
                polyJsonForPrint.push(wgs84Poly.toJSON());
            }
            return polyJsonForPrint;
        }
        return null;
    }

    setFieldInfo(fieldGuid, mapCount) {
        Object.assign(this, { fieldGuid, mapCount });
        if (fieldGuid == null) {
            this.features = null;
            return;
        }
        this.isLoading = true;
        this.isNewField = true;
        this.mapsLoadedCount = 0;

        FieldAPI.fetchFieldBoundariesByFieldGuidPerformant([fieldGuid], null, null, true).then(
            (boundaries) => {
                this.features = boundaries.map(FieldAPI.fieldBoundaryToGraphic);
                this._setMapFieldFeatures();
            }
        );
    }

    setLayerInfo(layerInfo) {
        this.layerInfo = layerInfo;
    }

    updateMGRS(graphic, mapId) {
        const mgrs = graphic ? graphic.attributes.mgrs : null;
        if (this.mgrs === mgrs) {
            return;
        }
        this.mgrs = mgrs;
        if (mgrs == null) {
            for (const lm of this.maps.values()) {
                lm.updateCrosshairs(null);
                lm.updateDgg(null);
            }
            return;
        }
        const dggInfo = new Map();
        for (const [rendererGuid, data] of this.dggData.entries()) {
            dggInfo.set(
                rendererGuid,
                data.filter((d) => d.mgrs === mgrs)
            );
        }
        for (const lm of this.maps.values()) {
            if (lm.props.id !== mapId) {
                lm.updateCrosshairs(graphic);
            }
            lm.updateDgg(dggInfo);
        }
    }

    updateMGRSGroup(mgrss, poly, mapId = null) {
        if (_.isEqual(this.mgrss, mgrss) && mapId) {
            return;
        }
        this.mgrss = mgrss;
        const dggInfo = new Map();
        this.userDefined = null;
        if (mgrss == null) {
            this.userDefinedPolygons = [];
            for (const lm of this.maps.values()) {
                lm.updateUserDefinedPoly(null, null);
                lm.updateDggGroup(null);
            }
            return;
        }
        this.userDefined = { mgrss, poly };
        this.userDefinedPolygons.push(this.userDefined);
        const mgrssResult = new Set();
        for (let index = 0; index < this.userDefinedPolygons.length; index++) {
            const udPoly = this.userDefinedPolygons[index].mgrss;
            udPoly.forEach((value) => {
                mgrssResult.add(value);
            });
        }
        for (const [rendererGuid, data] of this.dggData.entries()) {
            dggInfo.set(
                rendererGuid,
                data.filter((d) => mgrssResult.has(d.mgrs))
            );
        }
        for (const lm of this.maps.values()) {
            if (lm.props.id !== mapId) {
                for (let index = 0; index < this.userDefinedPolygons.length; index++) {
                    lm.updateUserDefinedPoly(
                        this.userDefinedPolygons[index].mgrss,
                        this.userDefinedPolygons[index].poly
                    );
                }
            }
            lm.updateDggGroup(dggInfo);
        }
    }
}
