import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";

import { Loader, SelectInput } from "~/core";
import { selectors as fileImportSelectors, actions as fileImportActions } from "~/file-import";

import { ModuleFiltersToggle, ModuleFilters } from "../../../module-filters";
import * as selectors from "../../selectors";
import * as actions from "../../actions";

import { ImportFilterTabs } from "./import-filter-tabs/import-filter-tabs";
import { ImportAccordion } from "./import-accordion/import-accordion";
import { ACTIVE_YN } from "~/core/picklist";
import "./import-list.css";

const messages = defineMessages({
    fileTypeInputPlaceholder: {
        id: "importModule.fileTypeInputPlaceholder",
        defaultMessage: "File Type",
    },
});

class ImportList_ extends Component {
    static propTypes = {
        importTypeFilterGuid: PropTypes.string.isRequired,
        importTypeOptions: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
        setTypeFilter: PropTypes.func.isRequired,
        fetchImportFileTypes: PropTypes.func.isRequired,
        onToggleFilters: PropTypes.func.isRequired,
        showFilters: PropTypes.bool.isRequired,
        searchValue: PropTypes.string.isRequired,
        intl: intlShape.isRequired,
        updateSearchValue: PropTypes.func.isRequired,
    };

    UNSAFE_componentWillMount() {
        if (this.props.importTypeOptions.length === 1) {
            this.props.fetchImportFileTypes();
        }
    }

    render() {
        const {
            importTypeFilterGuid,
            importTypeOptions,
            isLoading,
            setTypeFilter,
            onToggleFilters,
            showFilters,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const accordion = (
            <div
                className={classNames("pane-content", {
                    "pane-content-loader": isLoading,
                })}
            >
                {isLoading ? <Loader /> : <ImportAccordion />}
            </div>
        );

        const placeHolder = formatMessage(messages.fileTypeInputPlaceholder);
        const filterInputEls = [
            <SelectInput
                key={0}
                optionIsHiddenKey={ACTIVE_YN}
                required
                clearable={false}
                options={importTypeOptions}
                placeholderText={placeHolder}
                onChange={(guid) => setTypeFilter(guid)}
                value={importTypeFilterGuid}
            />,
        ];

        return (
            <div className={classNames("import-list")}>
                <div className="module-filter-row">
                    {!showFilters ? null : (
                        <div className="module-filters">
                            <ModuleFilters>{filterInputEls}</ModuleFilters>
                        </div>
                    )}
                    <ModuleFiltersToggle
                        filterVisible={showFilters}
                        onToggleFilters={(newValue) => onToggleFilters(newValue)}
                    />
                </div>

                <ImportFilterTabs>{accordion}</ImportFilterTabs>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { importTypeFilterGuid, isLoading } = selectors.getModuleState(state);

    const importTypes = Array.from(fileImportSelectors.getImportTypes(state).values());
    importTypes.splice(0, 0, { name: "All", guid: "" });
    const importTypeOptions = importTypes.map((importType) => {
        return {
            value: importType.guid,
            label: importType.name,
        };
    });

    return {
        importTypeFilterGuid: importTypeFilterGuid || "",
        importTypeOptions,
        isLoading: isLoading || fileImportSelectors.isFetchingTypes(state),
        showFilters: selectors.getShowFilters(state),
        searchValue: selectors.getSearchValue(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTypeFilter: (importTypeGuid) => dispatch(actions.setTypeFilter(importTypeGuid || null)),
        fetchImportFileTypes: () => dispatch(fileImportActions.fetchImportFileTypes()),
        onToggleFilters: (showFilters) => dispatch(actions.setShowFilters(showFilters)),
        updateSearchValue: (newSearchValue) => dispatch(actions.updateSearchValue(newSearchValue)),
    };
};

export const ImportList = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportList_));
