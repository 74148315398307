export const IMPORT_ATTR_GUID = "importAttributeGuid";
export const PROPS_AG_EVENT_GUID = "agEventGuid";
export const PROPS_BRAND_ORGANIZATION_GUID = "brandOrganizationGuid";
export const PROPS_BRAND_ORGANIZATION_NAME = "brandOrganizationName";
export const PROPS_EVENT_PLANTING_VARIETY_HYBRID_GUID = "eventPlantingVarietyHybridGuid";
export const PROPS_IS_SEED_TREAT_INFO_EXPANDED = "isSeedTreatInfoExpanded";
export const PROPS_LOT_NUMBER = "lotNumber";
export const PROPS_PERCENT_OF_PLANTER = "percentOfPlanter";
export const PROPS_PLANTS_PER_AREA = "plantsPerArea";
export const PROPS_PLANTS_PER_AREA_IA_GUID = "plantsPerAreaIaGuid";
export const PROPS_PRICE_PER_UNIT = "pricePerUnit";
export const PROPS_PRICE_PER_UNIT_GUID = "pricePerUnitGuid";
export const PROPS_PRICE_PER_UNIT_TEXT = "pricePerUnitText";
export const PROPS_ROOTSTOCK_NAME = "rootstockName";
export const PROPS_SEEDING_RATE = "seedingRate";
export const PROPS_SEEDING_RATE_IA_GUID = "seedingRateIaGuid";
export const PROPS_SEEDING_RATE_PER_UNIT_TEXT = "seedingRatePerUnitText";
export const PROPS_SEED_COUNT = "seedCount";
export const PROPS_VARIETY_HYBRID_GUID = "varietyHybridGuid";
export const PROPS_VARIETY_HYBRID_NAME = "varietyHybridName";
