import { action } from "typesafe-actions";

import Immutable from "immutable";

import { SearchAPI, FieldAPI } from "@ai360/core";

import * as models from "./models";
import { NonFieldFeature } from "@ai360/core/dist/4x/es/api/non-field-feature";
import { FieldListTabs } from "~/action-panel/components/field-module/actions";

export const ACTIVATE_CUSTOMER = "customer-data/ACTIVATE_CUSTOMER";
export const ACTIVATE_FIELDS = "customer-data/ACTIVATE_FIELDS";
export const ADD_CUSTOMER_FIELD_MAPPINGS = "customer-data/ADD_CUSTOMER_FIELD_MAPPINGS";
export const ADD_ENROLLED_FIELDS = "customer-data/ADD_ENROLLED_FIELDS";
export const ADD_SELECTED_FIELDS = "customer-data/ADD_SELECTED_FIELDS";
export const ADD_UPDATE_CUSTOMER = "customer-data/ADD_UPDATE_CUSTOMER";
export const ADD_UPDATE_FIELDS = "customer-data/ADD_UPDATE_FIELDS";
export const BATCH_UPDATE_FIELD = "customer-data/BATCH_UPDATE_FIELD";
export const BATCH_UPDATE_FIELD_EVENT_COUNT = "customer-data/BATCH_UPDATE_FIELD_EVENT_COUNT";
export const BATCH_UPDATE_FIELD_REC_COUNT = "customer-data/BATCH_UPDATE_FIELD_REC_COUNT";
export const CLEAR_ALL_SELECTED_FIELDS = "customer-data/CLEAR_ALL_SELECTED_FIELDS";
export const CLEAR_ENROLLED_FIELDS = "customer-data/CLEAR_ENROLLED_FIELDS";
export const CLEAR_SELECTED_FIELDS = "customer-data/CLEAR_SELECTED_FIELDS";
export const DEACTIVATE_CUSTOMER = "customer-data/DEACTIVATE_CUSTOMER";
export const DEACTIVATE_FIELDS = "customer-data/DEACTIVATE_FIELDS";
export const DELETE_CUSTOMER = "customer-data/DELETE_CUSTOMER";
export const FETCH_CUSTOMER_FIELDS = "customer-data/FETCH_CUSTOMER_FIELDS";
export const FETCHING_CUSTOMER_FIELDS = "customer-data/FETCHING_CUSTOMER_FIELDS";
export const FETCH_CUSTOMER_FIELDS_SUCCESS = "customer-data/FETCH_CUSTOMER_FIELDS_SUCCESS";
export const FETCH_CUSTOMER_FIELDS_ERROR = "customer-data/FETCH_CUSTOMER_FIELDS_ERROR";
export const FETCH_FIELDS = "customer-data/FETCH_FIELDS";
export const FETCHING_FIELDS = "customer-data/FETCHING_FIELDS";
export const FETCH_FIELDS_SUCCESS = "customer-data/FETCH_FIELDS_SUCCESS";
export const FETCH_FILTERED_FIELD_GUIDS = "customer-data/FETCH_FILTERED_FIELD_GUIDS";
export const FETCH_FILTERED_FIELD_GUIDS_SUCCESS =
    "customer-data/FETCH_FILTERED_FIELD_GUIDS_SUCCESS";
export const FETCH_FILTERED_FIELD_GUIDS_ERROR = "customer-data/FETCH_FILTERED_FIELD_GUIDS_ERROR";
export const FETCH_SUMMARY = "customer-data/FETCH_SUMMARY";
export const FETCHING_SUMMARY = "customer-data/FETCHING_SUMMARY";
export const FETCH_SUMMARY_SUCCESS = "customer-data/FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_ERROR = "customer-data/FETCH_SUMMARY_ERROR";
export const FIELDS_ACTIVITY_CHANGED = "customer-data/FIELDS_ACTIVITY_CHANGED";
export const MODIFY_CUSTOMERS = "customer-data/MODIFY_CUSTOMERS";
export const MODIFY_FIELDS = "customer-data/MODIFY_FIELDS";
export const MOVE_FIELDS = "customer-data/MOVE_FIELDS";
export const REMOVE_CONNECTED_CUSTOMER = "customer-data/REMOVE_CONNECTED_CUSTOMER";
export const SET_ORG_LEVEL_DATA = "customer-data/SET_ORG_LEVEL_DATA";
export const SET_SELECTED_FIELDS = "customer-data/SET_SELECTED_FIELDS";
export const UPDATE_FIELD_EVENT_COUNT = "customer-data/UPDATE_FIELD_EVENT_COUNT";
export const UPDATE_FIELD_REC_COUNT = "customer-data/UPDATE_FIELD_REC_COUNT";

export const SAVE_NON_FIELD_FEATURE = "customer-data/SAVE_NON_FIELD_FEATURE";
export const DELETE_NON_FIELD_FEATURE = "customer-data/DELETE_NON_FIELD_FEATURE";
export const FETCH_NON_FIELD_FEATURES_FOR_IDS = "customer-data/FETCH_NON_FIELD_FEATURES_FOR_IDS";
export const FETCH_NON_FIELD_FEATURES_FOR_USER = "customer-data/FETCH_NON_FIELD_FEATURES_FOR_USER";
export const FETCH_NON_FIELD_FEATURES_FOR_CUSTOMER =
    "customer-data/FETCH_NON_FIELD_FEATURES_FOR_CUSTOMER";

export const SET_NON_FIELD_FEATURES = "customer-data/SET_NON_FIELD_FEATURES";
export const MODIFY_NON_FIELD_FEATURES = "customer-data/MODIFY_NON_FIELD_FEATURES";
export const SET_SELECTED_NON_FIELD_FEATURES = "customer-data/SET_SELECTED_NON_FIELD_FEATURES";
export const MODIFY_SELECTED_NON_FIELD_FEATURES =
    "customer-data/MODIFY_SELECTED_NON_FIELD_FEATURES";
export const SET_VISIBLE_NON_FIELD_FEATURES = "customer-data/SET_VISIBLE_NON_FIELD_FEATURES";
export const MODIFY_VISIBLE_NON_FIELD_FEATURES = "customer-data/MODIFY_VISIBLE_NON_FIELD_FEATURES";
export const SET_HIGHLIGHTED_NON_FIELD_FEATURES =
    "customer-data/SET_HIGHLIGHTED_NON_FIELD_FEATURES";
export const MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES =
    "customer-data/MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES";

export interface IMapModifications<TId, TValue> {
    merge?: [TId, TValue][];
    remove?: TId[];
}

export type CustomerModifications = IMapModifications<string, models.CustomerInfo>;

export type FieldModifications = IMapModifications<string, models.FieldInfo>;

export interface ISetModifications<TValue> {
    merge?: TValue[];
    remove?: TValue[];
}

export type AutoExpandedCustomersModifications = ISetModifications<string>;

export const activateCustomer = (customerGuid: string, activatedFieldGuids?: string[]) =>
    action(ACTIVATE_CUSTOMER, { customerGuid, activatedFieldGuids });

export const activateFields = (fieldGuids: string[]) => action(ACTIVATE_FIELDS, { fieldGuids });

export const addCustomerFieldMappings = (mappings: models.ICustomerFieldMapping[]) =>
    action(ADD_CUSTOMER_FIELD_MAPPINGS, { mappings });

export const addEnrolledFields = (fieldGuids: Immutable.Set<string> | string[]) =>
    action(ADD_ENROLLED_FIELDS, { fieldGuids });

export const addSelectedField = (fieldGuid: string) => addSelectedFields([fieldGuid]);

export const addSelectedFields = (fieldGuids: Immutable.Set<string> | string[]) =>
    action(ADD_SELECTED_FIELDS, { fieldGuids });

export const addUpdateCustomer = (customer: models.CustomerInfo) =>
    action(ADD_UPDATE_CUSTOMER, {
        customer,
    });

export const addUpdateFields = (
    fields: models.FieldInfo[],
    customers: models.CustomerInfo[],
    autoExpandedCustomersModifications: AutoExpandedCustomersModifications
) => action(ADD_UPDATE_FIELDS, { fields, customers, autoExpandedCustomersModifications });

export const batchUpdateField = (
    fields: models.FieldInfo[],
    customers: models.CustomerInfo[],
    activeTab: FieldListTabs,
    autoExpandedCustomersModifications: AutoExpandedCustomersModifications
) =>
    action(BATCH_UPDATE_FIELD, {
        fields: [...fields],
        customers: [...customers],
        activeTab,
        autoExpandedCustomersModifications,
    });

export const batchUpdateFieldEventCount = (
    fieldCounts: Array<{ fieldGuid: string; eventCount: number }>
) => action(BATCH_UPDATE_FIELD_EVENT_COUNT, [...fieldCounts]);

export const batchUpdateFieldRecCount = (
    fieldCounts: Array<{ fieldGuid: string; recCount: number }>
) => action(BATCH_UPDATE_FIELD_REC_COUNT, [...fieldCounts]);

export const clearAllSelectedFields = () => action(CLEAR_ALL_SELECTED_FIELDS);

export const clearEnrolledFields = (fieldGuids: Immutable.Set<string> | string[]) =>
    action(CLEAR_ENROLLED_FIELDS, { fieldGuids });

export const clearSelectedField = (fieldGuid: string) => clearSelectedFields([fieldGuid]);

export const clearSelectedFields = (fieldGuids: Immutable.Set<string> | string[]) =>
    action(CLEAR_SELECTED_FIELDS, { fieldGuids });

export const deactivateCustomer = (customerGuid: string) =>
    action(DEACTIVATE_CUSTOMER, { customerGuid });

export const deactivateFields = (fieldGuids: string[]) => action(DEACTIVATE_FIELDS, { fieldGuids });

export const deleteCustomer = (customerGuid: string, deactivatedFieldGuids?: string[]) =>
    action(DELETE_CUSTOMER, { customerGuid, deactivatedFieldGuids });

export const fetchCustomerFields = () => action(FETCH_CUSTOMER_FIELDS, { restart: false });

export const fetchingCustomerFields = (abortController: AbortController) =>
    action(FETCHING_CUSTOMER_FIELDS, { abortController });

export const fetchCustomerFieldsSuccess = (
    customers: SearchAPI.ICustomerFieldResult[],
    autoExpandedCustomers: string[],
    lastPageId: SearchAPI.ICustomerFieldPageId,
    isDone: boolean,
    restart: boolean
) =>
    action(FETCH_CUSTOMER_FIELDS_SUCCESS, {
        customers,
        autoExpandedCustomers,
        lastPageId,
        isDone,
        restart,
    });

export const fetchCustomerFieldsError = () => action(FETCH_CUSTOMER_FIELDS_ERROR);

export const fetchFields = (customerId: string) => action(FETCH_FIELDS, { customerId });

export const fetchFieldsSuccess = (fields: SearchAPI.IFieldResult[], restart: boolean) =>
    action(FETCH_FIELDS_SUCCESS, { fields, restart });

export const fetchFilteredFieldGuids = () => action(FETCH_FILTERED_FIELD_GUIDS);

export const fetchFilteredFieldGuidsSuccess = (fieldGuids: string[]) =>
    action(FETCH_FILTERED_FIELD_GUIDS_SUCCESS, { fieldGuids });

export const fetchFilteredFieldGuidsError = () => action(FETCH_FILTERED_FIELD_GUIDS_ERROR);

export const fetchSummary = () => action(FETCH_SUMMARY);

export const fetchingSummary = () => action(FETCHING_SUMMARY);

export const fetchSummarySuccess = (response: SearchAPI.ISummaryResponse) =>
    action(FETCH_SUMMARY_SUCCESS, { response });

export const fetchSummaryError = () => action(FETCH_SUMMARY_ERROR);

export const modifyCustomers = (modifications: CustomerModifications) =>
    action(MODIFY_CUSTOMERS, modifications);

export const modifyFields = (modifications: FieldModifications) =>
    action(MODIFY_FIELDS, modifications);

export const moveFields = (customerGuid: string, farmName: string, fieldGuidList: string[]) =>
    action(MOVE_FIELDS, { customerGuid, farmName, fieldGuidList });

export const removeConnectedCustomer = (customerGuid: string) =>
    action(REMOVE_CONNECTED_CUSTOMER, { customerGuid });

export const restartCustomerFields = () => action(FETCH_CUSTOMER_FIELDS, { restart: true });

export const setOrgLevelData = (orgLevels: Map<string, FieldAPI.IOrgLevelInfo>) =>
    action(SET_ORG_LEVEL_DATA, { orgLevels });

export const setSelectedFields = (fieldGuids: Immutable.Set<string>) =>
    action(SET_SELECTED_FIELDS, { fieldGuids });

export const updateFieldEventCount = (fieldGuid: string, eventCount: number) =>
    action(UPDATE_FIELD_EVENT_COUNT, { fieldGuid, eventCount });

export const updateFieldRecCount = (fieldGuid: string, recCount: number) =>
    action(UPDATE_FIELD_REC_COUNT, { fieldGuid, recCount });

export const saveNonFieldFeature = (feature: GeoJSON.Feature) =>
    action(SAVE_NON_FIELD_FEATURE, { feature });

export const deleteNonFieldFeature = (feature: GeoJSON.Feature) =>
    action(DELETE_NON_FIELD_FEATURE, { feature });

export const fetchNonFieldFeaturesForIds = (ids: string[]) =>
    action(FETCH_NON_FIELD_FEATURES_FOR_IDS, { ids });

export const fetchNonFieldFeaturesForUser = () => action(FETCH_NON_FIELD_FEATURES_FOR_USER);

export const fetchNonFieldFeaturesForCustomer = (customerId: string) =>
    action(FETCH_NON_FIELD_FEATURES_FOR_CUSTOMER, { customerId });

export const setNonFieldFeatures = (features: GeoJSON.Feature[]) =>
    action(SET_NON_FIELD_FEATURES, { features });

export const modifyNonFieldFeatures = (modifications: {
    add?: any[];
    remove?: any[];
    replace?: { source: any; target: any }[];
}) => action(MODIFY_NON_FIELD_FEATURES, { modifications });

export const setVisibleNonFieldFeatures = (features: Immutable.Set<NonFieldFeature>) =>
    action(SET_VISIBLE_NON_FIELD_FEATURES, { features });

export const modifyVisibleNonFieldFeatures = (modifications: { add?: any[]; remove?: any[] }) =>
    action(MODIFY_VISIBLE_NON_FIELD_FEATURES, { modifications });

export const setSelectedNonFieldFeatures = (features: Immutable.Set<NonFieldFeature>) =>
    action(SET_SELECTED_NON_FIELD_FEATURES, { features });

export const modifySelectedNonFieldFeatures = (modifications: { add?: any[]; remove?: any[] }) =>
    action(MODIFY_SELECTED_NON_FIELD_FEATURES, { modifications });

export const setHighlightedNonFieldFeatures = (features: Immutable.Set<NonFieldFeature>) =>
    action(SET_HIGHLIGHTED_NON_FIELD_FEATURES, { features });

export const modifyHighlightedNonFieldFeatures = (modifications: { add?: any[]; remove?: any[] }) =>
    action(MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES, { modifications });
