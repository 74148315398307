import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ATTRIBUTE_NAME = "attributeName";
export const PROPS_PLANT_PART_NAME = "plantPartName";
export const PROPS_CAN_DELETE = adminData.PROPS_CAN_DELETE;
export const PROPS_CEC_MAX = "cecMax";
export const PROPS_CEC_MIN = "cecMin";
export const PROPS_CROPS_CLASS_NAME = "cropClassName";
export const PROPS_CROP_CLASS_NAME = "cropClassName";
export const PROPS_CROP_LIST = "cropList";
export const PROPS_CROP_NAME = "cropName";
export const PROPS_GROWTH_STAGE_ORDER = "growthStageOrder";
export const PROPS_HIGH = "high";
export const PROPS_IMPORT_ATTRIBUTE = "importAttribute";
export const PROPS_PLANT_PART = "plantPart";
export const PROPS_IMPORT_ATTRIBUTE_LIST = "importAttributeList";
export const PROPS_PLANT_PART_LIST = "plantPartList";
export const PROPS_LOW = "low";
export const PROPS_MEDIUM = "medium";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NUTRIENT_TARGET_VALUE = "targetValue";
export const PROPS_NUTRIENT_TARGET_VALUE_GUID = "targetValueGuid";
export const PROPS_NUTRIENT_TARGET_VALUE_ID = "targetValueId";
export const PROPS_ORG_LEVEL = "orgLevel";
export const PROPS_ORG_LEVEL_LIST = adminData.PROPS_ORG_LEVEL_LIST;
export const PROPS_ORG_LEVEL_NAME = "orgLevelName";
export const PROPS_ORG_LEVEL_PARENT = "orgLevelParents";
export const PROPS_SAMPLE_TYPE_GUID = "sampleTypeGuid";
export const PROPS_SAMPLE_TYPE_NAME = "sampleTypeName";
export const PROPS_TOXIC = "toxic";
export const PROPS_VERY_HIGH = "veryHigh";
export const PROPS_VERY_LOW = "veryLow";
