import React, { Component } from "react";
import { InjectedIntl, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Menu } from "~/core";
import {
    actions as cdActions,
    models as cdModels,
    selectors as cdSelectors,
} from "~/customer-data";
import { messages as globalMessages } from "~/i18n-messages";
import { selectors as loginSelectors } from "~/login";
import { mapActions } from "~/map";
import { PersonalityTypes } from "~/utils/keywords";

import { messages } from "../i18n-messages";
import { DeleteConfDialog } from "../item-delete-confirmation-dialog";

import * as fieldActions from "~/action-panel/components/field-module/actions";
import * as fieldSelectors from "~/action-panel/components/field-module/selectors";
import * as fieldListSelectors from "~/action-panel/components/field-module/components/field-list/selectors";
import * as fieldListModels from "~/action-panel/components/field-module/components/field-list/models";
import { fetchCustomer } from "~/admin/setup/customer/data/actions";

import * as nonFieldFeatureActions from "../../layer-module/components/non-field-feature-information/actions";

import { IFieldsToMove } from "../../field-module/models";
import { SearchAPI } from "@ai360/core";

import "./customer-context-menu.css";
import { CustomerInfo, ICustomerFieldMapping } from "~/customer-data/models";
import { activeFilter, filteredSelectCustomerFieldRequest } from "~/utils/api/search";

interface ICustomerContextMenuProps {
    activeTab: fieldActions.FieldListTabs;
    customer: cdModels.CustomerInfo;
    fetchCustomer: (customerGuid: string) => void;
    fieldCount: number;
    filterSelections: fieldListModels.IFieldFilterSelections;
    intl: InjectedIntl;
    isDisconnectedPersonality: boolean;
    isLockCustomer: boolean;
    loadDetailPage: (...args) => void;
    selectedFields: string[];
    onActivateCustomer: (customerGuid: string) => void;
    onAddCustomerFieldMappings: (mappings: ICustomerFieldMapping[]) => void;
    onAddEditField: (customer: CustomerInfo, fieldGuid: string | null) => void;
    onAddNonFieldFeatures: (customer: Record<string, any>) => void;
    onClearSelectedCustomerFields: (fieldGuidList: string[]) => void;
    onDeleteCustomer: (customerGuid) => void;
    onSelectAllCustomerFields: (fieldGuidList: string[]) => void;
    onSetFieldsToMove: (fieldsToMove: IFieldsToMove) => void;
    onSetZoomToCustomer: (customerGuid: string) => void;
    onFetchFullFieldRecords: (fieldGuidList: string[]) => void;
    userRole: any;
    userGuid: string;
}

interface ICustomerContextMenuState {
    isDeleteConfirmationOpen: boolean;
}

class CustomerContextMenu_ extends Component<ICustomerContextMenuProps, ICustomerContextMenuState> {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteConfirmationOpen: false,
        };
    }

    public render() {
        const { formatMessage } = this.props.intl;
        const { customer, isDisconnectedPersonality, onDeleteCustomer } = this.props;

        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  className: "dialog-expanded",
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: isDisconnectedPersonality
                      ? messages.deleteCustomerConfirmation
                      : messages.deleteCustomerConfirmation2,
                  onConfirmationClick: (confirmation) =>
                      this.setState(
                          {
                              isDeleteConfirmationOpen: false,
                          },
                          !confirmation ? undefined : () => onDeleteCustomer(customer.customerGuid)
                      ),
              });

        return (
            <div className="customer-context-menu-container">
                <Menu
                    className="context-menu customer-context-menu"
                    isDotMenu={true}
                    getMenuItems={this.getMenuItems}
                />
                {deleteConfDialog}
            </div>
        );
    }

    private onSelectAllFields = async () => {
        const {
            onSelectAllCustomerFields,
            onAddCustomerFieldMappings,
            userGuid,
            customer,
            activeTab,
            filterSelections,
        } = this.props;
        const fetched = await SearchAPI.selectCustomerFields(
            filteredSelectCustomerFieldRequest(
                activeFilter(activeTab),
                userGuid,
                customer.customerGuid,
                filterSelections
            )
        );
        onAddCustomerFieldMappings(
            fetched.map((x) => ({
                fieldGuid: x.fieldId,
                customerGuid: x.customerId,
            }))
        );
        onSelectAllCustomerFields(fetched.map((x) => x.fieldId));
    };

    private onClearAllFields = async () => {
        const { onClearSelectedCustomerFields, userGuid, customer } = this.props;
        const fetched = await SearchAPI.selectCustomerFields({
            userGuid,
            customerGuid: customer.customerGuid,
        });
        onClearSelectedCustomerFields(fetched.map((x) => x.fieldId));
    };

    private getMenuItems = (): Array<{
        key: number;
        label: string;
        action: () => any;
    }> => {
        const { formatMessage } = this.props.intl;

        const {
            activeTab,
            customer,
            fetchCustomer,
            fieldCount,
            isLockCustomer,
            loadDetailPage,
            onActivateCustomer,
            onAddEditField,
            onAddNonFieldFeatures,
            onSetZoomToCustomer,
            onSetFieldsToMove,
            onFetchFullFieldRecords,
            selectedFields,
            userRole,
            userGuid,
        } = this.props;

        const blockedByEnrollment = isLockCustomer === true && !customer.enrolledYn;

        const setFieldsToMove = async () => {
            const fieldsPromise = SearchAPI.getFields({
                customerGuid: [customer.customerGuid],
                userGuid,
            });
            const fields = await fieldsPromise;
            onSetFieldsToMove({
                customerGuid: customer.customerGuid,
                fieldGuids: [...selectedFields],
                fields,
                customer,
            });
        };

        const customerContextMenuItems: any[] = [];
        if (activeTab !== fieldActions.FieldListTabs.INACTIVE) {
            if (userRole.customerEdit) {
                customerContextMenuItems.push({
                    key: 0,
                    label: formatMessage(messages.viewEditCustomerText),
                    action: () => {
                        loadDetailPage("213", "101");
                        fetchCustomer(customer.customerGuid);
                    },
                });
            }
            if (fieldCount > 0) {
                customerContextMenuItems.push(
                    {
                        key: 1,
                        label: formatMessage(messages.zoomToFieldsText),
                        action: () => onSetZoomToCustomer(customer.customerGuid),
                    },
                    {
                        key: 2,
                        label: formatMessage(messages.selectAllFieldsText),
                        action: () => this.onSelectAllFields(),
                        disabled: selectedFields.length === fieldCount,
                    },
                    {
                        key: 3,
                        label: formatMessage(messages.clearSelectedFieldsText),
                        action: () => this.onClearAllFields(),
                        disabled: selectedFields.length === 0,
                    }
                );
            }
            if (userRole.customerDelete) {
                customerContextMenuItems.push({
                    key: 4,
                    label: formatMessage(messages.deleteCustomerText),
                    action: () => this.setState({ isDeleteConfirmationOpen: true }),
                });
            }
            if (userRole.nonFieldFeatures) {
                customerContextMenuItems.push({
                    key: 5,
                    label: formatMessage(messages.addNonFieldFeatureText),
                    action: () => onAddNonFieldFeatures(customer),
                });
            }
            if (userRole.combineFields) {
                customerContextMenuItems.push({
                    key: 6,
                    label: formatMessage(messages.combineFieldsText),
                    action: () => onFetchFullFieldRecords([...selectedFields]),
                    disabled: selectedFields.length < 2 || blockedByEnrollment,
                });
            }
            if (userRole.moveField) {
                customerContextMenuItems.push({
                    key: 7,
                    label: formatMessage(messages.moveFieldsText, {
                        count: selectedFields.length,
                    }),
                    action: setFieldsToMove,
                    disabled: selectedFields.length === 0,
                });
            }
            if (userRole.manageFields) {
                customerContextMenuItems.push({
                    key: 8,
                    label: formatMessage(messages.addNewFieldText),
                    action: () => onAddEditField(customer, null),
                });
            }
        } else if (userRole.customerAdd && userRole.customerDelete && userRole.customerEdit) {
            customerContextMenuItems.push({
                key: 0,
                label: formatMessage(messages.activateCustomerText),
                action: () => onActivateCustomer(customer.customerGuid),
                disabled: customer && customer.activeYn,
            });
            if (userRole.moveField) {
                customerContextMenuItems.push({
                    key: 1,
                    label: formatMessage(messages.moveFieldsText, {
                        count: selectedFields.length,
                    }),
                    action: setFieldsToMove,
                    disabled: selectedFields.length === 0,
                });
            }
        }

        if (customerContextMenuItems.length === 0) {
            customerContextMenuItems.push({
                key: 0,
                label: formatMessage(messages.viewEditCustomerText),
                action: () => null,
                disabled: true,
            });
        }

        return customerContextMenuItems;
    };
}

const mapStateToProps = (state, ownProps) => ({
    activeTab: fieldSelectors.getActiveTab(state),
    filterSelections: fieldListSelectors.getFilterSelections(state),
    isDisconnectedPersonality:
        loginSelectors.getTheUserPersonalityId(state) === PersonalityTypes.DISCONNECTED,
    isLockCustomer: loginSelectors.getTheUserLockCustomer(state),
    selectedFields: cdSelectors.getSelectedFieldGuidsForCustomer(
        state,
        ownProps.customer.customerGuid
    ),
    userRole: loginSelectors.getUser(state).role,
    userGuid: loginSelectors.getTheUserGuid(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomer: (customerGuid) => dispatch((fetchCustomer as any)(customerGuid)),
    onActivateCustomer: (customerGuid) => dispatch(fieldActions.activateCustomer(customerGuid)),
    onAddCustomerFieldMappings: (mappings) =>
        dispatch(cdActions.addCustomerFieldMappings(mappings)),
    onAddNonFieldFeatures: (customer) =>
        dispatch(nonFieldFeatureActions.startAddingFeature(customer)),
    onClearSelectedCustomerFields: (fieldGuidList) =>
        dispatch(cdActions.clearSelectedFields(fieldGuidList)),
    onDeleteCustomer: (customerGuid) => dispatch(fieldActions.deleteCustomer(customerGuid)),
    onSelectAllCustomerFields: (fieldGuidList) =>
        dispatch(cdActions.addSelectedFields(fieldGuidList)),
    onSetFieldsToMove: (fieldsToMove) => dispatch(fieldActions.setFieldsToMove(fieldsToMove)),
    onSetZoomToCustomer: (customerGuid) => dispatch(mapActions.setZoomToCustomer(customerGuid)),
    onFetchFullFieldRecords: (fieldGuidList) =>
        dispatch(fieldActions.fetchFullFieldRecords(fieldGuidList)),
});

export const CustomerContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CustomerContextMenu_));
