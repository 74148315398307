export const RESET_ANALYSIS_AREA_POLYGONS = "rec-events/RESET_ANALYSIS_AREA_POLYGONS";
export const SET_ANALYSIS_ERROR_CODES = "rec-events/SET_ANALYSIS_ERROR_CODES";
export const SET_ANALYSIS_LAYER_TYPES = "rec-events/SET_ANALYSIS_LAYER_TYPES";
export const SET_ANALYSIS_SUMMARY_MAP = "rec-events/SET_ANALYSIS_SUMMARY_MAP";
export const UPDATE_ANALYSIS_AREA_POLYGONS = "rec-events/UPDATE_ANALYSIS_AREA_POLYGONS";

export const resetAnalysisAreaPolygons = (fieldGuid, areaIdToPolygonMap) => ({
    type: RESET_ANALYSIS_AREA_POLYGONS,
    payload: { fieldGuid, areaIdToPolygonMap },
});

export const setAnalysisLayerTypes = (analysisLayerTypes) => ({
    type: SET_ANALYSIS_LAYER_TYPES,
    payload: { analysisLayerTypes },
});

export const setAnalysisErrorCodesList = (analysisDetailsErrorCodeList) => ({
    type: SET_ANALYSIS_ERROR_CODES,
    payload: { analysisDetailsErrorCodeList },
});

export const setAnalysisSummaryMap = (analysisSummary, fieldGuids) => ({
    type: SET_ANALYSIS_SUMMARY_MAP,
    payload: { analysisSummary, fieldGuids },
});

export const updateAnalysisAreaPolygons = (fieldGuid, areaIdToPolygonMap) => ({
    type: UPDATE_ANALYSIS_AREA_POLYGONS,
    payload: { fieldGuid, areaIdToPolygonMap },
});
