import React, { Component, FunctionComponent, ReactElement, ReactNode } from "react";
import { injectIntl } from "react-intl";
import Select, { MultiValue, SelectInstance } from "react-select";
import classnames from "classnames";
import { CloseIcon, DownArrowIcon } from "~/core/icons";
import { IFormatter, ISelectOption } from "@ai360/core";
import { messages } from "../../i18n-messages";
import "./select-input-multi.css";
type OptionType = string | boolean | number;
interface ISelectInputMultiProps<T extends OptionType> {
    containerClassNames?: string[];
    intl: IFormatter;
    isClearable?: boolean;
    isDisabled?: boolean;
    onBlur?: () => void;
    onChange?: (options: T[]) => void;
    onFocus?: () => void;
    options: MultiValue<ISelectOption<T>>;
    optionIsHiddenKey?: string;
    placeholder?: string;
    required?: boolean;
    showTopLabel?: boolean;
    /** Indicates how selected items are sorted. Setting to `true` means
     * items are sorted alphabetically, setting to `false` means newly
     * selected items are pushed to the beginning of the list, and providing
     * a compare function provides custom sorting.
     */
    sort?: boolean | ((a: ISelectOption<T>, b: ISelectOption<T>) => number);
    tabIndex?: number;
    value?: ISelectOption<T>[] | T[];
}

interface ISelectInputMultiState<T extends OptionType> {
    selectedOptionsList: ISelectOption<T>[];
    isMenuOpen: boolean;
}

interface DropdownProps {
    readonly children?: ReactNode;
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
    children,
    isOpen,
    target,
}: DropdownProps) => (
    <div className={"multi-select-dropdown"}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
    </div>
);

const Menu = (props: JSX.IntrinsicElements["div"]) => {
    return <div className={"dropdown-menu"} {...props} />;
};

export class SelectInputMulti_<T extends OptionType = string> extends Component<
    ISelectInputMultiProps<T>,
    ISelectInputMultiState<T>
> {
    REQUIRED_TEXT = "*";
    selectInstance?: SelectInstance<any, true, any> | null;

    static defaultProps = {
        containerClassNames: "",
        isClearable: true,
        isDisabled: false,
        onBlur: (): void => null,
        onChange: (options): void => null,
        onFocus: (): void => null,
        options: [],
        optionIsHiddenKey: null,
        placeholder: null,
        required: false,
        showTopLabel: true,
        sort: false,
        tabIndex: null,
        value: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOptionsList: this._getSelectedOptionsListFromValue(props),
            isMenuOpen: false,
        };
    }

    _getSelectedOptionsListFromValue(props: ISelectInputMultiProps<T>): ISelectOption<T>[] {
        const { value, options } = props;
        if (value && value.length > 0) {
            return value
                .map((v) => (typeof v === "object" ? v : options.find((o) => o.value === v)))
                .filter(Boolean);
        }
        return [];
    }

    _filterOptions(): MultiValue<ISelectOption<T>> {
        const { options, optionIsHiddenKey } = this.props;
        if (!optionIsHiddenKey) {
            return this._sortOptions([...options]);
        }
        const filteredOptions = options.filter((o) => {
            return o[optionIsHiddenKey] === true;
        });
        return this._sortOptions(filteredOptions);
    }

    _isSelected(option: ISelectOption<T>): boolean {
        const { selectedOptionsList } = this.state;
        return selectedOptionsList.some((o) => o.value === option.value);
    }

    _getHeader(): JSX.Element {
        const { placeholder, required, showTopLabel } = this.props;
        const { selectedOptionsList } = this.state;
        const hasValue = selectedOptionsList.length > 0;
        const requiredValue = required && hasValue ? this._getRequiredStarEl() : null;
        const labelValue = showTopLabel && placeholder && hasValue ? placeholder + ":" : null;
        return (
            <div className="input-label-container">
                {requiredValue}
                {labelValue}
            </div>
        );
    }

    _getInputValue(): JSX.Element {
        const { placeholder, required } = this.props;
        const { selectedOptionsList } = this.state;
        const hasValue = selectedOptionsList.length > 0;
        const inputClassNames = classnames("input-placeholder", {
            required: required,
        });

        return hasValue ? (
            this._getSelectedString()
        ) : (
            <div className={inputClassNames}>
                {required ? this._getRequiredStarEl() : null}
                {placeholder}
            </div>
        );
    }

    _getRequiredStarEl(): JSX.Element {
        const { selectedOptionsList } = this.state;
        return (
            <span
                key="red-star"
                className={classnames(
                    "red-star",
                    { disabled: this.props.isDisabled },
                    { "value-selected": selectedOptionsList.length > 0 }
                )}
            >
                {this.REQUIRED_TEXT}
            </span>
        );
    }

    _getSelectedString(): JSX.Element {
        const { selectedOptionsList } = this.state;
        const selectedLabels = selectedOptionsList.map((o) => o.label);
        const displayString = selectedLabels.join(", ");

        return (
            <div className="selected-string" title={displayString}>
                {displayString}
            </div>
        );
    }

    _onBlur(): void {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
        this.setState({ isMenuOpen: false });
    }

    _onChange(newValueList: MultiValue<ISelectOption<T>>): void {
        const { selectedOptionsList } = this.state;
        if (this.props.onChange) {
            this.props.onChange(newValueList.map((v) => v.value as T));
        }
        const newItem = newValueList.find(
            (v) => !selectedOptionsList.some((s) => s.value === v.value)
        );
        let newSelectedOptionsList = [...selectedOptionsList];
        if (newItem) {
            newSelectedOptionsList.unshift(newItem);
            if (this.props.sort === true) {
                newSelectedOptionsList.sort((a, b) => a.label.localeCompare(b.label));
            } else if (typeof this.props.sort === "function") {
                newSelectedOptionsList.sort(this.props.sort);
            }
        } else {
            newSelectedOptionsList = selectedOptionsList.filter((v) =>
                newValueList.some((n) => n.value === v.value)
            );
        }
        this.setState({ selectedOptionsList: newSelectedOptionsList });
    }

    _onClear(): void {
        if (this.props.onChange) {
            this.props.onChange([]);
        }
        this.setState({ selectedOptionsList: [] });
    }

    _onFocus(): void {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
        this.setState({ isMenuOpen: true });
    }

    _sortOptions(options: ISelectOption<T>[]): ISelectOption<T>[] {
        const firstSort = options.sort((a, b) =>
            typeof this.props.sort === "function"
                ? this.props.sort(a, b)
                : a.label.localeCompare(b.label)
        );
        return firstSort.sort((a, b) =>
            this._isSelected(a) && this._isSelected(b) ? 0 : this._isSelected(a) ? -1 : 1
        );
    }

    _toggleOpen = (): void => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };

    shouldComponentUpdate(nextProps, nextState): boolean {
        if (
            this.props.containerClassNames !== nextProps.containerClassNames ||
            this.props.options !== nextProps.options ||
            this.state.selectedOptionsList !== nextState.selectedOptionsList ||
            this.state.isMenuOpen !== nextState.isMenuOpen ||
            this.props.isDisabled !== nextProps.isDisabled
        ) {
            return true;
        }
        return false;
    }

    render(): ReactElement {
        const { isClearable, isDisabled, tabIndex } = this.props;
        const { isMenuOpen, selectedOptionsList } = this.state;
        const { formatMessage } = this.props.intl;
        const names = this.props.containerClassNames ? [...this.props.containerClassNames] : [];
        const containerClassNames = classnames(names, "multi-select-container", {
            "menu-open": isMenuOpen,
        });
        const optionProps = {
            onMouseDown: (evt) => evt.preventDefault(),
            onClick: (evt) => this._toggleOpen(),
        };
        const headerValue = this._getHeader();
        const clearButton = isClearable ? (
            <span key="clear" className="clear-btn-icon" onMouseDown={this._onClear.bind(this)}>
                <CloseIcon />
            </span>
        ) : null;
        //maxMenuHeight in react-select is at a default of 300 and is not adjustable when using controlShouldRenderValue

        return (
            <div className={containerClassNames}>
                <Dropdown
                    isOpen={isMenuOpen}
                    onClose={this._toggleOpen}
                    target={
                        <div className={"select-form-input-multi form-input"} {...optionProps}>
                            {headerValue}
                            <div className={"input-container"} {...optionProps}>
                                {this._getInputValue()}
                                {clearButton}
                                <span className="expand-contract-arrow" {...optionProps}>
                                    <DownArrowIcon />
                                </span>
                            </div>
                        </div>
                    }
                >
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        className={"basic-multi-select"}
                        classNamePrefix={"select"}
                        closeMenuOnSelect={false}
                        controlShouldRenderValue={false}
                        components={{
                            DropdownIndicator: null,
                            IndicatorSeparator: null,
                        }}
                        hideSelectedOptions={false}
                        isClearable={false}
                        isDisabled={isDisabled}
                        isMulti={true}
                        menuPlacement={"auto"}
                        menuIsOpen={isMenuOpen}
                        noOptionsMessage={(obj: { inputValue: string }) => {
                            return (
                                <React.Fragment>
                                    {formatMessage(messages.noResultsMatch, {
                                        typedVal: obj.inputValue,
                                    })}
                                </React.Fragment>
                            );
                        }}
                        onBlur={() => this._onBlur()}
                        onChange={(newValue) => this._onChange(newValue)}
                        onFocus={() => this._onFocus()}
                        options={this._filterOptions()}
                        placeholder={""}
                        ref={(ref) => {
                            this.selectInstance = ref;
                        }}
                        tabIndex={tabIndex}
                        value={selectedOptionsList}
                    />
                </Dropdown>
            </div>
        );
    }
}
export const SelectInputMulti = injectIntl(SelectInputMulti_);
