import { defineMessages } from "react-intl";

export const messages = defineMessages({
    activateEvent: {
        id: "eventModule.contextMenu.activateEvent",
        defaultMessage: "Activate Event",
    },
    applyYieldCalibration: {
        id: "eventModule.yieldDialog.applyYieldCalibration",
        defaultMessage: "Apply",
    },
    clear: { id: "eventModule.contextMenu.clear", defaultMessage: "Clear" },
    copy: { id: "eventModule.contextMenu.copy", defaultMessage: "Copy" },
    cropMatchError: {
        id: "eventModule.contextMenu.cropMatchError",
        defaultMessage: "Selected Item(s) Cannot Have Different Crop",
    },
    delete: { id: "eventModule.contextMenu.delete", defaultMessage: "Delete" },
    deleteEventConfirmation: {
        id: "eventModule.confirmationDialog.deleteEventConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Event?",
    },
    deleteMultipleEventsConfirmation: {
        id: "eventModule.confirmationDialog.deleteMultipleEventsConfirmation",
        defaultMessage: "Are you sure you want to delete the selected Events?",
    },
    editECDataLayerProperties: {
        id: "eventModule.ecDataMenu.editECDataLayerProperties",
        defaultMessage: "Edit Layer Properties",
    },
    exportComplete: {
        id: "eventModule.exportSoilSamplingFile.exportComplete",
        defaultMessage: "Export Complete",
    },
    exportFailed: {
        id: "eventModule.exportSoilSamplingFile.exportFailed",
        defaultMessage: "Export Failed",
    },
    exportPoints: {
        id: "eventModule.contextMenu.exportPoints",
        defaultMessage: "Export Points",
    },
    exportSurface: {
        id: "eventModule.contextMenu.exportSurface",
        defaultMessage: "Export Surface",
    },
    exportEventData: {
        id: "eventModule.contextMenu.exportEventData",
        defaultMessage: "Export Data",
    },
    fieldLabel: {
        id: "eventModule.yieldDialog.fieldLabel",
        defaultMessage: "Field",
    },
    fieldsLabel: {
        id: "eventModule.yieldDialog.fieldsLabel",
        defaultMessage: "Fields",
    },
    mergeEventsConfirmation: {
        id: "eventModule.mergeEventsDialog.mergeEventsConfirmation",
        defaultMessage: "Are you sure you want to Merge the following Events into a single Event?",
    },
    overrideMoistureText: {
        id: "eventModule.yieldDialog.overrideMoistureText",
        defaultMessage: "Override",
    },
    resetYieldCalibration: {
        id: "eventModule.yieldDialog.resetYieldCalibration",
        defaultMessage: "Reset Selected",
    },
    resetYieldConfirmationMessage: {
        id: "eventModule.yieldDialog.resetYieldConfirmationMessage",
        defaultMessage:
            "Are you sure you want to Reset the currently selected Load(s) back to their Original Values?",
    },
    resetYieldConfirmationTitle: {
        id: "eventModule.yieldDialog.resetYieldConfirmationTitle",
        defaultMessage: "Confirm Reset",
    },
    resetYieldInvalidMessage: {
        id: "eventModule.yieldDialog.resetYieldInvalidMessage",
        defaultMessage:
            "You must select at least one calibrated Load in order to proceed with a Reset.",
    },
    saveText: {
        id: "eventModule.yieldDialog.saveText",
        defaultMessage: "Save",
    },
    unitMatchError: {
        id: "eventModule.contextMenu.unitMatchError",
        defaultMessage: "Selected Item(s) Cannot Have Different Yield Unit",
    },
    viewDetails: {
        id: "eventModule.contextMenu.viewDetails",
        defaultMessage: "View Results",
    },
    viewEditDetails: {
        id: "eventModule.contextMenu.viewEditDetails",
        defaultMessage: "View / Edit Details",
    },
    yieldCalibration: {
        id: "eventModule.contextMenu.yieldCalibration",
        defaultMessage: "Yield Calibration",
    },
    yieldCalibrationAdjustType: {
        id: "eventModule.yieldDialog.yieldCalibrationAdjustType",
        defaultMessage: "Yield Adjustment Type",
    },
    yieldCalibrationAverages: {
        id: "eventModule.yieldDialog.yieldCalibrationAverages",
        defaultMessage: "Summary of Averages",
    },
    yieldCalibrationAvgDryYield: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgDryYield",
        defaultMessage: "Avg Dry Yield",
    },
    yieldCalibrationAvgMoisture: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgMoisture",
        defaultMessage: "Avg Moisture",
    },
    yieldCalibrationAvgWetYield: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgWetYield",
        defaultMessage: "Avg Wet Yield",
    },
    yieldCalibrationAvgLintYield: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgLintYield",
        defaultMessage: "Avg Lint Yield",
    },
    yieldCalibrationAvgLintPct: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgLintPct",
        defaultMessage: "Lint %",
    },
    yieldCalibrationAvgSeedYield: {
        id: "eventModule.yieldDialog.yieldCalibrationAvgSeedYield",
        defaultMessage: "Avg Seed Yield",
    },
    yieldCalibrationCalibrated: {
        id: "eventModule.yieldDialog.yieldCalibrationCalibrated",
        defaultMessage: "Calibrated",
    },
    yieldCalibrationCalibratedLoads: {
        id: "eventModule.yieldDialog.yieldCalibrationCalibratedLoads",
        defaultMessage: "Calibrated Loads",
    },
    yieldCalibrationCrop: {
        id: "eventModule.yieldDialog.yieldCalibrationCrop",
        defaultMessage: "Crop",
    },
    yieldCalibrationLintPctLabel: {
        id: "eventModule.yieldDialog.yieldCalibrationLintPctLabel",
        defaultMessage: "Calibrate Lint % To",
    },
    yieldCalibrationLint: {
        id: "eventModule.yieldDialog.yieldCalibrationLint",
        defaultMessage: "Lint",
    },
    yieldCalibrationMoisture: {
        id: "eventModule.yieldDialog.yieldCalibrationMoisture",
        defaultMessage: "Moisture",
    },
    yieldCalibrationMoistureLabel: {
        id: "eventModule.yieldDialog.yieldCalibrationMoistureLabel",
        defaultMessage: "Calibrate Moisture To",
    },
    yieldCalibrationOriginal: {
        id: "eventModule.yieldDialog.yieldCalibrationOriginal",
        defaultMessage: "Original",
    },
    yieldCalibrationSelectLoads: {
        id: "eventModule.yieldDialog.yieldCalibrationSelectLoads",
        defaultMessage: "Select Equipment/Loads",
    },
    yieldCalibrationTitle: {
        id: "eventModule.yieldDialog.yieldCalibrationTitle",
        defaultMessage: "Yield Calibration",
    },
    yieldCalibrationTotalArea: {
        id: "eventModule.yieldDialog.yieldCalibrationTotalArea",
        defaultMessage: "Total Area",
    },
    yieldCalibrationTotalYield: {
        id: "eventModule.yieldDialog.yieldCalibrationTotalYield",
        defaultMessage: "Total Yield",
    },
    yieldCalibrationUnknown: {
        id: "eventModule.yieldDialog.yieldCalibrationUnknown",
        defaultMessage: "Unknown",
    },
    yieldCalibrationYield: {
        id: "eventModule.yieldDialog.yieldCalibrationYield",
        defaultMessage: "Yield",
    },
    yieldCalibrationYieldLabel: {
        id: "eventModule.yieldDialog.yieldCalibrationYieldLabel",
        defaultMessage: "Calibrate Yield To",
    },
    yieldWeightError: {
        id: "eventModule.yieldDialog.yieldWeightError",
        defaultMessage:
            "One or more events is missing test or unit weight required to do calibration",
    },
});
