import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { messages as actionPanelMessages } from "~/action-panel/i18n-messages";
import { mapSelectors, mapActions } from "~/map";
import { MapConfig } from "@ai360/core";

import { fieldBoundaryLayerInfoShape, layerInfoShape } from "~/map/components/map-control";

import { DynamicLayerPreferences } from "./dialog-boxes/dynamic-layer-preferences";
import { FieldLabels, FieldsLayerPreferences } from "./dialog-boxes/fields-layer-preferences";
import { DynamicLayer } from "./dynamic-layer";
import { GraphicsLayer } from "./graphics-layer";
import { messages } from "../../i18n-messages";
import * as selectors from "../selectors";

const DynamicLayerGroups = {
    NONE: 0,
    CADASTRAL: 1,
};
const legendInfosShape = PropTypes.arrayOf(
    PropTypes.shape({
        layerId: PropTypes.number,
        layerName: PropTypes.string,
        layerType: PropTypes.string,
        legend: PropTypes.arrayOf(
            PropTypes.shape({
                contentType: PropTypes.string,
                height: PropTypes.number,
                imageData: PropTypes.string,
                label: PropTypes.string,
                url: PropTypes.string,
                width: PropTypes.number,
            })
        ),
        maxScale: PropTypes.number,
        minScale: PropTypes.number,
    })
);

class StandardLayers_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        cadastralLayerInfos: PropTypes.arrayOf(layerInfoShape).isRequired,
        cadastralLegendInfos: legendInfosShape,
        fieldBoundaryLayerInfo: fieldBoundaryLayerInfoShape.isRequired,
        nonFieldFeatureLayerInfo: PropTypes.any,
        mapScale: PropTypes.number.isRequired,
        onSetFieldBoundaryLayerInfo: PropTypes.func.isRequired,
        onSetNonFieldFeatureLayerInfo: PropTypes.any,
        onUpdateCadastralLayerInfo: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dynamicLayerPrefs: {
                group: DynamicLayerGroups.NONE,
                layerInfo: null,
                open: false,
            },
            fieldsLayerPrefsOpen: false,
        };
    }

    _closeDynamicLayerPrefs() {
        this.setState({
            dynamicLayerPrefs: {
                layerInfo: null,
                open: false,
            },
        });
    }

    _closeFieldsLayerPrefs() {
        this.setState({
            fieldsLayerPrefsOpen: false,
        });
    }

    _openDynamicLayerPrefs(group, layerInfo) {
        this.setState({
            dynamicLayerPrefs: {
                group,
                layerInfo,
                open: true,
            },
        });
    }

    _openFieldsLayerPrefs() {
        this.setState({
            fieldsLayerPrefsOpen: true,
        });
    }

    _onCheckCadastral(e, layerInfo) {
        this.props.onUpdateCadastralLayerInfo({
            ...layerInfo,
            visible: e.checked,
        });
    }

    _onCheckFields(e) {
        this.props.onSetFieldBoundaryLayerInfo({
            ...this.props.fieldBoundaryLayerInfo,
            visible: e.checked,
        });
    }

    _onCheckNonFieldFeatures(e) {
        this.props.onSetNonFieldFeatureLayerInfo(e.checked);
    }

    _updateDynamicLayerShowLabels({ group, layerInfo }) {
        const newLayerInfo = {
            ...layerInfo,
            showLabels: !layerInfo.showLabels,
        };
        this.setState(
            {
                dynamicLayerPrefs: {
                    group,
                    layerInfo: newLayerInfo,
                    open: true,
                },
            },
            () => {
                switch (group) {
                    case DynamicLayerGroups.CADASTRAL:
                        this.props.onUpdateCadastralLayerInfo(newLayerInfo);
                        break;
                    //for future dynamic layers
                    default:
                        break;
                }
            }
        );
    }

    _updateFieldsLayerShowLabels(label) {
        const { fieldBoundaryLayerInfo } = this.props;
        const lbls = fieldBoundaryLayerInfo.showLabels;
        const newFieldBoundaryLayerInfo = {
            ...fieldBoundaryLayerInfo,
            showLabels: {
                customerName:
                    label === FieldLabels.CUSTOMER_NAME ? !lbls.customerName : lbls.customerName,
                farmName: label === FieldLabels.FARM_NAME ? !lbls.farmName : lbls.farmName,
                fieldArea: label === FieldLabels.FIELD_AREA ? !lbls.fieldArea : lbls.fieldArea,
                fieldName: label === FieldLabels.FIELD_NAME ? !lbls.fieldName : lbls.fieldName,
            },
        };
        this.props.onSetFieldBoundaryLayerInfo(newFieldBoundaryLayerInfo);
    }

    render() {
        const {
            cadastralLayerInfos,
            cadastralLegendInfos,
            fieldBoundaryLayerInfo,
            mapScale,
            nonFieldFeatureLayerInfo,
        } = this.props;
        const { dynamicLayerPrefs, fieldsLayerPrefsOpen } = this.state;
        const { formatMessage } = this.props.intl;
        const fieldsLbl = formatMessage(actionPanelMessages.fields);

        const fieldSymbols = [];
        const normalSymbol = {
            ...MapConfig.symbols.fields.normal,
            outline: { ...MapConfig.symbols.fields.normal.outline },
        };
        const filteredSymbol = {
            ...MapConfig.symbols.fields.filteredOut,
            outline: { ...MapConfig.symbols.fields.filteredOut.outline },
        };
        const selectedSymbol = {
            ...MapConfig.symbols.fields.selected,
            outline: { ...MapConfig.symbols.fields.selected.outline },
        };
        if (mapScale >= MapConfig.layers.fields.minScale) {
            normalSymbol.color = [...MapConfig.symbols.fields.normal.outline.color];
            filteredSymbol.outline.color = [
                ...MapConfig.symbols.fields.filteredOut.color.slice(0, 3),
                0,
            ];
            selectedSymbol.color = selectedSymbol.outline.color = [
                ...MapConfig.symbols.fields.selected.color.slice(0, 3),
                255,
            ];
        }
        fieldSymbols.push({ label: fieldsLbl, symbol: normalSymbol });
        fieldSymbols.push({
            label: formatMessage(messages.symbolsFilteredOut),
            symbol: filteredSymbol,
        });
        fieldSymbols.push({
            label: formatMessage(messages.symbolsSelected),
            symbol: selectedSymbol,
        });

        const nonFieldFeatureSymbols = [
            {
                label: formatMessage(messages.symbolsNonFieldFeatures),
                symbol: {
                    ...MapConfig.symbols.nonFieldFeatures.panel.swatch,
                    outline: {
                        ...MapConfig.symbols.nonFieldFeatures.panel.swatch.outline,
                    },
                },
            },
        ];

        const dynamicLayers = [];
        cadastralLegendInfos.forEach((legendInfo) => {
            const { layerName, legend, maxScale, minScale } = legendInfo;
            let layerInfo = cadastralLayerInfos.find((li) => li.name === layerName);
            if (!layerInfo) {
                layerInfo = {
                    name: layerName,
                    showLabels: true,
                    visible: false,
                };
            }
            dynamicLayers.push(
                <DynamicLayer
                    checked={layerInfo.visible}
                    key={layerName}
                    label={layerName}
                    legend={legend}
                    mapScale={mapScale}
                    maxScale={maxScale}
                    minScale={minScale}
                    onChecked={(e) => this._onCheckCadastral(e, layerInfo)}
                    onLayerPreferences={() =>
                        this._openDynamicLayerPrefs(DynamicLayerGroups.CADASTRAL, layerInfo)
                    }
                />
            );
        });

        return (
            <div className="standard-layers">
                <GraphicsLayer
                    checked={fieldBoundaryLayerInfo.visible}
                    onChecked={(e) => this._onCheckFields(e)}
                    onLayerPreferences={() => this._openFieldsLayerPrefs()}
                    symbols={fieldSymbols}
                />
                <GraphicsLayer
                    checked={nonFieldFeatureLayerInfo.visible}
                    onChecked={(e) => this._onCheckNonFieldFeatures(e)}
                    onLayerPreferences={() => {}}
                    symbols={nonFieldFeatureSymbols}
                />
                {dynamicLayers}
                <FieldsLayerPreferences
                    isOpen={fieldsLayerPrefsOpen}
                    layerName={fieldsLbl}
                    onChangeShowLabels={(e, label) => this._updateFieldsLayerShowLabels(label)}
                    onClose={() => this._closeFieldsLayerPrefs()}
                    showLabels={fieldBoundaryLayerInfo.showLabels}
                />
                <DynamicLayerPreferences
                    isOpen={dynamicLayerPrefs.open}
                    layerName={dynamicLayerPrefs.layerInfo && dynamicLayerPrefs.layerInfo.name}
                    onChangeShowLabels={() => this._updateDynamicLayerShowLabels(dynamicLayerPrefs)}
                    onClose={() => this._closeDynamicLayerPrefs()}
                    showLabels={
                        dynamicLayerPrefs.layerInfo && dynamicLayerPrefs.layerInfo.showLabels
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cadastralLayerInfos: mapSelectors.getCadastralLayerInfos(state),
    cadastralLegendInfos: selectors.getCadastralLegendInfos(state),
    fieldBoundaryLayerInfo: mapSelectors.getFieldBoundaryLayerInfo(state),
    mapScale: mapSelectors.getScale(state),
    nonFieldFeatureLayerInfo: mapSelectors.getNonFieldFeatureLayerInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSetFieldBoundaryLayerInfo: (layerInfo) =>
        dispatch(mapActions.setFieldBoundaryLayerInfo(layerInfo)),
    onSetNonFieldFeatureLayerInfo: (visible) =>
        dispatch(mapActions.setNonFieldFeatureLayerInfo(visible)),
    onUpdateCadastralLayerInfo: (layerInfo) =>
        dispatch(mapActions.updateCadastralLayerInfo(layerInfo)),
});

export const StandardLayers = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(StandardLayers_));
