import React, { CSSProperties } from "react";
import classNames from "classnames";
import * as HtmlEntities from "html-entities";
import { FormattingHelpers } from "@ai360/core";
import { DialogBox, NoLink } from "~/core";
import "./quick-summary-bar.css";
import { injectIntl, intlShape } from "react-intl";
import messages from "./i18n-messages";

const entities = new HtmlEntities.AllHtmlEntities();

export interface IQuickSummaryBarProps {
    userInfo: Record<string, any>;
    allCustomers?: number;
    fieldCount?: number;
    selectedAcres?: number;
    allEvents?: number;
    allRecs?: number;
    intl: intlShape;
}
export interface IQuickSummaryBarState {
    isModalOpen: boolean;
}
class QuickSummaryBar extends React.Component<IQuickSummaryBarProps, IQuickSummaryBarState> {
    static defaultProps = {
        allCustomers: 0,
        fieldCount: 0,
        selectedAcres: 0,
        allEvents: 0,
        allRecs: 0,
    };
    /// ----------------------------------------- ///
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }
    /// Event Handlers ///
    private onToggleModalClick = (newValue) => {
        this.setState({
            isModalOpen: newValue,
        });
    };
    /// ------------- ///

    render() {
        const { formatMessage, formatNumber } = this.props.intl;
        const hasRecommendations =
            this.props.userInfo.role && this.props.userInfo.role.recommendations;
        const hasAgEvents = this.props.userInfo.role && this.props.userInfo.role.agEvents;

        const eventStyle: CSSProperties = {
            visibility: hasAgEvents ? "visible" : "hidden",
        };
        const recommendationStyle: CSSProperties = {
            visibility: hasRecommendations ? "visible" : "hidden",
        };

        return (
            <div
                className={classNames([
                    "quick-summary-bar",
                    (!hasAgEvents && hasRecommendations) || (hasAgEvents && !hasRecommendations)
                        ? "four-totals-displayed"
                        : "",
                    !hasAgEvents && !hasRecommendations ? "three-totals-displayed" : "",
                ])}
            >
                <div className={"quick-summary-bar-title"}>{formatMessage(messages.titleText)}</div>
                <div className={"quick-summary-stats-container"}>
                    <div className={"quick-summary-stats"}>
                        <div className="all-customers-count">
                            {formatNumber(this.props.allCustomers)}
                        </div>
                        <div className="all-customers-text">
                            {formatMessage(messages.customerText, {
                                count: this.props.allCustomers,
                            })}
                        </div>
                    </div>
                    <div className={"quick-summary-stats"}>
                        <div className="all-fields-count">
                            {formatNumber(this.props.fieldCount)}
                        </div>
                        <div className="all-fields-text">
                            {formatMessage(messages.fieldText, {
                                count: this.props.fieldCount,
                            })}
                        </div>
                    </div>
                    <div className={"quick-summary-stats quick-summary-stats-acres"}>
                        <div className="selected-acres-count">
                            {formatNumber(this.props.selectedAcres, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </div>
                        <div className="selected-acres-text">
                            {formatMessage(messages.acreText, {
                                count: this.props.selectedAcres,
                            })}
                        </div>
                    </div>
                    {hasRecommendations && (
                        <div className={"quick-summary-stats"} style={recommendationStyle}>
                            <div className="all-recs-count">{formatNumber(this.props.allRecs)}</div>
                            <div className="all-recs-text">
                                {formatMessage(messages.recText, {
                                    count: this.props.allRecs,
                                })}
                            </div>
                        </div>
                    )}
                    {hasAgEvents && (
                        <div className={"quick-summary-stats"} style={eventStyle}>
                            <div className="all-events-count">
                                {formatNumber(this.props.allEvents)}
                            </div>
                            <div className="all-events-text">
                                {formatMessage(messages.eventText, {
                                    count: this.props.allEvents,
                                })}
                            </div>
                        </div>
                    )}
                </div>

                <div className={"copyright-msg"}>
                    <span className="copyright-text">
                        {entities.decode(this.props.userInfo.systemProperties.copyrightHolder)}
                    </span>
                    <NoLink
                        className="privacy-policy-link"
                        label={this.props.intl.formatMessage(messages.linkText)}
                        onClick={() => {
                            this.onToggleModalClick(true);
                        }}
                    />
                    <DialogBox
                        isOpen={this.state.isModalOpen}
                        title={this.props.userInfo.systemProperties.privacyPolicyTitle}
                        onClose={() => this.onToggleModalClick(false)}
                    >
                        <div className="privacy-policy-body">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.props.userInfo.systemProperties.privacyPolicyText,
                                }}
                            />
                            <br />
                            {formatMessage(messages.updatedText, {
                                date: FormattingHelpers.formatLongDate(
                                    this.props.userInfo.systemProperties.privacyPolicyUpdatedDate
                                ),
                            })}
                        </div>
                    </DialogBox>
                </div>
            </div>
        );
    }
}
export default injectIntl(QuickSummaryBar);
