export const ImportModulePages = {
    IMPORT_LIST: "IMPORT_LIST",
    IMPORT_WIZARD: "IMPORT_WIZARD",
};

export const CHECK_DATA_EXCHANGE = "import-module/CHECK_DATA_EXCHANGE";
export const CLEAR_ALL_USERS_TAB_FILES = "import-module/CLEAR_ALL_USERS_TAB_FILES";
export const CLOSE_IMPORT_WIZARD = "import-module/CLOSE_IMPORT_WIZARD";
export const DELETE_IMPORT_FILE_INFO_ITEM = "import-module/DELETE_IMPORT_FILE_INFO_ITEM";
export const DELETE_SELECTED_IMPORT_FILE_INFO_ITEMS =
    "import-module/DELETE_SELECTED_IMPORT_FILE_INFO_ITEMS";
export const DESELECT_IMPORT_FILE_INFO_ITEMS = "import-module/DESELECT_IMPORT_FILE_INFO_ITEMS";
export const LOAD_IMPORT_WIZARD = "import-module/LOAD_IMPORT_WIZARD";
export const RESET_FILTER_TYPE_GUID = "import-module/RESET_FILTER_TYPE_GUID";
export const SET_CAN_DELETE_STATUS_CODES = "import-module/SET_CAN_DELETE_STATUS_CODES";
export const SELECT_ALL_IMPORT_FILE_INFO_ITEMS = "import-module/SELECT_ALL_IMPORT_FILE_INFO_ITEMS";
export const SELECT_IMPORT_FILE_INFO_ITEM = "import-module/SELECT_IMPORT_FILE_INFO_ITEM";
export const SET_INIT_SCROLL_TOP = "import-module/SET_INIT_SCROLL_TOP";
export const SET_IS_LOADING = "import-module/SET_IS_LOADING";
export const SET_LAST_CLICKED_DIMIDX = "import-module/SET_LAST_CLICKED_DIMIDX";
export const SET_FILTER_TYPE_GUID = "import-module/SET_FILTER_TYPE_GUID";
export const SET_SCROLL_TO_INDEX = "import-module/SET_SCROLL_TO_INDEX";
export const SET_SHOW_FILTERS = "import-module/SET_SHOW_FILTERS";
export const SET_TEMPLATE_EXPANDED_PREF = "import-module/SET_TEMPLATE_EXPANDED_PREF";
export const SET_TYPE_EXPANDED_PREF = "import-module/SET_TYPE_EXPANDED_PREF";
export const UPDATE_SEARCH = "import-module/UPDATE_SEARCH";

export const checkDataExchange = () => ({
    type: CHECK_DATA_EXCHANGE,
});

export const clearAllUsersTabFiles = () => ({
    type: CLEAR_ALL_USERS_TAB_FILES,
});

export const closeImportWizard = (isCancel = false) => ({
    type: CLOSE_IMPORT_WIZARD,
    payload: { isCancel },
});

export const deleteImportFileInfoItem = (itemDimIdx) => ({
    type: DELETE_IMPORT_FILE_INFO_ITEM,
    payload: { itemDimIdx },
});

export const deleteSelectedImportFileInfoItems = (itemDimIdx) => ({
    type: DELETE_SELECTED_IMPORT_FILE_INFO_ITEMS,
    payload: { itemDimIdx },
});

export const deselectAllImportFileInfoItems = (importFileGuidSet) => ({
    type: DESELECT_IMPORT_FILE_INFO_ITEMS,
    payload: { importFileGuidSet },
});

export const loadImportWizard = (importFileGuidList, importType, importTemplate) => ({
    type: LOAD_IMPORT_WIZARD,
    payload: { importFileGuidList, importType, importTemplate },
});

export const resetTypeFilter = () => ({
    type: RESET_FILTER_TYPE_GUID,
});

export const setCanDeleteStatusCodes = (canDeleteStatusCodes) => ({
    type: SET_CAN_DELETE_STATUS_CODES,
    payload: { canDeleteStatusCodes },
});

export const selectAllImportFileInfoItems = (importFileGuidSet) => ({
    type: SELECT_ALL_IMPORT_FILE_INFO_ITEMS,
    payload: { importFileGuidSet },
});

export const selectImportFileInfoItem = (importFileGuid) => ({
    type: SELECT_IMPORT_FILE_INFO_ITEM,
    payload: { importFileGuid },
});

export const setInitScrollTop = (scrollTop) => ({
    type: SET_INIT_SCROLL_TOP,
    payload: { scrollTop },
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
});

export const setScrollToIndex = (scrollToIndex) => ({
    type: SET_SCROLL_TO_INDEX,
    payload: { scrollToIndex },
});

export const setLastImportFileItemClickedDimIdx = (lastImportFileItemClickedDimIdx) => ({
    type: SET_LAST_CLICKED_DIMIDX,
    payload: { lastImportFileItemClickedDimIdx },
});

export const setTypeFilter = (importTypeFilterGuid) => ({
    type: SET_FILTER_TYPE_GUID,
    payload: { importTypeFilterGuid },
});

export const setShowFilters = (showFilters) => ({
    type: SET_SHOW_FILTERS,
    payload: { showFilters },
});

export const updateSearchValue = (searchValue) => ({
    type: UPDATE_SEARCH,
    payload: { searchValue },
});
