import React, { Component, ReactElement, MouseEvent as ReactMouseEvent, ReactNode } from "react";
import classnames from "classnames";
import "./nested-data-table.css";
//Components
import TableRow from "../data-table/components/table-row";
// icons
import { DownArrowIcon } from "~/core/icons";
import { ITableService, IRecord, IIndex } from "../interfaces";

export interface INestedTableRowProps {
    childClassName?: string;
    childComponent?: ReactElement;
    classNames?: string;
    isExpandedRow?: boolean;
    lastSelectedRow?: IRecord;
    onItemSelection?: () => void;
    onRowSelection?: (
        event: ReactMouseEvent,
        selectItem: IRecord,
        isSelected?: boolean,
        defaultGuid?: IRecord
    ) => void;
    record?: IRecord;
    recordGuid?: string;
    selectedChildRow?: IRecord;
    selectedRow?: IRecord;
    service?: ITableService;
    toggleChildComponent?: (record: IRecord, recordService: IRecord) => ReactNode;
    columns?: string[];
    checked?: boolean;
    deleteSelected?: (selectedRows: IRecord | IRecord[], deleteFromRow?: boolean) => void;
    isCheckbox?: boolean;
    isEditable?: boolean;
    selected?: boolean;
}

export interface INestedTableRowState {
    isExpanded: boolean;
    selectedRow: IRecord;
    lastSelectedRow: IRecord;
}

export default class NestedTableRow extends Component<INestedTableRowProps, INestedTableRowState> {
    static defaultProps = {
        isExpandedRow: false,
    };

    constructor(props: INestedTableRowProps) {
        super(props);
        this.state = {
            isExpanded: props.isExpandedRow,
            selectedRow: { canDelete: null, modifiedDate: null },
            lastSelectedRow: { canDelete: null, modifiedDate: null },
        };
    }

    getExpandCollapseButton = (): ReactNode => {
        const className = classnames(
            this.state.isExpanded ? "collapse-button-rotate-45" : "collapse-button-rotate-90",
            "expand-collapse-icon"
        );
        return (
            <div className="nested-grid-expand-button">
                <DownArrowIcon className={className} />
            </div>
        );
    };

    toggleExpandCollapse = (): void => {
        const { record, service } = this.props;
        this.setState(
            {
                isExpanded: !this.state.isExpanded,
            },
            () => {
                if (this.props.toggleChildComponent) {
                    this.props.toggleChildComponent(record, record[service.guid]);
                }
            }
        );
    };
    UNSAFE_componentWillReceiveProps(nextProps: INestedTableRowProps): void {
        if (nextProps.selectedRow !== this.props.selectedRow) {
            this.setState({
                selectedRow: nextProps.selectedRow,
            });
        }
        if (nextProps.lastSelectedRow !== this.props.lastSelectedRow) {
            this.setState({
                lastSelectedRow: nextProps.lastSelectedRow,
            });
        }
    }

    render(): ReactNode {
        const {
            classNames,
            childComponent,
            service,
            record,
            childClassName,
            onItemSelection,
            onRowSelection,
        } = this.props;
        const className = classnames("nested-table-row", classNames);
        const childClassNames = classnames("child-component", childClassName);
        const childProps = {
            lastSelectedRow: this.state.lastSelectedRow,
            parentGuid: record[service.guid],
            parentId: record[service.id],
            parentName: record[service.name],
            selectedRow: this.state.selectedRow,
        };
        return (
            <div className={className}>
                <TableRow
                    {...this.props}
                    columns={this.props.columns}
                    service={this.props.service}
                    key={`nested-${service.modelName}-row`}
                    onItemSelection={onItemSelection}
                    leftContent={[
                        {
                            component: this.getExpandCollapseButton(),
                            action: this.toggleExpandCollapse,
                        },
                    ]}
                    onRowSelection={(event, selectItem) => {
                        onRowSelection(event, selectItem, true, service.getDefaultGuid(record));
                    }}
                />
                {this.state.isExpanded && (
                    <div className={childClassNames}>
                        {React.cloneElement(childComponent, { ...childProps })}
                    </div>
                )}
            </div>
        );
    }
}
