import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { NoLink } from "~/core";
import { DetailedApiStatus, getErrorMessages } from "~/i18n-error-messages";

import { messages } from "./i18n-messages";

import "./api-error-notification.css";

export class ApiErrorNotification_ extends PureComponent {
    static propTypes = {
        err: PropTypes.object.isRequired,
        intl: intlShape.isRequired,
        intlMessageObj: PropTypes.object,
        intlMessageValues: PropTypes.object,
        onRetry: PropTypes.func.isRequired,
        onShowErrDetails: PropTypes.func.isRequired,
        retryAction: PropTypes.object,
    };

    render() {
        const { err, intlMessageObj, intlMessageValues, onRetry, onShowErrDetails, retryAction } =
            this.props;
        const { formatMessage } = this.props.intl;

        const delimiter = "|";
        const errCodeMessages = getErrorMessages(formatMessage, err, delimiter);

        let errMsg = formatMessage(
            err.detailedStatus === DetailedApiStatus.ValidationError
                ? messages.warningApiErrorMsg
                : messages.defaultApiErrorMsg
        );

        if (intlMessageObj != null && typeof intlMessageObj === "string") {
            errMsg = intlMessageObj;
        } else if (intlMessageObj != null && typeof intlMessageObj === "object") {
            errMsg = formatMessage(intlMessageObj, intlMessageValues);
        }

        const showErrDetailsLink = (
            <NoLink
                onClick={() => onShowErrDetails(err)}
                label={formatMessage(messages.showErrDetailsLinkLabel)}
            />
        );
        const retryLink =
            err.detailedStatus !== DetailedApiStatus.HttpError || !retryAction ? null : (
                <NoLink onClick={() => onRetry()} label={formatMessage(messages.retryLinkLabel)} />
            );
        const linkSep = retryLink === null ? null : "|";

        return (
            <div className="api-error-notification">
                <div className="err-msg">
                    <div>{errMsg}</div>
                    {!errCodeMessages ? null : (
                        <ul>
                            {errCodeMessages.split(delimiter).map((s, i) => (
                                <li key={i}>{s}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="action-links">
                    {showErrDetailsLink}
                    {linkSep}
                    {retryLink}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRetry: () => {
        if (ownProps.onRetry) {
            ownProps.onRetry();
        }
        dispatch(ownProps.retryAction);
    },
});

export const ApiErrorNotification = connect(
    null,
    mapDispatchToProps
)(injectIntl(ApiErrorNotification_));
