export const NOTIFICATION_STATE_KEY = "NOTIFICATION_STATE";

export const getIsLoading = (state): any => {
    return state[NOTIFICATION_STATE_KEY].isLoading;
};

export const getNotificationList = (state): any => {
    return state[NOTIFICATION_STATE_KEY].notificationList;
};

export const getNotificationUnReadReportsCount = (state): any => {
    return state[NOTIFICATION_STATE_KEY].notificationList?.filter((n) => {
        return (
            n.read === false &&
            (n.detail.type === "reportCompleted" || n.detail.type === "reportFailed")
        );
    }).length;
};

export const getNotificationUnReadSamplingCount = (state): any => {
    return state[NOTIFICATION_STATE_KEY].notificationList?.filter((n) => {
        return (
            n.read === false &&
            (n.detail.type === "sampleResultsImported" ||
                n.detail.type === "sampleResultsImportFailed")
        );
    }).length;
};

export const getSamplingId = (state): any => {
    return state[NOTIFICATION_STATE_KEY].samplingId;
};
