import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import { getAgBytesErrorClassNames, onTextChange, onNumberChange } from "~/admin/utils";
// constants
import * as picklistService from "~/core/picklist/picklist-names";
// Components
import { TextInput, NumericInput, Section, SubSection, Checkbox } from "~/core";
import TraitCharacteristics from "./../components/trait-characteristics";
import TraitSpecifics from "./../components/trait-specifics";
import DeveloperList from "./../components/developer-list";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.gene,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    static GUID = "guid";
    static PICKLIST_GUID = "picklistValueGuid";

    constructor(props) {
        super(props);
        this.gene = {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupGeneRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([this.props.getNextId(), this.props.fetchPicklistData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.gene);
        }

        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.gene = nextProps.record;
            }
        }

        this.initializeDropdowns(nextProps);
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId) {
            this.gene[model.PROPS_GENE_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupGeneRecord = () => {
        const { addEditPanel } = this.props;
        this.gene = {};
        if (addEditPanel.mode === "ADD") {
            this.gene = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeTraitCharacteristics(nextProps);
        this.initializeTraitSpecifics(nextProps);
        this.initializeDevelopers(nextProps);
    };

    initializeTraitCharacteristics = (nextProps) => {
        if (nextProps[picklistService.PICKLIST_TRAIT_CHARACTERISTICS]) {
            this.setState({
                [picklistService.PICKLIST_TRAIT_CHARACTERISTICS]:
                    nextProps[picklistService.PICKLIST_TRAIT_CHARACTERISTICS],
            });
        }
    };

    initializeTraitSpecifics = (nextProps) => {
        if (nextProps[picklistService.PICKLIST_TRAIT_SPECIFICS]) {
            this.setState({
                [picklistService.PICKLIST_TRAIT_SPECIFICS]:
                    nextProps[picklistService.PICKLIST_TRAIT_SPECIFICS],
            });
        }
    };

    initializeDevelopers = (nextProps) => {
        if (nextProps[picklistService.PICKLIST_DEVELOPER]) {
            this.setState({
                [picklistService.PICKLIST_DEVELOPER]: nextProps[picklistService.PICKLIST_DEVELOPER],
            });
        }
    };

    onTextChange = (formKey, value, callback) => {
        if (
            formKey === model.PROPS_DEVELOPER_NAMES ||
            formKey === model.PROPS_TRAIT_CHARACTERISTIC_NAMES ||
            formKey === model.PROPS_TRAIT_SPECIFIC_NAMES
        ) {
            const valueArr = [];
            value.forEach((val) => {
                for (const prop in val) {
                    valueArr.push(val[prop]);
                }
            });
            this.gene = onTextChange(this.gene, { formKey: [formKey], value: valueArr }, callback);
        } else {
            this.gene = onTextChange(this.gene, { formKey: [formKey], value }, callback);
        }
    };

    onNumberChange = (formKey, value, callback) => {
        this.gene = onNumberChange(this.gene, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.gene[model.PROPS_GENE_NAME]]);
    };

    renderGeneInfo = () => {
        const { apiErrors, userRole } = this.props;
        const { formatMessage } = this.props.intl;
        const { gene } = this;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={getAgBytesErrorClassNames([180, 181], apiErrors, [
                                "form-input-id",
                            ])}
                            placeholderText={formatMessage(messages.geneId)}
                            labelText={formatMessage(messages.geneId)}
                            value={gene[model.PROPS_GENE_ID]}
                            onChange={(value) => this.onNumberChange(model.PROPS_GENE_ID, value)}
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={gene[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames([178, 179], apiErrors)}
                            placeholderText={formatMessage(messages.geneName)}
                            labelText={formatMessage(messages.geneName)}
                            value={gene[model.PROPS_GENE_NAME]}
                            autoFocus
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_GENE_NAME, value, this.setHeaderText)
                            }
                            required
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="add-edit-panel">
                {this.renderGeneInfo()}
                <div className="section-container">
                    <Section
                        required
                        className="grid-section"
                        headerText={formatMessage(messages.traitCharacteristic, { count: 0 })}
                    >
                        <TraitCharacteristics
                            apiErrors={this.props.apiErrors}
                            formatMessage={formatMessage}
                            options={this.state[picklistService.PICKLIST_TRAIT_CHARACTERISTICS]}
                            record={(this.gene[model.PROPS_TRAIT_CHARACTERISTIC_NAMES] || []).map(
                                (traitCharacteristicName) => ({
                                    traitCharacteristicName,
                                })
                            )}
                            itemListAlias={model.PROPS_TRAIT_CHARACTERISTIC_NAME}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_TRAIT_CHARACTERISTIC_NAMES, value)
                            }
                        />
                    </Section>
                    <span className="bar section-fence"></span>
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.traitSpecific, {
                            count: 0,
                        })}
                    >
                        <TraitSpecifics
                            formatMessage={formatMessage}
                            options={this.state[picklistService.PICKLIST_TRAIT_SPECIFICS]}
                            record={(this.gene[model.PROPS_TRAIT_SPECIFIC_NAMES] || []).map(
                                (traitSpecificName) => ({
                                    traitSpecificName,
                                })
                            )}
                            itemListAlias={model.PROPS_TRAIT_SPECIFIC_NAME}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_TRAIT_SPECIFIC_NAMES, value)
                            }
                        />
                    </Section>
                    <span className="bar section-fence"></span>
                    <Section
                        required
                        className="grid-section"
                        headerText={formatMessage(messages.developer, {
                            count: 0,
                        })}
                    >
                        <DeveloperList
                            apiErrors={this.props.apiErrors}
                            formatMessage={formatMessage}
                            options={this.state[picklistService.PICKLIST_DEVELOPER]}
                            record={(this.gene[model.PROPS_DEVELOPER_NAMES] || []).map(
                                (developerName) => ({ developerName })
                            )}
                            itemListAlias={model.PROPS_DEVELOPER_NAME}
                            onTextChange={(e, value) =>
                                this.onTextChange(model.PROPS_DEVELOPER_NAMES, value)
                            }
                        />
                    </Section>
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
