import * as model from "./model";

const productDefaultRecord = () => ({
    [model.PROPS_ACTIVE_YN]: true,
    [model.PROPS_AGGATEWAY_COMPANY_ID]: "",
    [model.PROPS_AGGATEWAY_PRODUCT_ID]: "",
    [model.PROPS_AGRIAN_ID]: "",
    [model.PROPS_AGX_ID]: "",
    [model.PROPS_ANGLE_OF_REPOSE]: "",
    [model.PROPS_BRAND_ORGANIZATION_GUID]: "",
    [model.PROPS_BRAND_ORGANIZATION_NAME]: "",
    [model.PROPS_BUFFER_DISTANCE_INFORMATION]: "",
    [model.PROPS_CHEMICAL_FAMILY_GUID]: "",
    [model.PROPS_CHEMICAL_FAMILY_NAME]: "",
    [model.PROPS_CROP_GUID]: "",
    [model.PROPS_CROP_ID]: "",
    [model.PROPS_CROP_NAME]: "",
    [model.PROPS_DENSITY_UNIT_NAME]: "",
    [model.PROPS_DENSITY]: "",
    [model.PROPS_DENSITY_UNIT_ABBREVIATION]: "",
    [model.PROPS_DENSITY_UNIT_GUID]: "",
    [model.PROPS_DOCUMENT_LIST]: [],
    [model.PROPS_FEDERALLY_RESTRICTED]: false,
    [model.PROPS_FORMULATION_TYPE_GUID]: "",
    [model.PROPS_FORMULATION_TYPE_NAME]: "",
    [model.PROPS_FREEZING_TEMPERATURE]: "",
    [model.PROPS_GRAZING_RESTRICTION]: false,
    [model.PROPS_INGREDIENT_LIST]: [],
    [model.PROPS_MANUFACTURER_GUID]: "",
    [model.PROPS_MANUFACTURER_NAME]: "",
    [model.PROPS_MEDICAL_PHONENUMBER_OBJ]: {},
    [model.PROPS_MODE_OF_ACTION]: "",
    [model.PROPS_NUTRIENT_LIST]: [],
    [model.PROPS_OXIDE]: false,
    [model.PROPS_PEST_INJURY_SYMPTOMS]: "",
    [model.PROPS_PH]: "",
    [model.PROPS_PHYSICAL_STATE_GUID]: "",
    [model.PROPS_PHYSICAL_STATE_NAME]: "",
    [model.PROPS_PPE_INFORMATION]: "",
    [model.PROPS_PPE_REENTRY_INFORMATION]: "",
    [model.PROPS_PRODUCT_ACTION_METHOD_GUID]: "",
    [model.PROPS_PRODUCT_ACTION_METHOD_NAME]: "",
    [model.PROPS_PRODUCT_GUID]: "",
    [model.PROPS_PRODUCT_ID]: "",
    [model.PROPS_PRODUCT_NAME]: "",
    [model.PROPS_PRODUCT_PACKAGE_LIST]: [],
    [model.PROPS_PRODUCT_PARENT_TYPE_GUID]: "",
    [model.PROPS_PRODUCT_PARENT_TYPE_NAME]: "",
    [model.PROPS_PRODUCT_PLANT_BACK_RESTRICTION_LIST]: [],
    [model.PROPS_PRODUCT_TYPE_LIST]: [],
    [model.PROPS_REGISTRATION_CODE]: "",
    [model.PROPS_REI_HOURS_HIGH]: "",
    [model.PROPS_REI_HOURS_LOW]: "",
    [model.PROPS_RESPONSE_PHONENUMBER_OBJ]: {},
    [model.PROPS_SALTING_OUT_TEMPERATURE]: "",
    [model.PROPS_SHELF_LIFE]: "",
    [model.PROPS_SIGNAL_WORD]: "",
    [model.PROPS_SOLUBILITY_IN_WATER]: "",
    [model.PROPS_SPECIAL_INSTRUCTIONS]: "",
    [model.PROPS_SPECIFIC_GRAVITY]: "",
    [model.PROPS_TREATMENT]: "",
    [model.PROPS_VARIETY_HYBRID_GUID]: "",
    [model.PROPS_VARIETY_HYBRID_ID]: "",
    [model.PROPS_VARIETY_HYBRID_NAME]: "",
    [model.PROPS_UFTRS_CODE]: "",
    [model.PROPS_STANDARD_RATE]: "",
    [model.PROPS_STANDARD_RATE_UNIT_GUID]: "",
});

export default productDefaultRecord;
