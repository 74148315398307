export const NEW = "NEW";
export const LB = "lb";
export const GAL = "gal";
export const OZ = "oz";
export const FLOZ = "floz";
export const PER100GAL = "100gal";
export const GALPERAC = "gal/ac";
export const LBPERAC = "lb/ac";
export const OZPERAC = "oz/ac";
export const FLOZPERAC = "fl oz/ac";
export const LBPERGAL = "lb/gal";
export const LBPERFT3 = "lb/ft3";
