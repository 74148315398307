import React, { Component, MouseEvent as ReactMouseEvent, ReactNode } from "react";
import TableRow from "./table-row";
import _ from "lodash";
import { ITableService, IIndex, IRecord } from "../../interfaces";

export interface ITableRowsProps {
    columns: string[];
    deleteSelected?: (selectedRows: IRecord | IRecord[], deleteFromRow?: boolean) => void;
    isCheckbox?: boolean;
    isEditable?: boolean;
    isModalWindow?: boolean;
    onItemSelection: (record: IRecord, value?: boolean) => void;
    onRowSelection?: (event: ReactMouseEvent, selectedRow: IRecord) => void;
    openAddEditPanel?: () => void;
    records: IRecord[];
    selectedItems: IRecord | IRecord[];
    selectedRow?: IRecord;
    service: ITableService;
    lastSelectedRow?: IRecord;
    setLastEditedRow?: () => void;
}

export interface ITableRowsState {
    selectedRow: IRecord;
    deselectAll: boolean;
}
export default class TableRows extends Component<ITableRowsProps, ITableRowsState> {
    constructor(props: ITableRowsProps) {
        super(props);
        this.state = {
            deselectAll: false,
            selectedRow: null,
        };
    }

    UNSAFE_componentWillReceiveProps({ selectedRow }: ITableRowsProps): void {
        if (!_.isEmpty(this.state.selectedRow) && selectedRow) {
            this.setState({
                selectedRow,
            });
        }
        // Clear last selected row if selected row is passed using the parent component
        // and set the new selected row
        if (_.isEmpty(this.state.selectedRow) && selectedRow && this.props.lastSelectedRow) {
            this.setState({
                selectedRow,
            });
            this.props.setLastEditedRow();
        }
    }

    onRowSelection = (event: ReactMouseEvent, selectedRow: IRecord): void => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        if (this.props.isEditable) {
            if (selectedRow === this.state.selectedRow) {
                this.setState({ selectedRow: null });
            } else {
                this.setState({
                    selectedRow,
                });
            }
        }
        if (this.props.onRowSelection) {
            this.props.onRowSelection(event, selectedRow);
            this.props.setLastEditedRow();
        }
    };

    isRowChecked = (record: IRecord): boolean => {
        const { service, selectedItems } = this.props;
        const selectedRecord = service.getDefaultGuid(record);
        return (
            _.findIndex(selectedItems as IRecord[], (item) => {
                return service.isItemSelected(item, selectedRecord);
            }) > -1
        );
    };

    renderRows = (): ReactNode => {
        const {
            columns,
            deleteSelected,
            isCheckbox,
            isEditable,
            isModalWindow,
            lastSelectedRow,
            onItemSelection,
            openAddEditPanel,
            records,
            service,
        } = this.props;
        const { selectedRow } = this.state;
        return records
            .filter((record) => record != null)
            .map((record, key) => (
                <TableRow
                    key={record[service.guid] || key}
                    record={record}
                    service={service}
                    columns={columns}
                    isCheckbox={isCheckbox}
                    isEditable={isEditable}
                    checked={this.isRowChecked(record)}
                    selected={service.isRowSelected({
                        selectedRow,
                        currentRecord: record,
                        lastSelectedRow,
                    })}
                    onItemSelection={onItemSelection}
                    openAddEditPanel={openAddEditPanel}
                    deleteSelected={deleteSelected}
                    onRowSelection={this.onRowSelection}
                    className={isModalWindow ? "dt-row-log-window" : ""}
                />
            ));
    };

    render(): ReactNode {
        return <div className="data-table-rows">{this.renderRows()}</div>;
    }
}
