import React, { Component } from "react";
import { connect } from "react-redux";
import { InjectedIntl, injectIntl } from "react-intl";

import { getActiveModule } from "~/action-panel";
import { Menu, DialogBox, DialogBoxFooterType } from "~/core";
import { getUser } from "~/login";
import { SurfaceType, Confirm } from "@ai360/core";
import { actions as recsEventsActions, recsSelectors, recsModels } from "~/recs-events";
import { LayerAPI, LayerUtilsAPI, UserAPI } from "@ai360/core";

import * as analysisActions from "../analysis-info/actions";
import * as imageryActions from "../imagery/actions";
import { messages } from "../i18n-messages";
import { keywords } from "../../../import-module/keywords";

import "./surface-layer-context-menu.css";

interface IProps {
    field: any;
    onEditProps: () => void;
    onLayerStats: () => void;
    onPrintLayer: (rendererGuid: string, renderPoints: boolean, imageryLayerGuid: string) => void;
    surfaceInfo: LayerAPI.ISubLayer;
    onSampleSites: boolean;
    onDeleteAnalysisLayer: (analysisLayerGuid: string, fieldGuid: string) => void;
    onDeleteAnalysisLayerBatch: (
        batchGuid: string,
        analysisInBatch: LayerAPI.IAnalysisInBatch[]
    ) => void;
    onDeleteImageryLayer: (imageryLayerGuid: string, fieldGuid: string) => void;
    type: number;
    activeModule: string;
    onCreateEventFromLayer: (
        field: any,
        surfaceInfo: LayerAPI.ISubLayer,
        activeModule: string
    ) => void;
    onCreateRecFromLayer: (
        field: any,
        surfaceInfo: LayerAPI.ISubLayer,
        recTypeInfo: recsModels.RecTypeInfo,
        activeModule: string
    ) => void;
    onShowAnalysisLayer: (analysisInfoGuid: string) => void;
    onShowImageryLayer: (surfaceInfo: LayerAPI.ISubLayer, fieldGuid: string) => void;
    recTypeOptions: { label: string; value: recsModels.RecTypeInfo }[];
    userInfo: UserAPI.IUser;
    intl: InjectedIntl;
}

interface IState {
    analysisLayerGuid: string;
    imageryLayerGuid: string;
    confirmationType: Confirm;
    isConfirmationModalOpen: boolean;
}

class SurfaceLayerContextMenu_ extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            analysisLayerGuid: "",
            imageryLayerGuid: "",
            confirmationType: Confirm.NONE,
            isConfirmationModalOpen: false,
        };
    }

    private onDeleteAll() {
        const { surfaceInfo } = this.props;

        if (this.state.confirmationType) {
            console.assert(
                this.state.analysisLayerGuid,
                "Deleting all is only supported by analysis at this time."
            );

            this.props.onDeleteAnalysisLayerBatch(
                surfaceInfo.batchGuid,
                surfaceInfo.analysisInBatch
            );
        }

        this.closeConfirmDialog();
    }

    private onDeleteSingle() {
        const { field } = this.props;
        if (this.state.confirmationType) {
            if (this.state.analysisLayerGuid) {
                this.props.onDeleteAnalysisLayer(this.state.analysisLayerGuid, field.fieldGuid);
            } else if (this.state.imageryLayerGuid) {
                this.props.onDeleteImageryLayer(this.state.imageryLayerGuid, field.fieldGuid);
            }
        }
        this.closeConfirmDialog();
    }

    private closeConfirmDialog() {
        this.setState({
            analysisLayerGuid: "",
            imageryLayerGuid: "",
            confirmationType: Confirm.NONE,
            isConfirmationModalOpen: false,
        });
    }

    private getMenuItems() {
        const {
            activeModule,
            field,
            onCreateEventFromLayer,
            onCreateRecFromLayer,
            onEditProps,
            onLayerStats,
            onPrintLayer,
            onSampleSites,
            onShowAnalysisLayer,
            onShowImageryLayer,
            recTypeOptions,
            surfaceInfo,
            type,
            userInfo,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const editMenuItems = [];
        if (onSampleSites) {
            editMenuItems.push({
                label: formatMessage(messages.printLayer),
                action: () => onPrintLayer(null, false, null),
            });
        } else {
            if (userInfo.role.layerAnalysis && this.isAnalysisLayer(surfaceInfo)) {
                if (userInfo.role.analysisLayers) {
                    if (type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
                        editMenuItems.push(
                            {
                                label: formatMessage(messages.editAnalysisLayer),
                                action: () => onShowAnalysisLayer(surfaceInfo.analysisLayerGuid),
                            },
                            {
                                label: formatMessage(messages.editLayerProperties),
                                action: () => onEditProps(),
                            },
                            {
                                label: formatMessage(messages.deleteAnalysisLayer),
                                action: () =>
                                    this.openConfirmDialog(
                                        Confirm.DELETE,
                                        surfaceInfo.analysisLayerGuid,
                                        null
                                    ),
                            }
                        );
                    } else {
                        editMenuItems.push(
                            {
                                label: formatMessage(messages.editAnalysisLayer),
                                action: () => onShowAnalysisLayer(surfaceInfo.analysisLayerGuid),
                            },
                            {
                                label: formatMessage(messages.deleteAnalysisLayer),
                                action: () =>
                                    this.openConfirmDialog(
                                        Confirm.DELETE,
                                        surfaceInfo.analysisLayerGuid,
                                        null
                                    ),
                            }
                        );
                    }
                }
            } else if (surfaceInfo.imageryLayerGuid) {
                editMenuItems.push(
                    {
                        label: formatMessage(messages.editImageryLayer),
                        action: () => onShowImageryLayer(surfaceInfo, field.fieldGuid),
                    },
                    {
                        label: formatMessage(messages.deleteImageryLayer),
                        action: () =>
                            this.openConfirmDialog(
                                Confirm.DELETE,
                                null,
                                surfaceInfo.imageryLayerGuid
                            ),
                    }
                );
            } else {
                editMenuItems.push({
                    label: formatMessage(messages.editLayerProperties),
                    action: () => onEditProps(),
                });
            }

            if (
                surfaceInfo.surfaceRendererGuid ||
                (surfaceInfo.imageryLayerGuid && surfaceInfo.importFileGuid)
            ) {
                editMenuItems.push({
                    label: formatMessage(messages.printLayer),
                    action: () =>
                        onPrintLayer(
                            surfaceInfo.surfaceRendererGuid,
                            SurfaceType.parse(surfaceInfo) === SurfaceType.CanvasLayer,
                            surfaceInfo.importFileGuid
                        ),
                });
            }

            if (
                surfaceInfo.agEventTransactionType === keywords.HARVEST &&
                type === LayerUtilsAPI.LayerType.EVENT_IMPORTED
            ) {
                editMenuItems.push({
                    label: formatMessage(messages.layerStats),
                    action: () => onLayerStats(),
                });
            }

            if (userInfo.role.agEvents && !surfaceInfo.imageryLayerGuid) {
                editMenuItems.push({
                    label: formatMessage(messages.createEventUsingLayer),
                    action: () => onCreateEventFromLayer(field, surfaceInfo, activeModule),
                    disabled:
                        surfaceInfo.surfaceTypeDisplayName === "Coverage" ||
                        surfaceInfo.surfaceTypeDisplayName === "Points",
                });
            }

            if (userInfo.role.recommendations && !surfaceInfo.imageryLayerGuid) {
                editMenuItems.push({
                    label: formatMessage(messages.createRecUsingLayer),
                    subMenuItems: recTypeOptions.map((recTypeOption) => ({
                        key: recTypeOption.label,
                        label: recTypeOption.label,
                        action: () =>
                            onCreateRecFromLayer(
                                field,
                                surfaceInfo,
                                recTypeOption.value,
                                activeModule
                            ),
                    })),
                    disabled:
                        surfaceInfo.surfaceTypeDisplayName === "Coverage" ||
                        surfaceInfo.surfaceTypeDisplayName === "Points",
                });
            }
        }
        return editMenuItems.map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    private openConfirmDialog(confirmationType, analysisLayerGuid, imageryLayerGuid) {
        this.setState({
            analysisLayerGuid: analysisLayerGuid ? analysisLayerGuid : null,
            imageryLayerGuid: imageryLayerGuid ? imageryLayerGuid : null,
            confirmationType,
            isConfirmationModalOpen: true,
        });
    }

    private isBatched(surfaceInfo) {
        return (
            this.isAnalysisLayer(surfaceInfo) &&
            surfaceInfo.analysisInBatch &&
            surfaceInfo.analysisInBatch.length > 1
        );
    }

    private isAnalysisLayer(surfaceInfo) {
        return surfaceInfo.analysisLayerTypeGuid && surfaceInfo.analysisLayerTypeGuid.length > 0;
    }

    private deleteDialogBox() {
        const { intl } = this.props;
        const { formatMessage } = intl;
        const { isConfirmationModalOpen } = this.state;
        return (
            <DialogBox
                draggable
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isConfirmationModalOpen}
                onAction={() => this.onDeleteSingle()}
                onClose={() => this.closeConfirmDialog()}
                title={formatMessage(messages.confirmDeleteTitle)}
            >
                {formatMessage(messages.confirmDeleteMsg)}
            </DialogBox>
        );
    }

    private batchedDeleteDialogBox() {
        const { intl, surfaceInfo } = this.props;
        const { formatMessage } = intl;
        const { isConfirmationModalOpen } = this.state;
        const body = formatMessage(messages.deleteMultipleBody, {
            analysisLayerName: surfaceInfo.analysisLayerName,
            fieldName: surfaceInfo.fieldName,
        });
        const fieldNameElements = surfaceInfo.analysisInBatch.map((x, i) => (
            <li className="list-item" key={i}>
                {x.fieldName}
            </li>
        ));
        return (
            <DialogBox
                className="surface-layer-context-menu-batch-dialog-box"
                footerType={DialogBoxFooterType.MULTI_ACTION}
                isOpen={isConfirmationModalOpen}
                multiActionList={[
                    {
                        action: formatMessage(messages.deleteAllButton),
                        actionDisabled: false,
                        onAction: () => this.onDeleteAll(),
                    },
                    {
                        action: formatMessage(messages.deleteSingleButton),
                        actionDisabled: false,
                        onAction: () => this.onDeleteSingle(),
                    },
                    {
                        action: formatMessage(messages.cancel),
                        actionDisabled: false,
                        isCancelStyle: true,
                        onAction: () => this.closeConfirmDialog(),
                    },
                ]}
                title={formatMessage(messages.confirmDeleteTitle)}
            >
                <div className="body">{body}</div>
                <ul className="list">{fieldNameElements}</ul>
            </DialogBox>
        );
    }

    render() {
        const { surfaceInfo } = this.props;
        const { confirmationType } = this.state;

        const deleteDialogBox =
            confirmationType !== Confirm.DELETE
                ? null
                : this.isBatched(surfaceInfo)
                ? this.batchedDeleteDialogBox()
                : this.deleteDialogBox();

        return (
            <div className="context-menu-container">
                <Menu
                    className="context-menu"
                    isDotMenu={true}
                    getMenuItems={() => this.getMenuItems()}
                />
                {deleteDialogBox}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    recTypeOptions: recsSelectors.getNewableRecTypeOptions(state),
    activeModule: getActiveModule(state),
    userInfo: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
    onCreateEventFromLayer: (field, surfaceInfo, activeModule) =>
        dispatch(recsEventsActions.createNewClassifiedEvent(field, surfaceInfo, "", activeModule)),
    onCreateRecFromLayer: (field, surfaceInfo, recTypeInfo, activeModule) =>
        dispatch(
            recsEventsActions.createNewClassifiedRec(field, surfaceInfo, recTypeInfo, activeModule)
        ),
    onShowAnalysisLayer: (analysisInfoGuid) =>
        dispatch(analysisActions.showAnalysisInfo(analysisInfoGuid)),
    onShowImageryLayer: (surfaceInfo, fieldGuid) =>
        dispatch(imageryActions.showImageryLayer(surfaceInfo, fieldGuid)),
    onDeleteAnalysisLayer: (analysisLayerGuid, fieldGuid) =>
        dispatch(analysisActions.deleteAnalysisLayer(analysisLayerGuid, fieldGuid)),
    onDeleteAnalysisLayerBatch: (batchGuid, analysisInBatch) =>
        dispatch(analysisActions.deleteAnalysisLayerBatch(batchGuid, analysisInBatch)),
    onDeleteImageryLayer: (imageryLayerGuid, fieldGuid) =>
        dispatch(imageryActions.deleteImageryLayer(imageryLayerGuid, fieldGuid)),
});

export const SurfaceLayerContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SurfaceLayerContextMenu_));
