import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { InjectedIntl, injectIntl } from "react-intl";

import { FormattingHelpers } from "@ai360/core";

import { Checkbox, SelectInput } from "~/core";
import { CloseIcon } from "~/core/icons";
import { getTheUserGuid } from "~/login";

import {
    actions as recsEventsActions,
    eventsSelectors,
    models as recsEventsModel,
} from "~/recs-events";

import { messages as commonMessages } from "../../../../../../common/rec-event-info/i18n-messages";

import { ACTIVE_YN } from "~/core/picklist";

import * as eventInfoSelectors from "../../../selectors";

import { getUser } from "~/login/selectors";
import { messages } from "../../../i18n-messages";
import "../../../../../../common/rec-event-info/rec-event-zone-info.css";
import "./sampling-zone-info.css";

interface ISamplingZoneInfoProps {
    EditForm: any;
    eventDetails: any;
    fieldGuid: string;
    layerNameToSurfaceInfoMap: Map<string, any>;
    intl: InjectedIntl;
    isLoading: boolean;
    onFetchLayerNameToSurfaceInfoMap: () => void;
    onResetZones: () => void;
    onSetEventAreaIsIncluded: (eventAreaId: number, isIncluded: boolean) => void;
    onSetEventZonesFromLayer: (surfaceInfoOption: any) => void;
    onSetZoneInterpolation: (setZoneInterpolation: boolean) => void;
    userInfo: any;
}

export class SamplingZoneInfo_ extends PureComponent<ISamplingZoneInfoProps> {
    public UNSAFE_componentWillMount(): void {
        const {
            eventDetails,
            fieldGuid,
            onFetchLayerNameToSurfaceInfoMap,
            onSetEventAreaIsIncluded,
        } = this.props;
        if (fieldGuid !== recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            onFetchLayerNameToSurfaceInfoMap();
        }
        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );

        if (haveClassBreaks) {
            eventDetails.eventAreaList.forEach((eventArea) =>
                onSetEventAreaIsIncluded(eventArea.eventAreaId, eventArea.applyEventToArea)
            );
        }
    }

    public render(): JSX.Element {
        const { EditForm, eventDetails, isLoading, fieldGuid } = this.props;

        if (isLoading) {
            return null;
        }

        console.assert(eventDetails.eventAreaList.length > 0);
        const area = eventDetails.eventAreaList[0];
        console.assert(area.agEventList.length > 0);
        const { agEventModel } = area.agEventList[0];

        return (
            <div className="rec-event-info-form sampling-tissue-zone-info">
                {this._getZoneClassLegend()}
                <EditForm agEventModel={agEventModel} fieldGuid={fieldGuid} />
            </div>
        );
    }

    private _getDisplayLayerSelectInput(): JSX.Element {
        const { fieldGuid, layerNameToSurfaceInfoMap } = this.props;
        const { formatMessage } = this.props.intl;

        if (fieldGuid === recsEventsModel.BATCH_TEMPLATE_FIELD_GUID) {
            return null;
        }
        const options =
            layerNameToSurfaceInfoMap == null
                ? []
                : [...layerNameToSurfaceInfoMap.entries()].map(([layerName, surfaceInfo]) => ({
                      label: layerName,
                      value: surfaceInfo,
                  }));

        return (
            <SelectInput
                containerClassNames={["display-layer-select-input"]}
                disabled={layerNameToSurfaceInfoMap == null}
                optionIsHiddenKey={ACTIVE_YN}
                placeholderText={formatMessage(messages.displayLayerPlaceholderTxt)}
                options={options}
                onChange={this._onSelectDisplayLayer}
            />
        );
    }

    private _getInterpolationType(): number {
        const { userInfo } = this.props;
        const { role } = userInfo;
        if (role.zoneInterpolation) {
            return recsEventsModel.InterpolationType.ZONE_INTERPOLATION;
        } else if (role.zoneSampling) {
            return recsEventsModel.InterpolationType.ZONE_SAMPLING;
        } else {
            return recsEventsModel.InterpolationType.STANDARD;
        }
    }

    private _getInterpolationLabel(interpolationType): string {
        const { formatMessage } = this.props.intl;
        switch (interpolationType) {
            case recsEventsModel.InterpolationType.ZONE_INTERPOLATION:
                return formatMessage(messages.zoneInterpolationCbLabel);
            case recsEventsModel.InterpolationType.ZONE_SAMPLING:
                return formatMessage(messages.zoneSamplingCbLabel);
            default:
                return null;
        }
    }

    private _getZoneClassLegend(): JSX.Element {
        const { eventDetails } = this.props;
        const { formatMessage } = this.props.intl;

        const haveClassBreaks = eventDetails.eventAreaList.some(
            (area) => area.eventAreaClassBreak != null
        );

        if (!haveClassBreaks) {
            return this._getDisplayLayerSelectInput();
        }

        const cbLabelToTotalAcresMap = new Map();
        for (const area of eventDetails.eventAreaList) {
            const areaClassBreak = area.eventAreaClassBreak;
            const { calculatedArea } = area;
            const key = areaClassBreak.label;
            const accum = cbLabelToTotalAcresMap.has(key) ? cbLabelToTotalAcresMap.get(key) : 0;
            cbLabelToTotalAcresMap.set(key, accum + calculatedArea);
        }

        const cbLabelToColorMap = eventDetails.eventAreaList.reduce((cbLabelToColorMap, area) => {
            const classBreak = area.eventAreaClassBreak;
            if (!cbLabelToColorMap.has(classBreak.label)) {
                cbLabelToColorMap.set(classBreak.label, classBreak.rgbCssStr);
            }
            return cbLabelToColorMap;
        }, new Map());

        const legendElements = [...cbLabelToColorMap.keys()].map((label) => {
            const rgbCssStr = cbLabelToColorMap.get(label);
            const legendColorStyle = { backgroundColor: rgbCssStr };
            const zoneSize = formatMessage(commonMessages.zoneSize, {
                calculatedArea: FormattingHelpers.formatNumber(cbLabelToTotalAcresMap.get(label)),
            });
            return (
                <div key={label} className="event-zone-classbreaks-legend">
                    <div className="event-zone-legend-color" style={legendColorStyle} />
                    <div className="event-zone-legend-label">
                        <span className="label-text" title={label}>
                            {label}
                        </span>
                        <span className="label-size"> ({zoneSize})</span>
                    </div>
                </div>
            );
        });

        console.assert(
            new Set(eventDetails.eventAreaList.map((area) => area.eventAreaClassBreak.heading))
                .size === 1
        );
        const legendHeading = eventDetails.eventAreaList[0].eventAreaClassBreak.heading;
        const { interpolationTypeId } = eventDetails.eventAreaList[0].agEventList[0].agEventModel;
        const interpolationType = this._getInterpolationType();
        const interpolate =
            interpolationTypeId !== recsEventsModel.InterpolationType.STANDARD &&
            interpolationTypeId === interpolationType;
        return (
            <div className="zone-legend-container">
                <div className="zone-legend-heading-container">
                    <div className="zone-legend-heading">{legendHeading}</div>
                    <span onClick={this._onClose}>
                        <CloseIcon />
                    </span>
                </div>
                {legendElements}
                {interpolationType === recsEventsModel.InterpolationType.STANDARD ? null : (
                    <Checkbox
                        className="interpolation-cb"
                        label={this._getInterpolationLabel(interpolationType)}
                        value={interpolate}
                        onChange={(evt, cbValue) => this._onSetZoneInterpolation(cbValue)}
                    />
                )}
            </div>
        );
    }

    private _onClose = (): void => {
        this._onSetZoneInterpolation(false);
        this.props.onResetZones();
    };

    private _onSelectDisplayLayer = (surfaceInfo): void => {
        const { onSetEventZonesFromLayer } = this.props;
        this._onSetZoneInterpolation(true);
        onSetEventZonesFromLayer(surfaceInfo);
    };

    private _onSetZoneInterpolation(cbValue): void {
        const { eventDetails, onSetZoneInterpolation } = this.props;
        const interpolationTypeId = cbValue
            ? this._getInterpolationType()
            : recsEventsModel.InterpolationType.STANDARD;
        const eventAreaList = eventDetails.eventAreaList.map((eventArea) => {
            return recsEventsModel.AgEventArea.updateAgEventArea(eventArea, {
                agEventList: eventArea.agEventList.map((agEvent) => {
                    return recsEventsModel.AgEvent.updateAgEvent(agEvent, {
                        agEventModel: agEvent.agEventModel.updateAgEventModel({
                            interpolationTypeId: interpolationTypeId,
                        }),
                    });
                }),
            });
        });
        onSetZoneInterpolation(eventAreaList);
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchLayerNameToSurfaceInfoMap: (fieldGuid: string) =>
        dispatch(recsEventsActions.fetchLayerNameToSurfaceInfoMap(fieldGuid)),
    onUpdateEventDetails: (fieldGuid: string, newProps: any) =>
        dispatch(recsEventsActions.updateEventDetails(fieldGuid, newProps)),
    setEventZonesFromLayer: (fieldGuid: string, surfaceInfo: any) =>
        dispatch(recsEventsActions.setEventZonesFromLayer(fieldGuid, surfaceInfo)),
    resetEventAreas: (fieldGuid: string) => dispatch(recsEventsActions.resetEventAreas(fieldGuid)),
    setEventAreaIsIncluded: (fieldGuid: string, areaId: number, isIncluded: boolean) =>
        dispatch(recsEventsActions.setAreaIsIncluded(fieldGuid, areaId, isIncluded)),
});

const mapStateToProps = (state) => {
    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);
    const { eventSummary, isLoading } = eventInfoSelectors.getModuleState(state);
    const { fieldGuid } = eventSummary;
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const { layerNameToSurfaceInfoMap } = eventsSelectors.getModuleState(state);

    return {
        eventDetails,
        fieldGuid,
        layerNameToSurfaceInfoMap,
        isLoading,
        userGuid: getTheUserGuid(state),
        userInfo: getUser(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const onSetEventZonesFromLayer = (surfaceInfo) =>
        dispatchProps.setEventZonesFromLayer(stateProps.fieldGuid, surfaceInfo);

    const onResetZones = () => dispatchProps.resetEventAreas(stateProps.fieldGuid);

    const onFetchLayerNameToSurfaceInfoMap = () =>
        dispatchProps.onFetchLayerNameToSurfaceInfoMap(stateProps.fieldGuid);

    const onSetZoneInterpolation = (eventAreaList) =>
        dispatchProps.onUpdateEventDetails(stateProps.fieldGuid, {
            eventAreaList,
        });

    const onSetEventAreaIsIncluded = (areaId: number, isIncluded: boolean) =>
        dispatchProps.setEventAreaIsIncluded(stateProps.fieldGuid, areaId, isIncluded);

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onFetchLayerNameToSurfaceInfoMap,
        onResetZones,
        onSetEventAreaIsIncluded,
        onSetEventZonesFromLayer,
        onSetZoneInterpolation,
    };
};

export const SamplingZoneInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SamplingZoneInfo_));
