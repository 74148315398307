import {
    all,
    call,
    put,
    select,
    take,
    takeLatest,
    takeEvery,
    CallEffect,
    SelectEffect,
    PutEffect,
    AllEffect,
} from "redux-saga/effects";

import { actions as accordionActions } from "~/accordion";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { actions as analysisActions, models as analysisModels } from "~/recs-events";

import { createLayerAccordionItems } from "../layer-list/layer-accordion/layer-item";

import * as analysisSelectors from "~/recs-events/analysis/selectors";
import {
    ProfitLossCostType,
    ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
} from "~/recs-events/analysis/model";
import { ClassificationMethods } from "~/models/classification-method";

import { LayerAPI, FieldAPI } from "@ai360/core";
import { Toolset, GeometryMath, GeometryUtils } from "@ai360/core";
import { getTheUserGuid, getTheUserCompanyGuid, getTheUserLockCustomer } from "~/login";

import { mapActions, mapToolsActions } from "~/map";

import * as actions from "./actions";
import * as selectors from "./selectors";

import * as layerModuleActions from "../../actions";
import * as layerListActions from "../layer-list/actions";
import * as actionPanelActions from "~/action-panel/actions";

import { actions as notificationActions } from "~/notifications";

import * as layerActions from "../layer-list/actions";
import * as layerSelectors from "../layer-list/selectors";
import { messages } from "../i18n-messages";
import { EcAttributes } from "~/models/ec-attribute";
import { eventsSelectors } from "~/recs-events";
import { eventListSelectors } from "../../../event-module";

import {
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import _ from "lodash";
import {
    apiFilterOptionsToLayerFilterInfo,
    filterLayerOptions,
    getAvailableFieldGuidSet,
} from "../../utils";

const _getFilterOptions = function* (userGuid: string) {
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const lockCustomersNotEnrolled = yield select(getTheUserLockCustomer);
    const enrolledFieldGuids = yield select(cdSelectors.getEnrolledFieldGuids);
    const availableFieldSet = yield call(
        getAvailableFieldGuidSet,
        selectedFieldGuidSet,
        lockCustomersNotEnrolled,
        enrolledFieldGuids
    );
    //get current layer filter options
    return yield call(
        LayerAPI.getLayerFilterOptions,
        Array.from(availableFieldSet.values()),
        userGuid
    );
};

const defaultSummary = function* (type, layers, analysisLayerAreaList, fieldGuids, typeGuid) {
    const create = (numClasses, specificProps) => ({
        type,
        typeGuid,
        layers,
        analysisLayerAreaList,
        name: "",
        numClasses,
        batchGuid: null,
        ...specificProps,
    });

    switch (type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
            return create("5", {
                agEventGeneralGuidList: yield _selectedEventGuids(),
                isMultipartZoneAutoSplitAllowed: () => true,
            });
        case analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS:
            return create("5", {
                cropGuid: null,
                sellingPrice: null,
                cost: {
                    type: ProfitLossCostType.CostPerAcre,
                    value: null,
                },
                croppingSeasonGuid: yield _defaultCroppingSeasonGuid(),
            });
        case analysisModels.ANALYSIS_INFO_NAME_EC_DATA: {
            const userGuid = yield select(getTheUserGuid);
            const attributes = yield call(LayerAPI.retrieveEcDataAttributes, fieldGuids, userGuid);
            yield put(actions.setEcDataAttributes(attributes));
            const attribute = _defaultAttribute(attributes);
            if (attribute == null) {
                return null;
            }

            const colorRampGuid = yield _defaultEcDataColorRampGuid(
                attribute.agEventGeneralGuid,
                attribute.importAttributeGuid
            );
            return create("5", {
                classificationMethod: ClassificationMethods.naturalBreaks.name,
                attribute: attribute.attribute,
                colorRampGuid,
            });
        }
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
            return create("5", {
                normalizedYieldGuid: "",
                useECData: false,
                isSeedStrongDH: false,
                is4Mation: false,
            });
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
            return create("6", {
                normalizedYieldGuid: "",
                useECData: false,
                isSeedStrongDH: true,
                is4Mation: false,
            });
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION:
            return create("6", {
                normalizedYieldGuid: "",
                useECData: false,
                isSeedStrongDH: false,
                is4Mation: true,
            });
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA:
            analysisLayerAreaList.forEach((boundary) => {
                boundary.applyAnalysisToArea = false;
            });
            return create("3", {
                normalizedYieldGuid: "",
                useECData: false,
                isSeedStrongDH: false,
                is4Mation: false,
                managementAreaData: {},
                managementAreaTemplateGuid: null,
                croppingSeasonGuid: yield _defaultCroppingSeasonGuid(),
                isMultipartZoneAutoSplitAllowed: () => true,
            });
        default:
            console.error("Unknown analysis layer type.");
            return null;
    }
};

const setAnalysisSummary = function* (type, summary, userGuid) {
    const fieldGuids = summary.layers.map((x) => x.fieldGuid);

    yield put(actions.setAnalysisSummary({ ...summary }));

    switch (type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD: {
            const analysisLayerGuid = summary.layers[0].analysisLayerGuid;
            const isNew = !analysisLayerGuid || analysisLayerGuid === "";
            const options = yield call(
                LayerAPI.getNormalizedYieldOptions,
                isNew ? fieldGuids[0] : summary.selectedField,
                userGuid
            );
            yield put(actions.setNormalizedYieldOptions(options));
            break;
        }
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION: {
            const options = yield call(LayerAPI.getSeedStrongOptions, fieldGuids[0], userGuid);
            yield put(actions.setSeedStrongOptions(options));
            break;
        }
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA: {
            const templates = yield call(LayerAPI.getManagementAreaTypes, userGuid);
            yield put(actions.setManagementAreaTypes(templates));
            break;
        }
        default:
            return;
    }
};

const fetchFields = async (fieldGuids: string[], userGuid: string) => {
    const fields: any[] = await Promise.all(fieldGuids.map(FieldAPI.getField));
    return fields.sort((x, y) => fieldGuids.indexOf(x) - fieldGuids.indexOf(y));
};

const runLayer = function* (summary, userGuid) {
    const execute = function* (run, update, valid) {
        yield call(valid, summary, userGuid);

        const analysisLayerGuid = summary.layers[0].analysisLayerGuid;
        const isNew = !analysisLayerGuid || analysisLayerGuid === "";
        const endpoint = isNew ? run : update;
        yield put(actions.closeAnalysisInfo());
        const result = yield call(endpoint, summary, userGuid);
        return result;
    };

    switch (summary.type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
            return yield execute(
                LayerAPI.runNormalizedYield,
                LayerAPI.updateNormalizedYield,
                LayerAPI.validNormalizedYield
            );
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION:
            return yield execute(
                LayerAPI.runSeedStrong,
                LayerAPI.updateSeedStrong,
                LayerAPI.validSeedStrong
            );
        case analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS:
            return yield execute(
                LayerAPI.runProfitLoss,
                LayerAPI.updateProfitLoss,
                LayerAPI.validProfitLoss
            );
        case analysisModels.ANALYSIS_INFO_NAME_EC_DATA:
            return yield execute(LayerAPI.runEcData, LayerAPI.updateEcData, LayerAPI.validEcData);
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA:
            return yield execute(
                LayerAPI.runManagementArea,
                LayerAPI.updateManagementArea,
                LayerAPI.validManagementArea
            );
        default:
            console.error("Unknown analysis layer type.");
            return null;
    }
};

const areasFromBoundaries = (boundaries, isManagementArea) => {
    const groups = _(boundaries)
        .groupBy((x) => x.fieldGuid)
        .toPairs();
    const refinedBoundaries =
        groups.size() === 1
            ? isManagementArea
                ? [
                      [
                          {
                              ...groups.value()[0][1][0],
                              shape: GeometryUtils.getCombinedBoundaries(
                                  groups.value()[0][1].map((b) => b.shape)
                              ),
                          },
                          1,
                      ],
                  ]
                : groups.value()[0][1].map((boundary, i) => [boundary, i + 1])
            : groups
                  .map((group) =>
                      _(group[1]).reduce(
                          (accumulator, boundary) => ({
                              ...boundary,
                              shape:
                                  accumulator == null
                                      ? boundary.shape
                                      : GeometryMath.mergePolygons(
                                            boundary.shape,
                                            accumulator.shape
                                        ),
                          }),
                          null
                      )
                  )
                  .value()
                  .map((boundary) => [boundary, 1]);
    return refinedBoundaries.map(([boundary, id]) => ({
        analysisLayerAreaId: id,
        analysisLayerAreaGuid: "",
        analysisLayerGuid: "",
        applyAnalysisToArea: 1,
        calculatedArea: boundary.calculatedArea,
        fieldBoundaryGuid: boundary.fieldBoundaryGuid,
        zonePolygons: GeometryMath.getSimplifiedPolygonParts(boundary.shape).map((poly) => ({
            shape: poly,
        })),
    }));
};

const setupCurrentAreaIds = function* (fieldGuids: string[]) {
    for (const fieldGuid of fieldGuids) {
        yield put(analysisActions.setCurrentAreaId(fieldGuid, 1));
    }
};

const _expandLayerAnalysisForFieldGuid = function* (fieldGuid, showSurface, layerIdx) {
    const accordionId = yield select(layerSelectors.getAccordionId);
    const items = yield select(layerSelectors.getAccordionItems);
    const fieldIdx = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
    yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx]));
    if (showSurface) {
        yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx, layerIdx]));
        yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx, layerIdx, 0]));
    }
};

const _updateLayerInfos = function* (layerInfos, analysisSummary) {
    for (const analysisLayer of analysisSummary.layers) {
        const { fieldGuid, analysisLayerGuid } = analysisLayer;
        const hasLayerInfo = layerInfos && layerInfos.has(fieldGuid);
        if (analysisLayerGuid === "") {
            const processingAnalysisInfo = {
                analysisLayerGuid: selectors.TEMPGUID,
                analysisLayerStatus: messages.analysisLayerProcessing.defaultMessage,
                displayName: analysisSummary.name,
                displayNamePrefix: analysisSummary.type,
                fieldGuid: fieldGuid,
                isManual: false,
                isSampling: false,
                status: messages.analysisLayerProcessing.defaultMessage,
                subLayers: [],
                surfaceType: "Analysis Layer",
            };
            yield put(actions.setProcessingAnalysisInfo(fieldGuid, processingAnalysisInfo));
            if (!hasLayerInfo) {
                yield call(_expandLayerAnalysisForFieldGuid, fieldGuid, false, undefined);
            } else {
                yield call(_updateLayerSurfaces, fieldGuid);
            }
            continue;
        }

        if (hasLayerInfo) {
            const layers = [...layerInfos.get(fieldGuid)];

            const layerIdx = layers.findIndex((li) => li.analysisLayerGuid === analysisLayerGuid);
            const processingAnalysisInfo = {
                ...layers[layerIdx],
                analysisLayerStatus: messages.analysisLayerProcessing.defaultMessage,
                status: messages.analysisLayerProcessing.defaultMessage,
            };
            layers.splice(layerIdx, 1, processingAnalysisInfo);
            yield put(actions.setProcessingAnalysisInfo(fieldGuid, processingAnalysisInfo));
            const accordionId = yield select(layerSelectors.getAccordionId);
            const items = yield select(layerSelectors.getAccordionItems);
            const idx = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
            if (idx > -1) {
                yield put(
                    accordionActions.updateAccordionItem(accordionId, [idx], {
                        children: createLayerAccordionItems(layers),
                    })
                );
            }
            yield put(layerListActions.setLayerInfo(fieldGuid, layers));
            yield call(_expandLayerAnalysisForFieldGuid, fieldGuid, false, undefined);
        }
    }
};

const _updateLayerSurfaces = function* (fieldGuid) {
    const layerInfos = yield select(layerSelectors.getLayerInfos);
    if (layerInfos) {
        const index = Array.from(layerInfos.keys()).findIndex((key) => key === fieldGuid);
        if (index > -1) {
            yield put(layerActions.updateLayerSurfaces(fieldGuid));
        }
    }
};

const _selectedEventGuids = function* () {
    const _harvestEventGuids = [];
    const isBatchNormalizedYield = yield select(selectors.getBatchNormalizedYield);
    if (isBatchNormalizedYield) {
        const selectedEventSummary = yield select(
            eventsSelectors.getEventGeneralGuidToEventSummaryMap
        );
        const selectedEventGuidSet = yield select(eventListSelectors.getSelectedEventGuidSet);
        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.get(eventGeneralGuid) || {};
            if (event.agEventTypeName === "Harvest" && event.isImportedYn) {
                _harvestEventGuids.push(eventGeneralGuid);
            }
        }
        return _harvestEventGuids;
    } else {
        return [];
    }
};

const _defaultCroppingSeasonGuid = function* () {
    yield put(
        picklistActions.fetchPicklistData({
            [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            ),
        })
    );

    yield take(picklistActions.fetchedPicklistData);

    const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
    const croppingSeasonOptions = yield select(
        picklistSelectors.getPicklistOptionsFromCode,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    const fullYear = String(new Date().getFullYear());
    const defaultCroppingSeason = croppingSeasonOptions.find((option) => option.label === fullYear);
    return defaultCroppingSeason && defaultCroppingSeason.value;
};

const _defaultAttribute = (availableAttributes) =>
    availableAttributes.find((attribute) =>
        EcAttributes.all()
            .map((x) => x.attributeId)
            .includes(attribute.attribute)
    );

const _defaultEcDataColorRampGuid = function* (agEventGeneralGuid, importAttributeGuid) {
    const userGuid = yield select(getTheUserGuid);
    const symbology = yield call(
        LayerAPI.getDefaultSymbology,
        userGuid,
        agEventGeneralGuid,
        importAttributeGuid
    );
    return symbology.colorRampGuid;
};

const onDeleteAnalysisLayer = function* (action) {
    const { analysisLayerGuid, fieldGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield call(LayerAPI.deleteAnalysisLayer, analysisLayerGuid, userGuid);

    //get current layer filter options
    const filterOptions = yield _getFilterOptions(userGuid);

    //update layer filter info
    const layerFilterInfo = apiFilterOptionsToLayerFilterInfo(filterOptions);
    yield put(layerListActions.setLayerFilterInfo(layerFilterInfo));

    //update dropdown options
    const { croppingSeasons, crops, filters, layerTypes } = layerFilterInfo;
    const layerFilter = yield select(layerSelectors.getLayerFilter);
    const layerFilterOptions = filterLayerOptions(
        layerFilter,
        croppingSeasons,
        crops,
        filters,
        layerTypes
    );
    yield put(layerListActions.setLayerFilterOptions(layerFilterOptions));

    //remove from layerInfos
    const layerInfos = yield select(layerSelectors.getLayerInfos);
    if (layerInfos) {
        const layers = [...layerInfos.get(fieldGuid)];
        if (analysisLayerGuid && analysisLayerGuid !== "") {
            const layerIdx = layers.findIndex((li) => li.analysisLayerGuid === analysisLayerGuid);
            //Check to see if surface is enabled and remove
            const surfaceGuid = layers[layerIdx].surfaceGuid;
            const surfacesMap = yield select(layerSelectors.getVisibleSurfacesMap);
            const visibleSurface = surfacesMap.get(fieldGuid);
            if (visibleSurface && visibleSurface.surfaceGuid === surfaceGuid) {
                yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
            }
            layers.splice(layerIdx, 1);
        }
        const accordionId = yield select(layerSelectors.getAccordionId);
        const items = yield select(layerSelectors.getAccordionItems);
        const idx = items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
        if (idx > -1) {
            yield put(
                accordionActions.updateAccordionItem(accordionId, [idx], {
                    children: createLayerAccordionItems(layers),
                })
            );
        }
        yield put(layerListActions.setLayerInfo(fieldGuid, layers));
    }

    yield put(mapActions.setForceRefreshFlag(true));
};

const onDeleteAnalysisLayerBatch = function* (action) {
    const { batchGuid } = action.payload;
    const analysisInBatch: LayerAPI.IAnalysisInBatch[] = action.payload.analysisInBatch;
    const userGuid = yield select(getTheUserGuid);
    yield call(LayerAPI.deleteAnalysisLayerBatch, batchGuid, userGuid);
    //remove from layerInfos
    const layerInfos = yield select(layerSelectors.getLayerInfos);
    if (!layerInfos) {
        return;
    }

    const accordionId = yield select(layerSelectors.getAccordionId);
    const accordionItems = yield select(layerSelectors.getAccordionItems);

    const analysis = analysisInBatch
        .filter(
            (x) =>
                x.analysisLayerGuid &&
                x.analysisLayerGuid !== "" &&
                layerInfos.get(x.fieldGuid) != null
        )
        .map((x) => ({
            ...x,
            newLayers: layerInfos
                .get(x.fieldGuid)
                .filter((layer) => layer.analysisLayerGuid !== x.analysisLayerGuid),
            accordionIndex: accordionItems.findIndex(
                (item) => item.payload.fieldGuid === x.fieldGuid
            ),
        }));

    yield put(layerListActions.removeVisibleSurfaces(analysisInBatch.map((x) => x.fieldGuid)));

    for (const { accordionIndex, newLayers } of analysis) {
        if (accordionIndex > -1) {
            yield put(
                accordionActions.updateAccordionItem(accordionId, [accordionIndex], {
                    children: createLayerAccordionItems(newLayers),
                })
            );
        }
    }

    for (const { fieldGuid, newLayers } of analysis) {
        yield put(layerListActions.setLayerInfo(fieldGuid, newLayers));
    }

    yield put(mapActions.setForceRefreshFlag(true));
};

const fetchAnalysisInfo = function* (action) {
    const { analysisLayerGuid } = action.payload;
    if (!analysisLayerGuid) {
        yield put(actions.fetchAnalysisInfoSucceeded());
        return;
    }
    const userGuid = yield select(getTheUserGuid);
    try {
        const analysisSummary = yield call(LayerAPI.getAnalysisLayer, analysisLayerGuid, userGuid);
        if (
            analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA ||
            analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD
        ) {
            analysisSummary.isMultipartZoneAutoSplitAllowed = () => true;
        }
        yield put(actions.setAnalysisSummary(analysisSummary));

        if (analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_EC_DATA) {
            const fieldGuids = analysisSummary.layers.map((x) => x.fieldGuid);
            const attributes = yield call(LayerAPI.retrieveEcDataAttributes, fieldGuids, userGuid);
            yield put(actions.setEcDataAttributes(attributes));
        } else if (analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA) {
            yield put(
                actions.fetchManagementAreaAttributes(
                    analysisSummary.managementAreaTemplateGuid,
                    analysisLayerGuid
                )
            );
        }

        yield put(actions.fetchAnalysisInfoSucceeded(analysisSummary));
    } catch (err) {
        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

const fetchAnalysisDetails = function* (action) {
    const { analysisLayerTypes } = action.payload;
    const summary = yield select(selectors.getAnalysisLayerInfo);
    const { layers, typeGuid } = summary;
    const layerType = analysisLayerTypes.find(
        (item) =>
            Object.hasOwn(item, analysisModels.ANALYSIS_INFO_TYPE_GUID) &&
            item[analysisModels.ANALYSIS_INFO_TYPE_GUID] === typeGuid
    ).name;
    const userGuid = yield select(getTheUserGuid);
    const fieldGuids = layers.map((x) => x.fieldGuid);
    const fields = yield fetchFields(fieldGuids, userGuid);

    try {
        yield setAnalysisSummary(layerType, summary, userGuid);
    } catch (err) {
        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
    } finally {
        yield put(analysisActions.setAnalysisSummaryMap(summary, fieldGuids));

        yield put(actions.setFieldGuids(fieldGuids));
        yield put(actions.setFields(fields));

        if (fieldGuids.length > 1) {
            yield setupCurrentAreaIds(fieldGuids);
        } else {
            const field = fields[0];
            yield put(
                mapToolsActions.setActiveToolset(Toolset.ANALYSIS, {
                    recEventDetails: {
                        ...summary,
                        fieldBoundaryGuid: field.fieldBoundaryGuid,
                    },
                    field,
                })
            );
        }
        yield put(mapActions.setZoomToFieldList(fieldGuids));
        yield put(actions.setAnalysisDetailsLoading(false));
    }
};

const fetchAnalysisInfoSucceeded = function* () {
    try {
        let analysisLayerTypes = yield select(analysisSelectors.getAnalysisLayerTypes);
        if (analysisLayerTypes.length === 0) {
            const companyGuid = yield select(getTheUserCompanyGuid);
            const userGuid = yield select(getTheUserGuid);
            analysisLayerTypes = yield call(LayerAPI.getAnalysisLayerTypes, companyGuid, userGuid);
            yield put(analysisActions.setAnalysisLayerTypes(analysisLayerTypes));
        }
        yield put(actions.fetchAnalysisDetails(analysisLayerTypes));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        yield put(actions.closeAnalysisInfo());
    }
};

const fetchManagementAreaAttributes = function* (action) {
    const { importTemplateGuid, analysisLayerGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const importAttributes = yield call(
            LayerAPI.getManagementAreaAttributes,
            { importTemplateGuid, analysisLayerGuid },
            userGuid
        );
        if (Array.isArray(importAttributes)) {
            yield put(actions.setManagementAreaAttributes(importAttributes));
            if (!analysisLayerGuid) {
                yield put(actions.resetManagementAreaAttributeData());
            }
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

const getLayerTypes = function* () {
    let analysisLayerTypes = yield select(analysisSelectors.getAnalysisLayerTypes);
    if (!analysisLayerTypes || analysisLayerTypes.length === 0) {
        const companyGuid = yield select(getTheUserCompanyGuid);
        const userGuid = yield select(getTheUserGuid);
        analysisLayerTypes = yield call(LayerAPI.getAnalysisLayerTypes, companyGuid, userGuid);
        yield put(analysisActions.setAnalysisLayerTypes(analysisLayerTypes));
    }
    return analysisLayerTypes;
};

const onCreateAnalysisLayer = function* (action) {
    const { fieldGuids, analysisLayerType } = action.payload;
    const analysisLayerTypes = yield call(getLayerTypes);
    const layerTypeGuid = analysisLayerTypes.find(
        (item) =>
            Object.hasOwn(item, analysisModels.ANALYSIS_INFO_TYPE_NAME) &&
            item[analysisModels.ANALYSIS_INFO_TYPE_NAME] === analysisLayerType
    );

    const boundaries = yield call(
        FieldAPI.fetchFieldBoundariesByFieldGuidPerformant,
        fieldGuids,
        null,
        null,
        true
    );
    console.assert(boundaries.length > 0);

    //clear previous error codes
    yield put(analysisActions.setAnalysisErrorCodesList([]));

    const layers = fieldGuids.map((fieldGuid) => ({
        analysisLayerGuid: "",
        fieldGuid,
    }));

    const analysisSummary = yield defaultSummary(
        analysisLayerType,
        layers,
        areasFromBoundaries(boundaries, analysisLayerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA),
        fieldGuids,
        layerTypeGuid[analysisModels.ANALYSIS_INFO_TYPE_GUID]
    );
    if (analysisSummary == null) {
        return;
    }

    yield put(actions.setAnalysisSummary(analysisSummary));
    yield put(cdActions.addSelectedFields(fieldGuids));
    yield put(analysisActions.setAnalysisSummaryMap(analysisSummary, fieldGuids));
    yield put(actions.showAnalysisInfo());
};

const onCloseAnalysisInfo = function* () {
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(actionPanelActions.setIsEnabled(true));
    yield put(actions.setNormalizedYieldOptions([]));
    yield put(actions.setSeedStrongOptions([]));
    yield put(actions.setEcDataAttributes([]));
    yield put(actions.setManagementAreaTypes([]));
    yield put(actions.setManagementAreaAttributes([]));
    yield put(analysisActions.clearAnalysisDetails());
    yield put(notificationActions.clearToasterMessages());
    yield put(actions.setBatchNormalizedYield(false));
    yield put(layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.LAYER_LIST));
};

const onSaveAnalysisInfo = function* () {
    yield put(actions.setAnalysisDetailsLoading(true));
    //clear previous error codes
    yield put(analysisActions.setAnalysisErrorCodesList([]));
    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    try {
        yield put(actions.updateAnalysisLayer());
        yield call(_updateLayerInfos, yield select(layerSelectors.getLayerInfos), analysisSummary);
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    } finally {
        yield put(actions.setAnalysisDetailsLoading(false));
    }
};

const onShowAnalysisInfo = function* (action) {
    yield put(actions.setAnalysisDetailsLoading(true));
    const { analysisLayerGuid } = action.payload;

    yield put(
        layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.ANALYSIS_INFORMATION)
    );
    yield put(actions.fetchAnalysisInfo(analysisLayerGuid));
    yield put(actionPanelActions.setIsEnabled(false));
};

const updateAnalysisLayer = function* () {
    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    const userGuid = yield select(getTheUserGuid);
    try {
        yield runLayer(analysisSummary, userGuid);
    } catch (err) {
        const fieldGuids = analysisSummary.layers.map((x) => x.fieldGuid);
        for (const fieldGuid of fieldGuids) {
            yield put(actions.removeProcessingAnalysisInfo(fieldGuid));
        }

        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err));
        }
    }
};
export const onAnalysisCompletedAndLoaded = function* (
    action: ReturnType<typeof layerActions.updateLoadingFieldGuidSet>
): Generator<
    PutEffect<any> | CallEffect | SelectEffect | Generator<CallEffect | SelectEffect, any, unknown>,
    void,
    any
> {
    const { fieldGuid, isLoading } = action;
    const processedAnalysisInfos = yield select(selectors.getProcessingAnalysisInfo);
    const processedAnalysisInfo = processedAnalysisInfos.get(fieldGuid);
    if (!processedAnalysisInfo) {
        return;
    }
    if (
        processedAnalysisInfo.analysisLayerStatus ===
            messages.analysisLayerCompleted.defaultMessage &&
        !isLoading
    ) {
        const userGuid = yield select(getTheUserGuid);
        //get current layer filter options
        const filterOptions = yield _getFilterOptions(userGuid);

        //update layer filter info
        const layerFilterInfo = apiFilterOptionsToLayerFilterInfo(filterOptions);
        yield put(layerListActions.setLayerFilterInfo(layerFilterInfo));

        //update dropdown options
        const { croppingSeasons, crops, filters, layerTypes } = layerFilterInfo;
        const layerFilter = yield select(layerSelectors.getLayerFilter);
        const layerFilterOptions = filterLayerOptions(
            layerFilter,
            croppingSeasons,
            crops,
            filters,
            layerTypes
        );
        yield put(layerListActions.setLayerFilterOptions(layerFilterOptions));

        yield put(actions.removeProcessingAnalysisInfo(fieldGuid));
        const layerInfos = yield select(layerSelectors.getLayerInfos);
        const layerInfo = layerInfos.get(fieldGuid);
        const layerIdx = layerInfo.findIndex(
            (layer) =>
                layer.displayName === processedAnalysisInfo.displayName &&
                layer.surfaceType === processedAnalysisInfo.surfaceType
        );
        yield call(_expandLayerAnalysisForFieldGuid, fieldGuid, true, layerIdx);
    }
};

export const updateAnalysisZones = function* (
    action: ReturnType<typeof actions.updateZones>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { zones, fieldBoundaryGuid, areaIdToNewAreaIdPolygonMap } = action.payload;
    if (zones == null || zones.length === 0) {
        return;
    }

    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    if (analysisSummary.layers.length === 1) {
        analysisSummary.analysisLayerAreaList = zones.map((zone) => ({
            analysisLayerAreaId: zone.attributes.AREA_ID,
            analysisLayerAreaGuid: "",
            analysisLayerGuid: zone.attributes.AnalysisLayerGuid,
            applyAnalysisToArea: zone.isIncluded || zone.attributes.IS_INCLUDED,
            calculatedArea: zone.attributes.CALCULATED_AREA,
            fieldBoundaryGuid,
            zonePolygons: GeometryMath.getSimplifiedPolygonParts(zone.geometry).map((poly) => ({
                shape: poly,
            })),
        }));
        if (
            analysisSummary.type === ANALYSIS_INFO_NAME_MANAGEMENT_AREA &&
            areaIdToNewAreaIdPolygonMap != null &&
            Object.keys(analysisSummary.managementAreaData).length > 0
        ) {
            const { managementAreaData } = analysisSummary;
            analysisSummary.managementAreaData = {};
            for (const [
                replaceAreaId,
                newAreaIdToPolygonMap,
            ] of areaIdToNewAreaIdPolygonMap.entries()) {
                for (const newAreaId of newAreaIdToPolygonMap.keys()) {
                    analysisSummary.managementAreaData[newAreaId] = managementAreaData[
                        replaceAreaId
                    ].map((attribute) => ({
                        ...attribute,
                    }));
                }
            }
        }
        yield put(actions.setAnalysisSummary(analysisSummary));
        yield put(actions.updateManagementAreaApplyToArea());
        yield put(
            analysisActions.setAnalysisSummaryMap(
                analysisSummary,
                analysisSummary.layers.map((x) => x.fieldGuid)
            )
        );
    }
};

export const updateManagementAreaAttributeData = function* (
    action: ReturnType<typeof actions.updateManagementAreaAttributeData>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { areaId, name, textValue, yesNoValue, decimalIntValue } = action.payload;
    yield put(
        actions.setManagementAreaAttributeData(areaId, name, textValue, yesNoValue, decimalIntValue)
    );
    yield put(actions.updateManagementAreaApplyToArea());
    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    yield put(
        analysisActions.setAnalysisSummaryMap(
            analysisSummary,
            analysisSummary.layers.map((x) => x.fieldGuid)
        )
    );
};

const onFetchActualDetailedCost = function* (action) {
    const { model } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield put(actions.setIsLoading(true));
        const applicationCost = yield call(LayerAPI.getActualDetailedCost, model, userGuid);
        yield put(actions.fetchActualDetailCostCompleted(applicationCost));
        yield put(actions.setIsLoading(false));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        yield put(actions.setIsLoading(false));
    }
};

export const analysisInfoSaga = function* (): Generator<AllEffect, void, any> {
    yield all([
        takeLatest(actions.ADD_ANALYSIS_LAYER, onCreateAnalysisLayer),
        takeLatest(actions.CLOSE_ANALYSIS_INFO, onCloseAnalysisInfo),
        takeLatest(actions.DELETE_ANALYSIS_LAYER, onDeleteAnalysisLayer),
        takeLatest(actions.DELETE_ANALYSIS_LAYER_BATCH, onDeleteAnalysisLayerBatch),
        takeLatest(actions.FETCH_ACTUAL_DETAIL_COST, onFetchActualDetailedCost),
        takeLatest(actions.FETCH_ANALYSIS_DETAILS, fetchAnalysisDetails),
        takeLatest(actions.FETCH_ANALYSIS_INFO, fetchAnalysisInfo),
        takeLatest(actions.FETCH_ANALYSIS_INFO_SUCCEEDED, fetchAnalysisInfoSucceeded),
        takeLatest(actions.FETCH_MANAGEMENT_AREA_ATTRIBUTES, fetchManagementAreaAttributes),
        takeLatest(actions.SAVE_ANALYSIS_INFO, onSaveAnalysisInfo),
        takeLatest(actions.SHOW_ANALYSIS_INFO, onShowAnalysisInfo),
        takeLatest(actions.UPDATE_ANALYSIS_ZONES, updateAnalysisZones),
        takeEvery(actions.UPDATE_ANALYSIS_LAYER, updateAnalysisLayer),
        takeEvery(actions.UPDATE_MANAGEMENT_AREA_ATTRIBUTE_DATA, updateManagementAreaAttributeData),
        takeLatest(layerActions.UPDATE_LOADING_FIELD_GUID_SET, onAnalysisCompletedAndLoaded),
    ]);
};
