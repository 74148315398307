import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { messages } from "../i18n-messages";
import { AutoSearch } from "~/core";
import { SearchAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login/selectors";

export class CustomerSearchView extends Component {
    static propTypes = {
        classNames: PropTypes.array,
        clearOnSelection: PropTypes.bool,
        formatMessage: PropTypes.func,
        initialValue: PropTypes.string,
        onSelection: PropTypes.func,
        orgLevelList: PropTypes.array,
        required: PropTypes.bool,
        userGuid: PropTypes.string,
    };

    static defaultProps = {
        filteredData: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            initialValue: "",
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialValue) {
            this.setState({
                initialValue: nextProps.initialValue,
            });
        }
    }

    getOptions = (userGuid, searchValue) => {
        const { orgLevelList } = this.props;
        const search = searchValue === "_" ? null : searchValue;
        return SearchAPI.getCustomers({
            userGuid,
            search,
            orgLevelGuids: orgLevelList,
            active: true,
        });
    };

    render() {
        const { formatMessage, userGuid, clearOnSelection, onSelection, required, classNames } =
            this.props;
        return (
            <AutoSearch
                classNames={classNames}
                clearOnSelection={clearOnSelection}
                getAutoSearchList={this.getOptions}
                initialFilterStr={this.state.initialValue}
                itemList={[]}
                keyProp={SearchAPI.Props.CUSTOMER_GUID}
                nameProp={SearchAPI.Props.CUSTOMER_NAME}
                onSelection={onSelection}
                placeholderText={formatMessage(messages.searchFor, {
                    searchedFor: formatMessage(messages.customer),
                })}
                secondaryPropList={[SearchAPI.Props.CUSTOMER_CITY, SearchAPI.Props.STATE_ABBR]}
                selectedValue={this.state.initialValue}
                userGuid={userGuid}
                required={required}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export const CustomerSearch = connect(mapStateToProps)(CustomerSearchView);
