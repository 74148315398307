import { createReducer } from "redux-act";
import { setApiResult, resetApiResult } from "./actions";

export const API_ERROR_DATA_KEY = "API_ERROR_DATA";
const initialState = {};

export const apiReducer = createReducer(
    {
        [setApiResult]: (state, payload) => {
            return {
                ...state,
                ...payload,
            };
        },
        [resetApiResult]: () => {
            return {};
        },
    },
    initialState
);
