import { connect } from "react-redux";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { dropdowns, pickLists, service } from "./../data";
import { getPicklistState } from "~/core/picklist/selectors";
import { clearPicklistState } from "~/core/picklist/actions";

const mapStateToProps = (state) => ({
    picklistState: getPicklistState(state),
});

const mapDispatchToProps = (dispatch) => ({
    clearPicklistState: (code) => dispatch(clearPicklistState(code)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { dropdowns, pickLists, service }));
