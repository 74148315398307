import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";

import { adminData, VALUE } from "~/admin/data";
import { Checkbox, SelectInput, TextInput, TextArea } from "~/core";
import { selectors as picklistSelectors } from "~/core/picklist";
import { PICKLIST_NON_FIELD_FEATURE_TYPE, getPickListCode } from "~/core/picklist/picklist-names";
import { withApiResult } from "~/hocs";
import * as informationActions from "../../actions";
import * as informationSelectors from "../../selectors";
import { NonFieldFeatureAPI } from "@ai360/core";
import { compareFields } from "~/customer-data/models.ts";

import messages from "./i18n-messages";

import "./detail.css";

export const picklists = {
    [PICKLIST_NON_FIELD_FEATURE_TYPE]: getPickListCode(PICKLIST_NON_FIELD_FEATURE_TYPE),
};

class SingleSelectableOptionSet {
    constructor(valueProperty, labelProperty, activeYnProperty) {
        this._valueProperty = valueProperty;
        this._labelProperty = labelProperty;
        this._activeYnProperty = activeYnProperty;
    }

    newOptions(options) {
        if (!options) {
            this._options = [];
            return;
        }

        this._options = options.reduce((result, option) => {
            result.push({
                value: option[this._valueProperty],
                label: option[this._labelProperty],
                selected: false,
                activeYn: option[this._activeYnProperty],
            });
            return result;
        }, []);
    }

    select(value) {
        for (let option of this._options) {
            option.selected = option.value === value;
        }
    }

    clearSelection() {
        for (let option of this._options) {
            option.selected = false;
        }
    }

    allOptions() {
        return this._options;
    }
}

class BoundSingleSelectableOptionSet {
    constructor(
        component,
        dropdownProperty,
        propValueExtractor,
        valueProperty,
        labelProperty,
        activeYnProperty
    ) {
        this._component = component;
        this._dropdownProperty = dropdownProperty;
        this._propValueExtractor = propValueExtractor;

        this._underlying = new SingleSelectableOptionSet(
            valueProperty,
            labelProperty,
            activeYnProperty
        );
        this._underlying.newOptions(this._component.props[this._dropdownProperty]);
    }

    attemptUpdate(nextProps) {
        const oldProps = this._component.props;

        const oldPropValue = oldProps[this._dropdownProperty];
        const newPropValue = nextProps[this._dropdownProperty];
        if (oldPropValue !== newPropValue) {
            this._underlying.newOptions(newPropValue);
        }

        const oldValue = this._propValueExtractor(oldProps);
        const newValue = this._propValueExtractor(nextProps);
        if (oldValue !== newValue) {
            this._underlying.select(newValue);
        }
    }

    allOptions() {
        return this._underlying.allOptions();
    }
}

const fieldProperty = "fieldId";
const useExtent = "useExtent";

class Detail extends React.Component {
    static propTypes = {
        activeFeature: PropTypes.any,
        fields: PropTypes.any,
        intl: intlShape.isRequired,
        setInformationActiveFeature: PropTypes.any,
    };
    constructor(props) {
        super(props);
        this.typeOptionSet = new BoundSingleSelectableOptionSet(
            this,
            PICKLIST_NON_FIELD_FEATURE_TYPE,
            (x) => x.activeFeature.type,
            VALUE,
            VALUE,
            adminData.PROPS_ACTIVE_YN
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.typeOptionSet.attemptUpdate(nextProps);
    }

    render() {
        const { activeFeature, fields } = this.props;
        const { formatMessage } = this.props.intl;

        const typeProperty = "type";
        const privateProperty = "isPrivate";
        const nameProperty = "name";
        const descriptionProperty = "description";
        let disableUseExtent = false;
        const isFieldValueSelected = this._extractIfExists(activeFeature, fieldProperty, "");
        if (!isFieldValueSelected || isFieldValueSelected === "") {
            disableUseExtent = true;
        }

        return (
            <div className="non-field-feature-information-detail-container">
                <div className="non-field-feature-information-detail">
                    <div className="top-row">
                        <SelectInput
                            containerClassNames={["feature-type"]}
                            labelText={formatMessage(messages.featureType)}
                            placeholderText={formatMessage(messages.featureType)}
                            options={this.typeOptionSet.allOptions()}
                            onChange={(value) =>
                                this._setValueIfExists(activeFeature, typeProperty, value)
                            }
                            value={this._extractIfExists(activeFeature, typeProperty, "")}
                            required
                        />
                        <TextInput
                            containerClassNames={["feature-name"]}
                            labelText={formatMessage(messages.featureName)}
                            placeholderText={formatMessage(messages.featureName)}
                            onChange={(value) =>
                                this._setValueIfExists(activeFeature, nameProperty, value)
                            }
                            value={this._extractIfExists(activeFeature, nameProperty, "")}
                            required
                        />
                    </div>
                    <div className="top-row">
                        <SelectInput
                            containerClassNames={["field-selection"]}
                            labelText={formatMessage(messages.associatedField)}
                            placeholderText={formatMessage(messages.associatedField)}
                            options={fields}
                            onChange={(value) =>
                                this._setValueIfExists(activeFeature, fieldProperty, value)
                            }
                            value={this._extractIfExists(activeFeature, fieldProperty, "")}
                        />
                        <Checkbox
                            label={formatMessage(messages.useExtent)}
                            className="extent-checkbox"
                            disabled={disableUseExtent}
                            onChange={(e, value) =>
                                this._setValueIfExists(activeFeature, useExtent, value)
                            }
                            value={
                                disableUseExtent === true
                                    ? false
                                    : this._extractIfExists(activeFeature, useExtent, false)
                            }
                        />
                    </div>
                    <TextArea
                        containerClassNames={["description"]}
                        disabled={!activeFeature}
                        maxLength={1000}
                        placeholderText={formatMessage(messages.description)}
                        onChange={(value) =>
                            this._setValueIfExists(activeFeature, descriptionProperty, value)
                        }
                        value={this._extractIfExists(activeFeature, descriptionProperty, "")}
                    />
                    <Checkbox
                        className="private-checkbox"
                        label={formatMessage(messages.private)}
                        onChange={(e, value) =>
                            this._setValueIfExists(activeFeature, privateProperty, value)
                        }
                        value={this._extractIfExists(activeFeature, privateProperty, false)}
                    />
                </div>
            </div>
        );
    }

    _setValueIfExists = (object, propertyName, value) => {
        if (!object) {
            return;
        }

        object[propertyName] = value;
        if (propertyName === fieldProperty && value === undefined) {
            object[useExtent] = false;
        }
        this.props.setInformationActiveFeature(
            Object.assign(new NonFieldFeatureAPI.NonFieldFeature(), this.props.activeFeature)
        );
    };

    _extractIfExists = (object, propertyName, defaultValue) => {
        if (!object) {
            return defaultValue;
        }

        return object[propertyName];
    };
}

const mapStateToProps = (state) => {
    const retrievedPicklists = picklistSelectors.getPicklistState(state);
    const picklistData = Object.keys(picklists).reduce(
        (data, key) =>
            Object.assign(data, {
                [key]: (retrievedPicklists[picklists[key]] || []).sort((a, b) =>
                    a.value > b.value ? 1 : -1
                ),
            }),
        {}
    );

    const fieldData = informationSelectors.getFields(state);

    return {
        fields: fieldData.sort(compareFields).map((field) => {
            return {
                value: field.id,
                label: !field.farmName ? field.name : `${field.farmName} - ${field.name}`,
            };
        }),
        ...picklistData,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setInformationActiveFeature: (feature) =>
        dispatch(informationActions.setActiveFeature(feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withApiResult(Detail)));
