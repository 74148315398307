import { createService } from "~/hocs/crud";
import { apiUrl } from "@ai360/core";
import * as model from "./model";
import * as picklistService from "~/core/picklist/picklist-names";
import * as unitService from "~/core/units/unit-names";
import defaultProductRecord from "./default-record";

export const CHEMICAL_PRODUCT_TYPE = "1";
export const FERTILIZER_PRODUCT_TYPE = "2";
export const SERVICE_PRODUCT_TYPE = "4";

export function isChemicalType(productParentType) {
    return productParentType === CHEMICAL_PRODUCT_TYPE;
}

export function isFertilizerType(productParentType) {
    return productParentType === FERTILIZER_PRODUCT_TYPE;
}

export function isChemicalOrFertilizerType(productParentType) {
    return (
        productParentType === CHEMICAL_PRODUCT_TYPE || productParentType === FERTILIZER_PRODUCT_TYPE
    );
}

export function getProductParentType(productParentType) {
    return productParentType === SERVICE_PRODUCT_TYPE ? "" : productParentType;
}

// Module Name
export const MODEL_NAME = "product";

export const REQUEST_CROP = apiUrl("AgBytes/GetCropAgBytesList");
export const REQUEST_NUTRIENT = apiUrl("AgBytes/GetNutrientDropDownList");
export const REQUEST_PRODUCT_TYPE = apiUrl("Picklist/GetPicklistChildValueList");
export const REQUEST_VARIETY_HYBRID = apiUrl("AgBytes/GetVarietyHybridFilterList");

// Request payload
export const REQUEST_PAYLOAD_ACTIVE_ONLY = "activeOnly";
export const REQUEST_PAYLOAD_BRAND_ORGANIZATION = "BrandOrganization";
export const REQUEST_PAYLOAD_CROP_GUID = "CropID";
export const REQUEST_PAYLOAD_FILTER = "productFilter";
export const REQUEST_PAYLOAD_PAGE_OPTIONS = "productPageOptions";
export const REQUEST_PAYLOAD_PRODUCT_PARENT_TYPE = "Product Parent Type";
export const REQUEST_PAYLOAD_PRODUCT_TYPE = "Product Type";
export const REQUEST_PAYLOAD_SORT_LIST = "productSortList";

// URLs
export const AUTO_SEARCH_URL = apiUrl("AgBytes/GetProductAutoSearchList");
export const CONVERT_AGIIS_PACKAGE_FILE_NAME = "Agiis_Product_Package";
export const CONVERT_AGIIS_PRODUCT_FILE_NAME = "Agiis_Product";
export const CONVERT_AGIIS_URL = apiUrl("AgBytes/BuildHaasAgiisFiles");
export const CONVERT_AGRIAN_PACKAGE_FILE_NAME = "Agrian_Product_Package";
export const CONVERT_AGRIAN_PLANT_BACK_FILE_NAME = "AgrianPlantBackRestriction";
export const CONVERT_AGRIAN_PRODUCT_FILE_NAME = "Agrian_Product";
export const CONVERT_AGRIAN_URL = apiUrl("AgBytes/BuildHaasAgrianFiles");
export const CREATE = apiUrl("AgBytes/AddProduct");
export const EXPORT_FILE_NAME = "ProductExport";
export const EXPORT_PACKAGE_FILE_NAME = "ProductPackageExport";
export const EXPORT_PACKAGE_URL = apiUrl("AgBytes/ExportProductPackageList");
export const EXPORT_PLANT_BACK_RESTRICTION_FILE_NAME = "ProductPlantBackRestrictionExport";
export const EXPORT_PLANT_BACK_RESTRICTION_URL = apiUrl("AgBytes/ExportProductPlantBackList");
export const EXPORT_URL = apiUrl("AgBytes/ExportProductList");
export const GETRECORD = apiUrl("AgBytes/GetProduct");
export const IMPORT_PACKAGE_URL = apiUrl("AgBytes/ProductPackageImport");
export const IMPORT_PLANT_BACK_RESTRICTION_URL = apiUrl("AgBytes/productPlantBackImport");
export const IMPORT_URL = apiUrl("AgBytes/ProductImport");
export const IMPORT_VALID_PACKAGE_URL = apiUrl("AgBytes/ValidProductPackageImport");
export const IMPORT_VALID_PLANT_BACK_RESTRICTION_URL = apiUrl(
    "AgBytes/ValidProductPlantBackImport"
);
export const IMPORT_VALID_URL = apiUrl("AgBytes/ValidProductImport");
export const NEXT_ID = apiUrl("AgBytes/GetNextProductID");
export const SELECT_ALL = apiUrl("AgBytes/GetProductSelectAllList");
export const UPDATE = apiUrl("AgBytes/UpdateProduct");
export const URL = apiUrl("AgBytes/GetProductList");

// Default filter object
export const defaultRequestFilters = {
    [REQUEST_PAYLOAD_FILTER]: {
        productId: "",
        productName: "",
        productParentTypeName: "",
        productTypeList: "",
        manufacturerName: "",
        cropName: "",
        varietyHybridName: "",
        isActive: "",
        modifiedDate: "",
    },
    [REQUEST_PAYLOAD_SORT_LIST]: [
        {
            Sort: {
                Direction: "ASC",
                Order: 0,
            },
        },
    ],
    [REQUEST_PAYLOAD_PAGE_OPTIONS]: {
        pageSize: 50,
        skip: 0,
    },
    [REQUEST_PAYLOAD_ACTIVE_ONLY]: true,
};

export const defaultSort = {
    FieldName: "ProductName",
    ...defaultRequestFilters[REQUEST_PAYLOAD_SORT_LIST][0],
};

export const pickLists = {
    [picklistService.PICKLIST_PRODUCT_PARENT_TYPE]: picklistService.getPickListCode(
        picklistService.PICKLIST_PRODUCT_PARENT_TYPE
    ),
    [picklistService.PICKLIST_MANUFACTURER]: picklistService.getPickListCode(
        picklistService.PICKLIST_MANUFACTURER
    ),
    [picklistService.PICKLIST_PHYSICAL_STATE]: picklistService.getPickListCode(
        picklistService.PICKLIST_PHYSICAL_STATE
    ),
    [picklistService.PICKLIST_FORMULATION_TYPE]: picklistService.getPickListCode(
        picklistService.PICKLIST_FORMULATION_TYPE
    ),
    [picklistService.PICKLIST_PRODUCT_ACTION_METHOD]: picklistService.getPickListCode(
        picklistService.PICKLIST_PRODUCT_ACTION_METHOD
    ),
    [picklistService.PICKLIST_CHEMICAL_FAMILY]: picklistService.getPickListCode(
        picklistService.PICKLIST_CHEMICAL_FAMILY
    ),
};

export const unitTypes = {
    [unitService.UNIT_LENGTH]: unitService.getUnitCode(unitService.UNIT_LENGTH),
    [unitService.UNIT_DENSITY]: unitService.getUnitCode(unitService.UNIT_DENSITY),
    [unitService.UNIT_PRODUCT_RATE_DRY]: unitService.getUnitCode(unitService.UNIT_PRODUCT_RATE_DRY),
    [unitService.UNIT_PRODUCT_RATE_LIQUID]: unitService.getUnitCode(
        unitService.UNIT_PRODUCT_RATE_LIQUID
    ),
};

export const dropdowns = {
    [model.PROPS_CROP_NAME]: REQUEST_CROP,
    [model.NUTRIENT_TYPE]: REQUEST_NUTRIENT,
};

// Service
export const service = createService({
    id: model.PROPS_PRODUCT_ID,
    guid: model.PROPS_PRODUCT_GUID,
    name: model.PROPS_PRODUCT_NAME,
    modelName: MODEL_NAME,
    defaultRequestFilters,
    REQUEST_PAYLOAD_FILTER,
    REQUEST_PAYLOAD_SORT_LIST,
    REQUEST_PAYLOAD_PAGE_OPTIONS,
    CONVERT_AGIIS_PACKAGE_FILE_NAME,
    CONVERT_AGIIS_PRODUCT_FILE_NAME,
    CONVERT_AGRIAN_PACKAGE_FILE_NAME,
    CONVERT_AGRIAN_PLANT_BACK_FILE_NAME,
    CONVERT_AGRIAN_PRODUCT_FILE_NAME,
    EXPORT_FILE_NAME,
    EXPORT_PACKAGE_FILE_NAME,
    EXPORT_PLANT_BACK_RESTRICTION_FILE_NAME,
    pickLists,
    unitTypes,
    dropdowns,
    urls: {
        AUTO_SEARCH_URL,
        CONVERT_AGIIS_URL,
        CONVERT_AGRIAN_URL,
        CREATE,
        EXPORT_PACKAGE_URL,
        EXPORT_PLANT_BACK_RESTRICTION_URL,
        EXPORT_URL,
        GETRECORD,
        IMPORT_PACKAGE_URL,
        IMPORT_PLANT_BACK_RESTRICTION_URL,
        IMPORT_URL,
        IMPORT_VALID_PACKAGE_URL,
        IMPORT_VALID_PLANT_BACK_RESTRICTION_URL,
        IMPORT_VALID_URL,
        NEXT_ID,
        SELECT_ALL,
        UPDATE,
        URL,
    },
    _defaultLabels: {
        [model.PROPS_PRODUCT_ID]: { label: "productId", gridCol: 5 },
        [model.PROPS_PRODUCT_PARENT_TYPE]: {
            label: "productParentTypeName",
            gridCol: 10,
            sortNameOverRide: "productParentTypeName",
        },
        [model.PROPS_PRODUCT_TYPES]: {
            label: "productTypeList",
            gridCol: 10,
            sortNameOverRide: "productTypeList",
        },
        [model.PROPS_PRODUCT_NAME]: { label: "productName", gridCol: 10 },
        [model.PROPS_MANUFACTURER]: {
            label: "manufacturer",
            gridCol: 10,
            sortNameOverRide: "manufacturerName",
        },
        [model.PROPS_CROP_NAME]: { label: "cropName", gridCol: 10 },
        [model.PROPS_VARIETY_HYBRID_NAME]: {
            label: "varietyHybridName",
            gridCol: 10,
        },
        [model.PROPS_ACTIVE_YN]: {
            label: "isActive",
            gridCol: 5,
            visible: false,
            sortNameOverRide: "isActive",
        },
        [model.PROPS_MODIFIED_DATE]: { label: "modifiedDate" },
    },
    getDefaultRecord: () => ({ ...defaultProductRecord() }),
    defaultSort,
});
