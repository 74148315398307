import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { Checkbox, DialogBox } from "~/core";
import { messages } from "../../../i18n-messages";

class DynamicLayerPreferences_ extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isOpen: PropTypes.bool,
        layerName: PropTypes.string,
        onChangeShowLabels: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        showLabels: PropTypes.bool,
    };

    render() {
        const { isOpen, layerName, onChangeShowLabels, onClose, showLabels } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <DialogBox
                closeOnClickOff
                draggable
                isOpen={isOpen}
                onClose={() => onClose()}
                title={`${layerName} - ${formatMessage(messages.layerPreferences)}`}
            >
                <Checkbox
                    label={formatMessage(messages.showLabels)}
                    onChange={(evt) => onChangeShowLabels(evt)}
                    value={showLabels}
                />
            </DialogBox>
        );
    }
}

export const DynamicLayerPreferences = injectIntl(DynamicLayerPreferences_);
