import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { injectIntl } from "react-intl";

import { TextInput, SelectInput, NumericInput, Bucket, BucketHeader, Checkbox } from "~/core";
import {
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { ManagementAreaAttribute } from "~/recs-events/analysis/model";
import { EventAreaIcon } from "~/action-panel/components/common/rec-event-info/icons/event-area";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { analysisSelectors } from "~/recs-events";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { FormattingHelpers } from "@ai360/core";
import { messages } from "../i18n-messages";

import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./analysis-info.css";
import "./analysis-management-area-form.css";
import { IErrorCodeList } from "./interfaces";

import { fetchDropdownData } from "~/core/dropdowns/actions";
import { CropAPI } from "@ai360/core";
import { PICKLIST_CROP_PURPOSE, PICKLIST_IRRIGATION_TYPE } from "~/core/picklist/picklist-names";

interface Props {
    analysisDetailsErrorCodeList: number[];
    analysisSummary: any;
    fetchPicklistData: (picklists: any) => any;
    intl: any;
    croppingSeasonOptions: { label: string; value: string }[];
    currentArea: any;
    currentManagementAreaAttributes: ManagementAreaAttribute[];
    fetchManagementAreaAttributes: (managementAreaTemplateGuid: string) => void;
    managementAreaTypeOptions: { label: string; value: string }[];
    onUpdateCurrentAnalysisSummary: any;
    setManagementAreaAttributeData: (
        areaId: number,
        name: string,
        textValue: string,
        yesNoValue: boolean,
        decimalIntValue: number
    ) => void;
    fetchDropdownData: (dropdowns: any) => any;
    dropdownData: any;
    irrigationTypeOptions: { label: string; value: string }[];
    cropPurposeOptions: { label: string; value: string }[];
}

export const formLabelMessage = messages.managementAreaSidebar;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2806, messages.analysisDuplicateName],
    [2807, messages.analysisManagementAreaTypeNotSelected],
    [2808, messages.analysisManagementAreaDetailsMissing],
]);

export const errorCodesApply = (errorCodeList: IErrorCodeList): number => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisManagementAreaForm_ extends PureComponent<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this.getErrorMessagePlaceholderSet(props),
        };
    }

    componentDidMount(): void {
        this.props.fetchDropdownData({
            cropData: {
                url: CropAPI.REQUEST_CROP,
                model: "Name",
            },
        });
        const {
            croppingSeasonOptions,
            irrigationTypeOptions,
            cropPurposeOptions,
            fetchPicklistData,
        } = this.props;
        if (!!croppingSeasonOptions || croppingSeasonOptions.length === 0) {
            fetchPicklistData({
                [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                    picklistNames.PICKLIST_CROPPING_SEASON
                ),
            });
        }
        if (!!irrigationTypeOptions || irrigationTypeOptions.length === 0) {
            fetchPicklistData({
                [picklistNames.PICKLIST_IRRIGATION_TYPE]: picklistNames.getPickListCode(
                    picklistNames.PICKLIST_IRRIGATION_TYPE
                ),
            });
        }
        if (!!cropPurposeOptions || cropPurposeOptions.length === 0) {
            fetchPicklistData({
                [picklistNames.PICKLIST_CROP_PURPOSE]: picklistNames.getPickListCode(
                    picklistNames.PICKLIST_CROP_PURPOSE
                ),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: Props): void {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this.getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    private getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    private updateAnalysisSummary(newProps) {
        this.props.onUpdateCurrentAnalysisSummary(newProps);
    }

    private updateManagementAreaTemplateGuid(managementAreaTemplateGuid) {
        this.props.fetchManagementAreaAttributes(managementAreaTemplateGuid);
        this.updateAnalysisSummary({ managementAreaTemplateGuid });
    }

    private renderErrors(errorMessagePlaceholderSet, formatMessage) {
        return errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0
            ? null
            : [...errorMessagePlaceholderSet].map((message, index) => (
                  <div className="analysis-error-message" key={index}>
                      {formatMessage(message)}
                  </div>
              ));
    }

    _renderZoneNameAndSize(): JSX.Element {
        const {
            currentArea,
            analysisSummary: { analysisLayerAreaList },
        } = this.props;
        const { formatMessage } = this.props.intl;

        const areaId = currentArea?.analysisLayerAreaId;
        let calculatedArea =
            currentArea?.calculatedArea || analysisLayerAreaList[0]?.calculatedArea;
        calculatedArea =
            typeof calculatedArea === "string" ? parseFloat(calculatedArea) : calculatedArea;
        const zoneName =
            analysisLayerAreaList.length === 1
                ? formatMessage(messages.managementAreaDetailZoneNameEntireField)
                : formatMessage(messages.managementAreaDetailZoneName, {
                      areaId,
                  });
        const zoneSize = formatMessage(messages.managementAreaDetailZoneSize, {
            calculatedArea: FormattingHelpers.formatNumber(calculatedArea),
        });

        return (
            <div className="zone-info-heading">
                <EventAreaIcon className="zone-icon" />
                <div className="zone-name">{zoneName}</div>
                <div className="zone-size">{zoneSize}</div>
            </div>
        );
    }

    _updateAttributeData(
        name: string,
        textValue: string,
        yesNoValue: boolean,
        decimalIntValue: number
    ): void {
        const { analysisLayerAreaId } = this.props.currentArea;
        this.props.setManagementAreaAttributeData(
            analysisLayerAreaId,
            name,
            textValue,
            yesNoValue,
            decimalIntValue
        );
    }

    _renderManagementAreaAttributes(): JSX.Element[] {
        return this.props.currentManagementAreaAttributes
            .sort((a, b) => a.columnOrder - b.columnOrder)
            .map((attribute) => {
                const selectAttributes = [
                    messages.managementAreaCrop.defaultMessage,
                    messages.managementAreaCrop2.defaultMessage,
                    messages.managementAreaCropPurpose.defaultMessage,
                    messages.managementAreaCropPurpose2.defaultMessage,
                    messages.managementAreaIrrigationType.defaultMessage,
                ];
                if (selectAttributes.indexOf(attribute.name) >= 0) {
                    let selectOptions = [];
                    if (
                        [
                            messages.managementAreaCrop.defaultMessage,
                            messages.managementAreaCrop2.defaultMessage,
                        ].indexOf(attribute.name) >= 0
                    ) {
                        selectOptions = this.props.dropdownData.cropData.map((q) => ({
                            label: q.name,
                            value: q.name,
                        }));
                    } else if (
                        attribute.name === messages.managementAreaIrrigationType.defaultMessage
                    ) {
                        selectOptions = this.props.irrigationTypeOptions;
                    } else if (
                        [
                            messages.managementAreaCropPurpose.defaultMessage,
                            messages.managementAreaCropPurpose2.defaultMessage,
                        ].indexOf(attribute.name) >= 0
                    ) {
                        selectOptions = this.props.cropPurposeOptions;
                    }
                    return (
                        <SelectInput
                            disabled={false}
                            clearable={true}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(value) =>
                                this._updateAttributeData(attribute.name, value, null, null)
                            }
                            options={selectOptions}
                            placeholderText={attribute.name}
                            value={attribute.textValue}
                        />
                    );
                }
                switch (attribute.dataType) {
                    case "boolean":
                        return (
                            <Checkbox
                                key={attribute.importAttributeGuid}
                                className="analysis-layer-management-area-radio-button"
                                label={attribute.name}
                                value={!!attribute.yesNoValue}
                                onChange={(event, value) =>
                                    this._updateAttributeData(attribute.name, null, value, null)
                                }
                            />
                        );
                    case "integer":
                        return (
                            <NumericInput
                                key={attribute.importAttributeGuid}
                                scale={0}
                                precision={9}
                                containerClassNames={["analysis-layer-management-area-input"]}
                                onChange={(value) =>
                                    this._updateAttributeData(attribute.name, null, null, value)
                                }
                                placeholderText={attribute.name}
                                value={attribute.decimalIntValue}
                            />
                        );
                    case "decimal":
                        return (
                            <NumericInput
                                key={attribute.importAttributeGuid}
                                scale={2}
                                precision={9}
                                containerClassNames={["analysis-layer-management-area-input"]}
                                onChange={(value) =>
                                    this._updateAttributeData(attribute.name, null, null, value)
                                }
                                placeholderText={attribute.name}
                                value={attribute.decimalIntValue}
                            />
                        );
                    case "text":
                    default:
                        return (
                            <TextInput
                                key={attribute.importAttributeGuid}
                                required={false}
                                containerClassNames={["analysis-layer-management-area-input"]}
                                onChange={(value) =>
                                    this._updateAttributeData(attribute.name, value, null, null)
                                }
                                placeholderText={attribute.name}
                                value={attribute.textValue}
                                maxLength={50}
                            />
                        );
                }
            });
    }

    render(): JSX.Element {
        const {
            analysisSummary,
            croppingSeasonOptions,
            currentArea,
            currentManagementAreaAttributes,
            managementAreaTypeOptions,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;
        const typeDropdownDisabled =
            analysisSummary.layers.length > 0 && !!analysisSummary.layers[0].analysisLayerGuid;
        return (
            <div className="rec-event-info-form">
                {this.renderErrors(errorMessagePlaceholderSet, formatMessage)}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.profitLossGeneralInformation)}
                    </BucketHeader>
                    <div className="analysis-layer-management-area-grid">
                        <TextInput
                            required={true}
                            autoFocus={!analysisSummary.name}
                            containerClassNames={["analysis-layer-management-area-input"]}
                            onChange={(v) => this.updateAnalysisSummary({ name: v })}
                            placeholderText={formatMessage(messages.managementAreaNamePlaceholder)}
                            value={analysisSummary.name}
                            maxLength={50}
                        />
                        <SelectInput
                            disabled={false}
                            clearable={true}
                            required={false}
                            containerClassNames={["analysis-layer-management-area-input"]}
                            onChange={(v) =>
                                this.updateAnalysisSummary({
                                    croppingSeasonGuid: v,
                                })
                            }
                            options={croppingSeasonOptions}
                            placeholderText={formatMessage(
                                messages.managementAreaSeasonPlaceholder
                            )}
                            value={analysisSummary.croppingSeasonGuid}
                        />
                        <SelectInput
                            disabled={typeDropdownDisabled}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-management-area-type-input"]}
                            onChange={(v) => this.updateManagementAreaTemplateGuid(v)}
                            options={managementAreaTypeOptions}
                            placeholderText={formatMessage(
                                messages.managementAreaManagementAreaTypePlaceholder
                            )}
                            value={analysisSummary.managementAreaTemplateGuid}
                        />
                    </div>
                </Bucket>
                {currentArea ? (
                    <>
                        <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                            <BucketHeader className="analysis-info-bucket-header">
                                {currentArea ? this._renderZoneNameAndSize() : null}
                            </BucketHeader>
                        </Bucket>
                        <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                            <BucketHeader className="analysis-info-bucket-header">
                                {formatMessage(messages.managementAreaDetail)}
                            </BucketHeader>
                            <div className="analysis-layer-management-area-grid">
                                {currentManagementAreaAttributes
                                    ? this._renderManagementAreaAttributes()
                                    : null}
                            </div>
                        </Bucket>
                    </>
                ) : (
                    <div className="no-zone-selected">
                        {formatMessage(messages.managementAreaNoZoneSelected)}
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchManagementAreaAttributes: (managementAreaTemplateGuid) =>
        dispatch(actions.fetchManagementAreaAttributes(managementAreaTemplateGuid)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    fetchDropdownData: (dropdowns) =>
        dispatch(
            (fetchDropdownData as any)({
                ...dropdowns,
                action: actions.fetchedDropdownData,
            })
        ),
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateManagementAreaAnalysisSummary(newProps)),
    setManagementAreaAttributeData: (areaId, name, textValue, yesNoValue, decimalIntValue) =>
        dispatch(
            actions.updateManagementAreaAttributeData(
                areaId,
                name,
                textValue,
                yesNoValue,
                decimalIntValue
            )
        ),
});

const mapStateToProps = (state) => {
    const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
    const croppingSeasonOptions = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    const analysisSummary = selectors.getAnalysisLayerInfo(state);

    const currentArea = analysisSelectors.getCurrentAnalysisArea(
        state,
        analysisSummary.analysisLayerAreaList
    );

    const currentManagementAreaAttributes = currentArea
        ? selectors.getManagementAreaAttributesByAreaId(state, currentArea.analysisLayerAreaId)
        : null;

    const managementAreaTypeOptions = selectors.getManagementAreaTypes(state);

    const dropdownData = selectors.getDropdownData(state);

    const irrigationTypeOptions = picklistSelectors
        .getPicklistOptionsFromCode(state, getPickListCode(PICKLIST_IRRIGATION_TYPE))
        .map((o) => ({
            label: o.label,
            value: o.label,
        }));

    const cropPurposeOptions = picklistSelectors
        .getPicklistOptionsFromCode(state, getPickListCode(PICKLIST_CROP_PURPOSE))
        .map((o) => ({
            label: o.label,
            value: o.label,
        }));

    return {
        croppingSeasonOptions,
        currentArea,
        currentManagementAreaAttributes,
        managementAreaTypeOptions,
        dropdownData,
        irrigationTypeOptions,
        cropPurposeOptions,
    };
};

export const AnalysisManagementAreaForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisManagementAreaForm_));
