import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";

import { Menu } from "~/core";

import { messages as globalMessages } from "~/i18n-messages";

import { eventsSelectors, actions as recsEventsActions } from "~/recs-events";

import { DeleteConfDialog } from "../../../../../context-menus/item-delete-confirmation-dialog";
import { StatusCodes } from "../../../../../common/status-messages";
import { withUser } from "~/hocs";
import * as eventInfoActions from "../../../event-info/actions";

import { messages } from "../../../i18n-messages";
import * as actions from "../../actions";

import { keywords } from "../../../../keywords";
import { ErrorDetailsContextMenuLabel } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";

class ContextMenu_ extends Component {
    static propTypes = {
        activateEvent: PropTypes.any,
        activeTab: PropTypes.any,
        copyEventInfo: PropTypes.func.isRequired,
        deleteEvent: PropTypes.func.isRequired,
        eventSummary: PropTypes.any.isRequired,
        exportPoints: PropTypes.func.isRequired,
        exportSurface: PropTypes.func.isRequired,
        exportEventData: PropTypes.func.isRequired,
        getViewOnly: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        isApplicationEvent: PropTypes.bool.isRequired,
        isECDataEvent: PropTypes.bool.isRequired,
        isFromEquationRec: PropTypes.bool,
        isImportHarvestEvent: PropTypes.bool.isRequired,
        isSamplingEvent: PropTypes.bool.isRequired,
        isSamplingTissueEvent: PropTypes.bool.isRequired,
        isScoutingEvent: PropTypes.bool.isRequired,
        itemDimIdx: PropTypes.array.isRequired,
        objGuid: PropTypes.string.isRequired,
        setEventSummary: PropTypes.func.isRequired,
        showEventInfo: PropTypes.func.isRequired,
        showErrorDetails: PropTypes.func.isRequired,
        userRole: PropTypes.object,
        yieldCalibration: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isContextMenuExpanded: false,
            isDeleteConfirmationOpen: false,
        };
    }

    _getMenuItems() {
        const {
            copyEventInfo,
            eventSummary,
            exportPoints,
            exportSurface,
            exportEventData,
            getViewOnly,
            isECDataEvent,
            isImportHarvestEvent,
            isSamplingEvent,
            isScoutingEvent,
            isFromEquationRec,
            setEventSummary,
            showEventInfo,
            showErrorDetails,
            userRole,
            yieldCalibration,
            activateEvent,
            activeTab,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const menuItems = [];
        if (
            activeTab === recsEventsActions.RecEventListTabs.ACTIVE ||
            activeTab === recsEventsActions.RecEventListTabs.SELECTED
        ) {
            menuItems.push({
                key: 0,
                label: getViewOnly()
                    ? formatMessage(messages.viewDetails)
                    : formatMessage(messages.viewEditDetails),
                action: () => showEventInfo(eventSummary),
                disabled: eventSummary.importedStatus === StatusCodes.ErrorCreatingEvent,
            });
            if (
                !isECDataEvent &&
                !isFromEquationRec &&
                (isSamplingEvent || !eventSummary.isImportedYn) &&
                eventSummary.importedStatus !== StatusCodes.ErrorCreatingEvent
            ) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.copy),
                    action: () => copyEventInfo(eventSummary),
                });
            }
            menuItems.push({
                key: menuItems.length + 1,
                label: formatMessage(messages.delete),
                action: () => this.setState({ isDeleteConfirmationOpen: true }),
            });
            if (
                userRole.exportSurfaces &&
                !((isSamplingEvent || isScoutingEvent) && eventSummary.importedPoints === 0)
            ) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.exportSurface),
                    action: () => exportSurface([eventSummary]),
                });
            }
            if (
                userRole.exportEventData &&
                !(isSamplingEvent || isScoutingEvent || isFromEquationRec) &&
                eventSummary.isImportedYn
            ) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.exportEventData),
                    action: () => exportEventData([eventSummary]),
                });
            }
            if (isSamplingEvent) {
                if (userRole.exportSamplePoints) {
                    menuItems.push({
                        key: menuItems.length + 1,
                        label: formatMessage(messages.exportPoints),
                        action: () => exportPoints(eventSummary),
                    });
                }
            } else if (isImportHarvestEvent) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.yieldCalibration),
                    action: () => {
                        yieldCalibration();
                        setEventSummary(eventSummary);
                    },
                });
            }
        } else {
            if (userRole.activeInactive) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.activateEvent),
                    action: () => activateEvent(),
                });
            }
        }
        if (
            [
                StatusCodes.ErrorProcessingSurface,
                StatusCodes.ErrorCreatingEvent,
                StatusCodes.ErrorProcessingEvent,
            ].includes(eventSummary.importedStatus)
        ) {
            menuItems.push({
                key: menuItems.length + 1,
                label: formatMessage(ErrorDetailsContextMenuLabel),
                action: () => showErrorDetails(eventSummary),
            });
        }
        return menuItems;
    }

    _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }

    render() {
        const { formatMessage } = this.props.intl;

        const onDelete = () => this.props.deleteEvent();
        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: messages.deleteEventConfirmation,
                  onConfirmationClick: (confirmation) => {
                      const afterClose = !confirmation ? () => null : onDelete;
                      this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                  },
              });

        const contextMenuClassNames = classNames("context-menu", {
            expanded: this.state.isContextMenuExpanded,
        });
        return (
            <div className="context-menu-container" onClick={(event) => this._onClick(event)}>
                <Menu
                    className={contextMenuClassNames}
                    isDotMenu={true}
                    getMenuItems={() => this._getMenuItems()}
                    onExpand={() => this.setState({ isContextMenuExpanded: true })}
                    onCollapse={() => this.setState({ isContextMenuExpanded: false })}
                />
                {deleteConfDialog}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const { itemDimIdx, objGuid } = ownProps;
    return {
        copyEventInfo: (eventSummary) => dispatch(eventInfoActions.copyEventInfo(eventSummary)),
        deleteEvent: () => dispatch(actions.deleteEventsFromDimIdxList([itemDimIdx])),
        exportPoints: (eventSummary) => dispatch(actions.exportSamplingPoints([eventSummary])),
        exportSurface: (eventSummary) => dispatch(actions.exportSurfaceData(eventSummary)),
        exportEventData: (eventSummary) => dispatch(actions.exportEventData(eventSummary)),
        showEventInfo: (eventSummary) => dispatch(eventInfoActions.showEventInfo(eventSummary)),
        setEventSummary: (eventSummary) => dispatch(eventInfoActions.setEventSummary(eventSummary)),
        showErrorDetails: (eventSummary) => dispatch(actions.showErrorDetails(eventSummary)),
        yieldCalibration: () => dispatch(actions.loadYieldCalibration([objGuid])),
        activateEvent: () => dispatch(actions.activateEventFromDimIdx(itemDimIdx)),
    };
};

const mapStateToProps = (state, ownProps) => {
    const { activeTab } = ownProps;
    const isInactiveTab = activeTab === recsEventsActions.RecEventListTabs.INACTIVE;
    const eventGeneralGuidToEventMap = isInactiveTab
        ? eventsSelectors.getEventGeneralGuidToInactiveEventSummaryMap(state)
        : eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);
    const eventTypeInfoList = eventsSelectors.getEventTypeInfoList(state);
    const eventSummary = eventGeneralGuidToEventMap.get(ownProps.objGuid);
    const eventTypeInfo = eventTypeInfoList.find(
        (typeInfo) =>
            typeInfo.agEventTransactionTypeGuid === eventSummary.agEventTransactionTypeGuid
    );
    return {
        eventGeneralGuidToEventMap,
        eventSummary,
        isSamplingEvent: Boolean(eventTypeInfo.sampleTypeGuid),
        isImportHarvestEvent: Boolean(
            eventTypeInfo.agEventTransactionTypeName === keywords.harvest &&
                eventSummary.isImportedYn
        ),
        isECDataEvent: Boolean(eventTypeInfo.agEventTransactionTypeName === keywords.ecData),
        isScoutingEvent: Boolean(eventTypeInfo.agEventTransactionTypeName === keywords.scouting),
        isApplicationEvent: Boolean(
            eventTypeInfo.agEventTransactionTypeName === keywords.application
        ),
        isSamplingTissueEvent: Boolean(eventSummary.agEventTypeName === keywords.samplingTissue),
        isFromEquationRec: Boolean(eventSummary.isFromEquationRec),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getViewOnly: () => {
        const { eventGeneralGuidToEventMap } = stateProps;
        const { objGuid } = ownProps;
        const eventSummary = eventGeneralGuidToEventMap.get(objGuid);
        return eventSummary.importedPoints > 0;
    },
});

export const ContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(withUser(ContextMenu_)));
