import PropTypes from "prop-types";
import * as model from "~/admin/agBytes/product/data/model";

export const shape = {
    [model.PROPS_ACTIVE_YN]: PropTypes.bool,
    [model.PROPS_AGGATEWAY_COMPANY_ID]: PropTypes.string,
    [model.PROPS_AGGATEWAY_PRODUCT_ID]: PropTypes.string,
    [model.PROPS_AGRIAN_ID]: PropTypes.string,
    [model.PROPS_AGX_ID]: PropTypes.string,
    [model.PROPS_ANGLE_OF_REPOSE]: PropTypes.string,
    [model.PROPS_BRAND_ORGANIZATION_GUID]: PropTypes.string,
    [model.PROPS_BRAND_ORGANIZATION_NAME]: PropTypes.string,
    [model.PROPS_BUFFER_DISTANCE_INFORMATION]: PropTypes.string,
    [model.PROPS_CHEMICAL_FAMILY_GUID]: PropTypes.string,
    [model.PROPS_CHEMICAL_FAMILY_NAME]: PropTypes.string,
    [model.PROPS_CROP_GUID]: PropTypes.string,
    [model.PROPS_CROP_ID]: PropTypes.string,
    [model.PROPS_CROP_NAME]: PropTypes.string,
    [model.PROPS_DENSITY_UNIT_NAME]: PropTypes.any,
    [model.PROPS_DENSITY]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    [model.PROPS_DENSITY_UNIT_ABBREVIATION]: PropTypes.string,
    [model.PROPS_DENSITY_UNIT_GUID]: PropTypes.string,
    [model.PROPS_DOCUMENT_LIST]: PropTypes.array,
    [model.PROPS_FEDERALLY_RESTRICTED]: PropTypes.bool,
    [model.PROPS_FORMULATION_TYPE_GUID]: PropTypes.string,
    [model.PROPS_FORMULATION_TYPE_NAME]: PropTypes.string,
    [model.PROPS_FREEZING_TEMPERATURE]: PropTypes.string,
    [model.PROPS_GRAZING_RESTRICTION]: PropTypes.bool,
    [model.PROPS_INGREDIENT_LIST]: PropTypes.array,
    [model.PROPS_MANUFACTURER_GUID]: PropTypes.string,
    [model.PROPS_MANUFACTURER_NAME]: PropTypes.string,
    [model.PROPS_MEDICAL_PHONENUMBER_OBJ]: PropTypes.object,
    [model.PROPS_MODE_OF_ACTION]: PropTypes.string,
    [model.PROPS_NUTRIENT_LIST]: PropTypes.array,
    [model.PROPS_OXIDE]: PropTypes.bool,
    [model.PROPS_PEST_INJURY_SYMPTOMS]: PropTypes.string,
    [model.PROPS_PH]: PropTypes.string,
    [model.PROPS_PHYSICAL_STATE_GUID]: PropTypes.string,
    [model.PROPS_PHYSICAL_STATE_NAME]: PropTypes.string,
    [model.PROPS_PPE_INFORMATION]: PropTypes.string,
    [model.PROPS_PPE_REENTRY_INFORMATION]: PropTypes.string,
    [model.PROPS_PRODUCT_ACTION_METHOD_GUID]: PropTypes.string,
    [model.PROPS_PRODUCT_ACTION_METHOD_NAME]: PropTypes.string,
    [model.PROPS_PRODUCT_GUID]: PropTypes.string,
    [model.PROPS_PRODUCT_ID]: PropTypes.any,
    [model.PROPS_PRODUCT_NAME]: PropTypes.string,
    [model.PROPS_PRODUCT_PACKAGE_LIST]: PropTypes.array,
    [model.PROPS_PRODUCT_PARENT_TYPE_GUID]: PropTypes.string,
    [model.PROPS_PRODUCT_PARENT_TYPE_NAME]: PropTypes.string,
    [model.PROPS_PRODUCT_PLANT_BACK_RESTRICTION_LIST]: PropTypes.array,
    [model.PROPS_PRODUCT_TYPE_LIST]: PropTypes.array,
    [model.PROPS_REGISTRATION_CODE]: PropTypes.string,
    [model.PROPS_REI_HOURS_HIGH]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    [model.PROPS_REI_HOURS_LOW]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    [model.PROPS_RESPONSE_PHONENUMBER_OBJ]: PropTypes.object,
    [model.PROPS_SALTING_OUT_TEMPERATURE]: PropTypes.string,
    [model.PROPS_SHELF_LIFE]: PropTypes.string,
    [model.PROPS_SIGNAL_WORD]: PropTypes.string,
    [model.PROPS_SOLUBILITY_IN_WATER]: PropTypes.string,
    [model.PROPS_SPECIAL_INSTRUCTIONS]: PropTypes.string,
    [model.PROPS_SPECIFIC_GRAVITY]: PropTypes.any,
    [model.PROPS_TREATMENT]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_GUID]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_ID]: PropTypes.string,
    [model.PROPS_VARIETY_HYBRID_NAME]: PropTypes.string,
};

export default PropTypes.shape(shape);
