import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import * as actions from "../actions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getEmbedDashboardUrl, getAnalyticDashboardTypes, getOrgLevelList } from "../selectors";
import { messages } from "../i18n-messages";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { Loader, SelectInput } from "~/core";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";

import "./analytics.css";
export class Analytics_ extends Component {
    static propTypes = {
        clearBreadcrumbs: PropTypes.func,
        fetchAnalyticDashboardTypes: PropTypes.func,
        fetchDashboardUrl: PropTypes.func,
        fetchOrgLevelList: PropTypes.func,
        embedDashboardUrl: PropTypes.string,
        analyticDashboardTypes: PropTypes.array,
        orgLevelList: PropTypes.array,
        intl: intlShape.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedAnalyticDashboardTypeGuid: null,
            analyticDashboardTypeOptions: [],
            loadingDashboard: false,
        };
    }

    componentDidMount() {
        this.props.fetchAnalyticDashboardTypes();
        this.props.fetchOrgLevelList();
        this.props.clearBreadcrumbs();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.analyticDashboardTypes !== this.props.analyticDashboardTypes) {
            this.setState({
                analyticDashboardTypeOptions: nextProps.analyticDashboardTypes.map((t) => ({
                    value: t.analyticDashboardTypeGuid,
                    label: t.name,
                })),
                selectedAnalyticDashboardTypeGuid:
                    nextProps.analyticDashboardTypes.length === 1
                        ? nextProps.analyticDashboardTypes[0].analyticDashboardTypeGuid
                        : null,
            });
            if (nextProps.analyticDashboardTypes.length === 1) {
                this.fetchDashboard(nextProps.analyticDashboardTypes[0].analyticDashboardTypeGuid);
            }
        }
        if (
            nextProps.embedDashboardUrl &&
            this.props.embedDashboardUrl !== nextProps.embedDashboardUrl &&
            nextProps.orgLevelList
        ) {
            this.renderDashboardUrl(nextProps.embedDashboardUrl, nextProps.orgLevelList);
        }
    }

    fetchDashboard(dashboardTypeGuid) {
        this.fetchAndClearDashboardContainer();
        if (dashboardTypeGuid) {
            this.props.fetchDashboardUrl(dashboardTypeGuid);
        }
    }

    fetchAndClearDashboardContainer() {
        var containerDiv = document.getElementById("dashboardContainer");
        containerDiv.innerHTML = null;
        return containerDiv;
    }

    renderDashboardUrl(dashboardUrl, orgLevelList) {
        this.setState({ loadingDashboard: true });
        const userOrgName = this.getDefaultOrgName(orgLevelList);
        const containerDiv = this.fetchAndClearDashboardContainer();
        const options = {
            url: dashboardUrl,
            container: containerDiv,
            scrolling: "no",
            height: "100%",
            width: "100%",
            printEnabled: true,
            footerPaddingEnabled: false,
            loadCallback: () => {
                this.setState({ loadingDashboard: false });
            },
            parameters: {
                ParentOrgLevelName: userOrgName,
            },
        };
        embedDashboard(options);
    }

    getDefaultOrgName(orgLevelList) {
        const { orgName } = orgLevelList.reduce(
            (curr, org) =>
                org.orgLevelParents.length < curr.numParents
                    ? {
                          orgName: org.orgLevelName,
                          numParents: org.orgLevelParents.length,
                      }
                    : curr,
            { orgName: null, numParents: Number.MAX_SAFE_INTEGER }
        );

        return orgName;
    }

    render() {
        const {
            selectedAnalyticDashboardTypeGuid,
            analyticDashboardTypeOptions,
            loadingDashboard,
        } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <div className="analytics-main-container">
                <div className="dashboardSelector">
                    <SelectInput
                        placeholderText={formatMessage(messages.selectedDashboardPlaceholder)}
                        value={selectedAnalyticDashboardTypeGuid}
                        options={analyticDashboardTypeOptions}
                        onChange={(value) => this.fetchDashboard(value)}
                    />
                </div>
                {loadingDashboard ? <Loader /> : null}
                <div
                    id="dashboardContainer"
                    style={!loadingDashboard ? null : { display: "none" }}
                ></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    embedDashboardUrl: getEmbedDashboardUrl(state),
    analyticDashboardTypes: getAnalyticDashboardTypes(state),
    orgLevelList: getOrgLevelList(state),
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDashboardUrl: (analyticDashboardTypeGuid) =>
            dispatch(actions.fetchDashboardUrl(analyticDashboardTypeGuid)),
        fetchAnalyticDashboardTypes: () => dispatch(actions.fetchAnalyticDashboardTypes()),
        fetchOrgLevelList: () => dispatch(actions.fetchOrgLevelList()),
        clearBreadcrumbs: () => dispatch(setBreadcrumbs("")),
    };
};
export const Analytics = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Analytics_));
