import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { connect } from "react-redux";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { withMasked, withApiResult } from "~/hocs";
import { actions, selectors } from "~/admin/setup/release-notes/data";
import { Button, TextEditor } from "~/core";
import "./setup-release-notes.css";
import { keywords } from "~/admin/containers/keywords";

interface ISetupReleaseNotesOptionsProps {
    getReleaseNotes: () => string;
    loadingModal: boolean;
    releaseNotes: string;
    updateReleaseNotes: (releaseNotes: string) => void;
    needs: () => number;
}

interface ISetupReleaseNotesOptionsState {
    releaseNotes: string;
}

class SetupReleaseNotes_ extends Component<
    ISetupReleaseNotesOptionsProps & InjectedIntlProps,
    ISetupReleaseNotesOptionsState
> {
    modifiedReleaseNotes: string;
    releaseNotesSaveRequestId: string;

    constructor(props) {
        super(props);
        this.state = {
            releaseNotes: "",
        };
        this.modifiedReleaseNotes = "";
        this.releaseNotesSaveRequestId = null;
    }

    componentDidMount() {
        const { needs } = this.props;
        needs([this.props.getReleaseNotes()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISetupReleaseNotesOptionsProps) {
        const newState: any = {};
        if (
            nextProps.releaseNotes.length > 0 &&
            nextProps.releaseNotes !== this.state.releaseNotes
        ) {
            newState.releaseNotes = nextProps.releaseNotes;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }

        this._processSaveReleaseNotes(nextProps);
    }

    _processSaveReleaseNotes = (nextProps) => {
        if (nextProps.requestIds[this.releaseNotesSaveRequestId] === keywords.SUCCESS) {
            this.props.closeSlidingPanel();
            this.releaseNotesSaveRequestId = null;
        } else if (nextProps.requestIds[this.releaseNotesSaveRequestId] === keywords.FAILURE) {
            this.releaseNotesSaveRequestId = null;
        }
    };

    _onTextChange = (value) => {
        this.modifiedReleaseNotes = value;
    };

    _renderReleaseNotes = () => {
        const { releaseNotes } = this.state;
        return (
            <div className="add-edit-panel">
                <TextEditor
                    containerClassName="release-notes-editor"
                    value={releaseNotes}
                    onChange={(value) => this._onTextChange(value)}
                ></TextEditor>
            </div>
        );
    };

    _onSaveReleaseNotes = () => {
        if (this.releaseNotesSaveRequestId === null) {
            const { needs, updateReleaseNotes } = this.props;
            this.releaseNotesSaveRequestId = needs([updateReleaseNotes(this.modifiedReleaseNotes)]);
        }
    };

    _onCancel = () => {
        const { closeSlidingPanel } = this.props;
        closeSlidingPanel();
    };

    render() {
        const props = {
            children: [
                <Button key="0" type="save" forceSubmit onClick={this._onSaveReleaseNotes} />,
                <Button key="1" type="cancel" onClick={this._onCancel} />,
            ],
            component: this._renderReleaseNotes,
            navigateTo: { parentNameCode: 101, childNameCode: 428 },
        };
        return (
            <div>
                <SlidingPanel {...props} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    releaseNotes: selectors.getReleaseNotes(state),
});

const mapDispatchToProps = (dispatch) => ({
    getReleaseNotes: actions.getReleaseNotes,
    updateReleaseNotes: actions.updateReleaseNotes,
});

export const SetupReleaseNotes = withMasked(
    withApiResult(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SetupReleaseNotes_)))
);
