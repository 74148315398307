import React from "react";
import { Toolbar, ToolbarButton, ToolbarMenu, ToolbarTool } from "~/core";
import { SamplingTools } from "@ai360/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";

export const GridPointPlacementMethod = {
    CENTER: 0,
    RANDOM: 1,
    AUTO: 3,
};

export class SamplingToolSet extends SamplingTools {
    getToolbar(disabled = false): JSX.Element {
        this.setGettingToolbar(true);
        const { messages, formatMessage } = this.options;
        const toolsDisabled = this._areToolsDisabled();

        if ((disabled || toolsDisabled || this.showingGrid) !== this.disabled) {
            this.toggleTool({ selected: false }, false);
        }
        this.disabled = disabled || this.showingGrid;

        this.setGettingToolbar(false);

        const toolbarTools = [
            <ToolbarTool
                icon={ToolbarIcon(IconType.ADD_POINT)}
                disabled={toolsDisabled}
                key={IconType.ADD_POINT}
                label={formatMessage(messages.addSelectLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.addSelectTool = tool)}
            />,
            <ToolbarButton
                icon={ToolbarIcon(IconType.DELETE)}
                disabled={!this.canDelete()}
                label={formatMessage(messages.deleteLbl)}
                key={IconType.DELETE}
                onClick={() => this.delete()}
            />,
        ];
        if (!this.viewOnly) {
            toolbarTools.splice(
                1,
                0,
                <ToolbarTool
                    icon={ToolbarIcon(IconType.ADD_MULTIPOINT)}
                    disabled={toolsDisabled || this.selectedSites.length !== 1}
                    label={formatMessage(messages.compositeLbl)}
                    key={IconType.ADD_MULTIPOINT}
                    onToggle={(evt) => this.toggleTool(evt)}
                    ref={(tool) => (this.compositeTool = tool)}
                />
            );
        }
        return (
            <ToolbarMenu
                key="sampling"
                icon={ToolbarIcon(IconType.SAMPLING)}
                disabled={this.disabled}
                label={formatMessage(messages.samplingLbl)}
                ref={(menu) => (this.menu = menu)}
            >
                <Toolbar>{toolbarTools}</Toolbar>
            </ToolbarMenu>
        );
    }
}
