/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as models from "./model";

import Immutable from "immutable";

import { AgEventAPI, FieldAPI, SearchAPI } from "@ai360/core";
import { ClassBreak, ZoneGeometryInfo } from "@ai360/core";
import { IFieldData, ISettings } from "./interfaces";
import { ICalibrateEquipmentLoads } from "./models/yield-calibration";
import Graphic from "@arcgis/core/Graphic";
import { IEventChangedMessage } from "../model";
import { action } from "typesafe-actions";

export type agEventAreaIdType = number;

export const ACTIVATE_EVENT = "recs-events/ACTIVATE_EVENT";
export const ACTIVATE_EVENT_SUCCESSFUL = "recs-events/ACTIVATE_EVENT_SUCCESSFUL";
export const ADD_AREA = "recs-events/ADD_AREA";
export const ADD_AGGREGATE_EVENT = "recs-events/ADD_AGGREGATE_EVENT";
export const ADD_LATEST_UPDATED_EVENT = "recs-events/ADD_LATEST_UPDATED_EVENT";
export const APPLY_YIELD_CALIBRATION = "recs-events/APPLY_YIELD_CALIBRATION";
export const BATCH_COPY_EVENT_DETAILS = "recs-events/BATCH_COPY_EVENT_DETAILS";
export const BATCH_COPY_EVENTS = "recs-events/BATCH_COPY_EVENTS";
export const BATCH_COPY_RESET = "recs-events/BATCH_COPY_RESET";
export const BATCH_SAMPLING_RESET = "recs-events/BATCH_SAMPLING_RESET";
export const CACHE_TEMPORARY_SCOUTING_PHOTO_LIST =
    "recs-events/CACHE_TEMPORARY_SCOUTING_PHOTO_LIST";
export const CLEAR_TEMPORARY_SCOUTING_PHOTO_LIST =
    "recs-events/CLEAR_TEMPORARY_SCOUTING_PHOTO_LIST";
export const COPY_BATCH_TEMPLATE_TO_EVENTS = "recs-events/COPY_BATCH_TEMPLATE_TO_EVENTS";
export const COPY_EVENT_DETAILS = "recs-events/COPY_EVENT_DETAILS";
export const CLEAR_EVENT_DETAILS = "recs-events/CLEAR_EVENT_DETAILS";
export const CLEAR_YIELD_CALIBRATION_ERROR = "recs-events/CLEAR_YIELD_CALIBRATION_ERROR";
export const CLEAR_YIELD_CALIBRATION_LOAD_LIST = "recs-events/CLEAR_YIELD_CALIBRATION_LOAD_LIST";
export const CREATE_NEW_BATCH_EVENT = "recs-events/CREATE_NEW_BATCH_EVENT";
export const CREATE_NEW_COPY_BATCH_EVENT = "recs-events/CREATE_NEW_COPY_BATCH_EVENT";
export const CREATE_COPY_BATCH_EVENT_DETAILS = "recs-events/CREATE_COPY_BATCH_EVENT_DETAILS";
export const CREATE_NEW_EVENT_DETAILS = "recs-events/CREATE_NEW_EVENT_DETAILS";
export const CREATE_NEW_CLASSIFIED_EVENT_DETAILS =
    "recs-events/CREATE_NEW_CLASSIFIED_EVENT_DETAILS";
export const DELETE_EVENTS = "recs-events/DELETE_EVENTS";
export const DELETE_EVENTS_SUCCESSFUL = "recs-events/DELETE_EVENTS_SUCCESSFUL";
export const DELETE_TEMPORARY_SCOUTING_PHOTOS = "recs-events/DELETE_TEMPORARY_SCOUTING_PHOTOS";
export const FETCH_BATCH_EVENT_DETAILS_SUCCEEDED =
    "recs-events/FETCH_BATCH_EVENT_DETAILS_SUCCEEDED";
export const FETCH_EVENT_DETAILS = "recs-events/FETCH_EVENT_DETAILS";
export const FETCH_EVENT_DETAILS_SUCCEEDED = "recs-events/FETCH_EVENT_DETAILS_SUCCEEDED";
export const FETCH_EVENTS = "recs-events/FETCH_EVENTS";
export const FETCH_EVENTS_SUCCEEDED = "recs-events/FETCH_EVENTS_SUCCEEDED";
export const FETCH_LAYER_NAME_TO_SURFACE_INFO_MAP =
    "recs-events/FETCH_LAYER_NAME_TO_SURFACE_INFO_MAP";
export const FETCH_SOIL_SAMPLE_RESULTS = "recs-events/FETCH_SOIL_SAMPLE_RESULTS";
export const FETCH_MERGE_SOIL_SAMPLE_RESULTS = "recs-events/FETCH_MERGE_SOIL_SAMPLE_RESULTS";
export const FETCH_SOIL_SAMPLE_RESULTS_SUCCEEDED =
    "recs-events/FETCH_SOIL_SAMPLE_RESULTS_SUCCEEDED";
export const FETCH_TISSUE_SAMPLE_RESULTS = "recs-events/FETCH_TISSUE_SAMPLE_RESULTS";
export const FETCH_TISSUE_SAMPLE_RESULTS_SUCCEEDED =
    "recs-events/FETCH_TISSUE_SAMPLE_RESULTS_SUCCEEDED";
export const FETCH_YIELD_CALIBRATION = "recs-events/FETCH_YIELD_CALIBRATION";
export const FETCH_YIELD_CALIBRATION_COMPLETED = "recs-events/FETCH_YIELD_CALIBRATION_COMPLETED";
export const YIELD_CALIBRATION_COMPLETE = "rec-events/YIELD_CALIBRATION_COMPLETE";
export const REFRESH_YIELD_CALIBRATION = "recs-events/REFRESH_YIELD_CALIBRATION";
export const SET_PASSED_YIELD_CALIBRATION_VALIDATION =
    "recs-events/SET_PASSED_YIELD_CALIBRATION_VALIDATION";
export const INITIALIZE_EVENTS_ZONES = "recs-events/INITIALIZE_EVENTS_ZONES";
export const INITIALIZE_EVENTS_ZONES_SUCCEEDED = "recs-events/INITIALIZE_EVENTS_ZONES_SUCCEEDED";
export const MERGE_EVENTS_SAVE = "recs-events/MERGE_EVENTS";
export const MERGE_EVENTS_SUCCEEDED = "recs-events/MERGE_EVENTS_SUCCEEDED";
export const MERGE_EVENTS_FAILED = "recs-events/MERGE_EVENTS_FAILED";
export const REMOVE_AGGREGATE_EVENT = "recs-events/REMOVE_AGGREGATE_EVENT";
export const REMOVE_AGGREGATE_SCOUTING_EVENT = "recs-events/REMOVE_AGGREGATE_SCOUTING_EVENT";
export const REMOVE_EVENT_GENERAL = "recs-events/REMOVE_EVENT_GENERAL";
export const REMOVE_INACTIVE_EVENT_GENERAL = "recs-events/REMOVE_INACTIVE_EVENT_GENERAL";
export const REPLACE_EVENT_GENERAL = "recs-events/REPLACE_EVENT_GENERAL";
export const REPLACE_INACTIVE_EVENT_GENERAL = "recs-events/REPLACE_INACTIVE_EVENT_GENERAL";
export const RESET_CURRENT_EVENT_AREA_EVENT = "recs-events/RESET_CURRENT_EVENT_AREA_EVENT";
export const RESET_CLASSIFIED_EVENT_AREA_POLYGONS =
    "recs-events/RESET_CLASSIFIED_EVENT_AREA_POLYGONS";
export const RESET_EVENT_AREA_POLYGONS = "recs-events/RESET_EVENT_AREA_POLYGONS";
export const RESET_EVENT_AREAS = "recs-events/RESET_EVENT_AREAS";
export const RESET_YIELD_CALIBRATION = "recs-events/RESET_YIELD_CALIBRATION";
export const RESTORE_BATCH_SAMPLE_POINTS = "recs-events/RESTORE_BATCH_SAMPLE_POINTS";
export const S3_PHOTO_COPY_FAILED = "recs-events/S3_PHOTO_COPY_FAILED";
export const S3_PHOTO_UPLOAD_FAILED = "recs-events/S3_PHOTO_UPLOAD_FAILED";
export const SAVE_EVENT_DETAILS = "recs-events/SAVE_EVENT_DETAILS";
export const SAVE_EVENT_DETAILS_FAILED = "recs-events/SAVE_EVENT_DETAILS_FAILED";
export const SAVE_EVENT_DETAILS_SUCCEEDED = "recs-events/SAVE_EVENT_DETAILS_SUCCEEDED";
export const SET_BATCH_COPY_SAMPLE_POINTS = "recs-events/SET_BATCH_COPY_SAMPLE_POINTS";
export const SET_CURRENT_CROP_GUID = "recs-events/SET_CURRENT_CROP_GUID";
export const SET_EQUIPMENT_HARVEST_LOAD_MAP = "recs-events/SET_EQUIPMENT_HARVEST_LOAD_MAP";
export const SET_EVENT_AREA_IS_INCLUDED = "recs-events/SET_EVENT_AREA_IS_INCLUDED";
export const SET_EVENT_MODEL = "recs-events/SET_EVENT_MODEL";
export const SET_EVENT_SAMPLE_POINTS = "recs-events/SET_EVENT_SAMPLE_POINTS";
export const SET_EVENT_TYPE_INFO = "recs-events/SET_EVENT_TYPE_INFO";
export const SET_EVENT_ZONES_FROM_LAYER = "recs-events/SET_EVENT_ZONES_FROM_LAYER";
export const SET_GRID_MIN_MAX_AREA = "recs-events/SET_GRID_MIN_MAX_AREA";
export const SET_GRID_ORIENTATION_SETTINGS = "recs-events/SET_GRID_ORIENTATION_SETTINGS";
export const SET_GRID_STANDARD_UNIT_SETTINGS = "recs-events/SET_GRID_STANDARD_UNIT_SETTINGS";
export const SET_LAST_EVENT_BATCH_FIELD_GUID = "recs-events/SET_LAST_EVENT_BATCH_FIELD_GUID";
export const SET_LAYER_NAME_TO_SURFACE_INFO_MAP = "recs-events/SET_LAYER_NAME_TO_SURFACE_INFO_MAP";
export const SET_SAMPLING_EVENT_ID = "recs-events/SET_SAMPLING_EVENT_ID";
export const SET_SAMPLING_FIELD_POINTS_PLACED = "recs-events/SET_SAMPLING_FIELD_POINTS_PLACED";
export const SET_SCOUTING_PHOTO_PRESIGNED_URL_MAP =
    "recs-events/SET_SCOUTING_PHOTO_PRESIGNED_URL_MAP";
export const SET_SELECTED_SAMPLE_POINTS = "recs-events/SET_SELECTED_SAMPLE_POINTS";
export const SET_TRACE_COUNTS = "recs-events/SET_TRACE_COUNTS";
export const SET_TRACE_SITES = "recs-events/SET_TRACE_SITES";
export const UPDATE_BATCH_DEPTH_CONFIG = "recs-events/UPDATE_BATCH_DEPTH_CONFIG";
export const UPDATE_BATCH_SAMPLING_EVENTS = "recs-events/UPDATE_BATCH_SAMPLING_EVENTS";
export const UPDATE_CURRENT_EVENT_AREA_EVENT_MODEL =
    "recs-events/UPDATE_CURRENT_EVENT_AREA_EVENT_MODEL";
export const UPDATE_EVENT_MODEL = "recs-events/UPDATE_EVENT_MODEL";
export const UPDATE_EVENT_AREA_POLYGONS = "recs-events/UPDATE_EVENT_AREA_POLYGONS";
export const UPDATE_EVENT_DETAILS = "recs-events/UPDATE_EVENT_DETAILS";
export const UPDATE_EVENT_SUMMARY_IMPORTED_STATUS =
    "recs-events/UPDATE_EVENT_SUMMARY_IMPORTED_STATUS";
export const UPDATE_PLANTING_EVENT_MODEL = "recs-events/UPDATE_PLANTING_EVENT_MODEL";
export const UPDATE_SCOUTING_EVENT_MODEL = "recs-events/UPDATE_SCOUTING_EVENT_MODEL";
export const UPDATE_SAMPLING_EVENT_MODEL = "recs-events/UPDATE_SAMPLING_EVENT_MODEL";
export const UPDATE_SOIL_SAMPLE_RESULTS = "recs-events/UPDATE_SOIL_SAMPLE_RESULTS";
export const UPDATE_SELECTED_FIELD_EVENTS = "recs-events/UPDATE_SELECTED_FIELD_EVENTS";
export const UPDATE_TISSUE_SAMPLE_RESULTS = "recs-events/UPDATE_TISSUE_SAMPLE_RESULTS";
export const DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID =
    "recs-events/DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID";

export const activateEvent = (agEventGeneralGuid: string) =>
    action(ACTIVATE_EVENT, { agEventGeneralGuid });

export const activateEventSuccessful = () => action(ACTIVATE_EVENT_SUCCESSFUL);

export const addAggregateEvent = (
    fieldGuid: string,
    agEventTransactionTypeGuid: string,
    sampleTypeGuid: string
) => action(ADD_AGGREGATE_EVENT, { fieldGuid, agEventTransactionTypeGuid, sampleTypeGuid });

export const addLatestUpdatedEvent = (agEventSummary: any[]) =>
    action(ADD_LATEST_UPDATED_EVENT, { agEventSummary });

export const addArea = (
    fieldGuid: string,
    eventTypeName: string,
    eventAreaId: number,
    currentAreaId: number,
    calculatedArea: number,
    shape: string
) =>
    action(ADD_AREA, {
        fieldGuid,
        eventTypeName,
        eventAreaId,
        currentAreaId,
        calculatedArea,
        shape,
    });

export const applyYieldCalibration = (
    yieldCalibrationModel?: Record<string, unknown>,
    agEventGuidList?: string[]
) => action(APPLY_YIELD_CALIBRATION, { yieldCalibrationModel, agEventGuidList });

export const batchCopyEvents = (
    selectedEvents: models.AgEventSummary[],
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>
) => action(BATCH_COPY_EVENTS, { selectedEvents, fieldGuidList, fieldGuidToBoundaryGuidMap });

export const batchCopyEventDetails = (
    agEventGeneralGuidList: string[],
    agEventTransactionTypeName: string,
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>
) =>
    action(BATCH_COPY_EVENT_DETAILS, {
        agEventGeneralGuidList,
        agEventTransactionTypeName,
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
    });

export const batchCopyReset = () => action(BATCH_COPY_RESET);

export const batchSamplingReset = () => action(BATCH_SAMPLING_RESET);

export const cacheTemporaryScoutingPhotoList = (temporaryScoutingPhotoList: string[]) =>
    action(CACHE_TEMPORARY_SCOUTING_PHOTO_LIST, { temporaryScoutingPhotoList });

export const clearTemporaryScoutingPhotoList = () => action(CLEAR_TEMPORARY_SCOUTING_PHOTO_LIST);

export const copyBatchTemplateToEvents = () => action(COPY_BATCH_TEMPLATE_TO_EVENTS);

export const clearEventDetails = () => action(CLEAR_EVENT_DETAILS);

export const clearYieldCalibrationError = () => action(CLEAR_YIELD_CALIBRATION_ERROR);

export const clearYieldCalibrationLoadList = () => action(CLEAR_YIELD_CALIBRATION_LOAD_LIST);

export const copyEventDetails = (agEventGeneralGuid: string) =>
    action(COPY_EVENT_DETAILS, { agEventGeneralGuid });

export const createNewBatchEvent = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    fieldGuidToCustomerGuidMap: Map<string, string>,
    agEventTransactionTypeName: string
) =>
    action(CREATE_NEW_BATCH_EVENT, {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        fieldGuidToCustomerGuidMap,
        agEventTransactionTypeName,
    });

export const createNewCopyBatchEvent = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    agEventTransactionTypeName?: string,
    eventAreaList?: models.AgEventArea[]
) =>
    action(CREATE_NEW_COPY_BATCH_EVENT, {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        agEventTransactionTypeName,
        eventAreaList,
    });

export const createCopyBatchEventDetails = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    agEventTransactionTypeName?: string,
    eventAreaList?: models.AgEventArea[]
) =>
    action(CREATE_COPY_BATCH_EVENT_DETAILS, {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        agEventTransactionTypeName,
        eventAreaList,
    });

export const createNewClassifiedEvent = (
    field: IFieldData,
    surfaceInfo: Record<string, any>,
    agEventTransactionTypeName: string,
    triggeredPage: Record<string, any>
) =>
    action(CREATE_NEW_CLASSIFIED_EVENT_DETAILS, {
        field,
        surfaceInfo,
        agEventTransactionTypeName,
        triggeredPage,
    });

export const createNewEventDetails = (
    fieldGuidList: string[],
    fieldGuidToBoundaryGuidMap: Map<string, string>,
    fieldGuidToCustomerGuidMap: Map<string, string>,
    agEventTransactionTypeName?: string,
    eventAreaList?: models.AgEventArea[]
) =>
    action(CREATE_NEW_EVENT_DETAILS, {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        fieldGuidToCustomerGuidMap,
        agEventTransactionTypeName,
        eventAreaList,
    });

export const deleteEvents = (agEventGeneralGuidList: string[]) =>
    action(DELETE_EVENTS, { agEventGeneralGuidList });

export const deleteEventsSuccessful = () => action(DELETE_EVENTS_SUCCESSFUL);

export const deleteTemporaryScoutingPhotos = () => action(DELETE_TEMPORARY_SCOUTING_PHOTOS);

export const fetchEventDetails = (
    agEventGeneralGuid: string,
    fetchSamplingResults = true,
    isCopyAction = false
) => action(FETCH_EVENT_DETAILS, { agEventGeneralGuid, fetchSamplingResults, isCopyAction });

export const fetchBatchEventDetailsSucceeded = (eventDetails: models.EventDetails[]) =>
    action(FETCH_BATCH_EVENT_DETAILS_SUCCEEDED, { eventDetails });

export const fetchEventDetailsSucceeded = (
    eventDetails: models.EventDetails,
    fetchSamplingResults = true,
    isCopyAction = false
) => action(FETCH_EVENT_DETAILS_SUCCEEDED, { eventDetails, fetchSamplingResults, isCopyAction });

export const fetchLayerNameToSurfaceInfoMap = (fieldGuid: string) =>
    action(FETCH_LAYER_NAME_TO_SURFACE_INFO_MAP, { fieldGuid });

export const fetchSelectedFieldEvents = () => action(FETCH_EVENTS);

export const fetchSelectedFieldEventsSucceeded = (
    fieldGuidToEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>,
    fieldGuidToInactiveEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>,
    fieldGuidToFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>,
    fieldGuidToInactiveFieldMap: Immutable.OrderedMap<string, SearchAPI.IFieldResult>
) =>
    action(FETCH_EVENTS_SUCCEEDED, {
        fieldGuidToEventListMap,
        fieldGuidToInactiveEventListMap,
        fieldGuidToFieldMap,
        fieldGuidToInactiveFieldMap,
    });

export const fetchSoilSampleResults = (agEventGuid: string) =>
    action(FETCH_SOIL_SAMPLE_RESULTS, { agEventGuid });

export const fetchMergeSoilSampleResults = (mergeableEvents: Record<string, unknown>) =>
    action(FETCH_MERGE_SOIL_SAMPLE_RESULTS, { mergeableEvents });

export const fetchSoilSampleResultsSucceeded = (soilSampleResults: {
    gridData: Record<string, any>[];
    gridHeader: Record<string, any>[];
}) => action(FETCH_SOIL_SAMPLE_RESULTS_SUCCEEDED, { soilSampleResults });

export const fetchTissueSampleResults = (agEventGuid: string) =>
    action(FETCH_TISSUE_SAMPLE_RESULTS, { agEventGuid });

export const fetchTissueSampleResultsSucceeded = (tissueSampleResults: {
    gridData: Record<string, any>[];
    gridHeader: Record<string, any>[];
}) => action(FETCH_TISSUE_SAMPLE_RESULTS_SUCCEEDED, { tissueSampleResults });

export const fetchYieldCalibration = (agEventGuidList: string[], windowLoad: boolean) =>
    action(FETCH_YIELD_CALIBRATION, { agEventGuidList, windowLoad });

export const fetchYieldCalibrationCompleted = (results: any[]) =>
    action(FETCH_YIELD_CALIBRATION_COMPLETED, { results });

export const refreshYieldCalibration = (agEventGuidList: string[]) =>
    action(REFRESH_YIELD_CALIBRATION, { agEventGuidList });

export const setEquipmentHarvestLoadMap = (
    equipmentGuidToHarvestLoadListMap: Map<string, ICalibrateEquipmentLoads>
) => action(SET_EQUIPMENT_HARVEST_LOAD_MAP, { equipmentGuidToHarvestLoadListMap });

export const yieldCalibrationComplete = (results: any) =>
    action(YIELD_CALIBRATION_COMPLETE, results);

export const initializeEventsZones = () => action(INITIALIZE_EVENTS_ZONES);

export const initializeEventsZonesSucceeded = () => action(INITIALIZE_EVENTS_ZONES_SUCCEEDED);

export const mergeEventsSave = (mergeableEvents: Record<string, unknown>) =>
    action(MERGE_EVENTS_SAVE, { mergeableEvents });

export const mergeEventsSucceeded = (mergeableEvents: Record<string, unknown>) =>
    action(MERGE_EVENTS_SUCCEEDED, { mergeableEvents });

export const mergeEventsFailed = () => action(MERGE_EVENTS_FAILED);

export const removeAggregateEvent = (fieldGuid: string, agEventTransactionTypeGuid: string) =>
    action(REMOVE_AGGREGATE_EVENT, { fieldGuid, agEventTransactionTypeGuid });

export const removeAggregateScoutingEvent = (
    fieldGuid: string,
    agEventTransactionTypeGuid: string
) => action(REMOVE_AGGREGATE_SCOUTING_EVENT, { fieldGuid, agEventTransactionTypeGuid });

export const removeEventGeneral = (
    changes: Array<{
        fieldGuid: string;
        agEventGeneralGuid: string;
    }> = []
) => action(REMOVE_EVENT_GENERAL, { changes, fields: null as SearchAPI.IFieldResult[] });

export const removeInactiveEventGeneral = (
    changes: Array<{
        fieldGuid: string;
        agEventGeneralGuid: string;
        eventData: FieldAPI.IAgEventSummary;
    }> = []
) => action(REMOVE_INACTIVE_EVENT_GENERAL, { changes, fields: null as SearchAPI.IFieldResult[] });

export const replaceEventGeneral = (
    changes: IEventChangedMessage[],
    fields: SearchAPI.IFieldResult[]
) => action(REPLACE_EVENT_GENERAL, { changes, fields });

export const replaceInactiveEventGeneral = (
    changes: IEventChangedMessage[],
    fields: SearchAPI.IFieldResult[]
) => action(REPLACE_INACTIVE_EVENT_GENERAL, { changes, fields });

export const resetEventAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<number, ZoneGeometryInfo>
) =>
    action(RESET_EVENT_AREA_POLYGONS, {
        fieldGuid,
        areaIdToPolygonMap,
        areaIdToClassBreaksMap: null as Map<number, ClassBreak>,
    });

export const resetClassifiedEventAreaPolygons = (
    fieldGuid: string,
    areaIdToPolygonMap: Map<number, ZoneGeometryInfo>,
    areaIdToClassBreaksMap: Map<number, ClassBreak>
) =>
    action(RESET_CLASSIFIED_EVENT_AREA_POLYGONS, {
        fieldGuid,
        areaIdToPolygonMap,
        areaIdToClassBreaksMap,
    });

export const resetCurrentAgEventAreaAgEvent = (
    fieldGuid: string,
    agEventTransactionTypeGuid: string
) => action(RESET_CURRENT_EVENT_AREA_EVENT, { fieldGuid, agEventTransactionTypeGuid });

export const resetEventAreas = (fieldGuid: string) => action(RESET_EVENT_AREAS, { fieldGuid });

export const resetYieldCalibration = () => action(RESET_YIELD_CALIBRATION);

export const s3PhotoUploadFailed = () => action(S3_PHOTO_UPLOAD_FAILED);

export const s3PhotoCopyFailed = () => action(S3_PHOTO_COPY_FAILED);

export const restoreBatchSamplePoints = () => action(RESTORE_BATCH_SAMPLE_POINTS);

export const saveEventDetails = () => action(SAVE_EVENT_DETAILS);

export const saveEventDetailsFailed = (errorCodeList: number[], errorModel?: unknown) =>
    action(SAVE_EVENT_DETAILS_FAILED, { errorCodeList, errorModel });

export const saveEventDetailsSucceeded = () => action(SAVE_EVENT_DETAILS_SUCCEEDED);

export const setAreaIsIncluded = (
    fieldGuid: string,
    areaId: agEventAreaIdType,
    isIncluded: boolean
) => action(SET_EVENT_AREA_IS_INCLUDED, { fieldGuid, areaId, isIncluded });

export const setBatchCopySamplePoints = () => action(SET_BATCH_COPY_SAMPLE_POINTS);

export const setEventModel = (
    fieldGuid: string,
    agEventAreaId: agEventAreaIdType,
    agEventTransactionTypeGuid: string,
    newProps: Record<string, unknown>
) => action(SET_EVENT_MODEL, { fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps });

export const setEventSamplePoints = (samplePoints: AgEventAPI.ISoilSamplePoint[]) =>
    action(SET_EVENT_SAMPLE_POINTS, { samplePoints });

export const setEventTypeInfo = (agEventTypeInfoList: models.AgEventTypeInfo[]) =>
    action(SET_EVENT_TYPE_INFO, { agEventTypeInfoList });

export const setEventZonesFromLayer = (fieldGuid: string, surfaceInfo: Record<string, any>) =>
    action(SET_EVENT_ZONES_FROM_LAYER, { fieldGuid, surfaceInfo });

export const setGridMinMaxArea = (minAreaAcres: number, maxAreaAcres: number) =>
    action(SET_GRID_MIN_MAX_AREA, { minAreaAcres, maxAreaAcres });

export const setGridOrientationSettings = (settings: ISettings) =>
    action(SET_GRID_ORIENTATION_SETTINGS, { settings });

export const setGridStandardUnitSettings = (settings: ISettings) =>
    action(SET_GRID_STANDARD_UNIT_SETTINGS, { settings });

export const setLastEventBatchFieldGuid = (batchFieldGuid: string) =>
    action(SET_LAST_EVENT_BATCH_FIELD_GUID, { batchFieldGuid });

export const setLayerNameToSurfaceInfoMap = (
    layerNameToSurfaceInfoMap: Map<string, Record<string, any>>
) => action(SET_LAYER_NAME_TO_SURFACE_INFO_MAP, { layerNameToSurfaceInfoMap });

export const setSamplingEventId = (fieldGuid: string) =>
    action(SET_SAMPLING_EVENT_ID, { fieldGuid });

export const setSamplingFieldPointsPlaced = (isPlaced: boolean) =>
    action(SET_SAMPLING_FIELD_POINTS_PLACED, { isPlaced });

export const setScoutingPhotoPresignedUrlMap = (
    scoutingPhotoPresignedUrlMap: Map<string, string>
) => action(SET_SCOUTING_PHOTO_PRESIGNED_URL_MAP, { scoutingPhotoPresignedUrlMap });

export const setSelectedSamplePoints = (selectedSamplePointGuidIdSet: Set<number>) =>
    action(SET_SELECTED_SAMPLE_POINTS, { selectedSamplePointGuidIdSet });

export const setTraceCounts = (traceCompleteCount: number, traceTotalCount: number) =>
    action(SET_TRACE_COUNTS, { traceCompleteCount, traceTotalCount });

export const setTraceSites = (traceSites: Graphic[]) => action(SET_TRACE_SITES, { traceSites });

export const updateAgEventModel = (
    fieldGuid: string,
    agEventAreaId: agEventAreaIdType,
    agEventTransactionTypeGuid: string,
    newProps: Record<string, any>
) => action(UPDATE_EVENT_MODEL, { fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps });

export const updateBatchDepthConfig = (depthIAGuid: string, samplePointDepths: number) =>
    action(UPDATE_BATCH_DEPTH_CONFIG, { depthIAGuid, samplePointDepths });

export const updateBatchSamplingEvents = (
    isPointProps: boolean,
    newProps: Record<string, unknown>
) => action(UPDATE_BATCH_SAMPLING_EVENTS, { isPointProps, newProps });

export const updateCurrentAgEventAreaAgEventModel = (
    fieldGuid: string,
    agEventTransactionTypeGuid: string,
    newProps: Record<string, any>
) =>
    action(UPDATE_CURRENT_EVENT_AREA_EVENT_MODEL, {
        fieldGuid,
        agEventTransactionTypeGuid,
        newProps,
    });

export const updateEventAreaPolygons = (
    fieldGuid: string,
    areaIdToNewAreaIdPolygonMap: Map<agEventAreaIdType, Map<agEventAreaIdType, ZoneGeometryInfo>>
) => action(UPDATE_EVENT_AREA_POLYGONS, { fieldGuid, areaIdToNewAreaIdPolygonMap });

export const updateEventDetails = (fieldGuid: string, newProps: Partial<models.EventDetails>) =>
    action(UPDATE_EVENT_DETAILS, { fieldGuid, newProps });

export const updateEventSummaryImportedStatus = (
    fieldGuid: string,
    agEventGeneralGuid: string,
    importedStatus: number
) =>
    action(UPDATE_EVENT_SUMMARY_IMPORTED_STATUS, { fieldGuid, agEventGeneralGuid, importedStatus });

export const updatePlantingEventModel = (payload: Record<string, any>) =>
    action(UPDATE_PLANTING_EVENT_MODEL, payload);

export const updateSamplingAgEventModel = (fieldGuid: string, newProps: Record<string, unknown>) =>
    action(UPDATE_SAMPLING_EVENT_MODEL, { fieldGuid, newProps });

export const updateScoutingAgEventModel = (fieldGuid: string, newProps: Record<string, unknown>) =>
    action(UPDATE_SCOUTING_EVENT_MODEL, { fieldGuid, newProps });

export const updateSoilSampleResults = (soilSampleResults: {
    gridData: Record<string, any>[];
    gridHeader: Record<string, any>[];
}) => action(UPDATE_SOIL_SAMPLE_RESULTS, { soilSampleResults });

export const updateTissueSampleResults = (tissueSampleResults: {
    gridData: Record<string, any>[];
    gridHeader: Record<string, any>[];
}) => action(UPDATE_TISSUE_SAMPLE_RESULTS, { tissueSampleResults });

export const updateSelectedFieldEvents = (
    fieldGuidToEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>,
    fieldGuidToInactiveEventListMap: Immutable.OrderedMap<string, models.AgEventSummary[]>
) =>
    action(UPDATE_SELECTED_FIELD_EVENTS, {
        fieldGuidToEventListMap,
        fieldGuidToInactiveEventListMap,
    });

export const setPassedYieldCalibrationValidationCompleted = (results: boolean) =>
    action(SET_PASSED_YIELD_CALIBRATION_VALIDATION, { results });

export const destroyEventsByAgEventGeneralGuid = (agEventGeneralGuids: string[]) =>
    action(DESTROY_EVENTS_BY_AG_EVENT_GENERAL_GUID, { agEventGeneralGuids });
