import { combineReducers } from "redux";

import * as immutableUtils from "~/utils/immutable";

import {
    accordionReducer,
    actions as accordionActions,
    selectors as accordionSelectors,
    model as accordionModel,
} from "~/accordion";
import { actions as fileImportActions } from "~/file-import";

import * as wizardSelectors from "./components/import-wizard/selectors";
import { importWizardReducer } from "./components/import-wizard/reducer";

import * as actions from "./actions";
import * as selectors from "./selectors";

export const importModuleInitialState = Object.freeze({
    activePage: actions.ImportModulePages.IMPORT_LIST,
    canDeleteStatusCodes: null,
    importTypeFilterGuid: null,
    initScrollTop: 0,
    isLoading: false,
    lastImportFileItemClickedDimIdx: [0],
    scrollToIndex: null,
    selectedImportFileGuidList: new Set(),
    showFilters: false,
    searchValue: "",
});

export const reducer = (state = importModuleInitialState, action) => {
    switch (action.type) {
        case actions.LOAD_IMPORT_WIZARD: {
            return Object.freeze({
                ...state,
                activePage: actions.ImportModulePages.IMPORT_WIZARD,
            });
        }
        case actions.CLOSE_IMPORT_WIZARD: {
            return Object.freeze({
                ...state,
                activePage: actions.ImportModulePages.IMPORT_LIST,
            });
        }
        case actions.SET_CAN_DELETE_STATUS_CODES: {
            const { canDeleteStatusCodes } = action.payload;
            return Object.freeze({
                ...state,
                canDeleteStatusCodes,
            });
        }
        case actions.SELECT_IMPORT_FILE_INFO_ITEM: {
            const selectedImportFileGuidList = immutableUtils.noMutateSetAdd(
                state.selectedImportFileGuidList,
                action.payload.importFileGuid
            );
            return Object.freeze({
                ...state,
                selectedImportFileGuidList,
            });
        }
        case actions.SELECT_ALL_IMPORT_FILE_INFO_ITEMS: {
            const selectedImportFileGuidList = immutableUtils.noMutateSetAddAll(
                state.selectedImportFileGuidList,
                action.payload.importFileGuidSet
            );
            return Object.freeze({
                ...state,
                selectedImportFileGuidList,
            });
        }
        case actions.DESELECT_IMPORT_FILE_INFO_ITEMS: {
            const selectedImportFileGuidList = immutableUtils.noMutateSetDeleteAll(
                state.selectedImportFileGuidList,
                action.payload.importFileGuidSet
            );
            return Object.freeze({
                ...state,
                selectedImportFileGuidList,
            });
        }
        case actions.SET_SCROLL_TO_INDEX: {
            const { scrollToIndex } = action.payload;
            return Object.freeze({
                ...state,
                scrollToIndex,
            });
        }
        case actions.SET_IS_LOADING: {
            const { isLoading } = action.payload;
            return Object.freeze({
                ...state,
                isLoading,
            });
        }
        case actions.SET_LAST_CLICKED_DIMIDX: {
            const { lastImportFileItemClickedDimIdx } = action.payload;
            return Object.freeze({
                ...state,
                lastImportFileItemClickedDimIdx,
            });
        }
        case actions.SET_INIT_SCROLL_TOP: {
            return Object.freeze({
                ...state,
                initScrollTop: action.payload.scrollTop,
            });
        }
        case actions.SET_SHOW_FILTERS: {
            return Object.freeze({
                ...state,
                showFilters: action.payload.showFilters,
            });
        }
        case actions.SET_FILTER_TYPE_GUID: {
            return Object.freeze({
                ...state,
                importTypeFilterGuid: action.payload.importTypeFilterGuid,
            });
        }
        case actions.RESET_FILTER_TYPE_GUID: {
            return Object.freeze({
                ...state,
                importTypeFilterGuid: null,
            });
        }
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        default:
            return state;
    }
};

const importAccordionReducer = (state, action) => {
    if (action.type !== fileImportActions.REFRESH_IMPORT_TYPE_TEMPLATE_INFO_COMPLETE) {
        return accordionReducer(state, action);
    }

    // remove accordion type/template items that no longer exist ..
    const { fileImportTypes } = action.payload;
    const validTypeGuidSet = new Set(fileImportTypes.map((type) => type.guid));
    const validTemplateGuidSet = fileImportTypes.reduce((resultSet, type) => {
        type.templateList.forEach((tmpl) => resultSet.add(tmpl.templateGuid));
        return resultSet;
    }, new Set());

    const itemList = accordionModel.AccordionItem.recursiveFilter(
        accordionSelectors.itemsSelector(state),
        ({ item, dimIdx }) => {
            if (dimIdx.length === 1) {
                return validTypeGuidSet.has(item.payload.guid);
            }
            if (dimIdx.length === 2) {
                return validTemplateGuidSet.has(item.payload.templateGuid);
            }
            return true;
        }
    );
    const { accordionId } = state;
    return accordionReducer(
        state,
        accordionActions.replaceAllAccordionItems(accordionId, itemList)
    );
};

export const importModuleReducer = combineReducers({
    [selectors.MAIN_REDUCER_KEY]: reducer,
    [selectors.ACCORDION_KEY]: importAccordionReducer,
    [wizardSelectors.IMPORT_WIZARD_KEY]: importWizardReducer,
});
