import moment from "moment";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import { ClassBreak, GeometryUtils } from "@ai360/core";
import { AgEventAPI } from "@ai360/core";

import {
    AgEvent,
    AgEventArea,
    AgEventAreaPolygon,
    SampleSetup,
    AgEventTypeInfo,
    EVENT_TYPE_NAME_EC_DATA,
    EVENT_TYPE_NAME_SCOUTING,
    EVENT_TYPE_NAME_SAMPLING_SOIL,
    EVENT_TYPE_NAME_SAMPLING_TISSUE,
    transformSamplePointToApi,
    transformSamplePointFromApi,
} from "../model";

export interface agEventGeneralEquipmentProfile {
    agEventGeneralEquipmentProfileGuid: string;
    agEventGeneralGuid: string;
    equipmentProfileGuid: string;
    equipmentName: string;
    equipmentType: string;
    equipmentParentTypeName: string;
    ownerOperatorName: string;
    controllerId: string;
}

export class EventDetails implements AgEventAPI.IEventDetails {
    get hasOneSampleIdPerZone(): boolean {
        if (!this.isSamplingEvent) {
            return false;
        }
        const zoneGeometries = this.eventAreaList
            .filter((ea) => ea.applyEventToArea)
            .map((ea) => {
                return GeometryUtils.getCombinedBoundaries(ea.zonePolygons.map((p) => p.shape));
            }) as Polygon[];
        // All sample sites are on the first eventArea/agEvent for sampling events
        const { samplePoints } = this.eventAreaList[0].agEventList[0].agEventModel as SampleSetup;
        return zoneGeometries.every((polygon) => {
            const sites: AgEventAPI.ISamplePoint[] = samplePoints.filter((site) =>
                polygon.contains(new Point(JSON.parse(site.shape)))
            );
            const uniqueSampleIds: number[] = sites.reduce((sampleIds, site) => {
                const sampleId = site.sampleId;
                if (sampleIds.indexOf(sampleId) === -1) {
                    sampleIds.push(sampleId);
                }
                return sampleIds;
            }, []);
            return uniqueSampleIds.length === 1;
        });
    }

    get isSamplingEvent(): boolean {
        return (
            this.agEventTypeList.length > 0 &&
            this.agEventTypeList[0].sampleTypeGuid != null &&
            this.agEventTypeList[0].sampleTypeGuid !== ""
        );
    }

    get isECDataEvent(): boolean {
        return (
            this.agEventTypeList.length > 0 &&
            this.agEventTypeList[0].typeName != null &&
            this.agEventTypeList[0].typeName === EVENT_TYPE_NAME_EC_DATA
        );
    }

    get isScoutingEvent(): boolean {
        return (
            this.agEventTypeList.length > 0 &&
            this.agEventTypeList[0].typeName != null &&
            this.agEventTypeList[0].typeName === EVENT_TYPE_NAME_SCOUTING
        );
    }

    public static parseServerDateTime(startDate: string, startTime: string): moment.Moment {
        const dateStr = `${startDate} ${startTime}`;
        return moment(dateStr, `${EventDetails.SERVER_DATE_FMT} ${EventDetails.SERVER_TIME_FMT}`);
    }

    public static groomEventDetailsForAPICall(
        eventDetails: EventDetails,
        defaultDepthConfig: { configDetail: any[] } = { configDetail: [] }
    ): Readonly<EventDetails> {
        console.assert(eventDetails instanceof EventDetails);
        const { fieldGuid, fieldBoundaryGuid, agEventTypeList } = eventDetails;
        const rv: EventDetails = new EventDetails(fieldGuid, fieldBoundaryGuid, agEventTypeList);
        Object.assign(rv, eventDetails);
        if (rv.isSamplingEvent) {
            rv.eventAreaList = rv.eventAreaList.map((eventArea, index) => ({
                ...eventArea,
                agEventList:
                    index !== 0
                        ? []
                        : eventArea.agEventList.map((agEvent) => ({
                              ...agEvent,
                              agEventModel: {
                                  ...agEvent.agEventModel,
                                  samplePoints: (
                                      agEvent.agEventModel as SampleSetup
                                  ).samplePoints.map((samplePoint) => {
                                      transformSamplePointToApi(samplePoint);
                                      if (
                                          samplePoint.isNew ||
                                          agEvent.agEventModel.agEventGuid === ""
                                      ) {
                                          if (
                                              (samplePoint as AgEventAPI.ISoilSamplePoint)
                                                  .soilSamplePointGuid !== undefined
                                          ) {
                                              let { samplePointDepths } =
                                                  samplePoint as AgEventAPI.ISoilSamplePoint;
                                              if (samplePointDepths == null) {
                                                  samplePointDepths =
                                                      defaultDepthConfig.configDetail;
                                              }
                                              // need to clear out temp local guids for new Sample Points
                                              return {
                                                  ...samplePoint,
                                                  soilSamplePointGuid: "",
                                                  samplePointDepths: samplePointDepths.map(
                                                      (depth) => ({
                                                          ...depth,
                                                          soilSamplePointGuid: "",
                                                          soilSamplePointDepthGuid: "",
                                                          scanCode: "",
                                                      })
                                                  ),
                                              };
                                          } else if (
                                              (samplePoint as AgEventAPI.ITissueSamplePoint)
                                                  .eventSampleTissuePointGuid !== undefined
                                          ) {
                                              return {
                                                  ...samplePoint,
                                                  eventSampleTissuePointGuid: "",
                                              };
                                          }
                                      }
                                      return samplePoint;
                                  }),
                              },
                          })),
            }));
        }
        return Object.freeze(rv);
    }

    public static updateEventDetails(
        eventDetails: EventDetails,
        newProps: Partial<EventDetails>
    ): Readonly<EventDetails> {
        console.assert(eventDetails instanceof EventDetails);
        const { fieldGuid, fieldBoundaryGuid, agEventTypeList } = eventDetails;
        const rv = new EventDetails(fieldGuid, fieldBoundaryGuid, agEventTypeList);
        Object.assign(rv, eventDetails, newProps);
        return Object.freeze(rv);
    }

    public static fromJsonObj(jsonObj: AgEventAPI.IEventDetails): EventDetails {
        const { agEventTypeList, eventAreaList, fieldGuid, fieldBoundaryGuid, ...simpleFields } =
            jsonObj;
        const agEventTypeList_ = agEventTypeList
            .filter(
                (agEventTypeJsonObj) =>
                    agEventTypeJsonObj.agEventTransactionTypeGuid != null &&
                    agEventTypeJsonObj.agEventTransactionTypeGuid !== ""
            )
            .map((agEventTypeJsonObj) => AgEventTypeInfo.fromJsonObj(agEventTypeJsonObj));

        const rv = new EventDetails(fieldGuid, fieldBoundaryGuid, agEventTypeList_);
        Object.assign(rv, simpleFields);
        rv.eventAreaList = eventAreaList.map((agEventAreaJsonObj) => {
            Object.setPrototypeOf(agEventAreaJsonObj.zonePolygons, [AgEventAreaPolygon.prototype]);
            if (agEventAreaJsonObj.eventAreaClassBreak != null) {
                Object.setPrototypeOf(agEventAreaJsonObj.eventAreaClassBreak, ClassBreak.prototype);
            }
            agEventAreaJsonObj.agEventList = agEventAreaJsonObj.agEventList.map(
                (agEventJsonObj) => {
                    const agEventTypeInfo = agEventTypeList_.find(
                        (typeInfo) =>
                            typeInfo.agEventTransactionTypeGuid ===
                            agEventJsonObj.agEventTransactionTypeGuid
                    );

                    const isSampleEvent =
                        agEventTypeInfo.agEventTransactionTypeName ===
                            EVENT_TYPE_NAME_SAMPLING_SOIL ||
                        agEventTypeInfo.agEventTransactionTypeName ===
                            EVENT_TYPE_NAME_SAMPLING_TISSUE;
                    if (isSampleEvent) {
                        const sampleEvent: AgEventAPI.ISampleSetup = agEventJsonObj.agEventModel;
                        const samplePoints = sampleEvent.samplePoints;
                        if (samplePoints) {
                            for (const point of samplePoints) {
                                point.batchSamplingEventGuid =
                                    agEventJsonObj.agEventModel.batchSamplingEventGuid;
                                transformSamplePointFromApi(point);
                            }
                        }
                    }

                    const agEvent = new AgEvent(agEventTypeInfo);
                    agEvent.agEventModel = agEvent.agEventModel.updateAgEventModel(
                        agEventJsonObj.agEventModel
                    );
                    agEvent.agEventGuid = agEvent.agEventModel.agEventGuid
                        ? agEvent.agEventModel.agEventGuid
                        : agEventJsonObj.agEventGuid;
                    return agEvent;
                }
            );
            return AgEventArea.updateAgEventArea(new AgEventArea(null, null), agEventAreaJsonObj);
        });

        rv.momentStartDate = EventDetails.parseServerDateTime(jsonObj.startDate, jsonObj.startTime);
        rv.momentEndDate = EventDetails.parseServerDateTime(jsonObj.endDate, jsonObj.endTime);

        // Remove duplicate AgEvent instances returned by the API
        if (rv.isSamplingEvent && rv.eventAreaList.length > 1) {
            rv.eventAreaList = rv.eventAreaList.map((agEventArea, idx) => {
                if (idx === 0) {
                    console.assert(agEventArea.eventAreaId === 1);
                    return agEventArea;
                }
                return AgEventArea.updateAgEventArea(agEventArea, {
                    agEventList: [],
                });
            });
        }
        return Object.freeze(rv);
    }

    private static readonly SERVER_DATE_FMT = "MM-DD-YYYY";
    private static readonly SERVER_TIME_FMT = "hh:mmA";

    public momentStartDate: moment.Moment;
    public momentEndDate: moment.Moment;
    public eventAreaList: AgEventArea[];

    public agEventGeneralGuid = "";
    public creationType = "";
    public creationTypeGuid = "";
    public startDate = ""; // "10/21/2016 12:00:00 PM",
    public startTime = ""; // "12:00PM",
    public endDate = ""; // "10/21/2016 12:00:00 PM",
    public endTime = ""; // "12:00PM",
    public modifiedDate = "";
    public modifiedBy = "";
    public croppingSeasonGuid = "";
    public croppingSeasonCycleGuid = "";
    public eventName = "";
    public cropGrowingDays: number | null = null;
    public notes = "";
    public personList: any[] = []; // TODO: define w/ `person` module & import
    public isClassified = false; // TODO: remove
    public surfaceRendererVectorGuid: string | null = ""; // TODO: remove
    public surfaceExploded = false; // TODO: remove
    public reInterpolateFieldFoundYn = false; // TODO: remove
    // soil conditions
    public soilConditionGuid = "";
    public soilTemperature: number | null = null;
    public densityRatingGuid = "";
    // weather info
    public generalWeatherGuid = "";
    public airTemperature: number | null = null;
    public windSpeed: number | null = null;
    public directionGuid = "";
    public humidityPercentage: number | null = null;
    public precipitation: number | null = null;
    public cloudCoverPercentage: number | null = null;
    public filters: Record<string, any> | null = null;
    public zoneFileGuid: string | null = null;
    public recommendation: string | null = null;
    public equipmentList: agEventGeneralEquipmentProfile[] = [];
    public canEditEquipment: false;
    public isFromEquationRec: false;
    public customerGuid: string;

    constructor(
        public fieldGuid: string,
        public fieldBoundaryGuid: string,
        public agEventTypeList: AgEventTypeInfo[]
    ) {
        const agEventList = agEventTypeList.map((agEventTypeInfo) =>
            Object.freeze(new AgEvent(agEventTypeInfo))
        );
        this.eventAreaList = [new AgEventArea(null, agEventList, 0, this.isSamplingEvent)];

        const defaultStartEndDate = moment().startOf("day").hour(12);

        this.momentStartDate = defaultStartEndDate;
        this.momentEndDate = defaultStartEndDate;
    }

    public isMultipartZoneAutoSplitAllowed(): boolean {
        return true;
    }
}
