import moment from "moment";
import { errorStatusCodes } from "../action-panel/components/common/status-codes";
export interface IFilter {
    includeOtherUsers: boolean;
    userFilterStr: string;
    userFilterObj: any;
}
interface IJsonObj {
    [x: string]: any;
}
export interface IImportType {
    name: string;
    guid: string;
    isExpanded: boolean;
}

export interface IImportFileState {
    filter: IFilter;
    fetchingTypes: boolean;
    importTypes: Map<string, IImportType>;
    importTemplates: Map<string, ImportTemplate>;
    importFiles: Map<string, ImportFileInfo>;
}

export const WAITING_TO_UPLOAD_STATUS_NAME = "WaitingToUpload";
export const UPLOADING_STATUS_CODE = 1;

export class ImportTemplate {
    public static fromJsonObj(jsonObj: IJsonObj): Readonly<ImportTemplate> {
        const rv = new ImportTemplate();
        rv.name = jsonObj.name;
        rv.templateGuid = jsonObj.templateGuid;
        rv.fileTypes = jsonObj.fileTypes.toLowerCase();
        rv.importTypeGuid = jsonObj.importTypeGuid;
        rv.importControllerTypeGuid = jsonObj.importControllerTypeGuid;
        rv.isExpanded = Boolean(jsonObj.isExpanded);
        rv.isFetchingFiles = false;
        rv.isNameMatch = jsonObj.isNameMatch;
        rv.haveFetched = false;
        return Object.freeze(rv);
    }

    public templateGuid = "";
    public name = "";
    public fileTypes = "";
    public importTypeGuid = "";
    public importControllerTypeGuid = "";
    public isExpanded = false;
    public isFetchingFiles = false;
    public isNameMatch = false;
    public haveFetched = false;

    public isValid(): boolean {
        return Boolean(this.name && this.templateGuid && this.fileTypes && this.importTypeGuid);
    }

    public isZipNativeFormat(): boolean {
        return this.fileTypes === ".zip";
    }

    public clone(newProps = {}): ImportTemplate {
        const rv = new ImportTemplate();
        Object.assign(rv, this);
        Object.assign(rv, newProps);
        return rv;
    }
}

export class ImportFileInfo {
    public static fromJsonObj(jsonObj: IJsonObj): Readonly<ImportFileInfo> {
        const uploadDatetime = moment(`${jsonObj.uploadedDateUtc}Z`).toDate();
        const rv = new ImportFileInfo();
        rv.guid = jsonObj.importFileGuid;
        rv.fileName = jsonObj.importFileName;
        rv.templateGuid = jsonObj.templateGuid;
        rv.status = jsonObj.importFileStatus;
        rv.statusCode = Number(jsonObj.importFileStatusCode);
        rv.importedPoints = Number(jsonObj.importPoints.importedPoints);
        rv.totalPoints = Number(jsonObj.importPoints.totalPoints);
        rv.isControllerFile = Boolean(jsonObj.isControllerFile);
        rv.isFieldBoundary = Boolean(jsonObj.isFieldBoundary);
        rv.isImagery = Boolean(jsonObj.isImagery);
        rv.uploadDatetime = uploadDatetime;
        rv.statusPercentComplete = Number(jsonObj.statusPercentComplete || 0);
        return Object.freeze(rv);
    }

    public guid = "";
    public fileName = "";
    public templateGuid = "";
    public status = "";
    public statusCode = 0;
    public importedPoints = 0;
    public totalPoints = 0;
    public isControllerFile = false;
    public isFieldBoundary = false;
    public isImagery = false;
    public uploadDatetime: Date | null = null;
    public statusPercentComplete = 0;

    public isValid(): boolean {
        return Boolean(this.guid && this.fileName && this.templateGuid && this.statusCode);
    }

    public isReadyForImport(): boolean {
        return this.statusCode > 4 && this.statusCode < 10 && !this.isControllerFile;
    }

    public hasError(): boolean {
        return errorStatusCodes.includes(this.statusCode);
    }

    public clone(newProps: Record<string, any> = {}): ImportFileInfo {
        const rv = new ImportFileInfo();
        Object.assign(rv, this);
        Object.assign(rv, newProps);
        return rv;
    }
}

export interface RxFileImportTemplateAndImportType {
    importType: IImportType;
    template: ImportTemplate;
}
