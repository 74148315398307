import React from "react";
import { connect } from "react-redux";
import { SelectInputMulti, SelectInput, DialogBox, DialogBoxFooterType } from "~/core";
import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";
import { getTheUserPersonalityId } from "~/login/selectors";
import { PICKLIST_IRRIGATION_TYPE, PICKLIST_CROP_PURPOSE } from "~/core/picklist/picklist-names";

import { withMasked, withApiResult } from "~/hocs";
import { PersonalityTypes } from "~/utils/keywords";
import { FieldAPI } from "@ai360/core";
import { IFormatter } from "@ai360/core";
import { messages } from "../../i18n-messages";
import { getBatchFieldDetails } from "../../selectors";
import { getAgvanceFieldClassificationList, getFieldModuleDropdowns } from "../../../../selectors";
import {
    fetchBatchClassificationOptions,
    updateBatchFieldDetails,
    saveBatchDetails,
} from "../../actions";
import { DROPDOWN_CROP, IrrigatedListOptions, YesNoOptions } from "../../../../sagas";

import "./batch-edit-details.css";
interface IBatchEditDetailsProps {
    agvanceFieldClassificationList: FieldAPI.IAgvClassification[];
    changeOrganicValue: (value: boolean) => void;
    className: string;
    batchField: FieldAPI.IField;
    cropOptions: any;
    cropPurposeOptions: any;
    dropdowns: any;
    fetchBatchClassificationOptions: () => void;
    irrigatedOptions: any;
    irrigationTypeOptions: any;
    intl: IFormatter;
    fieldCount: number;
    onClose: () => void;
    personalityId: number;
    saveBatchDetails: () => void;
    updateBatchFieldDetails: (field: Partial<FieldAPI.IField>) => void;
    visible: boolean;
}
interface IBatchEditDetailsState {
    multiSelectIndex: number;
    showCertOrganicChangeDialog: boolean;
}
class BatchEditDetails_ extends React.Component<IBatchEditDetailsProps, IBatchEditDetailsState> {
    constructor(props: IBatchEditDetailsProps) {
        super(props);
        this.state = {
            multiSelectIndex: 0,
            showCertOrganicChangeDialog: false,
        };
    }

    #hasValue(): boolean {
        const {
            certifiedOrganic,
            cropGuid,
            cropPurposeGuid,
            drainageTile,
            irrigated,
            irrigationTypeGuid,
            classifications,
        } = this.props.batchField;

        return (
            certifiedOrganic != null ||
            cropGuid != null ||
            cropPurposeGuid != null ||
            drainageTile != null ||
            irrigated != null ||
            irrigationTypeGuid != null ||
            classifications?.length > 0
        );
    }
    #onSave(): void {
        this.props.saveBatchDetails();
        this.#onClose();
    }
    #onClose(): void {
        const { onClose, updateBatchFieldDetails } = this.props;
        updateBatchFieldDetails({
            certifiedOrganic: null,
            classifications: null,
            cropGuid: null,
            cropPurposeGuid: null,
            drainageTile: null,
            irrigated: null,
            irrigationTypeGuid: null,
        });
        onClose();
    }
    #onDetailChange(field: Partial<FieldAPI.IField>): void {
        this.props.updateBatchFieldDetails(field);
    }
    #onIrrigatedChange(value: number): void {
        let irrigationTypeGuid = this.props.batchField.irrigationTypeGuid;
        if (value === 0 || value == null) {
            irrigationTypeGuid = null;
        }
        this.props.updateBatchFieldDetails({
            irrigated: value,
            irrigationTypeGuid,
        });
    }
    componentDidMount(): void {
        const { personalityId } = this.props;
        if (personalityId !== PersonalityTypes.DISCONNECTED) {
            this.props.fetchBatchClassificationOptions();
        }
    }
    render() {
        const {
            batchField,
            changeOrganicValue,
            cropPurposeOptions,
            dropdowns,
            irrigationTypeOptions,
            agvanceFieldClassificationList,
            fieldCount,
            visible,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const classificationOptions = agvanceFieldClassificationList.map((c) => ({
            value: c.classificationKey,
            label: c.classificationName,
        }));
        const cropOptions = (dropdowns[DROPDOWN_CROP] || []).map((i) => ({
            value: i.cropGuid,
            label: i.cropName,
        }));

        return (
            <DialogBox
                action="save"
                actionDisabled={!this.#hasValue()}
                isModal={true}
                closeOnEscape={false}
                closeOnClickOff={false}
                hideCloseX={false}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                unrestricted={true}
                draggable={true}
                title={formatMessage(messages.batchEditDetails, { fieldCount })}
                className="batch-edit-details blownup"
                onAction={() => this.#onSave()}
                onClose={() => this.#onClose()}
                isOpen={visible}
            >
                <SelectInput
                    key={`organic${batchField.certifiedOrganic}`}
                    options={YesNoOptions}
                    placeholderText={formatMessage(messages.organic)}
                    onChange={(value) => changeOrganicValue(value)}
                    value={batchField.certifiedOrganic}
                />
                <SelectInput
                    key={`crop${batchField.cropGuid}`}
                    options={cropOptions}
                    placeholderText={formatMessage(messages.crop)}
                    onChange={(cropGuid) => this.#onDetailChange({ cropGuid })}
                    value={batchField.cropGuid}
                />
                <SelectInput
                    key={`cropPurpose${batchField.cropPurposeGuid}`}
                    options={cropPurposeOptions}
                    placeholderText={formatMessage(messages.cropPurpose)}
                    onChange={(cropPurposeGuid) => this.#onDetailChange({ cropPurposeGuid })}
                    value={batchField.cropPurposeGuid}
                />
                <SelectInput
                    key={`drainage${batchField.drainageTile}`}
                    options={YesNoOptions}
                    placeholderText={formatMessage(messages.drainageTile)}
                    onChange={(value) => this.#onDetailChange({ drainageTile: value })}
                    value={batchField.drainageTile}
                />
                {classificationOptions?.length > 0 ? (
                    <SelectInputMulti
                        key={`classification${this.state.multiSelectIndex}`}
                        isClearable={true}
                        options={classificationOptions}
                        placeholder={formatMessage(messages.classification)}
                        onChange={(classifications) => this.#onDetailChange({ classifications })}
                        showTopLabel={true}
                        sort={true}
                        value={classificationOptions.filter((c) =>
                            batchField.classifications
                                ?.map((b) => b.classificationKey)
                                .includes(c.value)
                        )}
                    />
                ) : null}
                <SelectInput
                    key={`irrigation_${batchField.irrigated}`}
                    options={IrrigatedListOptions}
                    placeholderText={formatMessage(messages.irrigated)}
                    onChange={(irrigated) => this.#onIrrigatedChange(irrigated)}
                    value={batchField.irrigated}
                />
                <SelectInput
                    disabled={batchField.irrigated === 0 || batchField.irrigated == null}
                    key={`irrigationType_${batchField.irrigationTypeGuid}`}
                    options={irrigationTypeOptions}
                    placeholderText={formatMessage(messages.irrigationType)}
                    onChange={(irrigationTypeGuid) => this.#onDetailChange({ irrigationTypeGuid })}
                    value={batchField.irrigationTypeGuid}
                />
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    agvanceFieldClassificationList: getAgvanceFieldClassificationList(state),
    batchField: getBatchFieldDetails(state),
    cropPurposeOptions: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(PICKLIST_CROP_PURPOSE)
    ),
    dropdowns: getFieldModuleDropdowns(state),
    irrigationTypeOptions: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(PICKLIST_IRRIGATION_TYPE)
    ),
    personalityId: getTheUserPersonalityId(state),
});
const mapDispatchToProps = (dispatch) => ({
    fetchBatchClassificationOptions: () => dispatch(fetchBatchClassificationOptions()),
    saveBatchDetails: () => dispatch(saveBatchDetails()),
    updateBatchFieldDetails: (field) => dispatch(updateBatchFieldDetails(field)),
});
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});
export const BatchEditDetails = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(withMasked(withApiResult(BatchEditDetails_)));
