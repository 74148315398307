import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";

import { Menu } from "~/core";

import { messages } from "./i18n-messages";
import { messages as globalMessages } from "~/i18n-messages";
import { DeleteConfDialog } from "./item-delete-confirmation-dialog";
//menuItems will need to be changed for the modifyMenuItems function to have correct typing
// import { MenuItems } from "~/core/components/menu/menu-items";

export interface IItemSelectionContextProps {
    modifyMenuItems: (menuItems: any, props: IItemSelectionContextProps) => Record<string, any>;
    getSelectAllArg: () => undefined;
    getClearSelectedArg: () => undefined;
    getDeleteSelectedArg: () => undefined;
    onSelectAll?: (Selected: () => void) => void;
    getSelectAllEnabled?: () => boolean;
    onClearSelected?: (Selected: () => void) => void;
    getClearSelectedEnabled?: () => boolean;
    onDeleteSelected?: (Selected: () => void) => void;
    getDeleteSelectedEnabled?: () => boolean;
    intl?: Record<string, any>;
    deleteConfirmationMessage?: string;
}

export interface IItemSelectionContextState {
    isDeleteConfirmationOpen: boolean;
    isContextMenuExpanded?: boolean;
}
class ContextMenu_ extends Component<IItemSelectionContextProps, IItemSelectionContextState> {
    static defaultProps = {
        modifyMenuItems: (menuItems) => menuItems,
        getSelectAllArg: () => undefined,
        getClearSelectedArg: () => undefined,
        getDeleteSelectedArg: () => undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            isContextMenuExpanded: false,
            isDeleteConfirmationOpen: false,
        };
    }

    _onContextMenuExpand() {
        this.setState({ isContextMenuExpanded: true });
    }

    _onContextMenuCollapse() {
        this.setState({ isContextMenuExpanded: false });
    }

    _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }

    render() {
        const { formatMessage } = this.props.intl;
        const {
            onSelectAll,
            getSelectAllArg,
            getSelectAllEnabled,
            onClearSelected,
            getClearSelectedArg,
            getClearSelectedEnabled,
            onDeleteSelected,
            getDeleteSelectedArg,
            getDeleteSelectedEnabled,
            modifyMenuItems,
        } = this.props;

        const getMenuItems = () => {
            const menuItems = modifyMenuItems(
                [
                    {
                        label: formatMessage(messages.selectAll),
                        action: () => onSelectAll(getSelectAllArg()),
                        disabled: !getSelectAllEnabled(),
                    },
                    {
                        label: formatMessage(messages.clearSelected),
                        action: () => onClearSelected(getClearSelectedArg()),
                        disabled: !getClearSelectedEnabled(),
                    },
                    {
                        label: formatMessage(messages.deleteSelected),
                        action: () => this.setState({ isDeleteConfirmationOpen: true }),
                        disabled: !getDeleteSelectedEnabled(),
                    },
                ],
                this.props
            );

            menuItems.forEach((menuItem, i) => {
                menuItem.key = i;
            });

            return menuItems;
        };

        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: this.props.deleteConfirmationMessage,
                  onConfirmationClick: (confirmation) => {
                      const afterClose = !confirmation
                          ? () => null
                          : () => onDeleteSelected(getDeleteSelectedArg());
                      this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                  },
              });

        const contextMenuClassNames = classNames("context-menu", {
            expanded: this.state.isContextMenuExpanded,
        });
        return (
            <div className="context-menu-container" onClick={(event) => this._onClick(event)}>
                <Menu
                    className={contextMenuClassNames}
                    isDotMenu={true}
                    getMenuItems={getMenuItems}
                    onExpand={() => this._onContextMenuExpand()}
                    onCollapse={() => this._onContextMenuCollapse()}
                />
                {deleteConfDialog}
            </div>
        );
    }
}

export const ContextMenu = injectIntl(ContextMenu_);
