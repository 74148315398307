import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import * as fieldActions from "~/action-panel/components/field-module/actions";
import {
    getAgvanceFieldList,
    getAgvanceOrgLevelList,
    getFullFieldRecords,
    getCombineFieldsError,
} from "~/action-panel/components/field-module/selectors";
import { DialogBox, DialogBoxFooterType, SelectInput } from "~/core";
import SearchAgvanceField from "~/action-panel/components/common/search-agvance-field";

import { adminData } from "~/admin/data";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import { getTheUserPersonalityId } from "~/login/selectors";
import { withApiResult } from "~/hocs";
import { selectors as cdSelectors } from "~/customer-data";

import { messages as globalMessages } from "~/i18n-messages";
import { messages } from "../../i18n-messages";
import { getErrorMessages } from "~/i18n-error-messages";

import "./combine-fields-modal.css";
import { getSystemAreaLimit, getTheUserGuid } from "~/login";
import * as mapActions from "~/map/components/map-control/actions";
import { fetchOccupiedFarmNames } from "~/utils/api/field";
import { FieldAPI } from "@ai360/core";

interface IProps {
    error: any;
    clearCombineFieldsError: () => void;
    intl: any;
    clearFullFieldRecords: () => void;
    onCombineFields: (model: any) => void;
    onZoomToFields: (selectedFieldGuids: string[]) => void;
    selectedCustomerGuid: string | null;
    selectedFields: FieldAPI.IFieldResult[];
    selectedFieldGuids: string[];
    systemAreaLimit: number;
    agvanceFieldList: any[];
    agvanceOrgList: any[];
    clearAgvanceFieldList: () => void;
    fetchAgvanceFieldList: (customerGuid: string) => void;
    fetchAgvanceOrgLevelList: (customerGuid: string) => void;
    personalityId: number;
    apiError: any;
    userGuid: string;
}

interface IState {
    agvanceCustomer: any;
    showModal: boolean;
    errorCodes: any[];
    fieldName: string;
    farmName: string;
    totalAcres: number;
    agvanceCustomerGuid: string;
    agvanceFieldOrgLevelGuid: string;
    agvanceFieldGuid: string;
    customerFarms: Set<string>;
    agvanceFieldId: string;
    [key: string]: any;
}

export class CombineFieldsModal_ extends Component<IProps, IState> {
    private farmListOptions: any[];

    //region agvance integration
    private agvanceInit(customerGuid: string) {
        this.props.fetchAgvanceFieldList(customerGuid);
        this.props.fetchAgvanceOrgLevelList(customerGuid);
    }

    private updateFieldForAgvanceCustomers(selectedItem: any) {
        this.setState({
            agvanceFieldOrgLevelGuid: selectedItem.orgLevelGuid,
            agvanceCustomerGuid: selectedItem.agvanceCustomerGuid,
            agvanceFieldGuid: selectedItem.agvanceFieldGuid,
        });
    }

    private getAgvanceCustomer(agvanceOrgList: any[]) {
        const selectedValue =
            this.props.selectedFields && this.props.selectedFields.length > 0
                ? agvanceOrgList.filter(
                      ({ agvanceCustomerGuid }) =>
                          agvanceCustomerGuid === this.props.selectedFields[0].agvanceCustomerGuid
                  )
                : agvanceOrgList.filter(({ isPrimary }) => isPrimary);
        if (selectedValue.length) {
            this.updateFieldForAgvanceCustomers(selectedValue[0]);
            return selectedValue[0].agvanceCustomerGuid;
        }
        return null;
    }

    // Check for Agvance personality and availability of more than one Agvance customers
    private showAdditionalAgvanceCustomer(nextProps?: IProps) {
        const { personalityId, agvanceOrgList } = nextProps || this.props;
        return AgvanceUtils.isAgvanceConnected(personalityId) && agvanceOrgList.length > 1;
    }

    private onAgvanceCustomerChange(guid: string) {
        const selectedItem = this.props.agvanceOrgList.filter((record) => {
            return record.agvanceCustomerGuid === guid;
        });
        if (selectedItem.length) {
            this.setState({
                agvanceCustomer: guid,
            });

            this.updateFieldForAgvanceCustomers(selectedItem[0]);
        }
    }

    private onFieldNameSelection(
        fieldName = "",
        agvanceFieldGuid = "",
        agvanceFieldId = "",
        farmName = ""
    ) {
        this.setState({
            fieldName,
            agvanceFieldGuid: agvanceFieldGuid || this.state.agvanceFieldGuid,
            agvanceFieldId: agvanceFieldId || this.state.agvanceFieldId,
            farmName,
        });
    }
    //endregion

    private fieldCannotBeSaved() {
        return this.resultingFieldTooLarge() || !this.state.fieldName;
    }

    private resultingFieldTooLarge() {
        return this.props.systemAreaLimit < this.state.totalAcres;
    }

    private setupLists(nextProps: IProps, oldProps: IProps) {
        const isInit = !oldProps;

        if (isInit || this.farmListOptions.length !== this.state.customerFarms.size) {
            this.farmListOptions = [...this.state.customerFarms].map((farmName) => ({
                value: farmName,
                label: farmName,
            }));
        }
    }

    private getAgvanceFieldList() {
        if (!this.state.agvanceCustomer) {
            return this.props.agvanceFieldList;
        }
        return this.props.agvanceFieldList.filter(({ agvanceGrowerGuid }) => {
            return this.state.agvanceCustomer === agvanceGrowerGuid;
        });
    }

    private updateFieldModel(name: string, value: any) {
        this.setState({ [name]: value });
    }

    private dialogClose() {
        this.setState({ showModal: false }, () => {
            this.dispatchClose();
        });
    }

    private dialogAction() {
        this.setState(() => {
            this.dispatchCombineField();
        });
    }

    private renderField(field: FieldAPI.IFieldResult, index: number) {
        const { formatMessage } = this.props.intl;
        const messageFormat =
            field.farmName && field.farmName !== ""
                ? messages.combineFieldNameWithFarmName
                : messages.combineFieldName;

        const fieldToRender = {
            ...field,
            acres: field.acres.toFixed(2),
        };

        return (
            <div key={"combineField" + index}>{formatMessage(messageFormat, fieldToRender)}</div>
        );
    }

    private renderDetails() {
        const { formatMessage } = this.props.intl;

        if (this.resultingFieldTooLarge()) {
            return (
                <div className="combine-fields-warning">
                    {formatMessage(messages.combinedFieldTooLarge, {
                        limit: this.props.systemAreaLimit,
                        size: this.state.totalAcres,
                    })}
                </div>
            );
        } else {
            const { agvanceOrgList, apiError } = this.props; //agvanceOrgList
            const { agvanceCustomer, farmName } = this.state;
            const errorCodeList = apiError && apiError.errorCodeList ? apiError.errorCodeList : [];

            let renderError = null;
            if (this.props.error) {
                const message = getErrorMessages(formatMessage, this.props.error);
                renderError = <div className="combine-fields-error">{message}</div>;
            }

            return (
                <div>
                    <div>{formatMessage(messages.combineWarning2)}</div>
                    <div className="new-field-details">
                        {/* Field Name */}
                        <SearchAgvanceField
                            autoFocus
                            openOnFocus={false}
                            errorCodes={errorCodeList}
                            value={this.state.fieldName}
                            onInputChange={(v) => this.updateFieldModel("fieldName", v)}
                            onChange={this.onFieldNameSelection.bind(this)}
                            records={this.getAgvanceFieldList()}
                        />
                        {this.showAdditionalAgvanceCustomer() && (
                            <SelectInput
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                clearFilterInputOnBlur={false}
                                placeholderText={formatMessage(globalMessages.agvanceCustomer)}
                                value={agvanceCustomer}
                                containerClassNames={[
                                    "field-agvance-customer",
                                    {
                                        "form-input-error": errorCodeList.some((item) =>
                                            [869, 870, 871].includes(item)
                                        ),
                                    },
                                ]}
                                onChange={(v) => this.onAgvanceCustomerChange(v)}
                                options={agvanceOrgList.map(
                                    ({
                                        agvanceGrowerName,
                                        customerName,
                                        agvanceCustomerGuid,
                                        orgLevelName,
                                        isPrimary,
                                    }) => ({
                                        label: `${
                                            isPrimary ? customerName : agvanceGrowerName
                                        } > ${orgLevelName}`,
                                        value: agvanceCustomerGuid,
                                    })
                                )}
                                required
                            />
                        )}
                        {/* Farm Name */}
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            clearFilterInputOnBlur={false}
                            options={this.farmListOptions}
                            noOptionsRenderer={null}
                            onInputChange={(v) => this.updateFieldModel("farmName", v)}
                            onChange={(v) => this.updateFieldModel("farmName", v)}
                            placeholderText={formatMessage(messages.fieldFarmNamePlaceholderText)}
                            value={farmName}
                            initialFilterStr={farmName}
                            maxLength={50}
                            allowEmptyOptions
                        />
                    </div>
                    {renderError}
                </div>
            );
        }
    }

    private dispatchCombineField() {
        const {
            agvanceFieldGuid,
            agvanceFieldOrgLevelGuid,
            agvanceCustomerGuid,
            farmName,
            fieldName,
        } = this.state;
        const { selectedCustomerGuid, selectedFieldGuids } = this.props;

        this.props.onCombineFields({
            customerGuid: selectedCustomerGuid,
            fieldGuidList: selectedFieldGuids,
            combinedFieldName: fieldName,
            farmName,
            fieldNotes: "",
            agvanceCustomerGuid,
            agvanceFieldOrgLevelGuid,
            agvanceFieldGuid,
        });
    }

    private dispatchClose() {
        this.props.clearFullFieldRecords();
        this.props.clearCombineFieldsError();
    }

    constructor(props: IProps) {
        super(props);
        const fieldName = "",
            farmName = "",
            agvanceCustomer = "";
        let showModal = false,
            totalAcres = 0;
        if (props.selectedFields && props.selectedFields.length > 0) {
            if (props.onZoomToFields) {
                props.onZoomToFields(props.selectedFieldGuids);
            }

            let farmName = props.selectedFields[0].farmName;
            props.selectedFields.forEach((field) => {
                totalAcres += field.acres;
                farmName = field.farmName ? farmName : "";
            });
            showModal = true;
        }

        if (props.selectedCustomerGuid) {
            this.agvanceInit(props.selectedCustomerGuid);
        }

        this.state = {
            showModal,
            errorCodes: [],
            agvanceCustomer,
            fieldName,
            farmName,
            totalAcres,
            agvanceCustomerGuid: null,
            agvanceFieldOrgLevelGuid: null,
            agvanceFieldGuid: null,
            customerFarms: new Set<string>(),
            agvanceFieldId: null,
        };
        this.farmListOptions = [];
    }

    async componentDidMount() {
        let agvanceCustomer = "";
        if (this.props.agvanceOrgList) {
            if (this.props.agvanceOrgList.length) {
                this.updateFieldForAgvanceCustomers(this.props.agvanceOrgList[0]);
            }
            if (this.showAdditionalAgvanceCustomer(this.props)) {
                agvanceCustomer = this.getAgvanceCustomer(this.props.agvanceOrgList);
            }
        }
        this.setState({ agvanceCustomer });

        const farmNames = await fetchOccupiedFarmNames(
            this.props.selectedCustomerGuid,
            true,
            this.props.userGuid
        );
        this.setState({
            customerFarms: new Set(farmNames),
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.setupLists(nextProps, this.props);
        if (
            nextProps.selectedCustomerGuid &&
            (!this.props.selectedCustomerGuid ||
                this.props.selectedCustomerGuid !== nextProps.selectedCustomerGuid)
        ) {
            this.agvanceInit(nextProps.selectedCustomerGuid);
        }

        if (nextProps.agvanceOrgList !== this.props.agvanceOrgList) {
            if (nextProps.agvanceOrgList.length === 1) {
                this.updateFieldForAgvanceCustomers(nextProps.agvanceOrgList[0]);
            }
            if (!this.state.agvanceCustomer && this.showAdditionalAgvanceCustomer(nextProps)) {
                this.setState({
                    agvanceCustomer: this.getAgvanceCustomer(nextProps.agvanceOrgList),
                });
            }
        }
    }

    componentWillUnmount() {
        if (this.props.agvanceFieldList.length) {
            this.props.clearAgvanceFieldList();
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { selectedFields } = this.props;

        if (!selectedFields || selectedFields.length < 2) {
            return null;
        }

        const details = this.renderDetails();

        return (
            <DialogBox
                isModal={true}
                closeOnEscape={false}
                closeOnClickOff={false}
                hideCloseX={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                unrestricted={true}
                title={formatMessage(messages.combineTitle)}
                className="combine-fields-modal"
                action={formatMessage(messages.combineButton, {
                    count: selectedFields.length,
                })}
                actionDisabled={this.fieldCannotBeSaved()}
                onClose={this.dialogClose.bind(this)}
                onAction={this.dialogAction.bind(this)}
                isOpen={this.state.showModal}
            >
                <div>{formatMessage(messages.combineWarning1)}</div>

                <div className="combine-field-list">
                    {selectedFields.map((value, index) => this.renderField(value, index))}
                </div>

                {details}
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedFields = getFullFieldRecords(state);
    const userGuid = getTheUserGuid(state);
    let selectedCustomerGuid = null;
    let selectedFieldGuids = [];

    if (selectedFields && selectedFields.length > 0) {
        selectedFieldGuids = selectedFields.map((field) => field.fieldGuid);
        selectedCustomerGuid = selectedFields[0].customerGuid;
    }

    return {
        selectedFieldGuids,
        selectedFields,
        selectedCustomerGuid,
        agvanceFieldList: getAgvanceFieldList(state),
        agvanceOrgList: getAgvanceOrgLevelList(state),
        personalityId: getTheUserPersonalityId(state),
        systemAreaLimit: getSystemAreaLimit(state),
        error: getCombineFieldsError(state),
        userGuid,
    };
};
const mapDispatchToProps = (dispatch) => ({
    clearAgvanceFieldList: () => dispatch(fieldActions.clearAgvanceFieldList()),
    clearFullFieldRecords: () => dispatch(fieldActions.clearFullFieldRecords()),
    clearCombineFieldsError: () => dispatch(fieldActions.setCombineFieldsError(null)),
    fetchAgvanceFieldList: (payload) => dispatch(fieldActions.fetchAgvanceFieldList(payload)),
    fetchAgvanceOrgLevelList: (payload) => dispatch(fieldActions.fetchAgvanceOrgLevelList(payload)),
    onCombineFields: (request) => dispatch(fieldActions.combineFields(request)),
    onZoomToFields: (fieldGuidList) => dispatch(mapActions.setZoomToFieldList(fieldGuidList)),
});
export const CombineFieldsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withApiResult(CombineFieldsModal_)));
