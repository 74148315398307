import { MAP_STATE_KEY } from "~/map/selectors";
import { MAP_TOOLS_STATE_KEY } from "~/map/components/map-tools/selectors";

export const ANALYSIS_STATE_KEY = "ANALYSIS_STATE";
export const LAYER_STATE_KEY = "LAYER_STATE_KEY";
export const RECS_EVENTS_DATA_KEY = "RECS_EVENTS_DATA";
export const ZONES_STATE_KEY = "ZONES_STATE";

export const getZonesState = (state) => state[RECS_EVENTS_DATA_KEY][ZONES_STATE_KEY];
export const getMapToolsState = (state) => state[MAP_STATE_KEY][MAP_TOOLS_STATE_KEY];
export const getChangedLayerItems = (state) =>
    state[RECS_EVENTS_DATA_KEY][LAYER_STATE_KEY].layersToUpdate;

export const isInBatchWorkflow = (state) => {
    const fieldGuidToCurrentAreaId = getZonesState(state).fieldGuidToCurrentAreaId;
    return fieldGuidToCurrentAreaId != null && fieldGuidToCurrentAreaId.size > 0;
};

const hasEmptyString = (details, prop) =>
    details != null && Object.hasOwn(details, prop) && details[prop] === "";

const isNewSingleAnalysisLayer = (details) =>
    details != null &&
    Object.hasOwn(details, "layers") &&
    details.layers.length === 1 &&
    details.layers[0].analysisLayerGuid === "";

/** Determines if the current map tools are targeting a *new* event or rec.
 * @param {object} state - The current application state.
 * @returns {boolean}
 */
export const getIsNewEventRec = (state) => {
    const { recEventDetails } = getMapToolsState(state).toolsetPayload;
    return (
        hasEmptyString(recEventDetails, "agEventGeneralGuid") ||
        hasEmptyString(recEventDetails, "recGeneralGuid") ||
        isNewSingleAnalysisLayer(recEventDetails)
    );
};
