import {
    CallEffect,
    PutEffect,
    all,
    call,
    fork,
    put,
    select,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import _ from "lodash";
import { OrderedMap } from "immutable";
import { IntlProvider } from "react-intl";
import { v4 as uuid } from "uuid";
import moment from "moment";

import { selectors as cdSelectors, models as cdModels } from "~/customer-data";
import { getTheUserGuid, getTheUserLockCustomer } from "~/login/selectors";
import { mapActions, mapToolsActions } from "~/map";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { MAX_FETCH_FIELDS } from "~/recs-events/model";
import * as recsEventsActions from "~/recs-events/actions";
import * as recsEventsSelectors from "~/recs-events/selectors";
import * as intlSelectors from "~/intl-provider/selectors";
import { actions as messagingActions } from "~/messaging";

import {
    AppHelpers,
    EventAPI,
    FieldAPI,
    LayerAPI,
    RecAPI,
    AgEventAPI,
    SearchAPI,
} from "@ai360/core";
import { ClassBreak, Toolset, GeometryUtils, ZoneUtils } from "@ai360/core";

import * as importWizardActions from "~/action-panel/components/import-module/components/import-wizard/actions";

import * as analysisInfoSelectors from "~/action-panel/components/layer-module/components/analysis-info/selectors";
import * as analysisInfoActions from "~/action-panel/components/layer-module/components/analysis-info/actions";
import * as analysisSelectors from "./analysis/selectors";
import * as analysisActions from "./analysis/actions";

import { configureEventFilters } from "~/action-panel/components/event-module/components/event-list/actions";
import * as eventsActions from "./events/actions";
import * as eventsModels from "./events/model";
import * as eventsSelectors from "./events/selectors";

import * as recInfoActions from "~/action-panel/components/rec-module/components/rec-info/actions";
import { configureRecFilters } from "~/action-panel/components/rec-module/components/rec-list/actions";
import * as recsActions from "./recs/actions";
import * as recsModels from "./recs/model";
import * as recsSelectors from "./recs/selectors";

import { eventsSaga } from "./events/sagas";
import { recsSaga } from "./recs/sagas";

import * as actions from "./actions";
import { messages } from "./i18n-messages";
import {
    BATCH_TEMPLATE_FIELD_GUID,
    IChangedMessage,
    IEventChangedMessage,
    IRecChangedMessage,
} from "./model";
import { getZonesState } from "./selectors";
import { StatusCodes } from "~/action-panel/components/common/status-messages";
import { setTriggeredPage } from "~/action-panel/components/rec-module/actions";
import { getActiveNutrientGuid } from "~/action-panel/components/rec-module/components/rec-info/selectors";

import { messages as statusMessages } from "~/action-panel/components/common/status-messages";

import * as eventListActions from "~/action-panel/components/event-module/components/event-list/actions";
import * as recListActions from "~/action-panel/components/rec-module/components/rec-list/actions";
import { errorCodeMessages } from "~/i18n-error-messages";
import { fetchFieldsWithSummaries, IField, ISummary as IApiSummary } from "~/utils/api/summary";
import { IActionData, IPayloadData } from "./interfaces";

const convertRecsToEvents = function* (action: Record<string, any>): any {
    const { recGeneralGuidToFieldGuidMap } = action.payload;
    yield put(recListActions.setRecPanelLoading(true));
    for (const [recGeneralGuid, fieldGuid] of recGeneralGuidToFieldGuidMap) {
        try {
            const agEventGeneralGuid = uuid();
            yield call(EventAPI.mergeEventFromRec, agEventGeneralGuid, recGeneralGuid);
            yield put(actions.convertRecToEventSucceeded());
            yield put(actions.refreshFilterOptions());
            yield put(eventListActions.updateExpandedFieldGuidSet(fieldGuid, true));
        } catch (err) {
            // If we have received a known validation error, then display the validation message directly
            // in a Toaster message, otherwise fall back to previous api error display.
            if (typeof err.apiResultObj !== "undefined" && err.apiResultObj?.errors?.length > 0) {
                for (const error of err.apiResultObj.errors) {
                    const message = errorCodeMessages[error.code];
                    if (message !== undefined) {
                        yield put(
                            notificationActions.pushToasterMessage(
                                message,
                                MSGTYPE.ERROR,
                                undefined,
                                false
                            )
                        );
                    } else {
                        yield put(notificationActions.apiCallError(err, action));
                    }
                }
            } else {
                yield put(notificationActions.apiCallError(err, action));
            }
        }
    }
    yield put(recListActions.clearConvertRecsToEventsGuidMap());
    yield put(recListActions.setRecPanelLoading(false));
};

const isAnalysisAreaAction = function* (fieldGuid) {
    const { fieldGuidToAnalysisSummaryMap } = yield select(analysisSelectors.getModuleState);
    return fieldGuidToAnalysisSummaryMap.has(fieldGuid);
};

const isEventAreaAction = function* (fieldGuid) {
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    return fieldGuidToEventDetails.has(fieldGuid);
};

const getAvailableFieldGuidSet = function* (selectedFieldGuidSet) {
    const lockCustomersNotEnrolled = yield select(getTheUserLockCustomer);
    const enrolledFieldGuids = yield select(cdSelectors.getEnrolledFieldGuids);
    return lockCustomersNotEnrolled
        ? selectedFieldGuidSet.filter((fieldGuid) => enrolledFieldGuids.has(fieldGuid))
        : selectedFieldGuidSet;
};

const getRevisedMinMax = (originalRecNutrient, newRecNutrient, minimumIncludeZeros) => {
    const originalRate = originalRecNutrient.recNutrientProductMix.targetRate || 0;
    const newRate = newRecNutrient.recNutrientProductMix.targetRate || 0;
    const targetRateUpdated = Boolean(
        originalRate !== 0 && newRate !== 0 && Math.round(originalRate) !== Math.round(newRate)
    );

    const revisedMinRate =
        originalRecNutrient.recNutrientParameters.minimumLock &&
        originalRecNutrient.recNutrientParameters.minimumRate != null
            ? originalRecNutrient.recNutrientParameters.minimumRate
            : minimumIncludeZeros ||
              Number(newRecNutrient.recNutrientParameters.percentAdjustment) / 100 !== 1 ||
              targetRateUpdated
            ? newRecNutrient.averageAdjustedRecNutrientResult.minRate
            : originalRecNutrient.recNutrientParameters.minimumRate
            ? originalRecNutrient.recNutrientParameters.minimumRate
            : newRecNutrient.averageRecNutrientResult.minRate;
    const revisedMaxRate =
        originalRecNutrient.recNutrientParameters.maximumLock &&
        originalRecNutrient.recNutrientParameters.maximumRate != null
            ? originalRecNutrient.recNutrientParameters.maximumRate
            : minimumIncludeZeros ||
              Number(newRecNutrient.recNutrientParameters.percentAdjustment) / 100 !== 1 ||
              targetRateUpdated
            ? newRecNutrient.averageAdjustedRecNutrientResult.maxRate
            : originalRecNutrient.recNutrientParameters.maximumRate
            ? originalRecNutrient.recNutrientParameters.maximumRate
            : newRecNutrient.averageRecNutrientResult.maxRate;
    return { revisedMinRate, revisedMaxRate };
};

export const getAreaListFromSurface = function* (
    // action: ActionType<typeof actions>
    action: Record<string, any>
): Generator<CallEffect | PutEffect<any>, any, any> {
    const { field, surfaceInfo } = action.payload;

    const FIELDS = LayerAPI.mappedSurfacePolygonFields;
    const { classBreaks, surfaceRendererGuid } = surfaceInfo;

    const classIdToClassBreakMap = new Map(
        classBreaks.map((cb) => {
            const rv = new ClassBreak();
            rv.acreage = cb.acreage;
            rv.classId = cb.classId?.toString();
            rv.heading = surfaceInfo.displayName;
            rv.label = cb.displayName;
            rv.lowerBound = cb.lowerBound;
            rv.upperBound = cb.upperBound;
            rv.red = cb.color.r;
            rv.green = cb.color.g;
            rv.blue = cb.color.b;
            return [rv.classId, rv];
        })
    );

    let surfacePolygonsResult;
    try {
        surfacePolygonsResult = yield call(LayerAPI.fetchSurfacePolygons, surfaceRendererGuid);
        if (surfacePolygonsResult.length === 0) {
            throw new Error("Query returned 0 features");
        }
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.fetchSurfacePolygonsFailed)
        );
        return;
    }

    const classIdToGeometryMap = new Map();
    for (const feature of surfacePolygonsResult.map(LayerAPI.surfacePolygonToGraphic)) {
        const classId = feature.attributes[FIELDS.class]?.toString();
        if (!classIdToGeometryMap.has(classId)) {
            classIdToGeometryMap.set(classId, feature.geometry);
        } else {
            const prevGeom = classIdToGeometryMap.get(classId);
            classIdToGeometryMap.set(
                classId,
                GeometryUtils.getCombinedBoundaries([prevGeom, feature.geometry])
            );
        }
    }

    if (Number(surfaceInfo.numberOfClasses) !== classIdToClassBreakMap.size) {
        yield put(
            notificationActions.pushToasterMessage(
                messages.unexpectedSurfaceClassCount,
                MSGTYPE.WARNING,
                {
                    classBreakCount: classIdToClassBreakMap.size,
                    classCount: surfaceInfo.numberOfClasses,
                },
                false
            )
        );
    }

    return yield call(
        ZoneUtils.processResidualAreaZoneForSurface,
        field.fieldGuid,
        classIdToGeometryMap,
        classIdToClassBreakMap
    );
};

export const getFieldBoundaryPolygons = function* (
    fieldGuidList: string[],
    getAreaIdFromFieldGuid: (guid: string) => void
): any {
    const fieldGuidToAreaUpdateMap = new Map();
    const boundaries = yield call(
        FieldAPI.fetchFieldBoundariesByFieldGuidPerformant,
        fieldGuidList,
        null,
        null,
        true
    );

    const fieldGuidToFeatureListMap = new Map();
    boundaries.forEach((boundary) => {
        const fieldGuid = boundary[FieldAPI.mappedFieldBoundaryFields.fieldGuid];
        if (!fieldGuidToFeatureListMap.has(fieldGuid)) {
            fieldGuidToFeatureListMap.set(fieldGuid, []);
        }
        const featureList = fieldGuidToFeatureListMap.get(fieldGuid);
        featureList.push(boundary.shape);
    });

    for (const [fieldGuid, geometryList] of fieldGuidToFeatureListMap.entries()) {
        const normalizedFieldGuid = AppHelpers.normalizeGuid(fieldGuid);
        const areaId = getAreaIdFromFieldGuid(normalizedFieldGuid);
        const shape = GeometryUtils.getCombinedBoundaries(geometryList);
        const areaIdToNewAreaIdPolygonMap = new Map([[areaId, new Map([[areaId, { shape }]])]]);
        fieldGuidToAreaUpdateMap.set(normalizedFieldGuid, areaIdToNewAreaIdPolygonMap);
    }

    return fieldGuidToAreaUpdateMap;
};

export const onCopyAreas = function* (action: Record<string, any>): any {
    const { fieldGuid, modelTypeGuid } = action.payload;

    // Get current area
    const { copyZonesFromToMap } = yield select(getZonesState);
    if (copyZonesFromToMap.size === 0) {
        return;
    }
    if (yield isAnalysisAreaAction(fieldGuid)) {
        console.warn("No copying between analysis layers");
        return;
    }
    console.assert(copyZonesFromToMap.size === 1);
    const [fromAreaId, toAreaIdList] = copyZonesFromToMap.entries().next().value;

    if (yield isEventAreaAction(fieldGuid)) {
        const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        const agEventArea = eventDetails.eventAreaList.find(
            (agEventArea) => agEventArea.eventAreaId === fromAreaId
        );
        const fromAgEvent = agEventArea.agEventList.find(
            (agEvent) => agEvent.agEventTransactionTypeGuid === modelTypeGuid
        );
        for (const toAreaId of toAreaIdList) {
            yield put(
                eventsActions.updateAgEventModel(
                    fieldGuid,
                    toAreaId,
                    modelTypeGuid,
                    fromAgEvent.agEventModel.updateAgEventModel({
                        agEventGuid: "",
                        productMixList: fromAgEvent.agEventModel.productMixList?.map((x) => ({
                            ...x,
                            productMixGuid: "",
                            products: x.products.map((x) => ({
                                ...x,
                                productMixProductGuid: "",
                            })),
                            nutrients: x.nutrients.map((x) => ({
                                ...x,
                                productMixNutrientGuid: "",
                            })),
                        })),
                    })
                )
            );
        }
        yield put(eventsActions.initializeEventsZonesSucceeded());
    } else {
        const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
        const recDetails = fieldGuidToRecDetails.get(fieldGuid);
        const recArea = recDetails.recAreaList.find((recArea) => recArea.recAreaId === fromAreaId);
        const fromRec = recArea.recs[0];
        for (const toAreaId of toAreaIdList) {
            const toRecArea = recDetails.recAreaList.find(
                (recArea) => recArea.recAreaId === toAreaId
            );
            const { recGuid, recAreaGuid } = toRecArea.recs[0];
            const updatedRec = fromRec.updateRecModel({
                recGuid,
                recAreaGuid,
                // only properties for manual recs should need to be populated when copying
                productMixList: fromRec.productMixList.map((x) => ({
                    ...x,
                    productMixGuid: "",
                    products: x.products.map((x) => ({
                        ...x,
                        productMixProductGuid: "",
                    })),
                    nutrients: x.nutrients.map((x) => ({
                        ...x,
                        productMixNutrientGuid: "",
                    })),
                })),
                recPlanting: {
                    ...fromRec.recPlanting,
                    recGuid,
                    recPlantingVarietyHybridList: fromRec.recPlanting
                        ? fromRec.recPlanting.recPlantingVarietyHybridList.map((x) => ({
                              ...x,
                              recPlantingVarietyHybridGuid: "",
                              recGuid,
                          }))
                        : [],
                },
            });
            yield put(recsActions.updateRecModel(fieldGuid, toAreaId, true, updatedRec));
        }
        yield put(recsActions.initializeRecsZonesSucceeded());
    }

    yield put(actions.setCopyFromAreaId(null));
};

export const processRecSummaryChanged = function* (action: Record<string, any>): any {
    const { changes } = action.payload;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const userGuid = yield select(getTheUserGuid);
    const recDetailsUpdates = [];
    let lastRecData = null;
    let isEquation = false;
    let lastRecDetails = null;
    let resetMapLoading = false;
    for (const change of changes) {
        const { recData } = change;
        if (
            recData &&
            fieldGuidToRecDetails.size !== 0 &&
            fieldGuidToRecDetails.has(recData.fieldGuid)
        ) {
            isEquation =
                recData.recType === recsModels.REC_TYPE_NAME_EQUATION_APPLICATION ||
                recData.recType === recsModels.REC_TYPE_NAME_EQUATION_PLANTING;
            const rec = fieldGuidToRecDetails.get(recData.fieldGuid);
            const recDate = rec.momentCreatedDate.format("MM/DD/YYYY");
            const recDataDate = moment(recData.recDate).format("MM/DD/YYYY");
            const isRunning = rec.recAreaList.some((recArea) =>
                recArea.recs.some((rec) => rec.isAnyEquationRun)
            );
            const fieldRecMatch =
                recData.activeYn &&
                (recData.recGeneralGuid === rec.recGeneralGuid ||
                    (!rec.recGeneralGuid &&
                        isRunning &&
                        recData.recName === rec.name &&
                        recDate === recDataDate));
            if (fieldRecMatch && rec.importedStatus !== 999) {
                lastRecData = recData;
                if (recData.importedStatus === 8 && rec.importedStatus === 6 && isRunning) {
                    const newProps = {
                        recAreaList: rec.recAreaList.map((recArea) => {
                            return recsModels.RecArea.updateRecArea(recArea, {
                                recs: recArea.recs.map((rec) => {
                                    return rec.recGuid === recData.recGeneralGuid
                                        ? rec.recsModels.Rec.updateRec(rec, {
                                              recStatus: recData.recStatuses,
                                              importedStatus: 8,
                                          })
                                        : rec;
                                }),
                            });
                        }),
                        importedStatus: recData.importedStatus,
                        recGeneralGuid: recData.recGeneralGuid,
                    };
                    lastRecDetails = recsModels.RecDetails.updateRecDetails(rec, { ...newProps });
                    recDetailsUpdates.push({
                        fieldGuid: rec.fieldGuid,
                        newProps: { ...newProps },
                    });
                } else if (recData.importedStatus === 8 && rec.importedStatus !== 8 && isRunning) {
                    const recInfo = yield call(RecAPI.getRec, userGuid, recData.recGeneralGuid);
                    const newRecDetails = mergeRecInfo(
                        { ...recInfo, importedStatus: recInfo.importedStatus },
                        rec
                    );

                    lastRecDetails = recsModels.RecDetails.fromJsonObj(newRecDetails);
                    recDetailsUpdates.push({
                        fieldGuid: rec.fieldGuid,
                        newProps: lastRecDetails,
                    });
                } else if (recData.importedStatus === 6 && rec.importedStatus !== 6 && isRunning) {
                    const newProps = {
                        recAreaList: rec.recAreaList.map((recArea) => {
                            return recsModels.RecArea.updateRecArea(recArea, {
                                recs: recArea.recs.map((rec) => {
                                    return recsModels.Rec.updateRec(rec, {
                                        recStatus: recData.recStatuses,
                                        importedStatus: 6,
                                    });
                                }),
                            });
                        }),
                        importedStatus: recData.importedStatus,
                        recGeneralGuid: recData.recGeneralGuid,
                    };
                    lastRecDetails = recsModels.RecDetails.updateRecDetails(rec, { ...newProps });
                    recDetailsUpdates.push({
                        fieldGuid: rec.fieldGuid,
                        newProps: { ...newProps },
                    });
                    const activeNutrientGuid = yield select(getActiveNutrientGuid);
                    const firstNutrient = rec.recAreaList[0].recs[0].recNutrientList.find(
                        (recNutrient) => recNutrient.equationSuccess
                    );
                    if (fieldGuidToRecDetails.size === 1 && firstNutrient && !activeNutrientGuid) {
                        const firstNutrientGuid = firstNutrient.nutrientGuid;
                        yield put(
                            recInfoActions.setActiveNutrient(rec.fieldGuid, firstNutrientGuid)
                        );
                    }
                    resetMapLoading = true;
                } else if (recData.importedStatus === 31) {
                    const recInfo = yield call(RecAPI.getRec, userGuid, recData.recGeneralGuid);
                    const newRecDetails = mergeRecInfo(
                        { ...recInfo, importedStatus: recData.importedStatus },
                        rec
                    );
                    lastRecDetails = recsModels.RecDetails.fromJsonObj(newRecDetails);
                    recDetailsUpdates.push({
                        fieldGuid: rec.fieldGuid,
                        newProps: lastRecDetails,
                    });
                } else {
                    const newProps = {
                        recAreaList: rec.recAreaList.map((recArea) => {
                            return recsModels.RecArea.updateRecArea(recArea, {
                                recs: recArea.recs.map((rec) => {
                                    return recsModels.Rec.updateRec(rec, {
                                        recStatus: recData.recStatuses,
                                        importedStatus: recData.importedStatus,
                                    });
                                }),
                            });
                        }),
                        importedStatus: recData.importedStatus,
                        recGeneralGuid: recData.recGeneralGuid,
                    };
                    lastRecDetails = recsModels.RecDetails.updateRecDetails(rec, { ...newProps });
                    recDetailsUpdates.push({
                        fieldGuid: rec.fieldGuid,
                        newProps: { ...newProps },
                    });
                }
            }
        }
    }
    if (resetMapLoading) {
        yield put(mapActions.setIsLoading(false));
    }

    yield put(actions.batchUpdateRecDetails(recDetailsUpdates));
    const { batchFieldGuid } = yield select(recsEventsSelectors.getZonesState);
    if (batchFieldGuid == null) {
        if (lastRecData != null) {
            const fields: SearchAPI.IFieldResult[] = yield call(SearchAPI.getFields, {
                userGuid,
                fieldGuid: [lastRecData.fieldGuid],
            });
            const newLastRecData =
                fields.length > 0
                    ? {
                          ...lastRecData,
                          fieldName: fields[0].name,
                          farmName: fields[0].farmName,
                          fieldAcres: fields[0].acres,
                          fieldBoundaryGuid: fields[0].boundaryId,
                          customerGuid: fields[0].customerId,
                          customerName: fields[0].customerName,
                          customerEnrolled: fields[0].customerEnrolled,
                      }
                    : lastRecData;
            yield put(recInfoActions.setRecSummary(newLastRecData));
            yield put(actions.saveRecDetailsSucceeded(isEquation));
        }
        if (lastRecDetails) {
            const field = {
                fieldGuid: lastRecDetails.fieldGuid,
                fieldBoundaryGuid: lastRecDetails.fieldBoundaryGuid,
                customerGuid: lastRecDetails.customerGuid,
            };
            yield put(
                mapToolsActions.setActiveToolsetPayloadOnly({
                    recEventDetails: lastRecDetails,
                    field,
                })
            );
        }
    }
};

export const processRecStatusChanged = function* (action: Record<string, any>): any {
    const { fieldGuidToRecGeneralGuids, recStatus } = action.payload;
    const locale = yield select(intlSelectors.getLocale);
    const { intl } = new IntlProvider({ locale, messages: statusMessages }, {}).getChildContext();
    const recStatusStr = intl.formatMessage(statusMessages.importStatus, {
        statusCode: recStatus,
    });
    for (const [fieldGuid, recGeneralGuids] of fieldGuidToRecGeneralGuids) {
        for (const recGeneralGuid of recGeneralGuids) {
            yield put(
                recsActions.updateRecSummaryImportedStatus(fieldGuid, recGeneralGuid, recStatus)
            );
            yield put(recsActions.updateRecSummaryStatus(fieldGuid, recGeneralGuid, recStatusStr));
        }
    }
};

export const onRefreshFilterOptions = function* (): any {
    const userGuid = yield select(getTheUserGuid);
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const availableFieldSet = yield call(getAvailableFieldGuidSet, selectedFieldGuidSet);
    const filters = yield call(
        LayerAPI.getLayerFilterOptions,
        Array.from(availableFieldSet.values()),
        userGuid
    );
    yield put(configureEventFilters(filters));
    yield put(configureRecFilters(filters));
};

interface IFetchImplementation<TApiSummary extends IApiSummary, TSummary> {
    apiCall: (fieldGuids: string[]) => Promise<TApiSummary[]>;
    parseField: (field: IField<TApiSummary>) => TSummary[];
    withParsedFields?: (activeMap: OrderedMap<string, TSummary[]>) => any;
    successAction: (
        active: OrderedMap<string, TSummary[]>,
        inactive: OrderedMap<string, TSummary[]>,
        activeField: OrderedMap<string, SearchAPI.IFieldResult>,
        inactiveField: OrderedMap<string, SearchAPI.IFieldResult>
    ) => any;
}

const fetchImplementation = function* <TApiSummary extends IApiSummary, TSummary>(
    implementation: IFetchImplementation<TApiSummary, TSummary>,
    action: any
) {
    const userGuid = yield select(getTheUserGuid);
    const selectedFieldGuids = yield select(cdSelectors.getSelectedFieldGuids);
    const fieldGuids = [...new Set<string>(selectedFieldGuids.slice(0, MAX_FETCH_FIELDS))];

    let fieldsWithSummaries: IField<TApiSummary>[] = [];
    try {
        fieldsWithSummaries = yield fetchFieldsWithSummaries(
            fieldGuids,
            userGuid,
            implementation.apiCall
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    fieldsWithSummaries.forEach((x) => x.id !== "");
    const { activeMap, inactiveMap, activeFieldMap, inactiveFieldMap } = fieldsWithSummaries
        .sort(cdModels.compareCustomersAndFields)
        .reduce(
            (accumulator, field) => {
                const activeSummaries = implementation.parseField({
                    ...field,
                    summaries: field.summaries.filter((x) => x.activeYn),
                });

                const inactiveSummaries = implementation.parseField({
                    ...field,
                    summaries: field.summaries.filter((x) => !x.activeYn),
                });

                return {
                    activeMap: accumulator.activeMap.set(field.id, activeSummaries),
                    inactiveMap: accumulator.inactiveMap.set(field.id, inactiveSummaries),
                    activeFieldMap: field.activeYn
                        ? accumulator.activeFieldMap.set(field.id, field)
                        : accumulator.activeFieldMap,
                    inactiveFieldMap: !field.activeYn
                        ? accumulator.inactiveFieldMap.set(field.id, field)
                        : accumulator.inactiveFieldMap,
                };
            },
            {
                activeMap: OrderedMap<string, TSummary[]>(),
                inactiveMap: OrderedMap<string, TSummary[]>(),
                activeFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
                inactiveFieldMap: OrderedMap<string, SearchAPI.IFieldResult>(),
            }
        );

    if (implementation.withParsedFields != null) {
        yield implementation.withParsedFields(activeMap);
    }

    yield put(
        implementation.successAction(activeMap, inactiveMap, activeFieldMap, inactiveFieldMap)
    );
};

export const onFetchSelectedFieldEventsOrRecs = function* (action: Record<string, any>): any {
    const isFetchRecs = action.type === actions.FETCH_RECS;

    if (isFetchRecs) {
        yield fetchImplementation(
            {
                apiCall: RecAPI.fetchSummaries,
                parseField: (field: IField<RecAPI.IRecSummaryRec>): recsModels.RecSummary[] =>
                    field.summaries
                        .map((summary) => recsModels.RecSummary.fromJsonObj(summary, field))
                        .map((summary) =>
                            summary.importedStatus === StatusCodes.WaitingForControllerProcess
                                ? summary.updateImportedStatus(StatusCodes.WaitingToProcess)
                                : summary
                        ),
                withParsedFields: null,
                successAction: actions.fetchSelectedFieldRecsSucceeded,
            },
            action
        );
    } else {
        const withParsedFields = function* (
            activeMap: OrderedMap<string, eventsModels.AgEventSummary[]>
        ) {
            for (const field of activeMap) {
                const { fieldGuidToEventListMap } = yield select(eventsSelectors.getModuleState);
                const latestUpdatedEvents = [];
                const selectedFieldEventsSummary = fieldGuidToEventListMap.get(field[0]);
                selectedFieldEventsSummary &&
                    selectedFieldEventsSummary.forEach((eventSummary) => {
                        const parsedEventSummary = field[1].find(
                            (parsed) =>
                                parsed.agEventGeneralGuid === eventSummary.agEventGeneralGuid
                        );
                        if (!parsedEventSummary) {
                            return;
                        }
                        if (!_.isEqual(eventSummary, parsedEventSummary)) {
                            latestUpdatedEvents.push(parsedEventSummary);
                        }
                    });
                if (latestUpdatedEvents.length) {
                    yield put(eventsActions.addLatestUpdatedEvent(latestUpdatedEvents));
                }
            }
        };

        yield fetchImplementation(
            {
                apiCall: AgEventAPI.fetchSummaries,
                parseField: (
                    field: IField<AgEventAPI.IAgEventSummary>
                ): eventsModels.AgEventSummary[] =>
                    field.summaries.map((summary) =>
                        eventsModels.AgEventSummary.fromJsonObj(
                            {
                                status: null,
                                ...summary,
                            },
                            field
                        )
                    ),
                withParsedFields,
                successAction: actions.fetchSelectedFieldEventsSucceeded,
            },
            action
        );
    }
};

export const mergeRecInfo = function (
    recDetails: Record<string, any>,
    recGeneral: Record<string, any>
): any {
    return {
        ...recGeneral,
        ...recDetails,
        eventSelectionList: recGeneral.eventSelectionList, // This doesn't change from anything in the run, keep what was already filled out at the beginning
        recAreaList: recGeneral.recAreaList.map((recArea) => {
            const newRecArea = recDetails.recAreaList.find(
                (newRecArea) => newRecArea.recAreaId === recArea.recAreaId
            );
            const firstRecArea = recDetails.recAreaList.find((fRecArea) => fRecArea.recAreaId);
            return !newRecArea
                ? {
                      ...recArea,
                      recs: recArea.recs.map((rec) => {
                          const newRec = firstRecArea.recs[0];
                          return !newRec
                              ? rec
                              : rec.updateRecModel({
                                    ...rec,
                                    recStatus: newRec.recStatus,
                                });
                      }),
                  }
                : {
                      ...recArea,
                      ...newRecArea,
                      zonePolygons: recArea.zonePolygons
                          ? recArea.zonePolygons
                          : newRecArea.zonePolygons,
                      recs: recArea.recs.map((rec) => {
                          const newRec = newRecArea.recs[0];
                          return !newRec
                              ? rec
                              : rec.updateRecModel({
                                    ...rec,
                                    ...newRec,
                                    equationFilters: {
                                        ...rec.equationFilters,
                                        recEquationFilterGuid: newRec.equationFilters
                                            ? newRec.equationFilters.recEquationFilterGuid
                                            : rec.equationFilters.recEquationFilterGuid,
                                    },
                                    isEquationRun:
                                        newRec.recNutrientList.some(
                                            (recNutrient) => recNutrient.equationSuccess
                                        ) ||
                                        (newRec.recPlanting.recPlantingVarietyHybridList.length >
                                            0 &&
                                            newRec.recPlanting.recPlantingVarietyHybridList.some(
                                                (rv) => rv.equationSuccess
                                            )),
                                    isAnyRecAdjustmentProcessing: false,
                                    isRecDirty: false,
                                    equationParameters: rec.equationParameters.map(
                                        (equationParameter) => {
                                            const newEquationParameter =
                                                newRec.equationParameters.find((newEqParameter) =>
                                                    newEqParameter.equationParameterGuid !== null
                                                        ? newEqParameter.equationParameterGuid ===
                                                          equationParameter.equationParameterGuid
                                                        : newEqParameter.name ===
                                                          equationParameter.name
                                                );
                                            return {
                                                ...equationParameter,
                                                ...newEquationParameter,
                                                value: equationParameter.value,
                                            };
                                        }
                                    ),
                                    recPlanting: {
                                        ...rec.recPlanting,
                                        ...newRec.recPlanting,
                                        recPlantingVarietyHybridList:
                                            rec.recPlanting.recPlantingVarietyHybridList.length > 0
                                                ? rec.recPlanting.recPlantingVarietyHybridList.map(
                                                      (recVariety, index) => {
                                                          return {
                                                              ...recVariety,
                                                              ...newRec.recPlanting
                                                                  .recPlantingVarietyHybridList[
                                                                  index
                                                              ],
                                                          };
                                                      }
                                                  )
                                                : newRec.recPlanting.recPlantingVarietyHybridList,
                                    },
                                    recNutrientList: rec.recNutrientList.map((recNutrient) => {
                                        const newRecNutrient = newRec.recNutrientList.find(
                                            (newRecNutrient) =>
                                                newRecNutrient.nutrientGuid ===
                                                recNutrient.nutrientGuid
                                        );
                                        if (!newRecNutrient) {
                                            return recNutrient;
                                        }
                                        const minimumIncludeZeros =
                                            newRecNutrient.recNutrientParameters
                                                .minimumIncludeZeros;

                                        const fieldRate =
                                            newRecNutrient.averageAdjustedRecNutrientResult
                                                .productRate ||
                                            newRecNutrient.averageRecNutrientResult.productRate;
                                        const targetRate =
                                            +newRecNutrient.averageAdjustedRecNutrientResult
                                                .productRate === 0
                                                ? 0
                                                : fieldRate ||
                                                  (!newRecNutrient.recNutrientProductMix
                                                      ? null
                                                      : newRecNutrient.recNutrientProductMix
                                                            .targetRate);

                                        const targetRateRatio =
                                            Number(targetRate) /
                                            recNutrient.recNutrientProductMix.targetRate;
                                        const { revisedMinRate, revisedMaxRate } = getRevisedMinMax(
                                            recNutrient,
                                            newRecNutrient,
                                            minimumIncludeZeros
                                        );

                                        return {
                                            ...recNutrient,
                                            ...newRecNutrient,
                                            creditedGridCells: !newRecNutrient.creditedGridCells
                                                ? recNutrient.creditedGridCells
                                                : newRecNutrient.creditedGridCells,
                                            recNutrientProductMix:
                                                !newRecNutrient.recNutrientProductMix
                                                    ? new recsModels.RecNutrientProductMix()
                                                    : {
                                                          ...recNutrient.recNutrientProductMix,
                                                          ...newRecNutrient.recNutrientProductMix,
                                                          guarateedAnalysis:
                                                              recNutrient.recNutrientProductMix
                                                                  .guarateedAnalysis,
                                                          products:
                                                              recNutrient.recNutrientProductMix.products.map(
                                                                  (product) => {
                                                                      const newProduct =
                                                                          newRecNutrient.recNutrientProductMix.products.find(
                                                                              (newProduct) =>
                                                                                  (newProduct.productGuid &&
                                                                                      newProduct.productGuid ===
                                                                                          product.productGuid) ||
                                                                                  (newProduct.customProductGuid &&
                                                                                      newProduct.customProductGuid ===
                                                                                          product.customProductGuid)
                                                                          );
                                                                      const isServiceProduct =
                                                                          product.productParentType ===
                                                                          "Service";
                                                                      const isAreaOrEach =
                                                                          isServiceProduct &&
                                                                          (product.costUnit ===
                                                                              "ac" ||
                                                                              product.costUnit ===
                                                                                  "ea");
                                                                      const adjustmentRatio =
                                                                          isServiceProduct &&
                                                                          isAreaOrEach
                                                                              ? 1
                                                                              : targetRateRatio;

                                                                      return {
                                                                          ...product,
                                                                          ...newProduct,
                                                                          rate:
                                                                              newRecNutrient
                                                                                  .recNutrientProductMix
                                                                                  .products
                                                                                  .length === 1
                                                                                  ? targetRate
                                                                                  : product.rate *
                                                                                    adjustmentRatio,
                                                                          cost: product.cost,
                                                                          costUnitGuid:
                                                                              product.costUnitGuid,
                                                                          costUnit:
                                                                              product.costUnit,
                                                                      };
                                                                  }
                                                              ),
                                                          targetRate: Number(targetRate),
                                                          targetRateUnit:
                                                              recNutrient.recNutrientProductMix
                                                                  .targetRateUnit,
                                                          targetRateUnitGuid:
                                                              recNutrient.recNutrientProductMix
                                                                  .targetRateUnitGuid,
                                                      },
                                            recNutrientParameters: {
                                                ...recNutrient.recNutrientParameters,
                                                ...newRecNutrient.recNutrientParameters,
                                                minimumRate: revisedMinRate,
                                                maximumRate: revisedMaxRate,
                                            },
                                        };
                                    }),
                                });
                      }),
                  };
        }),
    };
};

export const onProcessCancelRecs = function* (): any {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const userGuid = yield select(getTheUserGuid);

    for (const recDetails of fieldGuidToRecDetails.values()) {
        if (recDetails.recGeneralGuid && recDetails.recGeneralGuid !== "BATCH_REC_GENERAL_GUID") {
            const newProps = {
                recAreaList: recDetails.recAreaList.map((recArea) => {
                    return recsModels.RecArea.updateRecArea(recArea, {
                        recs: recArea.recs.map((rec) => {
                            return recsModels.Rec.updateRec(rec, {
                                recStatus: "Cancelled",
                                importedStatus: 999,
                            });
                        }),
                    });
                }),
                importedStatus: 999,
            };
            yield put(
                actions.updateRecSummaryImportedStatus(
                    recDetails.fieldGuid,
                    recDetails.recGeneralGuid,
                    999
                )
            );
            yield put(actions.updateRecDetails(recDetails.fieldGuid, { ...newProps }));
            yield fork(RecAPI.cancelRec, userGuid, recDetails.recGeneralGuid);
        }
    }
    yield put(recsEventsActions.clearRecDetails());
};

export const onResetAreaPolygons = function* (action: Record<string, any>): any {
    const { fieldGuid, areaIdToPolygonMap } = action.payload;
    if (yield isEventAreaAction(fieldGuid)) {
        yield put(eventsActions.resetEventAreaPolygons(fieldGuid, areaIdToPolygonMap));
    } else if (yield isAnalysisAreaAction(fieldGuid)) {
        yield put(analysisActions.resetAnalysisAreaPolygons(fieldGuid, areaIdToPolygonMap));
    } else {
        yield put(recsActions.resetRecAreaPolygons(fieldGuid, areaIdToPolygonMap));
    }
};

export const onResetClassifiedAreaPolygons = function* (action: Record<string, any>): any {
    const { fieldGuid, areaIdToPolygonMap, areaIdToClassBreaksMap } = action.payload;
    if (yield isEventAreaAction(fieldGuid)) {
        yield put(
            eventsActions.resetClassifiedEventAreaPolygons(
                fieldGuid,
                areaIdToPolygonMap,
                areaIdToClassBreaksMap
            )
        );
    } else {
        yield put(
            recsActions.resetClassifiedRecAreaPolygons(
                fieldGuid,
                areaIdToPolygonMap,
                areaIdToClassBreaksMap
            )
        );
    }
};

export const onRestoreAreaList = function* (action: Record<string, any>): any {
    const { fieldGuid, restoreId } = action.payload;
    const { fieldGuidToSavedAreaLists } = yield select(getZonesState);
    if (!fieldGuidToSavedAreaLists.has(fieldGuid)) {
        throw new Error(`No saved area lists for fieldGuid ${fieldGuid}`);
    }
    const savedAreaLists = fieldGuidToSavedAreaLists.get(fieldGuid);
    if (!savedAreaLists.has(restoreId)) {
        throw new Error(`Invalid \`restoreId\` ${restoreId} for fieldGuid ${fieldGuid}`);
    }
    const areaList = savedAreaLists.get(restoreId);

    if (yield isEventAreaAction(fieldGuid)) {
        const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
        const { eventAreaList, isSamplingEvent } = fieldGuidToEventDetails.get(fieldGuid);
        if (isSamplingEvent) {
            for (const prop of Object.keys(eventAreaList[0].agEventList[0].agEventModel)) {
                if (prop in areaList[0].agEventList[0].agEventModel) {
                    areaList[0].agEventList[0].agEventModel[prop] =
                        eventAreaList[0].agEventList[0].agEventModel[prop];
                }
            }
        }
        yield put(
            eventsActions.updateEventDetails(fieldGuid, {
                eventAreaList: areaList,
            })
        );
    } else if (yield isAnalysisAreaAction(fieldGuid)) {
        const { analysisSummary } = yield select(analysisInfoSelectors.getModuleState);
        analysisSummary.analysisLayerAreaList = areaList;
        yield put(analysisInfoActions.setAnalysisSummary(analysisSummary));
    } else {
        const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
        const isRecAction = fieldGuidToRecDetails.has(fieldGuid);
        console.assert(isRecAction);
        yield put(recsActions.updateRecDetails(fieldGuid, { recAreaList: areaList }));
    }
};

export const onSaveAreaList = function* (action: Record<string, any>): any {
    const { fieldGuid, restoreId } = action.payload;

    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const isEventAction = fieldGuidToEventDetails.has(fieldGuid);

    let areaList;
    if (isEventAction) {
        areaList = fieldGuidToEventDetails.get(fieldGuid).eventAreaList;
    } else {
        const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
        const isRecAction = fieldGuidToRecDetails.has(fieldGuid);
        if (isRecAction) {
            areaList = fieldGuidToRecDetails.get(fieldGuid).recAreaList;
        } else {
            const { analysisSummary } = yield select(analysisInfoSelectors.getModuleState);
            console.assert(analysisSummary);
            areaList = analysisSummary.analysisLayerAreaList;
        }
    }
    yield put(actions.updateSavedAreaLists(fieldGuid, restoreId, areaList));
};

const onSetCopyFromAreaId = function* (action: Record<string, any>): any {
    const { areaId } = action.payload;
    if (areaId != null) {
        yield put(mapToolsActions.setActiveToolsetOnly(Toolset.ZONE_COPY));
    } else {
        // canceling copy
        yield put(mapToolsActions.setActiveToolsetOnly(Toolset.ZONE_EDIT));
    }
};

const onSetCurrentBatchFieldGuid = function* (action: Record<string, any>): any {
    const { batchFieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);

    if (batchFieldGuid != null) {
        return;
    }

    const unfilteredFieldGuidList =
        fieldGuidToEventDetails.size > 0
            ? [...fieldGuidToEventDetails.keys()]
            : [...fieldGuidToRecDetails.keys()];

    const fieldGuidList = unfilteredFieldGuidList.filter(
        (fieldGuid) => fieldGuid !== BATCH_TEMPLATE_FIELD_GUID
    );
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(mapActions.setZoomToFieldList(fieldGuidList));
};

export const onUpdateAreaPolygons = function* (action: Record<string, any>): any {
    const { fieldGuid, areaIdToNewAreaIdPolygonMap } = action.payload;
    if (!fieldGuid) {
        console.error("onUpdateAreaPolygons dispatched without `fieldGuid`");
        return;
    }
    yield put(actions.setZonesLoading(true));
    if (yield isEventAreaAction(fieldGuid)) {
        yield put(eventsActions.updateEventAreaPolygons(fieldGuid, areaIdToNewAreaIdPolygonMap));
        yield put(eventsActions.initializeEventsZonesSucceeded());
    } else {
        console.assert(!(yield isAnalysisAreaAction(fieldGuid))); //should be handled in the map tools
        yield put(recsActions.updateRecAreaPolygons(fieldGuid, areaIdToNewAreaIdPolygonMap));
        yield put(recsActions.initializeRecsZonesSucceeded());
    }
    yield put(actions.setZonesLoading(false));

    // Check if we removed `currentAreaId`
    const { fieldGuidToCurrentAreaId } = yield select(getZonesState);
    const prevAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    let areaId = null;
    if (areaIdToNewAreaIdPolygonMap.has(prevAreaId)) {
        const newAreaIdList = [...areaIdToNewAreaIdPolygonMap.get(prevAreaId).keys()];
        if (newAreaIdList.includes(prevAreaId)) {
            areaId = prevAreaId;
        }
    }

    if (areaId) {
        yield put(actions.setCurrentAreaId(fieldGuid, areaId));
    }
};

export const refreshSelectedRecsEvents = function* (): any {
    yield put(actions.fetchSelectedFieldEvents());
    yield put(actions.fetchSelectedFieldRecs());
};

type ReplaceAction<TMessage extends IChangedMessage> = (
    messages: TMessage[],
    fields: SearchAPI.IFieldResult[]
) => any;

const onChanged = function* <TMessage extends IChangedMessage>(
    messages: TMessage[],
    replaceAction: ReplaceAction<TMessage>
) {
    const userGuid = yield select(getTheUserGuid);
    const fieldGuids = messages.map((x) => x.fieldGuid);
    const uniqueFieldGuids = _.uniq(fieldGuids);
    const currentMessages = [...messages];

    const fields: SearchAPI.IFieldResult[] = yield call(SearchAPI.getFields, {
        userGuid,
        fieldGuid: uniqueFieldGuids,
    });

    yield put(replaceAction(currentMessages, fields));
};

const onRecChanged = function* (messages: IRecChangedMessage[]) {
    yield onChanged(messages, recsActions.replaceRecGeneral);
};

const onInactiveRecChanged = function* (messages: IRecChangedMessage[]) {
    yield onChanged(messages, recsActions.replaceInactiveRecGeneral);
};

const onEventChanged = function* (messages: IEventChangedMessage[]) {
    yield onChanged(messages, eventsActions.replaceEventGeneral);
};

const onInactiveEventChanged = function* (messages: IEventChangedMessage[]) {
    yield onChanged(messages, eventsActions.replaceInactiveEventGeneral);
};

export const messageSubscriptions = function* (): any {
    const handleEventStatusChanged = function* (message) {
        yield put(
            recsEventsActions.updateEventSummaryImportedStatus(
                message.fieldGuid,
                message.agEventGeneralGuid,
                message.eventStatusCode
            )
        );

        if (message.eventStatusCode) {
            message.statusCode = message.eventStatusCode;
        }

        yield put(importWizardActions.updateProposedEventStatus(message));
    };

    yield put(
        messagingActions.subscribe(1000, {
            eventName: "eventStatusChanged",
            generator: handleEventStatusChanged,
        })
    );
    yield put(
        messagingActions.subscribe(
            1000,
            {
                eventName: "eventChanged",
                generatorAccumulate: onEventChanged,
            },
            {
                eventName: "eventRemoved",
                actionAccumulate: eventsActions.removeEventGeneral,
            },
            {
                eventName: "eventsDestroyed",
                action: (message) =>
                    eventsActions.destroyEventsByAgEventGeneralGuid(message.agEventGeneralGuids),
            },
            {
                eventName: "inactiveEventChanged",
                generatorAccumulate: onInactiveEventChanged,
            },
            {
                eventName: "inactiveEventRemoved",
                actionAccumulate: eventsActions.removeInactiveEventGeneral,
            },
            {
                eventName: "recChanged",
                generatorAccumulate: onRecChanged,
            },
            {
                eventName: "recRemoved",
                actionAccumulate: recsActions.removeRecGeneral,
            },
            {
                eventName: "s3PhotoUploadFailed",
                action: eventsActions.s3PhotoUploadFailed,
            },
            {
                eventName: "s3PhotoCopyFailed",
                action: eventsActions.s3PhotoCopyFailed,
            },
            {
                eventName: "recStatusChanged",
                action: (message) =>
                    recsActions.updateRecStatus(
                        new Map(Object.entries(message.fieldGuidToRecGeneralGuids)),
                        message.recStatusCode
                    ),
            },
            {
                eventName: "inactiveRecChanged",
                generatorAccumulate: onInactiveRecChanged,
            },
            {
                eventName: "inactiveRecRemoved",
                actionAccumulate: recsActions.removeInactiveRecGeneral,
            }
        )
    );
};

export const onSaveRecDetailsSucceeded = function* (): any {
    yield put(setTriggeredPage(null));
};

export const onSetZoneFileGuid = function* (action: Record<string, any>): any {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const { fieldGuid, zoneFileGuid } = action.payload;
    if (fieldGuidToEventDetails.has(fieldGuid)) {
        yield put(recsEventsActions.updateEventDetails(fieldGuid, { zoneFileGuid }));
    } else if (fieldGuidToRecDetails.has(fieldGuid)) {
        yield put(recsEventsActions.updateRecDetails(fieldGuid, { zoneFileGuid }));
    }
};

export const refreshLayerAccordionItems = function* (action: Record<string, any>): any {
    const { payload } = action;
    if (payload != null && payload.changes.length > 0) {
        //get the list of selected fields and layers to change
        const selectedFieldGuids = yield select(cdSelectors.getSelectedFieldGuids);
        const changedItems = yield select(recsEventsSelectors.getChangedLayerItems);
        const newFieldGuids = new Set<string>();
        for (const change of payload.changes) {
            const statusIsComplete =
                (change.eventData != null &&
                    change.eventData.importedStatus === StatusCodes.Complete) ||
                (change.recData != null && change.recData.importedStatus === StatusCodes.Complete);
            if (
                statusIsComplete &&
                selectedFieldGuids.has(change.fieldGuid) &&
                !changedItems.has(change.fieldGuid)
            ) {
                //add to the list of fieldguids to update
                newFieldGuids.add(change.fieldGuid);
            }
        }
        if (newFieldGuids.size > 0) {
            yield put(recsEventsActions.setChangedLayerItems(newFieldGuids));
        }
    }
};

export const recsEventsSaga = function* (): any {
    yield all([
        eventsSaga(),
        recsSaga(),
        messageSubscriptions(),
        takeEvery(actions.COPY_AREAS, onCopyAreas),
        takeLatest(actions.CONVERT_RECS_TO_EVENTS, convertRecsToEvents),
        takeLatest(actions.FETCH_EVENTS, onFetchSelectedFieldEventsOrRecs),
        takeLatest(actions.FETCH_RECS, onFetchSelectedFieldEventsOrRecs),
        takeLatest(actions.PROCESS_CANCEL_REC, onProcessCancelRecs),
        takeEvery(actions.REFRESH_FILTER_OPTIONS, onRefreshFilterOptions),
        takeEvery(actions.RESET_AREA_POLYGONS, onResetAreaPolygons),
        takeEvery(actions.RESET_CLASSIFIED_AREA_POLYGONS, onResetClassifiedAreaPolygons),
        takeEvery(actions.RESTORE_AREA_LIST, onRestoreAreaList),
        takeEvery(actions.REPLACE_REC_GENERAL, processRecSummaryChanged),
        takeEvery(actions.UPDATE_REC_STATUS, processRecStatusChanged),
        takeEvery(actions.SAVE_AREA_LIST, onSaveAreaList),
        takeEvery(actions.SET_COPY_FROM_AREA_ID, onSetCopyFromAreaId),
        takeLatest(actions.SET_CURRENT_BATCH_FIELD_GUID, onSetCurrentBatchFieldGuid),
        takeLatest(actions.SET_ZONE_FILE_GUID, onSetZoneFileGuid),
        takeEvery(actions.UPDATE_AREA_POLYGONS, onUpdateAreaPolygons),
        takeEvery(actions.SAVE_REC_DETAILS_SUCCEEDED, onSaveRecDetailsSucceeded),
        takeEvery(
            [
                actions.REMOVE_EVENT_GENERAL,
                actions.REMOVE_INACTIVE_EVENT_GENERAL,
                actions.REMOVE_INACTIVE_REC_GENERAL,
                actions.REMOVE_REC_GENERAL,
                actions.REPLACE_EVENT_GENERAL,
                actions.REPLACE_INACTIVE_EVENT_GENERAL,
                actions.REPLACE_INACTIVE_REC_GENERAL,
                actions.REPLACE_REC_GENERAL,
            ],
            refreshLayerAccordionItems
        ),
    ]);
};
