import { debounce } from "lodash";
import { delay } from "redux-saga";
import {
    all,
    call,
    cancel,
    cancelled,
    fork,
    join,
    put,
    race,
    select,
    take,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import { defineMessages } from "react-intl";

import { FileImportAPI } from "@ai360/core";
import * as loginActions from "~/login/actions";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { StatusCodes } from "~/action-panel/components/common/status-messages";
import { actions as messagingActions } from "~/messaging";
import { ImportFileInfo, ImportTemplate } from "./model";
import * as actions from "./actions";
import * as selectors from "./selectors";

let _fetchImportTypesTask = null;
const _getImportFileInfoListForTemplateTasks = new Map();
let _refreshImportTypesTask = null;

const messages = defineMessages({
    getTelematicsStatusError: {
        id: "apiError.getTelematicsStatusError",
        defaultMessage: "Failed to get the pending Telematics status.",
    },
    setExpandedPreferenceError: {
        id: "apiError.setExpandedPreferenceError",
        defaultMessage: "Unable to set the expanded pref",
    },
});

const debounceFetchTelematicsCounts = debounce(
    (userGuid) => {
        return FileImportAPI.getOnsiteEventDownloadCount(userGuid);
    },
    1000,
    { leading: true }
);

export const fetchOnLogin = function* () {
    do {
        yield take(loginActions.SET_USER_INFO_COMPLETE);

        yield put(actions.fetchIsTelematicsUser());
        yield put(actions.fetchTelematicsCounts());

        // wait for 15 seconds and then `put(actions.fetchImportFileTypes())`, unless
        // something else does a `put(actions.fetchImportFileTypes())` before the `delay` completes
        const { takeFetchResult } = yield race({
            _: call(delay, 15000),
            takeFetchResult: take(actions.FETCH_IMPORT_FILE_TYPES),
        });
        if (takeFetchResult == null) {
            yield put(actions.fetchImportFileTypes());
        }
    } while (true);
};

const getApiArgs = (userGuid, fetchFilter, templateGuid = "") => {
    return [
        userGuid,
        templateGuid,
        fetchFilter.includeOtherUsers,
        fetchFilter.userFilterObj,
        fetchFilter.userFilterStr,
    ];
};

export const getFileImportUploads = function* (
    importTypeGuid,
    templateList,
    userGuid,
    fetchFilter
) {
    for (let template of templateList) {
        if (template.isExpanded) {
            yield fork(
                getImportFileInfoListForTemplate,
                template.templateGuid,
                userGuid,
                fetchFilter
            );
        }
    }
};

export const getImportFileInfoListForTemplateTaskImpl = function* (
    templateGuid,
    userGuid,
    fetchFilter
) {
    yield put(actions.setIfoFetchingStatus(templateGuid, true));
    try {
        const getImportFileInfoListResult = yield call(
            FileImportAPI.getUploadedFileList,
            ...getApiArgs(userGuid, fetchFilter, templateGuid)
        );
        const importFileInfoList = yield* parseImportFileInfoList(
            getImportFileInfoListResult,
            templateGuid
        );
        yield put(actions.importFileInfoFetchSucceeded(importFileInfoList, templateGuid));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
    yield put(actions.setIfoFetchingStatus(templateGuid, false));
};

export const getImportFileInfoListForTemplate = function* (templateGuid, userGuid, fetchFilter) {
    const task = yield fork(
        getImportFileInfoListForTemplateTaskImpl,
        templateGuid,
        userGuid,
        fetchFilter
    );
    _getImportFileInfoListForTemplateTasks.set(templateGuid, task);
    try {
        yield join(task);
    } finally {
        _getImportFileInfoListForTemplateTasks.delete(templateGuid);
    }
};

export const getTypeAndTemplateList = function* (userGuid, fetchFilter) {
    try {
        return yield call(
            FileImportAPI.getTypeAndTemplateList,
            ...getApiArgs(userGuid, fetchFilter)
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

const handleFileDataChanged = function* (events) {
    let updates = [];
    let deletes = [];

    const importFileMap = yield select(selectors.getImportFiles);

    for (const event of events) {
        console.assert(event.importFileGuid != null);

        const ifo = importFileMap.get(event.importFileGuid);

        if (ifo == null) {
            continue;
        }

        // handle random model attr name inconsistency
        event.importPoints = {};
        if (event.totalPoints != null) {
            event.importPoints.totalPoints = event.totalPoints;
        }
        if (event.statusCode) {
            event.importFileStatusCode = event.statusCode;
        }
        const parsedIfo = ImportFileInfo.fromJsonObj(event);

        const updatedAttribs = {};
        if (event.statusCode != null) {
            updatedAttribs.statusCode = parsedIfo.statusCode;
            // If the import file is 'complete' or 'imported' we need to remove it from the list instead of updating it.
            const { Complete, Imported, ErrorAfterOnsiteConversion } = StatusCodes;
            if (
                event.statusCode === Complete ||
                event.statusCode === Imported ||
                event.statusCode === ErrorAfterOnsiteConversion
            ) {
                deletes.push(event.importFileGuid);
                continue;
            }
        }

        if (event.uploadedDate != null) {
            updatedAttribs.uploadDatetime = parsedIfo.uploadDatetime;
        }
        if (event.totalPoints != null) {
            updatedAttribs.totalPoints = parsedIfo.totalPoints;
        }
        if (event.isFieldBoundary != null) {
            updatedAttribs.isFieldBoundary = parsedIfo.isFieldBoundary;
        }

        updates.push(ifo.clone(updatedAttribs));
    }

    yield put(actions.updateImportFileInfos(updates));
    yield put(actions.deleteImportFileInfoList(deletes, true));
    yield put(actions.fetchTelematicsCounts());
};

export const messageSubscriptions = function* () {
    const controllerFileCompleted = (message) => {
        const { importTypeTemplateList } = message;
        const forceRefreshTemplateGuidSet = Array.isArray(importTypeTemplateList)
            ? new Set(
                  importTypeTemplateList.map(
                      (typeTemplateInfo) => typeTemplateInfo.ImportTemplateGuid
                  )
              )
            : new Set();
        return actions.refreshImportData(forceRefreshTemplateGuidSet);
    };

    yield put(
        messagingActions.subscribe(
            0,
            {
                eventName: "controllerFileCompleted",
                action: controllerFileCompleted,
            },
            {
                eventName: "controllerFileCompleted",
                action: actions.fetchTelematicsCounts,
            }
        )
    );

    yield put(
        messagingActions.subscribe(
            2000,
            {
                eventName: "fileDataChanged",
                generatorAccumulate: handleFileDataChanged,
            },
            {
                eventName: "onsiteNodeFilesPending",
                action: actions.fetchTelematicsCounts,
            }
        )
    );
};

export const onDeleteImportFileInfoList = function* (action) {
    const { importFileInfoGuidList, clientStateOnly } = action.payload;

    if (clientStateOnly) {
        return;
    }

    try {
        const userGuid = yield select(getTheUserGuid);
        yield call(FileImportAPI.deleteUploadedFile, userGuid, importFileInfoGuidList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchImportFileInfo = function* (action) {
    const { templateGuid } = action.payload;
    if (_getImportFileInfoListForTemplateTasks.has(templateGuid)) {
        return;
    }
    const userGuid = yield select(getTheUserGuid);
    const fetchFilter = yield select(selectors.getFetchAllUsersFilter);
    yield getImportFileInfoListForTemplate(templateGuid, userGuid, fetchFilter);
};

export const onFetchImportFileTypesTaskImpl = function* () {
    const userGuid = yield select(getTheUserGuid);
    const fetchFilter = yield select(selectors.getFetchAllUsersFilter);
    const fileImportTypes = yield* getTypeAndTemplateList(userGuid, fetchFilter);
    yield put(actions.setFetchingTypesStatus(false));
    if (fileImportTypes == null) {
        return;
    }

    for (const importType of fileImportTypes) {
        importType.templateList = yield* parseTemplateList(
            importType.templateList,
            importType.guid
        );
    }

    yield put(actions.importTypesTmpltsFetchSucceeded(fileImportTypes));
    for (let importType of fileImportTypes) {
        yield fork(
            getFileImportUploads,
            importType.guid,
            importType.templateList,
            userGuid,
            fetchFilter
        );
    }
};

export const onFetchImportFileTypesTask = function* () {
    yield put(actions.setFetchingTypesStatus(true));
    if (_fetchImportTypesTask != null) {
        yield cancel(_fetchImportTypesTask);
        _fetchImportTypesTask = null;
    }
    if (_refreshImportTypesTask != null) {
        yield cancel(_refreshImportTypesTask);
        _refreshImportTypesTask = null;
    }
    for (const [templateGuid, task] of [..._getImportFileInfoListForTemplateTasks.entries()]) {
        yield cancel(task);
        _getImportFileInfoListForTemplateTasks.delete(templateGuid);
    }

    _fetchImportTypesTask = yield fork(onFetchImportFileTypesTaskImpl);
    try {
        yield join(_fetchImportTypesTask);
    } finally {
        const _cancelled = yield cancelled();
        if (_cancelled) {
            yield put(actions.setFetchingTypesStatus(false));
        }
        _fetchImportTypesTask = null;
    }
};

export const onFetchIsTelematicsUser = function* (action) {
    try {
        const userGuid = yield select(getTheUserGuid);
        const isTelematicsUser = yield call(FileImportAPI.getIsOnsiteTelematicsUser, userGuid);
        yield put(actions.setIsTelematicsUser(isTelematicsUser));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.getTelematicsStatusError));
    }
};

export const onFetchTelematicsCounts = function* (action) {
    try {
        const userGuid = yield select(getTheUserGuid);
        const telematicsCounts = yield call(debounceFetchTelematicsCounts, userGuid);
        yield put(actions.updateTelematicsCounts(telematicsCounts));
        const isTelematicsProcessing = yield select(selectors.getIsTelematicsProcessing);
        if (isTelematicsProcessing && !telematicsCounts.isTelematicsProcessing) {
            // Prevent updating the store if not necessary
            yield put(actions.setIsTelematicsProcessing(false));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action, messages.getTelematicsStatusError));
    }
};

export const onRefreshImportData = function* (action) {
    if (_fetchImportTypesTask != null || _refreshImportTypesTask != null) {
        return;
    }
    while (_getImportFileInfoListForTemplateTasks.size > 0) {
        // wait until all file-info-fetch tasks finish or are cancelled
        yield all([..._getImportFileInfoListForTemplateTasks.values()].map((task) => join(task)));
    }

    const { forceRefreshTemplateGuidSet } = action.payload;
    _refreshImportTypesTask = yield fork(refreshImportDataTaskImpl, forceRefreshTemplateGuidSet);
    try {
        yield join(_refreshImportTypesTask);
    } finally {
        _refreshImportTypesTask = null;
    }
};

export const onSetFilterIncludeOtherUsers = function* (action) {
    const { includeOtherUsers } = action.payload;
    if (_fetchImportTypesTask != null) {
        yield cancel(_fetchImportTypesTask);
        _fetchImportTypesTask = null;
    }
    if (_refreshImportTypesTask != null) {
        yield cancel(_refreshImportTypesTask);
        _refreshImportTypesTask = null;
    }
    for (const [templateGuid, task] of [..._getImportFileInfoListForTemplateTasks.entries()]) {
        yield cancel(task);
        _getImportFileInfoListForTemplateTasks.delete(templateGuid);
    }
    yield put(actions.removeAllData());
    if (!includeOtherUsers) {
        yield put(actions.fetchImportFileTypes());
    }
};

export const updateUserImportPrefs = function* (action, typeGuid, templateGuid, expanded) {
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(
            FileImportAPI.updateUserImportPreferences,
            userGuid,
            typeGuid,
            templateGuid,
            expanded
        );
    } catch (err) {
        yield put(
            notificationActions.apiCallError(err, action, messages.setExpandedPreferenceError)
        );
    }
};

export const onSetTypeExpanded = function* (action) {
    const { typeGuid, expanded } = action.payload;
    yield* updateUserImportPrefs(action, typeGuid, null, expanded);
};

export const onSetTemplateExpanded = function* (action) {
    const { templateGuid, expanded } = action.payload;
    const importTemplate = yield select(selectors.getImportTemplate, templateGuid);
    if (expanded && !importTemplate.haveFetched) {
        yield put(actions.fetchImportFileInfo(templateGuid));
    }
    yield* updateUserImportPrefs(action, null, templateGuid, expanded);
};

export const deleteControllerFileInfoOnComplete = function* (action) {
    const updateIfoStatusCodeActions =
        action.type === actions.UPDATE_IFO_STATUS_CODE
            ? [action]
            : action.payload.actionList.filter(
                  (action) => action.type === actions.UPDATE_IFO_STATUS_CODE
              );
    if (updateIfoStatusCodeActions.length === 0) {
        return;
    }
    const importFiles = yield select(selectors.getImportFiles);
    const deleteIfoGuidList = updateIfoStatusCodeActions.filter((action) => {
        const { ifoGuid, statusCode } = action.payload;
        const { Complete, ErrorAfterOnsiteConversion } = StatusCodes;
        if (statusCode !== Complete && statusCode !== ErrorAfterOnsiteConversion) {
            return false;
        }
        const importFileInfo = importFiles.get(ifoGuid);
        return importFileInfo && importFileInfo.isControllerFile;
    });

    if (deleteIfoGuidList.length === 0) {
        return;
    }
    yield put(actions.deleteImportFileInfoList(deleteIfoGuidList, true));
};

export const parseImportFileInfoList = function* (
    getImportFileInfoListResult,
    expectedTemplateGuid
) {
    const importFileInfoList = [];
    for (let importFileInfoObj of getImportFileInfoListResult) {
        const importFileInfo = ImportFileInfo.fromJsonObj(importFileInfoObj);
        if (importFileInfo.statusCode === 6 || importFileInfo.statusCode === 7) {
            continue; // ignore complete or imported files
        }
        if (importFileInfo.isValid()) {
            if (importFileInfo.templateGuid === expectedTemplateGuid) {
                importFileInfoList.push(importFileInfo);
            } else {
                yield put(
                    actions.invalidApiResult(
                        "Invalid `importTypeGuid`",
                        importFileInfoObj,
                        expectedTemplateGuid
                    )
                );
            }
        } else {
            yield put(
                actions.invalidApiResult(
                    "Invalid file import info",
                    importFileInfoObj,
                    importFileInfo
                )
            );
        }
    }
    return importFileInfoList;
};

export const parseTemplateList = function* (getTemplateListFromTypeResult, expectedImportTypeGuid) {
    const fileImportTemplates = [];
    for (let templateJson of getTemplateListFromTypeResult) {
        const template = ImportTemplate.fromJsonObj(templateJson);
        if (template.isValid()) {
            if (template.importTypeGuid === expectedImportTypeGuid) {
                fileImportTemplates.push(template);
            } else {
                yield put(
                    actions.invalidApiResult(
                        "Invalid `importTypeGuid`",
                        templateJson,
                        expectedImportTypeGuid
                    )
                );
            }
        } else {
            yield put(actions.invalidApiResult("Invalid import template", templateJson));
        }
    }
    return fileImportTemplates;
};

export const refreshImportDataTaskImpl = function* (forceRefreshTemplateGuidSet) {
    const userGuid = yield select(getTheUserGuid);
    const fetchFilter = yield select(selectors.getFetchAllUsersFilter);
    const fileImportTypes = yield* getTypeAndTemplateList(userGuid, fetchFilter);
    if (fileImportTypes == null) {
        return;
    }

    /**
     * Parse the templates and keep track of new ones that we need to expand/fetch
     */
    let importTemplates = yield select(selectors.getImportTemplates);
    const newTemplatesMap = new Map();
    for (const importType of fileImportTypes) {
        importType.templateList = yield* parseTemplateList(
            importType.templateList,
            importType.guid
        );
        for (let template of importType.templateList) {
            if (importTemplates.has(template.templateGuid)) {
                continue;
            }
            newTemplatesMap.set(template.templateGuid, template);
        }
    }

    yield put(actions.refreshImportTypeTemplateInfoComplete(fileImportTypes));
    if (newTemplatesMap.size > 0) {
        for (let template of newTemplatesMap.values()) {
            yield fork(
                getImportFileInfoListForTemplate,
                template.templateGuid,
                userGuid,
                fetchFilter
            );
        }
    }

    importTemplates = yield select(selectors.getImportTemplates);
    if (forceRefreshTemplateGuidSet != null) {
        for (const templateGuid of forceRefreshTemplateGuidSet) {
            if (!importTemplates.has(templateGuid)) {
                console.warn(
                    `unknown templateGuid in 'forceRefreshTemplateGuidSet': ${templateGuid}`
                );
                continue;
            }
            if (!newTemplatesMap.has(templateGuid)) {
                // force-refresh any non-new templates in the `forceRefreshTemplateGuidSet` (new are fetched above)
                yield fork(getImportFileInfoListForTemplate, templateGuid, userGuid, fetchFilter);
            }
        }
    }

    const taskList = [..._getImportFileInfoListForTemplateTasks.values()];
    if (taskList.length > 0) {
        yield join(...taskList);
    }

    yield put(
        actions.refreshImportDataComplete(
            forceRefreshTemplateGuidSet,
            new Set(newTemplatesMap.keys())
        )
    );
};

export const onUploadCompleted = function* (action) {
    try {
        const userGuid = yield select(getTheUserGuid);
        yield call(FileImportAPI.importFileUploadsComplete, userGuid, [action.payload]);
        yield put(actions.uploadsProcessed());
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const fileImportSaga = function* () {
    yield all([
        fetchOnLogin(),
        takeEvery(actions.DELETE_IMPORT_FILE_INFO_LIST, onDeleteImportFileInfoList),
        takeLatest(actions.FETCH_IMPORT_FILE_TYPES, onFetchImportFileTypesTask),
        takeEvery(actions.FETCH_IMPORT_FILE_INFO, onFetchImportFileInfo),
        takeLatest(actions.FETCH_IS_TELEMATICS_USER, onFetchIsTelematicsUser),
        takeLatest(actions.FETCH_TELEMATICS_COUNTS, onFetchTelematicsCounts),
        takeEvery(actions.REFRESH_IMPORT_DATA, onRefreshImportData),
        takeEvery(actions.SET_FILTER_INCLUDE_OTHER_USERS, onSetFilterIncludeOtherUsers),
        takeLatest(actions.SET_TEMPLATE_EXPANDED, onSetTemplateExpanded),
        takeLatest(actions.SET_TYPE_EXPANDED, onSetTypeExpanded),
        takeEvery(
            [actions.CUSTOM_BATCH, actions.UPDATE_IFO_STATUS_CODE],
            deleteControllerFileInfoOnComplete
        ),
        takeEvery(actions.UPLOAD_COMPLETED, onUploadCompleted),
        messageSubscriptions(),
    ]);
};
