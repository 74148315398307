import React, { Component } from "react";

import SearchAgvanceField from "~/action-panel/components/common/search-agvance-field";
import { adminData } from "~/admin/data";
import { AutoSearch, DialogBox, DialogBoxFooterType, Loader, SelectInput } from "~/core";
import { ACTIVE_YN } from "~/core/picklist";
import { createAddLinkLabelText } from "~/i18n-messages";
import { SearchAPI } from "@ai360/core";
import { GeometryUtils, Toolset } from "@ai360/core";
import { FieldResurfacePrompt } from "~/action-panel/components/common";
import { optionRendererWithCustomTitle } from "~/action-panel/components/common/validation-utils";
import { fetchOccupiedFarmNames } from "~/utils/api/field";

import { messages } from "../../../i18n-messages";

interface IRecord {
    agvanceCustomerGuid: string;
    agvanceFieldGuid: string;
    agvanceFieldOrgLevelGuid: string;
    customerGuid: string;
    customerName: string;
    farmName: string;
    fieldName: string;
    fieldGuid: string;
    importFieldBoundaryGuidList: any;
}

interface IProps {
    record: IRecord;
    currentFieldData: any;
    drawToolFlags: any;
    fetchCurrentFieldData: (fieldGuid: string) => void;
    ignoreFarm: boolean;
    isLoading: boolean;
    isLockCustomer: boolean;
    locked: boolean;
    onAddNewCustomer: (customerName: string) => void;
    onClose: (isCancel: boolean) => void;
    onSave: (data: any, callback: () => void) => void;
    removeImportFieldBoundary: () => void;
    toolsetPayload: any;
    fieldEditGeometries: any[];
    fieldEditsChanged: boolean;
    maximumArea: number;
    setIsLoading: (isLoading: boolean) => void;
    setActiveToolset: (toolset: any, payload?: any) => void;
    setActiveToolsetPayloadOnly: (payload: any) => void;
    setToolsetDisabled: (isDisabled: boolean) => void;
    intl: any;
    agvanceFieldList: any[];
    agvanceOrgLevelList: any[];
    fetchAgvanceFieldList: (customerGuid: string) => void;
    fetchAgvanceOrgLevelList: (customerGuid: string) => void;
    isAgvanceCustomer: boolean;
    userGuid: string;
}

interface IState {
    customers: SearchAPI.ILegacyCustomerResult[];
    editFarmName: string;
    editFieldName: string;
    ignoreFarm: boolean;
    agvanceCustomerGuid: string;
    agvanceFieldGuid: string;
    agvanceFieldList: any[];
    agvanceFieldOrgLevelGuid: string;
    agvanceOrgList: any[];
    customerFarmOptions: any[];
    editCustomerGuid: string;
    editCustomerName: string;
    fieldToSave: any;
    isConfirmationModalOpen: boolean;
    isMaxAreaModalOpen: boolean;
    openAddCustomerPanel: boolean;
    searchValue: string;
    showResurfacePrompt: boolean;
    yesAction: any;
    doneLoading: boolean;
    delaySelectedValue: boolean;
}

export class AddEditFieldModal_ extends Component<IProps, IState> {
    static defaultProps = {
        locked: false,
    };

    constructor(props: IProps) {
        super(props);
        const {
            customerGuid,
            customerName,
            farmName,
            fieldName,
            agvanceCustomerGuid,
            agvanceFieldGuid,
            agvanceFieldOrgLevelGuid,
        } = props.record;

        if (customerGuid && props.isAgvanceCustomer) {
            this.props.fetchAgvanceOrgLevelList(customerGuid);
        }

        this.updateCustomer = this.updateCustomer.bind(this);
        this.onSaveField = this.onSaveField.bind(this);
        this.onResurfaceClose = this.onResurfaceClose.bind(this);
        this.onResurfaceCancel = this.onResurfaceCancel.bind(this);
        this.noOptionsRenderer = this.noOptionsRenderer.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.updateFarmName = this.updateFarmName.bind(this);
        this.updateFieldName = this.updateFieldName.bind(this);

        this.state = {
            customers: [],
            agvanceCustomerGuid,
            agvanceFieldGuid,
            agvanceFieldList: [],
            agvanceFieldOrgLevelGuid,
            agvanceOrgList: [],
            customerFarmOptions: [],
            editCustomerGuid: null,
            editCustomerName: customerName,
            editFarmName: props.ignoreFarm ? "" : farmName,
            editFieldName: fieldName,
            fieldToSave: null,
            ignoreFarm: props.ignoreFarm,
            isConfirmationModalOpen: false,
            isMaxAreaModalOpen: false,
            openAddCustomerPanel: false,
            searchValue: "",
            showResurfacePrompt: false,
            yesAction: null,
            doneLoading: false,
            delaySelectedValue: true,
        };
    }

    async componentDidMount() {
        const { customerGuid, fieldGuid, farmName } = this.props.record;
        const { toolsetPayload, userGuid, isLockCustomer } = this.props;

        this.props.setActiveToolset(Toolset.DRAW_EDIT_IMPORT, toolsetPayload);
        if (fieldGuid) {
            this.props.fetchCurrentFieldData(fieldGuid);
        }
        if (this.props.isAgvanceCustomer && customerGuid) {
            this.props.fetchAgvanceFieldList(customerGuid);
        }

        const customersPromise = SearchAPI.getCustomers({
            userGuid,
            enrolled: isLockCustomer ? true : null,
        });
        const occupiedFarmNamesPromise =
            customerGuid == null || customerGuid === ""
                ? Promise.resolve<string[]>([])
                : fetchOccupiedFarmNames(customerGuid, true, userGuid);

        const customers = await customersPromise;
        const occupiedFarmNames = await occupiedFarmNamesPromise;
        const customerFarmOptions = occupiedFarmNames
            .map((farm) => ({
                value: farm,
                label: farm,
            }))
            .concat({
                value: farmName || "",
                label: farmName || "",
            });
        this.setState({
            customers,
            customerFarmOptions,
            editCustomerGuid: this.props.record.customerGuid,
            doneLoading: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (nextProps.agvanceOrgLevelList !== this.props.agvanceOrgLevelList) {
            this.setAgvanceCustomer(nextProps.agvanceOrgLevelList);
            this.setState({
                agvanceOrgList: nextProps.agvanceOrgLevelList,
            });
        }
        if (nextProps.currentFieldData !== this.props.currentFieldData) {
            const { agvanceFieldGuid, agvanceFieldOrgLevelGuid, agvanceCustomerGuid } =
                nextProps.currentFieldData;
            this.setState({
                agvanceFieldGuid,
                agvanceFieldOrgLevelGuid,
                agvanceCustomerGuid,
            });
        }
        // Sets Agvance guids for the perfect matched field and farm name
        if (nextProps.agvanceFieldList !== this.props.agvanceFieldList) {
            const { editFarmName, editFieldName, ignoreFarm } = this.state;
            const selectedField = nextProps.agvanceFieldList.filter(
                (item) => item.name === editFieldName && item.farmName === editFarmName
            );
            if (selectedField.length) {
                this.setState({
                    editFarmName: ignoreFarm ? "" : selectedField[0].farmName,
                    agvanceFieldGuid: selectedField[0].agvanceGuid,
                });
            }
            this.setState({
                agvanceFieldList: nextProps.agvanceFieldList,
            });
        }
    }

    private canSaveEdits() {
        const { fieldEditGeometries, removeImportFieldBoundary } = this.props;
        const { editCustomerGuid, editFieldName } = this.state;

        return (
            editCustomerGuid !== "" &&
            editFieldName !== "" &&
            fieldEditGeometries &&
            // Allow save when there are no field geometries if
            // in import wizard and import file removal is needed
            (fieldEditGeometries.length > 0 || removeImportFieldBoundary)
        );
    }

    private close(isCancel?: boolean) {
        const { onClose, setIsLoading, setActiveToolset } = this.props;

        this.setState({
            editCustomerGuid: "",
            editCustomerName: "",
            editFarmName: "",
            editFieldName: "",
            customerFarmOptions: [],
        });
        setIsLoading(false);
        setActiveToolset(Toolset.DEFAULT);
        onClose(isCancel);
    }

    private save(resurfaceEventResult?: any) {
        const {
            fieldEditGeometries,
            fieldEditsChanged,
            setIsLoading,
            onSave,
            setToolsetDisabled,
            currentFieldData,
        } = this.props;
        const { editCustomerGuid, editCustomerName, editFarmName, editFieldName } = this.state;

        setIsLoading(true);
        let data = {
            editCustomerGuid,
            editCustomerName,
            editFarmName,
            editFieldName,
            fieldEditGeometries,
            fieldEditsChanged,
            ...currentFieldData,
        };

        if (resurfaceEventResult) {
            data.resurfaceEventResult = resurfaceEventResult;
        }

        if (this.props.isAgvanceCustomer) {
            const { agvanceFieldGuid, agvanceFieldOrgLevelGuid, agvanceCustomerGuid } = this.state;
            data = {
                ...data,
                agvanceFieldGuid,
                agvanceFieldOrgLevelGuid,
                agvanceCustomerGuid,
            };
        }
        setToolsetDisabled(true);
        onSave(data, () => this.close());
    }

    private setAgvanceCustomer = (agvanceOrgList: any[]) => {
        const { agvanceCustomerGuid, agvanceFieldOrgLevelGuid } = this.state;
        const selectedValue = agvanceOrgList.filter(({ isPrimary }) => isPrimary);
        if (!agvanceCustomerGuid && !agvanceFieldOrgLevelGuid && selectedValue.length) {
            this.setState({
                agvanceFieldOrgLevelGuid: selectedValue[0].orgLevelGuid,
                agvanceCustomerGuid: selectedValue[0].agvanceCustomerGuid,
            });
        }
    };

    private hideAgvanceCustomers() {
        return (
            !this.props.isAgvanceCustomer ||
            !this.state.editCustomerGuid ||
            this.state.agvanceOrgList.length === 1
        );
    }

    private showConfirmDialog(yesAction) {
        this.setState({
            isConfirmationModalOpen: true,
            yesAction,
        });
    }

    private showMaxAreaDialog() {
        this.setState({
            isMaxAreaModalOpen: true,
        });
    }

    private closeConfirmDialog() {
        this.setState({
            isConfirmationModalOpen: false,
            yesAction: null,
        });
    }

    private closeMaxAreaDialog() {
        this.setState({
            isMaxAreaModalOpen: false,
        });
    }

    private confirmYes() {
        if (this.state.yesAction) {
            this.state.yesAction();
        }
        this.closeConfirmDialog();
        this.close();
    }

    private updateCustomer(customer: SearchAPI.ILegacyCustomerResult) {
        if (!customer) {
            this.setState({
                editCustomerGuid: "",
                editCustomerName: "",
                customerFarmOptions: [],
            });

            if (this.props.isAgvanceCustomer) {
                this.setState({
                    agvanceFieldOrgLevelGuid: "",
                    agvanceCustomerGuid: "",
                });
            }
        } else if (customer.customerGuid !== this.state.editCustomerGuid) {
            const { customerGuid, customerName } = customer;
            fetchOccupiedFarmNames(customerGuid, true, this.props.userGuid).then((farmNames) =>
                this.setState({
                    editCustomerGuid: customerGuid,
                    editCustomerName: customerName,
                    customerFarmOptions: farmNames.map((farm) => ({
                        label: farm,
                        value: farm,
                    })),
                })
            );
            if (this.props.isAgvanceCustomer) {
                this.props.fetchAgvanceFieldList(customerGuid);
                this.props.fetchAgvanceOrgLevelList(customerGuid);
            }
        }
        this.updateToolsetPayload(
            !customer ? this.props.toolsetPayload.customerGuid : customer.customerGuid
        );
    }

    private updateFarmName(editFarmName: string) {
        this.setState({
            editFarmName,
        });
    }

    private updateFieldName(
        editFieldName: string,
        agvanceFieldGuid = "",
        fieldId: string = undefined,
        farmName: string = undefined
    ) {
        if (this.props.isAgvanceCustomer) {
            // Set the values for agvance integration
            this.setState({
                agvanceFieldGuid,
                editFarmName: this.state.ignoreFarm ? "" : farmName,
            });
        }
        this.setState({
            editFieldName,
        });
    }

    private onAgvanceCustomerChange(guid: string) {
        const selectedItem = this.props.agvanceOrgLevelList.find((record) => {
            return record.agvanceCustomerGuid === guid;
        });
        if (selectedItem) {
            this.setState({
                agvanceCustomerGuid: selectedItem.agvanceCustomerGuid,
                agvanceFieldOrgLevelGuid: selectedItem.orgLevelGuid,
                editFarmName: "",
                editFieldName: "",
            });
        } else {
            this.setState({
                agvanceFieldOrgLevelGuid: "",
                agvanceCustomerGuid: "",
            });
        }
    }

    private updateToolsetPayload(customerGuid: string) {
        this.props.setActiveToolsetPayloadOnly({
            ...this.props.toolsetPayload,
            customerGuid,
        });
    }

    private getAgvanceFieldList() {
        return this.state.agvanceFieldList.filter(({ agvanceGrowerGuid }) => {
            return this.state.agvanceCustomerGuid === agvanceGrowerGuid;
        });
    }

    private noOptionsRenderer() {
        const { formatMessage } = this.props.intl;
        const { editCustomerName, searchValue } = this.state;
        const customerName = searchValue ? searchValue : editCustomerName;
        return (
            <div className="add-customer" onClick={() => this.props.onAddNewCustomer(customerName)}>
                {createAddLinkLabelText(formatMessage, messages.customer)}
            </div>
        );
    }

    private onSaveField() {
        const { fieldEditGeometries, maximumArea } = this.props;

        if (GeometryUtils.checkMaxAcreage(fieldEditGeometries, maximumArea)) {
            this.showMaxAreaDialog();
            return;
        } else if (fieldEditGeometries.length === 0) {
            this.showConfirmDialog(this.props.removeImportFieldBoundary);
            return;
        }
        if (this.isEditingField()) {
            this.showResurfacePrompt();
        } else {
            this.save();
        }
    }

    private isEditingField() {
        return this.props.record && this.props.record.fieldGuid;
    }

    private onSearchChange(searchValue: string) {
        this.setState({ searchValue });
    }

    private showResurfacePrompt() {
        this.setState({ showResurfacePrompt: true });
    }

    private onResurfaceClose(resurfaceEventResult) {
        this.setState({ showResurfacePrompt: false }, () => this.save(resurfaceEventResult));
    }

    private onResurfaceCancel() {
        this.setState({ showResurfacePrompt: false });
    }

    render() {
        const {
            editCustomerGuid,
            editFarmName,
            editFieldName,
            agvanceOrgList,
            agvanceCustomerGuid,
            doneLoading,
            delaySelectedValue,
        } = this.state;
        const { record, locked, isLoading, maximumArea } = this.props;
        const { customerName } = record;
        const { formatMessage } = this.props.intl;
        const { customers } = this.state;
        const initFilter = customers.find((item) => item.customerName === customerName)
            ? customerName
            : "";

        const fieldResurfacePromptProps = {
            fieldGuid: record.fieldGuid,
            isOpen: this.state.showResurfacePrompt,
            onCancel: this.onResurfaceCancel,
            onClose: this.onResurfaceClose,
        };

        if (doneLoading && delaySelectedValue) {
            this.setState({
                delaySelectedValue: false,
            });
        }

        return (
            <div>
                <div className="edit-modal-container">
                    <DialogBox
                        action="save"
                        actionDisabled={!this.canSaveEdits()}
                        className="edit-field-modal"
                        closeOnClickOff={false}
                        closeOnEscape={false}
                        draggable
                        footerType={DialogBoxFooterType.ACTION_CANCEL}
                        forceOverflow
                        hideCloseX
                        isOpen
                        isModal
                        onAction={this.onSaveField}
                        onClose={() => this.close(true)}
                        title={formatMessage(messages.addEditFieldBoundaryText, {
                            count: record.fieldGuid || record.importFieldBoundaryGuidList ? 1 : 0,
                        })}
                    >
                        {isLoading ? <Loader /> : null}
                        <div className="edit-field-modal-body">
                            <AutoSearch
                                initialFilterStr={initFilter}
                                required={true}
                                disabled={locked}
                                itemList={customers}
                                selectedValue={delaySelectedValue ? null : editCustomerGuid}
                                onSelection={this.updateCustomer}
                                placeholderText={formatMessage(messages.customerName)}
                                keyProp={SearchAPI.Props.CUSTOMER_GUID}
                                nameProp="customerName"
                                secondaryPropList={["city", "state"]}
                                titlePropList={["customerName", "street", "city", "state"]}
                                noOptionsRenderer={this.noOptionsRenderer}
                                onSearchChange={this.onSearchChange}
                                autoFocus
                                openOnFocus={false}
                            />
                            {this.hideAgvanceCustomers() ? null : (
                                <SelectInput
                                    disabled={locked || this.state.ignoreFarm}
                                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                    clearFilterInputOnBlur={false}
                                    placeholderText={formatMessage(messages.agvanceCustomer)}
                                    value={agvanceCustomerGuid}
                                    onChange={(v) => this.onAgvanceCustomerChange(v)}
                                    options={agvanceOrgList.map(
                                        ({
                                            agvanceGrowerName,
                                            agvanceCustomerGuid,
                                            orgLevelName,
                                            alternateCustomerIdSelecting,
                                        }) => ({
                                            label: `${agvanceGrowerName} > ${orgLevelName}`,
                                            titleValue: `${agvanceGrowerName} - ${alternateCustomerIdSelecting}`,
                                            value: agvanceCustomerGuid,
                                        })
                                    )}
                                    optionRenderer={optionRendererWithCustomTitle}
                                    required
                                />
                            )}
                            <SelectInput
                                optionIsHiddenKey={ACTIVE_YN}
                                disabled={locked || this.state.ignoreFarm}
                                clearFilterInputOnBlur={false}
                                options={this.state.customerFarmOptions}
                                onChange={this.updateFarmName}
                                onInputChange={this.updateFarmName}
                                placeholderText={formatMessage(messages.farmNamePlaceholderText)}
                                value={editFarmName}
                                noOptionsRenderer={null}
                                initialFilterStr={editFarmName}
                                allowEmptyOptions
                            />
                            <SearchAgvanceField
                                value={editFieldName}
                                onChange={this.updateFieldName}
                                onInputChange={this.updateFieldName}
                                records={this.getAgvanceFieldList()}
                                disabled={locked}
                            />
                            <div className="edit-field-modal-body-message">
                                <div>
                                    {record.fieldGuid || record.importFieldBoundaryGuidList ? (
                                        <span>{formatMessage(messages.editBoundaryMessage)}</span>
                                    ) : (
                                        <div>
                                            <span className="red-star">*</span>
                                            <span>
                                                {formatMessage(messages.addBoundaryMessage)}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogBox>
                    <DialogBox
                        className="edit-field-area-warning"
                        title={formatMessage(messages.errorAboveMaxAcreageTitle)}
                        isOpen={this.state.isMaxAreaModalOpen}
                        onAction={() => this.closeMaxAreaDialog()}
                        onClose={() => this.closeMaxAreaDialog()}
                    >
                        {formatMessage(messages.errorAboveMaxAcreage, {
                            maxAcres: maximumArea,
                        })}
                    </DialogBox>
                    <DialogBox
                        title={formatMessage(messages.noValidPolygonsTitle)}
                        footerType={DialogBoxFooterType.YES_NO}
                        isOpen={this.state.isConfirmationModalOpen}
                        onAction={() => this.confirmYes()}
                        onClose={() => this.closeConfirmDialog()}
                    >
                        {formatMessage(messages.noValidPolygonsMsg)}
                    </DialogBox>
                </div>
                {record && record.fieldGuid && (
                    <FieldResurfacePrompt {...fieldResurfacePromptProps} />
                )}
            </div>
        );
    }
}
