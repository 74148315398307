import React from "react";
import { Toolbar, ToolbarMenu, ToolbarTool } from "~/core";

import { IconType, ToolbarIcon } from "../../../assets/toolbar-icon";
import { BaseToolbar, MapConfig, NonFieldFeatureTools } from "@ai360/core";

export class NonFieldFeatureDrawTools extends BaseToolbar {
    constructor(map, options, onSetGeometries) {
        super(map, { ...options, onSetActiveMapTool: () => {} });

        this._editor = new NonFieldFeatureTools.Editor(
            map,
            this.getLayer.bind(this),
            () => this.menu,
            this.moveLayersToTop.bind(this),
            onSetGeometries,
            (params) => this.activateTool(params, this.activateTool)
        );

        this._disabled = false;
    }

    editFeature(feature) {
        this._editor.setFeature(feature);
    }

    activateTool({ tool }) {
        this._editor.activateTool(tool);
    }

    activateToolset() {
        if (this._disabled) {
            this.reset();
            return;
        }

        if (this.activeTool) {
            this._editor.activateTool(this.activeTool);
        }
    }

    clear() {
        return this.reset().then(this._editor.clear.bind(this._editor));
    }

    canDelete() {
        return this._editor.isEditingObject();
    }

    delete() {
        this._editor.attemptDeleteEditingObject();
    }

    destroy() {
        this._editor.destroy();
        this.reset().then(() =>
            Object.keys(this).forEach((key) => {
                this[key] = null;
            })
        );
    }

    getDimensions() {
        return {
            top: 0,
            left: 70 * 3,
            leftAndRight: true,
            width: 10 + 70 * this._editor.allTools.length,
            height: 51,
        };
    }

    getTools() {
        return this._editor.allTools.map((tool) => tool.type);
    }

    canRedo() {
        return this._editor.canForwardHistory();
    }

    redo() {
        if (!this.canRedo()) {
            return;
        }

        this._editor.forwardHistory();
    }

    canUndo() {
        return this._editor.canReverseHistory();
    }

    undo() {
        if (!this.canUndo()) {
            return;
        }

        this._editor.reverseHistory();
    }

    reset(isRenderRequest = false) {
        return this._editor.reset(isRenderRequest);
    }

    resetTools() {
        setTimeout(() => this._editor.deselectTools());
    }

    setToolbarMenuInset(inset) {
        return this._editor.setToolbarMenuInset(inset);
    }

    getToolbar(disabled = false) {
        const toolsDisabled = this._editor.mapScale() > MapConfig.layers.fields.minScale;
        if ((disabled || toolsDisabled) !== this._disabled) {
            this.reset(true);
        }
        this._disabled = disabled;

        return this._render(toolsDisabled);
    }

    _render(toolsDisabled) {
        const { messages, formatMessage } = this.options;

        const onToggle = (e) => {
            this._editor.toggleTool(e.index);
        };

        return (
            <ToolbarMenu
                key="draw"
                icon={ToolbarIcon(IconType.DRAW)}
                disabled={this._disabled}
                label={formatMessage(messages.drawToolsLbl)}
                ref={(menu) => (this.menu = menu)}
            >
                <Toolbar>
                    <ToolbarTool
                        key={0}
                        disabled={toolsDisabled}
                        icon={ToolbarIcon(IconType.POLYGON)}
                        label={formatMessage(messages.drawPolygonLbl)}
                        onToggle={onToggle}
                        ref={(tool) => (this._editor.polygonTool.ui = tool)}
                    />
                    <ToolbarTool
                        key={1}
                        disabled={toolsDisabled}
                        icon={ToolbarIcon(IconType.CIRCLE)}
                        label={formatMessage(messages.drawCircleLbl)}
                        onToggle={onToggle}
                        ref={(tool) => (this._editor.circleTool.ui = tool)}
                    />
                    <ToolbarTool
                        key={2}
                        disabled={toolsDisabled}
                        icon={ToolbarIcon(IconType.ADD_POINT)}
                        label={formatMessage(messages.drawPointLbl)}
                        onToggle={onToggle}
                        ref={(tool) => (this._editor.pointTool.ui = tool)}
                    />
                </Toolbar>
            </ToolbarMenu>
        );
    }
}
