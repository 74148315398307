import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { Menu } from "~/core";
import { messages } from "../../../context-menus/i18n-messages";

import * as selectors from "../layer-list/non-field-data/selectors";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { actions as accordionActions } from "~/accordion";

const nonFieldDataLayersContextMenu_ = (props) => {
    const {
        possibleExpandedCount,
        expandedCount,
        possibleHighlightedCount,
        highlightedCount,
        accordionId,
    } = props;
    const { formatMessage } = props.intl;
    const menuItems = [
        {
            disabled: expandedCount === possibleExpandedCount,
            label: formatMessage(messages.expandAll),
            action: () => props.expandAll(accordionId),
        },
        {
            disabled: expandedCount === 0,
            label: formatMessage(messages.collapseAll),
            action: () => props.collapseAll(accordionId),
        },
        {
            disabled: highlightedCount === possibleHighlightedCount,
            label: formatMessage(messages.selectAll),
            action: () => props.selectAll(),
        },
        {
            disabled: highlightedCount === 0,
            label: formatMessage(messages.clearSelected),
            action: () => props.clearSelected(),
        },
    ].map((menuItem, key) => {
        return { ...menuItem, key };
    });

    return (
        <div className="context-menu-container" onClick={(evt) => evt.preventDefault()}>
            <Menu className="context-menu" isDotMenu={true} getMenuItems={() => menuItems} />
        </div>
    );
};
const mapDispatchToProps = (dispatch) => ({
    expandAll: (accordionId) =>
        dispatch(accordionActions.expandAllAccordionItems(accordionId, [1])),
    collapseAll: (accordionId) =>
        dispatch(accordionActions.collapseAllAccordionItems(accordionId, [1])),
    modifyHighlightedNonFieldFeatures: (modifications) =>
        dispatch(cdActions.modifyHighlightedNonFieldFeatures(modifications)),
});

const mapStateToProps = (state) => {
    const items = selectors.getItems(state);
    const expandedItems = items.filter((item) => item.expanded);
    const selectedFeatures = cdSelectors.getSelectedNonFieldFeatures(state);
    const highlightedSelectedFeatures = cdSelectors
        .getHighlightedNonFieldFeatures(state)
        .intersect(selectedFeatures);

    return {
        possibleExpandedCount: items.length,
        expandedCount: expandedItems.length,
        possibleHighlightedCount: items.flatMap((item) => item.children).length,
        highlightedCount: highlightedSelectedFeatures.count(),
        selectedFeatures,
        accordionId: selectors.getId(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { selectedFeatures, ...useStateProps } = stateProps;
    const { modifyHighlightedNonFieldFeatures, ...useDispatchProps } = dispatchProps;
    const useOwnProps = ownProps;

    return {
        ...useStateProps,
        ...useDispatchProps,
        ...useOwnProps,
        selectAll: () => modifyHighlightedNonFieldFeatures({ add: selectedFeatures }),
        clearSelected: () => modifyHighlightedNonFieldFeatures({ remove: selectedFeatures }),
    };
};

export const NonFieldDataLayersContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(nonFieldDataLayersContextMenu_));
