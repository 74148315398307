import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// Components
import { Checkbox, NumericInput, Section, SelectInput, SubSection, TextInput } from "~/core";
// constants
import { adminData, ID, PICKLIST_GUID, VALUE } from "~/admin/data";
import * as picklistService from "~/core/picklist/picklist-names";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        onChange: PropTypes.func,
        record: CustomPropTypes.gene,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.growthStage = {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupGrowthStageRecord();
        this.props.setBreadcrumbs([""]);
        const { needs, recordGuid } = this.props;
        needs([this.props.getNextId(), this.props.fetchPicklistData()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.growthStage);
        }

        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.growthStage = nextProps.record;
            }
        }

        this.initializeGrowthStageGroupType(nextProps);
    }

    initializeGrowthStageGroupType = (nextProps) => {
        if (
            this.growthStage[model.PROPS_GROWTH_STAGE_GROUP_TYPE] != null &&
            nextProps[picklistService.PICKLIST_GROWTH_STAGE_GROUP_TYPE]
        ) {
            this.setState({
                [picklistService.PICKLIST_GROWTH_STAGE_GROUP_TYPE]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_GROWTH_STAGE_GROUP_TYPE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.growthStage[model.PROPS_GROWTH_STAGE_GROUP_TYPE]
                ),
            });
        }
    };
    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.growthStage[model.PROPS_GROWTH_STAGE_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupGrowthStageRecord = () => {
        const { addEditPanel } = this.props;
        this.growthStage = {};
        if (addEditPanel.mode === "ADD") {
            this.growthStage = service.getDefaultRecord();
        }
    };

    onTextChange = (formKey, value, callback) => {
        this.growthStage = onTextChange(this.growthStage, { formKey: [formKey], value }, callback);
    };

    onNumberChange = (formKey, value, callback) => {
        this.growthStage = onNumberChange(
            this.growthStage,
            { formKey: [formKey], value },
            callback
        );
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.growthStage = handlePicklistChange(this.growthStage, { type, guid, value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.growthStage[model.PROPS_GROUP_NAME]]);
    };

    renderGrowStageInfo = () => {
        const { apiErrors, userRole } = this.props;
        const { formatMessage } = this.props.intl;
        const { growthStage } = this;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            placeholderText={formatMessage(messages.growthStageGroupID)}
                            labelText={formatMessage(messages.growthStageGroupID)}
                            containerClassNames={getAgBytesErrorClassNames([162, 163], apiErrors, [
                                "form-input-id",
                            ])}
                            value={"" + growthStage[model.PROPS_GROWTH_STAGE_ID]}
                            onChange={(value) =>
                                this.onNumberChange(model.PROPS_GROWTH_STAGE_ID, value)
                            }
                            required
                        />
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={growthStage[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(161, apiErrors)}
                            placeholderText={formatMessage(messages.growthStageGroupName)}
                            labelText={formatMessage(messages.growthStageGroupName)}
                            value={growthStage[model.PROPS_GROUP_NAME]}
                            autoFocus
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_GROUP_NAME, value, this.setHeaderText)
                            }
                            required
                        />
                        <SelectInput
                            tabIndex={0}
                            required
                            containerClassNames={getAgBytesErrorClassNames([160, 522], apiErrors)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_GROWTH_STAGE_GROUP_TYPE]}
                            placeholderText={formatMessage(messages.growthStageGroupType)}
                            value={mapToPicklistValue({
                                options:
                                    this.state[picklistService.PICKLIST_GROWTH_STAGE_GROUP_TYPE],
                                selectedGuid: this.growthStage[model.PROPS_GROWTH_STAGE_GROUP_TYPE],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_GROWTH_STAGE_GROUP_TYPE,
                                        guid: model.PROPS_GROWTH_STAGE_GROUP_TYPE,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        return <div className="add-edit-panel">{this.renderGrowStageInfo()}</div>;
    }
}

export default injectIntl(AddEditPanel);
