import * as actions from "./actions";

export const sampleSoilInitialState = {
    autoTraceDefault: undefined,
    showProductivityRating: false,
    userDepthConfig: null,
    userGridSettings: null,
};

export const sampleSoilReducer = (state = sampleSoilInitialState, action) => {
    switch (action.type) {
        //This is to be used when the back end and database is updated to accomdate autoTrace as a user preference
        //in the same way the user depth config is setup
        case actions.SET_AUTO_TRACE_DEFAULT: {
            const { autoTraceDefault } = action.payload;
            return Object.freeze({ ...state, autoTraceDefault });
        }
        case actions.SET_SHOW_PRODUCTIVITY_RATING: {
            const { showProductivityRating } = action.payload;
            return Object.freeze({ ...state, showProductivityRating });
        }
        case actions.SET_USER_DEPTH_CONFIG: {
            const { userDepthConfig } = action.payload;
            return Object.freeze({ ...state, userDepthConfig });
        }
        case actions.SET_USER_GRID_SETTINGS: {
            const { userGridSettings } = action.payload;
            return Object.freeze({ ...state, userGridSettings });
        }
        default:
            return state;
    }
};
