import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { DialogBox, DialogBoxFooterType, Checkbox, NoLink, Section } from "~/core";
import { actions as notificationActions } from "~/notifications";

import { messages } from "../i18n-messages";

import "./report-page-selection.css";

interface IReportPageSelectionProps {
    isOpen: boolean;
    onChange: any;
    onClose: any;
    reportTypePages: any[];
    reportTypePagesPrefs: any;
    selectedReportTypes: any[];
    selectedReportTypePages: any;
    title: string;
    userGuid: string;
}

interface IReportPageSelectionState {
    pagesDisplay: any;
    saveInvalid: boolean;
}

class ReportPageSelection_ extends Component<
    IReportPageSelectionProps & InjectedIntlProps,
    IReportPageSelectionState
> {
    constructor(props) {
        super(props);

        this.state = {
            pagesDisplay: {},
            saveInvalid: false,
        };
    }

    _createReportTypePageDisplayObject(
        reportTypePages,
        selectedReportTypes,
        selectedReportTypePages
    ) {
        const pagesDisplay = reportTypePages.reduce((a, c) => {
            if (selectedReportTypes.indexOf(c.reportTypeName) > -1) {
                if (Object.keys(a).indexOf(c.reportTypeName) === -1) {
                    a[c.reportTypeName] = {
                        displayName: c.reportTypeDisplayName || c.reportTypeName,
                        pages: {},
                        show: true,
                    };
                }
                a[c.reportTypeName].pages[c.id] = {
                    displayName: c.displayName,
                    show: Object.hasOwn(selectedReportTypePages, c.reportTypeName)
                        ? selectedReportTypePages[c.reportTypeName].indexOf(c.id) > -1
                        : true,
                };
            }
            return a;
        }, {});
        return pagesDisplay;
    }

    _onSave() {
        const { pagesDisplay } = this.state;
        const { selectedReportTypes } = this.props;

        const selectedReportTypePages = {};
        let saveInvalid = false;
        selectedReportTypes.forEach((st) => {
            if (
                Object.hasOwn(pagesDisplay, st) &&
                (!pagesDisplay[st].show ||
                    Object.keys(pagesDisplay[st].pages).every(
                        (pk) => !pagesDisplay[st].pages[pk].show
                    ))
            ) {
                saveInvalid = true;
                return;
            }
            if (
                Object.hasOwn(pagesDisplay, st) &&
                Object.keys(pagesDisplay[st].pages).some((pk) => !pagesDisplay[st].pages[pk].show)
            ) {
                selectedReportTypePages[st] = Object.keys(pagesDisplay[st].pages).filter(
                    (stpk) => pagesDisplay[st].pages[stpk].show
                );
            } else {
                selectedReportTypePages[st] = null;
            }
        });

        if (saveInvalid) {
            this.setState({ saveInvalid });
            return;
        }

        const reportTypePagesPrefs = {
            ...this.props.reportTypePagesPrefs,
            ...this.props.selectedReportTypePages,
            ...selectedReportTypePages,
        };
        Object.keys(selectedReportTypePages)
            .filter((k) => selectedReportTypePages[k] === null)
            .forEach((nullKey) => {
                delete reportTypePagesPrefs[nullKey];
            });
        this.props.onChange(reportTypePagesPrefs);
    }

    _onOpen() {
        const selectedReportTypePages = {
            ...this.props.reportTypePagesPrefs,
            ...this.props.selectedReportTypePages,
        };
        Object.keys(selectedReportTypePages)
            .filter((k) => !Object.hasOwn(this.props.selectedReportTypePages, k))
            .forEach((nullKey) => {
                delete selectedReportTypePages[nullKey];
            });
        this._resetState(selectedReportTypePages);
    }

    _resetState(selectedReportTypePages) {
        this.setState({
            pagesDisplay: this._createReportTypePageDisplayObject(
                this.props.reportTypePages,
                this.props.selectedReportTypes,
                selectedReportTypePages || {}
            ),
        });
    }

    _resetToDefaults() {
        this._resetState(this.props.reportTypePagesPrefs);
    }

    render() {
        const { isOpen, letterIcon } = this.props;
        const { formatMessage } = this.props.intl;

        const { pagesDisplay } = this.state;

        return (
            <React.Fragment>
                <DialogBox
                    className="report-page-selection-dialog"
                    draggable
                    isOpen={isOpen}
                    footerType={DialogBoxFooterType.NONE}
                    letterIcon={letterIcon}
                    onClose={() => this._onSave()}
                    onOpen={() => this._onOpen()}
                    title={this.props.title}
                >
                    <Section>
                        {Object.keys(pagesDisplay).map((rk) => (
                            <div className="report-page-selection-report" key={rk}>
                                <Checkbox
                                    label={pagesDisplay[rk].displayName}
                                    onChange={(e, value) => {
                                        pagesDisplay[rk].show = value;
                                        Object.keys(pagesDisplay[rk].pages).forEach((pk) => {
                                            pagesDisplay[rk].pages[pk].show = value;
                                        });
                                        this.setState({ pagesDisplay });
                                    }}
                                    value={pagesDisplay[rk].show}
                                />
                                <div className="report-page-selection-pages">
                                    {Object.keys(pagesDisplay[rk].pages).map((pk) => (
                                        <Checkbox
                                            key={pk}
                                            label={pagesDisplay[rk].pages[pk].displayName}
                                            onChange={(e, value) => {
                                                pagesDisplay[rk].pages[pk].show = value;
                                                if (value) {
                                                    pagesDisplay[rk].show = value;
                                                }
                                                this.setState({
                                                    pagesDisplay,
                                                });
                                            }}
                                            value={pagesDisplay[rk].pages[pk].show}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Section>
                    <Section>
                        <NoLink
                            className="reset"
                            label={formatMessage(messages.resetToDefault)}
                            onClick={() => {
                                this._resetToDefaults();
                            }}
                        />
                    </Section>
                </DialogBox>
                <DialogBox
                    isOpen={this.state.saveInvalid}
                    isModal={true}
                    draggable={true}
                    onClose={() => this.setState({ saveInvalid: false })}
                    title={formatMessage(messages.invalidPageSelection)}
                    footerType={DialogBoxFooterType.NONE}
                >
                    {formatMessage(messages.invalidPageSelectionMessage)}
                </DialogBox>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

export const ReportPageSelection = connect(
    null,
    mapDispatchToProps
)(injectIntl(ReportPageSelection_));
