import React from "react";
import PropTypes from "prop-types";

import { LayerUtilsAPI } from "@ai360/core";
import { MapConfig } from "@ai360/core";
import { messages } from "./components/i18n-messages";

export const LayerTypeShape = PropTypes.oneOf(
    Object.keys(LayerUtilsAPI.LayerType).map((k) => LayerUtilsAPI.LayerType[k])
);

export const canReclassifyRecSurface = (surface) => {
    const isEquationRec = surface.equationGuid != null;
    const isSingleValue = isAllSingleValueClasses(surface);
    const isAllZeroOrSingleWithZero =
        isSingleValue && surface.classBreaks[0].upperBound === 0 && surface.classBreaks.length <= 2;
    return isEquationRec && !isAllZeroOrSingleWithZero;
};

export const colorOptionRenderer = (item) => (
    <div className="color-option" style={{ backgroundImage: item.option.background }}>
        &nbsp;
    </div>
);

export const getAvailableFieldGuidSet = (
    selectedFieldGuidSet,
    lockCustomersNotEnrolled,
    enrolledFieldGuids
) => {
    return lockCustomersNotEnrolled
        ? selectedFieldGuidSet.filter((fieldGuid) => enrolledFieldGuids.has(fieldGuid))
        : selectedFieldGuidSet;
};

export const getBackgroundGradientStyle = (colorInfo, isManual = false) => {
    if (isManual) {
        const { hexCodes, endBlue, endGreen, endRed, startBlue, startGreen, startRed } = colorInfo;
        if (hexCodes != null) {
            return `linear-gradient(to right${hexCodes})`;
        }
        const start = `rgb(${startRed},${startGreen},${startBlue})`;
        const end = `rgb(${endRed},${endGreen},${endBlue})`;
        return `linear-gradient(to right, ${start}, ${end})`;
    }
    const { classBreaks } = colorInfo;
    const colors = classBreaks.map((cb) => `#${cb.color.hexCode}`);
    return `linear-gradient(to right, ${colors.join(", ")})`;
};

export const getLayerTypeLetter = (layerType, formatMessage) => {
    switch (layerType) {
        case LayerUtilsAPI.LayerType.ANALYSIS:
        case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
            return formatMessage(messages.layerTypeAnalysis);
        case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
        case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
        case LayerUtilsAPI.LayerType.EVENT_MANUAL:
            return formatMessage(messages.layerTypeEvent);
        case LayerUtilsAPI.LayerType.REC:
            return formatMessage(messages.layerTypeRec);
        case LayerUtilsAPI.LayerType.SOIL:
            return formatMessage(messages.layerTypeSoil);
        case LayerUtilsAPI.LayerType.IMAGERY:
            return formatMessage(messages.layerTypeImagery);
        default:
            return null;
    }
};

/**
 * returns a valid string for use with custom rgba() css styles
 * @param {number[]} colorArray [255, 255, 255, 255]
 */
export const getRgba = (colorArray) => {
    const colors = [...colorArray];
    if (colorArray.length === 3) {
        colors.push(255);
    }
    return [...colors.slice(0, -1), colors.slice(-1)[0] / 255.0].join(",");
};

/**
 * gets a css style that matches the current configured sample site markers
 */
export const getSampleSitesMarkerStyle = () => {
    const ss = MapConfig.symbols.sampleSites.default;
    return {
        backgroundColor: `rgba(${getRgba(ss.color)})`,
        border: `${ss.outline.width}px solid rgba(${getRgba(ss.outline.color)})`,
        boxShadow: `0 0 1px rgba(${getRgba(ss.outline.color)})`,
    };
};

/**
 * returns null when either the surface or its classBreaks do not exist or are an empty array
 * returns true when all of the surface classBreak upperbounds and lowerbounds have the same value, meaning there is really ony one classbreak
 * returns false when all of the surface classBreak upperbounds and lowerbounds do not have the same value
 *
 * @param surface for layer whose properties will be edited
 */
export const isAllSingleValueClasses = (surface) => {
    if (surface == null || surface.classBreaks == null || surface.classBreaks.length === 0) {
        return null;
    } else {
        return surface.classBreaks.every((b) => b.upperBound === b.lowerBound);
    }
};

/**
 * Takes the result from an API call to getLayerFilterOptions and converts it to a LayerFilterInfo object
 * @param filterOptions - object returned from an API call to getLayerFilterOptions
 * TODO: create a class for the object returned from the API and add this as a method
 */
export const apiFilterOptionsToLayerFilterInfo = (filterOptions) => {
    const uniqueCSs = new Map(
        filterOptions.croppingSeasons.map((cs) => [`${cs.croppingSeason}`, cs.croppingSeasonGuid])
    );
    const croppingSeasonsMap = new Map();
    for (const [croppingSeason, croppingSeasonGuid] of uniqueCSs.entries()) {
        const fieldGuids = filterOptions.croppingSeasons
            .filter((cs) => cs.croppingSeasonGuid === croppingSeasonGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filterOptions.all.filter(
            (surface) => surface.croppingSeasonGuid === croppingSeasonGuid
        );
        croppingSeasonsMap.set(croppingSeasonGuid, {
            croppingSeason,
            fieldGuids,
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
            layerTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }

    const uniqueLTs = new Set(filterOptions.layerTypes.map((lt) => lt.layerType));
    const layerTypesMap = new Map();
    for (const layerType of uniqueLTs.values()) {
        const fieldGuids = filterOptions.layerTypes
            .filter((lt) => lt.layerType === layerType)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filterOptions.all.filter((surface) => surface.layerType === layerType);
        layerTypesMap.set(layerType, {
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
        });
    }
    const uniqueCs = new Map(filterOptions.crops.map((crop) => [crop.crop, crop.cropGuid]));
    const cropsMap = new Map();
    for (const [crop, cropGuid] of uniqueCs.entries()) {
        const fieldGuids = filterOptions.crops
            .filter((c) => c.cropGuid === cropGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = filterOptions.all.filter((surface) => surface.cropGuid === cropGuid);
        cropsMap.set(cropGuid, {
            crop,
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            layerTypes: new Set(filter.map((lt) => lt.layerType).filter(Boolean)),
        });
    }
    return {
        crops: cropsMap,
        croppingSeasons: croppingSeasonsMap,
        layerTypes: layerTypesMap,
        filters: filterOptions,
    };
};

/**
 * Takes the result from an API call to getLayerFilterOptions and converts it to a LayerFilterOptions object
 * @param filterOptions - object returned from an API call to getLayerFilterOptions
 * TODO: create a class for the object returned from the API and add this as a method
 */

export const filterLayerOptions = (layerFilter, croppingSeasons, crops, filters, layerTypes) => {
    const cropOptions = [];
    const croppingSeasonOptions = [];
    const layerTypeOptions = [];
    if (filters && Object.keys(filters).length > 0) {
        const chkFilter = (list, guid, cat, filter) =>
            filter == null ||
            filter.length === 0 ||
            (list.has(guid) && list.get(guid)[cat].has(filter));

        const fcs = filters.croppingSeasons.filter((cs) => {
            return (
                chkFilter(
                    croppingSeasons,
                    cs.croppingSeasonGuid,
                    "layerTypes",
                    layerFilter.layerType
                ) &&
                chkFilter(croppingSeasons, cs.croppingSeasonGuid, "cropGuids", layerFilter.cropGuid)
            );
        });
        const uniqueCSs = new Map(fcs.map((cs) => [cs.croppingSeasonGuid, `${cs.croppingSeason}`]));
        croppingSeasonOptions.push(
            ...Array.from(uniqueCSs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        croppingSeasonOptions.sort((a, b) => a.label - b.label).reverse();

        const flt = filters.layerTypes.filter((lt) => {
            return (
                chkFilter(
                    layerTypes,
                    lt.layerType,
                    "croppingSeasonGuids",
                    layerFilter.croppingSeasonGuid
                ) && chkFilter(layerTypes, lt.layerType, "cropGuids", layerFilter.cropGuid)
            );
        });
        const uniqueLTs = new Set(flt.map((lt) => lt.layerType));
        layerTypeOptions.push(
            ...Array.from(uniqueLTs.values()).map((label) => ({
                label,
                value: label,
            }))
        );
        layerTypeOptions.sort();

        const fc = filters.crops.filter((c) => {
            return (
                chkFilter(
                    crops,
                    c.cropGuid,
                    "croppingSeasonGuids",
                    layerFilter.croppingSeasonGuid
                ) && chkFilter(crops, c.cropGuid, "layerTypes", layerFilter.layerType)
            );
        });
        const uniqueCs = new Map(fc.map((crop) => [crop.cropGuid, crop.crop]));
        cropOptions.push(
            ...Array.from(uniqueCs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        cropOptions.sort();
    }
    return {
        cropOptions,
        croppingSeasonOptions,
        layerTypeOptions,
    };
};

export const isImportedHarvest = (event) => {
    return event.agEventTypeName === "Harvest" && event.isImportedYn;
};
