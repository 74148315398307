import * as actions from "./actions";
import { defaultSelectedBasemap } from "./constants";
import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import { IFileNameFormat, IReportType, IReportTypePage, IYieldByCategory } from "./interfaces";
import { ReportAPI } from "@ai360/core";

interface ReducerAction {
    cropDropdown: ICrop[];
    defaultFileNameFormat: string;
    defaultMultiFieldRec: boolean;
    fileNameFormatList: IFileNameFormat[];
    pageList: IReportTypePage[];
    payload: any;
    reportPreferences: any;
    reportStatus: string[];
    type: string;
    typeList: IReportType[];
    yieldByCompareByOptions: IYieldByCategory;
    fields: ReportAPI.IField[];
}

const initialState = {
    cropDropdown: [],
    typeList: [],
    yieldCategories: {},
    yieldByCompareByOptions: {},
    reportStatus: [],
    defaultFileNameFormat: null,
    defaultMultiFieldRec: false,
    fileNameFormatList: [],
    reportPreferences: {
        ageOfSample: "3",
        breakEvenCropList: [],
        fieldLabelConfig: null,
        reportTypePagesPref: null,
        useCropPlanningSummary: false,
        useEventIdBarcode: false,
        useOverviewMap: true,
        useProductRecs: false,
        selectedBasemap: defaultSelectedBasemap,
        showNonFieldFeature: false,
        showGuaranteedAnalysis: false,
        showLoadsheetProductDetail: false,
        showPhotos: false,
        showProductRecCostDetail: false,
        showProductRecSummaryCostDetail: false,
        showRecSummary: false,
        useImportDate: false,
        showScoutingDetails: true,
    },
    fields: [],
};

export function reportsReducer(
    state = initialState,
    action: ReducerAction = {
        cropDropdown: [],
        defaultFileNameFormat: null,
        defaultMultiFieldRec: false,
        fileNameFormatList: [],
        pageList: [],
        payload: null,
        reportPreferences: {
            ageOfSample: "3",
            breakEvenCropList: [],
            fieldLabelConfig: null,
            reportTypePagesPref: null,
            useCropPlanningSummary: false,
            useEventIdBarcode: false,
            useOverviewMap: true,
            useProductRecs: false,
            selectedBasemap: defaultSelectedBasemap,
            showNonFieldFeature: false,
            showGuaranteedAnalysis: false,
            showLoadsheetProductDetail: false,
            showPhotos: false,
            showProductRecCostDetail: false,
            showProductRecSummaryCostDetail: false,
            showRecSummary: false,
            useImportDate: false,
            showScoutingDetails: true,
        },
        reportStatus: [],
        type: null,
        typeList: [],
        yieldByCompareByOptions: {} as IYieldByCategory,
        fields: [],
    }
): Record<string, unknown> {
    switch (action.type) {
        case actions.REPORTS_GET_CROP_DROPDOWN_SUCCESS:
            return {
                ...state,
                cropDropdown: action.payload,
            };
        case actions.REPORTS_GET_FILE_NAME_FORMAT_SUCCESS:
            return {
                ...state,
                fileNameFormatList: action.payload,
            };
        case actions.REPORTS_GET_REPORT_PREF_SUCCESS:
            return {
                ...state,
                reportPreferences: action.payload,
            };
        case actions.REPORTS_GET_REPORT_TYPE_SUCCESS:
            return {
                ...state,
                typeList: action.payload,
            };
        case actions.REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS:
            return {
                ...state,
                pageList: action.payload,
            };
        case actions.REPORTS_GET_USER_FILE_FORMAT_SUCCESS:
            return {
                ...state,
                defaultFileNameFormat: action.payload,
            };
        case actions.REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS:
            return {
                ...state,
                defaultMultiFieldRec: action.payload,
            };
        case actions.REPORTS_GET_YIELD_CATEGORY_SUCCESS:
            return {
                ...state,
                yieldCategories: action.payload,
            };
        case actions.REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS:
            return {
                ...state,
                yieldByCompareByOptions: action.payload,
            };
        case actions.REPORTS_CREATE_REPORT_HUB_SUCCESS: {
            return {
                ...state,
                reportStatus: action.payload,
            };
        }
        case actions.REPORTS_CLEAR_REPORT_HUB_DATA: {
            return {
                ...state,
                reportStatus: [],
            };
        }
        case actions.REPORTS_SET_FIELDS: {
            return {
                ...state,
                fields: action.payload.fields,
            };
        }
        default:
            return state;
    }
}
