import { AgEventAPI } from "@ai360/core";

export class ProductMix implements AgEventAPI.IProductMix {
    public isImport: boolean | null;
    public costPerAcre: number | null;
    public customProductGuid: string | null;
    public density: string | null;
    public densityUnitGuid: string | null;
    public guaranteedAnalysis: string | null;
    public isCarrier: boolean;
    public isLockGuaranteedAnalysis: boolean;
    public isLockProductRatios: boolean;
    public nutrients: ProductMixNutrient[];
    public limeEfficiency: number | null;
    public physicalStateGuid: string | null;
    public targetCost: number | null;
    public targetRate: string | null;
    public targetRateUnit: string | null;
    public targetRateUnitGuid: string | null;
    public actualRate: string | null;
    public actualRateUnit: string | null;
    public actualRateIaGuid: string | null;
    public actualRateUnitGuid: string | null;
    public productMixGuid: string | null;
    public eventRecGuid: string | null;
    public name: string | null;
    public productBlendGuid: string | null;
    public productBlendName: string | null;
    public productMixId: string | null;
    public productMixType: string | null;
    public productMixTypeGuid: string | null;
    public products: ProductMixProduct[];
    public recProductMixGuid: string | null;
    public totalCost: number | null;
    public totalProduct: number | null;
    public targetCostUnitGuid: string | null;

    constructor(eventRecGuid = "") {
        this.isImport = false;
        this.density = "";
        this.densityUnitGuid = "";
        this.isCarrier = false;
        this.isLockGuaranteedAnalysis = false;
        this.isLockProductRatios = false;
        this.nutrients = [];
        this.physicalStateGuid = "";
        this.targetRate = "";
        this.targetRateUnit = "";
        this.targetRateUnitGuid = "";
        this.actualRate = "";
        this.actualRateUnit = "";
        this.actualRateUnitGuid = "";
        this.actualRateIaGuid = "";
        this.productMixGuid = "";
        this.eventRecGuid = eventRecGuid;
        this.name = "";
        this.productMixId = "";
        this.productMixType = "";
        this.productMixTypeGuid = "";
        this.products = [];
    }
}
export class ProductMixProduct implements AgEventAPI.IProductMixProduct {
    public costPerAcre: number | null;
    public productMixProductGuid: string | null;
    public productMixGuid: string | null;
    public productGuid: string | null;
    public productName: string | null;
    public customProductGuid: string | null;
    public customProductName: string | null;
    public density: number | null;
    public limeEfficiency: number | null;
    public percentOfMix: number | null;
    public physicalState: string | null;
    public productParentType: string | null;
    public isCarrier: boolean;
    public rate: number | null;
    public rateUnitGuid: string | null;
    public rateUnit: string | null;
    public totalCost: number | null;
    public totalProduct: number | null;
    public cost: number | null;
    public costUnitGuid: string | null;
    public costUnit: string | null;
    public nutrientList: any[] | null;
    public nutrients: any[] | null;
    constructor() {
        this.productMixProductGuid = null;
        this.productMixGuid = null;
        this.productGuid = null;
        this.productName = "";
        this.customProductGuid = null;
        this.customProductName = "";
        this.percentOfMix = 1;
        this.isCarrier = false;
        this.rate = 0;
        this.rateUnitGuid = null;
        this.rateUnit = "";
        this.cost = 0;
        this.costUnitGuid = null;
        this.costUnit = "";
        this.nutrientList = [];
    }
}
export class ProductMixNutrient implements AgEventAPI.IProductMixNutrient {
    public nutrientGuid: string | null = null;
    public nutrientPercent: string | null = null;
    public productMixGuid: string | null = null;
    public productMixNutrientGuid: string | null = null;
    public name?: string | null = null;
}
