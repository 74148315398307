import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { defineMessages } from "react-intl";

import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";

import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";

const messages = defineMessages({
    analysisFormSideBarText: {
        id: "layerModule.analysisInfo.formUnknownSideBarText",
        defaultMessage: "Analysis",
    },
});

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
]);

export const errorCodesApply = (errorCodeList = []) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisUnknownForm extends PureComponent {
    static propTypes = {
        analysisSummary: PropTypes.object,
        analysisDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    render() {
        return <div className="analysis-info-form"></div>;
    }
}
