import React, { ReactElement } from "react";

import { Toolbar, ToolbarButton, ToolbarMenu, ToolbarTool } from "~/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { DrawTools, GeometryMath, MapConfig } from "@ai360/core";

export class DrawToolSet extends DrawTools {
    getToolbar(disabled = false, includeClipTool = false): ReactElement {
        disabled = disabled || !this.customerClipBoundary;
        const { formatMessage } = this.options;
        this.includeClipTool = includeClipTool;

        const toolsDisabled = this.mapView.scale > MapConfig.layers.fields.minScale;

        if ((disabled || toolsDisabled) !== this.disabled) {
            this.toggleTool({ isRenderRequest: true, selected: false }, false);
        }
        this.disabled = disabled;

        const tools = [];
        tools.push(
            <ToolbarTool
                key={0}
                disabled={toolsDisabled}
                icon={ToolbarIcon(IconType.POLYGON)}
                label={formatMessage(this.messages.drawPolygonLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawPolygon = tool)}
            />,
            <ToolbarTool
                key={1}
                disabled={toolsDisabled}
                icon={ToolbarIcon(IconType.CIRCLE)}
                label={formatMessage(this.messages.drawCircleLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawCircle = tool)}
            />,
            <ToolbarTool
                key={2}
                disabled={toolsDisabled || this.geometries.length === 0}
                icon={ToolbarIcon(IconType.RESHAPE)}
                label={formatMessage(this.messages.drawReshapeLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawReshape = tool)}
            />,
            <ToolbarTool
                key={3}
                disabled={toolsDisabled || this.geometries.length === 0}
                icon={ToolbarIcon(IconType.SPLIT)}
                label={formatMessage(this.messages.drawSplitLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawSplit = tool)}
            />,
            <ToolbarTool
                key={4}
                disabled={toolsDisabled || this.geometries.length === 0}
                icon={ToolbarIcon(IconType.MOVE)}
                label={formatMessage(this.messages.drawNudgeLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawNudge = tool)}
            />,
            <ToolbarTool
                key={5}
                disabled={
                    toolsDisabled ||
                    this.selectedGeometry == null ||
                    GeometryMath.getTotalPolygonPartCount(this.geometries) <= 1
                }
                icon={ToolbarIcon(IconType.UNION)}
                label={formatMessage(this.messages.drawUnionLbl)}
                onToggle={(evt) => this.toggleTool(evt)}
                ref={(tool) => (this.drawUnion = tool)}
            />
        );
        if (this.includeClipTool) {
            tools.push(
                <ToolbarButton
                    key={6}
                    disabled={toolsDisabled || !this._canClip()}
                    icon={ToolbarIcon(IconType.CLIP)}
                    label={formatMessage(this.messages.drawClipLbl)}
                    onClick={() => this.clip()}
                />
            );
        }

        return (
            <ToolbarMenu
                key="draw"
                className="draw"
                icon={ToolbarIcon(IconType.DRAW)}
                disabled={this.disabled}
                label={formatMessage(this.messages.drawToolsLbl)}
                ref={(menu) => (this.menu = menu)}
            >
                <Toolbar className="draw-tools">{tools}</Toolbar>
            </ToolbarMenu>
        );
    }
}
