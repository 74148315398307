import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { AttributeEditModal } from "./attribute-edit-modal";
import { ImportFilter } from "../import-filter";
import { AutoSearch, Loader } from "~/core";
import { EquipmentProfileAPI } from "@ai360/core";
import { withMasked, withApiResult, withCrud } from "~/hocs";
import { SUCCESS, FAILURE, PENDING } from "~/hocs/needs/utils";
import { injectIntl, intlShape } from "react-intl";
import { ImportFilterTypes } from "../../../../../actions";
import { service, actions } from "~/admin/setup/equipment/data";
import { ADD } from "~/admin/data";
import { createAddLinkLabelText } from "~/i18n-messages";
import { messages } from "./i18n-messages";
import "./equipment-filter.css";

class ImportEquipmentFilter_ extends Component {
    static propTypes = {
        addEquipmentImportFilter: PropTypes.func,
        importFileGuidList: PropTypes.array,
        filterItems: PropTypes.array.isRequired,
        selectItem: PropTypes.func.isRequired,
        updateFilterStatus: PropTypes.func.isRequired,
        updateAttribute: PropTypes.func.isRequired,
        selectedFieldGuid: PropTypes.string,
        userInfo: PropTypes.object.isRequired,
        intl: intlShape.isRequired,
        needs: PropTypes.func,
        loadDetailPage: PropTypes.func,
        liftRecordData: PropTypes.func,
        status: PropTypes.string,
        actions: PropTypes.object,
        requestIds: PropTypes.object,
        importType: PropTypes.object,
        isLoading: PropTypes.bool,
        equipmentFilter: PropTypes.object,
        removeImportFiles: PropTypes.func,
        assignMatchedToUnmatched: PropTypes.func,
    };
    static defaultProps = {
        importFileGuidList: [],
        selectedFieldGuid: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            editItem: null,
            equipmentProfile: null,
            equipmentProfileGuid: null,
            searchValue: null,
        };
        this.addEditRequestId = null;
        this.addEditRequestCB = null;
    }

    filterProperties = [
        {
            isRequired: false,
            isReqException: false, //prop added to prevent a logic rewrite now that equipment is not required but still needs the No Match functionality which was previously based off isRequired alone (import-filter.js)
            label: this.props.intl.formatMessage(messages.profileNameText),
            propertyName: "equipmentProfile",
            propertyGuid: "equipmentProfileGuid",
            cellSize: 45,
        },
        {
            isRequired: false,
            label: this.props.intl.formatMessage(messages.machineIdText),
            propertyName: "monitorId",
            cellSize: 45,
        },
    ];

    getLabel = ({ options = [], guid = "" }, valueKey = "value", labelKey = "label") => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return guid === option[valueKey];
            });
            const label = selectedOption.length && selectedOption[0][labelKey];
            return label;
        }
        return null;
    };

    onEditAttribute = (item) => {
        this.setState({
            equipmentProfile: item.equipmentProfile,
            equipmentProfileGuid: item.equipmentProfileGuid,
        });
    };

    updateMachineSelection = (machine) => {
        if (!machine) {
            this.setState({
                equipmentProfile: null,
                equipmentProfileGuid: null,
            });
        } else {
            this.setState({
                equipmentProfile: machine.equipmentProfileName,
                equipmentProfileGuid: machine.equipmentProfileGuid,
            });
        }
    };

    onSaveEdit = () => {
        const item = {
            ...this.editItem,
            equipmentProfile: this.state.equipmentProfile,
            equipmentProfileGuid: this.state.equipmentProfileGuid,
            hasLookupMatch: true,
        };
        this.props.updateAttribute(item);
        this.onCloseEdit();
    };

    onCloseEdit = () => {
        this.editItem = null;
        this.setState({
            editItem: null,
            equipmentProfile: null,
            equipmentProfileGuid: null,
        });
    };

    canSave = () => {
        return Boolean(this.state.equipmentProfileGuid);
    };

    liftRecordData = (data = {}, cb) => {
        if (data && this.props.status !== PENDING) {
            this.setState({
                createEquipmentInProgress: true,
            });
            this.equipmentData = data;
            this.addEditRequestId = this.props.needs([this.props.addEquipmentImportFilter(data)]);
            this.addEditRequestCB = cb;
        }
    };

    // It shouldn't be autofocus when item is selected
    shouldAutoFocus = () => !this.canSave();

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requestIds && this.addEditRequestId) {
            if (nextProps.requestIds[this.addEditRequestId] === SUCCESS) {
                const { equipmentName, equipmentGuid } = nextProps.equipmentFilter.model;
                this.setState(
                    () => ({
                        equipmentProfile: equipmentName,
                        equipmentProfileGuid: equipmentGuid,
                    }),
                    () => this.onSaveEdit()
                );
                if (this.addEditRequestCB) {
                    this.addEditRequestCB(nextProps.equipmentFilter.model);
                }
                this.addEditRequestId = null;
            } else if (nextProps.requestIds[this.addEditRequestId] === FAILURE) {
                this.addEditRequestId = null;
                this.equipmentData = null;
            }
        }
    }

    onAddEquipment = () => {
        const { actions, importType, needs } = this.props;
        const { liftRecordData } = this;
        const { searchValue } = this.state;
        const editItem = _.cloneDeep(this.editItem);
        const props = {
            addEditPanel: {
                mode: ADD,
            },
            actions,
            equipmentProfile: searchValue || (editItem ? editItem.equipmentProfile : ""),
            controllerId: editItem ? editItem.monitorId : "",
            renderWithSlidingPanel: true,
            liftRecordData,
            needs,
            importType: importType,
        };
        this.props.loadDetailPage("2", "101", props);
        this.setState({ editItem: null });
    };

    handleAddEdit = (item) => {
        const { filterProperties, onAddEquipment } = this;
        this.editItem = item;
        const idProp = filterProperties[1].propertyName;
        if (item[idProp] && !item.hasMultipleMatch) {
            this.onEditAttribute(item);
            onAddEquipment();
            this.setState({ editItem: null });
            return;
        }
        this.setState({ editItem: {} });
    };

    addEditChecker = (item) => {
        const { filterProperties } = this;
        if (item[filterProperties[0].propertyName]) {
            return false;
        }
        return true;
    };

    noOptionsRenderer = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="person-search-no-option" onClick={this.onAddEquipment}>
                {createAddLinkLabelText(formatMessage, messages.equipment)}
            </div>
        );
    };

    getFilterItems = () => {
        return _.cloneDeep(this.props.filterItems);
    };

    getValidation = (item) => {
        const profileName = item.equipmentProfile;
        const monitorId = item.monitorId;
        const monitorIdIsEmpty = monitorId == null || monitorId === "";

        return (!profileName && monitorIdIsEmpty) || profileName;
    };

    assignToEquipmentProfiles(data, index) {
        const from = data.equipmentProfiles[index];

        const monitorIdAliases = data.equipmentProfiles
            .filter((x) => !x.hasLookupMatch)
            .map((x) => x.monitorId);

        from.monitorIdAliases = monitorIdAliases;
        const copy = (to) => ({
            ...from,
            importFiles: to.importFiles,
            hasLookupMatch: true,
            isSelected: to.isSelected,
            monitorId: to.monitorId,
            monitorIdAliases,
        });

        return {
            ...data,
            equipmentProfiles: data.equipmentProfiles.map((to) =>
                to.hasLookupMatch ? to : copy(to)
            ),
        };
    }

    _getMonitorId() {
        const item = this.getFilterItems();
        if (item != null && item.length > 0) {
            return item[0].monitorId;
        }
        return item;
    }

    _getAutoSearchList(userGuid, searchText, secondarySearchFilter) {
        return EquipmentProfileAPI.getEquipmentProfileAutoComplete(userGuid, {
            importType: secondarySearchFilter.importType,
            monitorId: secondarySearchFilter.monitorId,
            search: searchText === "_" ? "" : searchText,
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <ImportFilter
                    additionalValidation={(item) => this.getValidation(item)}
                    alterEditColumn
                    title={formatMessage(messages.equipmentFilterTitle)}
                    itemProperties={this.filterProperties}
                    filterItems={this.getFilterItems()}
                    filterTypeName={ImportFilterTypes.EQUIPMENT_PROFILES}
                    selectedFieldGuid={this.props.selectedFieldGuid}
                    selectItem={(item) => {
                        this.props.selectItem(item);
                    }}
                    onEditAttribute={(item) => {
                        this.onEditAttribute(item);
                    }}
                    updateFilterStatus={(isComplete) => this.props.updateFilterStatus(isComplete)}
                    onAddEquipment={this.onAddEquipment}
                    isEquipmentInfo
                    handleEquipmentAddEdit={this.handleAddEdit}
                    equipmentAddEditChecker={this.addEditChecker}
                    removeImportFiles={(importFileGuidList) =>
                        this.props.removeImportFiles(importFileGuidList)
                    }
                    assignMatchedToUnmatched={(index) =>
                        this.props.assignMatchedToUnmatched(index, this.assignToEquipmentProfiles)
                    }
                />
                {!this.state.editItem ? null : (
                    <AttributeEditModal
                        className="equipment-edit-modal"
                        title={formatMessage(messages.editEquipmentText)}
                        isOpen={true}
                        canSave={this.canSave()}
                        onSaveEdit={this.onSaveEdit}
                        onCloseEdit={this.onCloseEdit}
                    >
                        {this.state.isSaving ? <Loader /> : null}
                        <div className="edit-modal-row equipment-filter">
                            <AutoSearch
                                required={true}
                                autoFocus={this.shouldAutoFocus()}
                                itemList={[]}
                                initialFilterStr={this.state.editItem.equipmentProfile}
                                selectedValue={this.state.equipmentProfileGuid}
                                onSelection={(c) => this.updateMachineSelection(c)}
                                getAutoSearchList={this._getAutoSearchList}
                                userGuid={this.props.userInfo.userGuid}
                                placeholderText={formatMessage(messages.equipmentNameText)}
                                noOptionsRenderer={this.noOptionsRenderer}
                                onSearchChange={(searchValue) => this.setState({ searchValue })}
                                keyProp="equipmentProfileGuid"
                                nameProp="equipmentProfileName"
                                secondaryPropList={[
                                    "ownerOperatorCity",
                                    "ownerOperatorStateAbbreviation",
                                ]}
                                secondarySearchFilter={{
                                    importType: this.props.importType.name,
                                    monitorId: this._getMonitorId(),
                                }}
                            />
                        </div>
                    </AttributeEditModal>
                )}
            </div>
        );
    }
}

export const ImportEquipmentFilter = injectIntl(
    withMasked(
        withApiResult(withCrud(ImportEquipmentFilter_, service, actions), actions.importData)
    )
);
