import { AgEventAPI } from "@ai360/core";

export class AgEventFilterSummary {
    agEventGeneralGuid: string;
    stats: any;
    filterData: any;
    showFilterPoints: boolean;

    static fromJsonObj(jsonObj: AgEventAPI.IAgEventFilterSummary): AgEventFilterSummary {
        const { agEventGeneralGuid, stats, filterData } = jsonObj;

        const rv = {
            agEventGeneralGuid,
            stats,
            filterData,
            showFilterPoints: false,
        };

        return Object.freeze(rv);
    }
}
