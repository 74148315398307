import { combineReducers } from "redux";

import * as immutableUtils from "~/utils/immutable";

import { analysisInfoReducer } from "./analysis/reducer";
import { eventsReducer, IEventsState } from "./events/reducer";
import { EVENTS_STATE_KEY } from "./events/selectors";
import { IRecsState, recsReducer } from "./recs/reducer";
import { RECS_STATE_KEY } from "./recs/selectors";
import { ANALYSIS_STATE_KEY } from "./selectors";

import { AgEventArea } from "./events/model";
import { RecArea } from "./recs/model";

import * as actions from "./actions";
import { LAYER_STATE_KEY, ZONES_STATE_KEY } from "./selectors";
import { IActionData, IReducerActions } from "./interfaces";
import { SearchAPI } from "@ai360/core";
import { ActionType } from "typesafe-actions";
import { recsActions } from ".";

export type AreaIdType = number;
export type RestoreIdType = any;
export type FieldGuidType = string;

export interface IZoneState {
    fieldGuidToCurrentAreaId: Map<string, AreaIdType>;
    fieldGuidToSavedAreaLists: Map<string, Map<RestoreIdType, AgEventArea[] | RecArea[]>>;
    copyZonesFromToMap: Map<AreaIdType, Set<AreaIdType>>;
    loading: boolean;
}

const zoneInfoInitialState: IZoneState = Object.freeze({
    batchFieldGuid: null,
    fieldGuidToCurrentAreaId: new Map(),
    fieldGuidToSavedAreaLists: new Map(),
    copyZonesFromToMap: new Map(),
    loading: false,
});

export interface ILayerState {
    layersToUpdate: Set<string>;
}

const layerInitialState: ILayerState = Object.freeze({
    layersToUpdate: new Set<string>(),
});

const zoneReducer = (state = zoneInfoInitialState, action) => {
    switch (action.type) {
        case actions.CLEAR_ANALYSIS_DETAILS:
        case actions.CLEAR_REC_DETAILS:
        case actions.CLEAR_EVENT_DETAILS: {
            return zoneInfoInitialState;
        }
        case actions.CREATE_NEW_EVENT_DETAILS:
        case actions.CREATE_COPY_BATCH_EVENT_DETAILS: {
            const { fieldGuidList } = action.payload;
            const fieldGuidToCurrentAreaId = new Map(
                fieldGuidList.map((fieldGuid) => [fieldGuid, null])
            );
            return Object.freeze({
                ...state,
                fieldGuidToCurrentAreaId,
            });
        }
        case actions.SET_COPY_FROM_AREA_ID: {
            const { areaId } = action.payload;
            const copyZonesFromToMap = areaId != null ? new Map([[areaId, new Set()]]) : new Map();
            return Object.freeze({
                ...state,
                copyZonesFromToMap,
            });
        }
        case actions.SET_COPY_TO_AREA_ID_SET: {
            const { areaIdSet } = action.payload;
            console.assert(state.copyZonesFromToMap.size === 1);
            const fromAreaId = state.copyZonesFromToMap.keys().next().value;
            const copyZonesFromToMap = immutableUtils.noMutateMapSet(
                state.copyZonesFromToMap,
                fromAreaId,
                areaIdSet
            );
            return Object.freeze({
                ...state,
                copyZonesFromToMap,
            });
        }
        case actions.SET_CURRENT_AREA_ID: {
            const { fieldGuid, areaId } = action.payload;
            const fieldGuidToCurrentAreaId = immutableUtils.noMutateMapSet(
                state.fieldGuidToCurrentAreaId,
                fieldGuid,
                areaId
            );
            return Object.freeze({
                ...state,
                fieldGuidToCurrentAreaId,
            });
        }
        case actions.SET_CURRENT_BATCH_FIELD_GUID: {
            const { batchFieldGuid } = action.payload;
            return Object.freeze({
                ...state,
                batchFieldGuid,
            });
        }
        case actions.SET_ZONES_LOADING: {
            return Object.freeze({
                ...state,
                ...action.payload,
            });
        }
        case actions.UPDATE_SAVED_AREA_LISTS: {
            const { fieldGuid, restoreId, areaList } = action.payload;
            let savedAreaLists = state.fieldGuidToSavedAreaLists.has(fieldGuid)
                ? state.fieldGuidToSavedAreaLists.get(fieldGuid)
                : new Map();
            savedAreaLists = immutableUtils.noMutateMapSet(savedAreaLists, restoreId, areaList);
            const fieldGuidToSavedAreaLists = immutableUtils.noMutateMapSet(
                state.fieldGuidToSavedAreaLists,
                fieldGuid,
                savedAreaLists
            );
            return Object.freeze({
                ...state,
                fieldGuidToSavedAreaLists,
            });
        }
        default:
            return state;
    }
};

const layerReducer = (state = layerInitialState, action) => {
    switch (action.type) {
        case actions.REMOVE_CHANGED_LAYER_ITEMS: {
            const { fieldGuids } = action.payload;
            const layersToUpdate = immutableUtils.noMutateSetDeleteAll(
                state.layersToUpdate,
                fieldGuids
            );
            return Object.freeze({
                ...state,
                layersToUpdate,
            });
        }
        case actions.SET_LAYERS_TO_UPDATE: {
            const { fieldGuids } = action.payload;
            const layersToUpdate = immutableUtils.noMutateSetAddAll(
                state.layersToUpdate,
                fieldGuids
            );
            return Object.freeze({
                ...state,
                layersToUpdate,
            });
        }
        default:
            return state;
    }
};

export const recsEventsReducer = combineReducers({
    [ANALYSIS_STATE_KEY]: analysisInfoReducer,
    [EVENTS_STATE_KEY]: eventsReducer,
    [LAYER_STATE_KEY]: layerReducer,
    [RECS_STATE_KEY]: recsReducer,
    [ZONES_STATE_KEY]: zoneReducer,
});

export const updateFieldRecEventMap = (
    state: IRecsState | IEventsState,
    { fieldGuid, recEventGeneralGuid, recEventData, keyProp, mapProp, model }: IActionData
): IRecsState | IEventsState => {
    const fieldGuidToRecEventListMap = state[mapProp];
    const existingFieldRecEventList = fieldGuidToRecEventListMap.get(fieldGuid);
    if (!existingFieldRecEventList) {
        return state;
    }
    const newFieldRecEventList = [...existingFieldRecEventList];
    const existingRecEventIndex = newFieldRecEventList.findIndex(
        (fieldRecEvent) => fieldRecEvent[keyProp] === recEventGeneralGuid
    );
    if (recEventData != null) {
        const newRecEvent = model.fromJsonObj(recEventData);
        if (existingRecEventIndex !== -1) {
            newFieldRecEventList.splice(existingRecEventIndex, 1, newRecEvent);
        } else {
            newFieldRecEventList.push(newRecEvent);
        }
    } else if (existingRecEventIndex !== -1) {
        newFieldRecEventList.splice(existingRecEventIndex, 1);
    } else {
        return state;
    }

    const newFieldGuidToRecEventListMap = immutableUtils.noMutateMapSet(
        fieldGuidToRecEventListMap,
        fieldGuid,
        newFieldRecEventList
    );
    return Object.freeze({
        ...state,
        [mapProp]: newFieldGuidToRecEventListMap,
    });
};

export const batchUpdateFieldRecEventMap = (
    state: IRecsState | IEventsState,
    changes: Array<{
        fieldGuid: string;
        recEventGeneralGuid: string;
        recEventData;
    }>,
    fields: SearchAPI.IFieldResult[],
    { keyProp, mapProp, model }: IReducerActions
): IRecsState | IEventsState => {
    const fieldGuidToRecEventListMap = state[mapProp].withMutations((map) => {
        changes.forEach((change) => {
            const { fieldGuid, recEventGeneralGuid, recEventData } = change;
            const existingFieldRecEventList = map.get(fieldGuid);
            if (!existingFieldRecEventList) {
                return;
            }
            const newFieldRecEventList = [...existingFieldRecEventList];
            const existingRecEventIndex = newFieldRecEventList.findIndex(
                (fieldRecEvent) => fieldRecEvent[keyProp] === recEventGeneralGuid
            );
            if (recEventData != null) {
                const field = fields.find((x) => x.id === fieldGuid);
                const newRecEvent = model.fromJsonObj(recEventData, field);
                if (existingRecEventIndex !== -1) {
                    newFieldRecEventList.splice(existingRecEventIndex, 1, newRecEvent);
                } else {
                    newFieldRecEventList.push(newRecEvent);
                }
            } else if (existingRecEventIndex !== -1) {
                newFieldRecEventList.splice(existingRecEventIndex, 1);
            } else {
                return;
            }

            map.set(fieldGuid, newFieldRecEventList);
        });
    });

    return Object.freeze({
        ...state,
        [mapProp]: fieldGuidToRecEventListMap,
    });
};
