import React from "react";
import classnames from "classnames";

import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import Immutable from "immutable";

import { toast, style, ToastType } from "react-toastify";

import * as loginActions from "~/login/actions";
import { getTheUserCompanyGuid, getTheUserGuid } from "~/login/selectors";
import { getUserPreference } from "~/admin/setup/preference/data/selectors";
import { APIError, NotificationAPI, SystemAPI } from "@ai360/core";
import * as cdActions from "~/customer-data/actions";
import * as cdSelectors from "~/customer-data/selectors";
import * as layerListActions from "~/action-panel/components/layer-module/components/layer-list/actions";
import {
    getAccordionItems,
    getPanelLoading,
} from "~/action-panel/components/layer-module/components/layer-list/selectors";
import * as actionPanelActions from "~/action-panel/actions";
import * as accordionActions from "~/accordion/actions";
import { ActionPanelModuleList } from "~/action-panel/actions";
import { actions as messagingActions } from "~/messaging";
import { messages } from "./components/i18n-messages";
import { ApiErrorNotification } from "./components/api-error-notification";
import { ToasterMessage } from "./components/toaster-message";
import { getNotificationList, getSamplingId } from "./selectors";

import * as actions from "./actions";

const REPORT_COMPLETED = "reportCompleted";
const REPORT_FAILED = "reportFailed";
const SAMPLE_RESULTS_IMPORTED = "sampleResultsImported";
const SAMPLE_RESULTS_IMPORTED_FAILED = "sampleResultsImportFailed";

const _getHighestZIndex = () => {
    return (
        Array.from(document.querySelectorAll("body *"))
            .map((a) => parseFloat(window.getComputedStyle(a).zIndex))
            .filter((a) => !isNaN(a))
            .sort((a, b) => a - b)
            .pop() || 10000
    );
};

const _logSystemError = function* (message) {
    const userGuid = yield select(getTheUserGuid);
    if (userGuid == null || userGuid === "") {
        return;
    }

    const companyGuid = yield select(getTheUserCompanyGuid);
    try {
        yield call(SystemAPI.logError, userGuid, companyGuid, message);
    } catch (err) {
        console.error("Error logging exception", err);
    }
};

const _getReportFiles = (reports: NotificationAPI.INotificationReport[]) => {
    const reportFiles = [...new Set(reports.map((item) => item.pdfLink))];
    return reportFiles.join(", ");
};

const _getSamplingField = (field) => {
    return field.farmName ? field.farmName + " - " + field.name : field.name;
};

export const clearToasterMessages = () => {
    toast.dismiss();
};

export const createWindowOnErrorActionChannel = () => {
    return eventChannel((emit) => {
        const emitUncaughtError = (errorEvent) => {
            const { error } = errorEvent;
            emit({
                type: "UnhandledError",
                payload: { error },
            });
        };

        window.addEventListener("error", emitUncaughtError, false);
        return () => window.removeEventListener("error", emitUncaughtError, false);
    });
};

export const onApiCallError = function* (action) {
    const { err } = action.payload;

    if (err instanceof APIError && (err.apiResultObj as any).status === 401) {
        yield put(loginActions.logoutUser());
        return;
    }

    let toastId = null;
    const dismiss = () => toast.dismiss(toastId);
    const sendEventAndDismiss = () => {
        window.dispatchEvent(new CustomEvent("api-error", { detail: action.payload }));
        dismiss();
    };
    const toastComp = (
        <ApiErrorNotification
            {...action.payload}
            onRetry={dismiss}
            onShowErrDetails={sendEventAndDismiss}
        />
    );
    toastId = toast(toastComp, {
        autoClose: action.payload.retryAction ? false : 8000,
        className: classnames("toastify-content", `toastify-content--${actions.MSGTYPE.ERROR}`),
        type: actions.MSGTYPE.ERROR as ToastType,
    });
    style({ zIndex: _getHighestZIndex() + 1 });
};

export const onPushToasterMsg = (action) => {
    const { autoClose, type } = action.payload;
    const adjustedType = type || actions.MSGTYPE.INFO;
    toast(<ToasterMessage {...action.payload} />, {
        autoClose,
        className: classnames("toastify-content", `toastify-content--${adjustedType}`),
        type: adjustedType,
    });
    style({ zIndex: _getHighestZIndex() + 1 });
};

export const onUnhandledException = function* (action) {
    const { error } = action.payload;

    if (error instanceof APIError) {
        if ((error.apiResultObj as any).status === 401) {
            put(loginActions.logoutUser());
        }
        return;
    }

    if (error == null) {
        return;
    }

    yield _logSystemError(`${error.message}\n\n${error.stack}`);
};

export const messageSubscriptions = function* () {
    const handleNotifications = function* (notificationMessages) {
        for (const message of notificationMessages) {
            if (message.read === true) {
                //Handles messages simply informing that the notification has been read
                continue;
            }
            const userPreference = yield select(getUserPreference);
            yield put(actions.fetchUserNotifications());
            switch (message.detail.type) {
                case REPORT_COMPLETED:
                    if (userPreference.receiveWebNotificationsForReports) {
                        yield put(
                            actions.pushToasterMessage(
                                messages.reportComplete,
                                actions.MSGTYPE.INFO,
                                {
                                    fileName: _getReportFiles(message.detail.reports),
                                }
                            )
                        );
                    }
                    break;
                case REPORT_FAILED:
                    if (userPreference.receiveWebNotificationsForReports) {
                        yield put(
                            actions.pushToasterMessage(
                                messages.reportFailed,
                                actions.MSGTYPE.ERROR,
                                {
                                    fileName: _getReportFiles(message.detail.reportsFailed),
                                }
                            )
                        );
                    }
                    break;
                case SAMPLE_RESULTS_IMPORTED:
                    if (userPreference.receiveWebNotificationsForSamplingResults) {
                        yield put(
                            actions.pushToasterMessage(
                                messages.samplingResultsToast,
                                actions.MSGTYPE.INFO,
                                {
                                    fileName: _getSamplingField(message.detail.field),
                                }
                            )
                        );
                    }
                    break;
                case SAMPLE_RESULTS_IMPORTED_FAILED:
                    if (userPreference.receiveWebNotificationsForSamplingResults) {
                        yield put(
                            actions.pushToasterMessage(
                                message.detail.title,
                                actions.MSGTYPE.ERROR,
                                {}
                            )
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "notifications",
            generator: handleNotifications,
        })
    );
};

export const fetchUserNotifications = function* () {
    const userGuid = yield select(getTheUserGuid);
    yield put(actions.setIsLoading(true));
    try {
        const notificationList = yield call(NotificationAPI.fetchUserNotifications, userGuid);
        yield put(actions.updateNotificationList(notificationList));
    } catch (err) {
        console.error("Error logging exception", err);
        yield put(actions.setIsLoading(false));
    } finally {
        yield put(actions.setIsLoading(false));
    }
};

export const expandFieldLayers = function* () {
    const samplingId = yield select(getSamplingId);
    if (!samplingId) {
        //action is not coming from Notifications component
        return;
    }
    const isPanelLoading = yield select(getPanelLoading);
    if (!isPanelLoading) {
        yield put(accordionActions.expandAccordionItem(25, [0]));
    }
};

export const selectSamplingLayer = function* () {
    const samplingId = yield select(getSamplingId);
    if (!samplingId) {
        //action is not coming from Notifications component
        return;
    }
    const layerListAccordion = yield select(getAccordionItems);
    const samplingAccordionItem = layerListAccordion[0]?.children.find(
        (i) => i.payload?.agEventGeneralGuid === samplingId
    );
    yield put(actions.setSamplingId(null));
    yield put(layerListActions.removeAllVisibleSurfaces());
    if (samplingAccordionItem) {
        yield put(
            accordionActions.expandAccordionItem(25, [0, samplingAccordionItem.payload.index])
        );
        yield put(
            accordionActions.expandAccordionItem(25, [0, samplingAccordionItem.payload.index, 1])
        );
    }
};

export const openSamplingLayer = function* (action) {
    const { fieldGuid, agEventGeneralGuid } = action.payload;
    const fieldMap = yield select(cdSelectors.getFieldMap);
    const existingField = fieldMap.get(fieldGuid);
    if (!existingField) {
        yield put(actions.pushToasterMessage(messages.findFieldError, actions.MSGTYPE.ERROR, {}));
        return;
    }
    yield put(accordionActions.collapseAllAccordionItems(25, [1]));
    yield put(cdActions.setSelectedFields(Immutable.Set<string>([fieldGuid])));
    yield put(actionPanelActions.setActiveModule(ActionPanelModuleList.LAYER));
    yield put(actions.setSamplingId(agEventGeneralGuid));
};

export const updateNotificationsRead = function* () {
    const userGuid = yield select(getTheUserGuid);
    const notificationList = yield select(getNotificationList);
    const notificationIds = notificationList.filter((n) => n.read === false).map((n) => n.id);
    if (notificationIds.length < 1) {
        //All notifications are already marked as read
        return;
    }
    const readNotificationList = notificationList.map((n) => {
        return {
            ...n,
            read: true,
        };
    });
    try {
        yield put(actions.updateNotificationList(readNotificationList));
        yield call(NotificationAPI.updateNotificationsRead, userGuid, notificationIds);
    } catch (err) {
        console.error("Error logging exception", err);
    }
};

export const notificationsSaga = function* () {
    yield all([
        messageSubscriptions(),
        takeEvery(actions.API_CALL_ERROR, onApiCallError),
        takeEvery(actions.CLEAR_TOASTER_MESSAGES, clearToasterMessages),
        takeEvery(actions.PUSH_TOASTER_MSG, onPushToasterMsg),
        takeEvery(actions.FETCH_USER_NOTIFICATIONS, fetchUserNotifications),
        takeEvery(actions.OPEN_SAMPLING_LAYER, openSamplingLayer),
        takeEvery(actions.UPDATE_NOTIFICATIONS_READ, updateNotificationsRead),
        takeEvery(createWindowOnErrorActionChannel(), onUnhandledException),
        takeLatest(layerListActions.SET_LAYER_FILTER_OPTIONS, expandFieldLayers),
        takeLatest(layerListActions.UPDATE_EXPANDED_FIELD_GUID_SET, selectSamplingLayer),
    ]);
};
