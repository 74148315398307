import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "~/admin/agBytes/i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service } from "../data";
import {
    formatPlainTextLabel,
    getAgBytesErrorClassNames,
    onNumberChange,
    onTextChange,
} from "~/admin/utils";

// Components
import { TextInput, Section, SubSection, Checkbox, TextArea } from "~/core";
import { VendorSearch } from "../../../../common/vendor-search";

// Styles
import "../../common/add-edit-panel.css";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        fetchData: PropTypes.bool,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.equationSet,
        recordGuid: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
        vendorName: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.equationSet = {};
        this.state = {
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupEquationSetRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([this.props.getNextId()]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.equationSet);
        }

        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.equationSet = nextProps.record;
            }
        }
    }

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.equationSet[model.PROPS_EQUATION_GROUP_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupEquationSetRecord = () => {
        const { addEditPanel } = this.props;
        this.equationSet = {};
        if (addEditPanel.mode === "ADD") {
            this.equationSet = service.getDefaultRecord();
        }
    };

    onTextChange = (formKey, value, callback) => {
        this.equationSet = onTextChange(this.equationSet, { formKey: [formKey], value }, callback);
    };

    onNumberChange = (formKey, value, callback) => {
        this.equationSet = onNumberChange(
            this.equationSet,
            { formKey: [formKey], value },
            callback
        );
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.equationSet[model.PROPS_GROUP_NAME]]);
    };

    onVendorSelectionChange = (data) => {
        const { VENDOR_NAME, VENDOR_GUID } = VendorSearch;
        if (data) {
            this.onTextChange(VENDOR_NAME, data[VENDOR_NAME]);
            this.onTextChange(VENDOR_GUID, data[VENDOR_GUID]);
        } else {
            this.onTextChange(VENDOR_NAME, "");
            this.onTextChange(VENDOR_GUID, "");
        }
    };

    renderForm = () => {
        const { apiErrors, userRole } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <div className="form-input-id">
                            <span>
                                {formatPlainTextLabel(
                                    formatMessage,
                                    messages.equationSetId,
                                    this.equationSet[model.PROPS_EQUATION_GROUP_ID]
                                )}
                            </span>
                        </div>
                    </SubSection>
                    <SubSection>
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            autoFocus
                            containerClassNames={getAgBytesErrorClassNames([540, 542], apiErrors)}
                            placeholderText={formatMessage(messages.name)}
                            labelText={formatMessage(messages.name)}
                            value={this.equationSet[model.PROPS_GROUP_NAME]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_GROUP_NAME, value, this.setHeaderText)
                            }
                            required
                        />
                        <VendorSearch
                            initialValue={this.equationSet[model.PROPS_VENDOR_NAME]}
                            onSelection={this.onVendorSelectionChange}
                            formatMessage={formatMessage}
                            clearOnSelection={true}
                        />
                    </SubSection>
                    <SubSection>
                        <Checkbox
                            onChange={(e, value) =>
                                this.onTextChange(model.PROPS_SAMPLING_EVENT_REQUIRED, value)
                            }
                            value={this.equationSet[model.PROPS_SAMPLING_EVENT_REQUIRED]}
                            label={formatMessage(messages.samplingEventRequired)}
                        />
                    </SubSection>
                    <SubSection>
                        <TextArea
                            tabIndex={0}
                            maxLength={1024}
                            placeholderText={formatMessage(messages.description)}
                            value={this.equationSet[model.PROPS_DESCRIPTION]}
                            onChange={(value) => this.onTextChange(model.PROPS_DESCRIPTION, value)}
                        />
                    </SubSection>
                </Section>
                <Section>
                    <SubSection>
                        {!userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className={"active-inactive"}
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={this.equationSet[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        return <div className="add-edit-panel">{this.renderForm()}</div>;
    }
}

export default injectIntl(AddEditPanel);
