import { AgEventAPI } from "@ai360/core";

export class AgEventTypeInfo implements AgEventAPI.IAgEventType {
    private static readonly SAMPLE_TYPE_NAME_SEPARATOR = " - ";
    sampleType: string | null = null;
    typeName: string;

    static fromGetAgEventTypeResponseObj(
        jsonObj: AgEventAPI.IGetAgEventTypeResponse
    ): AgEventTypeInfo {
        const { agEventTransactionTypeGuid, sampleTypeGuid, agEventTransactionTypeName } = jsonObj;
        const rv = new AgEventTypeInfo(
            agEventTransactionTypeGuid,
            sampleTypeGuid,
            agEventTransactionTypeName
        );
        return Object.freeze(rv);
    }

    static fromJsonObj(jsonObj: AgEventAPI.IAgEventType): Readonly<AgEventTypeInfo> {
        const agEventTransactionTypeName =
            jsonObj.sampleTypeGuid != null && jsonObj.sampleTypeGuid !== ""
                ? [jsonObj.typeName, jsonObj.sampleType].join(
                      AgEventTypeInfo.SAMPLE_TYPE_NAME_SEPARATOR
                  )
                : jsonObj.typeName;
        const rv = new AgEventTypeInfo(
            jsonObj.agEventTransactionTypeGuid,
            jsonObj.sampleTypeGuid,
            agEventTransactionTypeName
        );
        return Object.freeze(rv);
    }

    constructor(
        public agEventTransactionTypeGuid: string,
        public sampleTypeGuid: string | null,
        public agEventTransactionTypeName: string
    ) {
        this.typeName = agEventTransactionTypeName; // same value unless this is a sample type ..
        if (sampleTypeGuid) {
            const splitName = agEventTransactionTypeName.split(
                AgEventTypeInfo.SAMPLE_TYPE_NAME_SEPARATOR
            );
            console.assert(splitName.length === 2);
            this.typeName = splitName[0];
            this.sampleType = splitName[1];
        }
    }
}
