import { createStore, applyMiddleware, compose } from "redux";
import { batchDispatchMiddleware } from "redux-batched-actions";
import createSagaMiddleware from "redux-saga";
import createLogger from "redux-logger";

import { RequireRefreshError } from "~/notifications";
import { rootReducer } from "./root-reducer";
import { rootSaga } from "./root-saga";

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(batchDispatchMiddleware);

declare global {
    interface Window {
        process_env: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

if (!window.process_env.NO_LOGGER && window.process_env.NODE_ENV === "development") {
    const logger = createLogger();
    middlewares.push(logger);
}

// applyMiddleware can accept multiple params as different middleware
// applyMiddleware(thunks, sagas, logger, ...)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

const rootTask = sagaMiddleware.run(rootSaga);
rootTask.done.catch((err) => {
    // one of the sagas has an uncaught error, so we should probably reload the app
    throw new RequireRefreshError(err.toString());
});
