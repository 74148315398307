import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import {
    Bucket,
    BucketHeader,
    TextInput,
    SelectInput,
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    optionPropType,
    Checkbox,
} from "~/core";

import { ACTIVE_YN, picklistNames, selectors as picklistSelectors } from "~/core/picklist";

import * as actions from "./actions";

import { ImageryIcon } from "./icons/imagery";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./imagery-form.css";

const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;

const messages = defineMessages({
    imageryFormSideBarText: {
        id: "layerModule.imageryLayer.formSideBarText",
        defaultMessage: "Imagery",
    },
    imageryFormLabelText: {
        id: "layerModule.imageryLayer.imageryFormTitleText",
        defaultMessage: "Layer Detail",
    },
    imageryLayerNamePlaceholderText: {
        id: "layerModule.imageryLayer.namePlaceholderText",
        defaultMessage: "Layer Name",
    },
    confirm: {
        id: "layerModule.imageryLayer.confirm",
        defaultMessage: "Confirm",
    },
    confirmationText: {
        id: "layerModule.imageryLayer.confirmationText",
        defaultMessage: "Are you sure you want to change the Custom Imagery Default to this Layer?",
    },
    croppingSeasonText: {
        id: "layerModule.imageryLayer.croppingSeasonText",
        defaultMessage: "Cropping Season",
    },
    customImageryDefaultText: {
        id: "layerModule.imageryLayer.customImageryDefaultText",
        defaultMessage: "Custom Imagery Default",
    },
    dateText: {
        id: "layerModule.imageryLayer.dateText",
        defaultMessage: "Image Date",
    },
});

export const formLabelMessage = messages.imageryFormSideBarText;
export const formLabelIcon = ImageryIcon;

export class ImageryForm_ extends Component {
    static propTypes = {
        fieldImageryLayers: PropTypes.array,
        imageryLayerSummary: PropTypes.object,
        intl: intlShape.isRequired,
        onUpdateImageryLayerSummary: PropTypes.func,
        picklistOptionsCroppingSeason: PropTypes.arrayOf(optionPropType),
    };

    constructor(props) {
        super(props);
        this.state = {
            showCustomImageryChangeDialog: false,
        };
        this.customImagerySelection = null;
    }

    defaultImageryAlreadyExists = (fieldImageryLayers, imageryLayerGuid) =>
        fieldImageryLayers.some(
            (layer) => layer.isDefaultImagery && layer.imageryLayerGuid !== imageryLayerGuid
        );

    toggleCustomImageryChangeDialog = (show) =>
        this.setState({ showCustomImageryChangeDialog: show });

    getCustomImageryChangeDialog = () => {
        const { formatMessage } = this.props.intl;
        const { showCustomImageryChangeDialog } = this.state;
        const { onUpdateImageryLayerSummary } = this.props;
        return (
            <DialogBox
                forceOverflow
                title={formatMessage(messages.confirm)}
                footerType={DialogBoxFooterType.YES_NO}
                onClose={() => this.toggleCustomImageryChangeDialog(false)}
                onAction={() => {
                    onUpdateImageryLayerSummary({
                        isDefaultImagery: this.customImagerySelection,
                    });
                    this.toggleCustomImageryChangeDialog(false);
                }}
                isOpen={showCustomImageryChangeDialog}
            >
                {formatMessage(messages.confirmationText)}
            </DialogBox>
        );
    };

    render() {
        const {
            fieldImageryLayers,
            imageryLayerSummary,
            onUpdateImageryLayerSummary,
            picklistOptionsCroppingSeason,
        } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className="rec-event-info-form imagery-form">
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.imageryFormLabelText)}</BucketHeader>
                    <div className="imagery-input-row">
                        <TextInput
                            containerClassNames={["imagery-form-input"]}
                            onChange={(v) => onUpdateImageryLayerSummary({ name: v })}
                            placeholderText={formatMessage(
                                messages.imageryLayerNamePlaceholderText
                            )}
                            required={true}
                            value={imageryLayerSummary.name}
                            maxLength={50}
                        />
                    </div>
                    <div className="imagery-input-row">
                        <SelectInput
                            value={imageryLayerSummary.croppingSeasonGuid}
                            optionIsHiddenKey={ACTIVE_YN}
                            options={picklistOptionsCroppingSeason}
                            required={true}
                            clearable={false}
                            placeholderText={formatMessage(messages.croppingSeasonText)}
                            onChange={(v) =>
                                onUpdateImageryLayerSummary({
                                    croppingSeasonGuid: v,
                                    croppingSeasonName: picklistOptionsCroppingSeason.find(
                                        (o) => o.value === v
                                    ).label,
                                })
                            }
                        />
                        <DateInput
                            required
                            clearDisabled
                            openDirection="right"
                            onChange={(v) =>
                                onUpdateImageryLayerSummary({
                                    imageDate: moment(v).format("M/D/YYYY"),
                                })
                            }
                            placeholderText={formatMessage(messages.dateText)}
                            value={imageryLayerSummary.imageDate}
                            timeFormat={false}
                        />
                    </div>
                    <div className="imagery-input-row">
                        <Checkbox
                            className="check-box-container"
                            label={formatMessage(messages.customImageryDefaultText)}
                            value={imageryLayerSummary.isDefaultImagery}
                            onChange={(evt, value) => {
                                if (
                                    value &&
                                    this.defaultImageryAlreadyExists(
                                        fieldImageryLayers,
                                        imageryLayerSummary.imageryLayerGuid
                                    )
                                ) {
                                    this.customImagerySelection = value;
                                    this.toggleCustomImageryChangeDialog(true);
                                } else {
                                    onUpdateImageryLayerSummary({
                                        isDefaultImagery: value,
                                    });
                                }
                            }}
                        />
                    </div>
                    {this.getCustomImageryChangeDialog()}
                </Bucket>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUpdateImageryLayerSummary: (newProps) => dispatch(actions.updateImageryLayer(newProps)),
});

const mapStateToProps = (state) => {
    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    return {
        picklistOptionsCroppingSeason,
    };
};

export const ImageryForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(ImageryForm_));
