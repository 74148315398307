import React from "react";
import { ClearIcon, ImportIcon, RedoIcon, SearchIcon, TrashcanIcon, UndoIcon } from "~/core/icons";

import { AddMultipointIcon } from "./add-multipoint";
import { AddSelectIcon } from "./add-select-point";
import { CircleIcon } from "./circle";
import { ClipIcon } from "./clip";
import { CLUBoundaryIcon } from "~/map/components/assets/cluBoundary";
import { DrawIcon } from "./draw";
import { ExtentIcon } from "./extent";
import { FullExtentIcon } from "./full-extent";
import { MeasureIcon } from "./measure";
import { MoveIcon } from "./move";
import { NextIcon } from "./next";
import { PolygonIcon } from "./polygon";
import { PreviousIcon } from "./previous";
import { ReshapeIcon } from "./reshape";
import { SamplingToolsIcon } from "./sampling-tools";
import { SplitIcon } from "./split";
import { UnionIcon } from "./union";
import { ZoneBufferIcon } from "./zone-buffer";
import { ZoneClassifyIcon } from "./zone-classify";
import { ZoneCreateIcon } from "./zone-create";
import { ZoneEditIcon } from "./zone-edit";
import { ZoneExplodeIcon } from "./zone-explode";
import { ZoneLoadIcon } from "./zone-load";
import "./toolbar-icon.css";

export enum IconType {
    ADD_POINT = 0,
    ADD_MULTIPOINT = 1,
    CIRCLE = 2,
    CLIP = 3,
    CLU_BOUNDARY = 4,
    DELETE = 5,
    DRAW = 6,
    EXTENT = 7,
    FULL_EXTENT = 8,
    IMPORT = 9,
    MEASURE = 10,
    MOVE = 11,
    NEXT = 12,
    POLYGON = 13,
    PREVIOUS = 14,
    REDO = 15,
    RESET = 16,
    RESHAPE = 17,
    SAMPLING = 18,
    SEARCH = 19,
    SPLIT = 20,
    UNDO = 21,
    UNION = 22,
    ZONE_BUFFER = 23,
    ZONE_CLASSIFY = 24,
    ZONE_CREATE = 25,
    ZONE_EDIT = 26,
    ZONE_EXPLODE = 27,
    ZONE_LOAD = 28,
}

export const ToolbarIcon = (iconType: IconType): JSX.Element => {
    const props = {
        className: "toolbar-svg-icon",
    };
    switch (iconType) {
        case IconType.ADD_POINT:
            return <AddSelectIcon {...props} />;
        case IconType.ADD_MULTIPOINT:
            return <AddMultipointIcon {...props} />;
        case IconType.CIRCLE:
            return <CircleIcon {...props} />;
        case IconType.CLIP:
            return <ClipIcon {...props} />;
        case IconType.CLU_BOUNDARY:
            return <CLUBoundaryIcon {...props} />;
        case IconType.DELETE:
            return <TrashcanIcon {...props} />;
        case IconType.DRAW:
            return <DrawIcon {...props} />;
        case IconType.EXTENT:
            return <ExtentIcon {...props} />;
        case IconType.FULL_EXTENT:
            return <FullExtentIcon {...props} />;
        case IconType.IMPORT:
            return <ImportIcon {...props} />;
        case IconType.MEASURE:
            return <MeasureIcon {...props} />;
        case IconType.MOVE:
            return <MoveIcon {...props} />;
        case IconType.NEXT:
            return <NextIcon {...props} />;
        case IconType.POLYGON:
            return <PolygonIcon {...props} />;
        case IconType.PREVIOUS:
            return <PreviousIcon {...props} />;
        case IconType.REDO:
            return <RedoIcon {...props} />;
        case IconType.RESET:
            return <ClearIcon {...props} />;
        case IconType.RESHAPE:
            return <ReshapeIcon {...props} />;
        case IconType.SAMPLING:
            return <SamplingToolsIcon {...props} />;
        case IconType.SEARCH:
            return <SearchIcon {...props} />;
        case IconType.SPLIT:
            return <SplitIcon {...props} />;
        case IconType.UNDO:
            return <UndoIcon {...props} />;
        case IconType.UNION:
            return <UnionIcon {...props} />;
        case IconType.ZONE_BUFFER:
            return <ZoneBufferIcon {...props} />;
        case IconType.ZONE_CLASSIFY:
            return <ZoneClassifyIcon {...props} />;
        case IconType.ZONE_CREATE:
            return <ZoneCreateIcon {...props} />;
        case IconType.ZONE_EDIT:
            return <ZoneEditIcon {...props} />;
        case IconType.ZONE_EXPLODE:
            return <ZoneExplodeIcon {...props} />;
        case IconType.ZONE_LOAD:
            return <ZoneLoadIcon {...props} />;
        default:
            return null;
    }
};
