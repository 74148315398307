import React, { Component } from "react";
import PropTypes from "prop-types";
import { messages } from "../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
// Components
import {
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    SelectInput,
    TextInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { model } from "./data";
import { adminData, DAYS, GUID, IS_EDITING, NAME, SAVE, DETAILS } from "~/admin/data";
import moment from "moment";
import { formatDate, mapToPicklistValue } from "~/admin/utils";

import "./connection-type-list.css";

const RAVEN = "Raven Slingshot";

class ConnectionType extends Component {
    static propTypes = {
        editData: PropTypes.object,
        formatMessage: PropTypes.func,
        isEditing: PropTypes.bool,
        onAdd: PropTypes.func,
        onAddOrEditConnectionType: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        options: PropTypes.array,
        record: PropTypes.array,
        renderDeleteModal: PropTypes.func,
        setEditData: PropTypes.func,
        toggleModal: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [
            {
                name: [model.PROPS_CONNECTION_GUID],
                key: GUID,
            },
            {
                name: [model.PROPS_CONNECTION_NAME],
                key: NAME,
            },
            {
                name: [model.PROPS_ONSITE_PARAM_LIST],
                key: DETAILS,
            },
            {
                name: [model.PROPS_SYNC_FILES_FROM_DATE],
                key: [model.PROPS_SYNC_FILES_FROM_DATE],
            },
            {
                name: [model.PROPS_USER_CONNECTION_NAME],
                key: NAME,
            },
            {
                name: [model.PROPS_USER_CONNECTION_GUID],
                key: GUID,
            },
        ];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    _onConnectionTypeChange = (value) => {
        for (let i = 0; i < value.details.length; i++) {
            // clear out dynamic parameter values
            value.details[i].value = "";
        }
        this.props.onChange({
            [model.PROPS_CONNECTION_NAME]: value ? value.name : "",
            [model.PROPS_CONNECTION_GUID]: value ? value.guid : "",
            [model.PROPS_USER_CONNECTION_NAME]:
                value && this.props.editData[model.PROPS_CONNECTION_GUID] === value.guid
                    ? this.editData[model.PROPS_USER_CONNECTION_Name]
                    : "",
            [model.PROPS_SYNC_FILES_FROM_DATE]: null,
            [model.PROPS_ONSITE_PARAM_LIST]: value && value.details ? value.details : [],
        });
    };

    _onDateChange = (value) => {
        this.props.onChange({
            [model.PROPS_SYNC_FILES_FROM_DATE]: value,
        });
    };

    _onNameChange = (value) => {
        this.props.onChange({
            [model.PROPS_USER_CONNECTION_NAME]: value,
        });
    };

    _onParamChange = (value, index) => {
        const { editData } = this.props;
        const newParams = editData[model.PROPS_ONSITE_PARAM_LIST] || [];
        newParams[index].value = value;
        this.props.onChange({
            [model.PROPS_CONNECTION_NAME]: editData[model.PROPS_CONNECTION_NAME],
            [model.PROPS_CONNECTION_GUID]: editData[model.PROPS_CONNECTION_GUID],
            [model.PROPS_ONSITE_PARAM_LIST]: newParams,
        });
    };

    _onAddOrEditConnectionType = () => {
        if (this.props.onAddOrEditConnectionType) {
            this.props.onAddOrEditConnectionType(this.state.editingItem);
        }
    };
    _onAddOrEditItem = () => {
        if (this.props.onAddOrEditItem) {
            this.props.onAddOrEditItem();
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.editData[model.PROPS_SYNC_FILES_FROM_DATE] == null) {
            this._onDateChange(moment().subtract(30, DAYS));
        }
        this.props.setEditData(nextProps.editData);
    }

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, options, toggleModal } = this.props;
        return (
            <DialogBox
                forceOverflow
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                isOpen={isEditing}
                actionDisabled={
                    !editData[model.PROPS_CONNECTION_GUID] ||
                    !editData[model.PROPS_USER_CONNECTION_NAME] ||
                    (editData[model.PROPS_CONNECTION_NAME] !== RAVEN &&
                        !editData[model.PROPS_SYNC_FILES_FROM_DATE]) ||
                    (editData[model.PROPS_ONSITE_PARAM_LIST] &&
                        editData[model.PROPS_ONSITE_PARAM_LIST].some(
                            (param) => param.value == null || param.value.trim() === ""
                        ))
                }
                onAction={() => {
                    this._onAddOrEditConnectionType();
                    this._onAddOrEditItem();
                }}
                onClose={() => toggleModal(IS_EDITING, false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.connectionType,
                    { count: 1 }
                )}
                action={SAVE}
            >
                <SelectInput
                    required
                    disabled={this.state.editingItem}
                    tabIndex={0}
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    options={options}
                    value={mapToPicklistValue({
                        options: options,
                        selectedGuid: editData[model.PROPS_CONNECTION_GUID],
                    })}
                    onChange={(value) => {
                        this._onConnectionTypeChange(value);
                    }}
                    placeholderText={formatMessage(messages.connectionType, {
                        count: 1,
                    })}
                />
                <TextInput
                    required
                    value={editData[model.PROPS_USER_CONNECTION_NAME]}
                    onChange={(value) => {
                        this._onNameChange(value);
                    }}
                    placeholderText={formatMessage(messages.name)}
                />
                <DateInput
                    required
                    onChange={(value) => this._onDateChange(formatDate(value, ""))}
                    value={moment(editData[model.PROPS_SYNC_FILES_FROM_DATE])}
                    placeholderText={formatMessage(messages.syncFilesFromDate)}
                />

                {!editData || !editData[model.PROPS_ONSITE_PARAM_LIST]
                    ? null
                    : editData[model.PROPS_ONSITE_PARAM_LIST].map((param, index) => {
                          return (
                              <TextInput
                                  key={"param-" + index}
                                  required
                                  containerClassNames={["onsite-param-input"]}
                                  value={editData[model.PROPS_ONSITE_PARAM_LIST][index].value}
                                  onChange={(value) => {
                                      this._onParamChange(value, index);
                                  }}
                                  placeholderText={param.paramName}
                              />
                          );
                      })}
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid telematics-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [model.PROPS_CONNECTION_NAME]: {
                                title: formatMessage(messages.connectionType, {
                                    count: 1,
                                }),
                                className: "connection-type",
                            },
                            [model.PROPS_USER_CONNECTION_NAME]: {
                                title: formatMessage(messages.name),
                                className: "connection-name",
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.connectionType, {
                            count: 1,
                        })}
                        onClick={this.onAdd}
                    />
                </div>
            </div>
        );
    }
}

export default withEditableGrid(ConnectionType);
