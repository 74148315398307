import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, unitTypes, service, dropdowns } from "./../data";

export default AddEditPanelContainer(AddEditPanel, {
    pickLists,
    unitTypes,
    service,
    dropdowns,
});
