import React from "react";
import { connect } from "react-redux";
import { NoLink, SelectInputMulti, SelectInput, DialogBox, DialogBoxFooterType } from "~/core";
import { OrgLevelSearch } from "~/admin/agBytes/components/org-level-search";
import { withMasked, withApiResult } from "~/hocs";
import { FieldAPI } from "@ai360/core";
import { IFormatter } from "@ai360/core";
import { messages } from "../../i18n-messages";
import { getFilterSelections } from "../../selectors";
import { IFieldFilterSelections, IFieldFilterOptions } from "../../models";
import { setFilterSelections } from "../../actions";
import { getOrgLevelMap } from "~/customer-data/selectors";
import { getTheUserEnrollmentRequired, getTheUserPersonalityId } from "~/login/selectors";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";

import "./field-filter.css";

interface IFieldFilterProps {
    className: string;
    filterSelections: IFieldFilterSelections;
    filterOptions: IFieldFilterOptions;
    initialFilterStr: string;
    intl: IFormatter;
    records: FieldAPI.IOrgLevelInfo[];
    onClose: () => void;
    setFilterSelections: (filterSelections: IFieldFilterSelections) => void;
    visible: boolean;
    displayLocation: boolean;
    displayEnrollment: boolean;
    displayClassifications: boolean;
}

interface IFieldFilterState {
    multiSelectIndex: number;
}
class FieldFilter_ extends React.Component<IFieldFilterProps, IFieldFilterState> {
    static defaultProps = {
        className: "",
        initialFilterStr: "",
    };

    constructor(props: IFieldFilterProps) {
        super(props);
        this.state = { multiSelectIndex: 0 };
    }

    private onSelectionsChanged(filterSelections: Partial<IFieldFilterSelections>) {
        this.props.setFilterSelections(filterSelections);
    }

    private onSelectionsClear() {
        this.setState({ multiSelectIndex: this.state.multiSelectIndex + 1 });
        this.props.setFilterSelections({
            classifications: [],
            crops: [],
            enrolled: null,
            irrigated: [],
            certifiedOrganic: null,
            salespeople: [],
        });
    }

    render() {
        const { filterSelections, filterOptions, initialFilterStr, records, onClose, visible } =
            this.props;

        const { formatMessage } = this.props.intl;

        return (
            <DialogBox
                isModal={true}
                closeOnEscape={false}
                closeOnClickOff={false}
                hideCloseX={false}
                footerType={DialogBoxFooterType.NONE}
                unrestricted={true}
                draggable={true}
                title={formatMessage(messages.filterTitle)}
                className="field-filter blownup"
                onClose={onClose}
                isOpen={visible}
            >
                {this.props.displayLocation ? (
                    <OrgLevelSearch
                        key={`location_${filterSelections.orgLevelGuid}`}
                        classNames={["filter-location"]}
                        initialFilterStr={initialFilterStr}
                        itemList={records}
                        nameProp="name"
                        onSelection={({ orgLevelGuid }: IFieldFilterSelections = {}) => {
                            this.setState({
                                multiSelectIndex: this.state.multiSelectIndex + 1,
                            });
                            this.onSelectionsChanged({
                                orgLevelGuid,
                                classifications: [],
                                crops: [],
                                salespeople: [],
                                certifiedOrganic: null,
                                enrolled: null,
                                irrigated: [],
                            });
                        }}
                        placeholderText={formatMessage(messages.filterLocation)}
                        selectedValue={filterSelections.orgLevelGuid}
                        showTopLabel={true}
                        showSearchIcon={false}
                        subNameProps={["parentsSummary"]}
                    />
                ) : null}
                <SelectInputMulti
                    key={`salesperson${this.state.multiSelectIndex}`}
                    isClearable={true}
                    options={filterOptions.salespeople}
                    placeholder={formatMessage(messages.filterSalesperson)}
                    onChange={(salespeople) => this.onSelectionsChanged({ salespeople })}
                    showTopLabel={true}
                    sort={true}
                    value={filterOptions.salespeople.filter((p) =>
                        filterSelections.salespeople.includes(p.value)
                    )}
                />
                {this.props.displayClassifications ? (
                    <SelectInputMulti
                        key={`classification${this.state.multiSelectIndex}`}
                        isClearable={true}
                        options={filterOptions.classifications}
                        placeholder={formatMessage(messages.classification)}
                        onChange={(classifications) =>
                            this.onSelectionsChanged({ classifications })
                        }
                        showTopLabel={true}
                        sort={true}
                        value={filterOptions.classifications.filter((c) =>
                            filterSelections.classifications.includes(c.value)
                        )}
                    />
                ) : null}
                {this.props.displayEnrollment ? (
                    <SelectInput
                        key={`enrolled_${filterSelections.enrolled}`}
                        options={filterOptions.enrolled}
                        placeholderText={formatMessage(messages.filterEnrolled)}
                        onChange={(enrolled) => this.onSelectionsChanged({ enrolled })}
                        value={filterSelections.enrolled}
                    />
                ) : null}
                <SelectInputMulti
                    key={`crop${this.state.multiSelectIndex}`}
                    isClearable={true}
                    options={filterOptions.crops}
                    placeholder={formatMessage(messages.crop)}
                    onChange={(crops) => this.onSelectionsChanged({ crops })}
                    showTopLabel={true}
                    sort={true}
                    value={filterOptions.crops.filter((c) =>
                        filterSelections.crops.includes(c.value)
                    )}
                />
                <SelectInput
                    key={`organic_${filterSelections.certifiedOrganic}`}
                    options={filterOptions.certifiedOrganic}
                    placeholderText={formatMessage(messages.organic)}
                    onChange={(certifiedOrganic) => this.onSelectionsChanged({ certifiedOrganic })}
                    value={filterSelections.certifiedOrganic}
                />
                <SelectInputMulti<number>
                    key={`irrigated${this.state.multiSelectIndex}`}
                    isClearable={true}
                    options={filterOptions.irrigated}
                    placeholder={formatMessage(messages.irrigated)}
                    onChange={(irrigated) => this.onSelectionsChanged({ irrigated })}
                    showTopLabel={true}
                    sort={true}
                    value={filterOptions.irrigated.filter((i) =>
                        filterSelections.irrigated.includes(i.value)
                    )}
                />
                <NoLink
                    label={formatMessage(messages.filterClear)}
                    onClick={() => this.onSelectionsClear()}
                />
            </DialogBox>
        );
    }
}

const mapStateToProps = (state) => ({
    filterSelections: getFilterSelections(state),
    displayLocation: getOrgLevelMap(state).size > 1,
    displayEnrollment: getTheUserEnrollmentRequired(state),
    displayClassifications: AgvanceUtils.isAgvanceConnected(getTheUserPersonalityId(state)),
});

const mapDispatchToProps = (dispatch) => ({
    setFilterSelections: (filterSelections: IFieldFilterSelections) =>
        dispatch(setFilterSelections(filterSelections)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export const FieldFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(withMasked(withApiResult(FieldFilter_)));
