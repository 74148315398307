import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";

import { Accordion, model as accordionModel } from "~/accordion";
import { IGetAccordionItemElArgs } from "~/accordion/components/accordion";

import * as fieldModuleSelectors from "../../../../selectors";

import * as actions from "../../actions";
import * as selectors from "../../selectors";

import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";

import { FieldListTabs } from "~/action-panel/components/field-module/actions";

import { CustomerItem } from "./customer-item";
import { FieldItem } from "./field-item";
import { Trigger } from "~/infinite-scroll";

import { UserAPI } from "@ai360/core";
import { CustomerInfo } from "~/customer-data/models";

const _contentEl = (
    accordionId: number,
    accordionItemDimIdx: accordionModel.MultiDimIdx,
    payload: any,
    expanded: boolean,
    isLockCustomer: boolean,
    userRole: UserAPI.IUserRole,
    fetchingCustomerFields: boolean,
    onAddEditField: (customer: CustomerInfo, fieldGuid: string) => void,
    loadDetailPage: () => void,
    fetchCustomerFields: () => void
): JSX.Element => {
    if (accordionItemDimIdx.length === 1) {
        const { customer, infiniteScroll, isLast, isActiveInactive } = payload;
        return infiniteScroll != null ? (
            <Trigger onVisible={fetchCustomerFields} loading={fetchingCustomerFields} />
        ) : (
            <CustomerItem
                accordionId={accordionId}
                customer={customer}
                isExpanded={expanded}
                itemDimIdx={accordionItemDimIdx}
                loadDetailPage={loadDetailPage}
                onAddEditField={onAddEditField}
                isLast={isLast}
                isActiveInactive={isActiveInactive}
            />
        );
    } else {
        console.assert(accordionItemDimIdx.length === 2);
        const { field, customer } = payload;
        return (
            <FieldItem
                accordionId={accordionId}
                customer={customer}
                field={field}
                isLockCustomer={isLockCustomer}
                itemDimIdx={accordionItemDimIdx}
                onAddEditField={onAddEditField}
                userRole={userRole}
            />
        );
    }
};

interface IComponentProps {
    accordionId: number;
    isLockCustomer: boolean;
    userRole: UserAPI.IUserRole;
    fetchingCustomerFields: boolean;
    loadDetailPage: () => void;
    onAddEditField: () => void;
    fetchCustomerFields: () => void;
}

const _getAccordionItemEl = ({
    key,
    style,
    accordionItem,
    accordionItemDimIdx,
    isStuck,
    componentProps,
}: IGetAccordionItemElArgs<IComponentProps>) => {
    const {
        accordionId,
        isLockCustomer,
        userRole,
        fetchingCustomerFields,
        loadDetailPage,
        onAddEditField,
        fetchCustomerFields,
    } = componentProps;

    const contentEl = _contentEl(
        accordionId,
        accordionItemDimIdx,
        accordionItem.payload,
        accordionItem.expanded,
        isLockCustomer,
        userRole,
        fetchingCustomerFields,
        onAddEditField,
        loadDetailPage,
        fetchCustomerFields
    );

    return (
        <div key={key} style={style} className={classnames("accordion-item", { sticky: isStuck })}>
            {contentEl}
        </div>
    );
};

_getAccordionItemEl.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Unique key within array of rows
    style: PropTypes.object, // Style object to be applied to row (to position it)
    accordionItem: PropTypes.object,
    accordionItemDimIdx: PropTypes.arrayOf(PropTypes.number),
    isStuck: PropTypes.bool,
    componentProps: PropTypes.object, // Pass thru props from <Accordion />
};

const mapDispatchToProps = (dispatch) => ({
    onScrollTopChanged: (activeTab, scrollTop) =>
        dispatch(actions.setScrollTop(activeTab, scrollTop)),
    fetchCustomerFields: () => dispatch(cdActions.fetchCustomerFields()),
});

const mapStateToProps = (state) => ({
    activeTab: fieldModuleSelectors.getActiveTab(state),
    itemCount: selectors.getAccordionRecItemCount(state),
    itemList: selectors.getAccordionItems(state),
    totalHeight: selectors.getAccordionRecHeight(state),
    getAccordionItemFromFlatIdx: (idx) => selectors.getFlatIdxMap(state)(idx),
    getAccordionItemFromDimIdx: (dimIdx) => selectors.getDimIdxMap(state)(dimIdx),
    getAccordionItemEl: _getAccordionItemEl,
    scrollTop: selectors.getAccordionScrollTop(state),
    scrollToIndex: selectors.getAccordionScrollToFlatIdx(state),
    fetchingCustomerFields: cdSelectors.getFetchingCustomerFields(state),

    // pass-thru prop; <Accordion /> doesn't care about these but they're passed-thru
    // to `getAccordionItemEl` and `onScrollTopChanged`
    accordionId: selectors.getAccordionId(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onScrollTopChanged: (scrollTop) =>
        dispatchProps.onScrollTopChanged(stateProps.activeTab, scrollTop),
    fetchCustomerFields: () =>
        dispatchProps.fetchCustomerFields(stateProps.activeTab === FieldListTabs.ACTIVE),
});

export const CustomersAccordion = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Accordion);
