import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import {
    IFileNameFormat,
    IReportType,
    IReportTypePage,
    IYieldByCategory,
    IPayloadData,
    IActionData,
} from "./interfaces";

export const REPORTS_GET_CROP_DROPDOWN_LIST = "reports/GET_CROP_DROPDOWN_LIST";
export const REPORTS_GET_CROP_DROPDOWN_SUCCESS = "reports/GET_CROP_DROPDOWN_SUCCESS";
export const REPORTS_GET_CROP_DROPDOWN_FAILED = "reports/GET_CROP_DROPDOWN_FAILED";
export const REPORTS_GET_FILE_NAME_FORMAT_LIST = "reports/GET_FILE_NAME_FORMAT_LIST";
export const REPORTS_GET_FILE_NAME_FORMAT_SUCCESS = "reports/GET_FILE_NAME_FORMAT_SUCCESS";
export const REPORTS_GET_FILE_NAME_FORMAT_FAILED = "reports/GET_FILE_NAME_FORMAT_FAILED";
export const REPORTS_GET_REPORT_TYPE_LIST = "reports/REPORTS_GET_REPORT_TYPE_LIST";
export const REPORTS_GET_REPORT_TYPE_SUCCESS = "reports/REPORTS_GET_REPORT_TYPE_SUCCESS";
export const REPORTS_GET_REPORT_TYPE_FAILED = "reports/REPORTS_GET_REPORT_TYPE_FAILED";
export const REPORTS_GET_USER_FILE_FORMAT_PREF = "reports/REPORTS_GET_USER_FILE_FORMAT_PREF";
export const REPORTS_GET_USER_FILE_FORMAT_SUCCESS = "reports/REPORTS_GET_USER_FILE_FORMAT_SUCCESS";
export const REPORTS_GET_USER_FILE_FORMAT_FAILED = "reports/REPORTS_GET_USER_FILE_FORMAT_FAILED";
export const REPORTS_GET_USER_MULTI_FIELD_REC_PREF =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_PREF";
export const REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS";
export const REPORTS_GET_USER_MULTI_FIELD_REC_FAILED =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_FAILED";
export const REPORTS_GET_REPORT_TYPE_PAGE_LIST = "reports/REPORTS_GET_REPORT_TYPE_PAGE_LIST";
export const REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS = "reports/REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS";
export const REPORTS_GET_REPORT_TYPE_PAGE_FAILED = "reports/REPORTS_GET_REPORT_TYPE_PAGE_FAILED";
export const REPORTS_GET_REPORT_PREF_DATA = "reports/REPORTS_GET_REPORT_PREF_DATA";
export const REPORTS_GET_REPORT_PREF_SUCCESS = "reports/REPORTS_GET_REPORT_PREF_SUCCESS";
export const REPORTS_GET_REPORT_PREF_FAILED = "reports/REPORTS_GET_REPORT_PREF_FAILED";
export const REPORTS_CREATE_REPORT_DATA = "reports/REPORTS_CREATE_REPORT_DATA";
export const REPORTS_CREATE_REPORT_HUB_SUCCESS = "reports/REPORTS_CREATE_REPORT_HUB_SUCCESS";
export const REPORTS_CREATE_REPORT_SUCCESS = "reports/REPORTS_CREATE_REPORT_SUCCESS";
export const REPORTS_CREATE_REPORT_FAILED = "reports/REPORTS_CREATE_REPORT_FAILED";
export const REPORTS_GET_YIELD_CATEGORY_SUCCESS = "reports/REPORTS_GET_YIELD_CATEGORY_SUCCESS";
export const REPORTS_GET_YIELD_CATEGORY_FAILED = "reports/REPORTS_GET_YIELD_CATEGORY_FAILED";
export const REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA =
    "reports/REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA";
export const REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS =
    "reports/REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED";
export const REPORTS_DELETE_SELECTED = "reports/REPORTS_DELETE_SELECTED";
export const REPORTS_DELETE_SUCCESS = "reports/REPORTS_DELETE_SUCCESS";
export const REPORTS_DELETE_FAILED = "reports/REPORTS_DELETE_FAILED";
export const REPORTS_CLEAR_REPORT_HUB_DATA = "reports/REPORTS_CLEAR_REPORT_HUB_DATA";
export const REPORTS_SEND_EMAIL_DATA = "reports/REPORTS_SEND_EMAIL_DATA";
export const REPORTS_SEND_EMAIL_SUCCESS = "reports/REPORTS_SEND_EMAIL_SUCCESS";
export const REPORTS_SEND_EMAIL_FAILED = "reports/REPORTS_SEND_EMAIL_FAILED";
export const REPORTS_DOWNLOAD_DATA = "reports/REPORTS_DOWNLOAD_DATA";
export const REPORTS_DOWNLOAD_SUCCESS = "reports/REPORTS_DOWNLOAD_SUCCESS";
export const REPORTS_DOWNLOAD_FAILED = "reports/REPORTS_DOWNLOAD_FAILED";
export const REPORTS_PRINT_DATA = "reports/REPORTS_PRINT_DATA";
export const REPORTS_PRINT_SUCCESS = "reports/REPORTS_PRINT_SUCCESS";
export const REPORTS_PRINT_FAILED = "reports/REPORTS_PRINT_FAILED";
export const REPORTS_MERGE_DATA = "reports/REPORTS_MERGE_DATA";
export const REPORTS_MERGE_SUCCESS = "reports/REPORTS_MERGE_SUCCESS";
export const REPORTS_MERGE_FAILED = "reports/REPORTS_MERGE_FAILED";
export const REPORTS_SET_FIELDS = "reports/SET_FIELDS";

// Crop dropdown actions
export const getCropDropdown = (): IActionData => ({
    type: REPORTS_GET_CROP_DROPDOWN_LIST,
});
export const getCropDropdownSuccess = (cropDropdown: ICrop[]): IActionData => ({
    type: REPORTS_GET_CROP_DROPDOWN_SUCCESS,
    cropDropdown,
});

export const getCropDropdownFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_CROP_DROPDOWN_FAILED,
    payload,
});

//Loasheet File Name Format dropdown actions
export const getFileNameFormat = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_FILE_NAME_FORMAT_LIST,
    payload,
});
export const getFileNameFormatSuccess = (fileNameFormatList: IFileNameFormat[]): IActionData => ({
    type: REPORTS_GET_FILE_NAME_FORMAT_SUCCESS,
    fileNameFormatList,
});

export const getFileNameFormatFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_FILE_NAME_FORMAT_FAILED,
    payload,
});

// Report type list actions
export const getReportTypeList = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_LIST,
    payload,
});
export const getReportTypeSuccess = (typeList: IReportType[]): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_SUCCESS,
    typeList,
});
export const getReportTypeFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_FAILED,
    payload,
});

//Loasheet Multi-Field Rec actions
export const getUserMultiFieldRecPreference = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_USER_MULTI_FIELD_REC_PREF,
    payload,
});
export const getUserMultiFieldRecPreferenceSuccess = (
    defaultMultiFieldRec: boolean
): IActionData => ({
    type: REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS,
    defaultMultiFieldRec,
});
export const getUserMultiFieldRecPreferenceFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_USER_MULTI_FIELD_REC_FAILED,
    payload,
});

//Loasheet File Name Format actions
export const getUserFileNameFormatPreference = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_USER_FILE_FORMAT_PREF,
    payload,
});
export const getUserFileNameFormatPreferenceSuccess = (
    defaultFileNameFormat: string
): IActionData => ({
    type: REPORTS_GET_USER_FILE_FORMAT_SUCCESS,
    defaultFileNameFormat,
});
export const getUserFileNameFormatPreferenceFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_USER_FILE_FORMAT_FAILED,
    payload,
});

// Report type page list actions
export const getReportTypePageList = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_PAGE_LIST,
    payload,
});
export const getReportTypePageSuccess = (pageList: IReportTypePage[]): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS,
    pageList,
});
export const getReportTypePageFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_REPORT_TYPE_PAGE_FAILED,
    payload,
});

// Report prefs actions
export const getReportPrefData = (): IActionData => ({
    type: REPORTS_GET_REPORT_PREF_DATA,
});
export const getReportPrefSuccess = (reportPreferences: Record<string, unknown>): IActionData => ({
    type: REPORTS_GET_REPORT_PREF_SUCCESS,
    reportPreferences,
});
export const getReportPrefFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_REPORT_PREF_FAILED,
    payload,
});

// Delete Report
export const deleteSelectedReports = (payload: IPayloadData): IActionData => ({
    type: REPORTS_DELETE_SELECTED,
    payload,
});
export const deleteSelectedReportsSuccess = (DEL = true): IActionData => ({
    type: REPORTS_DELETE_SUCCESS,
    DEL,
});
export const deleteSelectedReportsFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_DELETE_FAILED,
    payload,
});

// Create report actions
export const createReport = (payload: IPayloadData): IActionData => ({
    type: REPORTS_CREATE_REPORT_DATA,
    payload,
});

export const createReportSuccess = (payload: []): IActionData => ({
    type: REPORTS_CREATE_REPORT_SUCCESS,
    payload,
});

export const createReportFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_CREATE_REPORT_FAILED,
    payload,
});
export const createReportHubSuccess = (reportStatus: string[]): IActionData => ({
    type: REPORTS_CREATE_REPORT_HUB_SUCCESS,
    reportStatus,
});
export const clearReportHubData = (): IActionData => ({
    type: REPORTS_CLEAR_REPORT_HUB_DATA,
});

// Send email actions
export const sendEmail = (payload: IPayloadData): IActionData => ({
    type: REPORTS_SEND_EMAIL_DATA,
    payload,
});
export const sendEmailSuccess = (): IActionData => ({
    type: REPORTS_SEND_EMAIL_SUCCESS,
});
export const sendEmailFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_SEND_EMAIL_FAILED,
    payload,
});

// Download report actions
export const downloadReports = (payload: IPayloadData): IActionData => ({
    type: REPORTS_DOWNLOAD_DATA,
    payload,
});
export const downloadReportsSuccess = (): IActionData => ({
    type: REPORTS_DOWNLOAD_SUCCESS,
});
export const downloadReportsFailed = (payload: Error): IActionData => ({
    type: REPORTS_DOWNLOAD_FAILED,
    payload,
});

// Download report actions
export const printReports = (payload: IPayloadData): IActionData => ({
    type: REPORTS_PRINT_DATA,
    payload,
});
export const printReportsSuccess = (): IActionData => ({
    type: REPORTS_PRINT_SUCCESS,
});
export const printReportsFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_PRINT_FAILED,
    payload,
});

// Merge report actions
export const mergeReports = (payload: IPayloadData): IActionData => ({
    type: REPORTS_MERGE_DATA,
    payload,
});
export const mergeReportsSuccess = (): IActionData => ({
    type: REPORTS_MERGE_SUCCESS,
});
export const mergeReportsFailed = (payload: Error): IActionData => ({
    type: REPORTS_MERGE_FAILED,
    payload,
});

export const getYieldByAnalysisCategoryFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_YIELD_CATEGORY_FAILED,
    payload,
});
// Get yield by compare by options actions
export const getYieldByCompareByOption = (payload: IPayloadData): IActionData => ({
    type: REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA,
    payload,
});
export const getYieldByCompareByOptionSuccess = (
    yieldByCompareByOptions: IYieldByCategory
): IActionData => ({
    type: REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS,
    yieldByCompareByOptions,
});
// Update yield by compare by options actions
export const updateYieldByCompareByOptions = (payload: IPayloadData): IActionData => ({
    type: REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA,
    payload,
});
export const updateYieldByCompareByOptionsSuccess = (payload: IPayloadData): IActionData => ({
    type: REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS,
    payload,
});
export const updateYieldByCompareByOptionsFailed = (payload: IPayloadData): IActionData => ({
    type: REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED,
    payload,
});
export const setFields = (payload: IPayloadData): IActionData => ({
    type: REPORTS_SET_FIELDS,
    payload,
});
