//actionChannel, call, fork, join, put, select, take,

import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { FileImportAPI } from "@ai360/core";

import {
    actions as uploaderActions,
    selectors as uploaderSelectors,
    models as uploaderModels,
} from "~/action-panel/components/common/drag-and-drop-file-uploader";
import { actions as fileImportActions, models as fileImportModels } from "~/file-import";
import {
    actions as recsEventsActions,
    models as recsEventsModels,
    recsModels,
    recsSelectors,
} from "~/recs-events";
import { errorCodeMessages } from "~/i18n-error-messages";
import { getTheUserGuid } from "~/login/selectors";

import * as recInfoActions from "../../../actions";
import * as recInfoSelectors from "../../../selectors";
import { models, actions, selectors } from "./";
import { messages } from "./i18n-messages";

const onNextStep = function* () {
    const { fileUploadComplete }: uploaderModels.IDragDropFileUploaderState = yield select(
        uploaderSelectors.getModuleState,
        models.RX_FILE_IMPORT_STATE_KEY
    );
    const { currentStep }: models.IRXFileImportState = yield select(selectors.getModuleState);
    if (currentStep === "upload") {
        if (!fileUploadComplete) {
            // Need to upload all files before moving to next step
            yield put(actions.setRxFileImportLoading(true));
            yield put(uploaderActions.processUploads(models.RX_FILE_IMPORT_STATE_KEY));
        } else {
            yield put(actions.setMatchStep());
        }
    } else {
        yield put(recInfoActions.setPermanentAdjustments(true));
        yield put(recInfoActions.saveRecInfo());
    }
};

const onDetermineStep = function* () {
    // Determine step to start at (upload or complete)
    const { recSummary } = yield select(recInfoSelectors.getModuleState);
    if (recSummary == null) {
        return;
    }

    const { fieldGuid } = recSummary;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const recDetails = fieldGuidToRecDetails.get(fieldGuid);
    const hasRecGuid = recDetails?.recAreaList.some((recArea) =>
        recArea?.recs.some((rec) => rec.recGuid)
    );
    const hasEquationRun = recDetails?.recAreaList.some((recArea) =>
        recArea?.recs.some((rec) => rec.isEquationRun)
    );
    if (hasRecGuid || hasEquationRun) {
        yield put(actions.setCompleteStep());
    } else {
        yield put(actions.setUploadStep());
    }
};

const onRxFileImportReset = function* () {
    yield put(uploaderActions.clearUploadFileList(models.RX_FILE_IMPORT_STATE_KEY));
    yield call(onDetermineStep);
};

const onRxFileImportSetup = function* () {
    yield call(onDetermineStep);
    const { selectedImportType, selectedTemplate }: uploaderModels.IDragDropFileUploaderState =
        yield select(uploaderSelectors.getModuleState, models.RX_FILE_IMPORT_STATE_KEY);
    if (selectedImportType && selectedTemplate) {
        return;
    }
    yield put(actions.setRxFileImportLoading(true));

    const userGuid = yield select(getTheUserGuid);

    const [rxFileImportType, rxFileImportTemplate]: [
        fileImportModels.IImportType,
        fileImportModels.ImportTemplate
    ] = yield all([
        call(FileImportAPI.getRxFileImportType, userGuid),
        call(FileImportAPI.getRxFileImportTemplate, userGuid),
    ]);

    yield put(
        uploaderActions.setSelectedImportType(models.RX_FILE_IMPORT_STATE_KEY, rxFileImportType)
    );
    yield put(
        uploaderActions.setSelectedTemplate(models.RX_FILE_IMPORT_STATE_KEY, rxFileImportTemplate)
    );
    yield put(actions.setRxFileImportLoading(false));
};

function* onUploadsProcessed(action) {
    const {
        fileUploadComplete,
        filesUploaded,
        uploadFileInfoMap,
    }: uploaderModels.IDragDropFileUploaderState = yield select(
        uploaderSelectors.getModuleState,
        models.RX_FILE_IMPORT_STATE_KEY
    );
    if (!fileUploadComplete) {
        return;
    }
    try {
        yield put(actions.setRxFileImportLoading(true));
        const uploadFileInfos = Array.from(filesUploaded).map((fileGuid) =>
            uploadFileInfoMap.get(fileGuid)
        );
        const importFileGuids = uploadFileInfos
            .map((uploadFileInfo) =>
                !uploadFileInfo.isZipFile()
                    ? [uploadFileInfo.guid]
                    : uploadFileInfo.children
                          .filter((child) => child.isShape())
                          .map((child) => child.guid)
            )
            .flat();
        const selectedFieldGuids = yield select(selectors.getRecFieldGuids);
        const userGuid = yield select(getTheUserGuid);
        const matchingResponse: FileImportAPI.ImportProductRecFieldMatchingResponse = yield call(
            FileImportAPI.getImportProductRecMatchingFields,
            userGuid,
            {
                FieldGuids: selectedFieldGuids,
                ImportFileGuids: importFileGuids,
            }
        );
        const uploadFileInfosToUpdate = [];
        if (matchingResponse == null) {
            for (const uploadFileInfo of uploadFileInfoMap.values()) {
                if (uploadFileInfo.isShape()) {
                    uploadFileInfo.errorMessages.push([messages.errorProcessingFile]);
                    uploadFileInfosToUpdate.push(uploadFileInfo);
                }
            }
        } else {
            for (const { importFileGuid, errorCode } of matchingResponse.invalid) {
                const uploadFileInfo = uploadFileInfoMap.get(importFileGuid);
                if (uploadFileInfo && uploadFileInfo.isShape()) {
                    const errorMessage =
                        errorCodeMessages[String(errorCode)] || errorCodeMessages["9999"];
                    uploadFileInfo.errorMessages.push([errorMessage]);
                    uploadFileInfosToUpdate.push(uploadFileInfo);
                }
            }
            for (const importFileGuid of matchingResponse.nonMatchingFiles) {
                const uploadFileInfo = uploadFileInfoMap.get(importFileGuid);
                if (uploadFileInfo) {
                    uploadFileInfo.errorMessages.push([messages.validationNoMatchingFieldsText]);
                    uploadFileInfosToUpdate.push(uploadFileInfo);
                }
            }
            yield put(actions.setImportProductRecMatchResult(matchingResponse));
        }
        yield put(
            uploaderActions.updateUploadFileInfos(
                models.RX_FILE_IMPORT_STATE_KEY,
                uploadFileInfosToUpdate
            )
        );
        yield put(actions.setRxFileImportLoading(false));
    } catch (error) {
        console.log(error);
    }
}

function* updateImportFileColumn(action) {
    const {
        column,
        recNutrientEquationName,
    }: { column: models.RXFileImportSelectableColumnValue; recNutrientEquationName: string } =
        action.payload;
    const { matchData }: models.IRXFileImportState = yield select(selectors.getModuleState);
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const fieldGuids = [...fieldGuidToRecDetails.keys()];
    // Add batch template if it exists
    const isBatch = fieldGuidToRecDetails.has(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID);
    const recDetailsToUpdate = [];
    const importFileColumnName = column?.column;
    for (const fieldGuid of fieldGuids) {
        let importFileGuid = isBatch ? null : column?.importFileGuid;
        if (column != null && !importFileGuid) {
            const columnFieldMatch = matchData.matches.find(
                (match) =>
                    match.fieldGuids.includes(fieldGuid) &&
                    match.columns.includes(importFileColumnName)
            );
            if (columnFieldMatch) {
                importFileGuid = columnFieldMatch.importFileGuid;
            }
        }
        const recDetails = fieldGuidToRecDetails.get(fieldGuid);
        const newRecAreaList = recDetails.recAreaList.map((recArea) => {
            const newRecList = recArea.recs.map((rec) => {
                const newRecNutrientList = rec.recNutrientList.map((recNutrient) => {
                    if (recNutrient.equationName === recNutrientEquationName) {
                        recNutrient.importFileGuid = importFileGuid;
                        recNutrient.importFileColumnName = importFileColumnName;
                    }
                    return recNutrient;
                });
                return recsModels.Rec.updateRec(rec, { recNutrientList: newRecNutrientList });
            });
            return recsModels.RecArea.updateRecArea(recArea, { recs: newRecList });
        });
        recDetailsToUpdate.push(
            put(recsEventsActions.updateRecDetails(fieldGuid, { recAreaList: newRecAreaList }))
        );
    }
    if (recDetailsToUpdate.length > 0) {
        yield all(recDetailsToUpdate);
    }
}

function* onSetImportProductRecMatchResult() {
    const fieldMatchingErrors = yield select(selectors.getFieldMatchingErrors);
    const { filesValidated } = yield select(selectors.getModuleState);
    if (filesValidated && fieldMatchingErrors.length === 0) {
        yield put(actions.setMatchStep());
    }
}

function* prepareRecs() {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    const equationNamesNotInUse = new Set();
    const recsToUpdate = [...fieldGuidToRecDetails.values()].map((recDetails) => {
        const newRecAreaList = recDetails.recAreaList.map((recArea) => {
            const newRecList = recArea.recs.map((rec) => {
                const newRecNutrientList = rec.recNutrientList.filter((recNutrient) => {
                    if (recNutrient.importFileGuid) {
                        return true;
                    }
                    equationNamesNotInUse.add(recNutrient.equationName);
                });
                return recsModels.Rec.updateRec(rec, { recNutrientList: newRecNutrientList });
            });
            return recsModels.RecArea.updateRecArea(recArea, { recs: newRecList });
        });
        return put(
            recsEventsActions.updateRecDetails(recDetails.fieldGuid, {
                recAreaList: newRecAreaList,
            })
        );
    });
    yield all(recsToUpdate);
}

export const rxFileImportSaga = function* () {
    all([
        yield takeEvery(actions.ON_NEXT_STEP, onNextStep),
        yield takeEvery(actions.PREPARE_RECS, prepareRecs),
        yield takeEvery(actions.RX_FILE_IMPORT_RESET, onRxFileImportReset),
        yield takeEvery(actions.SETUP_RX_FILE_IMPORT, onRxFileImportSetup),
        yield takeEvery(actions.SET_IMPORT_COLUMN, updateImportFileColumn),
        yield takeEvery(
            actions.SET_IMPORT_PRODUCT_REC_MATCH_RESULT,
            onSetImportProductRecMatchResult
        ),
        yield takeLatest(recsEventsActions.REPLACE_REC_GENERAL, onDetermineStep),
        yield takeLatest(fileImportActions.UPLOADS_PROCESSED, onUploadsProcessed),
    ]);
};
