import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { service, actions } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { withMasked, withNewApiResult, withValidateImportResults, withCrud } from "~/hocs";
// Components
import AddEditPanel from "./add-edit/add-edit-container";
import { ImportExportHeader } from "../components/import-export-header";
import { DataTable, DialogBox, Button } from "~/core";
import { GeneLog } from "./gene-log";
import { messages } from "../i18n-messages";
import SlidingPanel from "~/sliding-panel/sliding-panel";

export class Gene_ extends Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        intl: intlShape.isRequired,
        addEditPanel: PropTypes.object,
        closeSidePanel: PropTypes.func,
        actions: PropTypes.object,
        toggleActiveInactive: PropTypes.func,
        needs: PropTypes.func,
        onSubmit: PropTypes.func,
        deleteSelected: PropTypes.func,
    };
    static defaultProps = {};
    ///----------------------------///
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
        this.tableFooterOptions = [
            {
                label: "Delete Selected",
                action: this.props.deleteSelected,
            },
        ];
    }
    onToggleModalClick = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };
    render() {
        const { formatMessage } = this.props.intl;
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div className="content-table-container">
                <ImportExportHeader
                    {...this.props}
                    service={service}
                    onToggleModalClick={this.onToggleModalClick}
                    onToggleActiveClick={service.toggleColumnVisibility}
                />
                <DataTable
                    isEditable
                    isCheckbox
                    service={service}
                    messages={messages}
                    {...this.props}
                    footerOptions={this.tableFooterOptions}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "102",
                                childNameCode: "178",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.props.onSubmit()} />
                            <Button type="cancel" onClick={() => this.props.closeSidePanel()} />
                        </SlidingPanel>
                    </form>
                )}
                <DialogBox
                    className="view-log-dialog-box"
                    isOpen={this.state.isModalOpen}
                    draggable
                    unrestricted
                    onClose={() => this.onToggleModalClick()}
                    title={formatMessage(messages.geneLogHistory)}
                >
                    <GeneLog needs={this.props.needs} />
                </DialogBox>
            </div>
        );
    }
}
export const Gene = injectIntl(
    withMasked(
        withNewApiResult(
            withValidateImportResults(withCrud(Gene_, service, actions), actions.importData)
        )
    )
);
