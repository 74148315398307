import React from "react";
import PropTypes from "prop-types";
import { Button } from "~/core";
import "./button-bar.css";

export class ButtonBar extends React.Component {
    static propTypes = {
        saveIsEnabled: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="non-field-feature-information-button-bar">
                <Button
                    type="save"
                    disabled={!this.props.saveIsEnabled}
                    onClick={() => {
                        this.props.onSave();
                    }}
                />
                <Button
                    type="cancel"
                    onClick={() => {
                        this.props.onCancel();
                    }}
                />
            </div>
        );
    }
}
