import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { mapToolsActions } from "~/map";
import { Loader } from "~/core";
import { actions as cdActions } from "~/customer-data";
import { Toolset } from "@ai360/core";

import { FieldList } from "../components/field-list/containers/field-list";
import FieldInformation from "../components/field-information/containers/field-information";
import {
    FieldModulePages,
    clearFieldInfo,
    setIsFieldLoading,
    setAgvanceFieldData,
} from "../actions";
import { getIsFieldLoading } from "../selectors";
import { IFieldsToMove } from "../models";
import { CustomerInfo } from "~/customer-data/models";

import "./field-module.css";

interface IProps {
    togglePanelNavigationEnabled: (value: boolean) => void;
    onSetActiveToolset: (toolset: Toolset, payload?: any) => void;
    intl: intlShape;
    selectField: (fieldGuid: string) => void;
    clearFieldModel: () => void;
    isFieldLoading: boolean;
    setIsFieldLoading: (value: boolean) => void;
    setAgvanceFieldData: (payload: IFieldsToMove) => void;
    loadDetailPage: () => void;
}

interface IState {
    activePage: FieldModulePages;
    editFieldGuid: string;
    editCustomer: CustomerInfo;
}

class FieldModule extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            activePage: FieldModulePages.FIELD_LIST,
            editFieldGuid: "",
            editCustomer: undefined,
        };
    }

    private onAddEditField(customer: CustomerInfo, fieldGuid: string) {
        this.props.setAgvanceFieldData(null);
        this.props.togglePanelNavigationEnabled(false);
        this.props.onSetActiveToolset(Toolset.DRAW_EDIT, {
            customerGuid: customer.customerGuid,
            fieldGuid,
        });
        if (fieldGuid != null) {
            this.props.selectField(fieldGuid);
        }
        this.props.setIsFieldLoading(true);

        this.setState({
            activePage: FieldModulePages.FIELD_INFORMATION,
            editCustomer: customer,
            editFieldGuid: fieldGuid,
        });
    }

    private onCloseFieldInformation() {
        this.props.togglePanelNavigationEnabled(true);
        this.props.onSetActiveToolset(Toolset.DEFAULT);
        // If the field is now inactive, force deselect it.
        this.props.clearFieldModel();

        this.setState({
            activePage: FieldModulePages.FIELD_LIST,
            editFieldGuid: "",
        });
    }

    render() {
        const { isFieldLoading, loadDetailPage, togglePanelNavigationEnabled } = this.props;
        const { activePage, editCustomer, editFieldGuid } = this.state;
        return (
            <div className="field-module">
                {!isFieldLoading ? null : <Loader className="module-loader" />}
                {activePage !== FieldModulePages.FIELD_LIST ? null : (
                    <FieldList
                        onAddEditField={this.onAddEditField.bind(this)}
                        togglePanelNavigationEnabled={(isEnabled: boolean) =>
                            togglePanelNavigationEnabled(isEnabled)
                        }
                        loadDetailPage={loadDetailPage}
                    />
                )}
                {activePage !== FieldModulePages.FIELD_INFORMATION ? null : (
                    <FieldInformation
                        activeCustomer={editCustomer}
                        editFieldGuid={editFieldGuid}
                        onCloseFieldInformation={() => this.onCloseFieldInformation()}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isFieldLoading: getIsFieldLoading(state),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSetActiveToolset: (toolset: Toolset, payload?: any) =>
            dispatch(mapToolsActions.setActiveToolset(toolset, payload)),
        setAgvanceFieldData: (payload: IFieldsToMove) => dispatch(setAgvanceFieldData(payload)),
        selectField: (fieldGuid: string) => dispatch(cdActions.addSelectedField(fieldGuid)),
        clearFieldModel: () => dispatch(clearFieldInfo()),
        setIsFieldLoading: (isLoading: boolean) => dispatch(setIsFieldLoading(isLoading)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FieldModule));
