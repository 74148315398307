import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { messages } from "./../../../i18n-messages";
import { model, service, constants } from "./../../data";
import { adminData, GUID, NAME } from "~/admin/data";
// Components
import { TextInput, Section, SubSection, Button, Checkbox, SelectInput } from "~/core";
import VendorList from "./vendor-list";
import { keywords as adminKeywords } from "~/admin/containers/keywords";
import { keywords } from "../../keywords";

export class FileMetadata extends Component {
    static propTypes = {
        fetchDropdownData: PropTypes.func,
        fileMetadata: PropTypes.object,
        formatMessage: PropTypes.func,
        mode: PropTypes.string,
        needs: PropTypes.func,
        resetImportTemplateListData: PropTypes.func,
        resetTemplate: PropTypes.func,
        setIsMonitorFileFlag: PropTypes.func,
        templateGuid: PropTypes.string,
        updateFileType: PropTypes.func,
        updateImportTemplateList: PropTypes.func,
        updateTemplate: PropTypes.func,
        vendorData: PropTypes.array,
        verifyTemplateFile: PropTypes.func,
    };

    static IMPORT_SAMPLE_TEMPLATE_GUID = "importSampleTemplateGuid";

    constructor(props) {
        super(props);
        this.templateManagerData = {
            importAttributes: [],
            fileTypes: "",
        };
        this.verifyTemplateRequest = "";
        this.fileSelectorInput = null;
        this.state = {
            importAttribute: {},
            importSampleTemplate: null,
            showDelimiter: true,
            templateName: null,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps[constants.IMPORT_TYPES] !== this.props[constants.IMPORT_TYPES] ||
            nextProps.fileMetadata[model.PROPS_IMPORT_TYPE_GUID] !==
                this.props.fileMetadata[model.PROPS_IMPORT_TYPE_GUID] ||
            nextProps[constants.IMPORT_SAMPLE_TEMPLATES] !==
                this.props[constants.IMPORT_SAMPLE_TEMPLATES] ||
            nextProps.vendorData !== this.props.vendorData ||
            (nextProps.fileMetadata.fileTypes !== this.props.fileMetadata.fileTypes &&
                nextProps.fileMetadata.fileTypes === service.defaultFileTypes) ||
            nextProps.fileMetadata.templateName === nextState.templateName
        ) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeImportTypes(nextProps);
        this.initializeImportSampleTemplates(nextProps);
        this.initializeVendorData(nextProps);

        if (
            nextProps.fileMetadata.fileTypes !== this.props.fileMetadata.fileTypes &&
            nextProps.fileMetadata.fileTypes === service.defaultFileTypes
        ) {
            this.setState({
                showDelimiter: false,
            });
        }

        if (nextProps.fileMetadata.templateName !== this.props.fileMetadata.templateName) {
            this.setState({
                templateName: nextProps.fileMetadata.templateName,
                isNonEditableFileTypeFlag:
                    nextProps.mode === adminKeywords.EDIT &&
                    (nextProps.fileMetadata.templateName === keywords.MODUS_FILE_NAME ||
                        nextProps.fileMetadata.templateName === keywords.IMAGERY_FILE_NAME),
            });
        }

        if (
            nextProps.fileMetadata.importTypeGuid !== this.props.fileMetadata.importTypeGuid &&
            this.fileSelectorInput != null
        ) {
            this.fileSelectorInput.value = null;
        }

        if (
            nextProps.fileMetadata.importSampleTemplateGuid !==
                this.props.fileMetadata.importSampleTemplateGuid ||
            nextProps.fileMetadata.importTypeGuid !== this.props.fileMetadata.importTypeGuid
        ) {
            if (
                nextProps.fileMetadata.importTypeGuid &&
                nextProps.fileMetadata.importSampleTemplateGuid
            ) {
                this.fetchVendorData({
                    importTypeGuid: nextProps.fileMetadata.importTypeGuid,
                    templateGuid: nextProps.templateGuid || "",
                    importSampleTemplateGuid: nextProps.fileMetadata.importSampleTemplateGuid,
                });
            }
        }
    }

    fetchVendorData = ({
        templateGuid = "",
        importTypeGuid = "",
        importSampleTemplateGuid = "",
    }) => {
        this.props.needs([
            this.props.fetchDropdownData({
                [constants.VENDOR_DATA]: {
                    url: service.urls.VENDOR_DATA_URL,
                    model: {
                        importTypeGuid,
                        templateGuid,
                        importSampleTemplateGuid,
                    },
                },
            }),
        ]);
    };

    fetchSampleAttributes = () => {
        this.props.needs([
            this.props.fetchDropdownData({
                [constants.SAMPLE_ATTRIBUTES]: {
                    url: service.urls.GET_SAMPLE_ATTRIBUTES_URL,
                    model: this.props.fileMetadata.importTypeGuid,
                },
            }),
        ]);
    };

    verifyTemplateFile = (fileObject) => {
        const { name, type } = fileObject.files[0];
        if (this.props.verifyTemplateFile) {
            this.verifyTemplateRequest = this.props.needs([
                this.props.verifyTemplateFile({
                    fileObject,
                }),
            ]);
        }
        let fileTypes = service.getFileTypes(type);
        if (!type) {
            const fileExt = name.substring(name.lastIndexOf(".") + 1, name.length);
            fileTypes = service.getFileTypes(fileExt);
        }
        this.props.updateImportTemplateList({
            key: model.PROPS_FILE_TYPES,
            value: fileTypes,
        });
        this.setState({
            showDelimiter: fileTypes !== service.defaultFileTypes,
        });
    };

    initializeImportTypes = (nextProps) => {
        if (nextProps[constants.IMPORT_TYPES] !== this.props[constants.IMPORT_TYPES]) {
            this.setState({
                [constants.IMPORT_TYPES]: this.prepareSelectableOptions(
                    nextProps[constants.IMPORT_TYPES],
                    {
                        guid: GUID,
                        label: NAME,
                    }
                ),
            });
        }

        if (
            nextProps.fileMetadata[model.PROPS_IMPORT_TYPE_GUID] !==
            this.props.fileMetadata[model.PROPS_IMPORT_TYPE_GUID]
        ) {
            this.onImportAttributeChange(
                this.getImportTypeValue(nextProps.fileMetadata[model.PROPS_IMPORT_TYPE_GUID])
            );
        }
    };

    initializeImportSampleTemplates = (nextProps) => {
        if (
            nextProps[constants.IMPORT_SAMPLE_TEMPLATES] !==
            this.props[constants.IMPORT_SAMPLE_TEMPLATES]
        ) {
            this.setState({
                [constants.IMPORT_SAMPLE_TEMPLATES]: nextProps[
                    constants.IMPORT_SAMPLE_TEMPLATES
                ].map((item) => ({
                    value: item[FileMetadata.IMPORT_SAMPLE_TEMPLATE_GUID],
                    label: item[NAME],
                })),
            });
        }
    };

    initializeVendorData = (nextProps) => {
        if (nextProps.vendorData !== this.props.vendorData) {
            this.setState({
                vendorData: nextProps.vendorData,
            });
        }
    };

    prepareSelectableOptions = (options, { guid, label }) =>
        _.reduce(
            options,
            (result, option) => {
                let labelText = option[label];
                result.push({
                    value: { guid: option[guid], label: labelText },
                    label: labelText,
                });
                return result;
            },
            []
        );

    onImportAttributeChange = (value) => {
        if (value) {
            this.props.resetTemplate();
            if (service.isMonitorType(value.label)) {
                this.props.setIsMonitorFileFlag(true);
            } else {
                this.props.setIsMonitorFileFlag(false);
            }
            this.props.updateFileType({
                fileType: {
                    label: value.label,
                    guid: value.guid,
                },
            });
        }
    };

    onFileSelect = () => {
        this.fileSelectorInput.click();
    };

    getFileTypes = (name, type) => {
        const { importAttribute } = this.state;
        if (service.isECData(importAttribute.label)) {
            const ecDataFileExt = type || name.split(".")[1];
            return service.getFileTypes(ecDataFileExt);
        } else {
            return service.getFileTypes(type);
        }
    };

    importTemplateFile = (fileObject) => {
        if (fileObject && fileObject.files.length > 0) {
            const { name } = fileObject.files[0];
            const { importAttribute } = this.state;
            this.setState({
                currentTemplateFilename: name,
            });
            this.verifyTemplateFile(fileObject, importAttribute);
        }
    };

    getImportTypeValue = (selectedGuid) => {
        const selectedImportType = _.filter(
            this.state[constants.IMPORT_TYPES],
            (obj) => obj.value.guid === selectedGuid
        );
        if (selectedImportType.length > 0) {
            return selectedImportType[0].value;
        } else {
            return {};
        }
    };

    getImportSampleTemplatesValue = (selectedGuid) => {
        const selectedImportType = _.filter(
            this.state[constants.IMPORT_SAMPLE_TEMPLATES],
            (obj) => obj.value.guid === selectedGuid
        );
        if (selectedImportType.length > 0) {
            return selectedImportType[0].value;
        } else {
            return null;
        }
    };

    render() {
        const { formatMessage, fileMetadata } = this.props;
        const selectedFileType = this.getImportTypeValue(
            fileMetadata[model.PROPS_IMPORT_TYPE_GUID]
        );
        const disableFlag = fileMetadata[model.PROPS_TEMPLATE_NAME] === null;
        const { isNonEditableFileTypeFlag } = this.state;
        return (
            <Section>
                <SubSection className="file-metadata-cont">
                    <SelectInput
                        containerClassNames={["file-type-select"]}
                        required
                        autoFocus
                        openOnFocus={false}
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        disabled={isNonEditableFileTypeFlag}
                        value={selectedFileType}
                        options={this.state[constants.IMPORT_TYPES]}
                        placeholderText={formatMessage(messages.fileType)}
                        onChange={this.onImportAttributeChange}
                        clearable={false}
                    />
                    {!service.isManagementAreaType(selectedFileType.label) ? null : (
                        <div className="free-text">
                            <Checkbox
                                label={formatMessage(messages.manualOnly)}
                                value={fileMetadata[model.PROPS_MANUAL_ONLY]}
                                onChange={(event, value) => {
                                    this.props.resetImportTemplateListData({
                                        [model.PROPS_MANUAL_ONLY]: value,
                                        [model.PROPS_IMPORT_TYPE_GUID]: value
                                            ? this.props.fileMetadata[model.PROPS_IMPORT_TYPE_GUID]
                                            : "",
                                        [model.PROPS_IMPORT_ATTRIBUTES]: [],
                                    });
                                    if (value) {
                                        this.fetchSampleAttributes();
                                    }
                                }}
                            />
                        </div>
                    )}
                    {!service.isSampleFileType(selectedFileType.label) ? null : (
                        <SelectInput
                            containerClassNames={["file-type-select"]}
                            required
                            clearable={false}
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            disabled={isNonEditableFileTypeFlag}
                            value={fileMetadata[model.PROPS_IMPORT_SAMPLE_TEMPLATE_GUID]}
                            options={this.state[constants.IMPORT_SAMPLE_TEMPLATES]}
                            placeholderText={formatMessage(messages.importSampleTemplates)}
                            onChange={(value) => {
                                this.props.updateImportTemplateList({
                                    key: model.PROPS_IMPORT_SAMPLE_TEMPLATE_GUID,
                                    value,
                                });
                            }}
                        />
                    )}
                    <p className="free-text">Select a file on which to base your template:</p>
                    <Button
                        type="selectFile"
                        onClick={this.onFileSelect}
                        disabled={
                            !fileMetadata[model.PROPS_IMPORT_TYPE_GUID] ||
                            fileMetadata[model.PROPS_MANUAL_ONLY] ||
                            isNonEditableFileTypeFlag
                        }
                    />
                    <input
                        ref={(e) => (this.fileSelectorInput = e)}
                        style={{ display: "none" }}
                        type="file"
                        accept={service.getSupportedFileTypes()}
                        onChange={() => {
                            this.importTemplateFile(this.fileSelectorInput);
                        }}
                    />
                    <TextInput
                        required
                        maxLength={50}
                        disabled={
                            !fileMetadata[model.PROPS_MANUAL_ONLY] &&
                            (disableFlag || isNonEditableFileTypeFlag)
                        }
                        value={fileMetadata[model.PROPS_TEMPLATE_NAME]}
                        onChange={(value) => {
                            this.props.updateImportTemplateList({
                                key: model.PROPS_TEMPLATE_NAME,
                                value,
                            });
                            this.props.updateTemplate({
                                key: model.PROPS_TEMPLATE_NAME,
                                value,
                            });
                        }}
                        placeholderText={
                            service.isManagementAreaType(selectedFileType.label)
                                ? formatMessage(messages.managementAreaTypeName)
                                : formatMessage(messages.templateName)
                        }
                        labelText={
                            service.isManagementAreaType(selectedFileType.label)
                                ? formatMessage(messages.managementAreaTypeName)
                                : formatMessage(messages.templateName)
                        }
                    />
                    <TextInput
                        required
                        maxLength={100}
                        disabled={
                            disableFlag ||
                            isNonEditableFileTypeFlag ||
                            fileMetadata[model.PROPS_MANUAL_ONLY]
                        }
                        value={fileMetadata[model.PROPS_FILE_TYPES]}
                        onChange={(value) => {
                            this.props.updateImportTemplateList({
                                key: model.PROPS_FILE_TYPES,
                                value,
                            });
                        }}
                        placeholderText={formatMessage(messages.supportedFileTypes)}
                        labelText={formatMessage(messages.supportedFileTypes)}
                    />
                    {!this.state.showDelimiter ? null : (
                        <TextInput
                            required
                            maxLength={5}
                            disabled={true}
                            value={fileMetadata[model.PROPS_DELIMITER] || ""}
                            onChange={(value) => {
                                this.props.updateImportTemplateList({
                                    key: model.PROPS_DELIMITER,
                                    value,
                                });
                            }}
                            placeholderText={formatMessage(messages.delimiter)}
                            labelText={formatMessage(messages.delimiter)}
                            containerClassNames={["delimiter-container"]}
                        />
                    )}
                    {!service.isECData(selectedFileType.label) ? null : (
                        <div className="free-text">
                            <Checkbox
                                label={formatMessage(messages.statisticalOutliersOption)}
                                value={fileMetadata[model.PROPS_STATISTICAL_OUTLIERS_YN]}
                                onChange={(event, value) => {
                                    this.props.updateImportTemplateList({
                                        key: model.PROPS_STATISTICAL_OUTLIERS_YN,
                                        value,
                                    });
                                }}
                            />
                            <Checkbox
                                label={formatMessage(messages.ignoreNegativeValues)}
                                value={fileMetadata[model.PROPS_IGNORE_NEGATIVE_VALUES]}
                                onChange={(event, value) => {
                                    this.props.updateImportTemplateList({
                                        key: model.PROPS_IGNORE_NEGATIVE_VALUES,
                                        value,
                                    });
                                }}
                                disabled={isNonEditableFileTypeFlag}
                            />
                        </div>
                    )}
                    {!service.isSampleFileType(selectedFileType.label) ? null : (
                        <Section>
                            <SubSection className="free-text vendor-grid-cont">
                                <VendorList
                                    formatMessage={formatMessage}
                                    options={this.state.vendorData}
                                    record={fileMetadata[model.PROPS_VENDOR_LIST]}
                                    onTextChange={(e, value) => {
                                        this.props.updateImportTemplateList({
                                            key: model.PROPS_VENDOR_LIST,
                                            value,
                                        });
                                    }}
                                    itemListAlias={model.PROPS_VENDOR_LIST}
                                />
                            </SubSection>
                        </Section>
                    )}
                </SubSection>
            </Section>
        );
    }
}
