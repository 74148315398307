import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Section, SubSection, Tabs, Pane } from "~/core";
import { messages } from "../i18n-messages";
import { getReleaseNotes } from "../../login/selectors";
import "./about.css";
import "react-quill/dist/quill.snow.css";
import "../../core/components/text-editor/text-editor.css";

export interface IAbout_Props {
    intl: intlShape;
    releaseNotesProps?: string;
}
export interface IAbout_State {
    selectedTabIdx: number;
}
export class About_ extends Component<IAbout_Props, IAbout_State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabIdx: 0,
        };
    }

    switchTabs = (idx) => {
        this.setState({
            selectedTabIdx: idx,
        });
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { releaseNotesProps } = this.props;
        const { selectedTabIdx } = this.state;
        const buildStr = window.process_env.__BUILD_STR__.split("|");
        const releaseNotesPane = (
            <Pane key={1} label={<FormattedMessage {...messages.releaseNotes} />}>
                <div className="ql-container ql-snow">
                    <div className="ql-editor">
                        <div className="release-notes">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: releaseNotesProps,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Pane>
        );
        const versionsPane = (
            <Pane key={0} label={<FormattedMessage {...messages.version} />}>
                <div className="build-version">
                    <Section>
                        <SubSection>
                            <div className="sub-description-container">
                                {formatMessage(messages.build)}:{" "}
                                <span className="sub-description">{buildStr[0]}</span>
                            </div>
                        </SubSection>
                        <SubSection>
                            <div className="sub-description-container">
                                {formatMessage(messages.date)}:{" "}
                                <span className="sub-description">{buildStr[1]}</span>
                            </div>
                        </SubSection>
                    </Section>
                </div>
            </Pane>
        );
        return (
            <div className="add-edit-panel">
                <div className="about">
                    <Tabs
                        className="filter-tabs"
                        selected={selectedTabIdx}
                        onTabSelect={(idx) => this.switchTabs(idx)}
                    >
                        {versionsPane}
                        {releaseNotesPane}
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        releaseNotesProps: getReleaseNotes(state),
    };
};

export const About = connect(mapStateToProps)(injectIntl(About_));
