import React from "react";
import {
    AutoSearch,
    Loader,
    NumericInput,
    Pane,
    SelectInput,
    Tabs,
    ToolbarMenu,
    ToolbarMenuContent,
} from "~/core";
import { LayerAPI } from "@ai360/core";
import { SearchTools, IToolbar } from "@ai360/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";

export class SearchToolSet extends SearchTools implements IToolbar {
    getToolbar(): JSX.Element {
        const { messages, formatMessage, userGuid } = this.options;

        return (
            <ToolbarMenu
                className="search"
                label={formatMessage(messages.searchLbl)}
                icon={ToolbarIcon(IconType.SEARCH)}
                onToggle={(evt) => this.toggle(evt)}
            >
                <ToolbarMenuContent className="search-tools">
                    <Tabs
                        selected={this.selectedTab}
                        onPreTabSelect={() => this.reset()}
                        onTabSelect={(index) => this.selectTab(index)}
                    >
                        <Pane label={formatMessage(messages.searchPlaceTabLbl)}>
                            <div
                                className="search-pane-content search-city"
                                ref={(scd) => (this.searchCityDiv = scd)}
                            ></div>
                        </Pane>
                        <Pane label={formatMessage(messages.searchXYTabLbl)}>
                            <div className="search-pane-content">
                                <NumericInput
                                    placeholderText={formatMessage(messages.latitudeInputLbl)}
                                    value={this.y}
                                    allowNegatives={true}
                                    hasError={this.errors.y.hasError}
                                    errorText={this.errors.y.errorText}
                                    onChange={(y) => (this.y = y)}
                                    onKeyDown={(evt) => this._handleXYKeyDown(evt)}
                                />
                                <NumericInput
                                    placeholderText={formatMessage(messages.longitudeInputLbl)}
                                    value={this.x}
                                    allowNegatives={true}
                                    hasError={this.errors.x.hasError}
                                    errorText={this.errors.x.errorText}
                                    onChange={(x) => (this.x = x)}
                                    onKeyDown={(evt) => this._handleXYKeyDown(evt)}
                                />
                            </div>
                        </Pane>
                        <Pane label={formatMessage(messages.searchPLSSTabLbl)}>
                            <div className="search-pane-content">
                                {this.isLoadingPLSS && <Loader className="search-loader" />}
                                <SelectInput
                                    placeholderText={formatMessage(messages.stateLbl)}
                                    options={this.states}
                                    clearable={false}
                                    value={this.selectedState}
                                    onChange={(value) =>
                                        this._selectPlss(LayerAPI.PLSSTypes.STATE, value)
                                    }
                                />
                                <SelectInput
                                    placeholderText={formatMessage(messages.townshipLbl)}
                                    options={this.townships}
                                    clearable={false}
                                    value={this.selectedTownship}
                                    disabled={this.townships.length === 0}
                                    onChange={(value) =>
                                        this._selectPlss(LayerAPI.PLSSTypes.TOWNSHIP, value)
                                    }
                                />
                                <SelectInput
                                    placeholderText={formatMessage(messages.rangeLbl)}
                                    options={this.ranges}
                                    clearable={false}
                                    value={this.selectedRange}
                                    disabled={this.ranges.length === 0}
                                    onChange={(value) =>
                                        this._selectPlss(LayerAPI.PLSSTypes.RANGE, value)
                                    }
                                />
                                <SelectInput
                                    placeholderText={formatMessage(messages.sectionLbl)}
                                    options={this.sections}
                                    clearable={false}
                                    value={this.selectedSection}
                                    disabled={this.sections.length === 0}
                                    onChange={(value) =>
                                        this._selectPlss(LayerAPI.PLSSTypes.SECTION, value)
                                    }
                                />
                            </div>
                        </Pane>
                        <Pane label={formatMessage(messages.searchNonFieldLbl)}>
                            {this.selectedTab !== 3 ? null : (
                                <div className="auto-search-pane-content">
                                    <AutoSearch
                                        itemList={this.nonFieldFeatures}
                                        keyProp={"value"}
                                        nameProp={"label"}
                                        onSearchChange={(searchValue) =>
                                            this._updateSearchValue(searchValue)
                                        }
                                        onSelection={(search) =>
                                            this._selectedNonFieldFeature(search)
                                        }
                                        placeholderText={formatMessage(
                                            messages.nonFieldFeatureSearchLbl
                                        )}
                                        secondaryPropList={["secondaryPropList"]}
                                        selectedValue={this.selectedNonFieldFeature}
                                    />
                                </div>
                            )}
                        </Pane>
                        <Pane label={formatMessage(messages.searchEventIdLbl)}>
                            {this.selectedTab !== 4 ? null : (
                                <div className="auto-search-pane-content">
                                    <AutoSearch
                                        itemList={this.eventIds}
                                        keyProp={"value"}
                                        nameProp={"label"}
                                        onSearchChange={(searchValue) =>
                                            this._updateSearchValue(searchValue)
                                        }
                                        onSelection={(search) => this._searchEventId(search)}
                                        placeholderText={formatMessage(messages.eventIdSearchLbl)}
                                        secondaryPropList={["secondaryPropList"]}
                                        selectedValue={this.selectedEventId}
                                        userGuid={userGuid}
                                    />
                                </div>
                            )}
                        </Pane>
                    </Tabs>
                </ToolbarMenuContent>
            </ToolbarMenu>
        );
    }
}
