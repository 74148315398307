import React from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AddIcon } from "~/core/icons";
import { messages } from "../../i18n-messages";
import "./add-customer-link.css";

class AddCustomerLink_ extends React.Component {
    /// Prop type validation, Default prop values ///
    static propTypes = {
        className: PropTypes.string,
        intl: intlShape,
        loadDetailPage: PropTypes.func,
    };
    static defaultProps = {};
    /// ----------------------------------------- ///

    /// Event Handlers ///
    onAddCustomerClick = (e) => {
        e.preventDefault();
        e.target.blur();
        this.props.loadDetailPage("212", "101");
    };
    /// ------------- ///

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div
                className={classNames(
                    "add-customer-link",
                    "customer-header",
                    "add-new-customer",
                    this.props.className
                )}
            >
                <a
                    href="#/"
                    title={formatMessage(messages.addHint)}
                    onClick={this.onAddCustomerClick}
                >
                    <AddIcon />
                    {formatMessage(messages.addCustomerText)}
                </a>
            </div>
        );
    }
}

export const AddCustomerLink = injectIntl(AddCustomerLink_);
