import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { AdminScriptsAPI, AppHelpers } from "@ai360/core";

import { OrgLevelList } from "~/admin/agBytes/components/org-level-list";
import { adminData } from "~/admin/data";
import { getAgBytesErrorClassNames, preventBubbleUp } from "~/admin/utils";
import {
    Button,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    Section,
    SelectInput,
    SubSection,
    TextArea,
} from "~/core";
import { PICKLIST_CROPPING_SEASON } from "~/core/picklist/picklist-names";
import { MSGTYPE } from "~/notifications";
import {
    EVENT_TYPE_NAME_APPLICATION,
    EVENT_TYPE_NAME_EC_DATA,
    EVENT_TYPE_NAME_HARVEST,
    EVENT_TYPE_NAME_PLANTING,
    EVENT_TYPE_NAME_SAMPLING_SOIL,
    EVENT_TYPE_NAME_SAMPLING_TISSUE,
} from "~/recs-events/events/model";
import SlidingPanel from "~/sliding-panel/sliding-panel";

import { messages } from "../i18n-messages";

import "./admin-scripts.css";
import { ISelectOption } from "~/core/components/select-input/model";

enum Script {
    CreateEventDefinedLegends = "CreateEventDefinedLegends",
    ResurfaceEvents = "ResurfaceEvents",
    SetEventDefinedLegendsAsDefault = "SetEventDefinedLegendsAsDefault",
}

interface IAdminScriptsProps {
    fetchDropdownData: () => any[];
    fetchFilteredLocationList: (guid: string) => any[];
    fetchPicklists: () => any[];
    fetchUserCompanyList: () => any[];
    filteredLocationList: any[];
    lastUsedOwnerGuid: string;
    loggedInUserGuid: string;
    [PICKLIST_CROPPING_SEASON]: any[];
    onApiCallErr: (err: any) => void;
    onPushToasterMessage: (message: string, type: string) => void;
    setCompanyUserPreference: (guid: string) => void;
    userCompanyList: { companyName: string; companyGuid: string }[];
}

interface IAdminScriptsState {
    confirmationModalOpen: boolean;
    confirmationModalScriptPreview: string;
    croppingSeasons: any[];
    orgLevelList: any[];
    running: boolean;
    scriptOptions: ISelectOption<IAdminScript>[];
    selectedCompany: string;
    selectedOrgLevels: any[];
    selectedScript: IAdminScript;
    selectedScriptParams: IAdminScriptParams;
    specificAgEventGeneralGuids: string[];
    specificAgEventGeneralGuidsTextAreaFocused: boolean;
    userCompanyList: { label: string; value: string }[];
    useSpecificAgEventGeneralGuids: boolean;
}

interface IAdminScript {
    id: Script;
    runScript?: (preview?: boolean) => void;
}

interface IAdminScriptParams {
    agEventGeneralGuids?: string[];
    croppingSeasonGuid?: string;
    importEvent?: string;
    overwritePrevious?: boolean;
}

const IMPORT_EVENT_OPTIONS = [
    EVENT_TYPE_NAME_APPLICATION,
    EVENT_TYPE_NAME_EC_DATA,
    EVENT_TYPE_NAME_HARVEST,
    EVENT_TYPE_NAME_PLANTING,
    EVENT_TYPE_NAME_SAMPLING_SOIL,
    EVENT_TYPE_NAME_SAMPLING_TISSUE,
].map((ev) => ({ label: ev, value: ev }));

export class AdminScripts_ extends Component<
    IAdminScriptsProps & InjectedIntlProps,
    IAdminScriptsState
> {
    private specificAgEventGeneralGuidsTextAreaRef: TextArea;

    constructor(props: IAdminScriptsProps) {
        super(props);
        const scriptOptions = this.prepareScriptOptions();
        this.state = {
            confirmationModalOpen: false,
            confirmationModalScriptPreview: "",
            croppingSeasons: this.formatCroppingSeasons(props),
            orgLevelList: [],
            running: false,
            scriptOptions,
            selectedCompany: null,
            selectedOrgLevels: [],
            selectedScript: null,
            selectedScriptParams: {},
            specificAgEventGeneralGuids: [],
            specificAgEventGeneralGuidsTextAreaFocused: false,
            userCompanyList: [],
            useSpecificAgEventGeneralGuids: false,
        };
    }
    UNSAFE_componentWillMount(): void {
        this.props.fetchDropdownData();
        this.props.fetchPicklists();
        this.props.needs([this.props.fetchUserCompanyList()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAdminScriptsProps): void {
        const { filteredLocationList } = nextProps;
        const newState: any = {};
        if (filteredLocationList !== this.props.filteredLocationList) {
            newState.orgLevelList = AppHelpers.parseOrgLevelList(
                (filteredLocationList || []).map((oll) => ({
                    ...oll,
                    orgLevelParents: oll.orgLevelParents.map(({ name }) => name),
                    orgLevelParentsGuids: oll.orgLevelParents.map(({ guid }) => guid),
                })),
                "orgLevelParents",
                " > ",
                (filteredLocationList || []).find((ol) => ol.orgLevelParents.length === 0)
            );
            if (newState.orgLevelList.length === 1) {
                newState.selectedOrgLevels = [{ ...newState.orgLevelList[0] }];
            }
        }
        if (nextProps[PICKLIST_CROPPING_SEASON] !== this.props[PICKLIST_CROPPING_SEASON]) {
            newState.croppingSeasons = this.formatCroppingSeasons(nextProps);
            newState.selectedScriptParams = {
                ...this.state.selectedScriptParams,
                croppingSeasonGuid: newState.croppingSeasons.find((cs) => {
                    return cs.label.localeCompare(new Date().getFullYear()) === 0;
                })?.value,
            };
        }
        if (nextProps.userCompanyList !== this.props.userCompanyList) {
            newState.userCompanyList = nextProps.userCompanyList.map(
                ({ companyName, companyGuid }) => ({
                    label: companyName,
                    value: companyGuid,
                })
            );
        }

        if (nextProps.lastUsedOwnerGuid !== this.props.lastUsedOwnerGuid) {
            newState.selectedCompany = nextProps.lastUsedOwnerGuid;
        }
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    private formatCroppingSeasons(props) {
        return (props[PICKLIST_CROPPING_SEASON] || [])
            .map((cs) => ({
                label: cs.value,
                value: cs.picklistValueGuid,
            }))
            .sort((a, b) => Number(a.label) - Number(b.label));
    }

    private getOrgLevelAndChildren(orgLevels: { orgLevelGuid: string }[]): string[] {
        const orgLevelGuids = [];
        for (const orgLvl of orgLevels) {
            orgLevelGuids.push(orgLvl.orgLevelGuid);
            for (const ol of this.state.orgLevelList.filter((ol) =>
                ol.orgLevelParentsGuids.includes(orgLvl.orgLevelGuid)
            )) {
                orgLevelGuids.push(ol.orgLevelGuid);
            }
        }
        return orgLevelGuids;
    }

    private onCompanyNameChange(selectedCompany) {
        this.props.setCompanyUserPreference(selectedCompany);
        this.setState(
            {
                selectedCompany,
                selectedOrgLevels: [],
                orgLevelList: [],
            },
            () => {
                this.props.needs([this.props.fetchFilteredLocationList(selectedCompany)]);
            }
        );
    }

    private onConfirm() {
        if (this.state.selectedScript == null) {
            return;
        }
        if (this.state.selectedScript.runScript != null) {
            this.state.selectedScript.runScript(true);
        } else {
            this.setState(
                {
                    running: true,
                },
                () => {
                    const { loggedInUserGuid } = this.props;
                    const { selectedOrgLevels, selectedScriptParams, specificAgEventGeneralGuids } =
                        this.state;
                    const options = {
                        orgLevelGuids: this.getOrgLevelAndChildren(selectedOrgLevels),
                        ...selectedScriptParams,
                        isPreview: true,
                    };
                    if (this.usingSpecificAgEventGeneralGuids()) {
                        options.agEventGeneralGuids = specificAgEventGeneralGuids;
                    }
                    AdminScriptsAPI.runAdminScript(
                        loggedInUserGuid,
                        this.state.selectedScript.id,
                        options
                    )
                        .then((result) => {
                            this.setState({
                                confirmationModalOpen: true,
                                confirmationModalScriptPreview: result,
                            });
                        })
                        .catch((err) => {
                            this.props.onApiCallErr(err);
                        })
                        .finally(() => {
                            this.setState({
                                running: false,
                            });
                        });
                }
            );
        }
    }

    private onRun() {
        if (this.state.selectedScript == null) {
            return;
        }
        if (this.state.selectedScript.runScript != null) {
            this.state.selectedScript.runScript();
        } else {
            this.setState(
                {
                    confirmationModalOpen: false,
                    confirmationModalScriptPreview: "",
                    running: true,
                },
                () => {
                    const { loggedInUserGuid } = this.props;
                    const { selectedOrgLevels, selectedScriptParams, specificAgEventGeneralGuids } =
                        this.state;
                    const { formatMessage } = this.props.intl;
                    const options = {
                        orgLevelGuids: this.getOrgLevelAndChildren(selectedOrgLevels),
                        ...selectedScriptParams,
                    };
                    if (this.usingSpecificAgEventGeneralGuids()) {
                        options.agEventGeneralGuids = specificAgEventGeneralGuids;
                    }
                    AdminScriptsAPI.runAdminScript(
                        loggedInUserGuid,
                        this.state.selectedScript.id,
                        options
                    )
                        .then((result) => {
                            this.props.onPushToasterMessage(
                                <div>
                                    {formatMessage(messages.adminScriptSuccessMsg)}
                                    <br />
                                    {result}
                                </div>,
                                MSGTYPE.INFO
                            );
                        })
                        .catch((err) => {
                            this.props.onApiCallErr(err);
                        })
                        .finally(() => {
                            this.setState({
                                running: false,
                                selectedScript: null,
                            });
                        });
                }
            );
        }
    }

    private prepareScriptOptions(): ISelectOption<IAdminScript>[] {
        const { formatMessage } = this.props.intl;
        return [
            {
                label: formatMessage(messages.adminScriptCreateEventDefinedLegends),
                value: { id: Script.CreateEventDefinedLegends },
            },
            {
                label: formatMessage(messages.adminScriptResurfaceEvents),
                value: { id: Script.ResurfaceEvents },
            },
            {
                label: formatMessage(messages.adminScriptSetDefinedLegendsAsDefault),
                value: { id: Script.SetEventDefinedLegendsAsDefault },
            },
        ];
    }

    private renderAdminScripts(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const usingSpecificAgEventGeneralGuids = this.usingSpecificAgEventGeneralGuids();

        const croppingSeason = (
            <SelectInput
                key="croppingSeason"
                required
                clearable={false}
                disabled={usingSpecificAgEventGeneralGuids}
                onChange={(guid) => this.setScriptParams("croppingSeasonGuid", guid)}
                options={this.state.croppingSeasons}
                placeholderText={formatMessage(messages.croppingSeason)}
                value={this.state.selectedScriptParams.croppingSeasonGuid}
            />
        );
        const forceOverwrite = (
            <Checkbox
                onChange={(e, guid) => {
                    this.setScriptParams("overwritePrevious", guid);
                }}
                disabled={usingSpecificAgEventGeneralGuids}
                value={this.state.selectedScriptParams.overwritePrevious}
                label={formatMessage(messages.overwritePrevious)}
            />
        );
        const importEvents = (
            <SelectInput
                key="importEvents"
                required
                clearable={false}
                disabled={usingSpecificAgEventGeneralGuids}
                onChange={(guid) => this.setScriptParams("importEvent", guid)}
                options={IMPORT_EVENT_OPTIONS}
                placeholderText={formatMessage(messages.eventType)}
                value={this.state.selectedScriptParams.importEvent}
            />
        );

        const scriptOptions = [];
        switch (this.state.selectedScript?.id) {
            case Script.CreateEventDefinedLegends:
                scriptOptions.push(croppingSeason, forceOverwrite);
                break;
            case Script.ResurfaceEvents:
                scriptOptions.push(croppingSeason, importEvents);
                break;
            case Script.SetEventDefinedLegendsAsDefault:
                scriptOptions.push(croppingSeason);
                break;
        }

        return (
            <div className="admin-scripts-container">
                <div className="section-container">
                    <Section headerText={formatMessage(messages.selectAdminScript)} required>
                        <SubSection>
                            <SelectInput<IAdminScript>
                                required
                                clearable={false}
                                onChange={(selectedScript: IAdminScript) =>
                                    this.setState({ selectedScript })
                                }
                                options={this.state.scriptOptions}
                                placeholderText={formatMessage(messages.adminScript)}
                                tabIndex={0}
                                value={this.state.selectedScript}
                            />
                        </SubSection>
                        {this.state.selectedScript?.id !== Script.ResurfaceEvents ? null : (
                            <SubSection>
                                <Checkbox
                                    label={formatMessage(messages.specificAgEventGeneralGuids)}
                                    value={this.state.useSpecificAgEventGeneralGuids}
                                    onChange={(_, useSpecificAgEventGeneralGuids) =>
                                        this.setState({
                                            useSpecificAgEventGeneralGuids,
                                        })
                                    }
                                />
                            </SubSection>
                        )}
                        {!usingSpecificAgEventGeneralGuids ? null : (
                            <SubSection>
                                <TextArea
                                    ref={(ref) =>
                                        (this.specificAgEventGeneralGuidsTextAreaRef = ref)
                                    }
                                    height="calc(100vh - 350px)"
                                    maxLength={2000000} /* 2 mil can handle ~50k+ guids */
                                    onFocus={() => {
                                        if (
                                            !this.state.specificAgEventGeneralGuidsTextAreaFocused
                                        ) {
                                            this.setState(
                                                {
                                                    specificAgEventGeneralGuidsTextAreaFocused:
                                                        true,
                                                },
                                                () => {
                                                    this.specificAgEventGeneralGuidsTextAreaRef?.focus();
                                                }
                                            );
                                        }
                                    }}
                                    onBlur={() =>
                                        this.state.specificAgEventGeneralGuidsTextAreaFocused &&
                                        this.setState({
                                            specificAgEventGeneralGuidsTextAreaFocused: false,
                                        })
                                    }
                                    placeholderText={
                                        this.state.specificAgEventGeneralGuids?.length > 0 ||
                                        this.state.specificAgEventGeneralGuidsTextAreaFocused
                                            ? formatMessage(messages.agEventGeneralGuids)
                                            : `${formatMessage(
                                                  messages.agEventGeneralGuids
                                              )}:\n00000000-0000-0000-0000-0000000000,\n00000000-0000-0000-0000-0000000000`
                                    }
                                    value={this.state.specificAgEventGeneralGuids.join(",\n")}
                                    onChange={this.setSpecificAgEventGeneralGuids}
                                />
                            </SubSection>
                        )}
                    </Section>
                    <span className="bar section-fence"></span>
                    <Section
                        headerText={formatMessage(messages.selectLocations)}
                        required={!usingSpecificAgEventGeneralGuids}
                    >
                        <SubSection>
                            <SelectInput
                                clearable={false}
                                disabled={usingSpecificAgEventGeneralGuids}
                                onChange={this.onCompanyNameChange.bind(this)}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state.userCompanyList}
                                placeholderText={formatMessage(messages.companyName)}
                                required
                                value={this.state.selectedCompany}
                            />
                        </SubSection>
                        <SubSection>
                            <OrgLevelList
                                disabled={usingSpecificAgEventGeneralGuids}
                                classNames={getAgBytesErrorClassNames([2033], this.props.apiErrors)}
                                itemList={this.state.orgLevelList}
                                onSelectionChange={(value) =>
                                    this.setState({ selectedOrgLevels: value })
                                }
                                record={this.state.selectedOrgLevels}
                                statePropName={adminData.PROPS_STATE_ABBREVIATION}
                            />
                        </SubSection>
                    </Section>
                    <span className="bar section-fence"></span>
                    <Section
                        headerText={formatMessage(messages.scriptOptions)}
                        required={!usingSpecificAgEventGeneralGuids}
                    >
                        {scriptOptions.map((opt, i) => (
                            <SubSection key={i}>{opt}</SubSection>
                        ))}
                    </Section>
                </div>
            </div>
        );
    }

    private setScriptParams(paramName, paramValue) {
        this.setState({
            selectedScriptParams: {
                ...this.state.selectedScriptParams,
                [paramName]: paramValue,
            } as IAdminScriptParams,
        });
    }

    private setSpecificAgEventGeneralGuids = AppHelpers.debounce((guids: string) => {
        const cleanGuidList = guids.replace(/\n/gm, ",").replace(/'|"|\{|\}|\(|\)|\s*/gm, "");
        const specificAgEventGeneralGuids = cleanGuidList.split(",").filter(Boolean);
        const delta = specificAgEventGeneralGuids.join(",\n").length - guids.length;
        const selectionRange =
            this.specificAgEventGeneralGuidsTextAreaRef?.getSelectionRange(delta);
        this.setState(
            {
                specificAgEventGeneralGuids,
            },
            () => {
                this.specificAgEventGeneralGuidsTextAreaRef?.focus(selectionRange);
            }
        );
    }, 750);

    private usingSpecificAgEventGeneralGuids() {
        return (
            this.state.selectedScript?.id === Script.ResurfaceEvents &&
            this.state.useSpecificAgEventGeneralGuids
        );
    }

    private validateScriptParamsReady() {
        const {
            selectedOrgLevels,
            selectedScript,
            selectedScriptParams,
            specificAgEventGeneralGuids,
        } = this.state;
        const usingSpecificAgEventGeneralGuids = this.usingSpecificAgEventGeneralGuids();
        if (
            !usingSpecificAgEventGeneralGuids &&
            (selectedOrgLevels == null || selectedOrgLevels.length === 0)
        ) {
            return false;
        }
        switch (selectedScript?.id) {
            case Script.CreateEventDefinedLegends:
            case Script.SetEventDefinedLegendsAsDefault:
                return selectedScriptParams.croppingSeasonGuid != null;
            case Script.ResurfaceEvents:
                return usingSpecificAgEventGeneralGuids
                    ? specificAgEventGeneralGuids?.length > 0
                    : selectedScriptParams.croppingSeasonGuid != null &&
                          selectedScriptParams.importEvent != null;
            default:
                return false;
        }
    }

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { running } = this.state;
        const scriptReady = this.validateScriptParamsReady();
        const props = {
            children: [
                <Button
                    key="0"
                    forceSubmit
                    type="button"
                    value="run"
                    disabled={!scriptReady || running}
                    onClick={() => this.onConfirm()}
                />,
                <Button key="1" type="close" onClick={this.props.closeSlidingPanel} />,
            ],
            component: this.renderAdminScripts.bind(this),
            navigateTo: { parentNameCode: 101, childNameCode: 189 },
        };
        const runScriptMsg = formatMessage(
            messages[`runScript${Math.floor(Math.random() * (4 - 1) + 1)}`]
        );
        return (
            <div className="admin-scripts-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    {running ? <Loader /> : null}
                    <SlidingPanel {...props} />
                </form>
                <DialogBox
                    action={runScriptMsg}
                    draggable={true}
                    footerType={DialogBoxFooterType.ACTION_CANCEL}
                    hideCloseX={true}
                    isOpen={this.state.confirmationModalOpen}
                    isModal={true}
                    onAction={() => this.onRun()}
                    onClose={() =>
                        this.setState({
                            confirmationModalOpen: false,
                            confirmationModalScriptPreview: "",
                        })
                    }
                    title={formatMessage(messages.confirmationModalTitle)}
                >
                    {formatMessage(messages.confirmationModalAreYourSure)}
                    <br />
                    <br />
                    {this.state.confirmationModalScriptPreview}
                </DialogBox>
            </div>
        );
    }
}
export const AdminScripts = injectIntl(AdminScripts_);
export default AdminScripts;
