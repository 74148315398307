import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import moment from "moment";
import { injectIntl, intlShape } from "react-intl";

import { setActiveModule } from "~/action-panel/actions";
import { getTriggeredPage } from "~/action-panel/components/event-module/selectors";
import { setTriggeredPage } from "~/action-panel/components/rec-module/actions";
import { optionPropType, Pane } from "~/core";
import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";
import * as eventListSelectors from "../../event-list/selectors";
import { setMergeableEvents } from "../../event-list/actions";
import { messages as globalMessages } from "~/i18n-messages";
import { config as intlConfig } from "~/intl-provider/config";
import {
    actions as recsEventsActions,
    eventsSelectors,
    models as recsEventsModels,
    selectors as recsEventsSelectors,
} from "~/recs-events";

import { getIsCanvasLoading } from "~/map/components/map-control/selectors";

import { formatEventStripText } from "../../common";
import { BatchRecEventStrip } from "../../../../common/accordion/batch-rec-event-strip";
import { RecEventItem } from "../../../../common/accordion/rec-event-accordion-item";
import { getEventStatusMessageId } from "../../../../common/status-messages";
import { getClassNameForStatusFromEvent } from "../../../../common/status-codes";

import {
    RecEventInfo,
    GeneralPaneLabel,
    FilterPaneLabel,
} from "../../../../common/rec-event-info/rec-event-info";

import {
    errorCodesApply as eventGeneralErrorCodesApply,
    EventGeneralForm,
} from "./event-general-form";

import { FormLabel } from "../../common";

import * as layerActions from "../../../../layer-module/components/layer-list/actions";

import { getAgEventTransactionTypeNameToFormMap } from "./transaction-type-form-map";
import { BatchEventsList } from "./batch-events-list";

import * as actions from "../actions";
import * as selectors from "../selectors";
import { messages } from "../i18n-messages";
const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
const importedEventCreationType = "Imported";

export class EventInfo_ extends PureComponent {
    static propTypes = {
        batchCopyReset: PropTypes.func.isRequired,
        batchFieldGuid: PropTypes.string,
        batchSize: PropTypes.number.isRequired,
        batchSamplingReset: PropTypes.func.isRequired,
        enableSave: PropTypes.bool.isRequired,
        eventDetails: PropTypes.object,
        eventSummary: PropTypes.object,
        fieldGuid: PropTypes.string,
        intl: intlShape.isRequired,
        isBatchCopy: PropTypes.bool.isRequired,
        isBatchTemplate: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isMergeEvent: PropTypes.bool,
        mergeableEvents: PropTypes.any,
        newableEventTypeInfoList: PropTypes.array.isRequired,
        onAddAgEvent: PropTypes.func.isRequired,
        onCopyBatchTemplate: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onMergeEvents: PropTypes.func,
        onRemoveAgEvent: PropTypes.func.isRequired,
        onReturnToBatchEventTemplate: PropTypes.func.isRequired,
        onReturnToBatchEventList: PropTypes.func.isRequired,
        onSaveChanges: PropTypes.func.isRequired,
        picklistOptionsCroppingSeason: PropTypes.arrayOf(optionPropType),
        removeSurface: PropTypes.func.isRequired,
        resetMergeEvents: PropTypes.func.isRequired,
        setActiveModule: PropTypes.func,
        setTriggeredPage: PropTypes.func,
        triggeredPage: PropTypes.string,
        saveEventDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number),
    };

    getAgEventTransactionTypeNameToFormMap() {
        if (this._agEventTransactionTypeNameToFormMap == null) {
            this._agEventTransactionTypeNameToFormMap = getAgEventTransactionTypeNameToFormMap();
        }
        return this._agEventTransactionTypeNameToFormMap;
    }

    _getAddEventMenuItems() {
        const { batchSize, eventDetails, onAddAgEvent, newableEventTypeInfoList } = this.props;

        if (!eventDetails) {
            return [];
        }

        if (eventDetails.isSamplingEvent) {
            return [];
        }

        const hasClassBreaks =
            eventDetails.eventAreaList &&
            eventDetails.eventAreaList.length > 0 &&
            eventDetails.eventAreaList.some((area) => area.eventAreaClassBreak != null);

        const existingTypeGuidSet = new Set(
            eventDetails.agEventTypeList.map(
                (eventTypeInfo) => eventTypeInfo.agEventTransactionTypeGuid
            )
        );

        if (
            eventDetails.creationType === importedEventCreationType &&
            eventDetails.agEventTypeList
                .map((ev) => ev.agEventTransactionTypeName)
                .includes(recsEventsModels.EVENT_TYPE_NAME_PLANTING)
        ) {
            return newableEventTypeInfoList
                .filter(
                    (eventTypeInfo) =>
                        eventTypeInfo.agEventTransactionTypeName ===
                        recsEventsModels.EVENT_TYPE_NAME_APPLICATION
                )
                .filter(
                    (eventTypeInfo) =>
                        !existingTypeGuidSet.has(eventTypeInfo.agEventTransactionTypeGuid)
                )
                .map((eventTypeInfo) => ({
                    key: eventTypeInfo.sampleTypeGuid || eventTypeInfo.agEventTransactionTypeGuid,
                    label: eventTypeInfo.agEventTransactionTypeName,
                    action: () =>
                        onAddAgEvent(
                            eventTypeInfo.agEventTransactionTypeGuid,
                            eventTypeInfo.sampleTypeGuid
                        ),
                    disabled: existingTypeGuidSet.has(eventTypeInfo.agEventTransactionTypeGuid),
                }));
        }
        return newableEventTypeInfoList
            .filter(
                (
                    eventTypeInfo // filter sample and scouting event types for aggregate/batch events
                ) =>
                    (eventTypeInfo.sampleType == null &&
                        eventTypeInfo.agEventTransactionTypeName !==
                            recsEventsModels.EVENT_TYPE_NAME_SCOUTING) ||
                    (eventDetails.agEventTypeList.length === 0 &&
                        batchSize === 0 &&
                        !(
                            hasClassBreaks &&
                            eventTypeInfo.agEventTransactionTypeName ===
                                recsEventsModels.EVENT_TYPE_NAME_SCOUTING
                        ))
            )
            .map((eventTypeInfo) => ({
                key: eventTypeInfo.sampleTypeGuid || eventTypeInfo.agEventTransactionTypeGuid,
                label: eventTypeInfo.agEventTransactionTypeName,
                action: () =>
                    onAddAgEvent(
                        eventTypeInfo.agEventTransactionTypeGuid,
                        eventTypeInfo.sampleTypeGuid
                    ),
                disabled: existingTypeGuidSet.has(eventTypeInfo.agEventTransactionTypeGuid),
            }));
    }

    _getEventStripItem() {
        const {
            batchSize,
            eventDetails,
            eventSummary,
            isBatchCopy,
            isBatchTemplate,
            picklistOptionsCroppingSeason,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;

        const eventId = eventDetails
            ? eventDetails.eventAreaList.map((eventArea) =>
                  eventArea.agEventList.map((agEvent) => agEvent.agEventModel.eventId)
              )
            : null;
        if (eventDetails == null) {
            return null;
        }
        const croppingSeasonOption = picklistOptionsCroppingSeason.find(
            (option) => option.value === eventDetails.croppingSeasonGuid
        );
        const momentCreatedDate = moment(eventDetails.momentCreatedDate).format("M/D/YY");
        const batchDisplayNameParts = eventSummary
            ? [eventSummary.agEventName, croppingSeasonOption?.label, momentCreatedDate]
            : [croppingSeasonOption?.label, momentCreatedDate];
        const batchLine2 = isBatchCopy
            ? messages.batchCopySummaryLine2
            : messages.batchSummaryLine2;

        const batchStripItem =
            batchSize === 0 ? null : isBatchTemplate ? (
                <div className="item-container batch-strip">
                    <RecEventItem
                        isSelected
                        displayName={batchDisplayNameParts.join(" - ")}
                        summary={formatMessage(batchLine2, { batchSize })}
                    />
                </div>
            ) : (
                <BatchRecEventStrip />
            );
        const displayText = formatEventStripText(eventDetails, croppingSeasonOption?.label);
        const eventTypeInfo = eventDetails.agEventTypeList[0];
        const eventStripItemProps = isBatchTemplate
            ? null
            : {
                  eventId: eventId,
                  displayName: displayText,
                  isSelected: batchSize > 0,
                  summary: [
                      eventSummary.customerName,
                      eventSummary.farmName,
                      eventSummary.fieldName,
                      `${formatNumber(
                          eventSummary.fieldAcres,
                          intlConfig.numberFormatOptions
                      )} ${formatMessage(globalMessages.acres)}`,
                  ]
                      .filter((v) => v && v !== "")
                      .join(", "),
                  statusCodeClassName: !eventSummary
                      ? ""
                      : getClassNameForStatusFromEvent(eventSummary, eventTypeInfo),
                  statusMessageArg: !eventSummary
                      ? {}
                      : {
                            ...eventSummary,
                            eventId: eventId && eventId.length > 0 ? eventId[0] : null,
                            statusCode: eventSummary.importedStatus,
                        },
                  statusMessageId:
                      !eventSummary ||
                      (eventSummary.importedStatus === 0 && !eventDetails.isSamplingEvent)
                          ? null
                          : getEventStatusMessageId(eventSummary, eventTypeInfo),
              };

        const eventStripItem =
            eventStripItemProps == null ? null : (
                <div
                    className={classnames("item-container", {
                        "batch-rec-event-strip": batchSize > 0,
                    })}
                    onClick={() => this._returnToBatchList()}
                >
                    <RecEventItem {...eventStripItemProps} />
                </div>
            );

        return (
            <div>
                {batchStripItem}
                {eventStripItem}
            </div>
        );
    }

    _canRemoveFormLabel(
        eventSummary,
        eventDetails,
        isTissueSampling,
        isBatchCopy,
        isBatchTemplate,
        agEventTransactionTypeName
    ) {
        if (
            (eventSummary && eventSummary.isImportedYn) ||
            (eventDetails && eventDetails.creationType === importedEventCreationType)
        ) {
            return (
                agEventTransactionTypeName === recsEventsModels.EVENT_TYPE_NAME_APPLICATION &&
                eventDetails.agEventTypeList.length > 1 &&
                !(isBatchCopy && isBatchTemplate && isTissueSampling)
            );
        } else {
            return (
                eventSummary &&
                !eventSummary.isImportedYn &&
                !(isBatchCopy && isBatchTemplate && isTissueSampling)
            );
        }
    }

    _getEventPanes() {
        const panes = [];
        const {
            eventDetails,
            eventSummary,
            isBatchCopy,
            onRemoveAgEvent,
            saveEventDetailsErrorCodeList,
        } = this.props;
        const isSoilSampling =
            eventSummary?.agEventTypeName === recsEventsModels.EVENT_TYPE_NAME_SAMPLING_SOIL;
        const isTissueSampling =
            eventSummary?.agEventTypeName === recsEventsModels.EVENT_TYPE_NAME_SAMPLING_TISSUE;
        const isBatchTemplate =
            eventDetails?.fieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;

        if (!eventDetails || (isBatchCopy && isBatchTemplate && isSoilSampling)) {
            return panes;
        }

        const agEventTypeGuidToNameMap = this._getEventTypeMap(eventDetails);
        for (const [
            agEventTransactionTypeGuid,
            agEventTransactionTypeName,
        ] of agEventTypeGuidToNameMap.entries()) {
            const {
                EditForm,
                errorCodesApply,
                labelMessage,
                LabelIcon,
                ZoneInfo,
                SummarySection,
                FieldDataForm,
                FilterForm,
            } = this.getAgEventTransactionTypeNameToFormMap().get(agEventTransactionTypeName);
            const onRemove = () => onRemoveAgEvent(agEventTransactionTypeGuid);
            const label = (
                <FormLabel
                    canRemove={this._canRemoveFormLabel(
                        eventSummary,
                        eventDetails,
                        isTissueSampling,
                        isBatchCopy,
                        isBatchTemplate,
                        agEventTransactionTypeName
                    )}
                    hasError={errorCodesApply(saveEventDetailsErrorCodeList)}
                    labelMessage={labelMessage}
                    labelIconEl={<LabelIcon />}
                    onRemove={onRemove}
                />
            );
            panes.push(
                <Pane key={agEventTransactionTypeName} label={label}>
                    {!FieldDataForm ? null : (
                        <FieldDataForm
                            recEventDetails={eventDetails}
                            fieldGuid={eventDetails.fieldGuid}
                        />
                    )}
                    <ZoneInfo
                        modelId={{ agEventTransactionTypeGuid }}
                        EditForm={EditForm}
                        SummarySection={SummarySection}
                        isEventType
                    />
                </Pane>
            );
            if (
                ((eventSummary && eventSummary.isImportedYn && FilterForm) ||
                    (eventDetails &&
                        eventDetails.creationType === importedEventCreationType &&
                        FilterForm)) &&
                !eventDetails.isFromEquationRec
            ) {
                if (agEventTypeGuidToNameMap.size > 1) {
                    //Manual Application
                    if (agEventTransactionTypeName === recsEventsModels.EVENT_TYPE_NAME_PLANTING) {
                        panes.push(
                            <Pane
                                key={`${agEventTransactionTypeName}_filters`}
                                label={<FilterPaneLabel />}
                            >
                                <FilterForm fieldGuid={eventDetails.fieldGuid} />
                            </Pane>
                        );
                    }
                } else {
                    panes.push(
                        <Pane
                            key={`${agEventTransactionTypeName}_filters`}
                            label={<FilterPaneLabel />}
                        >
                            <FilterForm fieldGuid={eventDetails.fieldGuid} />
                        </Pane>
                    );
                }
            }
        }

        return panes;
    }

    _getEventTypeMap(eventDetails) {
        const agEventTypeGuidToNameMap = new Map(
            eventDetails.agEventTypeList
                .filter((agEventInfo) => {
                    const haveForm = this.getAgEventTransactionTypeNameToFormMap().has(
                        agEventInfo.agEventTransactionTypeName
                    );
                    if (!haveForm) {
                        console.warn(
                            "Ignoring unsupported `agEvent` type",
                            agEventInfo.typeName,
                            this.getAgEventTransactionTypeNameToFormMap()
                        );
                    }
                    return haveForm;
                })
                .map((agEventTypeInfo) => [
                    agEventTypeInfo.agEventTransactionTypeGuid,
                    agEventTypeInfo.agEventTransactionTypeName,
                ])
        );
        return agEventTypeGuidToNameMap;
    }

    _onClose() {
        const {
            batchCopyReset,
            batchSamplingReset,
            batchSize,
            eventDetails,
            eventSummary,
            onClose,
            removeSurface,
            setActiveModule,
            triggeredPage,
            setTriggeredPage,
            isMergeEvent,
            resetMergeEvents,
        } = this.props;
        if (triggeredPage) {
            setActiveModule(triggeredPage);
            setTriggeredPage(null);
        }
        if (eventDetails && eventDetails.isECDataEvent) {
            removeSurface(eventDetails.fieldGuid);
        }
        if (isMergeEvent) {
            resetMergeEvents();
        }
        if (batchSize > 0) {
            batchCopyReset();
            if (
                eventSummary &&
                (eventSummary.agEventTypeName === recsEventsModels.EVENT_TYPE_NAME_SAMPLING_SOIL ||
                    eventSummary.agEventTypeName ===
                        recsEventsModels.EVENT_TYPE_NAME_SAMPLING_TISSUE)
            ) {
                batchSamplingReset();
            }
        }
        onClose();
    }

    _onSaveBatch() {
        const { batchCopyReset, batchSamplingReset, onSaveChanges } = this.props;
        batchCopyReset();
        batchSamplingReset();
        onSaveChanges();
    }

    _returnToBatchList() {
        const { eventDetails, batchSize, onReturnToBatchEventList } = this.props;
        if (batchSize > 0 && eventDetails.isSamplingEvent) {
            const { agEventModel } = eventDetails.eventAreaList[0].agEventList[0];
            const allPointsHaveIds =
                agEventModel.samplePoints.length > 0 &&
                agEventModel.samplePoints.every((pt) => pt.sequenceId != null);
            if (!allPointsHaveIds) {
                return;
            }
        }
        onReturnToBatchEventList();
    }

    render() {
        const {
            batchSize,
            batchFieldGuid,
            enableSave,
            eventDetails,
            eventSummary,
            fieldGuid,
            isBatchTemplate,
            isLoading,
            isMergeEvent,
            onCopyBatchTemplate,
            onMergeEvents,
            onReturnToBatchEventTemplate,
            onSaveChanges,
            saveEventDetailsErrorCodeList,
            mergeableEvents,
        } = this.props;
        const { formatMessage } = this.props.intl;

        if (batchSize > 0 && batchFieldGuid == null) {
            // display batch accordion
            return (
                <BatchEventsList
                    isLoading={isLoading}
                    onBack={onReturnToBatchEventTemplate}
                    onSave={() => this._onSaveBatch()}
                    onCancel={() => this._onClose()}
                />
            );
        }
        const isBatchEdit =
            !batchFieldGuid && !fieldGuid
                ? false
                : !isBatchTemplate && batchFieldGuid === fieldGuid;

        const genPaneLabel = (
            <GeneralPaneLabel
                hasError={eventGeneralErrorCodesApply(saveEventDetailsErrorCodeList)}
            />
        );

        const informationPanes = [
            <Pane key="general" label={genPaneLabel}>
                <EventGeneralForm />
            </Pane>,
            ...this._getEventPanes(),
        ];

        const onMerge = () => onMergeEvents(mergeableEvents);
        const canAddRecEvent =
            eventSummary &&
            !(
                eventSummary.isImportedYn &&
                eventSummary.agEventTypeName !== recsEventsModels.EVENT_TYPE_NAME_PLANTING
            ) &&
            eventSummary.agEventTypeName !== recsEventsModels.EVENT_TYPE_NAME_SCOUTING;

        return (
            <RecEventInfo
                addItemTitle={formatMessage(messages.addEventTooltip)}
                addMenuItems={this._getAddEventMenuItems()}
                canAddRecEvent={canAddRecEvent}
                enableSave={enableSave}
                errorCodeList={saveEventDetailsErrorCodeList}
                eventRecItemStrip={this._getEventStripItem()}
                informationPanes={informationPanes}
                isBatch={batchSize > 1}
                isBatchTemplate={isBatchTemplate}
                isBatchEdit={isBatchEdit}
                isLoading={isLoading}
                isMergeEvent={isMergeEvent}
                isNew={Boolean(eventDetails && eventDetails.agEventGeneralGuid === "")}
                onBack={batchSize === 0 || isBatchTemplate ? null : onReturnToBatchEventTemplate}
                onSave={
                    isBatchTemplate ? onCopyBatchTemplate : isMergeEvent ? onMerge : onSaveChanges
                }
                onCancel={() => this._onClose()}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    addAggregateEvent: (fieldGuid, agEventTransactionTypeGuid, sampleTypeGuid) =>
        dispatch(
            recsEventsActions.addAggregateEvent(
                fieldGuid,
                agEventTransactionTypeGuid,
                sampleTypeGuid
            )
        ),
    batchCopyReset: () => dispatch(recsEventsActions.batchCopyReset()),
    batchSamplingReset: () => dispatch(recsEventsActions.batchSamplingReset()),
    onClose: () => dispatch(actions.closeEventInfo()),
    onCopyBatchTemplate: () => dispatch(recsEventsActions.copyBatchTemplateToEvents()),
    onMergeEvents: (mergeableEvents) => dispatch(actions.mergeEvents(mergeableEvents)),
    removeAggregateEvent: (fieldGuid, agEventTransactionTypeGuid) =>
        dispatch(recsEventsActions.removeAggregateEvent(fieldGuid, agEventTransactionTypeGuid)),
    removeAggregateScoutingEvent: (fieldGuid, agEventTransactionTypeGuid) =>
        dispatch(
            recsEventsActions.removeAggregateScoutingEvent(fieldGuid, agEventTransactionTypeGuid)
        ),
    restoreBatchSamplePoints: () => dispatch(recsEventsActions.restoreBatchSamplePoints()),
    onSaveChanges: () => dispatch(actions.saveEventInfo()),
    removeSurface: (fieldGuid) => dispatch(layerActions.removeVisibleSurfaces([fieldGuid])),
    resetMergeEvents: () => dispatch(setMergeableEvents(null)),
    setCurrentBatchFieldGuid: (fieldGuid) =>
        dispatch(recsEventsActions.setCurrentBatchFieldGuid(fieldGuid)),
    setActiveModule: (triggeredPage) => dispatch(setActiveModule(triggeredPage)),
    setLayerNameToSurfaceInfoMap: () =>
        dispatch(recsEventsActions.setLayerNameToSurfaceInfoMap(null)),
    setTriggeredPage: (triggeredPage) => dispatch(setTriggeredPage(triggeredPage)),
});

const mapStateToProps = (state) => {
    const triggeredPage = getTriggeredPage(state);
    const eventInfoState = selectors.getModuleState(state);
    const { eventSummary, isLoading } = eventInfoState;
    const { batchFieldGuid, loading } = recsEventsSelectors.getZonesState(state);

    const { fieldGuidToEventDetails, isBatchCopy, saveEventDetailsErrorCodeList } =
        eventsSelectors.getModuleState(state);

    const batchSize = Math.max(fieldGuidToEventDetails.size - 1, 0);
    const isBatchTemplate =
        batchSize > 0 && batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;

    const fieldGuid =
        batchSize > 0 ? batchFieldGuid : eventSummary != null ? eventSummary.fieldGuid : null;

    const { mergeableEvents } = eventListSelectors.getModuleState(state);
    const isMergeEvent = Boolean(mergeableEvents);
    const newableEventTypeInfoList = eventsSelectors.getNewableEventTypeInfoList(state);

    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const isCanvasLoading = getIsCanvasLoading(state);
    const enableSave =
        isBatchTemplate && eventDetails.isSamplingEvent
            ? eventsSelectors.getBatchSamplingEnableSave(state) && !isLoading
            : !isLoading && eventsSelectors.getEnableSave(state, fieldGuid);

    const picklistOptionsCroppingSeason = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    return {
        batchFieldGuid,
        batchSize,
        enableSave,
        eventDetails,
        eventSummary,
        fieldGuid,
        isBatchCopy,
        isBatchTemplate,
        isLoading: isLoading || loading || isCanvasLoading,
        isMergeEvent,
        newableEventTypeInfoList,
        picklistOptionsCroppingSeason,
        saveEventDetailsErrorCodeList,
        mergeableEvents,
        triggeredPage,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onAddAgEvent: (agEventTransactionTypeGuid, sampleTypeGuid) =>
        dispatchProps.addAggregateEvent(
            stateProps.fieldGuid,
            agEventTransactionTypeGuid,
            sampleTypeGuid
        ),
    onRemoveAgEvent: (agEventTransactionTypeGuid) => {
        dispatchProps.removeSurface(stateProps.fieldGuid);
        stateProps.eventDetails.isScoutingEvent
            ? dispatchProps.removeAggregateScoutingEvent(
                  stateProps.fieldGuid,
                  agEventTransactionTypeGuid
              )
            : dispatchProps.removeAggregateEvent(stateProps.fieldGuid, agEventTransactionTypeGuid);
    },
    onReturnToBatchEventList: () => {
        if (stateProps.batchSize > 0) {
            dispatchProps.setLayerNameToSurfaceInfoMap();
            dispatchProps.setCurrentBatchFieldGuid(null);
        }
    },
    onReturnToBatchEventTemplate: () => {
        if (stateProps.batchSize > 0) {
            dispatchProps.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID);
            if (
                stateProps.eventSummary.agEventTypeName ===
                    recsEventsModels.EVENT_TYPE_NAME_SAMPLING_SOIL ||
                stateProps.eventSummary.agEventTypeName ===
                    recsEventsModels.EVENT_TYPE_NAME_SAMPLING_TISSUE
            ) {
                //Re-add sample points to map
                dispatchProps.restoreBatchSamplePoints();
            }
        }
    },
});

export const EventInfo = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(EventInfo_));
