import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { messages } from "./../../i18n-messages";
import { injectIntl, intlShape } from "react-intl";
import { model, service, dropdowns } from "./../data";
import {
    getAgBytesErrorClassNames,
    handlePicklistChange,
    mapToPicklistValue,
    onTextChange,
    prepareSelectableOptions,
} from "~/admin/utils";
// constants
import * as picklistService from "~/core/picklist/picklist-names";
import { REQUEST_PICKLIST_NAME } from "./../data/service";
import { adminData, ID, NAME, PICKLIST_GUID, VALUE, GUID } from "~/admin/data";
// Components
import { Checkbox, Section, SelectInput, SubSection, TextInput } from "~/core";
// Styles
import "./add-edit-panel.css";

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        clearPicklistState: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.picklist,
        recordGuid: PropTypes.shape({
            [model.PROPS_PICKLIST_VALUE_GUID]: PropTypes.string,
            [model.PROPS_PICKLIST_NAME]: PropTypes.string,
        }),
        recordName: PropTypes.string,
        setBreadcrumbs: PropTypes.func,
        userRole: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.picklist = {};
        this.state = {
            [model.PROPS_VALUE_ID]: "",
            [picklistService.PICKLIST_CATEGORY]: [],
            [model.PROPS_PICKLIST_NAME]: [],
            [model.PROPS_PICKLIST_PARENT_VALUE]: [],
            nextId: null,
        };
    }

    componentDidMount() {
        this.setupPicklistRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.fetchPicklistData(),
            this.props.fetchDropdownData({
                [model.PROPS_PICKLIST_CATEGORY]: {
                    url: dropdowns[model.PROPS_PICKLIST_CATEGORY],
                    model: false,
                },
                [model.PROPS_PICKLIST_NAME]: {
                    url: REQUEST_PICKLIST_NAME,
                    model: {
                        picklistCategoryGuid: "",
                        picklistNameGuid: "",
                    },
                },
            }),
        ]);
        if (this.props.addEditPanel.recordName && this.props.addEditPanel.recordGuid) {
            needs([
                this.props.fetchRecord({
                    [model.PROPS_PICKLIST_NAME]: this.props.addEditPanel.recordName,
                    [model.PROPS_PICKLIST_VALUE_GUID]:
                        this.props.addEditPanel.recordGuid[model.PROPS_PICKLIST_VALUE_GUID],
                }),
            ]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.picklist);
            if (nextProps.fetchData !== this.props.fetchData) {
                this.props.clearPicklistState(this.picklist[model.PROPS_PICKLIST_ID]);
            }
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            if (nextProps.nextId !== this.props.nextId) {
                this.picklist[model.PROPS_VALUE_ID] = "" + nextProps.nextId;
                this.setState({
                    [model.PROPS_VALUE_ID]: nextProps.nextId,
                });
            }
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.picklist = nextProps.record;
                this.setState(
                    {
                        [model.PROPS_VALUE_ID]: this.picklist[model.PROPS_VALUE_ID],
                    },
                    () => {
                        this.props.needs([
                            this.props.fetchDropdownData({
                                [model.PROPS_PICKLIST_PARENT_VALUE]: {
                                    url: dropdowns[model.PROPS_PICKLIST_PARENT_VALUE],
                                    model: this.picklist[model.PROPS_PICKLIST_NAME],
                                },
                            }),
                        ]);
                    }
                );
            }
        }

        this.initializeDropdowns(nextProps, this.props);
    }

    setupPicklistRecord = () => {
        const { addEditPanel } = this.props;
        this.picklist = {};
        if (addEditPanel.mode === "ADD") {
            this.picklist = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps, props) => {
        this.initializePicklistName(nextProps, props);
        this.initializeCategory(nextProps, props);
        this.initializePicklistName(nextProps, props);
        this.initializePicklistParentValue(nextProps, props);
    };

    initializeCategory = (nextProps) => {
        if (
            this.picklist[model.PROPS_CATEGORY_GUID] != null &&
            nextProps[model.PROPS_PICKLIST_CATEGORY]
        ) {
            this.setState({
                [model.PROPS_PICKLIST_CATEGORY]: prepareSelectableOptions(
                    nextProps[model.PROPS_PICKLIST_CATEGORY],
                    { guid: model.PROPS_CATEGORY_GUID, label: NAME, id: ID },
                    this.picklist[model.PROPS_CATEGORY_GUID]
                ),
            });
        }
    };

    initializePicklistName = (nextProps) => {
        if (
            this.picklist[model.PROPS_PICKLIST_NAME_GUID] != null &&
            nextProps[model.PROPS_PICKLIST_NAME]
        ) {
            this.setState({
                [model.PROPS_PICKLIST_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_PICKLIST_NAME],
                    {
                        guid: model.PROPS_PICKLIST_NAME_GUID,
                        label: NAME,
                        id: ID,
                    },
                    this.picklist[model.PROPS_PICKLIST_NAME_GUID]
                ),
            });
        }
    };

    initializePicklistParentValue = (nextProps) => {
        if (
            this.picklist[model.PROPS_PICKLIST_PARENT_GUID] != null &&
            nextProps[model.PROPS_PICKLIST_PARENT_VALUE]
        ) {
            this.setState({
                [model.PROPS_PICKLIST_PARENT_VALUE]: prepareSelectableOptions(
                    nextProps[model.PROPS_PICKLIST_PARENT_VALUE],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID },
                    this.picklist[model.PROPS_PICKLIST_PARENT_VALUE_GUID]
                ),
            });
        }
    };

    onCategoryChange = () => {
        this.props.needs([
            this.props.fetchDropdownData({
                [model.PROPS_PICKLIST_NAME]: {
                    url: REQUEST_PICKLIST_NAME,
                    model: {
                        picklistCategoryGuid: this.picklist[model.PROPS_CATEGORY_GUID],
                        picklistNameGuid: "",
                    },
                },
            }),
        ]);
        this.updatePicklistName("");
    };

    onPicklistNameChange = () => {
        if (
            this.picklist[model.PROPS_PICKLIST_NAME_GUID] != null &&
            this.picklist[model.PROPS_PICKLIST_NAME_GUID] !== ""
        ) {
            this.props.needs([
                this.props.fetchDropdownData({
                    [model.PROPS_PICKLIST_PARENT_VALUE]: {
                        url: dropdowns[model.PROPS_PICKLIST_PARENT_VALUE],
                        model: this.picklist[model.PROPS_PICKLIST_NAME],
                    },
                }),
                this.props.getNextId(this.picklist[model.PROPS_PICKLIST_NAME_GUID]),
            ]);
            const selectedItem = this.state[model.PROPS_PICKLIST_NAME].find((item) => {
                return item.value[GUID] === this.picklist[model.PROPS_PICKLIST_NAME_GUID];
            });
            this.onTextChange(
                model.PROPS_PICKLIST_ID,
                selectedItem != null ? selectedItem.value.id : ""
            );
        } else {
            this.onTextChange(model.PROPS_VALUE_ID, "");
            this.setState({
                [model.PROPS_VALUE_ID]: "",
            });
        }
    };

    updatePicklistName = (value) => {
        this.onPicklistChange(
            {
                type: model.PROPS_PICKLIST_NAME,
                guid: model.PROPS_PICKLIST_NAME_GUID,
            },
            value,
            this.onPicklistNameChange
        );
    };

    onPicklistChange = ({ type, guid }, value, callback) => {
        this.picklist = handlePicklistChange(this.picklist, { type, guid, value }, callback);
    };

    onTextChange = (formKey, value, callback) => {
        this.picklist = onTextChange(this.picklist, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.picklist[model.PROPS_PICKLIST_VALUE]]);
    };

    renderPicklistInfo = () => {
        const { formatMessage } = this.props.intl;
        const { picklist } = this;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            autoFocus
                            openOnFocus={false}
                            containerClassNames={getAgBytesErrorClassNames(9, this.props.apiErrors)}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_PICKLIST_CATEGORY]}
                            disabled={this.props.addEditPanel.mode === "EDIT"}
                            placeholderText={formatMessage(messages.category)}
                            required
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_PICKLIST_CATEGORY],
                                selectedGuid: picklist[model.PROPS_CATEGORY_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_PICKLIST_CATEGORY,
                                        guid: model.PROPS_CATEGORY_GUID,
                                    },
                                    value,
                                    this.onCategoryChange
                                );
                            }}
                        />
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                68,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_PICKLIST_NAME]}
                            disabled={
                                this.props.addEditPanel.mode === "EDIT" ||
                                !picklist[model.PROPS_CATEGORY_GUID]
                            }
                            placeholderText={formatMessage(messages.picklistName)}
                            required
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_PICKLIST_NAME],
                                selectedGuid: picklist[model.PROPS_PICKLIST_NAME_GUID],
                            })}
                            onChange={(value) => {
                                this.updatePicklistName(value);
                            }}
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            containerClassNames={getAgBytesErrorClassNames(
                                391,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[model.PROPS_PICKLIST_PARENT_VALUE]}
                            disabled={
                                this.props.addEditPanel.mode === "EDIT"
                                    ? true
                                    : !this.state[model.PROPS_PICKLIST_PARENT_VALUE].length
                            }
                            placeholderText={formatMessage(messages.picklistParentValue)}
                            value={mapToPicklistValue({
                                options: this.state[model.PROPS_PICKLIST_PARENT_VALUE],
                                selectedGuid: picklist[model.PROPS_PICKLIST_PARENT_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_PICKLIST_PARENT_VALUE,
                                        guid: model.PROPS_PICKLIST_PARENT_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            containerClassNames={getAgBytesErrorClassNames(
                                [68, 389, 452, 799, 798],
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.picklistValue)}
                            labelText={formatMessage(messages.picklistValue)}
                            value={picklist[model.PROPS_PICKLIST_VALUE]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_PICKLIST_VALUE,
                                    value,
                                    this.setHeaderText
                                )
                            }
                            required
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="add-edit-panel">
                <div className="picklist-id-container">
                    <span className="picklist-id">
                        {formatMessage(messages.picklistValueID)}:{" "}
                        {this.state[model.PROPS_VALUE_ID]}
                    </span>
                    {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                        <Checkbox
                            className="picklist-active-checkbox"
                            onChange={(e, value) => this.onTextChange(model.PROPS_ACTIVE_YN, value)}
                            value={this.picklist[model.PROPS_ACTIVE_YN]}
                            label={formatMessage(messages.active)}
                        />
                    )}
                </div>
                {this.renderPicklistInfo()}
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
