import { createSelector } from "reselect";
import { selectors as accordionSelectors, model as accordionModel } from "~/accordion";

import { ACTION_PANEL_STATE_KEY } from "../../selectors";

export const IMPORT_MODULE_STATE_KEY = "IMPORT_MODULE_STATE";
export const MAIN_REDUCER_KEY = "MAIN";
export const ACCORDION_KEY = "ACCORDION";

export const getModuleState = (state) => {
    return state[ACTION_PANEL_STATE_KEY][IMPORT_MODULE_STATE_KEY][MAIN_REDUCER_KEY];
};

export const getAccordionState = (state) => {
    return state[ACTION_PANEL_STATE_KEY][IMPORT_MODULE_STATE_KEY][ACCORDION_KEY];
};

export const getSelectedImportFileInfoGuidSet = (state) => {
    return getModuleState(state).selectedImportFileGuidList;
};

export const getLastImportFileItemClickedDimIdx = (state) => {
    return getModuleState(state).lastImportFileItemClickedDimIdx;
};

export const getActivePage = (state) => {
    return getModuleState(state).activePage;
};

export const getAccordionId = (state) => {
    return getAccordionState(state).accordionId;
};

export const getAccordionItems = (state) =>
    accordionSelectors.itemsSelector(getAccordionState(state));

export const getShowFilters = (state) => getModuleState(state).showFilters;

export const getSearchValue = (state) => getModuleState(state).searchValue;

export const getImportTypeDimIdx = (state, importTypeGuid) => {
    const items = getAccordionItems(state);
    for (let i = 0; i < items.length; i++) {
        const accordionItem = items[i];
        if (accordionItem.payload.guid === importTypeGuid) {
            return [i];
        }
    }
    throw new Error("unreachable: importTypeGuid not found");
};

export const getImportTemplateDimIdx = (state, importTypeGuid, templateGuid) => {
    const importTypeItems = getAccordionItems(state);
    const impTypeIdx = getImportTypeDimIdx(state, importTypeGuid)[0];
    const importTypeAccordionItem = importTypeItems[impTypeIdx];
    const items = importTypeAccordionItem.children;
    for (let i = 0; i < items.length; i++) {
        const accordionItem = items[i];
        if (accordionItem.payload.templateGuid === templateGuid) {
            return [impTypeIdx, i];
        }
    }
    throw new Error("unreachable: templateGuid not found");
};

/**
 * Get the `importFileGuid` of all accordion items that are a descendant of `accordionItemDimIdx`
 */
export const getDimIdxToImportFileGuidSetMapSelector = (accordionItemDimIdx) => {
    return createSelector(getAccordionItems, (importTypeItems) => {
        const importFileGuidSet = new Set();

        if (accordionItemDimIdx.length === 3) {
            const item = accordionModel.getItem(importTypeItems, accordionItemDimIdx);
            importFileGuidSet.add(item.payload.importFileGuid);
            return importFileGuidSet;
        }

        const startDimIdx = accordionItemDimIdx.length === 0 ? [0] : accordionItemDimIdx;
        console.assert(startDimIdx.length === 1 || startDimIdx.length === 2);

        const endDimIdx =
            accordionItemDimIdx.length === 0
                ? undefined
                : accordionModel.getNextGreaterNonDescendantDimIdx(importTypeItems, startDimIdx);

        const dimIdxIter = accordionModel.getDimIdxSliceIter(
            importTypeItems,
            startDimIdx,
            endDimIdx,
            false,
            [3]
        );
        for (let dimIdx of dimIdxIter) {
            const item = accordionModel.getItem(importTypeItems, dimIdx);
            importFileGuidSet.add(item.payload.importFileGuid);
        }

        return importFileGuidSet;
    });
};

export const getSelectedChildGuidSetSelector = (itemDimIdx) => {
    return createSelector(
        getSelectedImportFileInfoGuidSet,
        getDimIdxToImportFileGuidSetMapSelector(itemDimIdx),
        (selectedGuidSet, childIfoGuidSet) => {
            const rv = new Set();
            for (let childGuid of childIfoGuidSet) {
                if (selectedGuidSet.has(childGuid)) {
                    rv.add(childGuid);
                }
            }
            return rv;
        }
    );
};
