import React from "react";
import { connect } from "react-redux";
import { SearchInput } from "~/core";
import { Loader, LoaderTypes } from "~/core";
import { selectors } from "~/customer-data";
import "./module-search.css";
export interface IModuleSearchProps {
    searchValue?: string;
    onSearchChange: (v: string) => void;
    isLoading?: boolean;
}
class ModuleSearch_ extends React.Component<IModuleSearchProps> {
    static defaultProps = {
        searchValue: "",
    };

    render() {
        const { isLoading, searchValue, onSearchChange } = this.props;
        return (
            <div className="module-search">
                <SearchInput
                    searchValue={searchValue}
                    onSearchChange={(v) => {
                        onSearchChange(v);
                    }}
                />
                <div className="search-loading-container">
                    {!isLoading ? null : (
                        <Loader className="filter-loader" type={LoaderTypes.LINE_SCALE_PARTY} />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: selectors.getFetchingCustomerFields(state),
    };
};

export const ModuleSearch = connect(mapStateToProps)(ModuleSearch_);
