import { FieldAPI, ReportAPI, ReportBooksAPI, SetupReportBooksAPI } from "@ai360/core";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";
import { getSelectedRecGuidSet } from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { IAutoSearchData } from "~/admin/setup/report-books/data/interfaces";
import * as reportTableModel from "~/reports/components/report-table/model";
import { PROPS_INDIVIDUAL_RB, PROPS_REPORT_BOOK_GROUP_BY } from "../reports/data/model";
import { messages } from "~/reports/i18n-messages";
import { errorCodeMessages } from "~/i18n-error-messages";
import { DialogBox, DialogBoxFooterType, Loader, LoaderTypes } from "~/core";
import {
    actions as picklistActions,
    picklistNames,
    selectors as picklistSelectors,
} from "~/core/picklist";
import { ReportOptionsContainer, ReportTable, ReportTypes } from "~/reports/components";
import { IReportTypePage, ISelectReportType } from "../reports/data/interfaces";
import { getCropDropdown, getReportTypePageList } from "../reports/data/actions";
import {
    getCropOptionsData,
    getReportPref,
    getReportTypePages,
    getFields,
} from "../reports/data/selectors";
import { actions, model, selectors } from "./data";
import { withMasked } from "~/hocs";
import { eventsSelectors, recsSelectors } from "~/recs-events";
import { IReportBookResultInfo } from "~/report-books/data/interface";
import { AgEventSummary } from "~/recs-events/events/models";
import { ISelectorCropDataReturn } from "../reports/data/selectors";
import { IActionData } from "../reports/data/interfaces";
import { v4 as uuid } from "uuid";
import "../reports/reports.css";
import "./report-report-books.css";
import { RecSummary } from "~/recs-events/recs/model";

interface IReportReportBooksProps {
    agEventSummaryMap: Map<string, AgEventSummary>;
    autoSearchData: IAutoSearchData;
    clearReportBookTypes: boolean;
    crops: ISelectorCropDataReturn[];
    deactivateReportBooks: (data: {
        reportBookGuids: string[];
        fieldGuids: string[];
        filterOptions: ReportBooksAPI.IReportBookFilterOptions;
    }) => void;
    fetchPicklistData: (...args) => void;
    getCropDropdown: () => IActionData;
    getReportBooks: (payload: {
        fieldGuids: string[];
        filterOptions: ReportBooksAPI.IReportBookFilterOptions;
    }) => ReportBooksAPI.IReportBookResponse;
    getReportBooksAutoComplete: (payload: {
        fieldGuids: string[];
        filterOptions: ReportBooksAPI.IReportBookFilterOptions;
        searchName: string;
        searchString: string;
    }) => ReportBooksAPI.IReportBookAutoCompleteResponse;
    getReportBooksSelectAll: (payload: {
        fieldGuids: string[];
        filterOptions: ReportBooksAPI.IReportBookFilterOptions;
    }) => ReportBooksAPI.IReportBookResponse;
    getReportBookTypes: () => any;
    getReportTypePageList: (payload: { url: string }) => void;
    loadingReportBook: boolean;
    mergeReportBooks: (data: ReportBooksAPI.IMergeReportBookRequest) => void;
    needs: (...args: any[]) => any;
    onAllReportCreated: () => void;
    recGeneralGuidToRecMap: Map<string, RecSummary>;
    refreshReportBooks: string;
    reportBookErrorsMap: Map<string, IReportBookResultInfo>;
    reportBooks: ReportBooksAPI.IReportBookData[];
    reportBookTypes: SetupReportBooksAPI.IReportBookTypeRecord[];
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    seasons: FieldAPI.ISeason[];
    selectedFields: ReportBooksAPI.IField[];
    selectedEventGuidSet: Set<string>;
    selectedItems: string[];
    selectedRecGuidSet: Set<string>;
    setReportBookErrorsMap: (data: Map<string, IReportBookResultInfo>) => void;
    totalCount: number;
    intl: any;
}

interface IReportReportBooksState {
    agEventSummaryMap: Map<string, AgEventSummary>;
    autoSearchData: IAutoSearchData;
    clearReportBookTypes: boolean;
    createReportHubProgress: boolean;
    crops: ISelectorCropDataReturn[];
    dateOrderError: boolean;
    filterQuery: ReportBooksAPI.IReportBookFilter;
    initialRequestOptions: { [model.PROPS_FIELD_GUID_LIST]: string[] };
    loadingReportBook: boolean;
    pageSize: number;
    recGeneralGuidToRecMap: Map<string, RecSummary>;
    reportBookErrors: IReportBookResultInfo[];
    reportBookRequestCount: number;
    reportBooks: ReportBooksAPI.IReportBookData[];
    reportBookTypes: IReportBookTypeForReportType[];
    reportPreferences: any;
    reportTypePages: IReportTypePage[];
    seasons: FieldAPI.ISeason[];
    selectedFieldCountError: boolean;
    selectedFields: ReportBooksAPI.IField[];
    selectedEventGuidSet: Set<string>;
    selectedItems: string[];
    selectedRecGuidSet: Set<string>;
    selectedReportCountError: boolean;
    selectedReportTypeNames: string[];
    selectedReportTypes: string[];
    showReportBookErrorsModal: boolean;
    showValidationModal: boolean;
    skip: number;
    sortOptions: ReportBooksAPI.IReportBookSortOptions;
    totalCount: number;
}

interface IReportBookTypeForReportType extends SetupReportBooksAPI.IReportBookTypeRecord {
    reportTypeDisplayName: string;
    reportTypeName: string;
    reportTypeGuid: string;
}

class ReportReportBooks_ extends Component<IReportReportBooksProps, IReportReportBooksState> {
    private reportData: Record<string, any> = {};

    constructor(props: IReportReportBooksProps) {
        super(props);
        this.state = {
            agEventSummaryMap: props.agEventSummaryMap || new Map<string, AgEventSummary>(),
            autoSearchData: null,
            clearReportBookTypes: false,
            createReportHubProgress: false,
            crops: props.crops || [],
            dateOrderError: false,
            filterQuery: {},
            initialRequestOptions: { [model.PROPS_FIELD_GUID_LIST]: null },
            loadingReportBook: false,
            pageSize: 50,
            recGeneralGuidToRecMap: props.recGeneralGuidToRecMap || new Map<string, RecSummary>(),
            reportBookErrors: [],
            reportBookRequestCount: 0,
            reportBooks: [],
            reportBookTypes: [],
            reportPreferences: props.reportPreferences || {},
            reportTypePages: props.reportTypePages || [],
            seasons: props.seasons || [],
            selectedFieldCountError: false,
            selectedFields: [],
            selectedEventGuidSet: props.selectedEventGuidSet || new Set<string>(),
            selectedItems: [],
            selectedRecGuidSet: props.selectedRecGuidSet || new Set<string>(),
            selectedReportCountError: false,
            selectedReportTypeNames: [],
            selectedReportTypes: [],
            showReportBookErrorsModal: false,
            showValidationModal: false,
            skip: 0,
            sortOptions: {},
            totalCount: 0,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.getReportBookTypes();
        this.props.needs([
            this.props.getReportTypePageList({
                url: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
            }),
            this.props.fetchPicklistData({
                picklistKey: picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON),
                picklistKey1: picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE),
            }),
            this.props.getCropDropdown(),
        ]);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IReportReportBooksProps) {
        const newState: Partial<IReportReportBooksState> = {};

        if (nextProps.agEventSummaryMap !== this.props.agEventSummaryMap) {
            newState.agEventSummaryMap = nextProps.agEventSummaryMap;
        }

        if (nextProps.autoSearchData !== this.props.autoSearchData) {
            newState.autoSearchData = nextProps.autoSearchData;
        }

        if (nextProps.clearReportBookTypes !== this.props.clearReportBookTypes) {
            newState.clearReportBookTypes = nextProps.clearReportBookTypes;

            if (newState.clearReportBookTypes) {
                newState.selectedReportTypeNames = [];
                newState.selectedReportTypes = [];
            }
        }

        if (nextProps.crops !== this.props.crops) {
            newState.crops = nextProps.crops;
        }

        if (nextProps.loadingReportBook !== this.state.loadingReportBook) {
            newState.loadingReportBook = nextProps.loadingReportBook;
        }

        if (
            nextProps.refreshReportBooks !== this.props.refreshReportBooks &&
            nextProps.selectedFields?.length > 0
        ) {
            const fieldGuids = nextProps.selectedFields.map((field) => field.fieldGuid);
            const filterOptions = {
                [model.PROPS_REPORT_PAGE_OPTIONS]: {
                    pageSize: this.state.pageSize,
                    skip: 0,
                },
                [model.PROPS_REPORT_SORT_LIST]: this.state.sortOptions,
                [model.PROPS_REPORT_FILTER]: this.state.filterQuery,
            };
            this.props.getReportBooks({ fieldGuids, filterOptions });
        }

        if (nextProps.recGeneralGuidToRecMap !== this.props.recGeneralGuidToRecMap) {
            newState.recGeneralGuidToRecMap = nextProps.recGeneralGuidToRecMap;
        }

        if (nextProps.reportBookErrorsMap?.size > 0) {
            const values = Array.from(nextProps.reportBookErrorsMap.values());
            if (values.filter(Boolean).length === this.state.reportBookRequestCount) {
                newState.reportBookErrors = values.filter((x) => x.reportErrorInfo?.length > 0);
            } else {
                newState.reportBookErrors = [];
            }
            newState.showReportBookErrorsModal = newState.reportBookErrors.length > 0;
        }

        if (
            JSON.stringify(nextProps.reportBooks) !== JSON.stringify(this.state.reportBooks) &&
            nextProps.reportBooks != null
        ) {
            newState.reportBooks = nextProps.reportBooks.map((rb) => ({
                ...rb,
                [reportTableModel.PROPS_CUSTOMER_NAME]: rb.customerName,
                [reportTableModel.PROPS_FARM_NAME]: rb.farmName,
                [reportTableModel.PROPS_FIELD_NAME]: rb.fieldName,
                [reportTableModel.PROPS_REPORT_DATE]: rb.createdDate,
                [reportTableModel.PROPS_REPORT_GUID]: rb.batchGuid,
                [reportTableModel.PROPS_REPORT_SEASON]: rb.season,
                [reportTableModel.PROPS_REPORT_STATUS]:
                    rb.status.toLowerCase() === "inprogress"
                        ? "In Progress"
                        : rb.status.toLowerCase() === "statuserror"
                        ? "Status Error"
                        : rb.status,
                [reportTableModel.PROPS_REPORT_TYPE_NAME]: rb.name,
                [reportTableModel.PROPS_REPORT_USER_GUID]: rb.createdByGuid,
            }));
        }

        if (
            JSON.stringify(nextProps.reportBookTypes) !==
                JSON.stringify(this.state.reportBookTypes) &&
            nextProps.reportBookTypes != null
        ) {
            newState.reportBookTypes = nextProps.reportBookTypes.map((rbt) => ({
                ...rbt,
                reportTypeDisplayName: rbt.name,
                reportTypeName: rbt.name,
                reportTypeGuid: rbt.reportBookTypeGuid,
                reportTypeNames: rbt.reportTypeNames,
                reportTypeGuids: rbt.reportTypeGuids,
            }));
        }

        if (nextProps.reportPreferences !== this.props.reportPreferences) {
            newState.reportPreferences = nextProps.reportPreferences;
        }

        if (nextProps.reportTypePages !== this.props.reportTypePages) {
            newState.reportTypePages = nextProps.reportTypePages;
        }

        if (nextProps.seasons !== this.props.seasons) {
            newState.seasons = nextProps.seasons;
        }

        if (nextProps.selectedFields !== this.state.selectedFields) {
            const fieldGuids = nextProps.selectedFields.map((field) => field.fieldGuid);
            newState.selectedFields = nextProps.selectedFields;
            this.props.getReportBooks({ fieldGuids, filterOptions: {} });
        }

        if (nextProps.selectedEventGuidSet !== this.state.selectedEventGuidSet) {
            newState.selectedEventGuidSet = nextProps.selectedEventGuidSet;
        }

        if (
            JSON.stringify(nextProps.selectedItems) !== JSON.stringify(this.state.selectedItems) &&
            nextProps.selectedItems != null
        ) {
            newState.selectedItems = nextProps.selectedItems;
        }

        if (nextProps.selectedRecGuidSet !== this.state.selectedRecGuidSet) {
            newState.selectedRecGuidSet = nextProps.selectedRecGuidSet;
        }

        if (nextProps.totalCount !== this.props.totalCount) {
            newState.totalCount = nextProps.totalCount;
        }

        if (Object.keys(newState).length > 0) {
            this.setState((prevState) => ({ ...prevState, ...newState }));
        }
    }

    render() {
        const {
            agEventSummaryMap,
            autoSearchData,
            createReportHubProgress,
            crops,
            dateOrderError,
            initialRequestOptions,
            loadingReportBook,
            recGeneralGuidToRecMap,
            reportBookErrors,
            reportBooks,
            reportBookTypes,
            reportPreferences,
            reportTypePages,
            seasons,
            selectedEventGuidSet,
            selectedFieldCountError,
            selectedFields,
            selectedItems,
            selectedRecGuidSet,
            selectedReportCountError,
            selectedReportTypeNames,
            selectedReportTypes,
            showReportBookErrorsModal,
            showValidationModal,
            totalCount,
        } = this.state;
        const { formatMessage } = this.props.intl;

        const {
            onAllReportCreated,
            deactivateReportBooks,
            fetchRecords,
            onFilterChange,
            onGetAutoSearchList,
            onPaginatorChange,
            onSelectAll,
            onSortOption,
        } = this;

        const reportTableProps = {
            autoSearchList: autoSearchData,
            createReportHubProgress: createReportHubProgress,
            initialRequestOptions: initialRequestOptions,
            fetchRecordsAlt: fetchRecords,
            deleteSelectedReportsAlt: deactivateReportBooks,
            hideMergeOption: true,
            getAutoSearchListAlt: onGetAutoSearchList,
            needs: this.props.needs,
            onAllReportCreated: onAllReportCreated,
            onFilterChangeAlt: onFilterChange,
            onPaginatorChangeAlt: onPaginatorChange,
            onSelectAllAlt: onSelectAll,
            onSortOptionAlt: onSortOption,
            records: reportBooks,
            selectedItemList: selectedItems,
            selectAll: selectedItems,
            totalCount,
        };

        return (
            <div className="reports-container report-report-books-container">
                {!loadingReportBook ? null : <Loader type={LoaderTypes.LINE_SCALE} />}
                <div className="reports-options-container">
                    <ReportTypes
                        selectReportType={this.selectReportType}
                        reportTypes={reportBookTypes}
                        selectedReportTypes={selectedReportTypes}
                        formatMessage={formatMessage}
                    />
                    <ReportOptionsContainer
                        agEventSummaryMap={agEventSummaryMap}
                        alwaysShowDefaultOptions={true}
                        createReport={this.createReport}
                        crops={crops}
                        seasons={seasons}
                        formatMessage={formatMessage}
                        hideReportOptions={true}
                        onInputChange={this.onInputChange}
                        recGeneralGuidToRecMap={recGeneralGuidToRecMap}
                        reportPreferences={reportPreferences}
                        reportTypePages={reportTypePages}
                        selectedEventGuidSet={selectedEventGuidSet}
                        selectedFields={selectedFields}
                        selectedRecGuidSet={selectedRecGuidSet}
                        selectedReportTypes={selectedReportTypeNames}
                        showReportBookGroupBy={true}
                        showIndividualRBOption={true}
                    />
                </div>
                <ReportTable {...reportTableProps} />

                <DialogBox
                    className="report-book-data-validation-modal"
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={showValidationModal}
                    onClose={() => this.setState({ showValidationModal: false })}
                    title={"Report Options"}
                >
                    {!selectedFieldCountError ? null : (
                        <div>{formatMessage(messages.selectFieldError)} </div>
                    )}
                    {!selectedReportCountError ? null : (
                        <div>{formatMessage(messages.selectReportBookTypeError)}</div>
                    )}
                    {!dateOrderError ? null : <div>{formatMessage(messages.dateOrderError)}</div>}
                </DialogBox>

                <DialogBox
                    className="report-book-data-error-modal"
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={showReportBookErrorsModal}
                    onClose={() => this.resetReportBookErrorsModal()}
                    title={"Report Book Errors"}
                >
                    {!showReportBookErrorsModal
                        ? null
                        : this.generateErrorInfo(reportBookErrors, formatMessage)}
                </DialogBox>
            </div>
        );
    }

    private createReport = () => {
        const errors = [];
        const newState = {
            dateOrderError: false,
            selectedFieldCountError: this.state.selectedFields?.length === 0,
            selectedReportCountError: this.state.selectedReportTypes?.length === 0,
            showValidationModal: false,
        };
        if (this.reportData[model.PROPS_START_DATE] && this.reportData[model.PROPS_END_DATE]) {
            const startDate = new Date(
                this.reportData[model.PROPS_START_DATE] as string | number | Date
            );
            const endDate = new Date(
                this.reportData[model.PROPS_END_DATE] as string | number | Date
            );
            if (startDate > endDate) {
                newState.dateOrderError = true;
                errors.push(true);
            }
        }
        if (newState.selectedFieldCountError || newState.selectedReportCountError) {
            errors.push(true);
        }
        if (errors.length > 0) {
            newState.showValidationModal = true;
            this.setState(newState);
            return;
        }
        const reportBookErrorsMap = new Map<string, IReportBookResultInfo>();
        let reportBookRequestCount = 0;
        this.props.setReportBookErrorsMap(reportBookErrorsMap);
        this.setState(newState, () => {
            const customerGuids = this.findCustomerIds(this.state.selectedFields);

            const sendMergeRequest = (reportBookType, customerFields) => {
                reportBookRequestCount++;
                const trackingGuid = uuid();
                reportBookErrorsMap.set(trackingGuid, null);
                const data = this.generateMergeReportBookData(
                    reportBookType.reportBookTypeGuid,
                    reportBookType.name,
                    customerFields,
                    trackingGuid
                );
                this.setState({ reportBookRequestCount }, () => {
                    this.props.mergeReportBooks(data);
                    this.props.setReportBookErrorsMap(reportBookErrorsMap);
                });
            };

            for (const customerGuid of customerGuids) {
                const customerFields = this.state.selectedFields
                    .filter((field) => field.customerGuid === customerGuid)
                    .map((field) => ({
                        ...field,
                        acres: Number(field.acres),
                    }));
                for (const reportBookTypeGuid of this.state.selectedReportTypes) {
                    const reportBookType = this.state.reportBookTypes.find(
                        (rbt) => rbt.reportBookTypeGuid === reportBookTypeGuid
                    );
                    if (this.reportData[PROPS_INDIVIDUAL_RB]) {
                        customerFields.map((customerField) => {
                            sendMergeRequest(reportBookType, [customerField]);
                        });
                    } else {
                        sendMergeRequest(reportBookType, customerFields);
                    }
                }
            }
        });
    };

    private deactivateReportBooks = (reportData) => {
        const fieldGuids = this.fieldGuids();
        const reportBookGuids = reportData.map((rd) => rd.reportBookGuid);

        const filterOptions = {
            [model.PROPS_REPORT_PAGE_OPTIONS]: {
                skip: 0,
            },
            [model.PROPS_REPORT_FILTER]: this.state.filterQuery,
            [model.PROPS_REPORT_SORT_LIST]: this.state.sortOptions,
        };
        this.props.deactivateReportBooks({
            reportBookGuids,
            fieldGuids,
            filterOptions,
        });
    };

    private fetchRecords = () => {
        const fieldGuids = this.fieldGuids();
        this.props.getReportBooks({ fieldGuids, filterOptions: {} });
    };

    private findCustomerIds(selectedFields: ReportBooksAPI.IField[]) {
        const customerGuids = new Set<string>();
        selectedFields.forEach((field) => customerGuids.add(field.customerGuid));
        return Array.from(customerGuids);
    }

    private generateErrorInfo(reportBookErrors: IReportBookResultInfo[], formatMessage) {
        return (
            <div className="report-book-error-info-container">
                {reportBookErrors.map((rbe, idx) => {
                    return (
                        <div key={`err-cont-${idx}`} className="error-info-container">
                            <div className="report-book-company-name">
                                {rbe.customerName} - {rbe.reportBook}
                            </div>
                            {rbe.reportErrorInfo.map((rei, idx2) => {
                                return (
                                    <div
                                        key={`err-info-${idx}-${idx2}`}
                                        className="report-type-error-info-container"
                                    >
                                        <div className="report-type-name">{rei.reportType}</div>
                                        {rei.errorInfo.map((ei, idx3) => {
                                            return (
                                                <div
                                                    key={`err-${idx}-${idx2}-${idx3}`}
                                                    className="error-code-field-config-info-container"
                                                >
                                                    <div className="error-code">
                                                        {formatMessage(
                                                            errorCodeMessages[ei.errorCode]
                                                        ).replace("{codeValue}", "")}
                                                    </div>
                                                    {ei.fieldAndConfigInfo.map((fci, idx4) => {
                                                        return (
                                                            <div
                                                                key={`field-config-${idx}-${idx2}-${idx3}-${idx4}`}
                                                                className="field-config-info"
                                                            >
                                                                {fci}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    private generateMergeReportBookData(
        guid: string,
        name: string,
        customerFields: ReportBooksAPI.IField[],
        trackingGuid: string
    ) {
        const data: ReportBooksAPI.IMergeReportBookRequest = {
            companyGuid: customerFields[0].customerGuid,
            cropGuid: this.reportData["cropGuid"] as string,
            endDate: new Date(this.reportData[model.PROPS_END_DATE] as string | number | Date),
            fields: customerFields,
            reportBookGroupByOption: this.reportData[PROPS_REPORT_BOOK_GROUP_BY] as string,
            reportBookIndividualOption: this.reportData[PROPS_INDIVIDUAL_RB] as boolean,
            reportBookTypeGuid: guid,
            reportBookTypeName: name,
            seasonGuid: this.reportData["croppingSeasonGuidList"] as string[],
            selectedEventGuids: Array.from(this.state.selectedEventGuidSet),
            selectedRecGuids: Array.from(this.state.selectedRecGuidSet),
            startDate: new Date(this.reportData[model.PROPS_START_DATE] as string | number | Date),
            trackingGuid,
        };
        return data;
    }

    private onAllReportCreated = () => {
        this.setState({ createReportHubProgress: false });
    };

    private onFilterChange = (searchName, searchString, filterQuery) => {
        this.setState({ filterQuery });
        const sortOptions = this.state.sortOptions ? this.state.sortOptions : null;

        const filterOptions = {
            [model.PROPS_REPORT_PAGE_OPTIONS]: {
                skip: 0,
            },
            [model.PROPS_REPORT_FILTER]: filterQuery,
            [model.PROPS_REPORT_SORT_LIST]: sortOptions,
        };
        const fieldGuids = this.fieldGuids();

        this.props.getReportBooks({ fieldGuids, filterOptions });
    };

    private onGetAutoSearchList = (searchName = "", searchString = "", filter) => {
        const filterOptions = {
            searchName,
            searchString,
            [model.PROPS_REPORT_FILTER]: filter,
        };
        const fieldGuids = this.fieldGuids();

        this.props.getReportBooksAutoComplete({
            fieldGuids,
            filterOptions,
            searchName,
            searchString,
        });
    };

    private onInputChange = (key, value) => {
        this.reportData[key] = value;
    };

    private onPaginatorChange = ({
        skip = 0,
        pageSize = 50,
        filterQuery = {},
        sortOptions = {},
    }) => {
        this.setState({ skip, pageSize, filterQuery, sortOptions });

        const filterOptions = {
            [model.PROPS_REPORT_PAGE_OPTIONS]: {
                pageSize,
                skip,
            },
            [model.PROPS_REPORT_SORT_LIST]: sortOptions,
            [model.PROPS_REPORT_FILTER]: filterQuery,
        };
        const fieldGuids = this.fieldGuids();

        this.props.getReportBooks({ fieldGuids, filterOptions });
    };

    private onSelectAll = ({ filterQuery, sortOptions }, clearSelectedItems = false) => {
        if (clearSelectedItems) {
            this.setState({ selectedItems: [] });
        } else {
            const filterOptions = {
                [model.PROPS_REPORT_PAGE_OPTIONS]: {
                    pageSize: 256000,
                    skip: 0,
                },
                [model.PROPS_REPORT_SORT_LIST]: sortOptions,
                [model.PROPS_REPORT_FILTER]: filterQuery,
            };
            const fieldGuids = this.fieldGuids();

            this.props.getReportBooksSelectAll({ fieldGuids, filterOptions });
        }
    };

    private onSortOption = ({ sortOptions, filterQuery, pageSize, skip }) => {
        this.setState({ skip, pageSize, filterQuery, sortOptions });

        const filterOptions = {
            [model.PROPS_REPORT_SORT_LIST]: sortOptions,
            [model.PROPS_REPORT_FILTER]: filterQuery,
            [model.PROPS_REPORT_PAGE_OPTIONS]: {
                pageSize,
                skip,
            },
        };
        const fieldGuids = this.fieldGuids();

        this.props.getReportBooks({ fieldGuids, filterOptions });
    };

    private resetReportBookErrorsModal = () => {
        this.props.setReportBookErrorsMap(new Map<string, IReportBookResultInfo>());
        this.setState({
            reportBookErrors: [],
            showReportBookErrorsModal: false,
        });
    };

    private selectReportType = (selected: ISelectReportType) => {
        const { guid } = selected;
        const selectedReportTypes = [...this.state.selectedReportTypes];
        const selectedReportTypeIndex = selectedReportTypes.indexOf(guid);
        const selectedReportTypeNames = [];
        if (selectedReportTypeIndex > -1) {
            selectedReportTypes.splice(selectedReportTypeIndex, 1);
        } else {
            selectedReportTypes.push(guid);
        }
        if (selectedReportTypes.length > 0 && this.state.reportBookTypes.length > 0) {
            const reportNamesSet = new Set<string>();
            this.state.reportBookTypes
                .filter((rbt) => selectedReportTypes.includes(rbt.reportBookTypeGuid))
                .forEach((rbt) => rbt.reportTypeNames?.forEach((name) => reportNamesSet.add(name)));
            selectedReportTypeNames.push(...Array.from(reportNamesSet));
        }

        this.setState({
            selectedReportTypeNames,
            selectedReportTypes,
        });
    };

    private fieldGuids() {
        return this.state.selectedFields.map((field) => field.fieldGuid);
    }
}

const mapStateToProps = (state: IReportReportBooksState) => ({
    agEventSummaryMap: eventsSelectors.getEventGeneralGuidToEventSummaryMap(state),
    autoSearchData: selectors.getReportBooksAutoComplete(state),
    clearReportBookTypes: selectors.getClearReportBookTypes(state),
    crops: getCropOptionsData(state),
    loadingReportBook: selectors.getLoadingReportBook(state),
    refreshReportBooks: selectors.getRefreshReportBooks(state),
    recGeneralGuidToRecMap: recsSelectors.getRecGeneralGuidToRecSummaryMap(state),
    reportBookErrorsMap: selectors.getReportBookErrorsMap(state),
    reportBooks: selectors.getReportBooks(state),
    reportBookTypes: selectors.getReportBookTypes(state),
    reportPreferences: getReportPref(state),
    reportTypePages: getReportTypePages(state),
    seasons: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
    ),
    selectedFields: getFields(state),
    selectedEventGuidSet: getSelectedEventGuidSet(state),
    selectedItems: selectors.getReportBooksSelectAll(state),
    selectedRecGuidSet: getSelectedRecGuidSet(state),
    totalCount: selectors.getReportBooksTotalCount(state),
});

const mapDispatchToProps = (dispatch) => ({
    deactivateReportBooks: (payload) => dispatch(actions.deactivateReportBooks(payload)),
    fetchPicklistData: (payload) => picklistActions.fetchPicklistData(payload),
    getCropDropdown: () => getCropDropdown(),
    getReportBooks: (payload) => dispatch(actions.getReportBooks(payload)),
    getReportBooksAutoComplete: (payload) => dispatch(actions.getReportBooksAutoComplete(payload)),
    getReportBooksSelectAll: (payload) => dispatch(actions.getReportBooksSelectAll(payload)),
    getReportBookTypes: () => dispatch(actions.getReportBookTypes()),
    getReportTypePageList: (payload) => getReportTypePageList(payload),
    mergeReportBooks: (payload) => dispatch(actions.mergeReportBooks(payload)),
    setReportBookErrorsMap: (payload) => dispatch(actions.setReportBookErrorsMap(payload)),
});

export const ReportReportBooks = injectIntl(
    withMasked(connect(mapStateToProps, mapDispatchToProps)(ReportReportBooks_))
);
