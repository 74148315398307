import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { withUser } from "~/hocs";
import { selectors as cdSelectors } from "~/customer-data";

import { EventModule, eventListSelectors } from "./event-module";
import { FieldModule } from "./field-module";
import { LayerModule } from "./layer-module";
import { PanelNavigation } from "./panel-navigation/panel-navigation";
import QuickSummaryBar from "./quick-summary-bar/quick-summary-bar";
import { RecModule, recListSelectors } from "./rec-module";
import {
    ImportModule,
    getSelectedImportFileInfoGuidSet,
} from "./import-module";

import {
    ActionPanelModuleList,
    setActiveModule,
    setIsExpanded,
    setIsEnabled,
} from "../actions";
import { getActiveModule, getIsExpanded, getIsEnabled } from "../selectors";
import BufferSetupModal from "~/map/components/map-tools/toolbars/dialog-boxes/buffer-setup-modal";

import "./action-panel.css";
import "./common/accordion/accordion.css";

class ActionPanelContainer extends React.Component {
    static propTypes = {
        setActiveModule: PropTypes.func,
        setIsExpanded: PropTypes.func,
        setIsEnabled: PropTypes.func,
        loadDetailPage: PropTypes.func,
        userInfo: PropTypes.object,

        activeModule: PropTypes.oneOf(
            Object.keys(ActionPanelModuleList).map(
                (k) => ActionPanelModuleList[k]
            )
        ),
        isExpanded: PropTypes.bool,
        isEnabled: PropTypes.bool,

        importSelectionCount: PropTypes.number,
        eventSelectionCount: PropTypes.number,
        recSelectionCount: PropTypes.number,
        fieldCount: PropTypes.number,
        quickSummary: PropTypes.object
    };

    setActiveModule = (activeModule) => {
        this.props.setActiveModule(activeModule);
    };
    toggleExpandCollapse = (isExpanded) => {
        this.props.setIsExpanded(isExpanded);
    };
    toggleEnabledDisabled = (isEnabled) => {
        this.props.setIsEnabled(isEnabled);
    };

    render() {
        const {
            activeModule,
            eventSelectionCount,
            fieldCount,
            importSelectionCount,
            isEnabled,
            isExpanded,
            recSelectionCount,
            userInfo,
        } = this.props;

        let currentModule = null;
        if (isExpanded) {
            switch (activeModule) {
                case ActionPanelModuleList.REC:
                    currentModule = <RecModule />;
                    break;
                case ActionPanelModuleList.EVENT:
                    currentModule = <EventModule />;
                    break;
                case ActionPanelModuleList.LAYER:
                    currentModule = <LayerModule />;
                    break;
                case ActionPanelModuleList.IMPORT:
                    currentModule = (
                        <ImportModule
                            loadDetailPage={this.props.loadDetailPage}
                        />
                    );
                    break;
                default:
                    currentModule = (
                        <FieldModule
                            togglePanelNavigationEnabled={(isEnabled) => {
                                this.toggleEnabledDisabled(isEnabled);
                            }}
                            loadDetailPage={this.props.loadDetailPage}
                        />
                    );
                    break;
            }
        }

        const quickSummaryBarStyle = {
            display: isExpanded ? "inherit" : "none",
        };

        const containerClassNames = classNames(
            "action-panel",
            isExpanded ? "action-panel-expanded" : "action-panel-collapsed"
        );
        let actionPanel = null;
        if (userInfo) {
            actionPanel = (
                <div className={containerClassNames}>
                    <PanelNavigation
                        activeModule={activeModule}
                        eventSelectionCount={eventSelectionCount}
                        fieldSelectionCount={fieldCount}
                        importSelectionCount={importSelectionCount}
                        isExpanded={isExpanded}
                        isEnabled={isEnabled}
                        onModuleChange={(newModule) =>
                            this.setActiveModule(newModule)
                        }
                        onToggleExpandCollapse={(newValue) =>
                            this.toggleExpandCollapse(newValue)
                        }
                        recSelectionCount={recSelectionCount}
                        userInfo={userInfo}
                    />
                    {currentModule}
                    <BufferSetupModal />
                    <div
                        className={classNames("quick-summary-bar-container")}
                        style={quickSummaryBarStyle}
                    >
                        <QuickSummaryBar
                            userInfo={userInfo}
                            allCustomers={this.props.quickSummary.customers}
                            fieldCount={this.props.quickSummary.fields}
                            selectedAcres={this.props.quickSummary.acres}
                            allEvents={this.props.quickSummary.events}
                            allRecs={this.props.quickSummary.recs}
                        />
                    </div>
                </div>
            );
        }
        return actionPanel;
    }
}

const mapStateToProps = (state) => {
    return {
        activeModule: getActiveModule(state),
        eventSelectionCount: eventListSelectors.getSelectedEventGuidSet(state).size,
        isExpanded: getIsExpanded(state),
        isEnabled: getIsEnabled(state),
        importSelectionCount: getSelectedImportFileInfoGuidSet(state).size,
        recSelectionCount: recListSelectors.getSelectedRecGuidSet(state).size,
        fieldCount: cdSelectors.getSelectedFieldsCount(state),
        quickSummary: cdSelectors.getQuickSummary(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveModule: (v) => dispatch(setActiveModule(v)),
        setIsExpanded: (v) => dispatch(setIsExpanded(v)),
        setIsEnabled: (v) => dispatch(setIsEnabled(v)),
    };
};

export const ActionPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(withUser(ActionPanelContainer));
