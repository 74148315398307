import React from "react";
import PropTypes from "prop-types";
import "./title-bar.css";

export const TitleBar = (props) => {
    const { featureName, customerName, acres } = props;

    return (
        <div className="non-field-feature-information-title-bar title-bar">
            <div className="first-line">{featureName}</div>
            <div className="second-line">{customerName}</div>
            <div className="third-line">{acres.toFixed(2)} acres</div>
        </div>
    );
};

TitleBar.propTypes = {
    acres: PropTypes.any,
    customerName: PropTypes.any,
    featureName: PropTypes.any,
};
