import { connect } from "react-redux";

import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getUser } from "~/login/selectors";
import { actions as recsEventsActions, models, recsSelectors } from "~/recs-events";

import { getRecGuidFromDimIdx } from "../../../../../common/accordion/rec-event-accordion-item";

import { ContextMenu } from "../../../../../context-menus/item-selection-context-menu";
import { messages as menuMessages } from "../../../../../context-menus/i18n-messages";

import * as selectors from "../../selectors";
import * as actions from "../../actions";

import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as recListSelectors from "~/action-panel/components/rec-module/components/rec-list/selectors";

import { messages } from "../../../i18n-messages";

const getRecItemPropIter = function* (accordionItems, selectedRecGuidSet) {
    const recAccordionItemIter = accordionModel.getDimIdxSliceIter(
        accordionItems,
        [0],
        undefined,
        false,
        [2]
    );
    for (let dimIdx of recAccordionItemIter) {
        const recGuid = getRecGuidFromDimIdx(accordionItems, dimIdx);
        const isSelected = selectedRecGuidSet.has(recGuid);
        yield { dimIdx, isSelected };
    }
};

const getFieldItemPropIter = function* (accordionItems) {
    const recAccordionItemIter = accordionModel.getDimIdxSliceIter(
        accordionItems,
        [0],
        undefined,
        false,
        [1]
    );
    for (let dimIdx of recAccordionItemIter) {
        const isExpanded = accordionModel.getItem(accordionItems, dimIdx).expanded;
        yield { dimIdx, isExpanded };
    }
};

const modifyMenuItems = (menuItems, props) => {
    const { formatMessage } = props.intl;
    const {
        disableExportController,
        recTypeOptions,
        selectedRecGuidSet,
        getBatchEnabled,
        getCollapseAllArg,
        getCollapseAllEnabled,
        getExpandAllArg,
        getExpandAllEnabled,
        isApplicationEquationRec,
        moreThanOneManualApplicationRecSelected,
        onOpenConvertRecsToEventsModal,
        onBatchEditRecs,
        onCollapseAll,
        onExpandAll,
        onOpenExportControllerFileModal,
        onNewBatchRec,
        onEventSelection,
        onRecSelection,
        eventSelectionCount,
        recSelectionCount,
        userInfo,
    } = props;

    const batchRecs = !userInfo.role.batchRecs
        ? []
        : [
              {
                  label: formatMessage(menuMessages.newBatchRecText),
                  disabled: !getBatchEnabled(),
                  subMenuItems: recTypeOptions.map((recTypeOption) => ({
                      key: recTypeOption.label,
                      label: recTypeOption.label,
                      action: () => onNewBatchRec(recTypeOption.value),
                  })),
              },
          ];

    const expandCollapse = [
        {
            label: formatMessage(menuMessages.expandAll),
            action: () => onExpandAll(getExpandAllArg()),
            disabled: !getExpandAllEnabled(),
        },
        {
            label: formatMessage(menuMessages.collapseAll),
            action: () => onCollapseAll(getCollapseAllArg()),
            disabled: !getCollapseAllEnabled(),
        },
    ];

    const eventRecSelection = [];

    if (recSelectionCount > 0) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.recSelectionText),
            action: () => onRecSelection(),
        });
    }
    if (recSelectionCount > 0 && userInfo.role.batchEditRecs) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.batchEditRecsText),
            disabled: !isApplicationEquationRec(),
            action: () => onBatchEditRecs(),
        });
    }
    if (eventSelectionCount > 0) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.eventSelectionText),
            action: () => onEventSelection(),
        });
    }

    if (selectedRecGuidSet.size < 2) {
        return [
            ...batchRecs,
            ...menuItems.slice(0, 2),
            ...eventRecSelection,
            ...expandCollapse,
            ...menuItems.slice(2),
        ];
    }

    const exportControllerFile = {
        label: formatMessage(messages.exportControllerFile, {
            count: selectedRecGuidSet.size,
        }),
        action: () => onOpenExportControllerFileModal(selectedRecGuidSet),
        disabled: disableExportController(),
    };

    const convertRecsToEvents = moreThanOneManualApplicationRecSelected()
        ? [
              {
                  label: formatMessage(menuMessages.convertRecsToEvents),
                  action: () => onOpenConvertRecsToEventsModal(),
                  disabled: recSelectionCount < 1,
              },
          ]
        : [];

    return [
        ...batchRecs,
        ...menuItems.slice(0, 2),
        ...eventRecSelection,
        ...expandCollapse,
        ...menuItems.slice(2),
        ...convertRecsToEvents,
        exportControllerFile,
    ];
};

const mapStateToProps = (state) => {
    const moduleState = selectors.getModuleState(state);
    const accordionState = selectors.getAccordionState(state);
    const recTypeOptions = recsSelectors.getNewableRecTypeOptions(state);
    const recGeneralGuidToRecMap = recsSelectors.getRecGeneralGuidToRecSummaryMap(state);
    const recSelection = recListSelectors.getSelectedRecGuidSet(state);
    const fieldGuidToRecListMap = recsSelectors.getFieldGuidToRecListMap(state);

    const { accordionId, items } = accordionState;
    const { selectedRecGuidSet } = moduleState;

    const getFieldGuidInfo = () => {
        const fieldGuidList = items.map((accordionItem) => accordionItem.payload.fieldGuid);
        const fieldGuidToBoundaryGuidMap = new Map(
            items.map((accordionItem) => {
                return [accordionItem.payload.fieldGuid, accordionItem.payload.fieldBoundaryGuid];
            })
        );

        return [fieldGuidList, fieldGuidToBoundaryGuidMap];
    };

    const someAreDeselected = () => {
        for (let { isSelected } of getRecItemPropIter(items, selectedRecGuidSet)) {
            if (!isSelected) {
                return true;
            }
        }
        return false;
    };

    const someAreSelected = () => {
        for (let { isSelected } of getRecItemPropIter(items, selectedRecGuidSet)) {
            if (isSelected) {
                return true;
            }
        }
        return false;
    };

    const someAreExpanded = () => {
        for (let { isExpanded } of getFieldItemPropIter(items)) {
            if (isExpanded) {
                return true;
            }
        }
        return false;
    };

    const someAreNotExpanded = () => {
        for (let { isExpanded } of getFieldItemPropIter(items)) {
            if (!isExpanded) {
                return true;
            }
        }
        return false;
    };

    const getSelectedDimIdxList = () => {
        return [...getRecItemPropIter(items, selectedRecGuidSet)]
            .filter(({ isSelected }) => isSelected)
            .map(({ dimIdx }) => dimIdx);
    };

    const disableExportController = () => {
        const selectedRecStatuses = [];
        selectedRecGuidSet.forEach((r) =>
            selectedRecStatuses.push(recGeneralGuidToRecMap.get(r).recStatuses)
        );
        return (
            selectedRecGuidSet.size === 0 ||
            selectedRecStatuses.some((r) => selectors.DISABLE_EXPORT_STATUSES.includes(r))
        );
    };

    const isApplicationEquationRec = () => {
        for (const recGeneralGuid of selectedRecGuidSet) {
            const recSummary = recGeneralGuidToRecMap.get(recGeneralGuid);
            if (
                recSummary != null &&
                recSummary.recType !== models.REC_TYPE_NAME_EQUATION_APPLICATION
            ) {
                return false;
            }
        }
        return true;
    };

    const moreThanOneManualApplicationRecSelected = () => {
        var i = 0;
        for (const recGeneralGuid of selectedRecGuidSet) {
            const recSummary = recGeneralGuidToRecMap.get(recGeneralGuid);
            if (recCanBeConvertedToEvent(recSummary)) {
                i++;
            }
            if (i > 1) {
                return true;
            }
        }
        return false;
    };

    const recCanBeConvertedToEvent = (recSummary) => {
        const canBeConverted =
            recSummary != null &&
            (recSummary.recType === models.REC_TYPE_NAME_MANUAL_APPLICATION ||
                recSummary.recType === models.REC_TYPE_NAME_EQUATION_APPLICATION) &&
            !selectors.DISALLOW_REC_CONVERT_COPY_STATUSES.includes(recSummary.recStatuses);
        return canBeConverted;
    };

    return {
        deleteConfirmationMessage: messages.deleteMultipleRecsConfirmation,
        disableExportController: disableExportController,
        fieldGuidToRecListMap,
        getBatchEnabled: () => items.length > 1,
        getClearSelectedArg: () => null,
        getClearSelectedEnabled: someAreSelected,
        getCollapseAllArg: () => accordionId,
        getCollapseAllEnabled: someAreExpanded,
        getDeleteSelectedArg: getSelectedDimIdxList,
        getDeleteSelectedEnabled: someAreSelected,
        getExpandAllArg: () => accordionId,
        getExpandAllEnabled: someAreNotExpanded,
        getFieldGuidInfo,
        getSelectAllArg: () => null,
        getSelectAllEnabled: someAreDeselected,
        modifyMenuItems,
        isApplicationEquationRec,
        moreThanOneManualApplicationRecSelected,
        recCanBeConvertedToEvent,
        recTypeOptions,
        selectedRecGuidSet,
        eventSelectionCount: eventListSelectors.getSelectedEventGuidSet(state).size,
        recSelectionCount: recSelection.size,
        recSelection,
        recGeneralGuidToRecMap: recGeneralGuidToRecMap,
        selectedEventFieldGuidSet: eventListSelectors.getSelectedEventFieldGuidSet(state),
        selectedRecFieldGuidSet: recListSelectors.getSelectedRecFieldGuidSet(state),
        selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
        userInfo: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearSelectedFields: (fieldGuids) => dispatch(cdActions.clearSelectedFields(fieldGuids)),
        selectFields: (fieldGuidList) => dispatch(cdActions.addSelectedFields(fieldGuidList)),
        onOpenConvertRecsToEventsModal: (recGeneralGuidToFieldGuidMap) =>
            dispatch(actions.setConvertRecsToEventsGuidMap(recGeneralGuidToFieldGuidMap)),
        onSelectAll: () => dispatch(actions.selectRecsFromAccordion([0])),
        onClearSelected: () => dispatch(actions.deselectRecsFromAccordion([0])),
        onDeleteSelected: (toDeleteDimIdxList) =>
            dispatch(actions.deleteRecsFromDimIdxList(toDeleteDimIdxList)),
        onExpandAll: (accordionId) =>
            dispatch(accordionActions.expandAllAccordionItems(accordionId, [1])),
        onCollapseAll: (accordionId) =>
            dispatch(accordionActions.collapseAllAccordionItems(accordionId, [1])),
        onNewBatchRec: (fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo) =>
            dispatch(
                recsEventsActions.createNewBatchRec(
                    fieldGuidList,
                    fieldGuidToBoundaryGuidMap,
                    recTypeInfo
                )
            ),
        onOpenExportControllerFileModal: (recGeneralGuids) =>
            dispatch(actions.setExportRecGeneralGuidSet(recGeneralGuids)),
        onBatchEditRecs: (
            selectedRecFieldGuidSet,
            recGeneralGuidToRecMap,
            fieldGuidList,
            fieldGuidToBoundaryGuidMap
        ) =>
            dispatch(
                recsEventsActions.showBatchRecsEdit(
                    selectedRecFieldGuidSet,
                    recGeneralGuidToRecMap,
                    fieldGuidList,
                    fieldGuidToBoundaryGuidMap
                )
            ),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onOpenConvertRecsToEventsModal: () => {
        const { selectedRecGuidSet, recGeneralGuidToRecMap } = stateProps;
        const recGeneralGuidToFieldGuidMap = new Map();
        selectedRecGuidSet.forEach((recGeneralGuid) => {
            const recSummary = recGeneralGuidToRecMap.get(recGeneralGuid);
            if (stateProps.recCanBeConvertedToEvent(recSummary)) {
                recGeneralGuidToFieldGuidMap.set(
                    recGeneralGuid,
                    recGeneralGuidToRecMap.get(recGeneralGuid).fieldGuid
                );
            }
        });
        dispatchProps.onOpenConvertRecsToEventsModal(recGeneralGuidToFieldGuidMap);
    },
    onBatchEditRecs: () => {
        const { selectedRecGuidSet, recGeneralGuidToRecMap } = stateProps;
        const [fieldGuidList, fieldGuidToBoundaryGuidMap] = stateProps.getFieldGuidInfo();

        dispatchProps.onBatchEditRecs(
            selectedRecGuidSet,
            recGeneralGuidToRecMap,
            fieldGuidList,
            fieldGuidToBoundaryGuidMap
        );
    },
    onNewBatchRec: (recTypeInfo) => {
        const [fieldGuidList, fieldGuidToBoundaryGuidMap] = stateProps.getFieldGuidInfo();
        dispatchProps.onNewBatchRec(fieldGuidList, fieldGuidToBoundaryGuidMap, recTypeInfo);
    },
    onEventSelection: () => {
        const { selectedFieldGuids, selectedEventFieldGuidSet } = stateProps;
        const { clearSelectedFields, selectFields } = dispatchProps;
        if (selectedEventFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedEventFieldGuidSet)));
            selectFields(Array.from(selectedEventFieldGuidSet.values()));
        }
    },
    onRecSelection: () => {
        const { selectedFieldGuids, selectedRecFieldGuidSet } = stateProps;
        const { clearSelectedFields, selectFields } = dispatchProps;
        if (selectedRecFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedRecFieldGuidSet)));
            selectFields(Array.from(selectedRecFieldGuidSet.values()));
        }
    },
});

export const TabsContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ContextMenu);
