import { call, put, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { actions as notificationActions } from "~/notifications";
import { actions as preferenceActions } from "~/admin/setup/preference/data";
import { NavigationAPI, UserAPI } from "@ai360/core";
import * as loginActions from "~/login/actions";

import * as actions from "./actions";
import { Messages } from "./i18n-messages";

function filterNavigationLists(theUser): Promise<NavigationAPI.INavigation[]> {
    /* eslint-disable no-console */
    return UserAPI.getNavigationLists(theUser.userGuid, "Main").then((response) => {
        //remove items which are not pages or parent items from the list
        //Todo: This could be done on the server after the remediation
        const filteredArray = [];
        const arrayToIgnore = [
            "103",
            "104",
            "106",
            "109",
            "110",
            "111",
            "112",
            "113",
            "114",
            "115",
            "116",
            "119",
            "184",
            "185",
            "218",
            "222",
            "230",
            "231",
            "232",
            "240",
            "244",
            "245",
            "246",
            "248",
            "249",
            "250",
        ];
        _.forEachRight(response.gearboxList, function (listItem) {
            if (!_.includes(arrayToIgnore, listItem.softwareFunctionNameCode)) {
                //loop through child lists and only push items which will display
                if (listItem.userChildDashboardGearBox) {
                    const childArray = [];
                    _.forEachRight(
                        listItem.userChildDashboardGearBox.gearboxList,
                        function (childListItem) {
                            if (
                                !_.includes(arrayToIgnore, childListItem.softwareFunctionNameCode)
                            ) {
                                //TODO: Agrintelligence Reports(Proprietary) this could be done better after remediation
                                if (
                                    childListItem.softwareFunctionNameCode !== "224" ||
                                    (childListItem.softwareFunctionNameCode === "224" &&
                                        theUser.systemProperties.systemName !== "Agvance Sky")
                                ) {
                                    if (!(childListItem.softwareFunctionNameCode in Messages)) {
                                        //Todo: this should display the Owner Branding Name over the new Branding Name
                                        console.warn(
                                            "skipping unknown `childListItem.softwareFunctionNameCode`",
                                            childListItem.softwareFunctionNameCode
                                        );
                                    } else {
                                        childListItem.label =
                                            Messages[childListItem.softwareFunctionNameCode] || "X";
                                        childListItem.key = childListItem.softwareFunctionGuid;
                                        childListItem.action = () => null;
                                        childArray.push(childListItem);
                                    }
                                }
                            }
                        }
                    );
                    listItem.gearboxList = childArray;
                }
                listItem.displayText = Messages[listItem.softwareFunctionNameCode];
                filteredArray.push(listItem);
            }
        });
        return filteredArray;
    });
}

function* getNavigationLists(setUserAction) {
    try {
        const navList: NavigationAPI.INavigation[] = yield call(
            filterNavigationLists,
            setUserAction.theUser
        );
        yield put(actions.navlistFetchSucceeded(navList));
    } catch (e) {
        yield put(notificationActions.apiCallError(e));
    } finally {
        yield put(notificationActions.fetchUserNotifications());
        yield put(preferenceActions.fetchPreference());
    }
}

export function* navSaga() {
    yield takeLatest(loginActions.SET_USER_INFO_COMPLETE, getNavigationLists);
}
