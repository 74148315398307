import React, { Component } from "react";
import PropTypes from "prop-types";
import { AttributeEditModal } from "./attribute-edit-modal";
import { ImportFilter } from "../import-filter";
import { DateInput, Loader, SelectInput, TextInput } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { ACTIVE_YN } from "~/core/picklist";
import { messages } from "./i18n-messages";
import { PICKLIST_CROPPING_SEASON } from "~/core/picklist/picklist-names";
import * as actions from "../../../../../actions";

class ImportLayerFilter_ extends Component {
    static propTypes = {
        importFileGuidList: PropTypes.array.isRequired,
        picklistData: PropTypes.object.isRequired,
        filterItems: PropTypes.array.isRequired,
        selectItem: PropTypes.func.isRequired,
        updateFilterStatus: PropTypes.func.isRequired,
        updateAttribute: PropTypes.func.isRequired,
        selectedFieldGuid: PropTypes.string,
        intl: intlShape.isRequired,
        removeImportFiles: PropTypes.func,
    };
    static defaultProps = {
        importFileGuidList: [],
        selectedFieldGuid: null,
    };

    constructor(props) {
        super(props);
        const croppingSeasonOptions = ImportLayerFilter_.getOptionsFromPicklist(
            props.picklistData[PICKLIST_CROPPING_SEASON]
        );
        this.state = {
            editItem: null,
            croppingSeasonOptions: croppingSeasonOptions,
            name: null,
            croppingSeasonGuid: null,
            imageDate: null,
            isSaving: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps[PICKLIST_CROPPING_SEASON] !==
            this.props.picklistData[PICKLIST_CROPPING_SEASON]
        ) {
            const croppingSeasonOptions = ImportLayerFilter_.getOptionsFromPicklist(
                nextProps.picklistData[PICKLIST_CROPPING_SEASON]
            );
            this.setState({
                croppingSeasonOptions,
            });
        }
    }

    static getOptionsFromPicklist(picklistData) {
        if (!picklistData) {
            return [];
        }
        return picklistData.map((item) => {
            return {
                value: item.picklistValueGuid,
                label: item.value,
            };
        });
    }

    filterProperties = [
        {
            isRequired: true,
            label: this.props.intl.formatMessage(messages.layerNameText),
            propertyName: "name",
            propertyGuid: "imageryLayerGuid",
            cellSize: 30,
        },
        {
            isRequired: true,
            label: this.props.intl.formatMessage(messages.croppingSeasonText),
            propertyName: "croppingSeasonName",
            propertyGuid: "croppingSeasonGuid",
            cellSize: 20,
        },
        {
            isRequired: true,
            label: this.props.intl.formatMessage(messages.dateText),
            propertyName: "imageDate",
            cellSize: 30,
        },
    ];

    onEditAttribute = (item) => {
        this.setState({
            editItem: item,
            name: item.name,
            croppingSeasonGuid: item.croppingSeasonGuid,
            imageDate: item.imageDate,
        });
    };

    onSaveEdit = () => {
        const requestObject = [
            {
                layer: {
                    imageryLayerGuid: this.state.editItem.imageryLayerGuid,
                    importFileGuid: this.state.editItem.importFileGuid,
                    name: this.state.name,
                    croppingSeasonGuid: this.state.croppingSeasonGuid,
                    imageDate: this.state.imageDate,
                },
            },
        ];
        this.setState({ isSaving: true });
        this.props.updateAttribute(requestObject, this.onCloseEdit);
    };

    onCloseEdit = () => {
        this.setState({
            editItem: null,
            name: null,
            croppingSeasonGuid: null,
            imageDate: null,
            isSaving: false,
        });
    };

    canSave = () => {
        return (
            Boolean(this.state.name) &&
            Boolean(this.state.croppingSeasonGuid) &&
            Boolean(this.state.imageDate)
        );
    };

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <ImportFilter
                    title={formatMessage(messages.layerFilterTitle)}
                    itemProperties={this.filterProperties}
                    filterItems={this.props.filterItems}
                    filterTypeName={actions.ImportFilterTypes.LAYER_LIST}
                    selectItem={(item) => {
                        this.props.selectItem(item);
                    }}
                    onEditAttribute={(item) => {
                        this.onEditAttribute(item);
                    }}
                    updateFilterStatus={(isComplete) => this.props.updateFilterStatus(isComplete)}
                    selectedFieldGuid={this.props.selectedFieldGuid}
                    removeImportFiles={(importFileGuidList) =>
                        this.props.removeImportFiles(importFileGuidList)
                    }
                />
                {!this.state.editItem ? null : (
                    <AttributeEditModal
                        className="layer-edit-modal"
                        title={formatMessage(messages.editLayerText)}
                        isOpen={true}
                        canSave={this.canSave()}
                        onSaveEdit={this.onSaveEdit}
                        onCloseEdit={this.onCloseEdit}
                    >
                        {this.state.isSaving ? <Loader /> : null}
                        <div className="edit-modal-row">
                            <TextInput
                                required
                                maxLength={50}
                                placeholderText={formatMessage(messages.layerNameText)}
                                onChange={(value) => this.setState({ name: value })}
                                value={this.state.name}
                            />
                        </div>
                        <div className="edit-modal-row">
                            <SelectInput
                                containerClassNames={["season-input"]}
                                value={this.state.croppingSeasonGuid}
                                optionIsHiddenKey={ACTIVE_YN}
                                options={this.state.croppingSeasonOptions}
                                required={true}
                                clearable={false}
                                placeholderText={formatMessage(messages.croppingSeasonText)}
                                initialFilterString={this.state.editItem.croppingSeasonName}
                                onChange={(value) => this.setState({ croppingSeasonGuid: value })}
                            />
                            <DateInput
                                required
                                clearDisabled
                                onChange={(value) => this.setState({ imageDate: value })}
                                placeholderText={formatMessage(messages.dateText)}
                                value={this.state.imageDate}
                                timeFormat={false}
                            />
                        </div>
                    </AttributeEditModal>
                )}
            </div>
        );
    }
}

export const ImportLayerFilter = injectIntl(ImportLayerFilter_);
