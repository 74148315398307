import {
    all,
    call,
    cancelled,
    put,
    select,
    takeEvery,
    takeLatest,
    SelectEffect,
    PutEffect,
} from "redux-saga/effects";
import { PayloadAction } from "typesafe-actions/dist/types";

import Immutable, { Map as ImmutableMap, Set as ImmutableSet } from "immutable";

import { actions as loginActions, selectors as loginSelectors } from "~/login";
import * as mapActions from "~/map/components/map-control/actions";
import { actions as notificationActions } from "~/notifications";
import * as fieldListActions from "~/action-panel/components/field-module/components/field-list/actions";
import * as fieldListSelectors from "~/action-panel/components/field-module/components/field-list/selectors";
import { actions as messagingActions } from "~/messaging";
import { SearchAPI, FieldAPI, HierarchyAPI, NonFieldFeatureAPI, pagination } from "@ai360/core";
import { PersonalityTypes } from "~/utils/keywords";
import * as fieldModuleActions from "~/action-panel/components/field-module/actions";
import * as fieldModuleSelectors from "~/action-panel/components/field-module/selectors";

import * as actions from "./actions";
import * as models from "./models";
import * as selectors from "./selectors";

import * as onApiError from "~/utils/api/on-error";
import { IActionData } from "./interfaces";
import { NonFieldFeature } from "@ai360/core/dist/4x/es/api/non-field-feature";
import {
    autoExpandedCustomerIds,
    autoExpandedCustomerModifications,
    fieldGuidFilter,
    fieldsSummaryRequest,
    filteredCustomerFieldRequest,
    filteredFieldRequest,
    filteredSummaryRequest,
    maxCustomerFieldPageSize,
    defaultCustomerFieldPageSize,
} from "~/utils/api/search";
import { delay } from "redux-saga";

interface ICustomerChangedMessage {
    customerGuid: string;
    customerData: any;
    activatedFieldGuids: string[];
    deactivatedFieldGuids: string[];
}

const fetchCustomerFields = function* (
    action:
        | ReturnType<typeof actions.fetchCustomerFields>
        | ReturnType<typeof actions.restartCustomerFields>
) {
    const { restart } = action.payload;

    yield delay(250);

    const abortController = new AbortController();

    yield put(actions.fetchingCustomerFields(abortController));

    const lastPageId: SearchAPI.ICustomerFieldPageId = restart
        ? null
        : yield select(selectors.getLastCustomerPageId);

    try {
        const customerFieldsResponse: pagination.IPaginatedResponse<
            SearchAPI.ICustomerFieldResult,
            SearchAPI.ICustomerFieldPageId
        > = yield call(
            SearchAPI.getCustomerFields,
            yield filteredCustomerFieldRequest(null, defaultCustomerFieldPageSize, lastPageId),
            abortController.signal
        );

        const autoExpandedCustomers = autoExpandedCustomerIds(customerFieldsResponse.results);

        const fieldsResponse: SearchAPI.IFieldResult[] =
            autoExpandedCustomers.length === 0
                ? []
                : yield call(
                      SearchAPI.getFields,
                      yield filteredFieldRequest(autoExpandedCustomers),
                      abortController.signal
                  );

        const manuallyExpandedCustomers: Immutable.Set<string> = yield select(
            fieldListSelectors.getManualExpandedCustomers
        );

        const autoExpandedCustomerSet = ImmutableSet(autoExpandedCustomers);

        const autoExpandedFields = fieldsResponse.filter(
            (x) =>
                autoExpandedCustomerSet.has(x.customerId) ||
                manuallyExpandedCustomers.has(x.customerId)
        );

        yield put(
            actions.fetchCustomerFieldsSuccess(
                customerFieldsResponse.results,
                autoExpandedCustomers,
                customerFieldsResponse.lastPageId,
                customerFieldsResponse.isDone,
                restart
            )
        );

        yield put(actions.fetchFieldsSuccess(autoExpandedFields, restart));
    } catch (error) {
        yield put(actions.fetchCustomerFieldsError());
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (yield cancelled()) {
            abortController.abort();
        }
    }
};

const fetchFields = function* (action: ReturnType<typeof actions.fetchFields>) {
    try {
        const results: SearchAPI.IFieldResult[] = yield call(
            SearchAPI.getFields,
            yield filteredFieldRequest([action.payload.customerId])
        );

        yield put(actions.fetchFieldsSuccess(results, false));
    } catch (error) {
        yield put(notificationActions.apiCallError(error, action));
    }
};

const fetchSummary = function* (action) {
    yield delay(250);

    const abortController = new AbortController();

    yield put(actions.fetchingSummary());

    const userGuid = yield select(loginSelectors.getTheUserGuid);
    const filterSelections = yield select(fieldListSelectors.getFilterSelections);
    const fieldSelections: ImmutableSet<string> = yield select(selectors.getSelectedFieldGuids);

    try {
        const request: SearchAPI.ISummaryRequest =
            fieldSelections.size === 0
                ? filteredSummaryRequest(userGuid, filterSelections)
                : fieldsSummaryRequest(userGuid, [...fieldSelections]);

        const response: SearchAPI.ISummaryResponse = yield call(
            SearchAPI.getSummary,
            request,
            abortController.signal
        );

        yield put(actions.fetchSummarySuccess(response));
    } catch (error) {
        yield put(actions.fetchSummaryError());
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (yield cancelled()) {
            abortController.abort();
        }
    }
};

const fetchFilteredFieldGuids = function* (action) {
    yield delay(250);

    const abortController = new AbortController();

    const userGuid = yield select(loginSelectors.getTheUserGuid);
    const filterSelections = yield select(fieldListSelectors.getFilterSelections);
    const selectedFieldGuids = yield select(selectors.getSelectedFieldGuids);
    const activeTab = yield select(fieldModuleSelectors.getActiveTab);

    try {
        const results: string[] = yield call(
            SearchAPI.getFieldIds,
            {
                fieldGuid: fieldGuidFilter(activeTab, selectedFieldGuids),
                userGuid,
                search: filterSelections.search === "" ? null : filterSelections.search,
                active: true,
                certifiedOrganic: filterSelections.certifiedOrganic,
                irrigated: filterSelections.irrigated,
                crop: filterSelections.crops,
                classification: filterSelections.classifications,
            },
            abortController.signal
        );

        yield put(actions.fetchFilteredFieldGuidsSuccess(results));
    } catch (error) {
        yield put(actions.fetchFilteredFieldGuidsError());
        yield put(notificationActions.apiCallError(error, action));
    } finally {
        if (yield cancelled()) {
            abortController.abort();
        }
    }
};

const onSummaryChanged = function* () {
    yield put(actions.fetchSummary());
    yield put(actions.fetchFilteredFieldGuids());
};

const fetchNonFieldFeatures = function* (action, apiCall) {
    if (!(yield call(hasAccessToNonFieldFeatures))) {
        return;
    }

    yield onApiError.displayNotification(function* () {
        const features = yield apiCall;
        const featureIds = features.map((feature) => feature.id);
        yield put(
            actions.modifyNonFieldFeatures({
                remove: featureIds,
                add: features,
            })
        );
    }, action);
};

const hasAccessToNonFieldFeatures = function* () {
    const userRole = (yield select(loginSelectors.getUser)).role;
    return Boolean(userRole.nonFieldFeatures);
};

const init = function* (action) {
    yield put(actions.fetchCustomerFields());
    yield put(fieldListActions.fetchFilters(null));
    yield put(actions.fetchSummary());
    yield put(actions.fetchFilteredFieldGuids());
    const userGuid = yield select(loginSelectors.getTheUserGuid);
    let response;
    try {
        response = yield HierarchyAPI.getOrgLevelList(userGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    const orgLevels = new Map<string, FieldAPI.IOrgLevelInfo>(
        response.map((obj) => [obj.orgLevelGuid, new models.OrgLevelInfo(obj)])
    );
    yield put(actions.setOrgLevelData(orgLevels));
};

export const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(0, {
            eventName: "moveField",
            action: (message) =>
                actions.moveFields(
                    message.fieldMoveRequest.customerGuid,
                    message.fieldMoveRequest.farmName,
                    message.fieldMoveRequest.fieldGuidList
                ),
        })
    );

    const addUpdateFields = function* (message: models.IAddUpdateFieldsMessage) {
        const customerFields: pagination.IPaginatedResponse<
            SearchAPI.ICustomerFieldResult,
            SearchAPI.ICustomerFieldPageId
        > = yield call(
            SearchAPI.getCustomerFields,
            yield filteredCustomerFieldRequest(null, defaultCustomerFieldPageSize, null)
        );

        const fields = message.fields.map(models.FieldInfo.fromMessage);
        const customers = customerFields.results.map(models.CustomerInfo.fromCustomerField);
        yield put(
            actions.addUpdateFields(
                fields,
                customers,
                autoExpandedCustomerModifications(customerFields.results)
            )
        );
    };

    const fieldActivated = function* (messages: { fieldGuid: string }[]) {
        yield put(actions.activateFields(messages.map((x) => x.fieldGuid)));
    };

    const fieldDeactivated = function* (messages: { fieldGuid: string }[]) {
        yield put(actions.deactivateFields(messages.map((x) => x.fieldGuid)));
    };

    yield put(
        messagingActions.subscribe(
            5000,
            {
                eventName: "addUpdateFields",
                generator: addUpdateFields,
            },
            {
                eventName: "fieldActivated",
                generatorAccumulate: fieldActivated,
            },
            {
                eventName: "fieldDeactivated",
                generatorAccumulate: fieldDeactivated,
            },
            {
                eventName: "fieldEventCountChanged",
                actionAccumulate: actions.batchUpdateFieldEventCount,
            },
            {
                eventName: "fieldRecCountChanged",
                actionAccumulate: actions.batchUpdateFieldRecCount,
            }
        )
    );

    const customerChanged = function* (message: ICustomerChangedMessage) {
        const { customerGuid, customerData } = message;
        console.assert(customerGuid != null && customerData != null);
        const customerFieldsResponse: pagination.IPaginatedResponse<
            SearchAPI.ICustomerFieldResult,
            SearchAPI.ICustomerFieldPageId
        > = yield call(
            SearchAPI.getCustomerFields,
            yield filteredCustomerFieldRequest(null, 1, null)
        );
        yield put(
            actions.addUpdateCustomer(
                models.CustomerInfo.fromCustomerField(customerFieldsResponse.results[0])
            )
        );
    };

    const customerActivated = (message) => {
        console.assert(message.customerGuid != null);
        const { customerGuid, activatedFieldGuids } = message;
        return actions.activateCustomer(customerGuid, activatedFieldGuids);
    };

    const customerDeactivated = (message) => {
        console.assert(message.customerGuid != null);
        const { customerGuid, deactivatedCustomerGuids } = message;
        return actions.deleteCustomer(customerGuid, deactivatedCustomerGuids);
    };

    yield put(
        messagingActions.subscribe(
            1000,
            {
                eventName: "customerChanged",
                generator: customerChanged,
            },
            {
                eventName: "customerActivated",
                action: customerActivated,
            },
            {
                eventName: "customerDeactivated",
                action: customerDeactivated,
            }
        )
    );
};

export const onAddUpdateFields = function* (action: IActionData) {
    const activeTab = yield select(fieldModuleSelectors.getActiveTab);
    const { fields, customers, autoExpandedCustomersModifications } = action.payload;
    yield put(
        actions.batchUpdateField(fields, customers, activeTab, autoExpandedCustomersModifications)
    );
    yield put(mapActions.setForceRefreshFlag(true));
};

export const onDeleteCustomer = function* (action: IActionData) {
    const { customerGuid } = action.payload;
    const customerMap: Map<string, models.CustomerInfo> = yield select(selectors.getCustomerMap);
    if (!customerMap.has(customerGuid) || !customerMap.get(customerGuid).activeYn) {
        // no need to remove a customer that doesn't exist or is already inactive
        return;
    }

    const personalityId: number = yield select(loginSelectors.getTheUserPersonalityId);

    if (personalityId === PersonalityTypes.DISCONNECTED) {
        yield put(actions.deactivateCustomer(customerGuid));
    } else {
        // note: connected customers cannot be deleted if they still have active fields
        yield put(actions.removeConnectedCustomer(customerGuid));
    }
};

const modifyFieldsAndCustomersFromServer = function* (fieldGuids: string[], activate: boolean) {
    const activeTab: fieldModuleActions.FieldListTabs = yield select(
        fieldModuleSelectors.getActiveTab
    );
    if (
        activeTab !== fieldModuleActions.FieldListTabs.ACTIVE &&
        activeTab !== fieldModuleActions.FieldListTabs.INACTIVE
    ) {
        return;
    }

    const onActiveTab = activeTab === fieldModuleActions.FieldListTabs.ACTIVE;
    const userGuid: string = yield select(loginSelectors.getTheUserGuid);

    const fieldsResponse: SearchAPI.IFieldResult[] = yield call(SearchAPI.getFields, {
        fieldGuid: fieldGuids,
        userGuid,
    });
    const customerFieldsResponse: pagination.IPaginatedResponse<
        SearchAPI.ICustomerFieldResult,
        SearchAPI.ICustomerFieldPageId
    > = yield call(
        SearchAPI.getCustomerFields,
        yield filteredCustomerFieldRequest(null, maxCustomerFieldPageSize, null)
    );

    const customers = customerFieldsResponse.results.map(models.CustomerInfo.fromCustomerField);
    const fields = fieldsResponse.map(models.FieldInfo.fromSearch);

    const customerModifications: actions.CustomerModifications = {
        merge: customers.map((x) => [x.customerGuid, x]),
    };

    const fieldModifications: actions.FieldModifications =
        onActiveTab === activate
            ? {
                  merge: fields.map((x) => [x.fieldGuid, x]),
              }
            : {
                  remove: fields.map((x) => x.fieldGuid),
              };

    yield put(actions.modifyCustomers(customerModifications));
    yield put(actions.modifyFields(fieldModifications));
};

export const onActivateFields = function* (action: IActionData) {
    yield call(modifyFieldsAndCustomersFromServer, [...action.payload.fieldGuids], true);
};

export const onDeactivateFields = function* (action: IActionData) {
    yield call(modifyFieldsAndCustomersFromServer, [...action.payload.fieldGuids], false);
};

export const onChangeActiveTab = function* () {
    yield put(actions.restartCustomerFields());
};

export const saveNonFieldFeature = function* (action) {
    if (!(yield call(hasAccessToNonFieldFeatures))) {
        return;
    }

    yield onApiError.displayNotification(function* () {
        yield NonFieldFeatureAPI.saveFeature(action.payload.feature);
    }, action);
};

export const deleteNonFieldFeature = function* (action) {
    if (!(yield call(hasAccessToNonFieldFeatures))) {
        return;
    }

    yield onApiError.displayNotification(function* () {
        const { feature } = action.payload;
        yield NonFieldFeatureAPI.deleteFeature(feature.id);
        yield put(actions.modifyNonFieldFeatures({ remove: [feature.id] }));
    }, action);
};

export const fetchNonFieldFeaturesForIds = function* (action: IActionData) {
    yield fetchNonFieldFeatures(
        action,
        call(NonFieldFeatureAPI.fetchFeaturesForIds, action.payload.ids)
    );
};

export const fetchNonFieldFeaturesForUser = function* (action: IActionData) {
    yield fetchNonFieldFeatures(action, call(NonFieldFeatureAPI.fetchFeaturesForUser));
};

export const fetchNonFieldFeaturesForCustomer = function* (action: IActionData) {
    yield fetchNonFieldFeatures(
        action,
        call(NonFieldFeatureAPI.fetchFeaturesForCustomer, action.payload.customerId)
    );
};

export const changeNonFieldFeatureSelectionToSelectedFields = function* (): Generator<
    | SelectEffect
    | PutEffect<
          PayloadAction<
              "customer-data/SET_SELECTED_NON_FIELD_FEATURES",
              {
                  features: Immutable.Set<NonFieldFeatureAPI.NonFieldFeature>;
              }
          >
      >,
    void,
    any
> {
    const nonFieldFeatures: Immutable.Set<NonFieldFeature> = yield select(
        selectors.getNonFieldFeatures
    );
    const selectedCustomerGuids: Immutable.Set<string> = yield select(
        selectors.getSelectedCustomerGuids
    );
    const selectedNonFieldFeatures: ImmutableSet<[string, NonFieldFeature]> = selectedCustomerGuids
        .flatMap((customerGuid) =>
            nonFieldFeatures.filter((feature) => feature.customerId === customerGuid).toArray()
        )
        .map((feature) => [feature.id, feature]);
    const uniqueSelectedNonFieldFeatures = ImmutableMap(selectedNonFieldFeatures).toSet();
    yield put(actions.setSelectedNonFieldFeatures(uniqueSelectedNonFieldFeatures));
};

export const handleNonFieldFeaturesForAddedCustomer = function* (action: IActionData) {
    const { customerGuid } = action.payload.customer;
    yield put(actions.fetchNonFieldFeaturesForCustomer(customerGuid));
};

export const handleNonFieldFeaturesForActivatedCustomer = function* (action: IActionData) {
    const { customerGuid } = action.payload;
    yield put(actions.fetchNonFieldFeaturesForCustomer(customerGuid));
};

export const handleNonFieldFeaturesForRemovedCustomer = function* (action: IActionData) {
    const { customerGuid } = action.payload;
    const nonFieldFeatureIds = (yield select(selectors.getNonFieldFeatures))
        .filter((feature) => feature.customerId === customerGuid)
        .map((feature) => feature.id);
    yield put(actions.modifyNonFieldFeatures({ add: [], remove: nonFieldFeatureIds }));
};

export const customerDataSaga = function* () {
    yield all([
        messageSubscriptions(),
        takeLatest(loginActions.SET_USER_INFO_COMPLETE, init),
        takeLatest(actions.FETCH_CUSTOMER_FIELDS, fetchCustomerFields),
        takeEvery(actions.FETCH_FIELDS, fetchFields),
        takeLatest(actions.FETCH_SUMMARY, fetchSummary),
        takeLatest(actions.FETCH_FILTERED_FIELD_GUIDS, fetchFilteredFieldGuids),
        takeEvery(
            [
                actions.MODIFY_CUSTOMERS,
                actions.MODIFY_FIELDS,
                actions.ADD_SELECTED_FIELDS,
                actions.CLEAR_ALL_SELECTED_FIELDS,
                actions.CLEAR_SELECTED_FIELDS,
                actions.ADD_UPDATE_CUSTOMER,
                actions.ACTIVATE_CUSTOMER,
                actions.DELETE_CUSTOMER,
                actions.DEACTIVATE_CUSTOMER,
                actions.BATCH_UPDATE_FIELD,
                actions.MOVE_FIELDS,
                actions.REMOVE_CONNECTED_CUSTOMER,
                actions.UPDATE_FIELD_EVENT_COUNT,
                actions.UPDATE_FIELD_REC_COUNT,
            ],
            onSummaryChanged
        ),
        takeEvery(actions.ADD_UPDATE_FIELDS, onAddUpdateFields),
        takeEvery(actions.DELETE_CUSTOMER, onDeleteCustomer),
        takeEvery(actions.ACTIVATE_FIELDS, onActivateFields),
        takeEvery(actions.DEACTIVATE_FIELDS, onDeactivateFields),

        takeEvery(fieldModuleActions.CHANGE_ACTIVE_TAB, onChangeActiveTab),

        takeLatest(actions.SAVE_NON_FIELD_FEATURE, saveNonFieldFeature),
        takeLatest(actions.DELETE_NON_FIELD_FEATURE, deleteNonFieldFeature),
        takeLatest(actions.FETCH_NON_FIELD_FEATURES_FOR_IDS, fetchNonFieldFeaturesForIds),
        takeLatest(actions.FETCH_NON_FIELD_FEATURES_FOR_USER, fetchNonFieldFeaturesForUser),
        takeLatest(actions.FETCH_NON_FIELD_FEATURES_FOR_CUSTOMER, fetchNonFieldFeaturesForCustomer),

        takeEvery(
            actions.MODIFY_NON_FIELD_FEATURES,
            changeNonFieldFeatureSelectionToSelectedFields
        ),
        takeEvery(actions.ADD_SELECTED_FIELDS, changeNonFieldFeatureSelectionToSelectedFields),
        takeEvery(
            actions.CLEAR_ALL_SELECTED_FIELDS,
            changeNonFieldFeatureSelectionToSelectedFields
        ),
        takeEvery(actions.CLEAR_SELECTED_FIELDS, changeNonFieldFeatureSelectionToSelectedFields),
        takeEvery(actions.SET_SELECTED_FIELDS, changeNonFieldFeatureSelectionToSelectedFields),

        takeEvery(actions.ADD_UPDATE_CUSTOMER, handleNonFieldFeaturesForAddedCustomer),
        takeEvery(actions.ACTIVATE_CUSTOMER, handleNonFieldFeaturesForActivatedCustomer),
        takeEvery(actions.DELETE_CUSTOMER, handleNonFieldFeaturesForRemovedCustomer),
        takeEvery(actions.DEACTIVATE_CUSTOMER, handleNonFieldFeaturesForRemovedCustomer),
    ]);
};
