import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { DialogBox, Menu, NoLink, NumericInput, DialogBoxFooterType } from "~/core";

import { actions as recsEventsActions, recsSelectors } from "~/recs-events";

import { getSetValuesForErrorCodeList } from "../../../../../common/validation-utils";

import { ApplicationIcon } from "~/core/icons";
import { ProductBlendModal } from "~/action-panel/components/common/product-blend-modal";
import { setEventRecCustomProductGuids } from "~/action-panel/components/common/product-blend-modal/actions";
import * as blendingModels from "~/action-panel/components/common/product-blend-modal/model";
import * as blendingSelectors from "~/action-panel/components/common/product-blend-modal/selectors";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import * as actions from "../../actions";

import "../../../../../common/rec-event-info/rec-event-info.css";
import "./rec-manual-application-form.css";

const messages = defineMessages({
    addProductMixText: {
        id: "recModule.recInfo.addProductMixText",
        defaultMessage: "+ Add Product Mix",
    },
    applicationFormLabelText: {
        id: "recModule.recInfo.applicationFormLabelText",
        defaultMessage: "Manual",
    },
    editProductMixText: {
        id: "recModule.recInfo.editProductMixText",
        defaultMessage: "Edit Product Mix",
    },
    mixText: {
        id: "recModule.recInfo.mixText",
        defaultMessage: "Mix",
    },
    overwriteBlendRatesText: {
        id: "recModule.recInfo.overwriteBlendRatesText",
        defaultMessage:
            "Since this adjusted Blend is present on other Zones, this will reset all other Zones to the same adjusted Blend and rates as this Zone. Do you want to continue with these changes?",
    },
    removeProductMixText: {
        id: "recModule.recInfo.removeProductMixText",
        defaultMessage: "Remove Product Mix",
    },
    resetRatesText: {
        id: "recModule.recInfo.resetRatesText",
        defaultMessage: "Reset Other Zones",
    },
});

export const formLabelMessage = messages.applicationFormLabelText;
export const formLabelIcon = ApplicationIcon;

const errorCodeToMessageIdSetMap = new Map([[]]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class RecManualApplicationForm_ extends PureComponent {
    static propTypes = {
        availableProducts: PropTypes.array,
        conversionFactors: PropTypes.object,
        productBlendPicklists: PropTypes.object,
        classBreaksCount: PropTypes.number.isRequired,
        classBreaksHaveOnlyOneZone: PropTypes.bool.isRequired,
        recModel: PropTypes.object,
        fieldGuid: PropTypes.string,
        isBatchTemplate: PropTypes.bool,
        calculatedArea: PropTypes.number,
        onBatchUpdateProductBlend: PropTypes.func,
        onUpdateCurrentRecAreaRec: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        recAreaId: PropTypes.number,
        recDetails: PropTypes.object,
        saveRecDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        setCustomProductGuids: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [],
            isSharedBlend: false,
            confirmSharedBlendOverwriteModalOpen: false,
            isBlendCleared: false,
        };
    }

    _getErrorMessagePlaceholderSet(props) {
        const { saveRecDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            saveRecDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateRec(newProps) {
        this.props.onUpdateCurrentRecAreaRec(newProps);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.saveRecDetailsErrorCodeList !== this.props.saveRecDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(nextProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    _getMenuItems(index) {
        const { formatMessage } = this.props.intl;
        return [
            {
                label: formatMessage(messages.editProductMixText),
                action: () => this.onAddEditProductMix(index),
            },
            {
                label: formatMessage(messages.removeProductMixText),
                action: () => this.onRemoveProductMix(index),
            },
        ].map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    _updateTargetRate(index, newTargetRate) {
        const {
            recModel,
            calculatedArea,
            availableProducts,
            conversionFactors,
            productBlendPicklists,
        } = this.props;
        const { productMixList } = recModel;
        const newProductMixList = productMixList.map((mix, mixIndex) => {
            if (
                mixIndex === index &&
                blending.roundValue(newTargetRate, 2) !== blending.roundValue(mix.targetRate, 2)
            ) {
                const blendingProps = {
                    availableProducts,
                    conversionFactors,
                    productBlendPicklists,
                };
                const lineRatio = newTargetRate / mix.targetRate;
                const newCostPerAcre = mix.costPerAcre * lineRatio;
                const newTotalProduct = mix.totalProduct * lineRatio;
                const newTotalCost = mix.totalCost * lineRatio;
                return {
                    ...mix,
                    targetRate: newTargetRate,
                    costPerAcre: newCostPerAcre,
                    totalProduct: newTotalProduct,
                    totalCost: newTotalCost,
                    products: mix.products.map((product) => {
                        const fullProduct = availableProducts.find(
                            (ap) => ap.productGuid === product.productGuid
                        );
                        const isServiceProduct = fullProduct?.productParentType === "Service";

                        const rate = isServiceProduct
                            ? product.rate
                            : newTargetRate && product.rate === 0
                            ? blending.getProductRateInMix(
                                  product.density,
                                  {
                                      ...mix,
                                      targetRate: newTargetRate,
                                  },
                                  product,
                                  blending.getRateUnit(product.rateUnitGuid, blendingProps),
                                  blendingProps
                              )
                            : product.rate * lineRatio;
                        const lineRateToLineCostConversionFactor =
                            blending.getProductRateConversionFactor(
                                product.rateUnitGuid,
                                product.costUnitGuid,
                                product.density,
                                newTargetRate,
                                blendingProps
                            );
                        const totalProduct = isServiceProduct
                            ? product.totalProduct
                            : newTargetRate && product.rate === 0
                            ? rate * lineRateToLineCostConversionFactor * calculatedArea
                            : product.totalProduct * lineRatio;
                        const totalCost = totalProduct * product.cost;
                        const costPerAcre = totalCost / calculatedArea;
                        return {
                            ...product,
                            rate,
                            costPerAcre,
                            totalProduct,
                            totalCost,
                        };
                    }),
                };
            }
            return mix;
        });
        this._updateRec({ productMixList: newProductMixList });
    }

    onAddEditProductMix(index = -1) {
        const isAdd = index === -1;
        const { recModel, recDetails } = this.props;
        let mixIndex = index;
        const originalProductMixList = [...recModel.productMixList];

        if (isAdd) {
            const productMixList = [
                ...recModel.productMixList,
                new blendingModels.ProductMix(recModel.recGuid),
            ];
            this._updateRec({ productMixList });
            mixIndex = productMixList.length - 1;
        }
        const eventRecCustomProductGuids =
            recModel.productMixList[index]?.products.map((p) => p.customProductGuid) || [];
        this.props.setCustomProductGuids(eventRecCustomProductGuids);

        const recAnalyses =
            recDetails.recAreaList
                ?.flatMap((ra) =>
                    ra.recs?.flatMap((r) =>
                        r.productMixList?.flatMap((rpm) => rpm.guaranteedAnalysis)
                    )
                )
                .filter((ga) => ga) || [];

        const isSharedBlend =
            recAnalyses.filter((ga) => ga === recModel.productMixList[mixIndex]?.guaranteedAnalysis)
                .length > 1;

        this.setState({
            isProductBlendingActive: true,
            activeProductBlend: mixIndex,
            originalProductMixList,
            isSharedBlend,
        });
    }

    onUpdateProductBlending(productMix) {
        const { originalProductMixList, activeProductBlend } = this.state;

        const productMixList = [...this.props.recModel.productMixList];
        productMixList.splice(this.state.activeProductBlend, 1, productMix);

        if (
            originalProductMixList[activeProductBlend]?.products.length &&
            !productMix.products.length
        ) {
            this.setState({ isBlendCleared: true });
        }

        this._updateRec({ productMixList });
    }

    onSaveProductBlending() {
        const { recDetails, recModel } = this.props;
        const { isSharedBlend, isBlendCleared, activeProductBlend, originalProductMixList } =
            this.state;

        const recNames =
            recDetails.recAreaList?.flatMap((ra) =>
                ra.recs?.flatMap((r) => r.productMixList?.flatMap((rpm) => rpm.name))
            ) || [];

        const isSharedNameBlend =
            recNames.filter(
                (rn) =>
                    rn === recModel.productMixList[activeProductBlend].name &&
                    rn !== recModel.productMixList[activeProductBlend].guaranteedAnalysis
            ).length > 1;

        if (
            ((isSharedBlend && !isBlendCleared) || isSharedNameBlend) &&
            recModel.productMixList[activeProductBlend].guaranteedAnalysis !==
                originalProductMixList[activeProductBlend]?.guaranteedAnalysis &&
            originalProductMixList[activeProductBlend]?.guaranteedAnalysis
        ) {
            this.setState({ confirmSharedBlendOverwriteModalOpen: true });
        } else {
            this._onConfirmOverwrite();
        }
    }

    onCancelProductBlending() {
        this._updateRec({
            productMixList: [...this.state.originalProductMixList],
        });
        this.onCloseProductBlending();
    }

    onCloseProductBlending() {
        this.setState({
            isProductBlendingActive: false,
            activeProductBlend: -1,
            originalProductMixList: [],
            confirmSharedBlendOverwriteModalOpen: false,
            isBlendCleared: false,
        });
    }

    _onConfirmOverwrite() {
        // Put an action to coerce any other mixes in the current rec/batch
        this.props.onBatchUpdateProductBlend(
            this.props.recModel.productMixList[this.state.activeProductBlend],
            this.state.originalProductMixList?.length && !this.state.isBlendCleared
                ? this.state.originalProductMixList[this.state.activeProductBlend]
                      ?.guaranteedAnalysis
                : null
        );

        this.onCloseProductBlending();
    }

    _onCancelOverwrite() {
        this.setState({ confirmSharedBlendOverwriteModalOpen: false });
    }

    onRemoveProductMix(index) {
        const productMixList = [...this.props.recModel.productMixList];
        productMixList.splice(index, 1);

        this._updateRec({ productMixList });
    }

    render() {
        const {
            classBreaksCount,
            classBreaksHaveOnlyOneZone,
            recModel,
            /** @type IRec */ calculatedArea,
            isBatchTemplate,
        } = this.props;
        const { formatMessage } = this.props.intl;

        // TODO: use portal to render into RecEventZoneInfo after React 16 upgrade
        //       https://codesandbox.io/s/82kmnvk0vj
        const addMixStyle = {};
        if (recModel.productMixList.length === 0 && !isBatchTemplate) {
            addMixStyle.paddingTop = 0;
            addMixStyle.position = "relative";
            addMixStyle.top =
                -53 -
                (classBreaksCount === 0
                    ? 0
                    : classBreaksCount * 28 + (classBreaksHaveOnlyOneZone ? 2 : 15));
        }
        return (
            <div className="rec-manual-application-form">
                <div className="product-blend-list">
                    {recModel.productMixList.map((mix, index) => {
                        const mixNumber = index + 1;
                        const mixName =
                            mix.products.length <= 1 &&
                            !(mix.products.length === 1 && mix.products[0].customProductGuid)
                                ? ""
                                : mix.name || "";
                        const mixTitle = `${formatMessage(
                            messages.mixText
                        )} ${mixNumber}:  ${mixName}`;
                        const isMixLocked =
                            mix.isLockGuaranteedAnalysis ||
                            mix.isLockProductRatios ||
                            !this.state.isProductBlendingActive;
                        return (
                            <div key={`mix-${index}`} className="product-blend-row input-row">
                                <div className="mix-information">
                                    <div className="mix-title" title={mixTitle}>
                                        {mixTitle}
                                    </div>
                                    <div className="mix-product-list">
                                        {mix.products
                                            .filter((product) => !product.customProductGuid)
                                            .map((product, pIndex) => (
                                                <div
                                                    key={`product-${index}-${pIndex}`}
                                                    className="product-row"
                                                >
                                                    {product.productName}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="rate-information">
                                    <div
                                        className={classNames("target-rate", {
                                            "is-locked": isMixLocked,
                                        })}
                                    >
                                        {isMixLocked ? (
                                            <NumericInput
                                                scale={2}
                                                precision={11}
                                                onChange={(strValue, fmtValue, numValue) =>
                                                    this._updateTargetRate(index, numValue)
                                                }
                                                value={mix.targetRate}
                                                showTopLabel={false}
                                            />
                                        ) : (
                                            <div>{blending.roundValue(mix.targetRate, 2)}</div>
                                        )}
                                    </div>
                                    <div className="target-rate-unit">{mix.targetRateUnit}</div>
                                </div>
                                <div className="mix-context-menu">
                                    <Menu
                                        className="context-menu"
                                        isDotMenu={true}
                                        getMenuItems={() => this._getMenuItems(index)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="add-mix" style={addMixStyle}>
                    <NoLink
                        label={formatMessage(messages.addProductMixText)}
                        onClick={() => this.onAddEditProductMix()}
                    />
                </div>
                {!this.state.isProductBlendingActive ? null : (
                    <ProductBlendModal
                        areaId={this.props.recAreaId}
                        productMix={recModel.productMixList[this.state.activeProductBlend]}
                        calculatedArea={isBatchTemplate ? 1 : calculatedArea}
                        isOpen={this.state.isProductBlendingActive}
                        activeProductMixIdx={this.state.activeProductBlend}
                        onUpdate={(productMix) => this.onUpdateProductBlending(productMix)}
                        onSave={() => this.onSaveProductBlending()}
                        onCancel={() => this.onCancelProductBlending()}
                    />
                )}
                {!this.state.confirmSharedBlendOverwriteModalOpen ? null : (
                    <DialogBox
                        isOpen={this.state.confirmSharedBlendOverwriteModalOpen}
                        isModal={true}
                        title={formatMessage(messages.resetRatesText)}
                        draggable={true}
                        hideCloseX={true}
                        onAction={() => this._onConfirmOverwrite()}
                        onClose={() => this._onCancelOverwrite()}
                        footerType={DialogBoxFooterType.YES_NO}
                    >
                        {formatMessage(messages.overwriteBlendRatesText)}
                    </DialogBox>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { fieldGuidToRecDetails, saveRecDetailsErrorCodeList } =
        recsSelectors.getModuleState(state);
    const availableProducts = blendingSelectors.getAvailableProducts(state);
    const conversionFactors = blendingSelectors.getConversionFactors(state);
    const productBlendPicklists = blendingSelectors.getProductBlendPicklists(state);

    const recDetails = fieldGuidToRecDetails.get(ownProps.fieldGuid);

    const classBreakIdSet = new Set(
        recDetails.recAreaList
            .filter((area) => area.recAreaClassBreak != null)
            .map((area) => area.recAreaClassBreak.classId)
    );
    const classBreaksCount = classBreakIdSet.size;

    const classBreaksHaveOnlyOneZone = classBreaksCount === recDetails.recAreaList.length;

    return {
        availableProducts,
        conversionFactors,
        productBlendPicklists,
        classBreaksCount,
        classBreaksHaveOnlyOneZone,
        recDetails,
        saveRecDetailsErrorCodeList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onBatchUpdateProductBlend: (productMix, previousMixGA) =>
        dispatch(actions.batchUpdateProductBlend(productMix, previousMixGA)),
    onUpdateCurrentRecAreaRec: (fieldGuid, newProps) =>
        dispatch(recsEventsActions.updateCurrentRecAreaRec(fieldGuid, newProps)),
    setCustomProductGuids: (guids) => dispatch(setEventRecCustomProductGuids(guids)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onUpdateCurrentRecAreaRec: (newProps) =>
        dispatchProps.onUpdateCurrentRecAreaRec(ownProps.fieldGuid, newProps),
});

export const RecManualApplicationForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(RecManualApplicationForm_));
