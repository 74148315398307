import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import moment from "moment";

import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { LayerUtilsAPI } from "@ai360/core";

import { createSurfaceAccordionItems } from "./surface-item";
import * as selectors from "../selectors";
import { LayerTypeShape, getLayerTypeLetter } from "../../../utils";

import {
    ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
    ANALYSIS_INFO_NAME_PROFIT_LOSS,
} from "~/recs-events/analysis/model";
import "./layer-item.css";

export const LAYER_HEIGHT = 25;
export const COMPLETED = "Completed";

export const createLayerAccordionItems = (layers) => {
    return layers.map((layer, index) => {
        const type = LayerUtilsAPI.getLayerType(layer);
        const payload = {
            agEventGeneralGuid: layer.agEventGeneralGuid,
            cropGuid: layer.cropGuid,
            croppingSeasonGuid: layer.croppingSeasonGuid,
            index,
            layerType: layer.layerType,
            type,
        };
        const layerItem = new accordionModel.AccordionItem(LAYER_HEIGHT, false, payload);
        const attributeGuidField = LayerUtilsAPI.getAttributeGuidFieldName(type);

        switch (type) {
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
                if (layer.isSampling) {
                    if (layer.subLayers) {
                        return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                            children: createSurfaceAccordionItems(
                                [
                                    {
                                        classBreaks: [{}],
                                        surfaceGuid: LayerUtilsAPI.SAMPLE_SITES_GUID,
                                    },
                                    ...layer.subLayers.filter((s) => {
                                        return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                                    }),
                                ],
                                type
                            ),
                        });
                    } else {
                        return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                            children: createSurfaceAccordionItems(
                                [
                                    {
                                        classBreaks: [{}],
                                        surfaceGuid: LayerUtilsAPI.SAMPLE_SITES_GUID,
                                    },
                                ],
                                type
                            ),
                        });
                    }
                } else {
                    return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                        children: createSurfaceAccordionItems(
                            layer.subLayers.filter((s) => {
                                return s.surfaceTypeGuid === s.selectedSurfaceTypeGuid;
                            }),
                            type
                        ),
                    });
                }
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return (
                                s.surfaceGuid === layer.selectedSurfaceGuid &&
                                s.surfaceTypeGuid === s.selectedSurfaceTypeGuid
                            );
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.ANALYSIS:
            case LayerUtilsAPI.LayerType.REC:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === s.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.SOIL:
                if (layer.subLayers) {
                    return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                        children: createSurfaceAccordionItems(
                            layer.subLayers.filter((s) => {
                                return s[attributeGuidField] === layer.selectedAttributeGuid;
                            }),
                            type
                        ),
                    });
                }
                return layerItem;
            case LayerUtilsAPI.LayerType.IMAGERY:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(layer.subLayers, type),
                });
            default:
                return layerItem;
        }
    });
};

class LayerItem_ extends Component {
    static propTypes = {
        accordionId: PropTypes.number.isRequired,
        collapseItem: PropTypes.func.isRequired,
        displayNamePrefix: PropTypes.string,
        expandItem: PropTypes.func.isRequired,
        fieldGuid: PropTypes.string.isRequired,
        hasVisibleSurface: PropTypes.bool.isRequired,
        index: PropTypes.number.isRequired,
        intl: intlShape.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        itemDimIdx: PropTypes.arrayOf(PropTypes.number),
        layer: PropTypes.object.isRequired,
        status: PropTypes.string,
        type: LayerTypeShape.isRequired,
    };

    _onClick(event) {
        if (event.isDefaultPrevented()) {
            return;
        }

        const { isExpanded, expandItem, collapseItem, accordionId, itemDimIdx } = this.props;
        if (isExpanded) {
            collapseItem(accordionId, itemDimIdx);
        } else {
            expandItem(accordionId, itemDimIdx);
        }
    }

    getDisplayName() {
        const { displayNamePrefix, layer } = this.props;
        let { displayName } = layer;
        if (displayNamePrefix) {
            if (
                layer.layerType === ANALYSIS_INFO_NAME_PROFIT_LOSS ||
                (layer.layerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA &&
                    layer.subLayers[0].croppingSeason !== "")
            ) {
                displayName = `${layer.layerType} - ${layer.subLayers[0].croppingSeason} - ${displayName}`;
            } else if (layer.layerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA) {
                displayName = `${layer.layerType} - ${displayName}`;
            } else {
                displayName = `${displayNamePrefix} - ${displayName}`;
            }
        }
        return displayName;
    }

    getTooltipText() {
        const layer = this.props.layer;
        const formatedDate = moment(layer.layerDate).format("M/D/YY - h:mm a");

        const user = layer.modifiedBy?.trim() !== "" ? layer.modifiedBy + " - " : "";
        const date = formatedDate ? formatedDate + " " : "";

        const toolTipText = `${user}${date}`;
        return toolTipText;
    }

    render() {
        const { hasVisibleSurface, layer, status, type } = this.props;
        const { formatMessage } = this.props.intl;

        if (!layer) {
            return null;
        }

        const displayName = this.getDisplayName();
        const toolTip = this.getTooltipText();
        const statusClass = status === COMPLETED ? "layer-status-completed" : "layer-status";

        return (
            <div className="layer-accordion-item" onClick={(event) => this._onClick(event)}>
                <div
                    className={classnames("letter-icon", {
                        "has-visible": hasVisibleSurface,
                    })}
                >
                    {getLayerTypeLetter(type, formatMessage)}
                </div>
                <div className="layer-label" title={displayName}>
                    {displayName}
                </div>
                {!status ? null : (
                    <div title={toolTip} className={statusClass}>
                        {status}
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    expandItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx)),
    collapseItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx)),
});

const mapStateToProps = (state, ownProps) => {
    const layerInfos = selectors.getLayerInfos(state).get(ownProps.fieldGuid);
    const layer = layerInfos ? layerInfos[ownProps.index] : null;
    //For some reason, calling remove layer infos doesn't stop this from loading - may need to track this down
    if (!layerInfos || !layer) {
        return {
            displayNamePrefix: "",
            layer: null,
            status: "",
        };
    }
    let { displayNamePrefix, status } = layer;
    if (!status) {
        status = null;
    }
    if (ownProps.type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
        displayNamePrefix = layer.layerType;
        status = layer.analysisLayerStatus;
    } else if (ownProps.type === LayerUtilsAPI.LayerType.ANALYSIS && layer.subLayers.length > 0) {
        displayNamePrefix = LayerUtilsAPI.getSurfaceInfo(layer).displayName;
        status = layer.analysisLayerStatus;
    }
    return {
        displayNamePrefix,
        layer,
        status,
    };
};

export const LayerItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LayerItem_));
