import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { defineMessages, injectIntl, intlShape } from "react-intl";

import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";

import { config as intlConfig } from "~/intl-provider/config";
import { eventsSelectors, recsSelectors } from "~/recs-events";
import { getModuleState as getEventInfoModuleState } from "~/action-panel/components/event-module/components/event-info/selectors";
import { getModuleState as getRecInfoModuleState } from "~/action-panel/components/rec-module/components/rec-info/selectors";

import * as selectors from "../product-blend-modal/selectors";

import "./product-summary.css";

const messages = defineMessages({
    fieldTotalsText: {
        id: "common.productSummary.fieldTotalsText",
        defaultMessage: "Field Totals:  {acres} {acres, plural, one {Acre} other {Acres}}",
    },
    productHeaderText: {
        id: "common.productSummary.productHeaderText",
        defaultMessage: "Product",
    },
    totalProductHeaderText: {
        id: "common.productSummary.totalProductHeaderText",
        defaultMessage: "Total Product",
    },
    totalCostHeaderText: {
        id: "common.productSummary.totalCostHeaderText",
        defaultMessage: "Total Cost ($)",
    },
    totalCostText: {
        id: "common.productSummary.totalCostText",
        defaultMessage: "Total Cost",
    },
    costPerAcreText: {
        id: "common.productSummary.costPerAcreText",
        defaultMessage: "Cost/Acre",
    },
});

export interface IProductSummary_Props {
    availableProducts?: Record<string, any>;
    isBatchRecEdit?: boolean;
    isEvent: boolean;
    isEquation: boolean;
    eventRecDetails: Record<string, any>;
    fieldAcres?: number;
    conversionFactors?: Record<string, any>;
    productBlendPicklists?: Record<string, any>;
    intl: intlShape;
}

export class ProductSummary_ extends PureComponent<IProductSummary_Props> {
    render(): JSX.Element {
        const {
            availableProducts,
            fieldAcres,
            isEvent,
            isEquation,
            eventRecDetails,
            conversionFactors,
            productBlendPicklists,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const blendingProps = {
            availableProducts,
            conversionFactors,
            productBlendPicklists,
        };
        const areaListProp = isEvent ? "eventAreaList" : "recAreaList";
        const eventsRecsProp = isEvent ? "agEventList" : "recs";
        const isEventOrManualRec = isEvent || !isEquation;
        const productMixListProp = isEventOrManualRec ? "productMixList" : "recNutrientList";

        const productSummaryMap = new Map();

        if (!eventRecDetails) {
            return null;
        }
        eventRecDetails[areaListProp].reduceRight((arr, eventRecArea) => {
            const areaAcres = eventRecArea.calculatedArea;
            eventRecArea[eventsRecsProp].forEach((eventRec) => {
                const productMixList = isEvent
                    ? eventRec.agEventModel[productMixListProp]
                    : eventRec[productMixListProp];

                const isFilterChanged = eventRec.isFilterChanged;

                if (!productMixList) {
                    return;
                }
                productMixList.forEach((item) => {
                    const appliedArea =
                        isEvent && eventRec.agEventModel.coverageArea
                            ? eventRec.agEventModel.coverageArea
                            : areaAcres;

                    const applicableAppliedArea = isEvent || eventRec.isIncluded ? appliedArea : 0;

                    const mix = isEventOrManualRec ? item : item.recNutrientProductMix;

                    if (!mix) {
                        return;
                    }
                    const productList = mix.products;
                    if (productList.length === 1) {
                        const product = productList[0];

                        const rateUnitGuid =
                            product.rateUnitGuid || item.unitGuid || mix.actualRateUnitGuid;
                        const rateUnit = blending.getRateUnit(rateUnitGuid, blendingProps);

                        if (!rateUnit) {
                            return;
                        }
                        const rateUnitQuantityLabel = blending.getRateQuantityUnitLabel(rateUnit);

                        const fromUnitPhysicalState = blending.getRateUnitPhysicalState(
                            rateUnit.value,
                            blendingProps
                        );
                        const matchingCostUnit = blending.getPriceUnitByName(
                            rateUnitQuantityLabel,
                            blendingProps,
                            fromUnitPhysicalState
                        );

                        const productGuid = product.productGuid || product.customProductGuid;
                        const existingSummaryInfo = productSummaryMap.get(productGuid) || {
                            totalProduct: 0,
                            totalCost: 0,
                            productPricingUnit: "",
                        };

                        const existingPricingUnit = existingSummaryInfo.productPricingUnit
                            ? blending.getPriceUnitByName(
                                  existingSummaryInfo.productPricingUnit,
                                  blendingProps,
                                  fromUnitPhysicalState
                              )
                            : null;
                        const productCostUnitGuid = !product.costUnitGuid
                            ? matchingCostUnit.value
                            : product.costUnitGuid;
                        const effectiveCostUnitGuid = existingPricingUnit
                            ? existingPricingUnit.value
                            : productCostUnitGuid;

                        const correctCostUnit = blending.getPriceUnit(
                            effectiveCostUnitGuid,
                            blendingProps
                        );
                        const productCostUnit = blending.getPriceUnit(
                            productCostUnitGuid,
                            blendingProps
                        );

                        if (!matchingCostUnit || !correctCostUnit || !productCostUnit) {
                            return;
                        }

                        const productRateFixed = mix.actualRate || mix.targetRate;
                        const conversionFactor = blending.getProductRateConversionFactor(
                            rateUnitGuid,
                            correctCostUnit.value,
                            mix.density,
                            productRateFixed,
                            blendingProps
                        );
                        const costConversionFactor = blending.getProductRateConversionFactor(
                            rateUnitGuid,
                            productCostUnit.value,
                            mix.density,
                            productRateFixed,
                            blendingProps
                        );

                        const summaryRate = productRateFixed * conversionFactor;
                        const productRate = productRateFixed * costConversionFactor;

                        productSummaryMap.set(productGuid, {
                            productName: product.productName || mix.name,
                            totalProduct:
                                existingSummaryInfo.totalProduct +
                                Number(summaryRate || 0) * appliedArea,
                            productPricingUnit: correctCostUnit.label,
                            totalCost:
                                existingSummaryInfo.totalCost +
                                Number(product.cost || 0) * Number(productRate || 0) * appliedArea,
                            isServiceProduct: false,
                        });
                    } else if (productList.length > 1) {
                        const rateUnitGuid = mix.targetRateUnitGuid;
                        const rateUnit = blending.getRateUnit(rateUnitGuid, blendingProps);
                        if (!rateUnit) {
                            return;
                        }
                        const rateUnitQuantityLabel = blending.getRateQuantityUnitLabel(rateUnit);

                        const fromUnitPhysicalState = blending.getRateUnitPhysicalState(
                            rateUnit.value,
                            blendingProps
                        );
                        const matchingCostUnit = blending.getPriceUnitByName(
                            rateUnitQuantityLabel,
                            blendingProps,
                            fromUnitPhysicalState
                        );

                        const existingSummaryInfo = productSummaryMap.get(mix.name) || {
                            totalProduct: 0,
                            totalCost: 0,
                            productPricingUnit: "",
                        };

                        const existingPricingUnit = existingSummaryInfo.productPricingUnit
                            ? blending.getPriceUnitByName(
                                  existingSummaryInfo.productPricingUnit,
                                  blendingProps,
                                  fromUnitPhysicalState
                              )
                            : null;
                        const effectiveCostUnitGuid = existingPricingUnit
                            ? existingPricingUnit.value
                            : blending.getEffectiveCostUnitGuid(mix, mix.products) ||
                              matchingCostUnit.value;
                        const correctCostUnit = blending.getPriceUnit(
                            effectiveCostUnitGuid,
                            blendingProps
                        );

                        if (!matchingCostUnit || !correctCostUnit) {
                            return;
                        }

                        const mixConversionFactor = blending.getProductRateConversionFactor(
                            rateUnitGuid,
                            correctCostUnit.value,
                            mix.density,
                            mix.actualRate || mix.targetRate,
                            blendingProps
                        );

                        const rate = (mix.actualRate || mix.targetRate) * mixConversionFactor;
                        let calculatedCost = 0;
                        for (const product of productList) {
                            const productCostUnit = blending.getPriceUnit(
                                product.costUnitGuid || effectiveCostUnitGuid,
                                blendingProps
                            );
                            const fullProductInfo = availableProducts.find(
                                (p) => p.productGuid === product.productGuid
                            );

                            const isServiceProduct =
                                fullProductInfo?.productParentType === "Service";

                            const productRateUnit = isServiceProduct
                                ? null
                                : blending.getRateUnit(
                                      product.rateUnitGuid || rateUnitGuid,
                                      blendingProps
                                  );
                            let density = product.density;
                            if (!product.density && !isServiceProduct) {
                                const calculatedDensity = blending.getProductDensityValues(
                                    fullProductInfo,
                                    product,
                                    rateUnitGuid,
                                    product.rateUnitGuid,
                                    blendingProps
                                ).density;
                                density = calculatedDensity > 0 ? calculatedDensity : 0;
                            }

                            const productRateCostConversionFactor = isServiceProduct
                                ? 0
                                : blending.getProductRateConversionFactor(
                                      product.rateUnitGuid,
                                      productCostUnit.value,
                                      density,
                                      mix.actualRate || mix.targetRate,
                                      blendingProps
                                  );
                            const productRate = isServiceProduct
                                ? 0
                                : blending.getProductRateInMix(
                                      density,
                                      mix,
                                      product,
                                      productRateUnit,
                                      blendingProps
                                  );
                            calculatedCost +=
                                (product.cost || 0) *
                                (productRate || 0) *
                                productRateCostConversionFactor;

                            if (isServiceProduct) {
                                const existingServiceSummaryProduct = productSummaryMap.get(
                                    product.productName
                                ) || {
                                    totalProduct: 0,
                                    totalCost: 0,
                                    productPricingUnit: "",
                                };

                                const serviceProductCostUnit = blending.getPriceUnit(
                                    product.costUnitGuid,
                                    blendingProps
                                );

                                productSummaryMap.set(product.productName, {
                                    productName: product.productName,
                                    totalProduct: isFilterChanged
                                        ? 0
                                        : existingServiceSummaryProduct.totalProduct +
                                          (product.rate || 0),
                                    productPricingUnit: serviceProductCostUnit.label,
                                    totalCost: isFilterChanged
                                        ? 0
                                        : productSummaryMap.has(product.productName)
                                        ? productSummaryMap.get(product.productName).totalCost +
                                          product.cost * (product.rate || 0)
                                        : product.cost * (product.rate || 0),
                                    isServiceProduct: true,
                                });
                            }
                        }
                        const cost = calculatedCost * applicableAppliedArea;
                        productSummaryMap.set(mix.name, {
                            productName: mix.name || mix.guaranteedAnalysis,
                            totalProduct: isFilterChanged
                                ? 0
                                : existingSummaryInfo.totalProduct +
                                  Number(rate || 0) * applicableAppliedArea,
                            productPricingUnit: correctCostUnit.label,
                            totalCost: isFilterChanged
                                ? 0
                                : productSummaryMap.has(mix.name)
                                ? productSummaryMap.get(mix.name).totalCost + cost
                                : cost,
                            isServiceProduct: false,
                        });
                    }
                });
            });
            return arr;
        }, []);
        const totalCost = Array.from(productSummaryMap.values()).reduce((acc, product) => {
            return acc + product.totalCost;
        }, 0);
        const costPerAcre = totalCost / fieldAcres;
        return productSummaryMap.size === 0 ? null : (
            <div className="rec-event-info-summary">
                <div className="field-totals">
                    {formatMessage(messages.fieldTotalsText, {
                        acres: formatNumber(fieldAcres, intlConfig.numberFormatOptions),
                    })}
                </div>
                <div className="summary-table">
                    <div className="summary-table-header">
                        <div className="summary-column summary-column-wide">
                            {formatMessage(messages.productHeaderText)}
                        </div>
                        <div className="summary-column">
                            {formatMessage(messages.totalProductHeaderText)}
                        </div>
                        <div className="summary-column">
                            {formatMessage(messages.totalCostHeaderText)}
                        </div>
                    </div>
                    <div className="summary-table-body">
                        {Array.from(productSummaryMap.values())
                            .filter((p) => !p.isServiceProduct)
                            .map((product, index) => {
                                return (
                                    <div key={`product-summary-${index}`} className="summary-row">
                                        <div
                                            className="summary-column summary-column-wide"
                                            title={product.productName}
                                        >
                                            {product.productName}
                                        </div>
                                        <div className="summary-column">
                                            {formatNumber(
                                                product.totalProduct,
                                                intlConfig.numberFormatOptions
                                            )}{" "}
                                            {product.productPricingUnit}
                                        </div>
                                        <div className="summary-column">
                                            $
                                            {formatNumber(
                                                product.totalCost,
                                                intlConfig.numberFormatOptions
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        {Array.from(productSummaryMap.values())
                            .filter((p) => p.isServiceProduct)
                            .map((product, index) => {
                                return (
                                    <div key={`product-summary-${index}`} className="summary-row">
                                        <div
                                            className="summary-column summary-column-wide"
                                            title={product.productName}
                                        >
                                            {product.productName}
                                        </div>
                                        <div className="summary-column">
                                            {formatNumber(
                                                product.totalProduct,
                                                intlConfig.numberFormatOptions
                                            )}{" "}
                                            {product.productPricingUnit}
                                        </div>
                                        <div className="summary-column">
                                            $
                                            {formatNumber(
                                                product.totalCost,
                                                intlConfig.numberFormatOptions
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="summary-table-totals">
                        <div className="total-row">
                            <div className="total-row-label">
                                {formatMessage(messages.totalCostText)}:
                            </div>
                            <div className="total-row-value">
                                ${formatNumber(totalCost, intlConfig.numberFormatOptions)}
                            </div>
                        </div>
                        <div className="total-row">
                            <div className="total-row-label">
                                {formatMessage(messages.costPerAcreText)}:
                            </div>
                            <div className="total-row-value">
                                ${formatNumber(costPerAcre, intlConfig.numberFormatOptions)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { isEvent } = ownProps;
    const availableProducts = selectors.getAvailableProducts(state);
    const conversionFactors = selectors.getConversionFactors(state);
    const productBlendPicklists = selectors.getProductBlendPicklists(state);
    const { eventSummary } = getEventInfoModuleState(state);
    const { recSummary, batchRecSummariesForEdit } = getRecInfoModuleState(state);
    const eventRecSummary = isEvent ? eventSummary : recSummary;
    const fieldGuid = eventRecSummary != null ? eventRecSummary.fieldGuid : null;

    const { fieldGuidToEventDetails } = eventsSelectors.getModuleState(state);
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);
    const fieldGuidToEventRecDetails = isEvent ? fieldGuidToEventDetails : fieldGuidToRecDetails;

    const isBatchRecEdit = batchRecSummariesForEdit && batchRecSummariesForEdit.length > 0;
    const eventRecDetails = fieldGuidToEventRecDetails.get(fieldGuid);
    let fieldAcres = 0;
    if (isBatchRecEdit) {
        const includedFields = [];
        for (const rec of batchRecSummariesForEdit) {
            if (includedFields.includes(rec.fieldGuid)) {
                continue;
            }
            includedFields.push(rec.fieldGuid);
            fieldAcres += rec.fieldAcres;
        }
    } else {
        fieldAcres = eventRecSummary != null ? eventRecSummary.fieldAcres : null;
    }

    return {
        availableProducts,
        eventRecDetails,
        fieldAcres,
        isBatchRecEdit,
        conversionFactors,
        productBlendPicklists,
    };
};

export const ProductSummary = connect(mapStateToProps)(injectIntl(ProductSummary_));
