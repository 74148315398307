import { combineReducers } from "redux";

import { ACTION_PANEL_STATE_KEY, actionPanelReducer } from "~/action-panel";
import { ADMIN_STATE_KEY, adminReducer } from "~/admin";
import { ANALYTICS_STATE_KEY, analyticsReducer } from "~/analytics";
import { AUTHENTICATION_STATE_KEY, authenticationReducer } from "~/authentication";
import { CORE_STATE_KEY, coreReducer } from "~/core";
import { CUSTOMER_DATA_KEY, customerDataReducer } from "~/customer-data";
import { FILE_IMPORT_DATA_KEY, fileImportReducer } from "~/file-import";
import { HOCS_STATE_KEY, hocsReducer } from "~/hocs";
import { LOCALE_STATE_KEY, localeReducer } from "~/intl-provider";
import { LOGIN_STATE_KEY, loginReducer, actions as loginActions } from "~/login";
import { MAP_STATE_KEY, mapReducer } from "~/map";
import { NAV_STATE_KEY, navigationReducer } from "~/navigation";
import { NOTIFICATION_STATE_KEY, notificationReducer } from "~/notifications";
import { RECS_EVENTS_DATA_KEY, recsEventsReducer } from "~/recs-events";
import { REPORT_BOOKS_DATA_KEY, reportBookReducer } from "~/report-books";
import { REPORTS_STATE_KEY, reportsReducer } from "~/reports";
import { SLIDING_PANEL_STATE_KEY, slidingPanelReducer } from "~/sliding-panel";
import { THEME_STATE_KEY, themeReducer } from "~/theme";
import { MESSAGING_STATE_KEY, messagingReducer } from "~/messaging";
import { WINDOW_STATE_KEY, windowReducer } from "~/window";

export const allReducers = combineReducers({
    [ACTION_PANEL_STATE_KEY]: actionPanelReducer,
    [ADMIN_STATE_KEY]: adminReducer,
    [ANALYTICS_STATE_KEY]: analyticsReducer,
    [AUTHENTICATION_STATE_KEY]: authenticationReducer,
    [CORE_STATE_KEY]: coreReducer,
    [CUSTOMER_DATA_KEY]: customerDataReducer,
    [FILE_IMPORT_DATA_KEY]: fileImportReducer,
    [HOCS_STATE_KEY]: hocsReducer,
    [LOCALE_STATE_KEY]: localeReducer,
    [LOGIN_STATE_KEY]: loginReducer,
    [MAP_STATE_KEY]: mapReducer,
    [NAV_STATE_KEY]: navigationReducer,
    [NOTIFICATION_STATE_KEY]: notificationReducer,
    [RECS_EVENTS_DATA_KEY]: recsEventsReducer,
    [REPORT_BOOKS_DATA_KEY]: reportBookReducer,
    [REPORTS_STATE_KEY]: reportsReducer,
    [SLIDING_PANEL_STATE_KEY]: slidingPanelReducer,
    [THEME_STATE_KEY]: themeReducer,
    [MESSAGING_STATE_KEY]: messagingReducer,
    [WINDOW_STATE_KEY]: windowReducer,
});

export const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === loginActions.RESET_APP) {
        newState = {
            [LOGIN_STATE_KEY]: state[LOGIN_STATE_KEY],
            [MESSAGING_STATE_KEY]: state[MESSAGING_STATE_KEY],
        };
    }

    return allReducers(newState, action);
};
