import { defineMessages } from "react-intl";

export const Messages = defineMessages({
    // TODO: Change preferences to correct childNameCode.
    1: {
        id: "namecode.preferences",
        defaultMessage: "Preferences",
    },
    2: {
        id: "namecode.equipment",
        defaultMessage: "Equipment",
    },
    3: {
        id: "namecode.account",
        defaultMessage: "Account",
    },
    4: {
        id: "namecode.about",
        defaultMessage: "About",
    },
    100: {
        id: "namecode.topLevel",
        defaultMessage: "Top Level",
    },
    101: {
        id: "namecode.setup",
        defaultMessage: "Setup",
    },
    102: {
        id: "namecode.agBytes",
        defaultMessage: "AgBytes",
    },
    103: {
        id: "namecode.events",
        defaultMessage: "Events",
    },
    104: {
        id: "namecode.recommendations",
        defaultMessage: "Recommendations",
    },
    105: {
        id: "namecode.reports",
        defaultMessage: "Reports",
    },
    107: {
        id: "namecode.templateManager",
        defaultMessage: "Template Manager",
    },
    110: {
        id: "namecode.sampling",
        defaultMessage: "Sampling",
    },
    111: {
        id: "namecode.scouting",
        defaultMessage: "Scouting",
    },
    112: {
        id: "namecode.workOrder",
        defaultMessage: "Work Orders",
    },
    113: {
        id: "namecode.financials",
        defaultMessage: "Financials",
    },
    114: {
        id: "namecode.import",
        defaultMessage: "Import",
    },
    116: {
        id: "namecode.manageFields",
        defaultMessage: "Add/Edit Fields",
    },
    117: {
        id: "namecode.location",
        defaultMessage: "Location",
    },
    118: {
        id: "namecode.person",
        defaultMessage: "Person",
    },
    119: {
        id: "namecode.owner",
        defaultMessage: "Owner",
    },
    120: {
        id: "namecode.user",
        defaultMessage: "User",
    },
    121: {
        id: "namecode.userRole",
        defaultMessage: "User Role",
    },
    122: {
        id: "namecode.vendor",
        defaultMessage: "Vendor",
    },
    123: {
        id: "namecode.equationSetsApplication",
        defaultMessage: "Equation Sets - Application",
    },
    "123-child": {
        id: "namecode.equation-child",
        defaultMessage: "Equations",
    },
    124: {
        id: "namecode.nematode",
        defaultMessage: "Nematode",
    },
    125: {
        id: "namecode.product",
        defaultMessage: "Products",
    },
    "125-child": {
        id: "namecode.productPackage",
        defaultMessage: "Product Package",
    },
    126: {
        id: "namecode.crop",
        defaultMessage: "Crops",
    },
    127: {
        id: "namecode.observation",
        defaultMessage: "Observations",
    },
    128: {
        id: "namecode.agBytesEquipment",
        defaultMessage: "Equipment",
    },
    129: {
        id: "namecode.unit",
        defaultMessage: "Units",
    },
    130: {
        id: "namecode.picklist",
        defaultMessage: "Picklist",
    },
    131: {
        id: "namecode.nutrientRemovalRate",
        defaultMessage: "Nutrient Removal",
    },
    132: {
        id: "namecode.nutrientTarget",
        defaultMessage: "Nutrient Targets",
    },
    133: {
        id: "namecode.nutrient",
        defaultMessage: "Nutrients",
    },
    134: {
        id: "namecode.countryState",
        defaultMessage: "Country/State",
    },
    135: {
        id: "namecode.varietyHybrid",
        defaultMessage: "Variety/Hybrid",
    },
    136: {
        id: "namecode.growthStages",
        defaultMessage: "Growth Stages",
    },
    "136-child": {
        id: "namecode.growthStageOrder",
        defaultMessage: "Growth Stage Order",
    },
    137: {
        id: "nameCode.mergeEvents",
        defaultMessage: "Merge Events",
    },
    138: {
        id: "namecode.soil",
        defaultMessage: "Soil",
    },
    139: {
        id: "namecode.fertilizer",
        defaultMessage: "Fertilizer",
    },
    140: {
        id: "namecode.equipment",
        defaultMessage: "Equipment",
    },
    141: {
        id: "namecode.manure",
        defaultMessage: "Manure",
    },
    142: {
        id: "namecode.tissue",
        defaultMessage: "Tissue",
    },
    143: {
        id: "namecode.water",
        defaultMessage: "Water",
    },
    144: {
        id: "namecode.planting",
        defaultMessage: "Planting",
    },
    145: {
        id: "namecode.harvest",
        defaultMessage: "Harvest",
    },
    146: {
        id: "namecode.tillage",
        defaultMessage: "Tillage",
    },
    147: {
        id: "namecode.application",
        defaultMessage: "Application",
    },
    148: {
        id: "namecode.irrigation",
        defaultMessage: "Irrigation",
    },
    149: {
        id: "namecode.custom",
        defaultMessage: "Custom",
    },
    150: {
        id: "namecode.assignToAll",
        defaultMessage: "Assign To All",
    },
    173: {
        id: "namecode.customer",
        defaultMessage: "Customer",
    },
    176: {
        id: "namecode.importAttribute",
        defaultMessage: "Import Attributes",
    },
    177: {
        id: "namecode.varietyHybridAttributes",
        defaultMessage: "Variety/Hybrid Attributes",
    },
    178: {
        id: "namecode.gene",
        defaultMessage: "Genes",
    },
    179: {
        id: "namecode.trait",
        defaultMessage: "Traits",
    },
    180: {
        id: "namecode.batchZapper",
        defaultMessage: "Batch Zapper",
    },
    181: {
        id: "namecode.notification",
        defaultMessage: "Notifications",
    },
    182: {
        id: "namecode.equation",
        defaultMessage: "Equations",
    },
    183: {
        id: "namecode.equationParameter",
        defaultMessage: "Equation Parameters",
    },
    184: {
        id: "namecode.UILabels",
        defaultMessage: "UI Labels",
    },
    185: {
        id: "namecode.activeInactive",
        defaultMessage: "Active Inactive",
    },
    187: {
        id: "namecode.importFilterSetting",
        defaultMessage: "Import Filter Settings",
    },
    188: {
        id: "namecode.testingLab",
        defaultMessage: "Testing Lab",
    },
    189: {
        id: "namecode.adminScripts",
        defaultMessage: "Admin Scripts",
    },
    209: {
        id: "namecode.agvanceBillableAreaText",
        defaultMessage: "Agvance Billable Area",
    },
    210: {
        id: "namecode.exportEvents",
        defaultMessage: "Events",
    },
    211: {
        id: "namecode.communityData",
        defaultMessage: "Community Data",
    },
    212: {
        id: "namecode.customerAdd",
        defaultMessage: "Customer Add",
    },
    213: {
        id: "namecode.customerEdit",
        defaultMessage: "Customer Edit",
    },
    214: {
        id: "namecode.manualApplication",
        defaultMessage: "Application (Manual)",
    },
    215: {
        id: "namecode.equationApplication",
        defaultMessage: "Application (Equation)",
    },
    216: {
        id: "namecode.manualPlanting",
        defaultMessage: "Planting (Manual)",
    },
    217: {
        id: "namecode.ecData",
        defaultMessage: "EC Data",
    },
    218: {
        id: "namecode.exports",
        defaultMessage: "Exports",
    },
    219: {
        id: "namecode.exportRecs",
        defaultMessage: "Recs",
    },
    220: {
        id: "namecode.exportSamplePoints",
        defaultMessage: "Sample Points",
    },
    221: {
        id: "namecode.exportFieldBoundary",
        defaultMessage: "Field Boundary",
    },
    222: {
        id: "namecode.analysisLayers",
        defaultMessage: "Analysis Layers",
    },
    223: {
        id: "namecode.personImportExport",
        defaultMessage: "Import/Export",
    },
    224: {
        id: "namecode.agrintelligence",
        defaultMessage: "AGRIntelligence",
    },
    225: {
        id: "namecode.fields",
        defaultMessage: "Fields",
    },
    226: {
        id: "namecode.summary",
        defaultMessage: "Summary",
    },
    227: {
        id: "namecode.surfaces",
        defaultMessage: "Surfaces",
    },
    228: {
        id: "namecode.moveFields",
        defaultMessage: "Move Field(s)",
    },
    229: {
        id: "namecode.customerDelete",
        defaultMessage: "Customer Delete",
    },
    230: {
        id: "namecode.batch",
        defaultMessage: "Batch",
    },
    231: {
        id: "namecode.batchEvents",
        defaultMessage: "Events",
    },
    232: {
        id: "namecode.batchRecs",
        defaultMessage: "Recs",
    },
    233: {
        id: "namecode.systemProfile",
        defaultMessage: "System Profile",
    },
    234: {
        id: "namecode.systemOwner",
        defaultMessage: "Owner",
    },
    235: {
        id: "namecode.aliasName",
        defaultMessage: "Alias Name",
    },
    236: {
        id: "namecode.zoneInterpolation",
        defaultMessage: "Zone Interpolation",
    },
    237: {
        id: "nameCode.acrossCustomers",
        defaultMessage: "Across Customers",
    },
    238: {
        id: "namecode.customerSalesperson",
        defaultMessage: "Salesperson",
    },
    239: {
        id: "namecode.personSalesperson",
        defaultMessage: "Salesperson",
    },
    240: {
        id: "namecode.layers",
        defaultMessage: "Layers",
    },
    241: {
        id: "namecode.layerAnalysis",
        defaultMessage: "Analysis",
    },
    242: {
        id: "namecode.layerEvents",
        defaultMessage: "Events",
    },
    243: {
        id: "namecode.layerRecs",
        defaultMessage: "Recommendations",
    },
    244: {
        id: "namecode.telematics",
        defaultMessage: "Telematics",
    },
    245: {
        id: "namecode.account",
        defaultMessage: "Account",
    },
    246: {
        id: "namecode.preferences",
        defaultMessage: "Preferences",
    },
    247: {
        id: "namecode.realTimeUpdates",
        defaultMessage: "Real-Time Updates",
    },
    248: {
        id: "namecode.resurfacing",
        defaultMessage: "Resurfacing",
    },
    249: {
        id: "nameCode.setupUserImportExport",
        defaultMessage: "User Import/Export",
    },
    250: {
        id: "nameCode.combineFields",
        defaultMessage: "Combine fields",
    },
    251: {
        id: "namecode.autoCreateReports",
        defaultMessage: "Auto Create Reports",
    },
    252: {
        id: "nameCode.batchEditRecs",
        defaultMessage: "Edit Recs",
    },
    253: {
        id: "namecode.productAssignmentText",
        defaultMessage: "Product Assignment",
    },
    254: {
        id: "namecode.orgLevelQuickChanger",
        defaultMessage: "Org Level Quick Changer",
    },
    255: {
        id: "namecode.surfaceDefaults",
        defaultMessage: "Surface Defaults",
    },
    256: {
        id: "namecode.locationMigrationToolText",
        defaultMessage: "Location Migration",
    },
    257: {
        id: "namecode.analyticsText",
        defaultMessage: "Analytics",
    },
    258: {
        id: "namecode.cluBoundariesText",
        defaultMessage: "CLU Boundaries",
    },
    259: {
        id: "namecode.setupReportBookOptions",
        defaultMessage: "Report Book Options",
    },
    customerHeaderOverRideText: {
        id: "namecode.customerHeaderOverRideText",
        defaultMessage: "Customer",
    },
    260: {
        id: "namecode.nonFieldFeatures",
        defaultMessage: "Non Field Features",
    },
    261: {
        id: "namecode.equationSetsPlanting",
        defaultMessage: "Equation Sets - Planting",
    },
    262: {
        id: "namecode.equationPlanting",
        defaultMessage: "Planting (Equation)",
    },
    263: {
        id: "namecode.exportEventData",
        defaultMessage: "Event Data",
    },
    264: {
        id: "namecode.samplingOptions",
        defaultMessage: "Sampling Options",
    },
    265: {
        id: "namecode.zoneSampling",
        defaultMessage: "Zone Sampling",
    },
    266: {
        id: "namecode.setupReportBooks",
        defaultMessage: "Report Books",
    },
    267: {
        id: "namecode.batchAnalysisLayers",
        defaultMessage: "Analysis Layers",
    },
    427: {
        id: "namecode.reportReportBooks",
        defaultMessage: "Report Books",
    },
    428: {
        id: "namecode.releaseNotes",
        defaultMessage: "Release Notes",
    },
    429: {
        id: "namecode.notifications",
        defaultMessage: "Notifications",
    },
});
