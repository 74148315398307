import React, { PureComponent } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { injectIntl, intlShape } from "react-intl";

import { Menu, NoLink, RadioButton, SelectInput } from "~/core";
import { config as intlConfig } from "~/intl-provider/config";
import { selectors as blendingSelectors } from "~/action-panel/components/common/product-blend-modal";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import * as recInfoActions from "~/action-panel/components/rec-module/components/rec-info/actions";
import * as recInfoSelectors from "~/action-panel/components/rec-module/components/rec-info/selectors";

import { messages } from "./i18n-messages";
import { actions, models, selectors } from "./";

interface NutrientProductSelectionDisplayProps {
    activeNutrientGuid: string;
    columns: models.RXFileImportSelectableColumn[];
    disableColumnSelection: boolean;
    fieldGuid: string;
    intl: intlShape;
    isBatchRecEdit: boolean;
    recModel: any;
    recNutrientsInProcessing: any;
    setActiveNutrient: (nutrientGuid: string) => void;
    onEditProductMix: (index: number) => void;
    productBlendPicklists: any;
    onRateSelection: (
        equationName: string,
        value: models.RXFileImportSelectableColumnValue
    ) => void;
    selectedColumns: models.RXFileImportSelectedColumn[];
}

class NutrientProductSelectionDisplay_ extends PureComponent<NutrientProductSelectionDisplayProps> {
    constructor(props) {
        super(props);
    }

    _getRateUnitName(rateUnitGuid) {
        const { dryRateUnits, liquidRateUnits } = this.props.productBlendPicklists;
        const allRateUnits = [...dryRateUnits, ...liquidRateUnits];
        return allRateUnits.find((r) => r.value === rateUnitGuid)?.label;
    }

    _getMenuItems(index) {
        return [
            {
                label: "Edit Product Rec",
                action: () => {
                    this.props.onEditProductMix(index);
                },
            },
        ].map((menuItem, key) => {
            return { ...menuItem, key };
        });
    }

    _toggleNutrientSurface(nutrientGuid) {
        this.props.setActiveNutrient(
            nutrientGuid === this.props.activeNutrientGuid ? null : nutrientGuid
        );
    }

    render() {
        const {
            activeNutrientGuid,
            columns,
            disableColumnSelection,
            isBatchRecEdit,
            onRateSelection,
            recModel,
            recNutrientsInProcessing,
            selectedColumns,
        } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;

        const columnsEqual = (
            column1: models.RXFileImportSelectableColumnValue,
            column2: models.RXFileImportSelectableColumnValue
        ) => {
            return (
                column1.column === column2.column &&
                column1.importFileGuid === column2.importFileGuid
            );
        };

        return (
            <div className="nutrient-product-display">
                {recModel.recNutrientList.map((recNutrient, index) => {
                    // When running a rec on a single field, you can have multiple files with the same column name,
                    // We capture that by using the importFileGuid as well as the column name
                    // Allow the selection of the same column name, but a different importFileGuid, for other nutrients
                    const selectedColumn = selectedColumns.find(
                        (r) => r.recNutrientEquationName === recNutrient.equationName
                    );
                    const selectedColumnValue = selectedColumn?.column
                        ? columns.find((c) => columnsEqual(c.value, selectedColumn.column))?.value
                        : null;
                    const availableColumns = columns.filter(
                        (c) =>
                            !selectedColumns.find(
                                (sc) =>
                                    sc.recNutrientEquationName != recNutrient.equationName &&
                                    columnsEqual(sc.column, c.value)
                            )
                    );

                    const hasProductMix = recNutrient.recNutrientProductMix != null;
                    const missingEquationAttributesList = recNutrient.missingEquationAttributes.map(
                        (missingAttribute) => missingAttribute.description
                    );
                    const missingEquationAttributeMessage =
                        missingEquationAttributesList.length > 0
                            ? `${recNutrient.equationName}:  ${missingEquationAttributesList.join(
                                  ", "
                              )}`
                            : "";
                    let showErrorBox = false;
                    if (missingEquationAttributeMessage) {
                        showErrorBox = true;
                    }
                    const editBlockedByFailure =
                        ["rec failed"].includes(recModel.recStatus.toLowerCase()) ||
                        (!recNutrient.equationSuccess && missingEquationAttributeMessage);
                    return (
                        <div
                            key={`nutrient-product-${recNutrient.equationName}`}
                            className="nutrient-product"
                        >
                            <div className="product-info-section">
                                {!["complete", "exported"].includes(
                                    recModel.recStatus.toLowerCase()
                                ) || isBatchRecEdit ? null : (
                                    <div className="product-surface-radio">
                                        <RadioButton
                                            value={recNutrient.nutrientGuid}
                                            checked={
                                                activeNutrientGuid === recNutrient.nutrientGuid
                                            }
                                            onChange={(evt, value) =>
                                                this._toggleNutrientSurface(value)
                                            }
                                            ternary={true}
                                        />
                                    </div>
                                )}
                                <div
                                    className={classnames("nutrient-name", {
                                        "no-surface": !recModel.recGuid,
                                    })}
                                >
                                    {recNutrient.equationName}
                                </div>
                                {!recModel.recGuid ? (
                                    <div className="rate-column-name-container">
                                        <SelectInput
                                            key={recNutrient.equationName}
                                            clearable={true}
                                            placeholderText={"Rate Column Name"}
                                            options={availableColumns}
                                            required
                                            onChange={(
                                                value: models.RXFileImportSelectableColumnValue
                                            ) => onRateSelection(recNutrient.equationName, value)}
                                            value={selectedColumnValue}
                                            disabled={disableColumnSelection}
                                        />
                                    </div>
                                ) : !recNutrient.averageAdjustedRecNutrientResult ||
                                  !recNutrient.averageAdjustedRecNutrientResult
                                      .appliedNutrientRate ? null : (
                                    <div className="nutrient-rate">
                                        {`Rec: ${recNutrient.averageAdjustedRecNutrientResult.appliedNutrientRate} lb/ac`}
                                    </div>
                                )}
                            </div>
                            {recNutrient.productBlendName ||
                            (hasProductMix &&
                                recNutrient.recNutrientProductMix &&
                                (recNutrient.recNutrientProductMix.name ||
                                    recNutrient.recNutrientProductMix.products.length >
                                        0)) ? null : (
                                <NoLink
                                    className="add-default-nolink"
                                    label={formatMessage(messages.setProductText)}
                                    onClick={() => this.props.onEditProductMix(index)}
                                />
                            )}
                            <div
                                className={classnames("product-blend-section", {
                                    "no-blend-name":
                                        !hasProductMix ||
                                        (!recNutrient.recNutrientProductMix.name &&
                                            !recNutrient.productBlendName) ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            recNutrient.productBlendName ===
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .productName) ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            recNutrient.recNutrientProductMix.products[0]
                                                .customProductName),
                                })}
                            >
                                {!hasProductMix ||
                                (recNutrient.recNutrientProductMix.products.length === 1 &&
                                    recNutrient.productBlendName ===
                                        recNutrient.recNutrientProductMix.products[0].productName &&
                                    !recNutrient.recNutrientProductMix.products[0]
                                        .customProductName) ? null : (
                                    <div className="product-blend">
                                        {!hasProductMix ||
                                        (recNutrient.recNutrientProductMix.products.length === 1 &&
                                            (recNutrient.recNutrientProductMix.name ===
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .productName ||
                                                recNutrient.recNutrientProductMix.products[0]
                                                    .customProductName)) ? null : (
                                            <div className="product-mix-name">
                                                {hasProductMix
                                                    ? recNutrient.recNutrientProductMix.name
                                                    : recNutrient.productBlendName}
                                            </div>
                                        )}
                                        <div className="product-mix-products">
                                            {!hasProductMix
                                                ? null
                                                : recNutrient.recNutrientProductMix.products.map(
                                                      (product, pIndex) => {
                                                          return product.customProductName ? (
                                                              <div
                                                                  key={`product-${index}-${pIndex}`}
                                                                  className="custom-product-name"
                                                              >
                                                                  {
                                                                      recNutrient
                                                                          .recNutrientProductMix
                                                                          .name
                                                                  }
                                                              </div>
                                                          ) : (
                                                              <div
                                                                  key={`product-${index}-${pIndex}`}
                                                                  className="product-name"
                                                              >
                                                                  {product.productName}
                                                              </div>
                                                          );
                                                      }
                                                  )}
                                        </div>
                                    </div>
                                )}

                                {!recModel.recGuid ||
                                !hasProductMix ||
                                (hasProductMix &&
                                    recNutrient.recNutrientProductMix.products.length ===
                                        0) ? null : (
                                    <div className="product-rec-value">
                                        {recNutrient.recNutrientGuid &&
                                        recNutrientsInProcessing.includes(
                                            recNutrient.recNutrientGuid
                                        ) ? (
                                            <div className="status import-status-processing">
                                                {formatMessage(messages.processingText)}
                                            </div>
                                        ) : (
                                            `${formatNumber(
                                                blending.isNumeric(
                                                    recNutrient.recNutrientProductMix.targetRate
                                                )
                                                    ? recNutrient.recNutrientProductMix.targetRate
                                                    : recNutrient.recNutrientParameters
                                                          .minimumIncludeZeros
                                                    ? recNutrient.averageAdjustedRecNutrientResult
                                                          .productRate ||
                                                      recNutrient.averageRecNutrientResult
                                                          .productRate
                                                    : recNutrient.averageAdjustedRecNutrientResult
                                                          .productRate ||
                                                      recNutrient.averageRecNutrientResult
                                                          .productRate ||
                                                      0,
                                                intlConfig.numberFormatOptions
                                            )} ${
                                                recNutrient.recNutrientProductMix.targetRateUnit ||
                                                recNutrient.unitName ||
                                                this._getRateUnitName(recNutrient.unitGuid)
                                            }`
                                        )}
                                    </div>
                                )}

                                {recNutrient.isRecAdjustmentProcessing ||
                                editBlockedByFailure ||
                                (recNutrient.recNutrientGuid &&
                                    recNutrientsInProcessing.includes(
                                        recNutrient.recNutrientGuid
                                    )) ||
                                !(
                                    recNutrient.productBlendGuid ||
                                    (hasProductMix &&
                                        (recNutrient.recNutrientProductMix.name ||
                                            recNutrient.recNutrientProductMix.products.length > 0))
                                ) ? null : (
                                    <div className="mix-context-menu">
                                        <Menu
                                            className="context-menu"
                                            isDotMenu={true}
                                            getMenuItems={() => this._getMenuItems(index)}
                                        />
                                    </div>
                                )}
                            </div>

                            {!showErrorBox ? null : (
                                <div
                                    className={classnames(
                                        "missing-equation-attribute-warning-message",
                                        {
                                            "missing-equation-attribute-error-message":
                                                !recNutrient.equationSuccess,
                                        }
                                    )}
                                >
                                    {`${formatMessage(
                                        messages.missingTestResultsText
                                    )} ${missingEquationAttributeMessage}`}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const batchRecDetailsForEdit = recInfoSelectors.getBatchRecDetailsForEdit(state);
    const isBatchRecEdit = batchRecDetailsForEdit && batchRecDetailsForEdit.length > 0;
    return {
        activeNutrientGuid: recInfoSelectors.getActiveNutrientGuid(state),
        columns: selectors.getColumns(state),
        isBatchRecEdit: isBatchRecEdit,
        productBlendPicklists: blendingSelectors.getProductBlendPicklists(state),
        selectedColumns: selectors.getSelectedColumns(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onRateSelection: (equationName: string, value: models.RXFileImportSelectableColumnValue) =>
            dispatch(actions.setImportColumn(equationName, value)),
        setActiveNutrient: (fieldGuid, nutrientGuid) =>
            dispatch(recInfoActions.setActiveNutrient(fieldGuid, nutrientGuid)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    setActiveNutrient: (nutrientGuid) =>
        dispatchProps.setActiveNutrient(ownProps.fieldGuid, nutrientGuid),
});

export const NutrientProductSelectionDisplay = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(NutrientProductSelectionDisplay_));
