import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classnames from "classnames";

import { Checkbox, SelectInput } from "~/core";
import { config as intlConfig } from "~/intl-provider/config";
import { LinkedMapControl, LinkedMapGroupManager } from "~/map/components/linked-map-control";

import { AppHelpers, FieldAPI, LayerAPI, LayerUtilsAPI } from "@ai360/core";
import { IFormatter } from "@ai360/core";
import { LegendDisplay } from "../../legend-display";
import * as selectors from "../../../selectors";
import { messages } from "../../../../i18n-messages";
import { getLayerTypeLetter, getSampleSitesMarkerStyle } from "../../../../../utils";

import "./surface-display.css";
import { ISelectOption } from "~/core/components/select-input/model";

type Statistics = {
    stats?: { avg: number; max: number; min: number };
    titles?: { avg: string; max: string; min: string };
    unitName?: string;
    values?: number[];
    isUserDefined?: boolean;
};
interface ISurfaceDisplayProps {
    displayEventSurfaceStats: boolean;
    fieldGuid: string;
    hidden: boolean;
    id: string;
    intl: IFormatter;
    inverted: boolean;
    isPrimary: boolean;
    layerInfo: LayerAPI.ILayer[];
    linkedMapGroupManager: LinkedMapGroupManager;
    onChange(
        stats: {
            isSampling: boolean;
            layerTitle: string;
            showSampleSites: boolean;
            stats: Statistics;
            surfaceInfo: LayerAPI.ISurfaceInfo;
            surfaceTitle: string;
        } | null
    ): void;
    right: boolean;
    visibleSampleSites: Map<string, LayerAPI.ILayerInfo>;
    visibleSurfaces: Map<string, LayerAPI.ISurfaceInfo>;
}
interface ISurfaceDisplayState {
    dggInfo: Map<string, { value: string; percentCovered: number }[]>;
    dggGroupInfo: Map<string, Record<string, any>[]>;
    isLoading: boolean;
    layer: LayerAPI.ILayer;
    layerTitle: string;
    showSampleSites: boolean;
    surface: Partial<LayerAPI.ISubLayer>;
    surfaceTitle: string;
}

class SurfaceDisplay_ extends Component<ISurfaceDisplayProps, ISurfaceDisplayState> {
    static defaultProps = {
        inverted: false,
        isPrimary: false,
        right: false,
    };

    constructor(props: ISurfaceDisplayProps) {
        super(props);
        this.state = {
            dggGroupInfo: null,
            dggInfo: null,
            isLoading: false,
            layer: null,
            layerTitle: "",
            showSampleSites: false,
            surface: null,
            surfaceTitle: "",
        };
    }

    _createLayerTitle(layer: LayerAPI.ILayer) {
        let { displayName } = layer;
        const type = LayerUtilsAPI.getLayerType(layer);

        if (type === LayerUtilsAPI.LayerType.ANALYSIS) {
            displayName = `${LayerUtilsAPI.getSurfaceInfo(layer).displayName} - ${displayName}`;
        } else if (
            type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA &&
            layer.subLayers[0].croppingSeason !== ""
        ) {
            displayName = `${layer.layerType} - ${layer.subLayers[0].croppingSeason} - ${displayName}`;
        } else if (type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
            displayName = `${layer.layerType} - ${displayName}`;
        }
        return displayName;
    }

    _createSurfaceTitle(layer: LayerAPI.ILayer, surface: Partial<LayerAPI.ISubLayer>) {
        let { displayName } = surface;
        if (!layer.isManual && !layer.isSampling && surface.surfaceTypeDisplayName) {
            displayName = `${displayName} - ${surface.surfaceTypeDisplayName}`;
        }
        return displayName;
    }

    _getDefaultSubLayer(layer: LayerAPI.ILayer) {
        const selectedSurface = layer.subLayers.find((sl) => {
            return (
                (!layer.selectedSurfaceGuid || layer.selectedSurfaceGuid === sl.surfaceGuid) &&
                (!layer.selectedSurfaceTypeGuid ||
                    layer.selectedSurfaceTypeGuid === sl.surfaceTypeGuid)
            );
        });

        return selectedSurface || layer.subLayers[0];
    }

    _getDgg() {
        const { dggInfo, surface } = this.state;
        if (surface == null) {
            return null;
        }

        const { surfaceRendererGuid, unitName } = surface;
        const { formatMessage, formatNumber } = this.props.intl;
        const helperText = <div className="dgg-label">{formatMessage(messages.dggHelper)}</div>;
        if (dggInfo == null) {
            return <div>{helperText}</div>;
        }
        const data = dggInfo.get(surfaceRendererGuid);
        let value = null;
        if (data != null) {
            if (
                data.length === 1 ||
                (data.length > 0 && this._isNonNumericSystemAttribute(surface.name))
            ) {
                value = data[0].value;
            } else if (data.length > 1 && AppHelpers.isNumeric(data[0].value)) {
                //assumes if first value is numeric, then all should be numeric
                const [sum, weight] = data.reduce(
                    ([s, w], { percentCovered, value }) => {
                        return [s + parseFloat(value) * percentCovered, w + percentCovered];
                    },
                    [0, 0]
                );
                if (weight !== 0) {
                    value = sum / weight;
                }
            } else {
                value = data.reduce(
                    (r, d) => {
                        return d.percentCovered > r.percentCovered ? d : r;
                    },
                    {
                        percentCovered: 0,
                        value: null,
                    }
                ).value;
            }
        }
        if (value != null) {
            if (AppHelpers.isNumeric(value) && !this._isNonNumericSystemAttribute(surface.name)) {
                value = formatNumber(value, intlConfig.numberFormatOptions);
            }
            if (unitName != null && unitName !== "" && unitName !== "no unit") {
                value = `${value} ${unitName}`;
            }
            return (
                <div>
                    <div className="dgg-label">{value}</div>
                </div>
            );
        }
        return null;
    }

    _getLegend() {
        const { layer, showSampleSites, surface } = this.state;
        const isSamplingLayer = layer && layer.isSampling;
        if ((surface == null || surface.classBreaks == null) && !isSamplingLayer) {
            return null;
        }
        const { formatMessage } = this.props.intl;
        const legend = [];
        if (isSamplingLayer) {
            legend.push(
                <div
                    key="sample-sites"
                    className="legend-class-row sample-sites-section"
                    onClick={() => this._setSampleSites(!showSampleSites)}
                >
                    <Checkbox className="legend-checkbox" value={showSampleSites} />
                    <div className="sample-sites-icon" style={getSampleSitesMarkerStyle()} />
                    <span className="sample-sites-label">
                        {formatMessage(messages.sampleSites)}
                    </span>
                </div>
            );
        }
        if (!surface || !surface.classBreaks || surface.classBreaks.length === 0) {
            return legend.length > 0 ? legend : null;
        }
        for (const classBreak of surface.classBreaks) {
            legend.push(
                <div key={classBreak.classId} className="legend-class-row">
                    <LegendDisplay classBreak={classBreak} />
                </div>
            );
        }
        return legend;
    }

    _getUserDefinedDggSummary() {
        const { linkedMapGroupManager } = this.props;
        const { stats, titles, unitName, values, isUserDefined } =
            this._getUserDefinedDggSummaryStats();
        const clear = (
            <div
                className="dgg-clear"
                onClick={() => linkedMapGroupManager.clearSurfaceDggSummary()}
            >
                X
            </div>
        );
        if (stats != null && stats.min != null && stats.max != null && stats.avg != null) {
            const units = unitName == null || unitName === "no unit" ? null : <td>{unitName}</td>;
            return (
                <div className="dgg-summary">
                    <div className="spacer" />
                    <table>
                        <tbody>
                            <tr>
                                <td>{titles.min}</td>
                                <td>{stats.min}</td>
                                {units}
                            </tr>
                            <tr>
                                <td>{titles.max}</td>
                                <td>{stats.max}</td>
                                {units}
                            </tr>
                            <tr>
                                <td>{titles.avg}</td>
                                <td>{stats.avg}</td>
                                {units}
                            </tr>
                        </tbody>
                    </table>
                    {isUserDefined ? clear : null}
                </div>
            );
        } else if (values != null && isUserDefined) {
            return (
                <div className="dgg-summary">
                    <table>
                        <tbody>
                            {values.map((v) => (
                                <tr key={v}>
                                    <td>{v}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {clear}
                </div>
            );
        }
        return null;
    }

    _getUserDefinedDggSummaryStats(): Statistics {
        const { dggGroupInfo, surface } = this.state;
        if (surface == null) {
            return {};
        }
        const { formatMessage, formatNumber } = this.props.intl;
        const { surfaceRendererGuid, unitName } = surface;
        const { linkedMapGroupManager } = this.props;
        const data =
            dggGroupInfo != null
                ? dggGroupInfo.get(surfaceRendererGuid)
                : linkedMapGroupManager.dggData.get(surfaceRendererGuid);
        let avg = null,
            max = null,
            min = null,
            values = null,
            isUserDefined = false;
        if (data != null && data.length > 0) {
            if (
                AppHelpers.isNumeric(data[0].value) &&
                !this._isNonNumericSystemAttribute(surface.name)
            ) {
                //assumes if first value is numeric, then all should be numeric
                const [minimum, maximum, sum, weight] = data.reduce(
                    ([min, max, s, w], { percentCovered, value }) => {
                        const val = parseFloat(value);
                        return [
                            isNaN(min) || val < min ? val : min,
                            isNaN(max) || val > max ? val : max,
                            s + value * percentCovered,
                            w + percentCovered,
                        ];
                    },
                    [NaN, NaN, 0, 0]
                );
                min = minimum;
                max = maximum;
                if (weight !== 0) {
                    avg = sum / weight;
                }
            } else {
                values = Array.from(new Set(data.map((d) => d.value))).sort();
            }
        }
        if (avg != null) {
            avg = formatNumber(avg, intlConfig.numberFormatOptions);
        }
        if (max != null) {
            max = formatNumber(max, intlConfig.numberFormatOptions);
        }
        if (min != null) {
            min = formatNumber(min, intlConfig.numberFormatOptions);
        }
        if (dggGroupInfo != null) {
            isUserDefined = true;
        }
        return {
            stats: { avg, max, min },
            titles:
                values != null
                    ? null
                    : {
                          avg: formatMessage(messages.layerStatsDialogAvg),
                          max: formatMessage(messages.layerStatsDialogMax),
                          min: formatMessage(messages.layerStatsDialogMin),
                      },
            unitName,
            values,
            isUserDefined,
        };
    }

    _isNonNumericSystemAttribute = (name: string): boolean => {
        const nonNumericSystemAttributes = ["Variety", "Equipment Name"];
        return nonNumericSystemAttributes.includes(name);
    };

    _optionRenderer = ({ option, isSelected, isHighlighted }) => {
        const { formatMessage } = this.props.intl;
        const className = classnames("select-form-input-option", {
            selected: isSelected,
            "filter-match": isHighlighted,
        });
        return (
            <div className={className}>
                <span className="letter-icon">
                    {getLayerTypeLetter(option.type, formatMessage)}
                </span>
                <span>{option.label}</span>
            </div>
        );
    };

    _layerOptions(): ISelectOption<LayerAPI.ILayer>[] {
        const { layerInfo } = this.props;
        if (layerInfo == null) {
            return [];
        }
        return layerInfo.map((layer) => ({
            label: this._createLayerTitle(layer),
            value: layer,
            type: LayerUtilsAPI.getLayerType(layer),
        }));
    }

    _selectLayerOption(layer: LayerAPI.ILayer) {
        const { fieldGuid, visibleSampleSites } = this.props;
        const showSampleSites =
            visibleSampleSites.get(fieldGuid) != null ||
            (layer && layer.isSampling && layer.subLayers.length === 0);
        const surface = layer != null ? this._getDefaultSubLayer(layer) : null;
        this.setState(
            {
                showSampleSites,
                layer,
                surface,
            },
            () => this._updateMapInfo()
        );
    }

    _selectLayerTitle(layerTitle: string) {
        this.setState({ layerTitle });
    }

    _selectSurfaceOption(surface: Partial<LayerAPI.ISubLayer>) {
        this.setState({ surface }, () => this._updateMapInfo());
    }

    _selectSurfaceTitle(surfaceTitle: string) {
        this.setState({ surfaceTitle });
    }

    _setSampleSites(showSampleSites: boolean) {
        this.setState({ showSampleSites }, () => this._updateMapInfo());
    }

    _surfaceOptions(layer: LayerAPI.ILayer): ISelectOption<LayerAPI.ISubLayer>[] {
        if (!layer || layer.subLayers === null) {
            return [];
        }
        return (
            layer.subLayers &&
            layer.subLayers
                .map((surface) => ({
                    label: this._createSurfaceTitle(layer, surface),
                    value: surface,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))
        );
    }

    _updateMapInfo() {
        const { onChange } = this.props;
        if (onChange == null) {
            return;
        }
        const { layer, layerTitle, showSampleSites, surface, surfaceTitle } = this.state;
        const isSamplingLayer = layer && layer.isSampling;
        const surfaceInfo = surface == null ? null : LayerUtilsAPI.getSlimSurfaceInfo(surface);
        const newSurfaceTitle = surfaceTitle ? surfaceTitle : surface ? surface.displayName : null;
        onChange(
            (layer == null || surface == null) && !isSamplingLayer
                ? null
                : {
                      isSampling: layer.isSampling,
                      layerTitle,
                      showSampleSites,
                      stats: this._getUserDefinedDggSummaryStats(),
                      surfaceInfo,
                      surfaceTitle: newSurfaceTitle,
                  }
        );
    }

    UNSAFE_componentWillMount() {
        const {
            fieldGuid,
            isPrimary,
            layerInfo,
            linkedMapGroupManager,
            visibleSurfaces,
            visibleSampleSites,
        } = this.props;
        if (!isPrimary) {
            return;
        }
        const visibleSurface = visibleSurfaces.get(fieldGuid);
        const visibleSite = visibleSampleSites.get(fieldGuid);
        if (visibleSurface != null) {
            const layer = linkedMapGroupManager.getLayer(visibleSurface);
            if (layer != null) {
                const surface = LayerUtilsAPI.getSurfaceInfo(layer, visibleSurface.surfaceGuid);
                this.setState(
                    {
                        layer,
                        layerTitle: this._createLayerTitle(layer),
                        showSampleSites: visibleSite != null,
                        surface,
                        surfaceTitle: this._createSurfaceTitle(layer, surface),
                    },
                    () => this._updateMapInfo()
                );
            }
        } else if (visibleSite) {
            const layer = layerInfo.find((l) => {
                return l.agEventGeneralGuid === visibleSite.agEventGeneralGuid;
            });
            if (layer != null) {
                this.setState(
                    {
                        layer,
                        layerTitle: this._createLayerTitle(layer),
                        showSampleSites: true,
                        surface: null,
                        surfaceTitle: null,
                    },
                    () => this._updateMapInfo()
                );
            }
        }
    }

    render() {
        const {
            fieldGuid,
            hidden,
            id,
            inverted,
            isPrimary,
            linkedMapGroupManager,
            right,
            displayEventSurfaceStats,
        } = this.props;
        const { layer, showSampleSites, surface } = this.state;
        const { formatMessage } = this.props.intl;

        const components = [];
        const selectRow = (
            <div
                key="select-row"
                className={classnames("surface-display-select-row", {
                    "right-aligned-row": right,
                })}
            >
                <SelectInput
                    containerClassNames={["surface-display-layer-list"]}
                    placeholderText={formatMessage(messages.splitScreenLayerLbl)}
                    value={layer as Record<string, any>}
                    options={this._layerOptions()}
                    optionRenderer={this._optionRenderer}
                    onChange={(l: Record<string, any>) =>
                        this._selectLayerOption(l as LayerAPI.ILayer)
                    }
                    onInputChange={(l) => this._selectLayerTitle(l)}
                />
                <SelectInput
                    disabled={layer == null}
                    placeholderText={formatMessage(messages.splitScreenSurfaceLbl)}
                    value={surface}
                    options={this._surfaceOptions(layer)}
                    onChange={(s: Record<string, any>) => this._selectSurfaceOption(s)}
                    onInputChange={(s) => this._selectSurfaceTitle(s)}
                />
            </div>
        );
        const map = (
            <LinkedMapControl
                key="map"
                id={id}
                className="surface-display-map"
                fieldGuid={fieldGuid}
                layer={layer}
                linkedMapGroupManager={linkedMapGroupManager}
                showSampleSites={layer != null && layer.isSampling && showSampleSites}
                surface={surface == null ? null : LayerUtilsAPI.getSlimSurfaceInfo(surface)}
                onUpdateDgg={(dggInfo) => this.setState({ dggInfo })}
                onUpdateDggGroup={(dggGroupInfo) =>
                    this.setState({ dggGroupInfo }, () => this._updateMapInfo())
                }
                updateTableRecords={isPrimary && displayEventSurfaceStats}
            >
                <div className="floating-legend">{this._getLegend()}</div>
                <div className="floating-dgg">
                    {this._getUserDefinedDggSummary()}
                    {this._getDgg()}
                </div>
            </LinkedMapControl>
        );
        if (inverted) {
            components.push(map, selectRow);
        } else {
            components.push(selectRow, map);
        }

        return (
            <div className={classnames("surface-display-container", hidden ? "hide" : "")}>
                {components}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const splitScreenCompareTool = selectors.getSplitScreenCompareTool(state);
    const { displayEventSurfaceStats } = splitScreenCompareTool;
    return {
        visibleSampleSites: selectors.getVisibleSampleSitesMap(state),
        visibleSurfaces: selectors.getVisibleSurfacesMap(state),
        displayEventSurfaceStats,
    };
};

export const SurfaceDisplay = connect(mapStateToProps)(injectIntl(SurfaceDisplay_));
