import React, { Component } from "react";
import { connect } from "react-redux";
import { intlShape, injectIntl } from "react-intl";
import { messages } from "../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";
import { Bucket, BucketHeader, ZeroToInfinite } from "~/core";
import VarietyHybridItems from "./variety-hybrid-items";
import { defaultValue } from "./default-value";
import { actions, selectors } from "./data";
import { LABEL, VALUE } from "~/admin/data";
// Constants
import * as constants from "./constants";
// Style
import "./variety-hybrid.css";
import { IPayloadData, IUpdatePlantingModal } from "../interfaces";

export interface IVarietyHybridProps {
    brandOrgData?: Record<string, any>[];
    brandVarietyHybridList?: Record<string, any>;
    cropGuid?: string;
    dryPriceUnit?: number;
    dryProductUnit?: number;
    errorMessagePlaceholderSet?: Record<string, any>;
    errorMessages?: Record<string, any>;
    fetchDropdownData?: () => void;
    fetchSystemAttributePicklist?: () => void;
    fetchVarietyHybridList?: (payload?: IPayloadData) => void;
    getPhysicalState?: (guid: string) => string;
    intl?: intlShape;
    isImportedYn?: boolean;
    liquidPriceUnit?: number;
    liquidProducteUnit?: number;
    onChildComponentChange?: (VHList) => void;
    packageUnit?: number;
    productData?: Record<string, any>[];
    saveEventDetailsErrorCodeList?: number[];
    seedingRate?: number[];
    transplant?: boolean;
    transplantRate?: number;
    varietyHybridList?: Partial<VarietyHybrid>[];
    fieldGuid?: string;
    updatePlantingModel?: (plantingModal: IUpdatePlantingModal) => void;
    agEventTransactionTypeGuid?: string;
}

export interface IVarietyHybridState {
    varietyHybridList: Record<string, any>[];
    seedingRate: number[];
}
export class VarietyHybrid extends Component<IVarietyHybridProps, IVarietyHybridState> {
    constructor(props: IVarietyHybridProps) {
        super(props);
        this.state = {
            varietyHybridList: [],
            seedingRate: [],
        };
    }

    public getLabel = (
        { options = [], guid = "" }: { options: Record<string, any>; guid: string },
        valueKey = VALUE,
        labelKey = LABEL
    ): string => {
        if (options.length) {
            const selectedOption = options.filter((option) => {
                return guid === option[valueKey];
            });
            const label = selectedOption.length ? selectedOption[0][labelKey] : null;
            return label;
        }
        return null;
    };

    UNSAFE_componentWillReceiveProps(nextProps: IVarietyHybridProps): void {
        if (
            nextProps.varietyHybridList !== this.state.varietyHybridList &&
            nextProps.varietyHybridList
        ) {
            this.setState(
                {
                    varietyHybridList: nextProps.varietyHybridList,
                },
                () => {
                    this.state.varietyHybridList.forEach((record) => {
                        if (record[constants.PROPS_BRAND_ORGANIZATION_GUID]) {
                            this.requestVarietyHybrid(
                                record[constants.PROPS_BRAND_ORGANIZATION_GUID]
                            );
                        }
                    });
                }
            );
        }

        if (nextProps.seedingRate !== this.props.seedingRate) {
            this.setState({
                seedingRate: nextProps.seedingRate,
            });
        }
    }

    public addItem = (e, items: Record<string, any>[]): any => {
        const modifiedItems = items.map((item, index) => {
            const percentOfPlanter = (100 / items.length).toFixed(2);
            if (index === 0) {
                return {
                    ...item,
                    [constants.PROPS_PERCENT_OF_PLANTER]: percentOfPlanter,
                };
            } else {
                return {
                    ...item,
                    [constants.PROPS_PRICE_PER_UNIT]: items[0][constants.PROPS_PRICE_PER_UNIT],
                    [constants.PROPS_PRICE_PER_UNIT_GUID]:
                        items[0][constants.PROPS_PRICE_PER_UNIT_GUID],
                    [constants.PROPS_SEEDING_RATE_PER_UNIT_TEXT]:
                        items[0][constants.PROPS_SEEDING_RATE_PER_UNIT_TEXT],
                    [constants.PROPS_SEEDING_RATE_IA_GUID]:
                        items[0][constants.PROPS_SEEDING_RATE_IA_GUID],
                    [constants.PROPS_PERCENT_OF_PLANTER]: percentOfPlanter,
                };
            }
        });
        this.setState({ varietyHybridList: modifiedItems }, () =>
            this.props.onChildComponentChange(this.state.varietyHybridList)
        );
    };

    public onDelete = (e, items: Record<string, any>[]): any => {
        const modifiedItems = items.map((item) => {
            return {
                ...item,
                [constants.PROPS_PERCENT_OF_PLANTER]: (100 / items.length).toFixed(2),
            };
        });
        this.setState({ varietyHybridList: modifiedItems }, () =>
            this.props.onChildComponentChange(this.state.varietyHybridList)
        );
    };

    public onChangeMultiple = (newObj: Record<string, any>, index: number): void => {
        const varietyHybridList = [...this.state.varietyHybridList];
        if (newObj) {
            const newVarietyHybrid = {
                ...varietyHybridList[index],
                ...newObj,
            };
            varietyHybridList.splice(index, 1, newVarietyHybrid);
        }
        this.setState({ varietyHybridList }, () => {
            this.props.onChildComponentChange(this.state.varietyHybridList);
        });
    };

    public onChange = (
        type: string,
        value: string,
        index: number,
        cb: (val: string) => void
    ): void => {
        const varietyHybridList = [...this.state.varietyHybridList];

        if (type) {
            const newVarietyHybrid = {
                ...varietyHybridList[index],
                [type]: value,
            };
            varietyHybridList.splice(index, 1, newVarietyHybrid);
        }

        this.setState({ varietyHybridList }, () => {
            this.props.onChildComponentChange(this.state.varietyHybridList);
            if (typeof cb === "function") {
                cb(value);
            }
        });
    };

    public onSeedingRateUnitChange = (params: Record<string, any>): void => {
        let varietyHybridList = [...this.state.varietyHybridList];
        if (params) {
            const { updatePlantingModel, fieldGuid, agEventTransactionTypeGuid } = this.props;
            const newProps = {
                [params.pricePerUnitProp]: params.pricePerUnit,
                [params.pricePerUnitGuidProp]: params.unitGuid,
                [params.seedingRateUnitGuidProp]: params.value,
                [params.seedingRateUnitProp]: params.label,
            };
            updatePlantingModel({
                fieldGuid,
                transactionTypeGuid: agEventTransactionTypeGuid,
                newProps,
            });
            varietyHybridList = this.state.varietyHybridList.map((vh) => {
                return {
                    ...vh,
                    ...newProps,
                };
            });
        }

        this.setState({ varietyHybridList }, () =>
            this.props.onChildComponentChange(this.state.varietyHybridList)
        );
    };

    public requestVarietyHybrid = (guid: string): void => {
        if (guid) {
            const { cropGuid } = this.props;
            const payload = { cropId: cropGuid, brandOrganization: guid };
            this.props.fetchVarietyHybridList(payload);
        }
    };

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const {
            brandOrgData,
            brandVarietyHybridList,
            dryPriceUnit,
            dryProductUnit,
            errorMessagePlaceholderSet,
            errorMessages,
            getPhysicalState,
            isImportedYn,
            liquidPriceUnit,
            liquidProducteUnit,
            packageUnit,
            productData,
            saveEventDetailsErrorCodeList,
            transplant,
            transplantRate,
        } = this.props;
        const {
            onChange,
            onChangeMultiple,
            requestVarietyHybrid,
            getLabel,
            onSeedingRateUnitChange,
        } = this;
        const { varietyHybridList } = this.state;
        const props = {
            brandOrgData,
            brandVarietyHybridList,
            dryPriceUnit,
            dryProductUnit,
            errorMessagePlaceholderSet,
            errorMessages,
            isImportedYn,
            getLabel,
            getPhysicalState,
            liquidPriceUnit,
            liquidProducteUnit,
            onChange,
            onChangeMultiple,
            onSeedingRateUnitChange,
            packageUnit,
            productData,
            requestVarietyHybrid,
            saveEventDetailsErrorCodeList,
            seedingRate: this.state.seedingRate,
            transplant,
            transplantRate,
            varietyHybridList,
        };
        return (
            <Bucket
                className="import-field-section-bucket plainting-event-variety-hybrid"
                isExpanded
            >
                <BucketHeader className="planting-event-section-header">
                    <div className="create-events-header">
                        {formatMessage(messages.varietyHybridInfo)}
                    </div>
                </BucketHeader>
                <ZeroToInfinite
                    items={this.state.varietyHybridList}
                    initialValue={defaultValue()}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    addText={createAddLinkLabelText(formatMessage, messages.varietyHybrid)}
                    deleteText={formatMessage(mainMessages.delete)}
                    hideAddAndDelete={isImportedYn}
                    isDisabled={!this.props.cropGuid}
                    formatMessage={formatMessage}
                    containerClassNames={["planting-variety-hybrid"]}
                    saveEventDetailsErrorCodeList={saveEventDetailsErrorCodeList}
                    errorMessagePlaceholderSet={errorMessagePlaceholderSet}
                    errorMessages={errorMessages}
                >
                    <VarietyHybridItems {...props} />
                </ZeroToInfinite>
            </Bucket>
        );
    }
}

const mapStateToProps = (state) => ({
    brandVarietyHybridList: selectors.getBrandVarietyHybridList(state),
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVarietyHybridList: (payload) => dispatch(actions.fetchVarietyHybridList(payload)),
    };
};

export default connect<
    Partial<IVarietyHybridProps>,
    Partial<IVarietyHybridProps>,
    Partial<IVarietyHybridProps>
>(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(VarietyHybrid));
