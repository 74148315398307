import { SearchAPI } from "@ai360/core";
import { ICustomerFieldPageId } from "@ai360/core/dist/4x/es/api/search";
import { select } from "redux-saga/effects";
import { FieldListTabs } from "~/action-panel/components/field-module/actions";
import { IFieldFilterSelections } from "~/action-panel/components/field-module/components/field-list/models";
import { getFilterSelections } from "~/action-panel/components/field-module/components/field-list/selectors";
import { getActiveTab } from "~/action-panel/components/field-module/selectors";
import { AutoExpandedCustomersModifications } from "~/customer-data/actions";
import { getSelectedFieldGuids } from "~/customer-data/selectors";
import { getTheUserGuid } from "~/login/selectors";

export const defaultCustomerFieldPageSize = 50;
export const maxCustomerFieldPageSize = 2147483647;

const searchFilter = (filterSelections: IFieldFilterSelections) =>
    filterSelections.search === "" ? null : filterSelections.search;

export const activeFilter = (activeTab: FieldListTabs) =>
    activeTab === FieldListTabs.ACTIVE ? true : activeTab === FieldListTabs.INACTIVE ? false : null;

export const fieldGuidFilter = (activeTab: FieldListTabs, selectedFieldGuids: string[]) =>
    activeTab === FieldListTabs.SELECTED ? selectedFieldGuids : null;

export const filteredCustomerFieldRequest = function* (
    customerGuids: string[],
    pageSize: number,
    lastPageId: ICustomerFieldPageId
) {
    const selectedFieldGuids = yield select(getSelectedFieldGuids);
    const activeTab: FieldListTabs = yield select(getActiveTab);
    const userGuid: string = yield select(getTheUserGuid);
    const filterSelections: IFieldFilterSelections = yield select(getFilterSelections);

    const request: SearchAPI.ICustomerFieldRequest = {
        userGuid,
        search: searchFilter(filterSelections),
        active: activeFilter(activeTab),
        enrolled: filterSelections.enrolled,
        certifiedOrganic: filterSelections.certifiedOrganic,
        locationOrgLevelGuid: filterSelections.orgLevelGuid,
        fieldGuid: fieldGuidFilter(activeTab, selectedFieldGuids),
        salesperson: filterSelections.salespeople,
        irrigated: filterSelections.irrigated,
        crop: filterSelections.crops,
        classification: filterSelections.classifications,
        customerGuid: customerGuids,
        pageSize,
        lastPageId,
    };
    return request;
};

export const filteredSelectCustomerFieldRequest = (
    active: boolean,
    userGuid: string,
    customerGuid: string,
    filterSelections: IFieldFilterSelections
): SearchAPI.ISelectCustomerFieldRequest => ({
    userGuid,
    search: searchFilter(filterSelections),
    active: active,
    enrolled: filterSelections.enrolled,
    certifiedOrganic: filterSelections.certifiedOrganic,
    locationOrgLevelGuid: filterSelections.orgLevelGuid,
    salesperson: filterSelections.salespeople,
    irrigated: filterSelections.irrigated,
    crop: filterSelections.crops,
    classification: filterSelections.classifications,
    customerGuid,
});

export const filteredFieldRequest = function* (customerIds: string[]) {
    const selectedFieldGuids = yield select(getSelectedFieldGuids);
    const activeTab: FieldListTabs = yield select(getActiveTab);
    const userGuid: string = yield select(getTheUserGuid);
    const filterSelections: IFieldFilterSelections = yield select(getFilterSelections);

    const request: SearchAPI.IFieldRequest = {
        customerGuid: customerIds,
        fieldGuid: fieldGuidFilter(activeTab, selectedFieldGuids),
        userGuid,
        search: filterSelections.search === "" ? null : filterSelections.search,
        active: activeFilter(activeTab),
        certifiedOrganic: filterSelections.certifiedOrganic,
        irrigated: filterSelections.irrigated,
        crop: filterSelections.crops,
        classification: filterSelections.classifications,
    };
    return request;
};

export const filteredSummaryRequest = (
    userGuid: string,
    filterSelections: IFieldFilterSelections
): SearchAPI.ISummaryRequest => ({
    userGuid,
    search: filterSelections.search === "" ? null : filterSelections.search,
    enrolled: filterSelections.enrolled,
    certifiedOrganic: filterSelections.certifiedOrganic,
    salesperson: filterSelections.salespeople,
    irrigated: filterSelections.irrigated,
    crop: filterSelections.crops,
    classification: filterSelections.classifications,
    locationOrgLevelGuid: filterSelections.orgLevelGuid,
});

export const fieldsSummaryRequest = (
    userGuid: string,
    fields: string[]
): SearchAPI.ISummaryRequest => ({
    userGuid,
    field: fields,
});

export const autoExpandedCustomerIds = (customerFields: SearchAPI.ICustomerFieldResult[]) =>
    customerFields.filter((x) => x.fieldMatchedSearch).map((x) => x.customerId);

export const notAutoExpandedCustomerIds = (customerFields: SearchAPI.ICustomerFieldResult[]) =>
    customerFields.filter((x) => !x.fieldMatchedSearch).map((x) => x.customerId);

export const autoExpandedCustomerModifications = (
    customerFields: SearchAPI.ICustomerFieldResult[]
): AutoExpandedCustomersModifications => ({
    merge: autoExpandedCustomerIds(customerFields),
    remove: notAutoExpandedCustomerIds(customerFields),
});
