import PropTypes from "prop-types";
import React, { Component, CSSProperties } from "react";
import ReactDOM from "react-dom";
import { AutoSizer, defaultCellRangeRenderer, List } from "react-virtualized";

import scrollbarSize from "dom-helpers/util/scrollbarSize";

import * as model from "../model";

import "./accordion.css";

// tslint:disable:member-ordering

interface IStickyItemsProps {
    /**
     * This is used to scroll the Accordion list when the scroll
     * wheel is used on the sticky items
     */
    onWheel: (this: HTMLDivElement, ev: WheelEvent) => any;
}

interface IStickyItemsState {
    stickyItemElements: HTMLElement[];
}

class StickyItems extends Component<IStickyItemsProps, IStickyItemsState> {
    private stickyDiv?: HTMLDivElement;

    public static propTypes = {
        onWheel: PropTypes.func,
    };

    public static defaultProps = {
        onWheel: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            stickyItemElements: [],
        };
    }

    public componentDidMount() {
        // better to use this than react onWheel ..
        // https://github.com/facebook/react/issues/1254
        this.stickyDiv.addEventListener("wheel", this.props.onWheel, {
            passive: false,
        });
    }

    public componentWillUnmount() {
        this.stickyDiv.removeEventListener("wheel", this.props.onWheel);
    }

    public render() {
        return <div ref={(ref) => (this.stickyDiv = ref)}>{this.state.stickyItemElements}</div>;
    }
}

export interface IGetAccordionItemElArgs<T = any> {
    accordionItem: model.AccordionItem;
    accordionItemDimIdx: model.MultiDimIdx;
    componentProps: IAccordionProps & T;
    isStuck: boolean;
    key: string;
    style: CSSProperties;
}

export interface IAccordionProps {
    itemCount: number;
    itemList?: model.AccordionItem[];
    totalHeight: number;
    getAccordionItemFromFlatIdx: (
        flatIdx: model.FlatIdx
    ) => [model.AccordionItem, model.MultiDimIdx];
    getAccordionItemFromDimIdx: (flatIdx: model.MultiDimIdx) => model.AccordionItem;
    getAccordionItemEl: (arg: IGetAccordionItemElArgs) => JSX.Element;
    style?: Record<string, any>;
    scrollTop?: number;
    onScrollTopChanged?: (scrollTop: number, props: IAccordionProps) => any;
    scrollToIndex?: model.FlatIdx;
}

export interface IAccordionState {
    scrollTop?: number;
    scrollToIndex?: model.FlatIdx;
}

export class Accordion extends Component<IAccordionProps, IAccordionState> {
    public static propTypes = {
        itemCount: PropTypes.number.isRequired,
        totalHeight: PropTypes.number.isRequired,
        getAccordionItemFromFlatIdx: PropTypes.func.isRequired,
        getAccordionItemFromDimIdx: PropTypes.func.isRequired,
        getAccordionItemEl: PropTypes.func.isRequired,
        style: PropTypes.object,
        scrollTop: PropTypes.number,
        onScrollTopChanged: PropTypes.func, // only called when component unmounts
        scrollToIndex: PropTypes.number,
    };

    public static defaultProps = {
        scrollTop: 0,
        onScrollTopChanged: (): void => null,
    };

    public static stickyItemOverlayStyle: CSSProperties = {
        position: "absolute",
        left: 0,
    };

    private listRef: List;
    private listScrollContainer: Element;
    private stickyItemsRef: StickyItems;

    constructor(props: IAccordionProps) {
        super(props);
        this.state = {
            scrollTop: props.scrollTop,
            scrollToIndex: props.scrollToIndex,
        };
    }

    private _addStickyItems(props, children) {
        // sometimes `props.visibleRowIndices.start` is incorrect and the first visible
        //  item is actually `start - 1|2`
        const [startIdx, stopIdx] = [
            Math.max(props.rowStartIndex, props.visibleRowIndices.start - 2),
            Math.min(props.rowStopIndex, props.visibleRowIndices.stop + 2),
        ];

        const [visibleDimIndices, visibleElStyles, stickyMakerDimIndices] = this._findStickyMakers(
            props.scrollTop,
            props.rowStartIndex,
            startIdx,
            stopIdx,
            children
        );

        const stickyItemDimIndices = this._getStickyItemDimIndices(stickyMakerDimIndices);

        const visibleDimIdxStrToVisibleItemIdxMap = {};
        visibleDimIndices.forEach(
            (dimIdx, flatIdx) => (visibleDimIdxStrToVisibleItemIdxMap[dimIdx] = flatIdx)
        );

        const stickyItemElements = [];
        let offset = 0;
        let scrollRelOffset = props.scrollTop;
        for (const stickyItemDimIdx of stickyItemDimIndices) {
            const pusherStyle = this._findStickyItemPusher(
                stickyItemDimIdx,
                visibleDimIndices,
                visibleElStyles
            );

            const maxRenderedHeight = Math.max(
                0,
                (pusherStyle != null ? pusherStyle.top : 10e6) - scrollRelOffset
            );
            const stickyItemEl = this._getStickyItemEl(stickyItemDimIdx, offset, maxRenderedHeight);
            const renderedHeight = Math.min(maxRenderedHeight, stickyItemEl.props.style.height);
            offset += renderedHeight;
            scrollRelOffset += renderedHeight;

            // Check if "sticky" item is "stuck" (i.e., not already fully visible)
            if (stickyItemDimIdx in visibleDimIdxStrToVisibleItemIdxMap) {
                const visibleItemIdx = visibleDimIdxStrToVisibleItemIdxMap[stickyItemDimIdx];
                const visibleElStyle = visibleElStyles[visibleItemIdx];
                if (visibleElStyle.top - props.scrollTop >= stickyItemEl.props.style.top) {
                    // sticky item @ `stickyItemDimIdx` and lower are already visible
                    break;
                }
            }
            stickyItemElements.splice(0, 0, stickyItemEl);
        }

        setTimeout(() => {
            if (this.stickyItemsRef != null) {
                this.stickyItemsRef.setState({ stickyItemElements });
            }
        }, 0);
    }

    /**
     * Find the `dimIdx` of the "pusher" of each sticky item ..
     * The "pusher" is the item that replaces the sticky item as we scroll
     */
    private _findStickyItemPusher = (stickyItemDimIdx, visibleDimIndices, visibleElStyles) => {
        for (let pusherIdx = 0; pusherIdx < visibleElStyles.length; pusherIdx++) {
            const testPusherDimIdx = visibleDimIndices[pusherIdx];
            if (model.dimIdxIsGreaterNonDescendant(testPusherDimIdx, stickyItemDimIdx)) {
                return visibleElStyles[pusherIdx];
            }
        }
    };

    /**
     * Find the items that require sticky parents
     */
    private _findStickyMakers(scrollTop, rowStartIndex, startIdx, stopIdx, children) {
        const visibleDimIndices = [];
        const visibleElStyles = [];
        const stickyMakerDimIndices = [];

        for (let elIdx = startIdx; elIdx < stopIdx; elIdx++) {
            const [visibleElIdx, visibleElStyle] = this._getNextVisibleChildIdx(
                scrollTop,
                rowStartIndex,
                elIdx,
                children
            );
            const itemDimIdx = this.props.getAccordionItemFromFlatIdx(visibleElIdx)[1];
            if (elIdx > startIdx) {
                const testStickyMakerDimIdx = visibleDimIndices[visibleDimIndices.length - 1];
                if (
                    !model.dimIdxIsDescendantOrHigherIdxSibling(testStickyMakerDimIdx, itemDimIdx)
                ) {
                    stickyMakerDimIndices.push(testStickyMakerDimIdx);
                }
            }
            visibleDimIndices.push(itemDimIdx);
            visibleElStyles.push(visibleElStyle);
        }

        if (visibleDimIndices.length > 0) {
            const lastVisibleDimIdx = visibleDimIndices[visibleDimIndices.length - 1];
            if (
                !new Set(stickyMakerDimIndices.map((dimIdx) => String(dimIdx))).has(
                    lastVisibleDimIdx
                )
            ) {
                stickyMakerDimIndices.push(lastVisibleDimIdx);
            }
        }

        return [visibleDimIndices, visibleElStyles, stickyMakerDimIndices];
    }

    private _getNextVisibleChildIdx(visibleTop, childrenIdxOffset, startIdx, children) {
        let nextVisibleChildEl = children[startIdx - childrenIdxOffset];
        let elStyle = nextVisibleChildEl.props.style;

        while (
            elStyle.height + elStyle.top - visibleTop < 0 &&
            startIdx - childrenIdxOffset + 1 < children.length
        ) {
            nextVisibleChildEl = children[++startIdx - childrenIdxOffset];
            elStyle = nextVisibleChildEl.props.style;
        }

        return [startIdx, elStyle];
    }

    /**
     * Find the `dimIdx` for every sticky item; tho sticky, they may still be visible and not yet "stuck"
     */
    private _getStickyItemDimIndices(stickyMakerDimIndices) {
        const stickyItemDimIdxStrs = new Set();
        const stickyItemDimIndices = [];
        for (const itemWithStickyParentDimIdx of stickyMakerDimIndices) {
            for (let dim = 1; dim < itemWithStickyParentDimIdx.length; dim++) {
                const stickyItemDimIdx = itemWithStickyParentDimIdx.slice(0, dim);
                const stickyItemDimIdxStr = String(stickyItemDimIdx);
                if (stickyItemDimIdxStrs.has(stickyItemDimIdxStr)) {
                    continue;
                }
                stickyItemDimIdxStrs.add(stickyItemDimIdxStr);
                stickyItemDimIndices.push(stickyItemDimIdx);
            }
        }
        return stickyItemDimIndices;
    }

    private _getStickyItemEl(itemDimIdx, offset, maxRenderedHeight) {
        const item = this.props.getAccordionItemFromDimIdx(itemDimIdx);
        const unrenderedHeight = item.height - Math.min(item.height, maxRenderedHeight);
        const style = {
            ...Accordion.stickyItemOverlayStyle,
            top: offset - unrenderedHeight,
            right: scrollbarSize(),
            height: item.height,
        };

        return this.props.getAccordionItemEl({
            accordionItem: item,
            accordionItemDimIdx: itemDimIdx,
            componentProps: this.props,
            isStuck: true,
            key: `ov-${itemDimIdx}`,
            style,
        });
    }

    private onScroll = () => {
        if (this.state.scrollTop != null || this.state.scrollToIndex != null) {
            this.setState({ scrollTop: null, scrollToIndex: null });
        }
    };

    private cellRangeRenderer = (props) => {
        const children = defaultCellRangeRenderer(props);
        this._addStickyItems(props, children);
        return children;
    };

    private getRowElement = (argsObj) => {
        const [item, dimIdx] = this.props.getAccordionItemFromFlatIdx(argsObj.index);

        const custStyle = {
            position: "absolute",
            left: 0,
            right: 0,
            top: argsObj.style.top,
            height: argsObj.style.height,
        };

        return this.props.getAccordionItemEl({
            ...argsObj,
            style: custStyle,
            accordionItem: item,
            accordionItemDimIdx: dimIdx,
            isStuck: false,
            componentProps: this.props,
        });
    };

    private getRowHeight = ({ index }) => {
        const item = this.props.getAccordionItemFromFlatIdx(index)[0];
        return item.height;
    };

    public getScrollTop(): number {
        return this.listScrollContainer.scrollTop;
    }

    private onStickyItemWheel = (event) => {
        event.preventDefault();
        const deltaCvt = 6.5;
        const totalDelta = event.deltaY * (event.deltaMode === 1 ? deltaCvt : 1 / deltaCvt);
        const maxScrollTop = this.listScrollContainer.scrollHeight;
        const scrollTop = Math.min(
            maxScrollTop,
            Math.max(0, this.listScrollContainer.scrollTop + totalDelta)
        );
        this.listScrollContainer.scrollTop = scrollTop;
    };

    public componentDidMount(): void {
        this.listScrollContainer = ReactDOM.findDOMNode(this.listRef) as Element;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IAccordionProps): void {
        // If the items change, but the item count and item height stay the same
        //   then `<List />` doesn't recompute row heights correctly, so we
        //   force it to by calling `this.listRef.recomputeRowHeights(0)`
        const mayNotProperlyRerender = this.props.itemList !== nextProps.itemList;
        if (mayNotProperlyRerender && this.listRef != null) {
            this.listRef.recomputeRowHeights(0);
        }

        if (nextProps.scrollTop !== this.props.scrollTop) {
            const newScrollTop =
                nextProps.scrollTop == null
                    ? null
                    : nextProps.scrollTop < nextProps.totalHeight
                    ? nextProps.scrollTop
                    : nextProps.totalHeight;
            if (newScrollTop !== this.state.scrollTop) {
                this.setState({ scrollTop: newScrollTop });
            }
        }
        if (nextProps.scrollToIndex !== this.props.scrollToIndex) {
            this.setState({
                scrollTop: null,
                scrollToIndex: nextProps.scrollToIndex,
            });
        }
    }

    public componentWillUnmount(): void {
        if (this.getScrollTop() !== this.props.scrollTop) {
            this.props.onScrollTopChanged(this.getScrollTop(), this.props);
        }
    }

    public render(): JSX.Element {
        const style = {
            ...this.props.style,
            willChange: "auto",
        };
        const { getAccordionItemFromFlatIdx, itemCount, totalHeight } = this.props;
        const avgRowHeight = itemCount > 0 ? totalHeight / itemCount : 0;
        return (
            <AutoSizer>
                {({ width, height }) => {
                    const containerStyle: CSSProperties = {
                        position: "relative",
                        overflow: "hidden",
                        width,
                        height,
                    };
                    let { scrollTop } = this.state;
                    if (
                        scrollTop == null &&
                        this.state.scrollToIndex != null &&
                        this.listRef != null &&
                        itemCount > this.state.scrollToIndex
                    ) {
                        // We cannot calculate this anywhere else, because this is the only place we know `height`.
                        // We scroll far enough that all children are visible, or if there's not enough
                        //   room for all children to be visible we scroll so that `scrollToItem` is at
                        //   the top
                        const scrollToItem = getAccordionItemFromFlatIdx(
                            this.state.scrollToIndex
                        )[0];
                        const allChildrenFit = scrollToItem.recHeight < height;

                        const index =
                            this.state.scrollToIndex +
                            (allChildrenFit ? scrollToItem.recChildCount : 0);
                        const alignment = allChildrenFit ? "auto" : "start";
                        scrollTop = this.listRef.getOffsetForRow({
                            alignment,
                            index,
                        });
                    }
                    if (height === 0 || totalHeight < height) {
                        scrollTop = null;
                        setTimeout(() => {
                            if (this.listRef != null) {
                                this.listRef.recomputeRowHeights(0);
                            }
                        }, 0);
                    }
                    return (
                        <div
                            style={containerStyle}
                            onScroll={scrollTop == null ? null : this.onScroll}
                        >
                            <List
                                scrollTop={scrollTop}
                                ref={(ref) => (this.listRef = ref)}
                                height={height}
                                width={width}
                                estimatedRowSize={avgRowHeight}
                                rowCount={this.props.itemCount}
                                rowHeight={this.getRowHeight}
                                rowRenderer={this.getRowElement}
                                cellRangeRenderer={this.cellRangeRenderer}
                                style={style}
                            />
                            <StickyItems
                                ref={(ref) => (this.stickyItemsRef = ref)}
                                onWheel={this.onStickyItemWheel}
                            />
                        </div>
                    );
                }}
            </AutoSizer>
        );
    }
}
