import React from "react";
import { Toolbar, ToolbarButton, ToolbarMenu, ToolbarTool } from "~/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { MapConfig, ScoutingZoneTools } from "@ai360/core";

export class ScoutingZoneToolSet extends ScoutingZoneTools {
    getToolbar(disabled = false): JSX.Element {
        this.setGettingToolbar(true);
        const { formatMessage } = this.options;
        const messages = this.options.messages;
        const toolsDisabled = this.mapView.scale > MapConfig.layers.fields.minScale;

        if ((disabled || toolsDisabled) !== this.disabled) {
            this.toggleTool({ selected: false }, false);
        }
        this.disabled = disabled;

        this.setGettingToolbar(false);
        return (
            <ToolbarMenu
                key="zones"
                className="zones"
                icon={ToolbarIcon(IconType.SPLIT)}
                disabled={this.disabled}
                label={formatMessage(messages.zonesLbl)}
                ref={(menu) => (this.menu = menu)}
            >
                <Toolbar className="zone-tools">
                    <ToolbarTool
                        key="zone-create"
                        className="zone-create"
                        icon={ToolbarIcon(IconType.POLYGON)}
                        disabled={toolsDisabled}
                        label={formatMessage(messages.drawPolygonLbl)}
                        onToggle={(evt) => this.toggleTool(evt)}
                        ref={(tool) => (this.zoneCreate = tool)}
                    />
                    <ToolbarTool
                        key="zone-point-create"
                        className="zone-point-create"
                        icon={ToolbarIcon(IconType.ADD_POINT)}
                        disabled={toolsDisabled}
                        label={formatMessage(messages.drawPointLbl)}
                        onToggle={(evt) => this.toggleTool(evt)}
                        ref={(tool) => (this.zonePointCreate = tool)}
                    />
                    <ToolbarButton
                        key="zone-delete"
                        className="zone-delete"
                        icon={ToolbarIcon(IconType.DELETE)}
                        disabled={toolsDisabled || !this.activeZone || this.zones.length <= 1}
                        label={formatMessage(messages.deleteLbl)}
                        onClick={() => this.deleteActiveZone()}
                    />
                    <ToolbarButton
                        className="zone-reset"
                        icon={ToolbarIcon(IconType.RESET)}
                        disabled={
                            toolsDisabled ||
                            this.onlyZoneIsWholeFieldZone() ||
                            this.entireFieldZones.length === 0
                        }
                        label={formatMessage(messages.resetLbl)}
                        onClick={() => this.resetZones()}
                    />
                </Toolbar>
            </ToolbarMenu>
        );
    }
}
