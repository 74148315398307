import React, { Component } from "react";
import { defineMessages, injectIntl } from "react-intl";
import classNames from "classnames";
import { connect } from "react-redux";

import { Menu } from "~/core";
import * as selectors from "~/action-panel/components/field-module/selectors";
import { selectors as cdSelectors } from "~/customer-data";
import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as recListSelectors from "~/action-panel/components/rec-module/components/rec-list/selectors";
import { messages as globalMessages } from "~/i18n-messages";

import { DeleteConfDialog } from "../item-delete-confirmation-dialog";

export interface IUserRoles {
    recommendations?: string;
    agEvents?: string;
    manageFields?: boolean;
}
export interface IBatchEdit {
    certifiedOrganic: boolean;
    cropGuid: string;
    cropPurposeGuid: string;
    drainageTile: unknown;
    irrigated: boolean;
    irrigationTypeGuid: string;
}

export interface IFieldAccordianContextMenuProps {
    fieldSelectionCount?: number;
    eventSelectionCount?: number;
    recSelectionCount?: number;
    isActiveTabOperationTab?: boolean;
    onDeleteSelected?: () => boolean;
    intl?: Record<string, any>;
    onSelectAll?: () => void;
    onClearAll?: () => void;
    onRecSelection?: () => void;
    onEventSelection?: () => void;
    onExpandAll?: () => void;
    onCollapseAll?: () => void;
    onExportBoundaries?: () => void;
    userRole?: IUserRoles;
    onBatchEditDetails?: (vals: IBatchEdit) => void;
}

export interface IFieldAccordianContextMenuState {
    isDeleteConfirmationOpen: boolean;
}

const messages = defineMessages({
    selectAllText: {
        id: "fieldAccordion.selectAllText",
        defaultMessage: "Select All",
    },
    batchEditText: {
        id: "fieldAccordion.batchEditText",
        defaultMessage: "Batch Edit Details",
    },
    clearAllText: {
        id: "fieldAccordion.clearAllText",
        defaultMessage: "Clear Selected",
    },
    recSelectionText: {
        id: "fieldAccordion.recSelectionText",
        defaultMessage: "Rec Selection",
    },
    eventSelectionText: {
        id: "fieldAccordion.eventSelectionText",
        defaultMessage: "Event Selection",
    },
    expandAllText: {
        id: "fieldAccordion.expandAllText",
        defaultMessage: "Expand All",
    },
    collapseAllText: {
        id: "fieldAccordion.collapseAllText",
        defaultMessage: "Collapse All",
    },
    deleteSelected: {
        id: "fieldAccordion.deleteSelected",
        defaultMessage: "Delete Selected",
    },
    exportBoundaries: {
        id: "fieldAccordion.exportBoundaries",
        defaultMessage: "Export Boundaries",
    },
    deleteConfirmationMessage: {
        id: "fieldAccordion.deleteConfirmationMessage",
        defaultMessage:
            "Deleting these fields will remove all of their associated data. Are you sure you want to delete the selected fields?",
    },
});

class FieldAccordionContextMenu_ extends Component<
    IFieldAccordianContextMenuProps,
    IFieldAccordianContextMenuState
> {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteConfirmationOpen: false,
        };
    }

    render() {
        const {
            fieldSelectionCount,
            eventSelectionCount,
            recSelectionCount,
            isActiveTabOperationTab,
            onDeleteSelected,
        } = this.props;
        const { formatMessage } = this.props.intl;

        const deleteConfDialog = !this.state.isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: messages.deleteConfirmationMessage,
                  formatMessage,
                  onConfirmationClick: (confirmation) => {
                      const afterClose = !confirmation ? () => null : () => onDeleteSelected();
                      this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                  },
              });

        const contextMenuItems = [];
        if (isActiveTabOperationTab) {
            contextMenuItems.push({
                key: 0,
                label: formatMessage(messages.selectAllText),
                action: () => this.props.onSelectAll(),
            });
        }
        contextMenuItems.push({
            key: 1,
            disabled: fieldSelectionCount === 0,
            label: formatMessage(messages.clearAllText),
            action: () => this.props.onClearAll(),
        });

        if (
            recSelectionCount > 0 &&
            isActiveTabOperationTab &&
            this.props.userRole.recommendations
        ) {
            contextMenuItems.push({
                key: 2,
                label: formatMessage(messages.recSelectionText),
                action: () => this.props.onRecSelection(),
            });
        }
        if (eventSelectionCount > 0 && isActiveTabOperationTab && this.props.userRole.agEvents) {
            contextMenuItems.push({
                key: 3,
                label: formatMessage(messages.eventSelectionText),
                action: () => this.props.onEventSelection(),
            });
        }
        contextMenuItems.push({
            key: 4,
            label: formatMessage(messages.expandAllText),
            action: () => this.props.onExpandAll(),
        });
        contextMenuItems.push({
            key: 5,
            label: formatMessage(messages.collapseAllText),
            action: () => this.props.onCollapseAll(),
        });
        if (fieldSelectionCount > 1) {
            contextMenuItems.push({
                key: 7,
                label: formatMessage(messages.batchEditText),
                action: () =>
                    this.props.onBatchEditDetails({
                        certifiedOrganic: null,
                        cropGuid: null,
                        cropPurposeGuid: null,
                        drainageTile: null,
                        irrigated: null,
                        irrigationTypeGuid: null,
                    }),
            });
        }

        if (isActiveTabOperationTab && this.props.userRole.manageFields) {
            contextMenuItems.push({
                key: 6,
                disabled: fieldSelectionCount === 0,
                label: formatMessage(messages.deleteSelected),
                action: () => this.setState({ isDeleteConfirmationOpen: true }),
            });
            contextMenuItems.push({
                key: 8,
                disabled: fieldSelectionCount === 0,
                label: formatMessage(messages.exportBoundaries),
                action: () => this.props.onExportBoundaries(),
            });
        }
        contextMenuItems.sort((a, b) => a.key - b.key);

        return (
            <div className="context-menu-container">
                <Menu
                    className={classNames(
                        "context-menu",
                        "accordion-context-menu",
                        "field-accordion-context-menu"
                    )}
                    isDotMenu={true}
                    menuItems={contextMenuItems}
                />
                {deleteConfDialog}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isActiveTabOperationTab: selectors.getActiveTabIsOperationTab(state),
    fieldSelectionCount: cdSelectors.getSelectedFieldsCount(state),
    eventSelectionCount: eventListSelectors.getSelectedEventGuidSet(state).size,
    recSelectionCount: recListSelectors.getSelectedRecGuidSet(state).size,
});

export const FieldAccordionContextMenu = connect(mapStateToProps)(
    injectIntl(FieldAccordionContextMenu_)
);
