import * as actions from "./actions";
import * as selectors from "./selectors";
import { fieldModuleReducer, initialFieldModel } from "./reducer";
import { fieldModuleSaga } from "./sagas";
import FieldModule from "./containers/field-module";

// TODO: update dependencies to only import `actions` and remove these exports:
const { saveFieldInformation, changeActiveTab } = actions;
// TODO: updated dependencies to only import `selectors` and remove these exports:
const { FIELD_MODULE_STATE_KEY, getActiveTab, getFieldInfoModel } = selectors;

export {
    actions,
    selectors,
    FIELD_MODULE_STATE_KEY,
    fieldModuleSaga,
    getActiveTab,
    getFieldInfoModel,
    saveFieldInformation,
    changeActiveTab,
    fieldModuleReducer,
    initialFieldModel,
    FieldModule
};
