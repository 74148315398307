import { createSelector } from "reselect";

import { selectors as uploaderSelectors } from "~/action-panel/components/common/drag-and-drop-file-uploader";
import { BATCH_TEMPLATE_FIELD_GUID } from "~/recs-events/model";
import * as recsSelectors from "~/recs-events/recs/selectors";

import { getModuleState as getRecInfoModuleState } from "../../../selectors";
import { models } from "./";
import { messages } from "./i18n-messages";

export const RX_FILE_IMPORT_KEY = "RX_FILE_IMPORT_KEY";

export const getModuleState = (state): models.IRXFileImportState => {
    return getRecInfoModuleState(state)[RX_FILE_IMPORT_KEY] as models.IRXFileImportState;
};

export const getRecFieldGuids = createSelector(recsSelectors.getModuleState, (state) => {
    const fields = [...state.fieldGuidToRecDetails.keys()];
    return fields.filter((fieldGuid) => fieldGuid !== BATCH_TEMPLATE_FIELD_GUID);
});

export const getMatchData = createSelector(getModuleState, (state) =>
    state.matchDataCurrent ? state.matchData : null
);

export const getColumns = createSelector(
    getMatchData,
    getRecFieldGuids,
    (state) => uploaderSelectors.getModuleState(state, models.RX_FILE_IMPORT_STATE_KEY),
    (matchData, selectedFieldGuids, uploaderState) => {
        const { uploadFileInfoMap } = uploaderState;
        if (!matchData || uploadFileInfoMap.size === 0 || selectedFieldGuids.length === 0) {
            return [];
        }
        const { matches } = matchData;
        let columns = [];
        // If we only have a single field, all files should match the field,
        // but there might be multiple files with the same column
        if (selectedFieldGuids.length === 1) {
            // Get all the columns for the field from matches (match.fieldGuid)
            // Concatenate duplicate columns from different files with the filename at the end
            // Add the column to the columns array
            const columnCount = {};
            matches
                .map((m) => m.columns)
                .flat()
                .forEach((column) => {
                    if (!columnCount[column]) {
                        columnCount[column] = 0;
                    }
                    columnCount[column]++;
                });
            Object.keys(columnCount).map((column) => {
                if (columnCount[column] > 1) {
                    matches.forEach((match) => {
                        const col = match.columns.find((c) => c === column);
                        if (col) {
                            const file = uploadFileInfoMap.get(match.importFileGuid);
                            columns.push({
                                label: `${column} (${file.fileName})`,
                                value: {
                                    column,
                                    importFileGuid: match.importFileGuid,
                                },
                            });
                        }
                    });
                } else {
                    columns.push({
                        label: column,
                        value: {
                            column,
                            importFileGuid: null,
                        },
                    });
                }
            });
        } else {
            // Multiple fields selected, find the columns for all of them
            const fieldToColumns = selectedFieldGuids.reduce((acc, fieldGuid) => {
                acc[fieldGuid] = [];
                return acc;
            }, {});
            matches.forEach((match) => {
                match.fieldGuids.forEach((fieldGuid) => {
                    if (!fieldToColumns[fieldGuid]) {
                        fieldToColumns[fieldGuid] = {};
                    }
                    match.columns.forEach((column) => {
                        fieldToColumns[fieldGuid][column] = true;
                    });
                });
            });
            // Find the columns that are in all the fields
            const stringColumns = Object.values(fieldToColumns).reduce(
                (acc: string[], columnObject: { [columnName: string]: boolean }) => {
                    const columns = Object.keys(columnObject);
                    if (acc.length === 0) {
                        return columns;
                    }
                    return acc.filter((column) => columns.includes(column));
                },
                []
            ) as string[];
            columns = stringColumns.map((column) => ({
                label: column,
                value: {
                    column,
                    importFileGuid: null,
                },
            }));
        }
        return columns;
    }
);

export const getRecNutrientsList = createSelector(recsSelectors.getModuleState, (state) => {
    const firstRec = [...state.fieldGuidToRecDetails.values()][0];
    if (!firstRec) {
        return [];
    }
    return firstRec.recAreaList[0].recs[0].recNutrientList;
});

export const getFieldMatchingErrors = createSelector(
    getModuleState,
    getColumns,
    recsSelectors.getModuleState,
    getRecNutrientsList,
    (state) => uploaderSelectors.getModuleState(state, models.RX_FILE_IMPORT_STATE_KEY),
    (state: models.IRXFileImportState, columns, recsState, recNutrientList, uploaderState) => {
        const { fieldGuidToFieldMap } = recsState;
        const { fileUploadComplete, importFileCount } = uploaderState;
        const errorMessages = [];
        if (!state.matchDataCurrent || !fileUploadComplete || importFileCount === 0) {
            // We don't need to show errors before they try matching anything.
            return errorMessages;
        }
        if (state.matchData.nonMatchingFields.length > 0) {
            const fieldNames = state.matchData.nonMatchingFields
                .map((fieldGuid) => fieldGuidToFieldMap.get(fieldGuid).name)
                .join(", ");
            errorMessages.push([messages.validationFieldsWithoutFilesText, { fieldNames }]);
        }
        return errorMessages;
    }
);

export const getIsRxFileImportStepUpload = (state) =>
    getModuleState(state).currentStep === "upload";

export const getIsRxFileImportComplete = (state) =>
    getModuleState(state).currentStep === "complete";

export const getIsNextActionEnabled = createSelector(
    getModuleState,
    getColumns,
    (state) => uploaderSelectors.getModuleState(state, models.RX_FILE_IMPORT_STATE_KEY),
    getRecNutrientsList,
    getFieldMatchingErrors,
    (state, columns, uploaderState, recNutrientList, fieldMatchingErrors) => {
        const { currentStep, filesValidated, selectedColumns } = state;
        const { fileUploadComplete, filesWithErrorsCount, importFileCount } = uploaderState;
        if (currentStep === "upload") {
            // If there are files to be uploaded, next action will be to upload them
            if (importFileCount > 0 && !fileUploadComplete) {
                return filesWithErrorsCount === 0;
            }
            return fieldMatchingErrors.length === 0 && filesValidated && filesWithErrorsCount === 0;
        }
        const hasProducts = recNutrientList.every(
            (recNutrient) =>
                recNutrient.recNutrientProductMix &&
                (recNutrient.recNutrientProductMix.name ||
                    recNutrient.recNutrientProductMix.products.length > 0)
        );
        return selectedColumns.length >= 1 && hasProducts;
    }
);

export const getHasEnoughColumns = createSelector(
    getColumns,
    getRecNutrientsList,
    (columns, recNutrientList) => {
        return columns.length >= recNutrientList.length;
    }
);

export const getSelectedColumns = (state) => getModuleState(state).selectedColumns;
