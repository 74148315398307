import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import * as layerSelectors from "../layer-list/selectors";
import { RecEventItem } from "~/action-panel/components/common/accordion/rec-event-accordion-item";
import { RecEventInfo } from "~/action-panel/components/common/rec-event-info/rec-event-info";
import { Pane, Loader } from "~/core";

import * as actions from "./actions";
import * as imageryForm from "./imagery-form";
import * as selectors from "./selectors";

export class FormLabel_ extends PureComponent {
    static propTypes = {
        hasError: PropTypes.bool.isRequired,
        intl: intlShape,
        labelIconEl: PropTypes.element.isRequired,
        labelMessage: PropTypes.object.isRequired,
    };

    render() {
        const { hasError, labelIconEl, labelMessage } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div
                className={classnames("rec-event-info-tab-label", {
                    "has-error": hasError,
                })}
            >
                {labelIconEl}
                <div className="tab-nav-text">{formatMessage(labelMessage)}</div>
            </div>
        );
    }
}

export const FormLabel = injectIntl(FormLabel_);

export class ImageryLayer_ extends PureComponent {
    static propTypes = {
        fieldImageryLayers: PropTypes.any,
        imageryLayerSummary: PropTypes.object.isRequired,
        customer: PropTypes.object,
        enableSave: PropTypes.bool.isRequired,
        field: PropTypes.object,
        intl: intlShape.isRequired,
        isLoading: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSaveChanges: PropTypes.func.isRequired,
    };

    _getEventStripItem() {
        const { imageryLayerSummary, isLoading } = this.props;
        if (isLoading) {
            return (
                <div className="item-container">
                    <Loader />;
                </div>
            );
        }
        const { croppingSeasonName, imageDate } = imageryLayerSummary;
        const itemProps = {
            displayName: ["Imagery ", croppingSeasonName, imageDate]
                .filter((v) => v && v !== "")
                .join("- "),
            summary: imageryLayerSummary.name,
            contextMenu: null,
            deselectItemsFromDimIdx: () => null,
            isExpanded: false,
            itemDimIdx: [],
            lastClickedDimIdx: [],
            setLastClickedDimIdx: () => null,
            selectItemsFromDimIdx: () => null,
            isSelected: true,
        };
        return (
            <div className="item-container">
                <RecEventItem {...itemProps} />
            </div>
        );
    }

    _getPanes() {
        const panes = [];
        const { fieldImageryLayers, imageryLayerSummary, isLoading } = this.props;
        const [EventEditForm, labelMessage, LabelIcon] = [
            imageryForm.ImageryForm,
            imageryForm.formLabelMessage,
            imageryForm.formLabelIcon,
        ];
        const label = (
            <FormLabel
                canRemove={false}
                hasError={false}
                labelMessage={labelMessage}
                labelIconEl={<LabelIcon />}
            />
        );
        panes.push(
            <Pane key={"imagery"} label={label}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <EventEditForm
                        fieldImageryLayers={fieldImageryLayers}
                        imageryLayerSummary={imageryLayerSummary}
                    />
                )}
            </Pane>
        );
        return panes;
    }

    render() {
        const { enableSave, isLoading, onClose, onSaveChanges } = this.props;

        return (
            <RecEventInfo
                addItemTitle={""}
                enableSave={enableSave}
                errorCodeList={[]}
                eventRecItemStrip={this._getEventStripItem()}
                informationPanes={this._getPanes()}
                isBatchTemplate={false}
                isLoading={isLoading}
                isNew={false}
                addMenuItems={[]}
                canAddEvent={false}
                onSave={onSaveChanges}
                onCancel={onClose}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(actions.closeImageryLayer()),
    onSaveChanges: () => dispatch(actions.saveImageryLayer()),
});

const mapStateToProps = (state) => {
    const imageryState = selectors.getModuleState(state);

    const { imageryLayerSummary, isLoading } = imageryState;

    let fieldImageryLayers = [];
    const layerInfos = layerSelectors.getLayerInfos(state);

    for (const layers of layerInfos.values()) {
        const imageryPresent = layers.some(
            (li) => li.imageryLayerGuid === imageryLayerSummary.imageryLayerGuid
        );

        if (imageryPresent) {
            Object.entries(layers).forEach(([, value]) => {
                if (value.layerType === "Imagery") {
                    fieldImageryLayers.push(value);
                }
            });
        }
    }

    const enableSave =
        !isLoading &&
        imageryLayerSummary.name &&
        imageryLayerSummary.croppingSeasonGuid &&
        imageryLayerSummary.imageDate;

    return {
        fieldImageryLayers,
        imageryLayerSummary,
        enableSave,
        isLoading,
    };
};

export const ImageryLayer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImageryLayer_));
