import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { intlShape } from "react-intl";

import { Bucket, BucketHeader, Checkbox, Menu } from "~/core";
import { ImportFileInfo } from "~/file-import/model";
import { config as intlConfig } from "~/intl-provider/config";

import { messages } from "../../../i18n-messages";
import { AddEditFieldModal } from "../containers/add-edit-field-modal";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";

export class FieldBoundaryPanel_ extends Component {
    static propTypes = {
        agvanceFieldList: PropTypes.array,
        agvanceOrgLevelList: PropTypes.array,
        canSubmit: PropTypes.bool.isRequired,
        closeImportWizard: PropTypes.func.isRequired,
        fetchAgvanceFieldList: PropTypes.func.isRequired,
        fetchAgvanceOrgLevelList: PropTypes.func,
        getImportFileInfo: PropTypes.func.isRequired,
        ignoreFarm: PropTypes.bool.isRequired,
        importFieldBoundaries: PropTypes.array,
        importFieldList: PropTypes.array,
        intl: intlShape.isRequired,
        isLockCustomer: PropTypes.bool,
        loadDetailPage: PropTypes.func,
        personalityId: PropTypes.number.isRequired,
        selectedImportFieldIndex: PropTypes.number.isRequired,
        setIgnoreFarm: PropTypes.func.isRequired,
        setImportFieldList: PropTypes.func.isRequired,
        setSelectedImportFieldIndex: PropTypes.func.isRequired,
        updateCanSubmit: PropTypes.func.isRequired,
        updateImportBoundary: PropTypes.func.isRequired,
        updateImportFileInfos: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            agvanceFieldList: [],
            customerGuid: null,
            editingRecord: -1,
            isAgvanceCustomer: AgvanceUtils.isAgvanceConnected(this.props.personalityId),
        };
    }

    componentDidMount() {
        this._hackyRefresh();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.importFieldList.length === 0 && this.props.importFieldList.length !== 0) {
            this.props.closeImportWizard();
        }
        if (!nextProps.importFieldList || !this.props.importFieldList) {
            this.props.setSelectedImportFieldIndex(-1);
        }
        const isValid = this.isValidImport(
            nextProps.importFieldList,
            nextProps.importFieldBoundaries
        );
        if (nextProps.canSubmit !== isValid) {
            this.props.updateCanSubmit(isValid);
        }
        if (nextProps.agvanceFieldList !== this.props.agvanceFieldList) {
            this.setState({
                agvanceFieldList: nextProps.agvanceFieldList,
            });
        }
        if (nextProps.agvanceOrgLevelList !== this.props.agvanceOrgLevelList) {
            this.setState({
                agvanceOrgLevelList: nextProps.agvanceOrgLevelList,
            });
        }
    }

    _hackyRefresh() {
        // 100% Hack to force the UI to recalculate the flex heights and respect them in IE/FF
        // NOTE: this.forceUpdate() does not do what we want
        const { selectedImportFieldIndex } = this.props;
        setTimeout(() => {
            this.props.setSelectedImportFieldIndex(-1);
            setTimeout(() => {
                this.props.setSelectedImportFieldIndex(selectedImportFieldIndex);
            });
        });
    }

    isRecordSelected(index) {
        return index === this.props.selectedImportFieldIndex;
    }

    isValidImport(importFieldList, importFieldBoundaries) {
        const { isLockCustomer } = this.props;
        const filteredImportFieldList = isLockCustomer
            ? importFieldList.filter((field) => {
                  return field.enrolledYn === true || field.noMatch === true;
              })
            : importFieldList;
        return (
            filteredImportFieldList &&
            importFieldBoundaries &&
            filteredImportFieldList.some((fb) => {
                const ifb = importFieldBoundaries.filter((boundary) => {
                    return (
                        fb.importFieldBoundaryGuidList.includes(boundary.importFieldBoundaryGuid) &&
                        !boundary.isSpatialConflict &&
                        !boundary.isNameConflict
                    );
                });
                return (
                    ifb.length === fb.importFieldBoundaryGuidList.length &&
                    fb.customerGuid &&
                    fb.fieldName
                );
            })
        );
    }

    onCloseEditCustomerField() {
        this.setState({
            editingRecord: -1,
            customerGuid: "",
            customerName: "",
        });
    }

    onEditCustomerField(index) {
        this.setState({
            editingRecord: index,
        });
        if (this.props.selectedImportFieldIndex !== index) {
            this.props.setSelectedImportFieldIndex(index);
        }
    }

    onCustomerClose = () => {
        this.setState({
            editingRecord: this.state.prevEditingRecord,
            prevEditingRecord: -1,
        });
    };

    onCustomerSaveSuccess = (customerData) => {
        if (this.state.isAgvanceCustomer) {
            this.props.fetchAgvanceFieldList(customerData.customerGuid);
        }
        this.setState({
            customerGuid: customerData.customerGuid,
            customerName: customerData.name,
        });
    };

    onCustomerSaveFailed = (customerData) => {
        console.assert(Object.keys(customerData).length > 0);
        console.error("There was a problem while saving the customer", customerData);
    };

    onAddNewCustomer = (customerName) => {
        this.setState({
            editingRecord: -1,
            prevEditingRecord: this.state.editingRecord,
        });
        this.props.loadDetailPage("213", "101", {
            onCloseSlidingPanel: this.onCustomerClose,
            onSaveSuccess: this.onCustomerSaveSuccess,
            onSaveFailed: this.onCustomerSaveFailed,
            customerName,
        });
    };

    onRemoveField(index) {
        let newImportFieldList = [
            ...this.props.importFieldList.slice(0, index),
            ...this.props.importFieldList.slice(index + 1),
        ];

        if (this.isRecordSelected(index)) {
            this.props.setSelectedImportFieldIndex(-1);
        }
        this.props.setImportFieldList(newImportFieldList);
    }

    onSaveEditCustomerField(data, callback) {
        const { getImportFileInfo, ignoreFarm, importFieldList, updateImportFileInfos } =
            this.props;
        const { editingRecord, isAgvanceCustomer } = this.state;
        const {
            editCustomerGuid,
            editCustomerName,
            editFarmName,
            editFieldName,
            fieldEditGeometries,
            fieldEditsChanged,
            agvanceFieldGuid,
            agvanceFieldOrgLevelGuid,
            agvanceCustomerGuid,
        } = data;
        const originalRecord = importFieldList[editingRecord];
        const originalBoundaryCount = importFieldList.reduce(
            (c, fb) => c + fb.importFieldBoundaryGuidList.length,
            0
        );
        const isClearingFarmName = editFarmName == null || editFarmName === "";

        const agvanceCustomerInfo = {
            agvanceFieldOrgLevelGuid,
            agvanceCustomerGuid,
        };
        const agvanceData = isAgvanceCustomer
            ? agvanceFieldGuid != null
                ? { agvanceFieldGuid, ...agvanceCustomerInfo }
                : agvanceCustomerInfo
            : {};

        const request = {
            batchUpdate: {
                customerGuid: editCustomerGuid,
                where: {
                    importFileGuidList: Array.from(
                        new Set(importFieldList.map((f) => f.importFileGuid))
                    ),
                    oldCustomerName: originalRecord.customerName,
                },
            },
        };
        if (editFarmName !== originalRecord.farmName) {
            if (isClearingFarmName) {
                request.batchUpdate.importFileGuid = originalRecord.importFileGuid;
                request.batchUpdate.fieldName = originalRecord.fieldName;
            }
            request.batchUpdate.where.oldFarmName = originalRecord.farmName;
            request.batchUpdate.farmName = editFarmName || "";
        }
        if (isAgvanceCustomer) {
            request.batchUpdate.agvanceCustomerGuid = agvanceCustomerGuid;
            request.batchUpdate.agvanceOrgLevelGuid = agvanceFieldOrgLevelGuid;
        }
        if (
            editFieldName !== originalRecord.fieldName ||
            fieldEditsChanged ||
            (isAgvanceCustomer && agvanceCustomerGuid !== originalRecord.agvanceCustomerGuid)
        ) {
            request.fieldUpdate = {
                newFieldName: editFieldName,
                where: {
                    importFileGuid: originalRecord.importFileGuid,
                    customerName: editCustomerName,
                    farmName: editFarmName,
                    fieldName: originalRecord.fieldName,
                },
            };
            if (isAgvanceCustomer && agvanceFieldGuid != null) {
                request.fieldUpdate.agvanceFieldGuid = agvanceFieldGuid;
            }
            if (fieldEditsChanged && fieldEditGeometries.length > 0) {
                request.fieldUpdate.polygons = fieldEditGeometries.map((g) => g.toJSON());
            }
        }

        request.ignoreFarm = ignoreFarm;
        this.props.updateImportBoundary(request, (importFieldBoundaryGuidList) => {
            const editRecord = {
                ...importFieldList[editingRecord],
                fieldName: editFieldName,
                farmName: editFarmName,
                customerName: editCustomerName,
                customerGuid: editCustomerGuid,
                ...agvanceData,
            };
            if (importFieldBoundaryGuidList) {
                editRecord.importFieldBoundaryGuidList = importFieldBoundaryGuidList;
            }
            // update customer and farm name locally without the need to fetch
            const updatedRecords = importFieldList.map((field) => ({
                ...field,
                customerName:
                    field.customerName === originalRecord.customerName
                        ? editCustomerName
                        : field.customerName,
                customerGuid:
                    field.customerName === originalRecord.customerName
                        ? editCustomerGuid
                        : field.customerGuid,
                farmName:
                    !isClearingFarmName &&
                    field.farmName === originalRecord.farmName &&
                    editFarmName !== originalRecord.farmName
                        ? editFarmName
                        : field.farmName,
            }));
            const newImportData = [
                ...updatedRecords.slice(0, editingRecord),
                editRecord,
                ...updatedRecords.slice(editingRecord + 1),
            ];

            const newBoundaryCount = newImportData.reduce(
                (c, fb) => c + fb.importFieldBoundaryGuidList.length,
                0
            );
            const delta = newBoundaryCount - originalBoundaryCount;
            if (delta !== 0) {
                const importFileInfo = getImportFileInfo(originalRecord.importFileGuid);
                updateImportFileInfos([
                    Object.assign(new ImportFileInfo(), {
                        ...importFileInfo,
                        totalPoints: importFileInfo.totalPoints + delta,
                    }),
                ]);
            }

            this.props.setImportFieldList(newImportData);
            if (callback) {
                callback(importFieldBoundaryGuidList);
            }
        });
    }

    toggleRecord(evt, index) {
        if (
            this.state.editingRecord === -1 &&
            !(
                evt.target.className.indexOf("context-menu") > -1 ||
                ["menu-dots", "menu-dot", "menu-item"].includes(evt.target.className) ||
                evt.target.parentNode.className === "menu-item"
            )
        ) {
            const newSelected = this.isRecordSelected(index) ? -1 : index;
            this.props.setSelectedImportFieldIndex(newSelected);
        }
    }

    render() {
        const { formatMessage, formatNumber } = this.props.intl;
        const { importFieldList, isLockCustomer } = this.props;
        const filteredImportFieldList = isLockCustomer
            ? importFieldList.filter((field) => {
                  return field.enrolledYn === true || field.noMatch === true;
              })
            : importFieldList;

        const importFileListGuids = filteredImportFieldList.map((field) => field.importFileGuid);
        //TODO (post-remediation): consider refactoring this to use an accordion
        const importFieldListItems = filteredImportFieldList.map((fieldBoundary, index) => {
            const contextMenuItems = [
                {
                    key: 0,
                    label: formatMessage(messages.editCustomerFieldText),
                    action: () => this.onEditCustomerField(index),
                },
                {
                    key: 1,
                    label: formatMessage(messages.removeFieldText),
                    action: () => this.onRemoveField(index),
                },
            ];
            const isEditing = this.state.editingRecord === index;
            const isNoMatch = fieldBoundary.customerGuid === "";
            const isSpatialConflict = this.props.importFieldBoundaries.some((fb) => {
                return (
                    fieldBoundary.importFieldBoundaryGuidList.includes(
                        fb.importFieldBoundaryGuid
                    ) && fb.isSpatialConflict
                );
            });
            let importFieldList = this.props.importFieldList[this.state.editingRecord];

            if (this.state.customerGuid && this.state.customerName) {
                importFieldList = {
                    ...importFieldList,
                    customerGuid: this.state.customerGuid,
                    customerName: this.state.customerName,
                };
            }

            return (
                <div
                    key={index}
                    className={classNames("ifb-row", {
                        selected: this.isRecordSelected(index),
                    })}
                >
                    <div
                        className={classNames("ifb-strip", {
                            editing: isEditing,
                            "no-match": isNoMatch,
                            "spatial-conflict": isSpatialConflict,
                            selected: this.isRecordSelected(index),
                        })}
                        onClick={(evt) => this.toggleRecord(evt, index)}
                    >
                        <div className="space field-selected-column">
                            <div className="field-selected-div">
                                <div className="field-selected-div"></div>
                            </div>
                        </div>
                        <div className="column1" title={fieldBoundary.customerName}>
                            {fieldBoundary.customerName}
                        </div>
                        {!this.props.ignoreFarm ? (
                            <div className="column" title={fieldBoundary.farmName}>
                                {fieldBoundary.farmName}
                            </div>
                        ) : null}
                        <div className="column" title={fieldBoundary.fieldName}>
                            {fieldBoundary.fieldName}
                        </div>
                        <div className="acres-column" title={fieldBoundary.acres}>
                            {formatNumber(fieldBoundary.acres, intlConfig.numberFormatOptions)}
                        </div>
                        <div className="space">
                            <Menu
                                className={classNames("field-boundary-context-menu")}
                                isDotMenu={true}
                                menuItems={contextMenuItems}
                            />
                        </div>
                    </div>
                    {!this.isRecordSelected(index) ? null : (
                        <div className="ifb-file" title={fieldBoundary.importFileName}>
                            <div className="ifb-file-name">{fieldBoundary.importFileName}</div>
                        </div>
                    )}
                    {index !== this.state.editingRecord ? null : (
                        <AddEditFieldModal
                            record={importFieldList}
                            onSave={(data, callback) =>
                                this.onSaveEditCustomerField(data, () => {
                                    this._hackyRefresh();
                                    callback();
                                })
                            }
                            onClose={() => this.onCloseEditCustomerField()}
                            toolsetPayload={{
                                customerGuid:
                                    this.props.importFieldList[this.state.editingRecord]
                                        .customerGuid,
                                importFieldBoundaryGuidList:
                                    this.props.importFieldList[this.state.editingRecord]
                                        .importFieldBoundaryGuidList,
                            }}
                            onAddNewCustomer={this.onAddNewCustomer}
                            fetchAgvanceFieldList={this.props.fetchAgvanceFieldList}
                            isAgvanceCustomer={this.state.isAgvanceCustomer}
                            agvanceFieldList={this.state.agvanceFieldList}
                            fetchAgvanceOrgLevelList={this.props.fetchAgvanceOrgLevelList}
                            agvanceOrgLevelList={this.state.agvanceOrgLevelList}
                            removeImportFieldBoundary={() =>
                                this.onRemoveField(this.state.editingRecord)
                            }
                        />
                    )}
                </div>
            );
        });

        return (
            <div className="field-boundary-panel">
                <div className="ignore-farm-checkbox">
                    <Checkbox
                        value={this.props.ignoreFarm}
                        label={formatMessage(messages.ignoreFarm)}
                        onChange={(evt, val) => this.props.setIgnoreFarm(importFileListGuids, val)}
                    />
                </div>
                <Bucket isExpanded={true} isCollapsible={false} showSymbol={false}>
                    <BucketHeader className={"import-field-boundary-header"}>
                        <div className="space"></div>
                        <div className="column1">Customer</div>
                        {this.props.ignoreFarm ? null : <div className="column">Farm</div>}
                        <div className="column">Field</div>
                        <div className="acres-column">Acres</div>
                        <div className="space"></div>
                    </BucketHeader>
                    {!this.props.importFieldList ? null : importFieldListItems}
                </Bucket>
            </div>
        );
    }
}
