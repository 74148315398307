import { OrderedMap, Set } from "immutable";
import Immutable from "immutable";

import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import * as models from "./models";
import { NonFieldFeature } from "@ai360/core/dist/4x/es/api/non-field-feature";

import { SearchAPI } from "@ai360/core";
import _ from "lodash";
import { FieldListTabs } from "~/action-panel/components/field-module/actions";

export type CustomerDataAction = ActionType<typeof actions>;

const modifyMap = <TId, TValue>(
    map: Immutable.OrderedMap<TId, TValue>,
    modifications: actions.IMapModifications<TId, TValue>
) => {
    const merge = modifications.merge ?? [];
    const remove = modifications.remove ?? [];
    return map.removeAll(remove).merge(merge);
};

const modifySet = <TValue>(
    set: Immutable.Set<TValue>,
    modifications: actions.ISetModifications<TValue>
) => {
    const merge = modifications.merge ?? [];
    const remove = modifications.remove ?? [];
    return set.subtract(remove).union(merge);
};

const newInitialState = (): models.CustomerDataState =>
    Object.freeze({
        customerMap: OrderedMap<string, models.CustomerInfo>(),
        fieldMap: OrderedMap<string, models.FieldInfo>(),
        customerFieldMappings: [],
        orgLevels: new Map<string, models.OrgLevelInfo>(),
        selectedFields: Set<string>(),
        searchFoundFieldGuidSet: Set<string>(),
        enrolledFieldGuids: Set<string>(),
        nonFieldFeatureMap: Immutable.Map<any, models.NonFieldFeatureInfo>(),
        fetchingCustomerFields: false,
        lastCustomerFieldPageId: null,
        isDoneFetchingCustomers: false,
        fetchingSummary: false,
        quickSummary: {
            customers: 0,
            acres: 0,
            fields: 0,
            events: 0,
            recs: 0,
        },
        filteredFieldGuids: Set<string>(),
        autoExpandedCustomers: Set<string>(),
    });

const setNonFieldFeatureSubInfo = (
    setter: (info: models.NonFieldFeatureInfo, setTo: boolean) => void,
    state: models.CustomerDataState,
    setToFeatures?: Immutable.Set<NonFieldFeature>
) => {
    const featureSet = Set(setToFeatures ? setToFeatures : []);

    const nonFieldFeatureMap = Immutable.Map(state.nonFieldFeatureMap);

    for (const [, info] of nonFieldFeatureMap.entries()) {
        setter(info, featureSet.has(info.feature));
    }

    return Object.freeze({
        ...state,
        nonFieldFeatureMap,
    });
};

const modifyNonFieldFeatureSubInfo = (
    setter: (info: models.NonFieldFeatureInfo, setTo: boolean) => void,
    state: models.CustomerDataState,
    modifications: { add?: any[]; remove?: any[] }
) => {
    const add = Set(modifications.add ? modifications.add : []);
    const remove = Set(modifications.remove ? modifications.remove : []);

    const nonFieldFeatureMap = Immutable.Map(state.nonFieldFeatureMap);

    for (const [, info] of nonFieldFeatureMap.entries()) {
        if (add.has(info.feature)) {
            setter(info, true);
        } else if (remove.has(info.feature)) {
            setter(info, false);
        }
    }

    return Object.freeze({
        ...state,
        nonFieldFeatureMap,
    });
};

export const customerDataReducer = (
    state: models.CustomerDataState,
    action: CustomerDataAction
): models.CustomerDataState => {
    if (state == null) {
        return newInitialState();
    }

    switch (action.type) {
        case actions.ACTIVATE_CUSTOMER: {
            const { customerGuid } = action.payload;

            return Object.freeze({
                ...state,
                customerMap: state.customerMap.delete(customerGuid),
                fieldMap: state.fieldMap.filter((x) => x.customerGuid !== customerGuid),
            });
        }
        case actions.ADD_CUSTOMER_FIELD_MAPPINGS: {
            return Object.freeze({
                ...state,
                customerFieldMappings: _(state.customerFieldMappings)
                    .concat(action.payload.mappings)
                    .uniqWith(models.equalCustomerFieldMappings)
                    .value(),
            });
        }
        case actions.ADD_ENROLLED_FIELDS: {
            const { fieldGuids } = action.payload;

            const fieldMap = state.fieldMap.withMutations((map) => {
                for (const field of map.values()) {
                    if (fieldGuids.includes(field.fieldGuid)) {
                        map.set(field.fieldGuid, models.FieldInfo.enroll(field, true));
                    }
                }
            });

            return Object.freeze({
                ...state,
                fieldMap,
            });
        }
        case actions.ADD_SELECTED_FIELDS: {
            const { fieldGuids } = action.payload;
            return Object.freeze({
                ...state,
                selectedFields: state.selectedFields.union(fieldGuids),
            });
        }
        case actions.ADD_UPDATE_CUSTOMER: {
            const { customer } = action.payload;

            return Object.freeze({
                ...state,
                customerMap: state.customerMap
                    .set(customer.customerGuid, customer)
                    .sort(models.compareCustomers),
                fieldMap: customer.activeYn
                    ? state.fieldMap
                    : state.fieldMap.filter((x) => x.customerGuid === customer.customerGuid),
            });
        }
        case actions.BATCH_UPDATE_FIELD: {
            const { fields, customers, autoExpandedCustomersModifications, activeTab } =
                action.payload;

            if (activeTab === FieldListTabs.SELECTED) {
                return Object.freeze({
                    ...state,
                });
            }

            const isActiveTab = activeTab === FieldListTabs.ACTIVE;
            const fieldsToAddOrUpdate: [string, models.FieldInfo][] = fields
                .filter((x) => x.activeYn === isActiveTab)
                .map((x) => [x.fieldGuid, x]);
            const fieldsToDelete = fields.filter((x) => x.activeYn !== isActiveTab);
            const fieldGuidsToDelete = fieldsToDelete.map((x) => x.fieldGuid);

            const newFieldMap = state.fieldMap
                .merge(fieldsToAddOrUpdate)
                .deleteAll(fieldGuidsToDelete);

            const customersToUpdate: [string, models.CustomerInfo][] = customers
                .filter((x) => x.activeYn === isActiveTab)
                .map((x) => [x.customerGuid, x]);

            const newCustomerMap = state.customerMap.merge(customersToUpdate);

            return Object.freeze({
                ...state,
                customerMap: newCustomerMap,
                fieldMap: newFieldMap,
                autoExpandedCustomers: modifySet(
                    state.autoExpandedCustomers,
                    autoExpandedCustomersModifications
                ),
            });
        }
        case actions.BATCH_UPDATE_FIELD_EVENT_COUNT: {
            const fieldMap = state.fieldMap.withMutations((map) => {
                for (const item of action.payload) {
                    map.set(
                        item.fieldGuid,
                        models.FieldInfo.updateEventCount(map.get(item.fieldGuid), item.eventCount)
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldMap,
            });
        }
        case actions.BATCH_UPDATE_FIELD_REC_COUNT: {
            const fieldMap = state.fieldMap.withMutations((map) => {
                for (const item of action.payload) {
                    if (!map.has(item.fieldGuid)) {
                        continue;
                    }

                    map.set(
                        item.fieldGuid,
                        models.FieldInfo.updateRecCount(map.get(item.fieldGuid), item.recCount)
                    );
                }
            });

            return Object.freeze({
                ...state,
                fieldMap,
            });
        }
        case actions.CLEAR_ALL_SELECTED_FIELDS:
            return Object.freeze({
                ...state,
                selectedFields: Set<string>(),
            });
        case actions.CLEAR_ENROLLED_FIELDS: {
            const { fieldGuids } = action.payload;
            return Object.freeze({
                ...state,
                enrolledFieldGuids: state.enrolledFieldGuids.subtract(fieldGuids),
            });
        }
        case actions.CLEAR_SELECTED_FIELDS: {
            const { fieldGuids } = action.payload;
            return Object.freeze({
                ...state,
                selectedFields: state.selectedFields.subtract(fieldGuids),
            });
        }
        case actions.DEACTIVATE_CUSTOMER: {
            const { customerGuid } = action.payload;

            return Object.freeze({
                ...state,
                customerMap: state.customerMap.delete(customerGuid),
                fieldMap: state.fieldMap.filter((x) => x.customerGuid !== customerGuid),
            });
        }
        case actions.FETCHING_CUSTOMER_FIELDS: {
            return Object.freeze({
                ...state,
                customerFieldAbortController: action.payload.abortController,
                fetchingCustomerFields: true,
            });
        }
        case actions.FETCH_CUSTOMER_FIELDS_SUCCESS: {
            const { customers, autoExpandedCustomers, lastPageId, isDone, restart } =
                action.payload;

            const infos: [string, models.CustomerInfo][] = customers.map((result) => [
                result.customerId,
                models.CustomerInfo.fromCustomerField(result),
            ]);

            return Object.freeze({
                ...state,
                customerMap: restart ? Immutable.OrderedMap(infos) : state.customerMap.merge(infos),
                fieldMap: restart
                    ? Immutable.OrderedMap<string, models.FieldInfo>()
                    : state.fieldMap,
                autoExpandedCustomers: restart
                    ? Set<string>(autoExpandedCustomers)
                    : state.autoExpandedCustomers.concat(autoExpandedCustomers),
                lastCustomerFieldPageId: lastPageId,
                isDoneFetchingCustomers: isDone,
                fetchingCustomerFields: false,
            });
        }
        case actions.FETCH_CUSTOMER_FIELDS_ERROR: {
            return Object.freeze({
                ...state,
                fetchingCustomerFields: false,
            });
        }
        case actions.FETCH_FIELDS_SUCCESS: {
            const { fields, restart } = action.payload;

            const newCustomerFieldMappings = fields.map((result) => ({
                fieldGuid: result.id,
                customerGuid: result.customerId,
            }));

            const infos: [string, models.FieldInfo][] = fields.map(
                (result: SearchAPI.IFieldResult) => [result.id, models.FieldInfo.fromSearch(result)]
            );

            const concatenatedCustomerFieldMappings = restart
                ? _(newCustomerFieldMappings)
                : _(state.customerFieldMappings).concat(newCustomerFieldMappings);

            return Object.freeze({
                ...state,
                fieldMap: state.fieldMap.merge(infos),
                customerFieldMappings: concatenatedCustomerFieldMappings
                    .uniqWith(models.equalCustomerFieldMappings)
                    .value(),
            });
        }
        case actions.FETCH_FILTERED_FIELD_GUIDS_SUCCESS: {
            const { fieldGuids } = action.payload;

            return Object.freeze({
                ...state,
                filteredFieldGuids: Set<string>(fieldGuids),
            });
        }
        case actions.FETCHING_SUMMARY: {
            return Object.freeze({
                ...state,
                fetchingSummary: true,
            });
        }
        case actions.FETCH_SUMMARY_SUCCESS: {
            const { response } = action.payload;

            return Object.freeze({
                ...state,
                fetchingSummary: false,
                quickSummary: {
                    customers: response.customers,
                    acres: response.acres,
                    fields: response.fields,
                    events: response.events,
                    recs: response.recs,
                },
            });
        }
        case actions.FETCH_SUMMARY_ERROR: {
            return Object.freeze({
                ...state,
                fetchingSummary: false,
            });
        }
        case actions.MODIFY_CUSTOMERS: {
            return Object.freeze({
                ...state,
                customerMap: modifyMap(state.customerMap, action.payload),
            });
        }
        case actions.MODIFY_FIELDS: {
            return Object.freeze({
                ...state,
                fieldMap: modifyMap(state.fieldMap, action.payload),
            });
        }
        case actions.MOVE_FIELDS: {
            const { customerGuid, farmName, fieldGuidList } = action.payload;
            const customerMap = state.customerMap.asMutable();
            const fieldMap = state.fieldMap.asMutable();
            const fieldGuidSet = Set(fieldGuidList.filter((fieldGuid) => fieldMap.has(fieldGuid)));
            if (fieldGuidSet.size === 0) {
                return state;
            }

            for (const fieldGuid of fieldGuidSet) {
                const existingField = fieldMap.get(fieldGuid);
                const newField = models.FieldInfo.updateFieldSummary(
                    existingField,
                    farmName && farmName.trim() !== ""
                        ? { customerGuid, farmName }
                        : { customerGuid }
                );
                fieldMap.set(fieldGuid, newField);
            }

            const newCustomerFieldMappings: models.ICustomerFieldMapping[] = _(
                state.customerFieldMappings
            )
                .filter((x) => !fieldGuidSet.has(x.fieldGuid))
                .concat(
                    fieldGuidList.map((x) => ({
                        fieldGuid: x,
                        customerGuid,
                    }))
                )
                .uniqWith(models.equalCustomerFieldMappings)
                .value();

            return Object.freeze({
                ...state,
                customerMap: customerMap.asImmutable(),
                fieldMap: fieldMap.asImmutable(),
                customerFieldMappings: newCustomerFieldMappings,
            });
        }
        case actions.REMOVE_CONNECTED_CUSTOMER:
            return Object.freeze({
                ...state,
                customerMap: state.customerMap.remove(action.payload.customerGuid),
                customerFieldMappings: state.customerFieldMappings.filter(
                    (x) => x.customerGuid !== action.payload.customerGuid
                ),
            });
        case actions.SET_ORG_LEVEL_DATA: {
            const { orgLevels } = action.payload;
            return Object.freeze({
                ...state,
                orgLevels: new Map(orgLevels),
            });
        }
        case actions.SET_SELECTED_FIELDS:
            return Object.freeze({
                ...state,
                selectedFields: Set(action.payload.fieldGuids),
            });
        case actions.UPDATE_FIELD_EVENT_COUNT: {
            const { fieldGuid, eventCount } = action.payload;
            if (state.fieldMap.get(fieldGuid) == null) {
                return state;
            }

            return Object.freeze({
                ...state,
                fieldMap: state.fieldMap.set(
                    fieldGuid,
                    models.FieldInfo.updateEventCount(state.fieldMap.get(fieldGuid), eventCount)
                ),
            });
        }
        case actions.UPDATE_FIELD_REC_COUNT: {
            const { fieldGuid, recCount } = action.payload;
            return Object.freeze({
                ...state,
                fieldMap: state.fieldMap.set(
                    fieldGuid,
                    models.FieldInfo.updateRecCount(state.fieldMap.get(fieldGuid), recCount)
                ),
            });
        }
        case actions.SET_NON_FIELD_FEATURES: {
            const features: [any, models.NonFieldFeatureInfo][] = action.payload.features.map(
                (feature) => [feature.id, new models.NonFieldFeatureInfo(feature)]
            );

            const nonFieldFeatureMap = Immutable.Map(features);

            return Object.freeze({
                ...state,
                nonFieldFeatureMap,
            });
        }
        case actions.MODIFY_NON_FIELD_FEATURES: {
            const { modifications } = action.payload;
            const add = modifications.add ? modifications.add : [];
            const remove = modifications.remove ? modifications.remove : [];
            const replace: { source: any; target: any }[] = modifications.replace
                ? modifications.replace
                : [];

            const nonFieldFeatureMap = state.nonFieldFeatureMap.withMutations((mutator) => {
                mutator.deleteAll(remove);
                for (const currentAdd of add) {
                    mutator.set(currentAdd.id, new models.NonFieldFeatureInfo(currentAdd));
                }
                for (const { source, target } of replace) {
                    const info = mutator.get(source.id);
                    info.feature = target;
                    mutator.delete(source.id);
                    mutator.set(target.id, info);
                }
            });

            return Object.freeze({
                ...state,
                nonFieldFeatureMap,
            });
        }
        case actions.SET_VISIBLE_NON_FIELD_FEATURES:
            return setNonFieldFeatureSubInfo(
                (info, setTo) => (info.visible = setTo),
                state,
                action.payload.features
            );
        case actions.MODIFY_VISIBLE_NON_FIELD_FEATURES:
            return modifyNonFieldFeatureSubInfo(
                (info, setTo) => (info.visible = setTo),
                state,
                action.payload.modifications
            );
        case actions.SET_SELECTED_NON_FIELD_FEATURES:
            return setNonFieldFeatureSubInfo(
                (info, setTo) => {
                    info.selected = setTo;
                    if (!setTo) {
                        info.highlighted = false;
                    }
                },
                state,
                action.payload.features
            );
        case actions.MODIFY_SELECTED_NON_FIELD_FEATURES:
            return modifyNonFieldFeatureSubInfo(
                (info, setTo) => {
                    info.selected = setTo;
                    if (!setTo) {
                        info.highlighted = false;
                    }
                },
                state,
                action.payload.modifications
            );
        case actions.SET_HIGHLIGHTED_NON_FIELD_FEATURES:
            return setNonFieldFeatureSubInfo(
                (info, setTo) => (info.highlighted = setTo),
                state,
                action.payload.features
            );
        case actions.MODIFY_HIGHLIGHTED_NON_FIELD_FEATURES:
            return modifyNonFieldFeatureSubInfo(
                (info, setTo) => (info.highlighted = setTo),
                state,
                action.payload.modifications
            );
        default:
            return state;
    }
};
