import { ReportsConfig } from "@ai360/core";

export function getFieldGuidArray(selectedFields) {
    const updatedSelectedFields = selectedFields.map(({ fieldGuid }) => fieldGuid);
    if (updatedSelectedFields.length > 0) {
        return updatedSelectedFields;
    }
    return null;
}

export function getDefaultReportImageConfig(mapkey) {
    const boundaryRC = ReportsConfig.getReportImageConfig(mapkey);
    return JSON.parse(JSON.stringify(boundaryRC));
}
