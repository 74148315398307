import React, { Component } from "react";
import PropTypes from "prop-types";
import { TextInput, DialogBox, DialogBoxFooterType, NoLink, ZeroToInfiniteGrid } from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "./../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
class BackOfficeList extends Component {
    static propTypes = {
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
    };

    static PROPS_BACKOFFICE_CODE = "backOfficeProductCode";

    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [BackOfficeList.PROPS_BACKOFFICE_CODE];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };
    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                hideCloseX
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.backOfficeCode
                )}
                actionDisabled={!editData[BackOfficeList.PROPS_BACKOFFICE_CODE]}
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                isOpen={isEditing}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
            >
                <div className="dialog-box-body">
                    <TextInput
                        tabIndex={0}
                        maxLengh={50}
                        required
                        autoFocus
                        placeholderText={formatMessage(messages.backOfficeCode)}
                        labelText={formatMessage(messages.backOfficeCode)}
                        value={formData[BackOfficeList.PROPS_BACKOFFICE_CODE] || ""}
                        onChange={(value) =>
                            onChange({
                                [BackOfficeList.PROPS_BACKOFFICE_CODE]: value,
                            })
                        }
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [BackOfficeList.PROPS_BACKOFFICE_CODE]: {
                                title: formatMessage(messages.backOfficeCode),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        showHeader={false}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.backOfficeCode)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(BackOfficeList);
