import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { defineMessages, injectIntl, intlShape } from "react-intl";

import { TextInput, Bucket, BucketHeader, SelectInput, Checkbox } from "~/core";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";

import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";

const messages = defineMessages({
    analysisFormLabelText: {
        id: "layerModule.analysisInfo.formNormalizedYieldLabelText",
        defaultMessage: "General Information",
    },
    analysisFormSideBarText: {
        id: "layerModule.analysisInfo.formNormalizedYieldSideBarText",
        defaultMessage: "Analysis",
    },
    analysisNamePlaceholderText: {
        id: "layerModule.analysisInfo.nameNormalizedYieldPlaceholderText",
        defaultMessage: "Analysis Layer Name",
    },
    analysisNormalizedYieldPlaceholderText: {
        id: "layerModule.analysisInfo.normalizedYieldsPlaceholderText",
        defaultMessage: "Normalized Yield",
    },
    analysisECDataPlaceholderText: {
        id: "layerModule.analysisInfo.ecDataPlaceholderText",
        defaultMessage: "Use EC Data",
    },
    analysisDataRequired: {
        id: "layerModule.analysisInfo.analysisDataRequired",
        defaultMessage: "No Soil Sampling data exists for the selected areas",
    },
    analysisYieldRequired: {
        id: "layerModule.analysisInfo.analysisYieldRequired",
        defaultMessage: "No Yield data exists for the selected areas",
    },
    analysisDuplicateName: {
        id: "layerModule.analysisInfo.seedStrong.analysisDuplicateName",
        defaultMessage: "Cannot have duplicate layer names.",
    },
    seedStrongCecRequired: {
        id: "layerModule.analysisInfo.seedStrongCecRequired",
        defaultMessage: "Missing Test Results For CEC.",
    },
    seedStrongOmRequired: {
        id: "layerModule.analysisInfo.seedStrongOmRequired",
        defaultMessage: "Missing Test Results For OM (%) or Humic Matter (%).",
    },
});

export const formLabelMessage = messages.analysisFormSideBarText;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2805, messages.analysisMethodPlaceholderText], // ErrorCode.AnalysisLayerNameIsRequired
    [2836, messages.analysisDataRequired],
    [2837, messages.analysisYieldRequired],
    [2806, messages.analysisDuplicateName],
    [3008, messages.seedStrongOmRequired],
    [3009, messages.seedStrongCecRequired],
]);

export const errorCodesApply = (errorCodeList) => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisSeedStrongForm_ extends PureComponent {
    static propTypes = {
        analysisSummary: PropTypes.object,
        intl: intlShape.isRequired,
        seedStrongOptions: PropTypes.object,
        analysisDetailsErrorCodeList: PropTypes.arrayOf(PropTypes.number).isRequired,
        onUpdateCurrentAnalysisSummary: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this._getErrorMessagePlaceholderSet(props),
        };
    }

    _getAnalysisErrors(errorMessagePlaceholderSet, formatMessage) {
        if (errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0) {
            return null;
        }
        const errorMessages = [];
        errorMessagePlaceholderSet.forEach((errorEntry) => {
            errorMessages.push(
                <div className="analysis-error-message">{formatMessage(errorEntry)}</div>
            );
        });
        return errorMessages;
    }

    _getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    _updateAnalysisSummary(newProps) {
        this.props.onUpdateCurrentAnalysisSummary(newProps);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this._getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    render() {
        const { analysisSummary, /** @type  */ seedStrongOptions } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;
        const { normalizedYieldGuid, useEcData } = analysisSummary;
        const seedStrongOptionsMap =
            seedStrongOptions.length === 0
                ? []
                : seedStrongOptions.normalizedYieldLayerList.map((item, key) => {
                      return {
                          label: item.name,
                          key: key,
                          value: item.analysisLayerGuid,
                      };
                  });

        const analysisErrors = this._getAnalysisErrors(errorMessagePlaceholderSet, formatMessage);

        return (
            <div className="rec-event-info-form">
                {analysisErrors}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>{formatMessage(messages.analysisFormLabelText)}</BucketHeader>
                    <TextInput
                        containerClassNames={[
                            "analysis-form-input",
                            {
                                "form-input-error": errorMessagePlaceholderSet.has(
                                    messages.analysisMethodPlaceholderText
                                ),
                            },
                        ]}
                        onChange={(v) => this._updateAnalysisSummary({ name: v })}
                        placeholderText={formatMessage(messages.analysisNamePlaceholderText)}
                        required={true}
                        maxLength={50}
                        value={analysisSummary.name}
                    />
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader>
                        {formatMessage(messages.analysisNormalizedYieldPlaceholderText)}
                    </BucketHeader>
                    <SelectInput
                        clearable={false}
                        containerClassNames={["analysis-form-input"]}
                        onChange={(v) =>
                            this._updateAnalysisSummary({
                                normalizedYieldGuid: v,
                            })
                        }
                        options={seedStrongOptionsMap}
                        placeholderText={formatMessage(
                            messages.analysisNormalizedYieldPlaceholderText
                        )}
                        required={true}
                        value={normalizedYieldGuid}
                    />
                </Bucket>
                {!seedStrongOptions.ecDataExists ? null : (
                    <Checkbox
                        className="analysis-input"
                        onChange={(evt, value) => this._updateAnalysisSummary({ useEcData: value })}
                        label={formatMessage(messages.analysisECDataPlaceholderText)}
                        value={useEcData}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateSeedStrongAnalysisSummary(newProps)),
});

const mapStateToProps = (state) => {
    const seedStrongOptions = selectors.getSeedStrongOptions(state);
    return {
        seedStrongOptions,
    };
};

export const AnalysisSeedStrongForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisSeedStrongForm_));
