import React, { Component } from "react";
import { InjectedIntl, injectIntl } from "react-intl";

import classnames from "classnames";
import { connect } from "react-redux";

import { model as accordionModel } from "~/accordion";
import {
    actions as cdActions,
    models as cdModels,
    selectors as cdSelectors,
} from "~/customer-data";
import { config as intlConfig } from "~/intl-provider/config";

import { FieldContextMenu } from "~/action-panel/components/context-menus/field-context-menu/field-context-menu";
import {
    actions as fieldActions,
    selectors as fieldSelectors,
} from "~/action-panel/components/field-module";

import * as actions from "../../actions";
import { messages } from "../../i18n-messages";
import * as selectors from "../../selectors";

import "./field-item.css";

interface IFieldItemProps {
    activeTab: fieldActions.FieldListTabs;
    clearSelectedField: (fieldGuid: string) => void;
    customer: cdModels.CustomerInfo;
    field: cdModels.FieldInfo;
    intl: InjectedIntl;
    isSelected: boolean;
    itemDimIdx: accordionModel.MultiDimIdx;
    itemList: accordionModel.AccordionItem[];
    lastClickedDimIdx: accordionModel.MultiDimIdx;
    onAddEditField: (customer: cdModels.CustomerInfo, fieldGuid: string) => void;
    addSelectedField: (fieldGuid: string) => void;
    addSelectedFields: (fieldGuids: Set<string>) => void;
    setlastClickedDimIdx: (activeTab: string, dimIdx: accordionModel.MultiDimIdx) => void;
    userRole: any;
}

class FieldItem_ extends Component<IFieldItemProps> {
    public render() {
        const { customer, field, isSelected, onAddEditField, userRole } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;

        const acres = formatMessage(messages.acres, {
            acres: formatNumber(field.acres, intlConfig.numberFormatOptions),
        });

        const fieldInfo = !field.farmName
            ? acres
            : `${formatMessage(messages.farm, {
                  farmName: field.farmName,
              })}, ${acres}`;

        const recText = formatMessage(messages.recText, {
            count: field.recCount,
        });
        const eventText = formatMessage(messages.eventText, {
            count: field.eventCount,
        });

        return (
            <div
                className={classnames("field-accordion-item", {
                    selected: isSelected,
                })}
                onClick={this._onClick}
            >
                <div className="field-selected-column">
                    <div className="field-selected-mark" />
                </div>
                <div className="field-info" title={`${field.name}\n${fieldInfo}`}>
                    <div className="field-name clip-overflow">{field.name}</div>
                    <div className="field-farm-acres clip-overflow">
                        {field.farmName ? (
                            [
                                <div key="farm" className="clip-overflow">
                                    {formatMessage(messages.farm, {
                                        farmName: field.farmName,
                                    })}
                                </div>,
                                <div key="acres">{`, ${acres}`}</div>,
                            ]
                        ) : (
                            <div className="clip-overflow">{acres}</div>
                        )}
                    </div>
                </div>
                {!userRole.recommendations ? (
                    <div className="rec-count" />
                ) : (
                    <div className="rec-count" title={`${field.recCount} ${recText}`}>
                        <div className="clip-overflow">{field.recCount}</div>
                        <div className="units">{recText}</div>
                    </div>
                )}
                {!userRole.agEvents ? (
                    <div className="event-count" />
                ) : (
                    <div className="event-count" title={`${field.eventCount} ${eventText}`}>
                        <div className="clip-overflow">{field.eventCount}</div>
                        <div className="units">{eventText}</div>
                    </div>
                )}
                <FieldContextMenu
                    field={field}
                    customer={customer}
                    onAddEditField={onAddEditField}
                />
            </div>
        );
    }

    public shouldComponentUpdate(nextProps: IFieldItemProps) {
        if (
            nextProps.activeTab !== this.props.activeTab ||
            nextProps.field !== this.props.field ||
            nextProps.isSelected !== this.props.isSelected
        ) {
            return true;
        }
        return false;
    }

    private _onClick = (event) => {
        const { activeTab, field } = this.props;
        if (event.isDefaultPrevented()) {
            return;
        }
        const {
            clearSelectedField,
            isSelected,
            itemDimIdx,
            itemList,
            lastClickedDimIdx,
            addSelectedField,
            addSelectedFields,
            setlastClickedDimIdx,
        } = this.props;

        if (event.shiftKey) {
            const comp = accordionModel.dimIdxCompare(lastClickedDimIdx, itemDimIdx);
            if (comp === 0) {
                return;
            }
            const fieldGuids = new Set<string>();
            const [startDimIdx, endDimIdx] =
                comp === -1 ? [lastClickedDimIdx, itemDimIdx] : [itemDimIdx, lastClickedDimIdx];

            // It's unlikely, but possible for items in the list to be added/removed between
            // when `lastClickedDimIdx` was set and this click
            const invalidDimIdx = [startDimIdx, endDimIdx].filter(
                (dimIdx) => !accordionModel.isValidDimIdx(itemList, dimIdx)
            );
            if (invalidDimIdx.length > 0) {
                return;
            }
            for (const dimIdx of accordionModel.getDimIdxSliceIter(
                itemList,
                startDimIdx,
                endDimIdx,
                true,
                [2]
            )) {
                const { fieldGuid } = accordionModel.getItem(itemList, dimIdx).payload;
                fieldGuids.add(fieldGuid);
            }
            addSelectedFields(fieldGuids);
        } else if (isSelected) {
            clearSelectedField(field.fieldGuid);
        } else {
            addSelectedField(field.fieldGuid);
        }

        setlastClickedDimIdx(activeTab, itemDimIdx);
    };
}

const mapDispatchToProps = (dispatch) => ({
    clearSelectedField: (fieldGuid) => dispatch(cdActions.clearSelectedField(fieldGuid)),
    addSelectedField: (fieldGuid) => dispatch(cdActions.addSelectedField(fieldGuid)),
    addSelectedFields: (fieldGuids) => dispatch(cdActions.addSelectedFields(fieldGuids)),
    setlastClickedDimIdx: (activeTab, dimIdx) =>
        dispatch(actions.setLastClickedDimIdx(activeTab, dimIdx)),
});

const mapStateToProps = (state, ownProps) => ({
    activeTab: fieldSelectors.getActiveTab(state),
    isSelected: cdSelectors.getSelectedFieldGuids(state).has(ownProps.field.fieldGuid),
    itemList: selectors.getAccordionItems(state),
    lastClickedDimIdx: selectors.getLastClickedDimIdx(state),
});

export const FieldItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(FieldItem_));
