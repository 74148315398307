import moment from "moment";
import { FieldAPI, SearchAPI } from "@ai360/core";
import { EventDetails, SampleSetup } from ".";

export class AgEventSummary {
    activeYn: boolean;
    agEventDisplayName: string;
    agEventGeneralGuid: string;
    agEventGuid: string;
    agEventName: string;
    agEventTransactionTypeGuid: string;
    agEventTypeName: string;
    cropDisplayName: string;
    cropGuids: string[];
    croppingSeasonGuid: string;
    croppingSeasonName: string;
    eventDate: string;
    eventId: string;
    fieldGuid: string;
    fieldBoundaryGuid: string;
    fieldName: string;
    fieldAcres: number;
    farmName: string;
    hasSoilSampleResults: boolean;
    importedPoints: number;
    importedStatus: number;
    isImportedYn: boolean;
    isFromEquationRec: boolean;
    modifiedDate: string;
    modifiedBy: string;
    numberOfSampleDepths: string;
    status: string;
    totalPoints: number;
    calculatedArea: number;
    customerName: string;
    customerGuid: string;
    customerEnrolled: boolean;

    get croppingSeasonNum(): number {
        return Number(this.croppingSeasonName);
    }

    get eventDateParsed(): Date {
        return moment(this.eventDate, "MM-D-YY").toDate();
    }

    static fromJsonObj(
        jsonObj: FieldAPI.IAgEventSummary,
        field: SearchAPI.IFieldResult
    ): Readonly<AgEventSummary> {
        const { importPoints, ...rvProps } = jsonObj;
        const rv = new AgEventSummary();
        Object.assign(rv, rvProps);
        rv.importedPoints = importPoints.importedPoints;
        rv.totalPoints = importPoints.totalPoints;
        rv.fieldBoundaryGuid = field.boundaryId;
        rv.fieldName = field.name;
        rv.farmName = field.farmName;
        rv.fieldAcres = field.acres;
        rv.customerName = field.customerName;
        rv.customerGuid = field.customerId;
        rv.customerEnrolled = field.customerEnrolled;
        return Object.freeze(rv);
    }

    static fromEventDetails(
        eventDetails: EventDetails,
        fieldBoundaryGuid: string = null,
        fieldName: string = null,
        farmName: string = null,
        fieldAcres: number = null,
        customerName: string = null
    ): Readonly<AgEventSummary> {
        const rv = new AgEventSummary();
        rv.agEventTransactionTypeGuid =
            eventDetails.agEventTypeList.length > 0
                ? eventDetails.agEventTypeList[0].agEventTransactionTypeGuid
                : "";
        rv.agEventTypeName =
            eventDetails.agEventTypeList.length > 0
                ? eventDetails.agEventTypeList[0].agEventTransactionTypeName
                : "";

        rv.eventDate = eventDetails.momentStartDate.format("M-D-YY");
        rv.agEventName = eventDetails.agEventTypeList
            .map((agEventTypeInfo) => agEventTypeInfo.agEventTransactionTypeName)
            .join(" + ");
        rv.agEventDisplayName = [rv.agEventName, rv.eventDate]
            .filter((val) => val !== "")
            .join(" - ");
        rv.modifiedDate = eventDetails.modifiedDate;
        rv.modifiedBy = eventDetails.modifiedBy;
        rv.fieldGuid = eventDetails.fieldGuid;
        rv.calculatedArea = eventDetails.eventAreaList.reduce(
            (accumulator, area) => accumulator + area.calculatedArea,
            0
        );
        rv.importedStatus = 0;
        rv.fieldBoundaryGuid = fieldBoundaryGuid;
        rv.fieldName = fieldName;
        rv.farmName = farmName;
        rv.fieldAcres = fieldAcres;
        rv.customerName = customerName;

        if (eventDetails.isSamplingEvent && eventDetails.eventAreaList.length > 0) {
            const sampleEvent = eventDetails.eventAreaList[0].agEventList[0]
                .agEventModel as SampleSetup;
            rv.eventId = sampleEvent.eventId;
            rv.importedPoints = 0;
            rv.numberOfSampleDepths = "1";
            rv.totalPoints = sampleEvent.samplePoints.length;
        }

        return Object.freeze(rv);
    }

    compare(rightHandSide: AgEventSummary): number {
        console.assert(rightHandSide instanceof AgEventSummary);
        if (this.croppingSeasonNum !== rightHandSide.croppingSeasonNum) {
            return this.croppingSeasonNum - rightHandSide.croppingSeasonNum;
        }
        const thisEventDate = this.eventDateParsed;
        const rhsEventDate = rightHandSide.eventDateParsed;
        return thisEventDate < rhsEventDate ? -1 : thisEventDate > rhsEventDate ? 1 : 0;
    }

    updateImportedStatus(importedStatus: number): AgEventSummary {
        const rv = new AgEventSummary();
        Object.assign(rv, this, { importedStatus });
        return Object.freeze(rv);
    }
}
