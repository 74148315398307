import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import { DialogBox, Loader, LoaderTypes } from "~/core";
import { DialogTool } from "~/core/components/dialog-box/dialog-tool";
import { PrintIcon, SplitFour, SplitTwo, TableIcon } from "~/core/icons";
import { getTheUserGuid } from "~/login";
import { LinkedMapGroupManager } from "~/map";

import { SurfaceDisplay } from "./components/surface-display";
import { EventSurfaceStatsTable } from "./components/event-surface-stats-table";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import { messages } from "../../../i18n-messages";

import "./split-screen-compare-tool.css";

class SplitScreenCompareTool_ extends Component {
    static propTypes = {
        customer: PropTypes.object,
        displayEventSurfaceStats: PropTypes.bool,
        eventSurfaceStatsLoading: PropTypes.bool,
        field: PropTypes.object,
        fieldLayerInfoIsLoading: PropTypes.bool.isRequired,
        intl: intlShape.isRequired,
        isOpen: PropTypes.bool.isRequired,
        layerInfo: PropTypes.array,
        onClearTableRecords: PropTypes.func,
        onClose: PropTypes.func.isRequired,
        onFetchLayerInfo: PropTypes.func.isRequired,
        onFetchTableRecords: PropTypes.func.isRequired,
        onPrintSplitLayer: PropTypes.func.isRequired,
        onSetDisplayEventSurfaceStats: PropTypes.func,
        splitScreenCompareTool: PropTypes.object.isRequired,
        tableRecords: PropTypes.any,
        userGuid: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.linkedMapsManager = new LinkedMapGroupManager();
        this.linkedMapsManager.mapDisplayCount = 2;
        this.state = {
            isLoading: false,
            mapDisplayCount: 2,
        };
    }

    _getTitle() {
        const { customer, field } = this.props;
        return customer != null && field != null
            ? field.farmName != null && field.farmName.length > 0
                ? `${customer.name} - ${field.farmName} - ${field.name}`
                : `${customer.name} - ${field.name}`
            : "";
    }

    _onChangeMapInfo(map, info) {
        this.setState({ [map]: info });
    }

    _onClose() {
        const { onClose, onSetDisplayEventSurfaceStats } = this.props;
        this.linkedMapsManager = new LinkedMapGroupManager();
        this.linkedMapsManager.mapDisplayCount = 2;
        this.isNewField = true;
        onSetDisplayEventSurfaceStats(false);
        this.linkedMapsManager.clearSurfaceDggInfo();
        onClose();
    }

    _onDisplayTable() {
        const { displayEventSurfaceStats, onSetDisplayEventSurfaceStats } = this.props;
        const { field, onClearTableRecords, onFetchTableRecords } = this.props;
        const polyJson = this.linkedMapsManager.getUserDefinedPolyJson();
        if (!displayEventSurfaceStats) {
            onFetchTableRecords(field.fieldGuid, polyJson ? JSON.stringify(polyJson) : null);
        } else {
            onClearTableRecords();
        }
        onSetDisplayEventSurfaceStats(!displayEventSurfaceStats);
    }

    _onPrint() {
        const { field, onPrintSplitLayer, displayEventSurfaceStats } = this.props;
        if (!displayEventSurfaceStats) {
            const { map1, map2, map3, map4, mapDisplayCount } = this.state;
            const maps = [map1, map2];
            if (mapDisplayCount === 4) {
                maps.push(map3, map4);
            }
            onPrintSplitLayer({
                extent: this.linkedMapsManager.getExtent(),
                fieldGuid: field != null ? field.fieldGuid : null,
                maps,
                title: this._getTitle(),
                userDefinedPoly: this.linkedMapsManager.getUserDefinedPolyJsonForPrint(),
            });
        }
    }

    _toggleSplit() {
        this.setState(
            {
                mapDisplayCount: this.state.mapDisplayCount === 4 ? 2 : 4,
            },
            () => {
                this.linkedMapsManager.mapDisplayCount = this.state.mapDisplayCount;
                this.linkedMapsManager.isNewField = true;
                this.linkedMapsManager._setMapFieldFeatures();
            }
        );
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (
            newProps.splitScreenCompareTool.fieldGuid !==
            this.props.splitScreenCompareTool.fieldGuid
        ) {
            this.setState({
                map1: null,
                map2: null,
                map3: null,
                map4: null,
                mapDisplayCount: 2,
            });
            this.linkedMapsManager.setFieldInfo(newProps.field.fieldGuid, 4);
            this.linkedMapsManager.updateMGRSGroup();
            if (newProps.layerInfo != null) {
                this.linkedMapsManager.setLayerInfo(newProps.layerInfo);
            } else if (newProps.field != null) {
                this.props.onFetchLayerInfo(newProps.field.fieldGuid);
            }
        }
        if (
            newProps.layerInfo != null &&
            newProps.fieldLayerInfoIsLoading &&
            JSON.stringify(newProps.layerInfo) !== JSON.stringify(this.props.layerInfo)
        ) {
            this.linkedMapsManager.setLayerInfo(newProps.layerInfo);
        }
    }

    componentWillUnmount() {
        this.linkedMapsManager.clearSurfaceDggInfo();
    }

    render() {
        const {
            displayEventSurfaceStats,
            eventSurfaceStatsLoading,
            field,
            fieldLayerInfoIsLoading,
            isOpen,
            layerInfo,
            tableRecords,
        } = this.props;
        const { isLoading, mapDisplayCount } = this.state;
        const { formatMessage } = this.props.intl;

        const split4 = mapDisplayCount === 4;
        let title = formatMessage(messages.splitScreenCompareTool);
        if (field != null) {
            title = `${title} - ${this._getTitle()}`;
        }
        const tableIconStyle = displayEventSurfaceStats ? "selected" : null;
        const printIconStyle = displayEventSurfaceStats ? "disabled" : null;

        const printTool = (
            <DialogTool onClick={(e) => this._onPrint(e)}>
                <PrintIcon className={printIconStyle} />
            </DialogTool>
        );

        const tableTool = (
            <DialogTool onClick={(e) => this._onDisplayTable(e)}>
                <TableIcon className={tableIconStyle} />
            </DialogTool>
        );

        const tools = (
            <span>
                {tableTool}
                {printTool}
            </span>
        );

        return (
            <DialogBox
                className="split-screen-compare-dialog"
                isOpen={isOpen}
                title={title}
                tools={tools}
                onClose={() => this._onClose()}
                unrestricted
            >
                <div
                    className={classnames("split-screen-row", {
                        "split-4": split4,
                    })}
                >
                    <SurfaceDisplay
                        id="map1"
                        isPrimary
                        fieldGuid={field.fieldGuid}
                        layerInfo={layerInfo}
                        linkedMapGroupManager={this.linkedMapsManager}
                        onChange={(i) => this._onChangeMapInfo("map1", i)}
                    />
                    <SurfaceDisplay
                        id="map2"
                        right
                        fieldGuid={field.fieldGuid}
                        layerInfo={layerInfo}
                        linkedMapGroupManager={this.linkedMapsManager}
                        onChange={(i) => this._onChangeMapInfo("map2", i)}
                        hidden={displayEventSurfaceStats}
                    />
                    <div className="split-button-top" onClick={() => this._toggleSplit()}>
                        {split4 ? <SplitTwo /> : <SplitFour />}
                    </div>
                    <EventSurfaceStatsTable
                        id="table1"
                        tableRecords={tableRecords}
                        hidden={!displayEventSurfaceStats}
                        isLoading={eventSurfaceStatsLoading}
                    />
                </div>
                <div className={classnames("split-screen-row", split4 ? "split-4" : "split-hide")}>
                    <SurfaceDisplay
                        id="map3"
                        inverted
                        fieldGuid={field.fieldGuid}
                        layerInfo={layerInfo}
                        linkedMapGroupManager={this.linkedMapsManager}
                        onChange={(i) => this._onChangeMapInfo("map3", i)}
                    />
                    <SurfaceDisplay
                        id="map4"
                        inverted
                        right
                        fieldGuid={field.fieldGuid}
                        layerInfo={layerInfo}
                        linkedMapGroupManager={this.linkedMapsManager}
                        onChange={(i) => this._onChangeMapInfo("map4", i)}
                    />
                    <div className="split-button-bottom" onClick={() => this._toggleSplit()}>
                        <SplitTwo />
                    </div>
                </div>
                {!isLoading && !fieldLayerInfoIsLoading ? null : (
                    <Loader type={LoaderTypes.BALL_SCALE_MULTIPLE} />
                )}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onFetchLayerInfo: (fieldGuid) => dispatch(actions.fetchLayerInfo(fieldGuid)),
    onFetchTableRecords: (fieldGuid, userDefinedPoly) =>
        dispatch(actions.fetchTableRecords(fieldGuid, userDefinedPoly)),
    onClearTableRecords: () => dispatch(actions.clearTableRecords()),
    onPrintSplitLayer: (payload) => dispatch(actions.createSplitPrintLayer(payload)),
    onSetDisplayEventSurfaceStats: (displayEventSurfaceStats) =>
        dispatch(actions.setDisplayEventSurfaceStats(displayEventSurfaceStats)),
});

const mapStateToProps = (state) => ({
    getIsFieldLayerInfoLoading: (fieldGuid) =>
        selectors.getLoadingFieldGuidSet(state).has(fieldGuid),
    layerInfos: selectors.getLayerInfos(state),
    splitScreenCompareTool: selectors.getSplitScreenCompareTool(state),
    userGuid: getTheUserGuid(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { displayEventSurfaceStats, eventSurfaceStatsLoading, fieldGuid, tableRecords } =
        stateProps.splitScreenCompareTool;
    const layerInfos = stateProps.layerInfos.get(fieldGuid);
    const layerInfo = layerInfos?.length > 0 ? layerInfos[0] : null;
    const field = {
        fieldGuid,
        name: layerInfo?.fieldName,
        farmName: layerInfo?.farmName,
    };
    const customer = {
        name: layerInfo?.customerName,
    };
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        customer,
        displayEventSurfaceStats,
        eventSurfaceStatsLoading,
        field,
        fieldLayerInfoIsLoading: stateProps.getIsFieldLayerInfoLoading(fieldGuid),
        layerInfo: layerInfos,
        tableRecords,
    };
};

export const SplitScreenCompareTool = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(SplitScreenCompareTool_));
