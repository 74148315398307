import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { ZeroToInfiniteGrid, AutoSearch } from "~/core";
import { SearchAPI } from "@ai360/core";
import { adminData } from "~/admin/data";
import { messages } from "./../i18n-messages";

const PROPS_CUSTOMER_GUID = SearchAPI.Props.CUSTOMER_GUID;
const PROPS_CUSTOMER_NAME = SearchAPI.Props.CUSTOMER_NAME;
const PROPS_CITY = SearchAPI.Props.CUSTOMER_CITY;
const PROPS_STATE_ABBR = SearchAPI.Props.STATE_ABBR;
const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;

class CustomerAffiliations_ extends Component {
    static propTypes = {
        classNames: PropTypes.string,
        disabled: PropTypes.bool,
        getOptionCB: PropTypes.func,
        intl: intlShape.isRequired,
        onDelete: PropTypes.func,
        onSelectionChange: PropTypes.func,
        options: PropTypes.array,
        orgLevelList: PropTypes.array,
        record: PropTypes.array,
        resetOptions: PropTypes.bool,
        statePropName: PropTypes.string,
        userGuid: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            options: props.options || [],
        };
    }

    prepareRecords(records) {
        if (PROPS_STATE_ABBR !== this.props.statePropName) {
            return records.map((record) => {
                let newRecord = { ...record };
                if (!record[this.props.statePropName]) {
                    newRecord[this.props.statePropName] = record[PROPS_STATE_ABBR];
                }
                return newRecord;
            });
        }
        return records;
    }

    getOptions = (userGuid, searchValue) => {
        if (this.props.getOptionCB) {
            this.props.getOptionCB();
        }
        const { orgLevelList } = this.props;
        const search = searchValue === "_" ? null : searchValue;
        return SearchAPI.getCustomers({
            userGuid,
            search,
            orgLevelGuids: orgLevelList,
            active: true,
        });
    };

    onChange = (item, isAdd = true) => {
        if (item == null) {
            return;
        }
        const { record } = this.props;
        const value = item[PROPS_CUSTOMER_GUID];
        let selectedValues = record ? [...record] : [];
        let selectedCustomerIndex = -1;
        const isAlreadyInList = selectedValues.some((customer, index) => {
            if (customer[PROPS_CUSTOMER_GUID] === value) {
                selectedCustomerIndex = index;
            }
            return customer[PROPS_CUSTOMER_GUID] === value;
        });
        if (isAdd && !isAlreadyInList) {
            selectedValues.push(item);
        } else {
            selectedValues.splice(selectedCustomerIndex, 1);
        }
        this.props.onSelectionChange(this.prepareRecords(selectedValues));
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { record, disabled, userGuid, resetOptions } = this.props;
        const { onChange } = this;
        return (
            <div className="auto-search-customer-affiliation auto-search-list-container">
                <AutoSearch
                    disabled={disabled}
                    itemList={[]}
                    isRepeatedlyRefreshed
                    getAutoSearchList={this.getOptions}
                    placeholderText={formatMessage(messages.addCustomer)}
                    keyProp={PROPS_CUSTOMER_GUID}
                    nameProp={PROPS_CUSTOMER_NAME}
                    secondaryPropList={[PROPS_CITY, PROPS_STATE_ABBR]}
                    onSelection={(value) => onChange(value, true)}
                    clearOnSelection
                    classNames={this.props.classNames}
                    required
                    userGuid={userGuid}
                    excludedValues={record ? record.map(({ customerGuid }) => customerGuid) : []}
                    resetOptions={resetOptions}
                />
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_CUSTOMER_NAME]: {
                                title: formatMessage(messages.name),
                            },
                            [PROPS_CITY]: {
                                title: formatMessage(messages.city),
                            },
                            [PROPS_STATE_ABBREVIATION]: {
                                title: formatMessage(messages.stateAbbr),
                            },
                        }}
                        className={"affiliation-table-stretch"}
                        onDelete={(option) => this.onChange(option, false)}
                    />
                )}
            </div>
        );
    }
}

export const CustomerAffiliations = injectIntl(CustomerAffiliations_);
