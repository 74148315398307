import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ImportList } from "./import-list/import-list";
import { ImportWizard } from "./import-wizard/containers/import-wizard";

import * as actions from "../actions";
import * as selectors from "../selectors";

import "./import-module.css";

export class ImportModule_ extends Component {
    static propTypes = {
        loadDetailPage: PropTypes.func,
        activePage: PropTypes.string.isRequired,
    };

    _getCurrentPage() {
        const { activePage, loadDetailPage } = this.props;

        if (activePage === actions.ImportModulePages.IMPORT_LIST) {
            return <ImportList />;
        }
        console.assert(activePage === actions.ImportModulePages.IMPORT_WIZARD);
        return <ImportWizard loadDetailPage={loadDetailPage} />;
    }

    render() {
        return <div className="import-module">{this._getCurrentPage()}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        activePage: selectors.getActivePage(state),
    };
};

export const ImportModule = connect(mapStateToProps, null)(ImportModule_);
