import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ImportFilter } from "../import-filter";
import * as actions from "../../../../../actions";
import { ProductBlendModal } from "~/action-panel/components/common/product-blend-modal";
import * as blendingModels from "~/action-panel/components/common/product-blend-modal/model";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";
import { Loader } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "./i18n-messages";
import { v4 as uuid } from "uuid";

class ImportProductFilter_ extends Component {
    static propTypes = {
        filterItems: PropTypes.array.isRequired,
        importFileGuidList: PropTypes.array,
        isApplication: PropTypes.bool,
        intl: intlShape.isRequired,
        selectItem: PropTypes.func.isRequired,
        selectedFieldGuid: PropTypes.string,
        updateAttribute: PropTypes.func.isRequired,
        updateFilterStatus: PropTypes.func.isRequired,
        removeImportFiles: PropTypes.func,
        assignMatchedToUnmatched: PropTypes.func,
    };
    static defaultProps = {
        importFileGuidList: [],
        selectedFieldGuid: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            isProductBlendingActive: false,
            activeProductBlend: -1,
            activeFilterItem: -1,
            originalProductMixList: [],
            importProductMixList: props.filterItems.map((item) => {
                const productMix = new blendingModels.ProductMix();
                return {
                    ...productMix,
                    products: item.products || [],
                    productMixTypeGuid: item.productMixTypeGuid,
                    productMixType: item.productMixType || "fertilizer",
                    productBlendGuid: item.productBlendGuid,
                    density: item.density,
                    densityUnitGuid: item.densityUnitGuid,
                    physicalStateGuid: item.physicalStateGuid,
                    isCarrier: item.isCarrier,
                    name: "",
                    isImport: Boolean(item.avgApplicationRate),
                    targetRate: blending.roundValue(item.avgApplicationRate, 2),
                    targetRateUnitGuid: item.avgRateUnitGuid,
                    targetRateUnit: item.avgRateUnitAbbreviation,
                    actualRate: blending.roundValue(item.avgApplicationRate, 2),
                    actualRateUnitGuid: item.avgRateUnitGuid,
                    actualRateUnit: item.avgRateUnitAbbreviation,
                };
            }),
            isSaving: false,
        };
    }

    filterProperties = this._buildFilterProperties();

    onAddEditProductMix(filterItem) {
        const mixIndex = this.props.filterItems.findIndex((item) => item === filterItem);
        const originalProductMixList = [...this.state.importProductMixList];

        this.setState({
            isProductBlendingActive: true,
            activeProductBlend: mixIndex,
            activeFilterItem: mixIndex,
            originalProductMixList,
        });
    }

    onUpdateProductBlending(productMix) {
        const importProductMixList = [...this.state.importProductMixList];
        importProductMixList.splice(this.state.activeProductBlend, 1, productMix);

        this.setState({ importProductMixList });
    }

    onSaveProductBlending() {
        const activeItem = this.state.importProductMixList[this.state.activeProductBlend];
        const activeFilterItem = this.props.filterItems[this.state.activeFilterItem];

        const newProductMixGuid = uuid();

        const newImportProductMix = {
            ...activeFilterItem,
            ...activeItem,
            name: activeItem.name,
            productMixTypeGuid: activeItem.productMixTypeGuid,
            productBlendGuid: activeItem.productBlendGuid,
            rawProduct: activeFilterItem.rawProduct,
            products: activeItem.products.map((product) => ({
                productGuid: product.productGuid,
                customProductGuid: product.customProductGuid,
                productName: product.productName || product.customProductName,
                percentOfMix: product.percentOfMix,
                rateUnitGuid: product.rateUnitGuid,
                rateUnitName: product.rateUnit,
                isCarrier: product.isCarrier,
                rate: product.rate,
                cost: product.cost,
                costUnitGuid: product.costUnitGuid,
                costUnitName: product.costUnit,
                density: product.density,
            })),
            actualRate: activeItem.actualRate,
            targetRate: activeItem.targetRate,
            productMixName: activeItem.name,
            productMixGuid: newProductMixGuid,
            productMixNameInFile: activeFilterItem.rawProduct,
            avgRateUnitAbbreviation: activeItem.targetRateUnit,
            targetRateUnitGuid: activeItem.targetRateUnitGuid,
            importProductMixGuid: newProductMixGuid,
            hasLookupMatch: true,
        };

        this.props.updateAttribute(newImportProductMix);
        this.onCloseProductBlending();
    }

    onCancelProductBlending() {
        this.setState({
            importProductMixList: [...this.state.originalProductMixList],
        });
        this.onCloseProductBlending();
    }

    onCloseProductBlending() {
        this.setState({
            isProductBlendingActive: false,
            activeProductBlend: -1,
            activeFilterItem: -1,
            originalProductMixList: [],
            isSaving: false,
        });
    }

    isValidProductMix(item) {
        return (
            item.products &&
            item.products.length > 0 &&
            !item.products.some((product) => product.percentOfMix === 0) &&
            item.avgApplicationRate > 0
        );
    }

    assignToProducts(data, index) {
        const from = data.products[index];

        const copy = (to) => {
            const newProductMixGuid = uuid();
            return {
                ...from,
                importFiles: to.importFiles,
                hasLookupMatch: true,
                isSelected: to.isSelected,
                rawProduct: to.rawProduct,
                productMixGuid: newProductMixGuid,
                importProductMixGuid: newProductMixGuid,
                productMixNameInFile: to.rawProduct,
                eventDates: to.eventDates,
                pointCount: to.pointCount,
            };
        };

        return {
            ...data,
            products: data.products.map((to) => (to.hasLookupMatch ? to : copy(to))),
        };
    }

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <ImportFilter
                    additionalValidation={(item) => this.isValidProductMix(item)}
                    isApplication={this.props.isApplication}
                    alterEditColumn={this.props.isApplication}
                    filterTypeName={actions.ImportFilterTypes.PRODUCTS}
                    title={formatMessage(messages.productFilterTitle)}
                    itemProperties={this.filterProperties}
                    filterItems={this.props.filterItems}
                    selectItem={(item) => {
                        this.props.selectItem(item);
                    }}
                    onEditAttribute={(item) => {
                        this.onAddEditProductMix(item);
                    }}
                    updateFilterStatus={(isComplete) => this.props.updateFilterStatus(isComplete)}
                    removeImportFiles={(importFileGuidList) =>
                        this.props.removeImportFiles(importFileGuidList)
                    }
                    assignMatchedToUnmatched={(index) =>
                        this.props.assignMatchedToUnmatched(index, this.assignToProducts)
                    }
                />
                {this.state.isSaving ? <Loader /> : null}
                {!this.state.isProductBlendingActive ? null : (
                    <ProductBlendModal
                        productMix={this.state.importProductMixList[this.state.activeProductBlend]}
                        hideAreaCalculations={true}
                        isOpen={this.state.isProductBlendingActive}
                        activeProductMixIdx={this.state.activeProductBlend}
                        onUpdate={(productMix) => this.onUpdateProductBlending(productMix)}
                        onSave={() => this.onSaveProductBlending()}
                        onCancel={() => this.onCancelProductBlending()}
                    />
                )}
            </div>
        );
    }

    _buildFilterProperties() {
        return this.props.isApplication
            ? [
                  {
                      isRequired: true,
                      label: this.props.intl.formatMessage(messages.productNameText),
                      propertyName: "productMixName",
                      propertyGuid: "hasLookupMatch",
                      cellSize: 60,
                  },
                  {
                      isRequired: false,
                      label: this.props.intl.formatMessage(messages.rateUnitText),
                      propertyName: "avgRateUnitAbbreviation",
                      cellSize: 15,
                  },
                  {
                      isRequired: false,
                      label: this.props.intl.formatMessage(messages.featuresText),
                      propertyName: "pointCount",
                      format: "numeric",
                      cellSize: 15,
                  },
              ]
            : [
                  {
                      isRequired: true,
                      label: this.props.intl.formatMessage(messages.productNameText),
                      propertyName: "productMixName",
                      propertyGuid: "hasLookupMatch",
                      cellSize: 50,
                  },
                  {
                      isRequired: false,
                      label: this.props.intl.formatMessage(messages.featuresText),
                      propertyName: "pointCount",
                      format: "numeric",
                      cellSize: 20,
                  },
              ];
    }
}

export const ImportProductFilter = connect(null, null)(injectIntl(ImportProductFilter_));
