import { connect } from "react-redux";

import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getUser } from "~/login/selectors";
import { actions as recsEventsActions, eventsSelectors, models } from "~/recs-events";
import { AgEventSummary } from "~/recs-events/events/model";
import {
    MergeableEvent,
    MergeableApplicationEvents,
    MergeableSoilEvents,
} from "~/data/mergeable-events";

import { getEventGuidFromDimIdx } from "../../../../../common/accordion/rec-event-accordion-item";

import { ContextMenu } from "../../../../../context-menus/item-selection-context-menu";
import { messages as menuMessages } from "../../../../../context-menus/i18n-messages";

import * as selectors from "../../selectors";
import * as actions from "../../actions";

import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as recListSelectors from "~/action-panel/components/rec-module/components/rec-list/selectors";

import { messages } from "../../../i18n-messages";
import { StatusCodes } from "../../../../../common/status-messages";

const fieldAccordionItemData = (accordionItems) => {
    const root = Array.from(
        accordionModel.getDimIdxSliceIter(accordionItems, [0], undefined, false, [1])
    ).map((dimIdx) => {
        const isExpanded = accordionModel.getItem(accordionItems, dimIdx).expanded;
        return { dimIdx, isExpanded } as {
            dimIdx: number[];
            isExpanded: boolean;
        };
    });

    const someAreExpanded = root.some(({ isExpanded }) => isExpanded);
    const someAreCollapsed = root.some(({ isExpanded }) => !isExpanded);

    return { someAreExpanded, someAreCollapsed };
};

const eventAccordionItemData = (accordionItems: any[], selectedEventGuidSet: Set<string>) => {
    const root = Array.from(
        accordionModel.getDimIdxSliceIter(accordionItems, [0], undefined, false, [2])
    ).map((dimIdx) => {
        const eventGuid = getEventGuidFromDimIdx(accordionItems, dimIdx);
        const isSelected = selectedEventGuidSet.has(eventGuid as string);
        return { dimIdx, isSelected };
    });

    const someAreSelected = root.some(({ isSelected }) => isSelected);
    const someAreDeselected = root.some(({ isSelected }) => !isSelected);
    const selectedDimIdxList = root
        .filter(({ isSelected }) => isSelected)
        .map(({ dimIdx }) => dimIdx);

    return { someAreSelected, someAreDeselected, selectedDimIdxList };
};

const summaryToMergeableEvent = (summary: AgEventSummary): MergeableEvent => ({
    activeYn: summary.activeYn,
    agEventDisplayName: summary.agEventDisplayName,
    agEventGeneralGuid: summary.agEventGeneralGuid,
    agEventGuid: summary.agEventGuid,
    agEventName: summary.agEventName,
    agEventTransactionTypeGuid: summary.agEventTransactionTypeGuid,
    agEventTypeName: summary.agEventTypeName,
    cropDisplayName: summary.cropDisplayName,
    cropGuids: summary.cropGuids,
    croppingSeasonGuid: summary.croppingSeasonGuid,
    croppingSeasonName: summary.croppingSeasonName,
    eventDate: summary.eventDate,
    eventId: summary.eventId,
    fieldGuid: summary.fieldGuid,
    hasSoilSampleResults: summary.hasSoilSampleResults,
    importedPoints: summary.importedPoints,
    importedStatus: summary.importedStatus,
    modifiedDate: summary.modifiedDate,
    numberOfSampleDepths: summary.numberOfSampleDepths,
    status: summary.status,
    totalPoints: summary.totalPoints,
    calculatedArea: summary.calculatedArea,
});

const extractMergeableApplicationEvents = (
    selected: AgEventSummary[]
): MergeableApplicationEvents | null => {
    const mergeableEvents = selected
        .filter(
            (event) =>
                event.fieldGuid === selected[0].fieldGuid &&
                event.croppingSeasonGuid === selected[0].croppingSeasonGuid
        )
        .map(summaryToMergeableEvent);
    return mergeableEvents.length >= 2
        ? new MergeableApplicationEvents(mergeableEvents[0], mergeableEvents.slice(1))
        : null;
};

//Maybe add check for status?
const extractMergeableSoilEvents = (selected: AgEventSummary[]): MergeableSoilEvents | null => {
    const mergeableEvents = selected
        .filter(
            (event) =>
                event.fieldGuid === selected[0].fieldGuid &&
                event.hasSoilSampleResults === selected[0].hasSoilSampleResults &&
                event.numberOfSampleDepths === selected[0].numberOfSampleDepths
        )
        .map(summaryToMergeableEvent);
    return mergeableEvents.length >= 2
        ? new MergeableSoilEvents(
              mergeableEvents[0],
              mergeableEvents.slice(1),
              mergeableEvents[0].hasSoilSampleResults
          )
        : null;
};

const modifyMenuItems = (menuItems, props) => {
    const { formatMessage } = props.intl;
    const {
        eventTypeOptions,
        getBatchEnabled,
        getCollapseAllArg,
        getCollapseAllEnabled,
        getExpandAllArg,
        getExpandAllEnabled,
        getIsYieldCalibrationEnabled,
        getIsBatchCopyEnabled,
        getIsBatchExportPointsEnabled,
        getIsBatchExportEventDataEnabled,
        getIsBatchExportSurfaceEnabled,
        getIsMergeEventsEnabled,
        onCollapseAll,
        onExpandAll,
        onNewBatchEvent,
        onCalibrateYield,
        onBatchCopy,
        onBatchExportPoints,
        onBatchExportEventData,
        onBatchExportSurface,
        onEventSelection,
        onMergeEvents,
        onRecSelection,
        eventSelectionCount,
        recSelectionCount,
        sampleSelectionCount,
        userInfo,
    } = props;
    //TODO: Remove scouting check once batch is functional
    const batchEvents = !userInfo.role.batchEvents
        ? []
        : [
              {
                  label: formatMessage(menuMessages.newBatchEventText),
                  disabled: !getBatchEnabled(),
                  subMenuItems: eventTypeOptions
                      .filter(
                          (eventTypeOption) =>
                              eventTypeOption.label !== models.EVENT_TYPE_NAME_SCOUTING
                      )
                      .map((eventTypeOption) => ({
                          key: eventTypeOption.label,
                          label: eventTypeOption.label,
                          action: () => onNewBatchEvent(eventTypeOption.value),
                      })),
              },
          ];

    const expandCollapse = [
        {
            label: formatMessage(menuMessages.expandAll),
            action: () => onExpandAll(getExpandAllArg()),
            disabled: !getExpandAllEnabled(),
        },
        {
            label: formatMessage(menuMessages.collapseAll),
            action: () => onCollapseAll(getCollapseAllArg()),
            disabled: !getCollapseAllEnabled(),
        },
    ];

    const calibrateYield = {
        label: formatMessage(menuMessages.calibrateYield),
        action: () => onCalibrateYield(),
        disabled: !getIsYieldCalibrationEnabled(),
    };

    const batchExportPoints = {
        label: formatMessage(menuMessages.batchExportPoints),
        action: () => onBatchExportPoints(),
        disabled: !getIsBatchExportPointsEnabled(),
    };

    const batchExportEventData = {
        label: formatMessage(menuMessages.batchExportEventData),
        action: () => onBatchExportEventData(),
        disabled: !getIsBatchExportEventDataEnabled(),
    };

    const batchExportSurface = {
        label: formatMessage(menuMessages.batchExportSurface),
        action: () => onBatchExportSurface(),
        disabled: !getIsBatchExportSurfaceEnabled(),
    };

    const mergeEvents = userInfo.role.mergeEvents
        ? {
              label: formatMessage(menuMessages.mergeEventsText),
              action: () => onMergeEvents(),
              disabled: !getIsMergeEventsEnabled(),
          }
        : null;

    const eventRecSelection = [];

    if (recSelectionCount > 0) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.recSelectionText),
            action: () => onRecSelection(),
        });
    }
    if (eventSelectionCount > 0) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.eventSelectionText),
            action: () => onEventSelection(),
        });
    }
    if (sampleSelectionCount > 1 && userInfo.role.batchEvents) {
        eventRecSelection.push({
            label: formatMessage(menuMessages.batchCopy),
            action: () => onBatchCopy(),
            disabled: !getIsBatchCopyEnabled(),
        });
    }
    const newMenuItems = [
        ...batchEvents,
        ...menuItems.slice(0, 2),
        ...eventRecSelection,
        ...expandCollapse,
        ...menuItems.slice(2),
        calibrateYield,
    ];
    if (userInfo.role.exportSamplePoints) {
        newMenuItems.push(batchExportPoints);
    }
    if (userInfo.role.exportEventData) {
        newMenuItems.push(batchExportEventData);
    }
    if (userInfo.role.exportSurfaces) {
        newMenuItems.push(batchExportSurface);
    }
    if (mergeEvents) {
        newMenuItems.push(mergeEvents);
    }

    return newMenuItems;
};

const mapStateToProps = (state) => {
    const accordionState = selectors.getAccordionState(state);
    const eventTypeOptions = eventsSelectors.getNewableEventTypeOptions(state);

    const { accordionId } = accordionState;
    const accordionItems = accordionState.items;
    const selectedEventGuidSet: Set<string> = selectors.getSelectedEventGuidSet(state);

    const agEventSummaryMap = eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);

    const selectedSurfaceEvents = Array.from(selectedEventGuidSet).reduce((accumulator, guid) => {
        const selectedEvent = agEventSummaryMap.get(guid);
        if (
            selectedEvent != null &&
            !(
                (selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_SOIL ||
                    selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE ||
                    selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SCOUTING) &&
                selectedEvent.importedPoints === 0
            )
        ) {
            accumulator.push(selectedEvent);
        }

        return accumulator;
    }, []);

    const selectedImportedEvents = Array.from(selectedEventGuidSet).reduce((accumulator, guid) => {
        const selectedEvent = agEventSummaryMap.get(guid);
        if (
            selectedEvent != null &&
            !(
                selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_SOIL ||
                selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE ||
                selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_SCOUTING ||
                selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_IRRIGATION ||
                selectedEvent.agEventTypeName === models.EVENT_TYPE_NAME_TILLAGE
            ) &&
            selectedEvent.isImportedYn
        ) {
            accumulator.push(selectedEvent);
        }

        return accumulator;
    }, []);

    const getFieldGuidInfo = () => {
        const fieldGuidList = accordionItems.map(
            (accordionItem) => accordionItem.payload.fieldGuid
        );
        const fieldGuidToBoundaryGuidMap = new Map(
            accordionItems.map((accordionItem) => [
                accordionItem.payload.fieldGuid,
                accordionItem.payload.fieldBoundaryGuid,
            ])
        );
        const fieldGuidToCustomerGuidMap = new Map(
            accordionItems.map((accordionItem) => [
                accordionItem.payload.fieldGuid,
                accordionItem.payload.customerGuid,
            ])
        );

        return [fieldGuidList, fieldGuidToBoundaryGuidMap, fieldGuidToCustomerGuidMap];
    };

    const selectedSummaries = Array.from(selectedEventGuidSet).map((agEventGeneralGuid) =>
        agEventSummaryMap.get(agEventGeneralGuid)
    );
    const nonNullSelectedSummaries = selectedSummaries.filter((summary) => summary != null);

    const selectedApplicationEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_APPLICATION
    );
    const selectedHarvestEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_HARVEST
    );
    const selectedEcDataEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_EC_DATA
    );
    const selectedPlantingEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_PLANTING
    );
    const selectedSoilEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_SOIL
    );
    const selectedTissueEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
    );
    const selectedSampleEvents = nonNullSelectedSummaries.filter(
        (summary) =>
            summary.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_SOIL ||
            summary.agEventTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
    );

    const selectedCompletedEvents = nonNullSelectedSummaries.filter(
        (summary) => summary.importedStatus === StatusCodes.Complete
    );

    const isImportedSummary = (summary) => summary.isImportedYn;
    const selectedImportedApplicationEvents = selectedApplicationEvents.filter(isImportedSummary);
    const selectedImportedHarvestEvents = selectedHarvestEvents.filter(isImportedSummary);
    const selectedImportedEcDataEvents = selectedEcDataEvents.filter(isImportedSummary);
    const selectedImportedPlantingEvents = selectedPlantingEvents.filter(isImportedSummary);
    const selectedImportedSampleEvents = selectedSampleEvents.filter(isImportedSummary);

    const allAreSoilSampleEvents = selectedSoilEvents.length === nonNullSelectedSummaries.length;
    const allAreTissueSampleEvents =
        selectedTissueEvents.length === nonNullSelectedSummaries.length;
    const someAreCompletedEvents = selectedCompletedEvents.length > 0;
    const someProcessingOrErrorSampling = selectedSampleEvents.some(
        (s) =>
            s.importedStatus === StatusCodes.CreatingEvent ||
            s.importedStatus === StatusCodes.ErrorCreatingEvent
    );

    const someAreImportedHarvestEvents = selectedImportedHarvestEvents.length > 0;
    const someAreImportedSampleEvents = selectedImportedSampleEvents.length > 0;

    const someAreImportedNonSoilEvents =
        selectedImportedApplicationEvents.length +
            selectedImportedHarvestEvents.length +
            selectedImportedEcDataEvents.length +
            selectedImportedPlantingEvents.length >
        0;

    const potentialMergeableEvents = [
        extractMergeableApplicationEvents(selectedImportedApplicationEvents),
        extractMergeableSoilEvents(selectedSoilEvents),
    ]
        .filter((x) => x)
        .filter((x) => x.all.length > 0);

    const mergeableEvents =
        potentialMergeableEvents.length === 1 ? potentialMergeableEvents[0] : null;
    const fieldGuids = [];
    let multipleEventsPerField = false;
    selectedSampleEvents.forEach((e) => {
        if (fieldGuids.includes(e.fieldGuid)) {
            multipleEventsPerField = true;
        } else {
            fieldGuids.push(e.fieldGuid);
        }
    });
    const { someAreExpanded, someAreCollapsed } = fieldAccordionItemData(accordionItems);
    const { someAreSelected, someAreDeselected, selectedDimIdxList } = eventAccordionItemData(
        accordionItems,
        selectedEventGuidSet
    );

    return {
        deleteConfirmationMessage: messages.deleteMultipleEventsConfirmation,
        eventTypeOptions,
        getBatchEnabled: () => accordionItems.length > 1,
        getClearSelectedArg: () => null,
        getClearSelectedEnabled: () => someAreSelected,
        getCollapseAllArg: () => accordionId,
        getCollapseAllEnabled: () => someAreExpanded,
        getDeleteSelectedArg: () => selectedDimIdxList,
        getDeleteSelectedEnabled: () => someAreSelected,
        getExpandAllArg: () => accordionId,
        getExpandAllEnabled: () => someAreCollapsed,
        getIsYieldCalibrationEnabled: () => someAreImportedHarvestEvents,
        getIsBatchCopyEnabled: () =>
            selectedSampleEvents.length > 1 &&
            !multipleEventsPerField &&
            !someProcessingOrErrorSampling &&
            (allAreSoilSampleEvents || allAreTissueSampleEvents),
        getIsBatchExportPointsEnabled: () => someAreImportedSampleEvents,
        getIsBatchExportEventDataEnabled: () => someAreImportedNonSoilEvents,
        getIsBatchExportSurfaceEnabled: () => someAreCompletedEvents,
        getIsMergeEventsEnabled: () => mergeableEvents !== null,
        getFieldGuidInfo,
        getSelectAllArg: () => null,
        getSelectAllEnabled: () => someAreDeselected,
        getSelectedHarvestAgEventGeneralGuids: () =>
            selectedImportedHarvestEvents.map((aeg) => aeg.agEventGeneralGuid),
        getSelectedSoilAgEvents: () => selectedSoilEvents,
        getSelectedCompletedEvents: () => selectedCompletedEvents,
        getSelectedSampleAgEvents: () => selectedSampleEvents,
        getSelectedEvents: () => selectedImportedEvents,
        getSelectedSurfaceEvents: () => selectedSurfaceEvents,
        getMergeableEvents: () => mergeableEvents,
        modifyMenuItems,
        eventSelectionCount: eventListSelectors.getSelectedEventGuidSet(state).size,
        recSelectionCount: recListSelectors.getSelectedRecGuidSet(state).size,
        sampleSelectionCount: selectedSampleEvents.length,
        selectedEventFieldGuidSet: eventListSelectors.getSelectedEventFieldGuidSet(state),
        selectedRecFieldGuidSet: recListSelectors.getSelectedRecFieldGuidSet(state),
        selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
        userInfo: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearSelectedFields: (fieldGuids) => dispatch(cdActions.clearSelectedFields(fieldGuids)),
        selectFields: (fieldGuidList) => dispatch(cdActions.addSelectedFields(fieldGuidList)),
        onSelectAll: () => dispatch(actions.selectEventsFromAccordion([0])),
        onClearSelected: () => dispatch(actions.deselectEventsFromAccordion([0])),
        onDeleteSelected: (toDeleteDimIdxList) =>
            dispatch(actions.deleteEventsFromDimIdxList(toDeleteDimIdxList)),
        onExpandAll: (accordionId) =>
            dispatch(accordionActions.expandAllAccordionItems(accordionId, [1])),
        onCollapseAll: (accordionId) =>
            dispatch(accordionActions.collapseAllAccordionItems(accordionId, [1])),
        onNewBatchEvent: (
            fieldGuidList,
            fieldGuidToBoundaryGuidMap,
            fieldGuidToCustomerGuidMap,
            agEventTransactionTypeName
        ) =>
            dispatch(
                recsEventsActions.createNewBatchEvent(
                    fieldGuidList,
                    fieldGuidToBoundaryGuidMap,
                    fieldGuidToCustomerGuidMap,
                    agEventTransactionTypeName
                )
            ),
        onCalibrateYield: (agEventGeneralGuidList) =>
            dispatch(actions.loadYieldCalibration(agEventGeneralGuidList)),
        onBatchCopy: (selectedAgEvents, fieldGuidList, fieldGuidToBoundaryGuidMap) =>
            dispatch(
                recsEventsActions.batchCopyEvents(
                    selectedAgEvents,
                    fieldGuidList,
                    fieldGuidToBoundaryGuidMap
                )
            ),
        onBatchExportPoints: (selectedAgEvents) =>
            dispatch(actions.exportSamplingPoints(selectedAgEvents)),
        onBatchExportEventData: (selectedImportedEvents) =>
            dispatch(actions.exportEventData(selectedImportedEvents)),
        onBatchExportSurface: (selectedCompletedEvents) =>
            dispatch(actions.exportSurfaceData(selectedCompletedEvents)),
        onMergeEvents: () => dispatch(actions.showMergeModal()),
        setMergeableEvents: (mergeableEvents) =>
            dispatch(actions.setMergeableEvents(mergeableEvents)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onNewBatchEvent: (eventTypeInfo) => {
        const { agEventTransactionTypeName } = eventTypeInfo;
        const [fieldGuidList, fieldGuidToBoundaryGuidMap, fieldGuidToCustomerGuidMap] =
            stateProps.getFieldGuidInfo();
        dispatchProps.onNewBatchEvent(
            fieldGuidList,
            fieldGuidToBoundaryGuidMap,
            fieldGuidToCustomerGuidMap,
            agEventTransactionTypeName
        );
    },
    onCalibrateYield: () => {
        dispatchProps.onCalibrateYield(stateProps.getSelectedHarvestAgEventGeneralGuids());
    },
    onBatchCopy: () => {
        const [fieldGuidList, fieldGuidToBoundaryGuidMap] = stateProps.getFieldGuidInfo();
        dispatchProps.onBatchCopy(
            stateProps.getSelectedSampleAgEvents(),
            fieldGuidList,
            fieldGuidToBoundaryGuidMap
        );
    },
    onBatchExportPoints: () => {
        dispatchProps.onBatchExportPoints(stateProps.getSelectedSampleAgEvents());
    },
    onBatchExportEventData: () => {
        dispatchProps.onBatchExportEventData(stateProps.getSelectedEvents());
    },
    onBatchExportSurface: () => {
        dispatchProps.onBatchExportSurface(stateProps.getSelectedSurfaceEvents());
    },
    onEventSelection: () => {
        const { selectedFieldGuids, selectedEventFieldGuidSet } = stateProps;
        const { clearSelectedFields, selectFields } = dispatchProps;
        if (selectedEventFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedEventFieldGuidSet)));
            selectFields(Array.from(selectedEventFieldGuidSet.values()));
        }
    },
    onMergeEvents: () => {
        dispatchProps.setMergeableEvents(stateProps.getMergeableEvents());
        dispatchProps.onMergeEvents();
    },
    onRecSelection: () => {
        const { selectedFieldGuids, selectedRecFieldGuidSet } = stateProps;
        const { clearSelectedFields, selectFields } = dispatchProps;
        if (selectedRecFieldGuidSet.size > 0) {
            clearSelectedFields(Array.from(selectedFieldGuids.subtract(selectedRecFieldGuidSet)));
            selectFields(Array.from(selectedRecFieldGuidSet.values()));
        }
    },
});

export const TabsContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ContextMenu);
