import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { config as intlConfig } from "~/intl-provider/config";
import { connect } from "react-redux";

import {
    Bucket,
    BucketHeader,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    NumericInput,
    RadioButtonGroup,
    RadioButton,
    SelectInput,
    SimpleTable,
    NoLink,
} from "~/core";
import { getTheUserGuid } from "~/login";
import { actions as notificationActions } from "~/notifications";

import { ISelectOption } from "~/core/components/select-input/model";
import { selectors as cdSelectors } from "~/customer-data";
import { FieldInfo } from "~/customer-data/models";

import {
    getEquipmentGuidToHarvestLoadListMap,
    getYieldCalibrationPassedValidation,
    getYieldCalibrationOptions,
    getYieldCalibrationErrorState,
} from "~/recs-events/events/selectors";
import * as eventActions from "~/recs-events/events/actions";
import * as eventListActions from "../../actions";
import { getYieldCalibrationLoading, getFieldGuidToSelectedEventGuidSetMap } from "../../selectors";
import { getEventSummary } from "../../../event-info/selectors";
import { messages } from "../../../i18n-messages";

import "./yield-calibration.css";
import {
    ICalibrateHarvestLoad,
    ICalibrateEquipmentLoads,
} from "~/recs-events/events/models/yield-calibration";
import { SearchAPI } from "@ai360/core";

const blank = "-";
const percent = "%";
const AVG_DRY_YIELD = 1;
const PER_ACRE = "/ac";

enum RequestType {
    Apply = 1,
    Reset = 3,
}
interface ICalibrationRequest {
    mode: RequestType;
    loads: any[];
    moistureAdjustPercent?: number;
    moistureOverride?: boolean;
    yieldAdjustTypeId?: number;
    yieldAdjustValue?: number;
}
interface IYieldCalibrationModalProps {
    saveYieldCalibration: (batch: ICalibrationRequest[], agEventGuidList: string[]) => null;
    equipmentGuidToHarvestLoadListMap: Map<string, ICalibrateEquipmentLoads>; //Map
    eventSummary: Record<string, any>;
    fieldEventMap: Map<string, Set<string>>;
    intl: IntlShape;
    isLoading: boolean;
    isOpen: boolean;
    onApiError: (err, message) => null;
    onClose: () => null;
    passedYieldCalibrationValidation: boolean;
    userGuid: string;
    yieldCalibrationPicklist: any[];
    yieldCalibrationHasError: boolean;
    clearYieldCalibrationError: () => null;
    clearYieldCalibrationLoadList: () => null;
    selectedFieldGuids: string[];
    setEquipmentGuidToHarvestLoadListMap: (newMap: Map<string, ICalibrateEquipmentLoads>) => null;
}
interface IYieldCalibrationModalState {
    confirmReset: boolean;
    errorToDisplay: number;
    isCalibrateToYield: string | boolean;
    isOverrideMoisture: boolean;
    modifiedLoadList: ICalibrateHarvestLoad[];
    moisture?: number;
    requests: ICalibrationRequest[];
    resetInvalid: boolean;
    title: string;
    yieldAmount?: number;
    yieldCalibrationAdjustId?: number;
}

interface IRecord {
    label: string;
    original: string;
    calibrated: string;
}

interface IRecordsData {
    calculatedYieldAveragesRecords: IRecord[];
    cropName: string;
    totalYieldAmount: number;
    totalAreaAmount: number;
    yieldUnitName: string;
}

class YieldCalibrationModal_ extends React.Component<
    IYieldCalibrationModalProps,
    IYieldCalibrationModalState
> {
    constructor(props: IYieldCalibrationModalProps) {
        super(props);
        this.state = {
            confirmReset: false,
            errorToDisplay: 0,
            isCalibrateToYield: true,
            isOverrideMoisture: false,
            modifiedLoadList: [],
            moisture: -1,
            requests: [],
            resetInvalid: false,
            title: "",
            yieldAmount: -1,
            yieldCalibrationAdjustId: null,
        };
    }

    private getDefaultRecordsData(): IRecordsData {
        const { formatMessage } = this.props.intl;
        const { modifiedLoadList } = this.state;
        const isCotton = modifiedLoadList.length > 0 && modifiedLoadList[0].lintMode;

        return {
            calculatedYieldAveragesRecords: [
                {
                    label: formatMessage(
                        !isCotton
                            ? messages.yieldCalibrationAvgDryYield
                            : messages.yieldCalibrationAvgLintYield
                    ),
                    original: blank,
                    calibrated: blank,
                },
                {
                    label: formatMessage(
                        !isCotton
                            ? messages.yieldCalibrationAvgMoisture
                            : messages.yieldCalibrationAvgLintPct
                    ),
                    original: blank,
                    calibrated: blank,
                },
            ],
            cropName: "",
            totalYieldAmount: 0,
            totalAreaAmount: 0,
            yieldUnitName: "",
        };
    }

    private applyYieldCalibration() {
        const { equipmentGuidToHarvestLoadListMap, setEquipmentGuidToHarvestLoadListMap } =
            this.props;
        const {
            isCalibrateToYield,
            isOverrideMoisture,
            moisture,
            requests,
            yieldAmount,
            yieldCalibrationAdjustId,
            modifiedLoadList,
        } = this.state;

        const yieldCalibrationModel: ICalibrationRequest = {
            mode: RequestType.Apply,
            loads: modifiedLoadList,
            moistureOverride: false,
            moistureAdjustPercent: null,
            yieldAdjustTypeId: null,
            yieldAdjustValue: null,
        };
        if (!isCalibrateToYield && moisture > -1) {
            yieldCalibrationModel.moistureAdjustPercent = moisture;
            yieldCalibrationModel.moistureOverride = isOverrideMoisture;
        }
        if (isCalibrateToYield && yieldAmount > -1) {
            yieldCalibrationModel.yieldAdjustTypeId = yieldCalibrationAdjustId;
            yieldCalibrationModel.yieldAdjustValue = yieldAmount;
        }

        const totalAcres = modifiedLoadList.reduce((acc, load) => {
            acc += load.loadArea;
            return acc;
        }, 0);
        let totalYield = 0;
        const totalAverageDryYield = modifiedLoadList.reduce((acc, load) => {
            acc +=
                (load.averageCalibratedDryYield || load.averageDryYield) *
                (load.loadArea / totalAcres);
            totalYield += (load.averageCalibratedDryYield || load.averageDryYield) * load.loadArea;
            return acc;
        }, 0);

        // Go through and mangle the equipmentGuidToHarvestLoadListMap such that it looks like the aftermath of the requested action
        const newEquipmentGuidToHarvestLoadListMap = new Map(equipmentGuidToHarvestLoadListMap);
        for (const [epg, item] of equipmentGuidToHarvestLoadListMap) {
            const { customerFieldList } = item;
            const newCustomerFieldList = [];

            for (const fieldItem of customerFieldList) {
                const newLoadList = [];

                for (const loadItem of fieldItem.loadList) {
                    const isMatch = modifiedLoadList.some(
                        (l) =>
                            l.agEventGuid === loadItem.agEventGuid &&
                            l.equipmentProfileGuid === loadItem.equipmentProfileGuid &&
                            l.loadName === loadItem.loadName
                    );

                    if (isMatch) {
                        if (isCalibrateToYield) {
                            let percentFactor = 1;
                            switch (yieldCalibrationAdjustId) {
                                case 1: // Avg Dry Yield
                                    percentFactor = yieldAmount / totalAverageDryYield;
                                    break;
                                case 2: // % Increase
                                    percentFactor += yieldAmount / 100;
                                    break;
                                case 3: // % Decrease
                                    percentFactor -= yieldAmount / 100;
                                    break;
                                default: // 4: Total Yield
                                    percentFactor = yieldAmount / totalYield;
                                    break;
                            }

                            loadItem.totalDryYield *= percentFactor;
                            loadItem.averageCalibratedDryYield *= percentFactor;
                            loadItem.averageCalibratedWetYield *= percentFactor;
                        } else {
                            // calibrating for moisture percent
                            const effectiveMoisturePct = loadItem.lintMode
                                ? Number(moisture) / 100
                                : (1 - Number(moisture) / 100) /
                                  (1 - loadItem.cropOptimalPercent / 100);

                            loadItem.averageCalibratedDryYield =
                                loadItem.averageCalibratedWetYield * effectiveMoisturePct;
                            loadItem.totalDryYield =
                                loadItem.averageCalibratedDryYield * loadItem.loadArea;
                            loadItem.averageCalibratedPercent = Number(moisture);
                        }
                    }

                    newLoadList.push(loadItem);
                }
                newCustomerFieldList.push({
                    ...fieldItem,
                    loadList: newLoadList,
                });
            }
            newEquipmentGuidToHarvestLoadListMap.set(epg, {
                ...item,
                customerFieldList: newCustomerFieldList,
            });
        }

        this.setState(
            {
                requests: [...requests, yieldCalibrationModel],
            },
            () => setEquipmentGuidToHarvestLoadListMap(newEquipmentGuidToHarvestLoadListMap)
        );
    }

    private saveYieldCalibration() {
        const { requests } = this.state;
        const agEventGuidList = new Set<string>();
        requests.forEach((req) =>
            req.loads.forEach((load) => agEventGuidList.add(load.agEventGuid))
        );

        this.setState(
            {
                modifiedLoadList: [],
                requests: [],
            },
            () => {
                this.props.saveYieldCalibration(requests, Array.from(agEventGuidList));
                this.props.clearYieldCalibrationLoadList();
                this.onClose();
            }
        );
    }

    private emptyFieldMap(): boolean {
        const { fieldEventMap } = this.props;
        return fieldEventMap.size < 1 || [...fieldEventMap.values()].every((s) => s.size < 1);
    }

    private async getModalTitle(): Promise<string> {
        const { formatMessage, formatNumber } = this.props.intl;
        const { eventSummary, fieldEventMap, selectedFieldGuids, userGuid } = this.props;

        let fieldGuidsWithTargetEvents = [];
        if (this.emptyFieldMap()) {
            //No Events were selected, so use the target Event Field
            fieldGuidsWithTargetEvents = [eventSummary.fieldGuid];
        } else {
            const fieldsWithSelectedEvents = new Map(
                [...fieldEventMap].filter(([k, v]) => v.size > 0)
            );
            fieldGuidsWithTargetEvents = [...fieldsWithSelectedEvents.keys()];
        }
        const selectedFieldsFiltered = selectedFieldGuids.filter((f) =>
            fieldGuidsWithTargetEvents.includes(f)
        );

        const fields = await SearchAPI.getFields({
            fieldGuid: selectedFieldsFiltered,
            userGuid,
        });

        const totalAcres = fields.reduce((acc, x) => acc + x.acres, 0);

        const fieldsLabel =
            selectedFieldsFiltered.length > 1
                ? messages.fieldsLabel.defaultMessage
                : messages.fieldLabel.defaultMessage;
        const formattedAcres = formatNumber(totalAcres, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return `${
            formatMessage(messages.yieldCalibrationTitle) +
            " (" +
            fieldGuidsWithTargetEvents.length +
            " " +
            fieldsLabel +
            ", " +
            formattedAcres +
            " ac)"
        }`;
    }

    private getYieldUnitName(harvestLoadList: ICalibrateHarvestLoad[] = []) {
        return harvestLoadList.reduce((acc, { yieldUnitName }, index) => {
            if (index) {
                console.assert(
                    acc === yieldUnitName,
                    "Yield Unit Name is not same for the harvestLoadList",
                    { acc, yieldUnitName }
                );
            }
            return yieldUnitName;
        }, "");
    }

    private setRecordsData(
        recordsData: IRecordsData,
        checkedHarvestLoadList: ICalibrateHarvestLoad[],
        {
            averageDryYield,
            averagePercent,
            averageCalibratedDryYield,
            averageCalibratedPercent,
        }: {
            averageDryYield: number;
            averagePercent: number;
            averageCalibratedDryYield: number;
            averageCalibratedPercent: number;
        }
    ) {
        const yieldUnitName = this.getYieldUnitName(checkedHarvestLoadList);
        recordsData.calculatedYieldAveragesRecords[0].original = this.formatAvgRecordData(
            recordsData.calculatedYieldAveragesRecords[0].original,
            averageDryYield,
            yieldUnitName
        );
        recordsData.calculatedYieldAveragesRecords[1].original = this.formatAvgRecordData(
            recordsData.calculatedYieldAveragesRecords[1].original,
            averagePercent,
            percent
        );

        recordsData.calculatedYieldAveragesRecords[0].calibrated = this.formatAvgRecordData(
            recordsData.calculatedYieldAveragesRecords[0].calibrated,
            averageCalibratedDryYield,
            yieldUnitName
        );
        recordsData.calculatedYieldAveragesRecords[1].calibrated = this.formatAvgRecordData(
            recordsData.calculatedYieldAveragesRecords[1].calibrated,
            averageCalibratedPercent,
            percent
        );

        return recordsData;
    }

    private getYieldUnit(checkedHarvestLoadList: ICalibrateHarvestLoad[]): string {
        const item = checkedHarvestLoadList.find((l) => !!l.yieldUnitName);
        return !item ? "" : item.yieldUnitName;
    }

    private calculateYieldAverages(checkedHarvestLoadList: ICalibrateHarvestLoad[]) {
        const recordsData = this.getDefaultRecordsData();
        if (checkedHarvestLoadList.length === 0) {
            return recordsData;
        }
        let currentAmt = 0;
        let averageDryYield = 0,
            averagePercent = 0,
            averageCalibratedDryYield = 0,
            averageCalibratedPercent = 0;

        recordsData.yieldUnitName = this.getYieldUnit(checkedHarvestLoadList);

        const combAcres = checkedHarvestLoadList.reduce((acc, load) => {
            acc += load.loadArea;
            return acc;
        }, 0);
        let anyCalibrated = false;

        checkedHarvestLoadList.forEach((loadItem) => {
            anyCalibrated = anyCalibrated || this.isLoadCalibrated(loadItem);
            let weightedAverageFactor = 0;
            if (combAcres > 0) {
                weightedAverageFactor = loadItem.loadArea / combAcres;
            }

            recordsData.cropName = loadItem.cropName;
            currentAmt += loadItem.totalDryYield;
            const currentDryYield = loadItem.averageDryYield * weightedAverageFactor;
            const currentPercent = loadItem.averagePercent * weightedAverageFactor;
            averageDryYield = this.calculateNewAverage(averageDryYield, currentDryYield);
            averagePercent = this.calculateNewAverage(averagePercent, currentPercent);

            const currentCalibratedDryYield =
                (loadItem.averageCalibratedDryYield || loadItem.averageDryYield) *
                weightedAverageFactor;
            const currentCalibratedPercent =
                (loadItem.averageCalibratedPercent || loadItem.averagePercent) *
                weightedAverageFactor;
            averageCalibratedDryYield = this.calculateNewAverage(
                averageCalibratedDryYield,
                currentCalibratedDryYield
            );
            averageCalibratedPercent = this.calculateNewAverage(
                averageCalibratedPercent,
                currentCalibratedPercent
            );
        });
        if (!anyCalibrated) {
            averageCalibratedDryYield = 0;
            averageCalibratedPercent = 0;
        }
        recordsData.totalYieldAmount = currentAmt;
        recordsData.totalAreaAmount = combAcres;
        return this.setRecordsData(recordsData, checkedHarvestLoadList, {
            averageDryYield,
            averagePercent,
            averageCalibratedDryYield,
            averageCalibratedPercent,
        });
    }

    // Formats the average value to be displayed on the table
    private formatAvgRecordData(defaultVal: string, value: number, yieldUnitName: string) {
        const { formatNumber } = this.props.intl;
        if (value && value > 0) {
            return `${formatNumber(value, intlConfig.numberFormatOptions)} ${yieldUnitName}`;
        }
        return defaultVal;
    }

    private calculateNewAverage(original: number, recordAverage: number) {
        if (!recordAverage) {
            return original;
        }
        return original + recordAverage;
    }

    private createSelectedLoadsCheckboxes(
        equipmentGuidToHarvestLoadListMap: Map<string, ICalibrateEquipmentLoads>,
        modifiedLoadList: ICalibrateHarvestLoad[]
    ) {
        const { formatMessage } = this.props.intl;
        let numberOfLoads = 0;

        const equipmentBuckets = Array.from(equipmentGuidToHarvestLoadListMap.entries()).map(
            ([equipmentProfileGuid, item], equipmentIndex) => {
                const { customerFieldList, equipmentProfileName } = item;
                const loadList = customerFieldList.map((fielditem, fieldIndex) => {
                    const { customerName, fieldName, loadList } = fielditem;
                    const formattedFieldName = fieldName.replace(" -", ", ");
                    numberOfLoads += loadList.length;
                    const Checkboxlist = loadList.map((loadItem, loadIndex) => {
                        const {
                            agEventGuid,
                            averageCalibratedDryYield,
                            averageCalibratedPercent,
                            averageCalibratedWetYield,
                            totalDryYield,
                            averageDryYield,
                            averagePercent,
                            averageWetYield,
                            cropName,
                            eventDate,
                            eventName,
                            lintMode,
                            loadArea,
                            loadName,
                            yieldIaGuid,
                            yieldUnitName,
                        } = loadItem;
                        const checkBoxCSS = this.isLoadCalibrated(loadItem)
                            ? "calibrated"
                            : "unCalibrated";
                        const modifiedItem: ICalibrateHarvestLoad = {
                            agEventGuid: agEventGuid,
                            cropOptimalPercent: undefined,
                            equipmentProfileGuid: equipmentProfileGuid,
                            loadName: loadName,
                            eventDate,
                            eventName,
                            averageCalibratedDryYield: Number(averageCalibratedDryYield),
                            averageCalibratedPercent: Number(averageCalibratedPercent),
                            averageCalibratedWetYield: Number(averageCalibratedWetYield),
                            averageDryYield: Number(averageDryYield),
                            averagePercent: Number(averagePercent),
                            averageWetYield: Number(averageWetYield),
                            cropName,
                            lintMode,
                            loadArea: Number(loadArea),
                            totalDryYield: Number(totalDryYield),
                            yieldIaGuid,
                            yieldUnitName,
                        };
                        return (
                            <Checkbox
                                className={checkBoxCSS}
                                key={`${equipmentIndex}-${fieldIndex}-${loadIndex}-checkbox`}
                                label={
                                    eventName.length > 0
                                        ? `${loadName} - ${eventName}`
                                        : `${loadName}`
                                }
                                value={Boolean(
                                    modifiedLoadList.find((item) => {
                                        return (
                                            item.equipmentProfileGuid === equipmentProfileGuid &&
                                            item.agEventGuid === agEventGuid &&
                                            item.loadName === loadName &&
                                            item.eventName === eventName
                                        );
                                    })
                                )}
                                onChange={(_e, value) =>
                                    this.updateCheckedLoadList(modifiedItem, value)
                                }
                            />
                        );
                    });
                    return (
                        <Bucket
                            showSymbol={false}
                            title={`${customerName}, ${formattedFieldName}`}
                            isCollapsible={false}
                            isExpanded
                            key={`${equipmentIndex}-${fieldIndex}-list`}
                            className="customer-field-bucket"
                        >
                            <BucketHeader className="customer-field-name">
                                {`${customerName}, ${formattedFieldName} - ${loadList[0].yieldUnitName}`}
                            </BucketHeader>
                            {Checkboxlist}
                        </Bucket>
                    );
                });
                const someAreSelected = modifiedLoadList.some(
                    (l) => l.equipmentProfileGuid === equipmentProfileGuid
                );
                const allAreSelected = customerFieldList.every((cf) => {
                    return cf.loadList.every((l) => {
                        return (
                            l.equipmentProfileGuid !== equipmentProfileGuid ||
                            modifiedLoadList.some((item) => {
                                return (
                                    item.equipmentProfileGuid === l.equipmentProfileGuid &&
                                    item.agEventGuid === l.agEventGuid &&
                                    item.loadName === l.loadName &&
                                    item.eventName === l.eventName
                                );
                            })
                        );
                    });
                });

                return (
                    <Bucket
                        showSymbol={false}
                        isCollapsible={false}
                        isExpanded
                        key={`${equipmentIndex}-list`}
                    >
                        <BucketHeader className="equipment-profile-name">
                            <Checkbox
                                label={
                                    equipmentProfileName ||
                                    formatMessage(messages.yieldCalibrationUnknown)
                                }
                                value={someAreSelected}
                                someChecked={someAreSelected && !allAreSelected}
                                onChange={(_e, value) =>
                                    this.toggleSelectAll(value, equipmentProfileGuid)
                                }
                            />
                        </BucketHeader>
                        {loadList}
                    </Bucket>
                );
            }
        );
        return (
            <Bucket
                className="select-load-panel"
                showSymbol={false}
                isCollapsible={false}
                isExpanded
                key="equipment-field-load-list"
            >
                <BucketHeader className="no-bucket-header"></BucketHeader>
                <Checkbox
                    className="yield-calibration-all-checkbox"
                    label={"All"}
                    value={modifiedLoadList.length > 0}
                    someChecked={
                        modifiedLoadList.length > 0 && modifiedLoadList.length < numberOfLoads
                    }
                    onChange={(_e, value) => this.toggleSelectAll(value)}
                />

                {equipmentBuckets}
            </Bucket>
        );
    }

    private isLoadCalibrated(loadItem: ICalibrateHarvestLoad) {
        const { formatNumber } = this.props.intl;
        const {
            averageDryYield,
            averageCalibratedDryYield,
            averageWetYield,
            averageCalibratedWetYield,
            averagePercent,
            averageCalibratedPercent,
        } = loadItem;
        const isDryYieldCalibrated =
            averageCalibratedDryYield &&
            formatNumber(averageDryYield, intlConfig.numberFormatOptions) !==
                formatNumber(averageCalibratedDryYield, intlConfig.numberFormatOptions);
        const isWetYieldCalibrated =
            averageCalibratedWetYield &&
            formatNumber(averageWetYield, intlConfig.numberFormatOptions) !==
                formatNumber(averageCalibratedWetYield, intlConfig.numberFormatOptions);
        const isMoistureCalibrated =
            averageCalibratedPercent &&
            formatNumber(averagePercent, intlConfig.numberFormatOptions) !==
                formatNumber(averageCalibratedPercent, intlConfig.numberFormatOptions);
        return isDryYieldCalibrated || isWetYieldCalibrated || isMoistureCalibrated;
    }

    private resetYieldCalibration() {
        const { modifiedLoadList } = this.state;
        if (modifiedLoadList.length > 0 && modifiedLoadList.some((l) => this.isLoadCalibrated(l))) {
            this.setState({ confirmReset: true });
        } else {
            this.setState({ resetInvalid: true });
        }
    }

    private updateCheckedLoadList(loadItem: ICalibrateHarvestLoad, value: boolean) {
        const tmpList = [...this.state.modifiedLoadList];
        const cropIsMatch = !tmpList.some((load) => load.cropName !== loadItem.cropName);
        const yieldUnitIsMatch = !tmpList.some(
            (load) => load.yieldUnitName !== loadItem.yieldUnitName
        );
        let isCropUnitMatch = true;

        if (!(tmpList.length === 0 || (cropIsMatch && yieldUnitIsMatch))) {
            isCropUnitMatch = false;
        }
        if (value && isCropUnitMatch) {
            tmpList.push(loadItem);
        } else {
            const idx = tmpList.findIndex((item) => {
                return (
                    item.equipmentProfileGuid === loadItem.equipmentProfileGuid &&
                    item.agEventGuid === loadItem.agEventGuid &&
                    item.loadName === loadItem.loadName &&
                    item.eventName === loadItem.eventName
                );
            });
            if (idx > -1) {
                tmpList.splice(idx, 1);
            }
        }
        this.setState({
            modifiedLoadList: tmpList,
            errorToDisplay: this.getErrorCode(cropIsMatch, yieldUnitIsMatch),
        });
    }

    private getErrorCode(cropIsMatch: boolean, yieldUnitIsMatch: boolean): number {
        if (!cropIsMatch && !yieldUnitIsMatch) {
            return 3;
        } else if (!yieldUnitIsMatch) {
            return 2;
        } else if (!cropIsMatch) {
            return 1;
        }
        return 0;
    }

    private isValidSelection(loadItem: ICalibrateHarvestLoad, tmpList: ICalibrateHarvestLoad[]) {
        const cropIsMatch = !tmpList.some((load) => load.cropName !== loadItem.cropName);
        const yieldUnitIsMatch = !tmpList.some(
            (load) => load.yieldUnitName !== loadItem.yieldUnitName
        );
        let isCropUnitMatch = true;

        if (!(tmpList.length === 0 || (cropIsMatch && yieldUnitIsMatch))) {
            isCropUnitMatch = false;
        }

        return !(!cropIsMatch || !yieldUnitIsMatch || !isCropUnitMatch); // is valid
    }

    private onResetConfirmClick(confirm: boolean) {
        const { equipmentGuidToHarvestLoadListMap, setEquipmentGuidToHarvestLoadListMap } =
            this.props;
        const { modifiedLoadList, requests } = this.state;
        this.setState({ confirmReset: false });
        if (!confirm) {
            return;
        }

        const selectedLoads = modifiedLoadList.map((loadItem) => {
            return {
                agEventGuid: loadItem.agEventGuid,
                equipmentProfileGuid: loadItem.equipmentProfileGuid,
                loadName: loadItem.loadName,
            };
        });

        // Go through and mangle the equipmentGuidToHarvestLoadListMap such that it looks like the aftermath of the requested action
        const newEquipmentGuidToHarvestLoadListMap = new Map(equipmentGuidToHarvestLoadListMap);
        for (const [epg, item] of equipmentGuidToHarvestLoadListMap) {
            const { customerFieldList } = item;
            const newCustomerFieldList = [];

            for (const fieldItem of customerFieldList) {
                const newLoadList = [];

                for (const loadItem of fieldItem.loadList) {
                    const isMatch = selectedLoads.some(
                        (l) =>
                            l.agEventGuid === loadItem.agEventGuid &&
                            l.equipmentProfileGuid === loadItem.equipmentProfileGuid &&
                            l.loadName === loadItem.loadName
                    );
                    const resetItem = {
                        ...loadItem,
                        averageCalibratedDryYield: loadItem.averageDryYield,
                        averageCalibratedPercent: loadItem.averagePercent,
                        averageCalibratedWetYield: loadItem.averageWetYield,
                        totalDryYield: loadItem.averageDryYield * loadItem.loadArea,
                    };
                    newLoadList.push(isMatch ? resetItem : loadItem);
                }
                newCustomerFieldList.push({
                    ...fieldItem,
                    loadList: newLoadList,
                });
            }
            newEquipmentGuidToHarvestLoadListMap.set(epg, {
                ...item,
                customerFieldList: newCustomerFieldList,
            });
        }

        this.setState(
            {
                modifiedLoadList: [],
                requests: [...requests, { mode: RequestType.Reset, loads: selectedLoads }],
            },
            () => {
                setEquipmentGuidToHarvestLoadListMap(newEquipmentGuidToHarvestLoadListMap);
            }
        );
    }

    private onClose() {
        this.props.clearYieldCalibrationLoadList();
        this.props.onClose();
    }

    private toggleSelectAll(val: boolean, equipmentProfileGuid: string = null) {
        const { modifiedLoadList } = this.state;
        const { equipmentGuidToHarvestLoadListMap } = this.props;
        const tmpList: ICalibrateHarvestLoad[] = [];
        let cropsMatch = true;
        let unitsMatch = true;

        if (equipmentProfileGuid == null) {
            if (!val && modifiedLoadList.length > 0) {
                this.setState({
                    modifiedLoadList: [],
                });
            } else {
                for (const [, item] of equipmentGuidToHarvestLoadListMap) {
                    const { customerFieldList } = item;
                    for (const fieldItem of customerFieldList) {
                        for (const loadItem of fieldItem.loadList) {
                            const {
                                agEventGuid,
                                averageCalibratedDryYield,
                                averageCalibratedPercent,
                                averageCalibratedWetYield,
                                totalDryYield,
                                averageDryYield,
                                averagePercent,
                                averageWetYield,
                                cropName,
                                eventDate,
                                eventName,
                                lintMode,
                                loadArea,
                                loadName,
                                yieldIaGuid,
                                yieldUnitName,
                            } = loadItem;
                            const modifiedItem: ICalibrateHarvestLoad = {
                                agEventGuid: agEventGuid,
                                cropOptimalPercent: undefined,
                                equipmentProfileGuid: loadItem.equipmentProfileGuid,
                                loadName: loadName,
                                eventDate,
                                eventName,
                                averageCalibratedDryYield: Number(averageCalibratedDryYield),
                                averageCalibratedPercent: Number(averageCalibratedPercent),
                                averageCalibratedWetYield: Number(averageCalibratedWetYield),
                                averageDryYield: Number(averageDryYield),
                                averagePercent: Number(averagePercent),
                                averageWetYield: Number(averageWetYield),
                                cropName,
                                lintMode,
                                loadArea: Number(loadArea),
                                totalDryYield: Number(totalDryYield),
                                yieldIaGuid,
                                yieldUnitName,
                            };

                            const isValid = this.isValidSelection(modifiedItem, tmpList);
                            if (isValid) {
                                tmpList.push(modifiedItem);
                            } else {
                                cropsMatch =
                                    cropsMatch && modifiedItem.cropName === tmpList[0].cropName;
                                unitsMatch =
                                    unitsMatch &&
                                    modifiedItem.yieldUnitName === tmpList[0].yieldUnitName;
                            }
                        }
                    }
                }

                this.setState({
                    modifiedLoadList: tmpList,
                });
            }
        } else {
            if (
                !val &&
                modifiedLoadList.some((l) => l.equipmentProfileGuid === equipmentProfileGuid)
            ) {
                this.setState({
                    modifiedLoadList: modifiedLoadList.filter(
                        (l) => l.equipmentProfileGuid !== equipmentProfileGuid
                    ),
                });
            } else {
                tmpList.push(...modifiedLoadList);
                for (const [epg, item] of equipmentGuidToHarvestLoadListMap) {
                    if (epg !== equipmentProfileGuid) {
                        continue;
                    }
                    const { customerFieldList } = item;
                    for (const fieldItem of customerFieldList) {
                        for (const loadItem of fieldItem.loadList) {
                            const {
                                agEventGuid,
                                averageCalibratedDryYield,
                                averageCalibratedPercent,
                                averageCalibratedWetYield,
                                totalDryYield,
                                averageDryYield,
                                averagePercent,
                                averageWetYield,
                                cropName,
                                eventDate,
                                eventName,
                                lintMode,
                                loadArea,
                                loadName,
                                yieldIaGuid,
                                yieldUnitName,
                            } = loadItem;
                            const modifiedItem: ICalibrateHarvestLoad = {
                                agEventGuid: agEventGuid,
                                cropOptimalPercent: undefined,
                                equipmentProfileGuid: equipmentProfileGuid,
                                loadName: loadName,
                                eventDate,
                                eventName,
                                averageCalibratedDryYield: Number(averageCalibratedDryYield),
                                averageCalibratedPercent: Number(averageCalibratedPercent),
                                averageCalibratedWetYield: Number(averageCalibratedWetYield),
                                averageDryYield: Number(averageDryYield),
                                averagePercent: Number(averagePercent),
                                averageWetYield: Number(averageWetYield),
                                cropName,
                                lintMode,
                                loadArea: Number(loadArea),
                                totalDryYield: Number(totalDryYield),
                                yieldIaGuid,
                                yieldUnitName,
                            };

                            const isValid = this.isValidSelection(modifiedItem, tmpList);
                            if (isValid) {
                                tmpList.push(modifiedItem);
                            } else {
                                cropsMatch =
                                    cropsMatch && modifiedItem.cropName === tmpList[0].cropName;
                                unitsMatch =
                                    unitsMatch &&
                                    modifiedItem.yieldUnitName === tmpList[0].yieldUnitName;
                            }
                        }
                    }
                }
                this.setState({
                    modifiedLoadList: tmpList,
                });
            }
        }

        this.setState({
            errorToDisplay: this.getErrorCode(cropsMatch, unitsMatch),
        });
    }

    private getDisplayError(formatMessage: IntlShape): JSX.Element {
        switch (this.state.errorToDisplay) {
            case 0:
                return null;
            case 1:
                return <React.Fragment>{formatMessage(messages.cropMatchError)}</React.Fragment>;
            case 2:
                return <React.Fragment>{formatMessage(messages.unitMatchError)}</React.Fragment>;
            default:
                return (
                    <React.Fragment>
                        {formatMessage(messages.cropMatchError)}
                        <br />
                        {formatMessage(messages.unitMatchError)}
                    </React.Fragment>
                );
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IYieldCalibrationModalProps) {
        if (
            nextProps.equipmentGuidToHarvestLoadListMap !==
            this.props.equipmentGuidToHarvestLoadListMap
        ) {
            const newModifiedLoadList = this.state.modifiedLoadList.map((load) => {
                const equipmentRecord = nextProps.equipmentGuidToHarvestLoadListMap.get(
                    load.equipmentProfileGuid
                );
                if (equipmentRecord == null) {
                    return null;
                } else {
                    const customerFieldRecord = equipmentRecord.customerFieldList.find(
                        (customerField) => {
                            return customerField.loadList.some(
                                (fieldLoad) => fieldLoad.agEventGuid === load.agEventGuid
                            );
                        }
                    );
                    const newLoad = customerFieldRecord.loadList.find((fieldLoad) => {
                        return (
                            fieldLoad.agEventGuid === load.agEventGuid &&
                            fieldLoad.loadName === load.loadName &&
                            fieldLoad.eventName === load.eventName
                        );
                    });
                    return {
                        ...load,
                        ...newLoad,
                    };
                }
            });
            if (newModifiedLoadList[0] != null) {
                this.setState({
                    modifiedLoadList: newModifiedLoadList,
                    moisture: nextProps.passedYieldCalibrationValidation ? -1 : this.state.moisture,
                    yieldAmount: nextProps.passedYieldCalibrationValidation
                        ? -1
                        : this.state.yieldAmount,
                    yieldCalibrationAdjustId: nextProps.passedYieldCalibrationValidation
                        ? null
                        : this.state.yieldCalibrationAdjustId,
                });
            }
        }
        if (
            nextProps.yieldCalibrationPicklist.length > 0 &&
            nextProps.yieldCalibrationPicklist !== this.props.yieldCalibrationPicklist &&
            (nextProps.passedYieldCalibrationValidation ||
                this.state.yieldCalibrationAdjustId == null)
        ) {
            this.setState({
                yieldCalibrationAdjustId: AVG_DRY_YIELD,
            });
        }
    }

    async componentDidMount() {
        const title = await this.getModalTitle();

        this.setState({
            title,
        });
    }

    render() {
        const { isOpen, isLoading, equipmentGuidToHarvestLoadListMap, yieldCalibrationPicklist } =
            this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        const {
            confirmReset,
            modifiedLoadList,
            moisture,
            resetInvalid,
            requests,
            title,
            yieldAmount,
            yieldCalibrationAdjustId,
        } = this.state;
        const errorToDisplay = this.getDisplayError(formatMessage);
        const moistureSet = moisture !== null && moisture > -1;
        const yieldSet = yieldAmount !== null && yieldAmount > -1;
        const isCotton = modifiedLoadList.length > 0 && modifiedLoadList[0].lintMode;

        const actionList = [
            {
                action: formatMessage(messages.applyYieldCalibration),
                actionDisabled:
                    modifiedLoadList.length === 0 ||
                    (!moistureSet && (!yieldSet || yieldCalibrationAdjustId === null)),
                onAction: () => this.applyYieldCalibration(),
            },
            {
                action: formatMessage(messages.saveText),
                actionDisabled: requests.length === 0,
                onAction: () => this.saveYieldCalibration(),
            },
        ];
        const selectableLoads =
            equipmentGuidToHarvestLoadListMap.size > 0
                ? this.createSelectedLoadsCheckboxes(
                      equipmentGuidToHarvestLoadListMap,
                      modifiedLoadList
                  )
                : "";
        const recordsData = this.calculateYieldAverages(modifiedLoadList);
        const { calculatedYieldAveragesRecords, cropName, yieldUnitName } = recordsData;

        const picklistOptions: ISelectOption<number>[] =
            !yieldCalibrationPicklist || yieldCalibrationPicklist.length === 0
                ? []
                : yieldCalibrationPicklist.map((item) => ({
                      value: Number(item.id),
                      label: !isCotton ? item.value : item.value.replace("Dry", "Lint"),
                  }));

        const crop = `${formatMessage(messages.yieldCalibrationCrop)} : ${cropName}`;
        const totalYield = `${formatMessage(messages.yieldCalibrationTotalYield)} : ${formatNumber(
            recordsData.totalYieldAmount,
            intlConfig.numberFormatOptions
        )} ${yieldUnitName?.replace(PER_ACRE, "")}`;
        const totalArea = `${formatMessage(messages.yieldCalibrationTotalArea)} : ${formatNumber(
            recordsData.totalAreaAmount,
            intlConfig.numberFormatOptions
        )} ac`;

        const columns = [
            { dataKey: "label", label: "", width: 150 },
            {
                dataKey: "original",
                label: formatMessage(messages.yieldCalibrationOriginal),
                width: 150,
            },
            {
                dataKey: "calibrated",
                label: formatMessage(messages.yieldCalibrationCalibrated),
                width: 150,
            },
        ];

        return (
            <DialogBox
                className="yield-calibration-dialog"
                draggable
                footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                multiActionList={actionList}
                forceOverflow
                isOpen={isOpen}
                unrestricted
                onClose={() => this.onClose()}
                title={title}
            >
                <Bucket
                    showSymbol={false}
                    isCollapsible={false}
                    isExpanded
                    className="yield-calibration-select-loads"
                >
                    <BucketHeader className="yield-calibration-title">
                        <span>{formatMessage(messages.yieldCalibrationSelectLoads)}</span>
                        <NoLink
                            className="reset-link"
                            label={formatMessage(messages.resetYieldCalibration)}
                            onClick={() => this.resetYieldCalibration()}
                        />
                    </BucketHeader>
                    {selectableLoads}
                    <div className="calibrated-legend">
                        <div className="calibrated-legend-symbol"></div>
                        <div className="calibrated-legend-label">
                            {formatMessage(messages.yieldCalibrationCalibratedLoads)}
                        </div>
                    </div>
                </Bucket>
                <DialogBox
                    footerType={DialogBoxFooterType.YES_NO}
                    isOpen={confirmReset}
                    onAction={() => this.onResetConfirmClick(true)}
                    onClose={() => this.onResetConfirmClick(false)}
                    title={formatMessage(messages.resetYieldConfirmationTitle)}
                >
                    {formatMessage(messages.resetYieldConfirmationMessage)}
                </DialogBox>
                <DialogBox
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={resetInvalid}
                    onClose={() => this.setState({ resetInvalid: false })}
                    title={formatMessage(messages.resetYieldConfirmationTitle)}
                >
                    {formatMessage(messages.resetYieldInvalidMessage)}
                </DialogBox>
                <Bucket
                    showSymbol={false}
                    isCollapsible={false}
                    isExpanded
                    className="yield-calibration-averages"
                >
                    <BucketHeader className="yield-calibration-title">
                        {formatMessage(messages.yieldCalibrationAverages)}
                    </BucketHeader>
                    <Bucket
                        showSymbol={false}
                        isCollapsible={false}
                        isExpanded
                        className="yield-calibration-averages-tables"
                    >
                        <div className="crop-total-yield-label">{crop}</div>
                        <div className="crop-total-yield-label">{totalYield}</div>
                        <div className="crop-total-yield-label">{totalArea}</div>
                        <SimpleTable
                            sortDirection={"ASC"}
                            height={250}
                            width={400}
                            className="yield-calibration-results-table"
                            headerHeight={30}
                            rowHeight={25}
                            columns={columns}
                            records={calculatedYieldAveragesRecords as any}
                        />
                    </Bucket>
                    <Bucket
                        showSymbol={false}
                        isCollapsible={false}
                        isExpanded
                        className="yield-calibration-averages-input"
                    >
                        <RadioButtonGroup
                            selectedValue={this.state.isCalibrateToYield}
                            afterOnChange={(v) => {
                                this.setState({
                                    isCalibrateToYield: v,
                                    moisture: v ? null : this.state.moisture,
                                    isOverrideMoisture: v ? null : this.state.isOverrideMoisture,
                                    yieldCalibrationAdjustId: v
                                        ? this.state.yieldCalibrationAdjustId
                                        : null,
                                    yieldAmount: v ? this.state.yieldAmount : null,
                                });
                            }}
                        >
                            <RadioButton
                                value={false}
                                label={
                                    <div className="input-row">
                                        <div className="label">
                                            {formatMessage(
                                                !isCotton
                                                    ? messages.yieldCalibrationMoistureLabel
                                                    : messages.yieldCalibrationLintPctLabel
                                            )}
                                        </div>
                                        <NumericInput
                                            disabled={Boolean(this.state.isCalibrateToYield)}
                                            scale={2}
                                            precision={8}
                                            className="yield-calibration-form-input"
                                            placeholderText={
                                                !isCotton
                                                    ? formatMessage(
                                                          messages.yieldCalibrationMoisture
                                                      )
                                                    : formatMessage(messages.yieldCalibrationLint)
                                            }
                                            onChange={(value) => {
                                                this.setState({
                                                    moisture: Number(value),
                                                });
                                            }}
                                            value={moistureSet ? moisture : ""}
                                        />
                                        <div className="label no-grow">%</div>
                                        {isCotton ? null : (
                                            <Checkbox
                                                className="override-checkbox"
                                                value={this.state.isOverrideMoisture}
                                                disabled={Boolean(this.state.isCalibrateToYield)}
                                                onChange={(_e, val) =>
                                                    this.setState({
                                                        isOverrideMoisture: val,
                                                    })
                                                }
                                                label={formatMessage(messages.overrideMoistureText)}
                                            />
                                        )}
                                    </div>
                                }
                            />
                            <RadioButton
                                value={true}
                                label={
                                    <div className="input-row">
                                        <div className="label">
                                            {formatMessage(messages.yieldCalibrationYieldLabel)}
                                        </div>
                                        <NumericInput
                                            disabled={!this.state.isCalibrateToYield}
                                            scale={2}
                                            precision={15}
                                            className="yield-calibration-form-input"
                                            placeholderText={formatMessage(
                                                messages.yieldCalibrationYield
                                            )}
                                            onChange={(value) => {
                                                this.setState({
                                                    yieldAmount: Number(value),
                                                });
                                            }}
                                            value={yieldSet ? yieldAmount : ""}
                                        />
                                        <SelectInput
                                            disabled={!this.state.isCalibrateToYield}
                                            required
                                            clearable={false}
                                            options={picklistOptions}
                                            onChange={(v) =>
                                                this.setState({
                                                    yieldCalibrationAdjustId: v as number,
                                                })
                                            }
                                            placeholderText={formatMessage(
                                                messages.yieldCalibrationAdjustType
                                            )}
                                            value={yieldCalibrationAdjustId}
                                        />
                                    </div>
                                }
                            />
                        </RadioButtonGroup>
                    </Bucket>
                </Bucket>
                {!isLoading ? null : <Loader />}
                <DialogBox
                    draggable
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={errorToDisplay !== null}
                    onClose={() => this.setState({ errorToDisplay: 0 })}
                    title={""}
                >
                    {errorToDisplay}
                </DialogBox>
                <DialogBox
                    draggable
                    footerType={DialogBoxFooterType.NONE}
                    isOpen={this.props.yieldCalibrationHasError}
                    onClose={() => this.props.clearYieldCalibrationError()}
                    title={""}
                >
                    {formatMessage(messages.yieldWeightError)}
                </DialogBox>
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
    saveYieldCalibration: (yieldCalibrationModel, agEventGuidList) =>
        dispatch(eventActions.applyYieldCalibration(yieldCalibrationModel, agEventGuidList)),
    onClose: () => dispatch(eventListActions.showHideYieldCalibrationModal(false)),
    clearYieldCalibrationError: () => dispatch(eventActions.clearYieldCalibrationError()),
    clearYieldCalibrationLoadList: () => dispatch(eventActions.clearYieldCalibrationLoadList()),
    setEquipmentGuidToHarvestLoadListMap: (equipmentGuidToHarvestLoadListMap) =>
        dispatch(eventActions.setEquipmentHarvestLoadMap(equipmentGuidToHarvestLoadListMap)),
});

const mapStateToProps = (state) => ({
    equipmentGuidToHarvestLoadListMap: getEquipmentGuidToHarvestLoadListMap(state),
    eventSummary: getEventSummary(state),
    fieldEventMap: getFieldGuidToSelectedEventGuidSetMap(state),
    isLoading: getYieldCalibrationLoading(state),
    passedYieldCalibrationValidation: getYieldCalibrationPassedValidation(state),
    userGuid: getTheUserGuid(state),
    selectedFieldGuids: cdSelectors.getSelectedFieldGuids(state),
    yieldCalibrationPicklist: getYieldCalibrationOptions(state),
    yieldCalibrationHasError: getYieldCalibrationErrorState(state),
});

export const YieldCalibrationModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(YieldCalibrationModal_));
