import { createSelector } from "reselect";

import { IFilter, IImportFileState } from "./model";

export const FILE_IMPORT_DATA_KEY = "FILE_IMPORT_DATA";

interface IState {
    [FILE_IMPORT_DATA_KEY]: IImportFileState;
}

export const getFetchAllUsersFilter = (state: IState): IFilter =>
    state[FILE_IMPORT_DATA_KEY].filter;

export const getImportTypes = (state: IState): any => state[FILE_IMPORT_DATA_KEY].importTypes;

export const getImportTemplates = (state: IState): any =>
    state[FILE_IMPORT_DATA_KEY].importTemplates;

export const getImportFiles = (state: IState): any => state[FILE_IMPORT_DATA_KEY].importFiles;

export const getImportType = (state: IState, importTypeGuid: string): any =>
    state[FILE_IMPORT_DATA_KEY].importTypes.get(importTypeGuid);

export const getImportTemplate = (state: IState, importTemplateGuid: string): any =>
    state[FILE_IMPORT_DATA_KEY].importTemplates.get(importTemplateGuid);

export const getImportFileInfo = (state: IState, importFileInfoGuid: string): any =>
    state[FILE_IMPORT_DATA_KEY].importFiles.get(importFileInfoGuid);

export const isFetchingTypes = (state: IState): any => state[FILE_IMPORT_DATA_KEY].fetchingTypes;

export const getImportTypeGuidToTemplateListMap = createSelector(
    getImportTemplates,
    (importTemplateMap) => {
        const importTypeGuidToTemplateListMap = new Map();
        for (const template of importTemplateMap.values()) {
            if (!importTypeGuidToTemplateListMap.has(template.importTypeGuid)) {
                importTypeGuidToTemplateListMap.set(template.importTypeGuid, []);
            }
            importTypeGuidToTemplateListMap.get(template.importTypeGuid).push(template);
        }
        return importTypeGuidToTemplateListMap;
    }
);

export const getImportTemplateGuidToImportFileListMap = createSelector(
    getImportFiles,
    (importFileMap) => {
        const importTemplateGuidToImportFileListMap = new Map();
        for (const ifo of importFileMap.values()) {
            if (!importTemplateGuidToImportFileListMap.has(ifo.templateGuid)) {
                importTemplateGuidToImportFileListMap.set(ifo.templateGuid, []);
            }
            importTemplateGuidToImportFileListMap.get(ifo.templateGuid).push(ifo);
        }
        return importTemplateGuidToImportFileListMap;
    }
);

export const getIsTelematicsUser = (state: unknown): any => {
    return state[FILE_IMPORT_DATA_KEY].isTelematicsUser;
};

export const getIsTelematicsProcessing = (state: unknown): any => {
    return state[FILE_IMPORT_DATA_KEY].isTelematicsProcessing;
};

export const getTelematicsCounts = (state: unknown): any => {
    return state[FILE_IMPORT_DATA_KEY].telematicsCounts;
};
